import React, { FC } from "react";
import { Descriptions, Card, Row, Col, Tooltip, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import PropTypes, { InferProps } from "prop-types";
import moment from "moment";
import { DATE_FORMAT } from "../../../constants";

const authorityCardPropTypes = {
  onEdit: PropTypes.func,
  displayValues: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    data: PropTypes.shape({
      regulatoryAuthorityRef: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        orgName: PropTypes.string.isRequired,
        countryName: PropTypes.string.isRequired,
      }).isRequired,
      licenseIssueDate: PropTypes.instanceOf(Date).isRequired,
      licenseExpiryDate: PropTypes.instanceOf(Date),
      isCurrentlyLicensed: PropTypes.bool.isRequired,
      hasDisciplinaryAction: PropTypes.bool.isRequired,
      licensureDocumentStatus: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export type AuthorityCardProps = InferProps<typeof authorityCardPropTypes>;

export const AuthorityCard: FC<AuthorityCardProps> = ({
  onEdit,
  displayValues,
}) => {
  const countryName = displayValues.data.regulatoryAuthorityRef?.countryName;
  const orgName = displayValues.data.regulatoryAuthorityRef?.orgName;
  //console.log(displayValues);
  return (
    <div className="space-align-block">
      <Card style={{ width: 350 }}>
        {/* org name */}
        <Descriptions title={orgName} size="small" layout="vertical">
          {/* country Name */}
          <Descriptions.Item label="Country">{countryName}</Descriptions.Item>
          <Descriptions.Item label="Date">
            {moment(displayValues.data.licenseIssueDate).format(DATE_FORMAT)}
          </Descriptions.Item>
          <Descriptions.Item label="Current">
            {displayValues.data.isCurrentlyLicensed ? "Yes" : "No"}
          </Descriptions.Item>
          <Descriptions.Item label="Disciplinary Action">
            {displayValues.data.hasDisciplinaryAction ? "Yes" : "No"}
          </Descriptions.Item>
        </Descriptions>
        <Row>
          <Col span="24" style={{ textAlign: "right" }}>
            <Tooltip title="Edit">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => onEdit && onEdit()}
              />
            </Tooltip>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

AuthorityCard.defaultProps = {
  onEdit: () => {},
};
