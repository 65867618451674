import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Button, Row, Col, Space, Typography } from 'antd';
import '../App.css';
import Login from './login';
import moment from 'moment';
import { useMsal } from './msal-react-lite';
import { LOGIN_TYPE } from './constants';
import { Helmet } from 'react-helmet';
import FooterLayout from './footer-layout';
import ECFMGLogo from '../logos/ECFMGTag_Full_Color_TM.svg';
import ECFMGLogoWhite from '../logos/ECFMG_NoTag_WHITE_TM_forSmall.svg';

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const { Title } = Typography;
const { Header, Content, Footer } = Layout;

const LayoutLogin: FC<any> = (props) => {
  const { logout } = useMsal();
  const [selectedMenu, setSelectedMenu] = useState('1');
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const menuItemSelected = async (e: any) => {
    setSelectedMenu(e.key);
    switch (e.key) {
      case '1':
        navigate(`/login`);
        break;
      case '2':
        navigate(`/case-management`);
        break;
    }
  };

  const HeaderLogo = () => {
    return screens.xs ? (
      <Header className="header-logo" style={{ alignContent: 'center', height: '100%' }}>
        <Row style={{ height: '100%', paddingTop: '20px' }}>
          <Col span={24}>
            <Space
              direction={'vertical'}
              size={'small'}
              style={{ alignItems: 'center', height: '100%' }}
            >
              <img
                src={ECFMGLogo}
                style={{
                  flex: 1,
                  height: '75%',
                  width: '100%',
                  resize: 'block',
                  paddingLeft: 0,
                  float: 'left',
                  minHeight: '100px',
                  maxWidth: '350px',
                }}
              />
              <Title
                level={1}
                style={{
                  textAlign: 'center',
                  paddingLeft: '0',
                }}
              >
                Certification Pathways
              </Title>
            </Space>
          </Col>
        </Row>
      </Header>
    ) : (
      <Header className="header-logo" style={{ alignContent: 'center', height: '100%' }}>
        <Row style={{ height: '100%', paddingTop: '10px' }}>
          <Col span={24}>
            <Space
              direction={'horizontal'}
              size={50}
              style={{ alignItems: 'center', height: '100%' }}
            >
              <img
                src={ECFMGLogo}
                style={{
                  flex: 1,
                  height: '75%',
                  width: '100%',
                  resize: 'block',
                  paddingLeft: '25px',
                  float: 'left',
                  minHeight: '100px',
                  minWidth: '300px',
                  maxWidth: '350px',
                }}
              />
              <Title
                level={1}
                style={{
                  textAlign: 'right',
                  paddingLeft: '20px',
                }}
              >
                Certification Pathways
              </Title>
            </Space>
          </Col>
        </Row>
      </Header>
    );
  };

  console.log(`login.props:${props}`);
  console.log(props);
  if (props.isAuthenticated === true) {
    return (
      <>
        <Helmet>
          <title>Pathways Administration</title>
        </Helmet>
        <Layout className="layout">
          <Header style={{ position: 'fixed', zIndex: 2, width: '100%' }}>
            <img className="logo" src={ECFMGLogoWhite} />
            <Menu
              onClick={(e) => menuItemSelected(e)}
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={[selectedMenu]}
            >
              <Menu.Item key="2">Case List</Menu.Item>
              <Menu.Item key="1" style={{ marginLeft: 'auto' }}>
                Logout
              </Menu.Item>
            </Menu>
          </Header>
          <Content
            className="site-layout"
            style={{ marginTop: '0px', display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="site-layout-background"
              style={{
                flex: '1 auto',
                display: 'flex',
                flexFlow: 'column',
                borderRadius: '0 0 0.8rem 0.8rem',
                marginTop: '60px',
                padding: '24px',
              }}
            >
              <Button
                size="large"
                type="primary"
                onClick={() => logout(LOGIN_TYPE.Administration)}
                style={{ width: '100px', alignSelf: 'end' }}
              >
                Logout
              </Button>
            </div>
          </Content>
          <FooterLayout />
        </Layout>
      </>
    );
  } else {
    return (
      <>
        <Layout className="layout">
          <HeaderLogo />
          <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}></Header>
            <Content
              className="site-layout"
              style={{ marginTop: '0px', display: 'flex', flexDirection: 'column' }}
            >
              <div
                className="site-layout-background"
                style={{
                  flex: '1 auto',
                  display: 'flex',
                  flexFlow: 'column',
                  borderRadius: '0 0 0.8rem 0.8rem',
                  marginTop: '60px',
                }}
              >
                <Login />
              </div>
            </Content>
            <FooterLayout />
          </Layout>
        </Layout>
      </>
    );
  }
};

export default LayoutLogin;
