import { FC } from 'react';
import { Dropdown, Menu } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import { DownOutlined } from '@ant-design/icons';

const ComponentPropTypes = {
  secondaryEmails: PropTypes.arrayOf(PropTypes.string),
};

export interface IProps {
  __typename?: 'PhysicianResponseType';
  secondaryEmails: string[];
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const EmailList: FC<ComponentProps> = ({ secondaryEmails }) => {

  const emailFunction = () => {
    return secondaryEmails.map((email) => {
      return <Menu.Item> {email}</Menu.Item>
    });
  };

  const menu = <Menu>
    {emailFunction()}
  </Menu>;

  return (
    <>

      <Dropdown overlay={menu}>
        <a className="ant-dropdown-link">Show E-mails <DownOutlined /></a>
      </Dropdown>

    </>
  );
};

export default EmailList;
