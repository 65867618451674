import PropTypes, { InferProps } from 'prop-types';
import { FC } from 'react';
import { MiniCexResponseType, PhysicianResponseType } from '../../../../../generated';
import MiniCEXPDF from './physician-miniCEX-pdf';
import MiniCEXPDFV2 from './physician-minicex-pdf-v2';
import MiniCEXPDFV3 from './physician-minicex-pdf-v3';
import MiniCEXPDFV4 from './physician-minicex-pdf-v4';

const PAGE_NAME = 'minicex-summary';
const MINICEX_COMPONENT_NAME = 'minicex-summary-review';

const ReadOnlyMiniCEXPropTypes = {
  showApplicantDetails: PropTypes.bool,
  showLicensureInfo: PropTypes.bool,
};

export interface ComponentProps {
  currentPhysicianData?: PhysicianResponseType;
  readOnlyMiniCEXData?: MiniCexResponseType;
}

export type ReadOnlyMiniCEXProps = InferProps<typeof ReadOnlyMiniCEXPropTypes> & ComponentProps;

const PhysicianMiniCEXPDFFactory: FC<ReadOnlyMiniCEXProps> = ({
  readOnlyMiniCEXData,
  currentPhysicianData,
}) => {
  
  const renderVersion = () => {
    var miniCEXVersionNum = 0;

    readOnlyMiniCEXData?.uiConfig?.config?.pages?.map((page) => {
      if (page?.pageName === PAGE_NAME) {
        page.components?.map((component) => {
          if (component?.componentName === MINICEX_COMPONENT_NAME) {
            miniCEXVersionNum = component?.version || 0;
          }
        });
      }
    });

    
    switch (miniCEXVersionNum) {
      case 1: {
        return (
          <MiniCEXPDF
            readOnlyMiniCEXData={readOnlyMiniCEXData}
            currentPhysicianData={currentPhysicianData}
          />
        );
      }
      case 2: {
        return (
          <MiniCEXPDFV2
            readOnlyMiniCEXData={readOnlyMiniCEXData}
            currentPhysicianData={currentPhysicianData}
          />
        );
      }
      case 3: {
        return (
          <MiniCEXPDFV3
            readOnlyMiniCEXData={readOnlyMiniCEXData}
            currentPhysicianData={currentPhysicianData}
          />
        );
      }
      case 4: {
        return (
          <MiniCEXPDFV4
            readOnlyMiniCEXData={readOnlyMiniCEXData}
            currentPhysicianData={currentPhysicianData}
          />
        );
      }
      default: {
        return (
          <MiniCEXPDFV4
            readOnlyMiniCEXData={readOnlyMiniCEXData}
            currentPhysicianData={currentPhysicianData}
          />
        );
      }
    }
  };

  return renderVersion();
};

export default PhysicianMiniCEXPDFFactory;
