import { Card, List, Typography } from 'antd';
import { FC } from 'react';
import { ArchivedCasePathwayXReviewType, UiPortalConfigType } from '../../../../generated';
import { CHECKLIST_EVALUATION_MAPPINGS } from '../../../constants';
import { PathwayXArchivedCaseReviewChecklistGeneralEligibilityFactory } from './pathway-x-archived-case-review-checklist-versions/pathway-x-archived-case-review-checklist-general-eligibility-factory';
import { PathwayXArchivedCaseReviewChecklistExceptionEligibilityFactory } from './pathway-x-archived-case-review-checklist-versions/pathway-x-archived-case-review-checklist-exception-eligibility-factory';
import { PathwayXArchivedCaseReviewChecklistPathEligibilityFactory } from './pathway-x-archived-case-review-checklist-versions/pathway-x-archived-case-review-checklist-path-eligibility-factory';

const { Title, Text } = Typography;

const PATHWAY_STATUS_MAPPINGS = [
  { key: 'pathway-exception-request-in-process', value: 'Exception request in progress' },
  { key: 'pathway-awaiting-applicant-resolution', value: 'Awaiting applicant resolution' },
  {
    key: 'pathway-awaiting-verification-licensure-document',
    value: 'Awaiting Verification of Licensure Document (Pathway 1)',
  },
  {
    key: 'pathway-awaiting-verification-osce-document',
    value: 'Awaiting Verification of OSCE Document (Pathway 2)',
  },
  { key: 'pathway-awaiting-attestation-pathway-2', value: 'Awaiting Attestation (Pathway 2)' },
  {
    key: 'pathway-awaiting-attestation-pathway-345',
    value: 'Awaiting Attestation (Pathway 3, 4, 5)',
  },
  { key: 'pathway-document-received-in-review', value: 'Pathway document received; In Review' },
  {
    key: 'pathway-verification-rejected-and-resent',
    value: 'Pathway verification Rejected and Resent',
  },
  { key: 'pathway-verification-review-complete', value: 'Pathway verification review complete' },
];

const EXCEPTION_STATUS_MAPPINGS = [
  { key: 'exception-exception-request-in-process', value: 'Exception request in process' },
  { key: 'awaiting-applicant-resolution', value: 'Awaiting applicant resolution' },
  {
    key: 'exception-request-under-review-with-operations',
    value: 'Exception request under review with operations leadership',
  },
  {
    key: 'exception-request-under-review-with-enterprise',
    value: 'Exception request under review with enterprise leadership',
  },
  { key: 'exception-review-complete', value: 'Exception review complete' },
];


const getColor = (value: string) => {
  switch (value) {
    case 'Yes':
      return 'green';
    case 'No':
      return 'red';
    default:
      return 'black';
  }
};

interface PathwayXArchivedCaseChecklistProps {
  data: ArchivedCasePathwayXReviewType;
  uiConfig: UiPortalConfigType;
}

export const PathwayXArchivedCaseChecklist: FC<PathwayXArchivedCaseChecklistProps> = (props) => {
  
const pathwayXGeneralEligibilityMap  = PathwayXArchivedCaseReviewChecklistGeneralEligibilityFactory(props.uiConfig);

const pathwayXExceptionEligibilityMap = PathwayXArchivedCaseReviewChecklistExceptionEligibilityFactory(props.uiConfig);

const pathwayXPathEligibilityMap = PathwayXArchivedCaseReviewChecklistPathEligibilityFactory(props.uiConfig);
  
  
  // Building Checklist Structure and Data
  let pathwayXChecklistData = [
    <Text style={{ fontWeight: 'bold' }} key={1}>
      Pathway Status
    </Text>,
    <div key={2}>
      {
        PATHWAY_STATUS_MAPPINGS.find((status) => status.key === props.data?.pathwayChecklistState)
          ?.value
      }
    </div>,
    <Text style={{ fontWeight: 'bold' }} key={3}>
      Exception Status
    </Text>,
    <div key={4}>
      {
        EXCEPTION_STATUS_MAPPINGS.find(
          (status) => status.key === props.data?.exceptionChecklistState
        )?.value
      }
    </div>,
    <Text style={{ fontWeight: 'bold' }} key={5}>
      General Eligibility
    </Text>,
  ];

  let tempChecklist = props.data?.checklist as Record<string, string>;

  for (const key in props.data?.checklist) {
    if (!pathwayXGeneralEligibilityMap?.[key]) {
      continue;
    }

    pathwayXChecklistData.push(
      <table width="100%">
        <tr>
          <td>{pathwayXGeneralEligibilityMap[key]}</td>
          <td align="right" style={{ color: getColor(tempChecklist[key]) }}>
            {CHECKLIST_EVALUATION_MAPPINGS.find((eva) => eva.key === tempChecklist[key])?.value}
          </td>
        </tr>
      </table>
    );
  }

  // New Section for Exception
  pathwayXChecklistData.push(<Text style={{ fontWeight: 'bold' }}>Exception Eligibility</Text>);

  for (const key in props.data?.checklist) {
    if (!pathwayXExceptionEligibilityMap?.[key]) {
      continue;
    }

    pathwayXChecklistData.push(
      <table width="100%">
        <tr>
          <td>{pathwayXExceptionEligibilityMap[key]}</td>
          <td align="right" style={{ color: getColor(tempChecklist[key]) }}>
            {CHECKLIST_EVALUATION_MAPPINGS.find((eva) => eva.key === tempChecklist[key])?.value}
          </td>
        </tr>
      </table>
    );
  }

  // New Section for Path
  pathwayXChecklistData.push(<Text style={{ fontWeight: 'bold' }}>Path Eligibility</Text>);

  for (const key in props.data?.checklist) {
    if (!pathwayXPathEligibilityMap?.[key]) {
      continue;
    }

    pathwayXChecklistData.push(
      <table width="100%">
        <tr>
          <td>{pathwayXPathEligibilityMap[key]}</td>
          <td align="right" style={{ color: getColor(tempChecklist[key]) }}>
            {CHECKLIST_EVALUATION_MAPPINGS.find((eva) => eva.key === tempChecklist[key])?.value}
          </td>
        </tr>
      </table>
    );
  }

  return (
    <Card
      bodyStyle={{ paddingTop: '5px', paddingBottom: '5px' }}
      style={{
        width: '50%',
        borderRadius: '10px',
        border: '1px solid var(--Neutral-4, #F0F0F0)',
        background: 'var(--Neutral-1, #FFF)',
      }}
      title={
        <Title level={5} style={{ fontWeight: 'bold' }}>
          Exception Checklist
        </Title>
      }
    >
      <List
        size="small"
        dataSource={pathwayXChecklistData}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </Card>
  );
};
