import { FC, useState } from 'react';
import { Card, Modal, Button, Descriptions, Tooltip, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import ViewPDFWrapper from '../../../ViewPDF-wrapper';
import { AdditionalDocument } from '../../../../generated';
const { Paragraph } = Typography;

interface AdditionalDocumentCardProps {
  displayValues: AdditionalDocument;
  disabled: boolean;
}

export const AdditionalDocumentCard: FC<AdditionalDocumentCardProps> = ({
  displayValues,
  disabled,
}) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);

  return (
    <div>
    <Card style={{ height: '132', width: '100%' }}>
      <Descriptions title="" size="small" layout="vertical">
        <Descriptions.Item>
          <Tooltip title={displayValues.document?.title}>
            <Paragraph strong ellipsis={{ rows: 2 }}>
              {displayValues.document?.title}
            </Paragraph>
          </Tooltip>
        </Descriptions.Item>
      </Descriptions>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Tooltip title="Preview">
            <Button
              type="primary"
              shape="circle"
              icon={<SearchOutlined />}
              onClick={() => setShowPreviewPdfModal(true)}
              disabled={disabled}
            />
          </Tooltip>
        </Col>
      </Row>
    </Card>

      <Modal
        title={displayValues.document?.title}
        visible={showPreviewPdfModal}
        width ="fit-content"
        closable
        bodyStyle={{ minWidth: '20vw', maxHeight: '80vh' }}
        footer={null}
        onCancel={() => setShowPreviewPdfModal(false)}
      >
        {showPreviewPdfModal && (
        <ViewPDFWrapper filename={displayValues.document?.title!} docId={displayValues.document?.docId!} />
        )}
      </Modal>
    </div>
  );
};
