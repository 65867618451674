import React, { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Divider, message, Collapse, Spin, Typography } from 'antd';
import { AdditionalDocumentCardList } from '../additional-document/additional-document-card-list';
import Pathway1ReviewApplicantDetails from './pathway1-review-applicant-details';
import ActivityFeed from '../../activity-feed';
import { useQuery, useMutation } from '@apollo/client';
import { AuthorityCardList } from '../authority-list/authority-card-list';
import { UploadDocument } from '../upload-document/upload-document';
import _ from 'lodash';
import { case_ } from '../../../schema/casePortal-case';
import {
  ActivityLogType,
  CaseMgmtPathway1LayoutAddLicenseDocument,
  CaseMgmtPathway1LayoutCasePortalDocument,
  CaseMgmtPathway1LayoutDeleteLicenseDocument,
  CaseSummaryType,
  CaseMgmtPathway1LayoutAddAdditionalDocumentDocument,
} from '../../../../generated';
const { Title } = Typography;

export interface ComponentProp {
  casePortal: {
    case: case_;
  };
}

var isUploadSuccessful = false;

const ADDITIONAL_DOC_TYPES = {
  license: 'Additional License',
  document: 'Additional Document',
};

var applicantDetails: any;

const Pathway1Layout: FC<any> = (props) => {
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);
  const navigate = useNavigate();
  let { usmleId } = useParams<string>();
  const [authorities, setAuthorities] = useState<any>([]);

  const { Panel } = Collapse;

  const {
    loading,
    error,
    data: path1ReviewData,
    refetch,
  } = useQuery(CaseMgmtPathway1LayoutCasePortalDocument, {
    variables: { usmleId: usmleId },
  });

  const [additionalLicenses, setAdditionalLicenses] = useState<any>([]);
  const [additionalDocuments, setAdditionalDocuments] = useState<any>([]);

  const [addAdditionalLicenseMutation] = useMutation(CaseMgmtPathway1LayoutAddLicenseDocument, {
    refetchQueries: [
      { query: CaseMgmtPathway1LayoutCasePortalDocument, variables: { usmleId: usmleId } },
    ],
    awaitRefetchQueries: true,
  });
  const [deleteAdditionalLicenseMutation] = useMutation(
    CaseMgmtPathway1LayoutDeleteLicenseDocument,
    {
      refetchQueries: [
        { query: CaseMgmtPathway1LayoutCasePortalDocument, variables: { usmleId: usmleId } },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [addAndDeleteAdditionalDocumentsMutation] = useMutation(
    CaseMgmtPathway1LayoutAddAdditionalDocumentDocument,
    {
      refetchQueries: [
        { query: CaseMgmtPathway1LayoutCasePortalDocument, variables: { usmleId: usmleId } },
      ],
      awaitRefetchQueries: true,
    }
  );

  useEffect(() => {
    if (path1ReviewData) {
      applicantDetails = {
        applicant: path1ReviewData?.casePortal?.case?.applicant,
        caseSummary: path1ReviewData?.casePortal?.case?.caseSummary,
      };

      setDataVersion(applicantDetails.caseSummary.dataVersion);

      // populate auth card list
      setAuthorities(path1ReviewData?.casePortal?.case?.pathway1?.applicantProvidedData);

      const staffProvidedData = path1ReviewData?.casePortal?.case?.pathway1?.staffProvidedData;
      var retrievedAdditionalLicenses: any = [];
      if (staffProvidedData) {
        _.forEach(staffProvidedData, (license) => {
          retrievedAdditionalLicenses.push(license?.data?.documents?.[0]);
        });
      }
      setAdditionalLicenses(retrievedAdditionalLicenses);

      const retrievedAdditionalDocuments: any = [];
      _.forEach(path1ReviewData?.casePortal?.case?.additionalDocuments, (element) => {
        retrievedAdditionalDocuments.push(element?.document);
      });
      setAdditionalDocuments(retrievedAdditionalDocuments);
    }
  }, [path1ReviewData]);

  const disableChecklist: boolean =
    path1ReviewData?.casePortal?.case?.caseSummary?.caseState?.toUpperCase() !==
      'eligibilityReview'.toUpperCase() ||
    path1ReviewData?.casePortal?.case?.caseSummary?.eligibilityReviewAssigneeId !==
      sessionStorage.getItem('userIdToken');
  //be assignee
  //and caseState = 'eligibilitReview'

  //Get the blob name for azure storage by saving in mongo
  async function getBlobname(filename: string, docType: string) {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalLicenses));
    var uniqueDocIds: any = [];
    deepCopyDocuments.map((doc: any) => {
      uniqueDocIds.push(doc.docId);
      delete doc.__typename;
    });
    const documentToUpload = {
      docType: docType,
      title: filename,
    };
    deepCopyDocuments.push(documentToUpload);
    if (docType === ADDITIONAL_DOC_TYPES.license) {
      return await addAdditionalLicenseMutation({
        variables: {
          usmleId: usmleId as string,
          dataVersion: dataVersion!,
          recordId: null,
          input: { documents: [documentToUpload] },
        },
      })
        .then((data) => {
          setDataVersion(data?.data?.CasePortal_pathway1_setData?.caseSummary?.dataVersion);
          const addedLicenses =
            data?.data?.CasePortal_pathway1_setData?.pathway1?.staffProvidedData; //debug this

          var docs: any = [];
          _.forEach(addedLicenses, (license) => {
            if (license?.data?.documents && license?.data?.documents?.length > 0) {
              docs.push(license?.data?.documents[0]);
            }
          });
          var thisDocGuid = '';
          docs.map((doc: any) => {
            //The most recent document won't exist in the uniqueDocIds, so that is the one for this document
            if (!uniqueDocIds.includes(doc.docId)) {
              thisDocGuid = doc.docId;
            }
          });
          setAdditionalLicenses(docs);
          isUploadSuccessful = true;
          return thisDocGuid;
        })
        .catch((error) => {
          isUploadSuccessful = false;
          console.log(error);
          return 'error';
        });
    } else {
      var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));
      var uniqueDocIds: any = [];
      deepCopyDocuments.map((doc: any) => {
        uniqueDocIds.push(doc.docId);
        delete doc.__typename;
      });
      const documentToUpload = {
        docType: docType,
        title: filename,
      };
      deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
        return {
          document: element,
        };
      });
      deepCopyDocuments.push({ document: documentToUpload });
      return addAndDeleteAdditionalDocumentsMutation({
        variables: {
          usmleId: usmleId as string,
          dataVersion: dataVersion!,
          input: deepCopyDocuments,
        },
      })
        .then((data) => {
          setDataVersion(data?.data?.CasePortal_additionalDocuments?.caseSummary?.dataVersion);
          const addedDocuments = data?.data?.CasePortal_additionalDocuments?.additionalDocuments; //debug this

          var docs: any = [];
          _.forEach(addedDocuments, (data) => {
            docs.push(data?.document);
          });
          var thisDocGuid = '';
          docs.map((doc: any) => {
            //The most recent document won't exist in the uniqueDocIds, so that is the one for this document
            if (!uniqueDocIds.includes(doc.docId)) {
              thisDocGuid = doc.docId;
            }
          });
          setAdditionalDocuments(docs);
          isUploadSuccessful = true;
          return thisDocGuid;
        })
        .catch((error) => {
          isUploadSuccessful = false;
          console.log(error);
          return 'error';
        });
    }
  }

  const onUpdateDataVersionFromChild = async (dataVersion: number) => {
    setDataVersion(dataVersion);
  };

  const openChecklistForLicense = async (licenseId: any) => {
    //open up the license
    navigate(`/case-management/case-detail/${usmleId}/pathway-1/document-review/${licenseId}`);
  };

  const onDeleteLicense = async (docId: any) => {
    const staffProvidedData = path1ReviewData?.casePortal?.case?.pathway1?.staffProvidedData;
    const recordId = _.find(
      staffProvidedData,
      (license) => license?.data?.documents?.[0]?.docId === docId
    )?._id;
    await deleteAdditionalLicenseMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        recordId: recordId!,
      },
    })
      .then((res: any) => {
        message.success('Delete successfully!');
        navigate(0);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onDeleteDocument = async (docId: any) => {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));

    deepCopyDocuments = _.remove(deepCopyDocuments, (document: any) => {
      return document.docId !== docId;
    });

    deepCopyDocuments.map((doc: any) => {
      delete doc.__typename;
    });

    deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
      return {
        document: element,
      };
    });

    return addAndDeleteAdditionalDocumentsMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        input: deepCopyDocuments,
      },
    })
      .then((res: any) => {
        message.success('Delete successfully!');
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };
  //reload the page when license upload is done
  const isLicenseUploaded = (value: boolean) => {
    navigate(0);
  };

  if (loading) {
    return <Spin></Spin>;
  }
  return (
    <>
      <Row>
        <Title level={2}>Pathway 1 Document Review</Title>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col md={24} lg={24} xl={12}>
          {!!applicantDetails?.applicant && !!applicantDetails?.caseSummary ? (
            <Pathway1ReviewApplicantDetails.Component
              applicant={applicantDetails?.applicant}
              caseSummary={applicantDetails?.caseSummary}
            />
          ) : null}
          <Divider plain orientation="center">
            <b>Applicant Licenses</b>
          </Divider>
          <Row>
            <Col span={23}>
              {authorities.length === 0 ? (
                <div>There is no license provided by this applicant.</div>
              ) : (
                <AuthorityCardList
                  onEdit={(licenseId) => openChecklistForLicense(licenseId)}
                  authorities={authorities}
                />
              )}
            </Col>
          </Row>

          <Divider plain orientation="center">
            <b>Additional Licenses</b>
          </Divider>
          <Row>
            <Col span={23}>
              <Collapse>
                <Panel key="1" header="">
                  {additionalLicenses.length === 0 ? (
                    <div>There is no additional license added.</div>
                  ) : (
                    <AdditionalDocumentCardList
                      numberOfCardsPerRow={3}
                      documents={additionalLicenses}
                      onDelete={(docId) => onDeleteLicense(docId)}
                      disabled={disableChecklist}
                    />
                  )}
                  <UploadDocument
                    getGuid={(filename) => getBlobname(filename, ADDITIONAL_DOC_TYPES.license)}
                    showIcon={false}
                    label="Add License"
                    disabled={disableChecklist}
                    updateIsUploadDone={(val: boolean) => {
                      isLicenseUploaded(val);
                    }}
                  />
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Divider plain orientation="center">
            <b>Additional Documents</b>
          </Divider>
          <Row>
            <Col span={23}>
              {additionalDocuments.length === 0 ? (
                <div>There is no additional document added.</div>
              ) : (
                <AdditionalDocumentCardList
                  numberOfCardsPerRow={3}
                  documents={additionalDocuments}
                  onDelete={(docId) => onDeleteDocument(docId)}
                  disabled={disableChecklist}
                />
              )}
              <UploadDocument
                getGuid={(filename) => getBlobname(filename, ADDITIONAL_DOC_TYPES.document)}
                showIcon={false}
                label="Add Document"
                //disabled because we want to allow document uploads at all times for supporting docs. Ex: refund supporting docs
                //disabled={disableChecklist}
              />
            </Col>
          </Row>
        </Col>

        <Col md={24} lg={24} xl={12}>
          <Title level={3}>Activity Feed</Title>
          <hr style={{ borderTop: '1px black solid' }} />
          {!!path1ReviewData?.casePortal?.case?.activityLog &&
          !!path1ReviewData?.casePortal?.case?.caseSummary ? (
            <ActivityFeed.Component
              activityLog={path1ReviewData.casePortal.case.activityLog as ActivityLogType[]}
              caseSummary={path1ReviewData.casePortal.case.caseSummary}
              parentDataVersion={dataVersion}
              onUpdateDataVersion={(dataVersion: number) => {
                onUpdateDataVersionFromChild(dataVersion);
              }}
              refetch={() => {
                refetch();
              }}
            />
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default Pathway1Layout;
