import { FC } from "react";
import { Divider } from "antd";
import PropTypes, { InferProps } from "prop-types";

const ReviewCSExamPropTypes = {
  pathway2: PropTypes.shape({
    userResponse: PropTypes.shape({
      hasPassedCSExamInRecentPast: PropTypes.bool,
      hasPassedFromQualifiedSchoolInRecentPast: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.shape({
          oscExamRef: PropTypes.shape({
            _id: PropTypes.string,
            examCode: PropTypes.string,
            examShortTitle: PropTypes.string,
            examFullName: PropTypes.string,
          }),
          schoolRef: PropTypes.shape({
            _id: PropTypes.number,
            countryName: PropTypes.string,
            name: PropTypes.string,
          }),
          examDate: PropTypes.string,
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
};

export type ReviewCSExamProps = InferProps<typeof ReviewCSExamPropTypes>;

// Q-E
const ReviewCSExamInformation: FC<ReviewCSExamProps> = ({
  pathway2,
}) => {

  const userResponse = pathway2?.userResponse;

  return (
    <>
      {userResponse ? (
        <div>
          <Divider plain orientation="left">
            <b>Pathway 2</b>
          </Divider>
          <div>
            <p>I do not meet the requirements for Pathway 2.</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default {
  Component: ReviewCSExamInformation,
};
