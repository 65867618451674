import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ArchivedCaseApplicantContainerArchivedCaseDocument, ArchivedCaseApplicantType } from '../../../../generated';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';
import ArchivedCaseApplicantDetails from './archived-case-applicant-details';
import { useQuery } from '@apollo/client';

const ArchivedCasApplicantContainer: FC<any> = () => {
  const { caseId } = useParams();

    const {
      loading: casesLoading,
      error: caseError,
      data: caseData,
    } = useQuery(ArchivedCaseApplicantContainerArchivedCaseDocument, {
      variables: {
        caseId: caseId ?? ''
      },
    });

  return (
    <ComponentQueryLoader
      loading={casesLoading}
        hasData={caseData?.archivedCase !== undefined}
      hasDataComponent={
        <ArchivedCaseApplicantDetails data={caseData?.archivedCase?.applicant as ArchivedCaseApplicantType} />
      }
      error={caseError}
    />
  );
};

export default ArchivedCasApplicantContainer;
