import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Space, Button, Row, Col, Select, Popconfirm, message, Typography } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import { SendOutlined, CheckOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client/react';
import {
  CaseMgmtPathway2DocumentReviewChecklistReviewDocument,
  CaseSummaryType,
} from '../../../../../../generated';
const { Title } = Typography;

const { Option } = Select;

const Pathway2DocumentReviewChecklistPropsTypes = {
  checklist: PropTypes.shape({
    osceExaminationAcceptable: PropTypes.string,
    osceExaminationPassed: PropTypes.string,
    osceExaminationAfterJuly1: PropTypes.string,
    osceNameOK: PropTypes.string,
    osceDocumentCompleteLegible: PropTypes.string,
    osceTranslation: PropTypes.string,
    verificationCompleted: PropTypes.string,
    verificationReturned: PropTypes.string,
    verificationPassedExamConfirmed: PropTypes.string,
    verificationAcceptableDateConfirmed: PropTypes.string,
  }),
  checklistState: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  parentDataVersion: PropTypes.number,
  onUpdateDataVersion: PropTypes.func.isRequired,
  applicant: PropTypes.shape({
    email: PropTypes.string,
  }),
  redirect: PropTypes.func.isRequired,
  checklistStatus: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export interface ComponentProps {
  caseSummary: CaseSummaryType;
}

export type Pathway2DocumentReviewChecklistProps = InferProps<
  typeof Pathway2DocumentReviewChecklistPropsTypes
> &
  ComponentProps;

const Pathway2DocumentReviewChecklistV2: FC<Pathway2DocumentReviewChecklistProps> = ({
  checklist,
  caseSummary,
  checklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  redirect,
  applicant,
  checklistStatus,
  refetch,
}) => {
  const [form] = Form.useForm();
  const [dataVersion, setDataVersion] = useState(parentDataVersion);
  const [licenseStatus, setLicenseStatus] = useState(checklistState);
  const [examinationAcceptable, setExaminationAcceptable] = useState(
    checklist?.osceExaminationAcceptable
  );
  const [examinationPassed, setExaminationPassed] = useState(checklist?.osceExaminationPassed);
  const [examinationAfterJuly1, setExaminationAfterJuly1] = useState(
    checklist?.osceExaminationAfterJuly1
  );
  const [nameOK, setNameOK] = useState(checklist?.osceNameOK);
  const [verificationCompleted, setVerificationCompleted] = useState(
    checklist?.verificationCompleted
  );
  const [verificationReturned, setVerificationReturned] = useState(checklist?.verificationReturned);
  const [passedExamConfirmed, setPassedExamConfirmed] = useState(
    checklist?.verificationPassedExamConfirmed
  );
  const [acceptableDateConfirmed, setAcceptableDateConfirmed] = useState(
    checklist?.verificationAcceptableDateConfirmed
  );
  const [osceDocumentCompleteLegible, setOsceDocumentCompleteLegible] = useState(
    checklist?.osceDocumentCompleteLegible
  );
  const [osceTranslation, setOsceTranslation] = useState(checklist?.osceTranslation);
  const [setPathway2Checklist] = useMutation(CaseMgmtPathway2DocumentReviewChecklistReviewDocument);

  useEffect(() => {
    if (checklistState && typeof licenseStatus == 'undefined') {
      setLicenseStatus(checklistState);
      form.setFieldsValue({
        licenseStatus: checklistState,
      });
    }
    if (parentDataVersion) {
      setDataVersion(parentDataVersion);
    }
    if (checklist) {
      if (typeof examinationAcceptable == 'undefined') {
        setExaminationAcceptable(checklist?.osceExaminationAcceptable);
        form.setFieldsValue({
          examinationAcceptable: checklist?.osceExaminationAcceptable,
        });
      }
      if (typeof examinationPassed == 'undefined') {
        setExaminationPassed(checklist?.osceExaminationPassed);
        form.setFieldsValue({
          examinationPassed: checklist?.osceExaminationPassed,
        });
      }
      if (typeof examinationAfterJuly1 == 'undefined') {
        setExaminationAfterJuly1(checklist?.osceExaminationAfterJuly1);
        form.setFieldsValue({
          examinationAfterJuly1: checklist?.osceExaminationAfterJuly1,
        });
      }
      if (typeof nameOK == 'undefined') {
        setNameOK(checklist?.osceNameOK);
        form.setFieldsValue({
          nameOK: checklist?.osceNameOK,
        });
      }
      if (typeof verificationCompleted == 'undefined') {
        setVerificationCompleted(checklist?.verificationCompleted);
        form.setFieldsValue({
          verificationCompleted: checklist?.verificationCompleted,
        });
      }
      if (typeof verificationReturned == 'undefined') {
        setVerificationReturned(checklist?.verificationReturned);
        form.setFieldsValue({
          verificationReturned: checklist?.verificationReturned,
        });
      }
      if (typeof passedExamConfirmed == 'undefined') {
        setPassedExamConfirmed(checklist?.verificationPassedExamConfirmed);
        form.setFieldsValue({
          passedExamConfirmed: checklist?.verificationPassedExamConfirmed,
        });
      }
      if (typeof osceTranslation == 'undefined') {
        setOsceTranslation(checklist?.osceTranslation);
        form.setFieldsValue({
          osceTranslation: checklist?.osceTranslation,
        });
      }
      if (typeof osceDocumentCompleteLegible == 'undefined') {
        setOsceDocumentCompleteLegible(checklist?.osceDocumentCompleteLegible);
        form.setFieldsValue({
          osceDocumentCompleteLegible: checklist?.osceDocumentCompleteLegible,
        });
      }
      if (typeof acceptableDateConfirmed == 'undefined') {
        setAcceptableDateConfirmed(checklist?.verificationAcceptableDateConfirmed);
        form.setFieldsValue({
          acceptableDateConfirmed: checklist?.verificationAcceptableDateConfirmed,
        });
      }
    }
  });

  const selectOptions = () => {
    return (
      <>
        <Option value=""> </Option>
        <Option value="No">No</Option>
        <Option value="Yes">Yes</Option>
        <Option value="N/A">N/A</Option>
      </>
    );
  };

  const onClickSave = async () => {
    var submitChecklist = {
      osceExaminationAcceptable: examinationAcceptable || '',
      osceExaminationPassed: examinationPassed || '',
      osceExaminationAfterJuly1: examinationAfterJuly1 || '',
      osceNameOK: nameOK || '',
      osceDocumentCompleteLegible: osceDocumentCompleteLegible || '',
      osceTranslation: osceTranslation || '',
      verificationCompleted: verificationCompleted || '',
      verificationReturned: verificationReturned || '',
      verificationPassedExamConfirmed: passedExamConfirmed || '',
      verificationAcceptableDateConfirmed: acceptableDateConfirmed || '',
    };

    var tempRecordId: any = recordId;
    if (tempRecordId?.toUpperCase() === 'OveridePath'.toUpperCase()) {
      tempRecordId = null;
    }

    await setPathway2Checklist({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion!,
        recordId: tempRecordId,
        isComplete: false,
        checklistResponse: submitChecklist,
        checklistState: licenseStatus || '',
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_pathway2_setReview?.caseSummary;
        setDataVersion(casePortalResponse?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.dataVersion);
        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onFormFinish = async () => {
    console.log('FORM VALIDATED');

    var submitChecklist = {
      osceExaminationAcceptable: examinationAcceptable || '',
      osceExaminationPassed: examinationPassed || '',
      osceExaminationAfterJuly1: examinationAfterJuly1 || '',
      osceNameOK: nameOK || '',
      osceDocumentCompleteLegible: osceDocumentCompleteLegible || '',
      osceTranslation: osceTranslation || '',
      verificationCompleted: verificationCompleted || '',
      verificationReturned: verificationReturned || '',
      verificationPassedExamConfirmed: passedExamConfirmed || '',
      verificationAcceptableDateConfirmed: acceptableDateConfirmed || '',
    };

    var tempRecordId: any = recordId;
    if (tempRecordId.toUpperCase() === 'OveridePath'.toUpperCase()) {
      tempRecordId = null;
    }

    await setPathway2Checklist({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion!,
        recordId: tempRecordId,
        isComplete: true,
        checklistResponse: submitChecklist,
        checklistState: licenseStatus || '',
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_pathway2_setReview?.caseSummary;
        setDataVersion(casePortalResponse?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.dataVersion);
        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onFormFinishFailed = () => {
    console.log('FORM VALIDATION FAILED');
  };

  const disableChecklist: boolean =
    checklistStatus?.toUpperCase() === 'APPROVED' ||
    checklistStatus?.toUpperCase() === 'REJECTED' ||
    caseSummary?.pathwayEligibilityStatus?.toUpperCase() === 'COMPLETED' ||
    caseSummary?.eligibilityReviewAssigneeId !== sessionStorage.getItem('userIdToken');

  /*review.status = (Approved/Rejected) || pathwayEligibilityStatus = Completed || pathwayReviewAssigneeId != tokenUserId */
  return (
    <Form
      size={'small'}
      form={form}
      onFieldsChange={(changedFields, allFields) => {}}
      onFinish={() => onFormFinish()}
      onFinishFailed={() => onFormFinishFailed()}
      initialValues={{}}
    >
      <div style={{ border: '1px black solid', padding: '15px' }}>
        <Title level={3}>Pathway 2 Review</Title>
        <hr style={{ borderTop: '1px black solid' }} />
        <Row justify="end">
          <Col>
            <Button shape="round" icon={<SendOutlined />} size={'small'}>
              <a href={`mailto:${applicant?.email}?subject=ECFMG%20Pathway%20Application`}>
                {' Contact Applicant'}
              </a>
            </Button>
          </Col>
        </Row>

        <Title level={4}>OSCE Status</Title>
        <Row>
          <Col span={24}>
            <Form.Item
              name="licenseStatus"
              rules={[{ required: true, message: 'Please select an answer' }]}
              initialValue={licenseStatus !== '' ? licenseStatus : null}
            >
              <Select
                onChange={(e: any) => setLicenseStatus(e.toString())}
                disabled={disableChecklist}
              >
                {/* <Option value="osce-review-in-process">OSCE review in process</Option> */}
                {/* <Option value="awaiting-applicant-resolution-osce">
                  Awaiting applicant resolution –OSCE
                </Option> */}
                <Option value="awaiting-attestation-document">Awaiting Attestation Document</Option>
                <Option value="exception-review-escalation-reqired">
                  Exception Review/Escalation Required
                </Option>
                <Option value="sent-for-verification">Sent for Verification</Option>
                <Option value="resent-for-verification">Resent for Verification</Option>
                <Option value="verification-received-in-review">
                  Verification received; In Review
                </Option>
                <Option value="verification-rejected-and-resent">
                  Verification Rejected and Resent
                </Option>
                <Option value="verification-review-complete">Verification review complete</Option>
                <Option value="attestation-received-in-review">
                  Attestation received; In Review
                </Option>
                <Option value="attestation-rejected-and-resent">
                  Attestation Rejected and Resent
                </Option>
                <Option value="attestation-review-complete">Attestation review complete</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Title level={4}>OSCE Review</Title>
        <Row>
          <Col span={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              validateTrigger="onSubmit"
              name="examinationAcceptable"
              rules={[
                { required: true, message: 'Please select an answer' },
                {
                  pattern: new RegExp('^(?!\\s*$).+'),
                  message: 'Please select an answer',
                },
              ]}
              initialValue={examinationAcceptable ?? null}
            >
              <Select
                style={{ width: '70px' }}
                onChange={(e: any) => setExaminationAcceptable(e.toString())}
                disabled={disableChecklist}
              >
                {selectOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col>Medical School matches ECFMG Record  </Col>
        </Row>
        <br />
        <Row>
          <Col span={4}>
            <Form.Item
              name="examinationPassed"
              validateTrigger="onSubmit"
              rules={[
                { required: true, message: 'Please select an answer' },
                {
                  pattern: new RegExp('^(?!\\s*$).+'),
                  message: 'Please select an answer',
                },
              ]}
              initialValue={examinationPassed !== '' ? examinationPassed : null}
            >
              <Select
                style={{ width: '70px' }}
                onChange={(e: any) => setExaminationPassed(e.toString())}
                disabled={disableChecklist}
              >
                {selectOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col>Applicant confirmed as graduate or Attestation received</Col>
        </Row>
        <br />
        <Row>
          <Col span={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              validateTrigger="onSubmit"
              name="examinationAfterJuly1"
              rules={[
                { required: true, message: 'Please select an answer' },
                {
                  pattern: new RegExp('^(?!\\s*$).+'),
                  message: 'Please select an answer',
                },
              ]}
              initialValue={examinationAfterJuly1 !== '' ? examinationAfterJuly1 : null}
            >
              <Select
                style={{ width: '70px' }}
                onChange={(e: any) => setExaminationAfterJuly1(e.toString())}
                disabled={disableChecklist}
              >
                {selectOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col>Graduation date after January 1, 2022</Col>
        </Row>
        <br />
        <Row>
          <Col span={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              validateTrigger="onSubmit"
              name="nameOK"
              rules={[
                { required: true, message: 'Please select an answer' },
                {
                  pattern: new RegExp('^(?!\\s*$).+'),
                  message: 'Please select an answer',
                },
              ]}
              initialValue={nameOK !== '' ? nameOK : null}
            >
              <Select
                style={{ width: '70px' }}
                onChange={(e: any) => setNameOK(e.toString())}
                disabled={disableChecklist}
              >
                {selectOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col>Name OK</Col>
        </Row>
        <br />
        <Row>
          <Col span={4}>
            <Form.Item
              name="osceDocumentCompleteLegible"
              validateTrigger="onSubmit"
              rules={[
                { required: true, message: 'Please select an answer' },
                {
                  pattern: new RegExp('^(?!\\s*$).+'),
                  message: 'Please select an answer',
                },
              ]}
              initialValue={osceDocumentCompleteLegible !== '' ? osceDocumentCompleteLegible : null}
            >
              <Select
                style={{ width: '70px' }}
                onChange={(e: any) => setOsceDocumentCompleteLegible(e.toString())}
                disabled={disableChecklist}
              >
                {selectOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col>Document Complete and Legible</Col>
        </Row>
        <br />
        <Row>
          <Col span={4}>
            <Form.Item
              name="osceTranslation"
              validateTrigger="onSubmit"
              rules={[
                { required: true, message: 'Please select an answer' },
                {
                  pattern: new RegExp('^(?!\\s*$).+'),
                  message: 'Please select an answer',
                },
              ]}
              initialValue={osceTranslation !== '' ? osceTranslation : null}
            >
              <Select
                style={{ width: '70px' }}
                onChange={(e: any) => setOsceTranslation(e.toString())}
                disabled={disableChecklist}
              >
                {selectOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col>Translation meets ECFMG requirements</Col>
        </Row>
        <br />
        <Title level={4}>Verification Review</Title>
        <Row>
          <Col span={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              name="verificationCompleted"
              validateTrigger="onSubmit"
              rules={[
                { required: true, message: 'Please select an answer' },
                {
                  pattern: new RegExp('^(?!\\s*$).+'),
                  message: 'Please select an answer',
                },
              ]}
              initialValue={verificationCompleted !== '' ? verificationCompleted : null}
            >
              <Select
                style={{ width: '70px' }}
                onChange={(e: any) => setVerificationCompleted(e.toString())}
                disabled={disableChecklist}
              >
                {selectOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col>Verification/Attestation Complete</Col>
        </Row>
        <br />
        <Row>
          <Col span={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              name="verificationReturned"
              validateTrigger="onSubmit"
              rules={[
                { required: true, message: 'Please select an answer' },
                {
                  pattern: new RegExp('^(?!\\s*$).+'),
                  message: 'Please select an answer',
                },
              ]}
              initialValue={verificationReturned !== '' ? verificationReturned : null}
            >
              <Select
                style={{ width: '70px' }}
                onChange={(e: any) => setVerificationReturned(e.toString())}
                disabled={disableChecklist}
              >
                {selectOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col>Verification/Attestation Completed by Authorized Official</Col>
        </Row>
        <br />
        <Row>
          <Col span={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              name="passedExamConfirmed"
              validateTrigger="onSubmit"
              rules={[
                { required: true, message: 'Please select an answer' },
                {
                  pattern: new RegExp('^(?!\\s*$).+'),
                  message: 'Please select an answer',
                },
              ]}
              initialValue={passedExamConfirmed !== '' ? passedExamConfirmed : null}
            >
              <Select
                style={{ width: '70px' }}
                onChange={(e: any) => setPassedExamConfirmed(e.toString())}
                disabled={disableChecklist}
              >
                {selectOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col>Official Title Ok</Col>
        </Row>
        <br />
        <Row>
          <Col span={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              name="acceptableDateConfirmed"
              validateTrigger="onSubmit"
              rules={[
                { required: true, message: 'Please select an answer' },
                {
                  pattern: new RegExp('^(?!\\s*$).+'),
                  message: 'Please select an answer',
                },
              ]}
              initialValue={acceptableDateConfirmed !== '' ? acceptableDateConfirmed : null}
            >
              <Select
                style={{ width: '70px' }}
                onChange={(e: any) => setAcceptableDateConfirmed(e.toString())}
                disabled={disableChecklist}
              >
                {selectOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col>Authorized Official Signature Match</Col>
        </Row>
        <br />
        <Row justify="end">
          <Col>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={form.submit}
              disabled={disableChecklist}
            >
              <Button
                type="primary"
                shape="round"
                icon={<CheckOutlined />}
                size={'small'}
                disabled={disableChecklist}
              >
                Complete Review
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        <br />
        <Row justify="end">
          <Col>
            <Space>
              <Button
                shape="round"
                onClick={() => redirect(`/case-management/case-detail/${caseSummary?.usmleId}`)}
              >
                Cancel
              </Button>
              <Button
                shape="round"
                type="primary"
                onClick={onClickSave}
                disabled={disableChecklist}
              >
                Save and Return
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default {
  Component: Pathway2DocumentReviewChecklistV2,
};
