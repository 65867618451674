import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import { FC, useState } from 'react';
import { MedicalSchoolOfficialsSyncAccountFromB2CDocument } from '../../generated';

interface MedicalSchoolOfficialsProps {}
export const MedicalSchoolOfficials: FC<MedicalSchoolOfficialsProps> = (props) => {
  const [form] = Form.useForm();
  const [gqlSyncAccountFromB2C, { loading }] = useMutation(
    MedicalSchoolOfficialsSyncAccountFromB2CDocument
  );
  const[ authorityData, setAuthorityData] = useState<any>('{}');
  const [ inputUsername, setInputUsername] = useState('');

  const [ showSyncOutputModal, setShowSyncOutputModal] = useState(false);
  
  const onCloseSyncOutputModal = () => {
    form.resetFields();
    setAuthorityData(null);
    setInputUsername('');
  };

  const onSyncAccountFromB2C = async (values: any) => {
    setInputUsername(values.username);
    await gqlSyncAccountFromB2C({ variables: { userName: values.username } })
      .then((result) => {
        setAuthorityData(result.data?.CasePortal_AuthorityMgmt_syncAccountFromB2C?.msg);
        setShowSyncOutputModal(true);
        // message.success(result.data?.CasePortal_AuthorityMgmt_syncAccountFromB2C?.msg);
      })
      .catch((ex) => {
        //only grab the first error
        const graphQLErrorReference = ex?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${ex.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  return (
    <>
      <Form onFinish={onSyncAccountFromB2C} form={form} layout="inline">
        <Form.Item
          style={{ width: '300px' }}
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please enter a username' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Refresh
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title={`EMSWP Account Sync Output`}
        visible={showSyncOutputModal}
        closable={false}
        width={1000}
        onCancel={() => {
          setShowSyncOutputModal(false);
          onCloseSyncOutputModal();
        }}
        destroyOnClose
        footer={[
          <Button
            onClick={() => {
              setShowSyncOutputModal(false);
              onCloseSyncOutputModal();
            }}
          >
            Close
          </Button>,
        ]}
      >
        <Row>
          <Col><b>{`Username: ${inputUsername}`}</b></Col>
        </Row>
        <br /><br />
        <Row>
          <Col><pre>{JSON.stringify(JSON.parse(authorityData), null, 2)}</pre></Col>
        </Row>
      </Modal>
    </>
  );
};
