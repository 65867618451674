import { Card, List, Typography } from 'antd';
import { FC } from 'react';
import { ArchivedCasePathway6ReviewType, UiPortalConfigType } from '../../../../generated';
import { CHECKLIST_EVALUATION_MAPPINGS, PATHWAY6_SUBCASE_STATUSES } from '../../../constants';
import { Pathway6ArchivedCaseReviewChecklistMiniCEXEvaluationFactory } from './pathway6-archived-case-review-checklist-versions/pathway6-archived-case-review-checklist-minicex-evaluation-factory';

const { Title, Text } = Typography;

interface Pathway6ArchivedCaseChecklistProps {
  data: ArchivedCasePathway6ReviewType;
  uiConfig: UiPortalConfigType;
}

const getColor = (value: string) => {
  switch (value) {
    case 'Yes':
      return 'green';
    case 'No':
      return 'red';
    default:
      return 'black';
  }
};

export const Pathway6ArchivedCaseChecklist: FC<Pathway6ArchivedCaseChecklistProps> = (props) => {
  const pathway6EvaluationMap = Pathway6ArchivedCaseReviewChecklistMiniCEXEvaluationFactory(
    props.uiConfig
  );

  // Building Checklist Structure and Data
  let tempChecklist = props.data?.checklist as Record<string, string>;
  let pathway6ChecklistData = [
    <Text style={{ fontWeight: 'bold' }} key={1}>
      Status
    </Text>,
    <div key={2}>
      {PATHWAY6_SUBCASE_STATUSES.find((status) => status.key === props.data?.checklistState)?.value}
    </div>,
  ];

  if (tempChecklist) {
    pathway6ChecklistData.push(
      <Text style={{ fontWeight: 'bold' }} key={3}>
        Evaluation
      </Text>
    );
    for (const key in props.data?.checklist) {
      if (!pathway6EvaluationMap?.[key]) {
        continue;
      }

      pathway6ChecklistData.push(
        <table width="100%">
          <tr>
            <td>{pathway6EvaluationMap[key]}</td>
            <td align="right" style={{ color: getColor(tempChecklist[key]) }}>
              {CHECKLIST_EVALUATION_MAPPINGS.find((eva) => eva.key === tempChecklist[key])?.value}
            </td>
          </tr>
        </table>
      );
    }
  } else {
    pathway6ChecklistData.push(
      <Text style={{ fontWeight: 'bold' }} key={3}>
        Evaluation (checklist not completed)
      </Text>
    );
  }

  return (
    <Card
      bodyStyle={{ paddingTop: '5px', paddingBottom: '5px' }}
      style={{
        width: '50%',
        borderRadius: '10px',
        border: '1px solid var(--Neutral-4, #F0F0F0)',
        background: 'var(--Neutral-1, #FFF)',
      }}
      title={
        <Title level={5} style={{ fontWeight: 'bold' }}>
          Pathway 6 Checklist
        </Title>
      }
    >
      <List
        size="small"
        dataSource={pathway6ChecklistData}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </Card>
  );
};
