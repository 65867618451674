import { FC, useState, useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Divider, Spin, Result, Button, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import CaseOverviewSummary from './case-overview-summary';
import CaseOverviewApplicantInformation from './case-overview-applicant-information';
import CaseOverviewActions from './case-overview-actions';
import ActivityFeed from '../activity-feed';
import { useQuery } from '@apollo/client/react';
import { Helmet } from 'react-helmet';
import { case_ } from '../../schema/casePortal-case';
import {
  ActivityLogType,
  CaseMgmtCaseOverviewLayoutCasePortalDocument,
  CaseSummaryType,
  C_ApplicantType,
  PastAttemptsType,
  UiPortalConfigType,
} from '../../../generated';
import ApplicantSummaryFactory from '../pathways/applicant-summary/applicant-summary-factory';

const { Paragraph, Text, Title } = Typography;

export interface ComponentProp {
  casePortal: {
    case: case_;
  };
}

const CaseOverviewLayoutPropsTypes = {
  initialValues: PropTypes.shape({
    case: PropTypes.shape({
      caseSummary: PropTypes.object,
      activityLog: PropTypes.shape({
        timestamp: PropTypes.string,
        logType: PropTypes.string,
        message: PropTypes.string,
        user: PropTypes.arrayOf(
          PropTypes.shape({
            role: PropTypes.string,
            id: PropTypes.string,
            name: PropTypes.string,
          })
        ),
      }),
      applicant: PropTypes.shape({
        email: PropTypes.string,
        dateOfBirth: PropTypes.string,
        country: PropTypes.string,
        gradSchool: PropTypes.string,
        gradYear: PropTypes.string,
        degree: PropTypes.string,
        examHistory: PropTypes.arrayOf(
          PropTypes.shape({
            examName: PropTypes.string.isRequired,
            examDate: PropTypes.string.isRequired,
            result: PropTypes.string.isRequired,
          }).isRequired
        ).isRequired,
        restrictions: PropTypes.arrayOf(
          PropTypes.shape({
            reason: PropTypes.string.isRequired,
            level: PropTypes.string.isRequired,
            startDate: PropTypes.string.isRequired,
            releaseDate: PropTypes.string.isRequired,
            user: PropTypes.string.isRequired,
            comments: PropTypes.string.isRequired,
          }).isRequired
        ).isRequired,
      }),
    }),
  }),
};

export type CaseOverviewLayoutProps = InferProps<typeof CaseOverviewLayoutPropsTypes>;

const CaseOverviewLayout: FC<any> = () => {
  const [dataVersion, setDataVersion] = useState<number | undefined>(undefined);
  let { usmleId } = useParams<any>();

  const { loading, error, data, refetch } = useQuery(CaseMgmtCaseOverviewLayoutCasePortalDocument, {
    variables: { usmleId: usmleId },
  });
  useEffect(() => {
    if (data) {
      setDataVersion(data?.casePortal?.case?.caseSummary?.dataVersion!);
    }
  }, [data]);
  if (loading) {
    return (
      <div style={{ textAlign: 'center', margin: '80px 40px' }}>
        <Spin size="large" />
        <Title level={2}>Loading Case Details</Title>
      </div>
    );
  }
  if (error) {
    console.error('Error2:', error);
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Link to="/case-management">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      >
        <div className="desc">
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              The content you submitted has the following error:
            </Text>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" /> The following
            informaiton may be helpful to resolve this issue
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" />
            Page Name: <b>case-overview-layout:</b>
            <br />
            <br />
            Error Detail: {JSON.stringify(error)}
          </Paragraph>
        </div>
      </Result>
    );
  }

  const onUpdateDataVersionFromChild = async (dataVersion: number) => {
    setDataVersion(dataVersion);
  };

  return (
    <>
      <Helmet>
        <title>Case Detail</title>
      </Helmet>
      <Row>
        <Col span={24}>
          {!!data?.casePortal?.case ? (
            <CaseOverviewSummary.Component initialValues={data?.casePortal?.case as any} />
          ) : null}
        </Col>
        {!!data?.casePortal?.case?.applicant && !!data?.casePortal?.case?.pastAttempts ? (
          <CaseOverviewApplicantInformation.Component
            initialValues={data.casePortal.case.applicant as C_ApplicantType}
            pastAttempts={data.casePortal.case.pastAttempts as PastAttemptsType[]}
            versionConfig={data.casePortal.case.uiConfig as UiPortalConfigType}
          />
        ) : null}
      </Row>
      <Row></Row>
      <Row>
        <Col md={24} lg={11}>
          <Divider plain orientation="center">
            <b>Applicant Provided Information (read only)</b>
          </Divider>
          <ApplicantSummaryFactory usmleID={usmleId} versionConfig={data?.casePortal?.case?.uiConfig}></ApplicantSummaryFactory>
        </Col>
        <Col lg={1}>&nbsp;</Col>
        <Col md={24} lg={12}>
          <br />
          <Title level={3}>Case Actions</Title>
          <hr style={{ borderTop: '1px black solid' }} />
          <br/>
          {!!data?.casePortal?.case?.caseSummary?.usmleId &&
          !!data?.casePortal?.case?.caseSummary ? (
            <CaseOverviewActions.Component
              key={data.casePortal.case.caseSummary.usmleId}
              caseSummary={data.casePortal.case.caseSummary as CaseSummaryType}
            />
          ) : null}
          <Title level={3}>Activity Feed</Title>
          <hr style={{ borderTop: '1px black solid' }} />
          {!!data?.casePortal?.case?.activityLog && !!data?.casePortal?.case?.caseSummary ? (
            <ActivityFeed.Component
              activityLog={data.casePortal.case.activityLog as ActivityLogType[]}
              caseSummary={data.casePortal.case.caseSummary as CaseSummaryType}
              parentDataVersion={dataVersion}
              onUpdateDataVersion={(dataVersion: number) => {
                onUpdateDataVersionFromChild(dataVersion);
              }}
              refetch={async () => {
                refetch();
              }}
            />
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default {
  Component: CaseOverviewLayout,
};
