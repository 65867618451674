import React, { FC } from 'react';
import { Spin, Typography } from 'antd';
import '../../App.css';
import { useQuery } from '@apollo/client/react';
import PhysicianListTable from './physician-list/physician-list-table';
import {physicians} from '../schema/casePortal-physicians';
import {CaseMgmtPhysicianListCasePortalDocument, CaseMgmtPhysicianListCasePortalQuery} from '../../generated';
const { Title } = Typography;

export interface ComponentProp {
  casePortal: {
    physicians: physicians[]
  }
}

export type PhysicianListProps = ComponentProp;


const PhysicianList: FC<any> = (props) => {
  const { loading, data, error } = useQuery(CaseMgmtPhysicianListCasePortalDocument);

  return (
    <>
      {loading ? (
                <div style={{ textAlign: 'center', margin: '80px 40px' }}>
                <Spin size="large" />
                <Title level={2}>Loading...</Title>
              </div>
      ) : (
        <PhysicianListTable.Component physicians={data?.casePortal?.physicians as CaseMgmtPhysicianListCasePortalQuery} />
      )}
    </>
  );
};

export default PhysicianList;
