import { FC, useState, useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  CaseSummaryType,
  Pathway345,
  UiPageConfigType,
  UiPortalConfigType,
} from '../../../../../generated';
import EMSWPAttestationPDFV1 from './emswp-attestation-pdf-versions/emswp-attestation-pdf-v1';
import EMSWPAttestationPDFV2 from './emswp-attestation-pdf-versions/emswp-attestation-pdf-v2';
import EMSWPAttestationPDFV3 from './emswp-attestation-pdf-versions/emswp-attestation-pdf-v3';

const COMPONENT_NAME = 'certification-instructions';
const PAGE_NAME = 'emswp-certification-attestation';

export interface EMSWPAttestationPDFFactoryProps {
  versionConfig?: UiPortalConfigType;
  caseSummary: CaseSummaryType;
  pathway345: Pathway345;
}

export const EMSWPAttestationPDFFactory: FC<EMSWPAttestationPDFFactoryProps> = (props) => {
  const emswpAttestationPDF = () => {
    if (props.versionConfig?.config) {
      //find the page that has the component we want
      const page = props?.versionConfig?.config?.pages?.find((page: any) => {
        return page?.pageName === PAGE_NAME;
      });

      if (page) {
        //find the component we want
        const component = page?.components?.find((component: any) => {
          return component?.componentName === COMPONENT_NAME;
        });
        if (component) {
          switch (component.version) {
            case 1:
              return (
                <EMSWPAttestationPDFV1
                  caseSummary={props.caseSummary}
                  pathway345={props.pathway345}
                />
              );
            case 2:
              return (
                <EMSWPAttestationPDFV2
                  caseSummary={props.caseSummary}
                  pathway345={props.pathway345}
                />
              );
            case 3:
            default:
              return (
                <EMSWPAttestationPDFV3
                  caseSummary={props.caseSummary}
                  pathway345={props.pathway345}
                />
              );
          }
        } else {
          return (
            <EMSWPAttestationPDFV1 caseSummary={props.caseSummary} pathway345={props.pathway345} />
          );
        }
      } else {
        return (
          <EMSWPAttestationPDFV1 caseSummary={props.caseSummary} pathway345={props.pathway345} />
        );
      }
    } else {
      //This means that the certification being loaded up did not have a versionConfig before. Version Config for this component was created April 24, 2023.
      return (
        <EMSWPAttestationPDFV1 caseSummary={props.caseSummary} pathway345={props.pathway345} />
      );
    }
  };

  return <>{emswpAttestationPDF()}</>;
};
