import React, { FC, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Modal, Button, Typography } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import ViewPDFWrapper from '../../../ViewPDF-wrapper';
const { Title } = Typography;
const ReviewExaceptionPropTypes = {
  pathwayX: PropTypes.shape({
    data: PropTypes.shape({
      requestText: PropTypes.string,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          docId: PropTypes.string,
          docType: PropTypes.string,
          title: PropTypes.string,
        })
      ),
    }),
  }),
};

export type ReviewEducationProps = InferProps<typeof ReviewExaceptionPropTypes>;

const QualificationReviewException: FC<ReviewEducationProps> = ({ pathwayX }) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<string | undefined>(undefined);
  const reason = pathwayX?.data?.requestText ?? '';
  const documents = pathwayX?.data?.documents ?? undefined;

  const previewDocument = async (docId: string) => {
    setShowPreviewPdfModal(true);
    setSelectedDoc(docId);
  };

  const viewPdfClosed = async () => {
    setShowPreviewPdfModal(false);
    setSelectedDoc(undefined);
  };

  return (
    <>
      <div>
        <Title level={3}>Exception</Title>
        <div>
          Reason: <p style={{ wordWrap: 'break-word' }}>{reason}</p>
        </div>
        {documents?.map((doc: any) => {
          return (
            <div key={doc.docId}>
              Supporting Documents submitted: &nbsp;
              <Button
                icon={<SearchOutlined />}
                shape="round"
                size="small"
                onClick={() => previewDocument(doc.docId)}
              >
                {doc.title}
              </Button>
              <Modal
                title={doc?.title}
                visible={showPreviewPdfModal && selectedDoc === doc.docId}
                width="fit-content"
                closable
                bodyStyle={{ minWidth: '20vw', maxHeight:'80vh' }}
                okText="Continue"
                footer={null}
                onCancel={() => viewPdfClosed()}
              >
                {showPreviewPdfModal ? (
                  <ViewPDFWrapper filename={doc?.title} docId={doc?.docId}></ViewPDFWrapper>
                ) : (
                  ''
                )}
              </Modal>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default {
  Component: QualificationReviewException,
};
