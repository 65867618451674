import { Tabs } from 'antd';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ArchivedCaseAssessmentsEligibilityContainer } from '../../shared-components/assessments-eligibility/archived-case-assessments-eligibility-container';
import { Pathway6ArchivedCaseAssessmentsMiniCEXInvalidTableContainer } from './assessment-minicex-components/pathway6-archived-case-assessments-minicex-invalid-table-container';
import { Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableContainer } from './assessment-minicex-components/pathway6-archived-case-assessments-minicex-scores-table-container';
import { Pathway6ArchivedCaseAssessmentsMiniCEXValidTableContainer } from './assessment-minicex-components/pathway6-archived-case-assessments-minicex-valid-table-container';
import { ArchivedCaseAssessmentsOETContainer } from '../../shared-components/assessments-oet/archived-case-assessments-oet-container';

const TabPaneName = {
  Eligibility: 'Eligibility',
  OET: 'OET',
  MiniCEX: 'Mini-CEX',
};

const MiniCEXTabPaneName = {
  Valid: 'Valid',
  Invalid: 'Invalid',
  Score: 'Score',
};

interface Pathway6ArchivedCaseAssessmentsProps {}
export const Pathway6ArchivedCaseAssessments: FC<Pathway6ArchivedCaseAssessmentsProps> = (
  props
) => {
  const { caseId, usmleId } = useParams();


  const eligibilityTab = () => (
    <ArchivedCaseAssessmentsEligibilityContainer caseId={caseId ?? ''} />
  );

  const oetTab = () => <ArchivedCaseAssessmentsOETContainer caseId={caseId ?? ''} />;

  const miniCEXTab = () => (
    <Tabs>
      <Tabs.TabPane tab={MiniCEXTabPaneName.Valid} key={MiniCEXTabPaneName.Valid}>
        <Pathway6ArchivedCaseAssessmentsMiniCEXValidTableContainer caseId={caseId ?? ''} usmleId={usmleId ?? ""} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={MiniCEXTabPaneName.Invalid} key={MiniCEXTabPaneName.Invalid}>
        <Pathway6ArchivedCaseAssessmentsMiniCEXInvalidTableContainer caseId={caseId ?? ''}  usmleId={usmleId ?? ""} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={MiniCEXTabPaneName.Score} key={MiniCEXTabPaneName.Score}>
        <Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableContainer caseId={caseId ?? ''}  usmleId={usmleId ?? ""} />
      </Tabs.TabPane>
    </Tabs>
  );

  return (
    <Tabs defaultActiveKey={TabPaneName.Eligibility}>
      <Tabs.TabPane tab={TabPaneName.Eligibility} key={TabPaneName.Eligibility}>
        {eligibilityTab()}
      </Tabs.TabPane>
      <Tabs.TabPane tab={TabPaneName.OET} key={TabPaneName.OET}>
        {oetTab()}
      </Tabs.TabPane>
      <Tabs.TabPane tab={TabPaneName.MiniCEX} key={TabPaneName.MiniCEX}>
        {miniCEXTab()}
      </Tabs.TabPane>
    </Tabs>
  );
};
