import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import ViewPDFToolBar from './ViewPDFToolBar';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export interface ComponentProps {
  filename: string;
  docId: string;
  blobPromise?: Promise<Blob>;
}
// This is used to display PDF on fly from tsx file or link from blob storage
const SummaryPDFViewer = (props: ComponentProps) => {
  const [numPages, setNumPages] = useState(0);
  const [scale, setScale] = useState(1);
  const [rotateAngle, setRotate] = useState(0);
  const [blob, setBlob] = useState<Blob>();

  useEffect(() => {
    if (props.blobPromise) {
      props.blobPromise.then((blob) => {
        setBlob(blob);
      });
    }
  }, [props.blobPromise]);

  const onDocumentLoadSuccess = (numPages: PDFDocumentProxy) => {
    console.log(numPages.numPages);
    setNumPages(numPages.numPages);
  };

  const onScaleChanged = (s: number) => {
    setScale(s);
  };
  return (
    <>
      {props.docId || props.blobPromise ? (
        <>
          <ViewPDFToolBar
            filePath={props.docId}
            filename={props.filename}
            blobData={blob}
            scale={scale}
            onScaleChangedCallback={(scale) => onScaleChanged(scale)}
            rotateAngle={rotateAngle}
            onRotationChangedCallback={(angle) => setRotate(angle)}
          />
          <div style={{overflowY:'auto', height:'70vh'}}>
            <Document
              file={blob ?? props.docId}
              onLoadSuccess={onDocumentLoadSuccess}
              rotate={rotateAngle}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <div style={{ width: '100%', backgroundColor: 'black' }}>
                  <div style={{ textAlign: 'center', marginLeft: '8pt', marginRight: '8pt' }}>
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
                    <br />
                  </div>
                </div>
              ))}
            </Document>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SummaryPDFViewer;
