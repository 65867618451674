import { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  CaseSummaryType,
  C_ApplicantType,
  UiPageConfigType,
  UiPortalConfigType,
} from '../../../../../generated';

import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';
import Pathway3DocumentReviewChecklist from './checklist-versions/pathway3-document-review-checklist';
import Pathway3DocumentReviewChecklistV2 from './checklist-versions/pathway3-document-review-checklist-v2';
import Pathway3DocumentReviewChecklistV3 from './checklist-versions/pathway3-document-review-checklist-v3';
import Pathway3DocumentReviewChecklistV4 from './checklist-versions/pathway3-document-review-checklist-v4';

const ComponentPropTypes = {
    checklist: PropTypes.shape({
      licenseStatus: PropTypes.string.isRequired,
      eligibilityMedicalSchool: PropTypes.string.isRequired,
      eligibilityGradDateEligible: PropTypes.string.isRequired,
      attestationComplete: PropTypes.string.isRequired,
      attestationAuthorizedOfficial: PropTypes.string.isRequired,
      attestationTitleOk: PropTypes.string.isRequired,
      attestationSignatureMatch: PropTypes.string.isRequired,
      attestationSealOk: PropTypes.string.isRequired,
      attestationSignatureDate: PropTypes.string.isRequired,
      attestationSentPrimarySource: PropTypes.string.isRequired,
      attestationCompletedByMedicalSchool: PropTypes.string.isRequired,
    }).isRequired,
    // caseSummary: PropTypes.shape({
    //   usmleId: PropTypes.string.isRequired,
    //   eligibilityReviewAssigneeId: PropTypes.string.isRequired,
    //   pathwayEligibilityStatus: PropTypes.string.isRequired,
    // }),
    checklistState: PropTypes.string.isRequired,
    recordId: PropTypes.string.isRequired,
    parentDataVersion: PropTypes.number,
    onUpdateDataVersion: PropTypes.func.isRequired,
    // applicant: PropTypes.shape({
    //   email: PropTypes.string.isRequired,
    // }),
    redirect: PropTypes.func.isRequired,
    checklistStatus: PropTypes.string.isRequired,
  };

export interface IProps {
  versionConfig: UiPortalConfigType;
  caseSummary: CaseSummaryType;
  applicant: C_ApplicantType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes & IProps>;

const Pathway3DocumentReviewChecklistFactory: FC<ComponentProps> = ({
  versionConfig,
  checklist,
  caseSummary,
  checklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  checklistStatus,
  applicant,
  redirect,
}) => {
  const getPathway3Checklist = () => {
    return versionConfig?.config?.pages.map((page: UiPageConfigType) => {
      if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
        return page?.components?.map((component) => {
          if (component?.componentName === COMPONENT_NAME.PATHWAY345_CHECKLIST) {
            switch (component.version) {
              case 1:
                return (
                  <Pathway3DocumentReviewChecklist
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    checklistStatus={checklistStatus}
                  />
                );
              case 2:
                return (
                  <Pathway3DocumentReviewChecklistV2
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    checklistStatus={checklistStatus}
                  />
                );
              case 3:
                return (
                  <Pathway3DocumentReviewChecklistV3
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    checklistStatus={checklistStatus}
                  />
                );
              
                case 4:
                default:
                  //return the latest version by default
                  return (
                    <Pathway3DocumentReviewChecklistV4
                      caseSummary={caseSummary}
                      checklist={checklist}
                      checklistState={checklistState}
                      recordId={recordId}
                      parentDataVersion={parentDataVersion}
                      onUpdateDataVersion={(dataVersion: number) => {
                        onUpdateDataVersion(dataVersion);
                      }}
                      applicant={applicant}
                      redirect={(url: string) => {
                        redirect(url);
                      }}
                      checklistStatus={checklistStatus}
                    />
                  );
            }
          }
        });
      }
    });
  };

  return <div>{getPathway3Checklist()}</div>;
};

export default Pathway3DocumentReviewChecklistFactory;
