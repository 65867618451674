import { useQuery } from '@apollo/client';
import { FC } from 'react';
import {
  ArchivedCaseAssessmentEligibilityType,
  ArchivedCaseAssessmentsEligibilityContainerArchivedCaseDocument,
} from '../../../../generated';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';
import { ArchivedCaseAssessmentsEligibility } from './archived-case-assessments-eligibility';

interface ArchivedCaseAssessmentsEligibilityContainerProps {
  caseId: string;
}
export const ArchivedCaseAssessmentsEligibilityContainer: FC<ArchivedCaseAssessmentsEligibilityContainerProps> =
  (props) => {
    const {
      data: caseData,
      loading,
      error,
    } = useQuery(ArchivedCaseAssessmentsEligibilityContainerArchivedCaseDocument, {
      variables: { caseId: props.caseId ?? '' },
    });

    return (
      <ComponentQueryLoader
        loading={loading}
        hasData={caseData?.archivedCase !== undefined}
        hasDataComponent={
          <ArchivedCaseAssessmentsEligibility
            data={
              caseData?.archivedCase?.assessments
                ?.eligibility as ArchivedCaseAssessmentEligibilityType
            }
          />
        }
        error={error}
      />
    );
  };
