import { FC, useState } from 'react';
import { Button, Table, Modal, Row, Col } from 'antd';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { InferProps } from 'prop-types';
import moment from 'moment';
import { CEX_STATUS, DATE_FORMAT, WITHDRAW_STATUSES } from '../../constants';
import _ from 'lodash';
import ReviewRejectedMiniCEX from '../../case-management/pathways/pathway6/pathway6-document-review/pathway6-review-rejected-miniCEX';
import { MiniCexResponseType, PhysicianResponseType } from '../../../generated';

import { getMiniCEXPDF } from '../../case-management/pathways/pathway6/pathway6-document-review/pathway6-pdf-helpers';
import ReviewRejectedMiniCEXFactory from '../../case-management/pathways/pathway6/pathway6-document-review/pathway6-review-rejected-miniCEX-factory';

const PhysicianEncounterClosedTablePropTypes = {};

export interface ComponentProps {
  minicexList: MiniCexResponseType[];
  physicianData: PhysicianResponseType;
}

export type PhysicianEncounterClosedTableProps = InferProps<
  typeof PhysicianEncounterClosedTablePropTypes
> &
  ComponentProps;

const PhysicianCompletedTable: FC<PhysicianEncounterClosedTableProps> = ({
  minicexList,
  physicianData,
}) => {
  const [showMiniCEXSummary, setShowMiniCEXSummary] = useState(false);
  const [showEncounterHistoryModal, setShowEncounterHistoryModal] = useState<boolean>(false);
  const [currentRecord, setCurrentRecord] = useState<any>();
  const [showReviewRejectedMiniCEXVisible, setShowReviewRejectedMiniCEXVisible] = useState(false);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'encounterId',
      key: 'encounterId',
      render: (text: any, record: any) => {
        return <>{record.summary.encounterId}</>;
      },
    },
    {
      title: 'USMLE ID',
      dataIndex: 'usmleid',
      key: 'usmleid',
      render: (text: any, record: any) => {
        return <>{record.summary.usmleId}</>;
      },
    },
    {
      title: 'Applicant Last Name',
      dataIndex: 'applicantLastName',
      key: 'applicantLastName',
      render: (text: any, record: any) => {
        return <>{record.summary.applicantLastName}</>;
      },
    },
    {
      title: 'Applicant Rest of Name',
      dataIndex: 'applicantRestOfName',
      key: 'applicantRestOfName',
      render: (text: any, record: any) => {
        return <>{record.summary.applicantRestOfName}</>;
      },
    },

    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      render: (state: any, record: any) => (
        <>
          {record.summary.state !== undefined ? (
            <Row wrap={false} justify="end">
              <Col
                flex="20px"
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
              >
                <InfoCircleOutlined
                  style={{ fontSize: '18px' }}
                  onClick={() => {
                    setCurrentRecord(record);
                    setShowEncounterHistoryModal(true);
                  }}
                />
              </Col>
              <Col flex="auto" offset={1}>
                {getStatus(CEX_STATUS, record.summary.state)}
              </Col>
            </Row>
          ) : null}
        </>
      ),
    },
    {
      title: 'Date Requested',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any, record: any) => (
        <div>
          {record.summary.createdAt !== undefined ? (
            <div>{moment(record.summary.createdAt).utc().format(DATE_FORMAT)}</div>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    {
      title: 'Last Action',
      dataIndex: 'completedAt',
      key: 'completedAt',
      render: (completedAt: any, record: any) => (
        <div>{moment(record.summary.completedAt).utc().format(DATE_FORMAT)}</div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'allowedActions',
      key: 'allowedActions',
      render: (allowedActions: any, record: any, index: any) => renderButtons(record),
    },
  ];

  const historyColumns = [
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      render: (state: any, record: any) => (
        <div>
          {record.state !== undefined ? (
            <div>{getStatus(CEX_STATUS, record?.state) + ' '}</div>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: any, record: any) => (
        <div>
          {record.timestamp !== undefined ? (
            <div>{moment(record.timestamp).utc().format(`${DATE_FORMAT}, h:mm:ss a`)}</div>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
  ];

  const renderButtons = (record: any) => {
    if (
      record?.summary?.allowedActions?.includes('VIEW') &&
      _.indexOf(WITHDRAW_STATUSES, record?.summary?.state) === -1
    ) {
      return (
        <Button
          type="primary"
          style={{ width: '100px', fontSize: '14px' }}
          icon={<SearchOutlined />}
          onClick={() => {
            let tempSeletedMiniCEX = _.find(minicexList, {
              encounterId: record?.summary?.encounterId,
            });
            setCurrentRecord(tempSeletedMiniCEX);
            if (record?.summary?.rejectionReason) {
              // show rejection review
              setShowReviewRejectedMiniCEXVisible(true);
            } else {
              setShowMiniCEXSummary(true);
            }
          }}
        >
          View
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const getStatus = (obj: any, key: any) => {
    return obj[key];
  };

  return (
    <>
      <Table columns={columns} dataSource={minicexList as any} size="middle"></Table>
      <p style={{ fontSize: '11px', marginTop: '10px' }}>
        All times are calculated using Coordinated Universal Time (UTC)
      </p>
      <Modal
        title="Status History"
        visible={showEncounterHistoryModal}
        closable={false}
        footer={[
          <Button key="close" type="primary" onClick={() => setShowEncounterHistoryModal(false)}>
            Close
          </Button>,
        ]}
      >
        Encounter ID: {currentRecord?.encounterId}
        <br />
        <br />
        <Table columns={historyColumns} dataSource={currentRecord?.summary?.stateModel}></Table>
        <p style={{ fontSize: '11px', marginTop: '10px' }}>
          All times are calculated using Coordinated Universal Time (UTC)
        </p>
      </Modal>

      <Modal
        title="Review Completed Mini-CEX"
        visible={showMiniCEXSummary}
        width="fit-content"
        bodyStyle={{ minWidth: '30vw' }}
        onCancel={() => {
          setShowMiniCEXSummary(false);
        }}
        destroyOnClose
        footer={[]}
      >
        {getMiniCEXPDF(currentRecord, physicianData)}
      </Modal>

      <Modal
        title="Review Rejected Mini-CEX"
        visible={showReviewRejectedMiniCEXVisible}
        width={'70%'}
        onCancel={() => {
          setShowReviewRejectedMiniCEXVisible(false);
        }}
        footer={[
          <Button type="primary" onClick={() => setShowReviewRejectedMiniCEXVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <ReviewRejectedMiniCEXFactory miniCEX={currentRecord} physician={physicianData} />
      </Modal>
    </>
  );
};
export default PhysicianCompletedTable;
