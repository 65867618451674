import { Table, Tabs } from 'antd';
import { FC, Key, useState } from 'react';
import { ArchivedCaseOetReviewType, ArchivedCaseReviewsType, UiPortalConfigType } from '../../../../generated';
import { Pathway1ArchivedCaseChecklist } from './pathway1-archived-case-checklist';
import { Pathway1ArchivedCaseInfoCard } from './pathway1-archived-case-info-card';
import ArchivedCaseOetReview from '../../shared-components/oet/archived-case-oet-review';

const TabPaneName = {
  Pathway1: 'Pathway 1',
  OET: 'OET',
};

interface Pathway1ArchivedCaseReviewProps {
  data: ArchivedCaseReviewsType;
  uiConfig: UiPortalConfigType
}
export const Pathway1ArchivedCaseReview: FC<Pathway1ArchivedCaseReviewProps> = (props) => {
  const [currentPathwayLicense, setCurrentPathwayLicense] = useState(
    props.data.pathway1 ? props.data.pathway1[0] : null
  );
  const [currentRowKeys, setCurrentRowKeys] = useState([currentPathwayLicense?.summary?._id]);

  const rowSelection = {
    selectedRowKeys: currentRowKeys as Key[],
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setCurrentRowKeys(selectedRowKeys);
      setCurrentPathwayLicense(selectedRows[0]);
    },
  };

  const tableData = props?.data?.pathway1?.map((entry: any) => ({
    key: entry?.summary?._id,
    title: entry?.summary?.title,
    status: entry?.summary?.status,
    name: entry?.summary?.updatedBy?.name
  }));

  const pathwayTableColumns = [
    {
      title: 'ID',
      dataIndex: 'key',
      key: 'name',
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Assigned Case Manager',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const pathway1Tab = (
    <Tabs.TabPane tab={TabPaneName.Pathway1} key={TabPaneName.Pathway1}>
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        columns={pathwayTableColumns}
        dataSource={tableData}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Pathway1ArchivedCaseChecklist data={currentPathwayLicense ?? {}} uiConfig={props.uiConfig}/>
        <Pathway1ArchivedCaseInfoCard data={currentPathwayLicense ?? {}} />
      </div>
    </Tabs.TabPane>
  );

  const OetTab = (
    <Tabs.TabPane tab={TabPaneName.OET} key={TabPaneName.OET}>
      <ArchivedCaseOetReview data={props.data.oet as ArchivedCaseOetReviewType[]} />
    </Tabs.TabPane>
  );

  return (
    <>
      <Tabs defaultActiveKey="1">
        {pathway1Tab}
        {OetTab}
      </Tabs>
    </>
  );
};
