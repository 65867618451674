import { Descriptions, Table, Tabs } from 'antd';
import { FC, useState } from 'react';
import { ArchivedCaseApplicantType, RestrictionType } from '../../../../generated';
import dayjs from 'dayjs';

const { TabPane } = Tabs;

interface ArchivedCaseApplicantDetailsProps {
  data: ArchivedCaseApplicantType;
}

const ArchivedCaseApplicantDetails: FC<ArchivedCaseApplicantDetailsProps> = (props) => {
  const { data } = props;
  const [activeTab, setActiveTab] = useState('graduation');

  const examHistoryColumns = [
    {
      title: 'Exam Name',
      dataIndex: 'examName',
      key: 'examName',
    },
    {
      title: 'Exam Date',
      dataIndex: 'examDate',
      key: 'examDate',
      render: (text: string) => (text ? dayjs(text).format('MM/DD/YYYY') : 'N/A'),
    },
    {
      title: 'Result',
      dataIndex: 'result',
      key: 'result',
    },
  ];

  const restrictionsColumns = [
    {
      title: 'Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text: string) => (text ? dayjs(text).format('MM/DD/YYYY') : 'N/A'),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: 'Release Date',
      dataIndex: 'releaseDate',
      key: 'releaseDate',
      render: (text: string) => (text ? dayjs(text).format('MM/DD/YYYY') : 'N/A'),
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
    },
  ];

  return (
    <>
      <Descriptions
        className={'ecfmg-small-descriptions'}
        layout="vertical"
        bordered={false}
        column={4}
        size="small"
        style={{ paddingBottom: '30px' }}
      >
        <Descriptions.Item label="First Name">{data.profile?.firstName}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{data.profile?.lastName}</Descriptions.Item>
        <Descriptions.Item label="Date of Birth">
          {data.profile?.dateOfBirth
            ? dayjs(data.profile?.dateOfBirth).format('MM/DD/YYYY')
            : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{data.profile?.email}</Descriptions.Item>
      </Descriptions>
      <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        <TabPane tab="Graduation" key="graduation">
          <Descriptions
            className={'ecfmg-small-descriptions'}
            layout="vertical"
            bordered={false}
            size="small"
            column={3}
          >
            <Descriptions.Item label="Year">{data.graduation?.year}</Descriptions.Item>
            <Descriptions.Item label="Degree">{data.graduation?.degree}</Descriptions.Item>
            <Descriptions.Item label="Cred Status Code">
              {data.graduation?.credStatusCode}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            className={'ecfmg-small-descriptions'}
            layout="vertical"
            bordered={false}
            size="small"
            column={3}
          >
            <Descriptions.Item label="School">{data.graduation?.school}</Descriptions.Item>
            <Descriptions.Item label="Country">{data.graduation?.country}</Descriptions.Item>
            <Descriptions.Item label="Cred Status Comments">
              {data.graduation?.credComments}
            </Descriptions.Item>
          </Descriptions>
        </TabPane>

        <TabPane tab="Exam History" key="examHistory">
          <Table
            columns={examHistoryColumns}
            dataSource={data.examHistory as RestrictionType[]}
            bordered
            pagination={false}
          />
        </TabPane>
        <TabPane tab="Restrictions" key="restrictions">
          <Table
            columns={restrictionsColumns}
            dataSource={data.restrictions as RestrictionType[]}
            bordered
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ArchivedCaseApplicantDetails;
