import React, { FC, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import '../../App.css';
import PhysicianLeftNav from './physician-left-nav';
import PhysicianList from './physician-list';
import PhysicianDetail from './physician-detail/physician-detail';
import PhysicianHeader from './physician-header';
import { Helmet } from 'react-helmet';
import FooterLayout from '../footer-layout';

const { Content, Footer } = Layout;

const LayoutOetSubmissions: FC<any> = (props) => {
  const location = useLocation();
  const [candidateId, setCandidateId] = useState<any>(undefined);

  return (
    <>
      <Helmet>
        <title>Physician List</title>
      </Helmet>
      <Layout className="layout">
        <PhysicianHeader.Component />
        <Layout style={{ marginTop: '64px' }} hasSider>
          <PhysicianLeftNav />
          <Layout
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 auto',
              height: 'calc(100vh - 64px)',
              overflowY: 'scroll',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 auto',
              }}
            >
              <Content
                //className="site-layout"
                style={{ margin: '0 16px 0', minHeight: 'inherit' }}
              >
                <div
                  className="site-layout-background"
                  style={{
                    // flex: '1 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                    borderRadius: '0 0 0.8rem 0.8rem',
                    background: '#FFFFFF',
                    padding: '20px',
                  }}
                >
                  <Routes>
                    <Route path={'/'} element={<PhysicianList />} />
                    <Route
                      path={`/physician-detail/:physicianEmail`}
                      element={<PhysicianDetail {...props} key={candidateId} />}
                    />
                  </Routes>
                </div>
              </Content>
              <FooterLayout />
            </div>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default LayoutOetSubmissions;
