import { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  CaseSummaryType,
  C_ApplicantType,
  UiPageConfigType,
  UiPortalConfigType,
} from '../../../../../generated';
import Pathway1DocumentReviewChecklist from './checklist-versions/pathway1-document-review-checklist';
import Pathway1DocumentReviewChecklistV2 from './checklist-versions/pathway1-document-review-checklist-v2';
import Pathway1DocumentReviewChecklistV3 from './checklist-versions/pathway1-document-review-checklist-v3';
import Pathway1DocumentReviewChecklistV4 from './checklist-versions/pathway1-document-review-checklist-v4';
import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';
import Pathway1DocumentReviewChecklistV5 from './checklist-versions/pathway1-document-review-checklist-v5';

const ComponentPropTypes = {
  checklist: PropTypes.shape({
    licensureUnrestrictedLicense: PropTypes.string,
    licensureIssuedAppropriateAuthority: PropTypes.string,
    licensureApplicantLicenseOnPoint: PropTypes.string,
    licensureDisciplinaryAction: PropTypes.string,
    licensureNameValidation: PropTypes.string,
    licensureCompleteLegible: PropTypes.string,
    licensureTranslationStatus: PropTypes.string,
    verificationAuthorized: PropTypes.string,
    verificationPrimarySource: PropTypes.string,
    verificationActiveLicense: PropTypes.string,
    verificationGoodStanding: PropTypes.string,
  }).isRequired,
  checklistMetaData: PropTypes.shape({
    regulatoryAuthorityRef: PropTypes.shape({
      orgName: PropTypes.string,
      countryName: PropTypes.string,
      _id: PropTypes.string,
    }),
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        docId: PropTypes.string,
        docType: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  }),
  checklistState: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  parentDataVersion: PropTypes.number,
  onUpdateDataVersion: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  checklistStatus: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export interface IProps {
  versionConfig: UiPortalConfigType;
  caseSummary: CaseSummaryType;
  applicant: C_ApplicantType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes & IProps>;

const Pathway1DocumentReviewChecklistFactory: FC<ComponentProps> = ({
  versionConfig,
  checklist,
  caseSummary,
  checklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  checklistStatus,
  applicant,
  redirect,
  refetch,
  checklistMetaData,
}) => {
  const getPathway1Checklist = () => {
    return versionConfig?.config?.pages.map((page: UiPageConfigType) => {
      if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
        return page?.components?.map((component) => {
          if (component?.componentName === COMPONENT_NAME.PATHWAY1_CHECKLIST) {
            switch (component.version) {
              case 1:
                return (
                  <Pathway1DocumentReviewChecklist
                    key={recordId}
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    checklistStatus={checklistStatus}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    refetch={() => {
                      refetch();
                    }}
                    checklistMetaData={checklistMetaData}
                  />
                );
              case 2:
                return (
                  <Pathway1DocumentReviewChecklistV2
                    key={recordId}
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    checklistStatus={checklistStatus}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    refetch={() => {
                      refetch();
                    }}
                    checklistMetaData={checklistMetaData}
                  />
                );
              case 3:
                return (
                  <Pathway1DocumentReviewChecklistV3
                    key={recordId}
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    checklistStatus={checklistStatus}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    refetch={() => {
                      refetch();
                    }}
                    checklistMetaData={checklistMetaData}
                  />
                );

              case 4:
                return (
                  <Pathway1DocumentReviewChecklistV4
                    key={recordId}
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    checklistStatus={checklistStatus}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    refetch={() => {
                      refetch();
                    }}
                    checklistMetaData={checklistMetaData}
                  />
                );

              case 5:
              default:
                //return the latest version by default
                return (
                  <Pathway1DocumentReviewChecklistV5
                    key={recordId}
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    checklistStatus={checklistStatus}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    refetch={() => {
                      refetch();
                    }}
                    checklistMetaData={checklistMetaData}
                  />
                )
            }
          }
        });
      }
    });
  };

  return <div>{getPathway1Checklist()}</div>;
};

export default Pathway1DocumentReviewChecklistFactory;
