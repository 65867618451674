export const Pathway2ReviewChecklistOSCEV1: Record<string, string> = {
  osceExaminationAcceptable: "Examination acceptable (2A) or Medical School Acceptable (2B)",
  osceExaminationPassed: "Examination passed (2A) or Applicant confirmed as graduate (2B-G) or Attestation received (2B-S)",
  osceExaminationAfterJuly1: "Date of examination on or after January 1, 2018 (2A) or graduation date after January 1, 2018 (2B)",
  osceNameOK: "Name OK",
  osceDocumentCompleteLegible: "Document Complete and Legible",
  osceTranslation: "Translation meets ECFMG requirements",
}


export const Pathway2ReviewChecklistVerificationV1: Record<string, string> = {
  verificationCompleted: "Verification/Attestation completed by authorized official",
  verificationReturned: "Verification/Attestation returned from primary source",
  verificationPassedExamConfirmed: "Confirms applicant passed exam (2A) or Confirms applicant graduated (2B-G) or Attestation completed (2B-S)",
  verificationAcceptableDateConfirmed: "Confirms date acceptable",
}

export const Pathway2ReviewChecklistOSCEV2: Record<string, string> = {
  osceExaminationAcceptable: "Medical School Acceptable",
  osceExaminationPassed: "Applicant confirmed as graduate or Attestation received",
  osceExaminationAfterJuly1: "Graduation date after January 1, 2021",
  osceNameOK: "Name OK",
  osceDocumentCompleteLegible: "Document Complete and Legible",
  osceTranslation: "Translation meets ECFMG requirements",
}


export const Pathway2ReviewChecklistVerificationV2: Record<string, string> = {
  verificationCompleted: "Verification/Attestation completed by authorized official",
  verificationReturned: "Verification/Attestation returned from primary source",
  verificationPassedExamConfirmed: "Confirms applicant graduated or Attestation completed",
  verificationAcceptableDateConfirmed: "Confirms date acceptable",
}


export const Pathway2ReviewChecklistOSCEV3: Record<string, string> = {
  osceExaminationAcceptable: "Medical School matches ECFMG Record",
  osceExaminationPassed: "Applicant confirmed as graduate or Attestation received",
  osceExaminationAfterJuly1: "Graduation date after January 1, 2022",
  osceNameOK: "Name OK",
  osceDocumentCompleteLegible: "Document Complete and Legible",
  osceTranslation: "Translation meets ECFMG requirements",
}


export const Pathway2ReviewChecklistVerificationV3: Record<string, string> = {
  verificationCompleted: "Verification/Attestation Complete",
  verificationReturned: "Verification/Attestation Completed by Authorized Official",
  verificationPassedExamConfirmed: "Official Title Ok",
  verificationAcceptableDateConfirmed: "Authorized Official Signature Match",
}

export const Pathway2ReviewChecklistOSCEV4: Record<string, string> = {
  osceExaminationAcceptable: "Medical School matches ECFMG Record",
  osceExaminationPassed: "Applicant confirmed as graduate or Attestation received",
  osceExaminationAfterJuly1: "Graduation date after January 1, 2023",
  osceNameOK: "Name OK",
  osceDocumentCompleteLegible: "Document Complete and Legible",
  osceTranslation: "Translation meets ECFMG requirements",
}

export const Pathway2ReviewChecklistVerificationV4: Record<string, string> = {
  verificationCompleted: "Verification/Attestation Complete",
  verificationReturned: "Verification/Attestation Completed by Authorized Official",
  verificationPassedExamConfirmed: "Official Title Ok",
  verificationAcceptableDateConfirmed: "Authorized Official Signature Match",
}
