import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Alert, Spin } from 'antd';
import PhysicianDashboardActiveTable from './pathway6-miniCEX-active-table';
import PhysicianDashboardClosedTable from './pathway6-miniCEX-inactive-table';
import Pathway6MiniCEXScoreTable from './pathway6-miniCEX-score-table';
import PropTypes, { InferProps } from 'prop-types';
import { PATHWAY6_RESPONSE_TYPE } from '../../../../constants';
import {
  MiniCexResponseType,
  PhysicianResponseType,
  Pathway6MiniCexType,
  UiConfigRecordType,
} from '../../../../../generated';

const { TabPane } = Tabs;

const TABS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  SCORES: 'Scores',
};

const Pathway6TableLayoutPropsTypes = {
  updateResponseType: PropTypes.func.isRequired,
};

export interface ComponentProps {
  minicexList: MiniCexResponseType[];
  physicianByMiniCEX: PhysicianResponseType[];
  applicantProvidedData: Pathway6MiniCexType[];
  uiConfig: UiConfigRecordType;
}

export type Pathway6TableLayoutProps = InferProps<typeof Pathway6TableLayoutPropsTypes> &
  ComponentProps;

const Pathway6TableLayout: FC<Pathway6TableLayoutProps> = ({
  minicexList,
  physicianByMiniCEX,
  applicantProvidedData,
  uiConfig,
  updateResponseType,
}) => {
  const [activeTab, setActiveTab] = useState<string>(TABS.ACTIVE);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const onTabChange = (e: any) => {
    switch (e) {
      case TABS.ACTIVE:
        updateResponseType(PATHWAY6_RESPONSE_TYPE.ACTIVE);
        break;
      case TABS.INACTIVE:
        updateResponseType(PATHWAY6_RESPONSE_TYPE.ARCHIVED);
        break;
      case TABS.SCORES:
        updateResponseType(PATHWAY6_RESPONSE_TYPE.ACTIVE);
        break;
    }
    setActiveTab(e);
  };

  return (
    <>
      {showAlert && <Alert message={alertText} type="error" closable showIcon></Alert>}

      <Tabs
        activeKey={activeTab}
        onChange={(e: any) => {
          onTabChange(e);
        }}
      >
        <TabPane tab={TABS.ACTIVE} key={TABS.ACTIVE}>
          {minicexList && physicianByMiniCEX ? (
            <PhysicianDashboardActiveTable
              minicexList={minicexList}
              physicianByMiniCEX={physicianByMiniCEX}
            />
          ) : (
            <Spin />
          )}
        </TabPane>
        <TabPane tab={TABS.INACTIVE} key={TABS.INACTIVE}>
          {minicexList && physicianByMiniCEX ? (
            <PhysicianDashboardClosedTable
              minicexList={minicexList}
              physicianByMiniCEX={physicianByMiniCEX}
            />
          ) : (
            <Spin />
          )}
        </TabPane>
        <TabPane tab={TABS.SCORES} key={TABS.SCORES}>
          {minicexList && applicantProvidedData && physicianByMiniCEX ? (
            <Pathway6MiniCEXScoreTable
              minicexList={minicexList}
              applicantProvidedData={applicantProvidedData}
              physicianByMiniCEX={physicianByMiniCEX}
            />
          ) : (
            <Spin />
          )}
        </TabPane>
      </Tabs>
    </>
  );
};

export default {
  Component: Pathway6TableLayout,
};
