import { useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import {
  MiniCexResponseType,
  Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerArchivedCaseDocument,
  Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerPhysicianByMiniCexDocument,
  PhysicianResponseType,
} from '../../../../../generated';
import { ComponentQueryLoader } from '../../../../../utils/molecules/component-query-loader';
import { PATHWAY6_RESPONSE_TYPE, getPhysicianName } from '../../../../constants';
import {
  Pathway6ArchivedCaseAssessmentsMiniCEXValidTable,
  Pathway6ArchivedCaseAssessmentsMiniCEXValidTableDataType,
} from './pathway6-archived-case-assessments-minicex-valid-table';

interface Pathway6ArchivedCaseAssessmentsMiniCEXValidTableContainerProps {
  caseId: string;
  usmleId: string;
}
export const Pathway6ArchivedCaseAssessmentsMiniCEXValidTableContainer: FC<Pathway6ArchivedCaseAssessmentsMiniCEXValidTableContainerProps> =
  (props) => {
    const [tableData, setTableData] = useState<
      Pathway6ArchivedCaseAssessmentsMiniCEXValidTableDataType[]
    >([]);

    const {
      data: miniCEXData,
      loading,
      error,
    } = useQuery(Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerArchivedCaseDocument, {
      variables: {
        caseId: props.caseId ?? '',
        filter: { caseId: props.caseId ?? '', responseType: PATHWAY6_RESPONSE_TYPE.ACTIVE },
      },
    });

    const {
      loading: physicianLoading,
      error: physicianError,
      data: physicianData,
    } = useQuery(
      Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerPhysicianByMiniCexDocument,
      {
        variables: {
          filter: {
            caseId: props.caseId as string,
            responseType: PATHWAY6_RESPONSE_TYPE.ACTIVE,
          },
        },
      }
    );

    useEffect(() => {
      if (miniCEXData && physicianData) {
        getTableData();
      }
    }, [miniCEXData, physicianData]);

    const getPhysicianNameByEmail = (email: any) => {
      var currentPhysicianRecord: any = physicianData?.physiciansByArchivedMinicex?.find((item) => item?.email === email);
  
      return getPhysicianName(
        currentPhysicianRecord?.profile?.lastName,
        currentPhysicianRecord?.profile?.restOfName
      );
    };

    const getTableData = () => {
      const data = miniCEXData?.archivedCase?.assessments?.minicex?.minicexList?.map((item) => {
        return {
          id: item?.summary?.encounterId,
          // nameFromPhysician: getPhysicianName(
          //   item?.summary?.physicianLastNameProvidedByPhysician,
          //   item?.summary?.physicianRestOfNameProvidedByPhysician
          // ),
          nameFromPhysician: getPhysicianNameByEmail(item?.summary?.physicianEmail),
          dateRequested: item?.summary?.createdAt as string,
          actionDue: item?.summary?.expiresAt as string,
          status: item?.summary?.state as string,
          rejectionReason: item?.summary?.rejectionReason as string,
          physicianEmail: item?.summary?.physicianEmail as string,
        };
      });
      setTableData(data as Pathway6ArchivedCaseAssessmentsMiniCEXValidTableDataType[]);
    };

    return (
      <ComponentQueryLoader
        loading={loading || physicianLoading}
        hasData={miniCEXData?.archivedCase?.assessments?.minicex?.minicexList}
        hasDataComponent={
          <Pathway6ArchivedCaseAssessmentsMiniCEXValidTable
            tableData={tableData}
            minicexList={
              miniCEXData?.archivedCase?.assessments?.minicex?.minicexList as MiniCexResponseType[]
            }
            physicianByMiniCEX={
              physicianData?.physiciansByArchivedMinicex as PhysicianResponseType[]
            }
          />
        }
        errorComponent={
          <>
            {physicianError?.message ?? <div>{physicianError?.message}</div>}
            {error?.message ?? <div>{error?.message}</div>}
          </>
        }
        error={error}
      />
    );
  };
