import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import moment from 'moment';
import { InferProps } from 'prop-types';
import { FC } from 'react';
import { MiniCexResponseType, PhysicianResponseType } from '../../../../../generated';
import {
  convertDateFromTZTime,
  EncounterSettings,
  EvaluationCategory,
  formatMonths,
  getPhysicianName,
  GetScoreCategory,
  PATHWAY6_DATE_FORMAT,
} from '../../../../constants';

// Reference fonts:
// 'Courier',
// 'Courier-Bold',
// 'Courier-Oblique',
// 'Courier-BoldOblique',
// 'Helvetica',
// 'Helvetica-Bold',
// 'Helvetica-Oblique',
// 'Helvetica-BoldOblique',
// 'Times-Roman',
// 'Times-Bold',
// 'Times-Italic',
// 'Times-BoldItalic',
// 'Symbol',
// 'ZapfDingbats',

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 15,
    margin: 2,
  },
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 18,
    marginBottom: 15,
  },

  sectionTitle: { fontFamily: 'Helvetica-Bold', fontSize: 12 },

  sectionSubTitle: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 11,

    marginBottom: 5,
  },

  sectionSubTitleMiniCEX: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 11,
    marginBottom: 5,
    marginTop: 5,
  },

  label: {
    fontFamily: 'Times-Roman',
    fontSize: 8,
  },

  content: {
    fontSize: 12,
  },

  horizontalLine: {
    borderTop: 1,
    borderColor: 'lightgrey',
    height: 1,
    marginBottom: 5,
    marginTop: 2,
  },

  table: {
    display: 'flex',
    width: '100%',
    marginBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
  },

  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    paddingLeft: 10,
    marginBottom: 10,
  },

  tableCell: {
    fontSize: 10,
  },
});

const Section: FC<any> = (props) => {
  return (
    <>
      <Text style={styles.sectionTitle}>{props.title}</Text>
      <View style={styles.horizontalLine}></View>
    </>
  );
};

const InfoGroup: FC<any> = (props) => {
  return (
    <>
      <Text style={styles.label}>{props.label}</Text>
      <Text style={styles.content}>{props.content}</Text>
    </>
  );
};

const MiniCEXPDFPropsPropTypes = {};

export interface ComponentProps {
  currentPhysicianData?: PhysicianResponseType;
  readOnlyMiniCEXData?: MiniCexResponseType;
}

export type MiniCEXPDFProps = InferProps<typeof MiniCEXPDFPropsPropTypes> & ComponentProps;

const MiniCEXPDFV3: FC<MiniCEXPDFProps> = ({ readOnlyMiniCEXData, currentPhysicianData }) => {
  const getAdditionalFeedbackArea = () => {
    if (readOnlyMiniCEXData?.form?.evaluation?.additionalInfoText) {
      return (
        <>
          <Text style={styles.sectionSubTitleMiniCEX}>Additional Feedback:</Text>
          <View style={styles.tableRow}>
            <View style={{ width: '100%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.label}>
                  {readOnlyMiniCEXData.form.evaluation.additionalInfoText}
                </Text>
              </View>
            </View>
          </View>
        </>
      );
    }
    return null;
  };
  return (
    <>
      {readOnlyMiniCEXData ? (
        <Document title={`Mini-CEX Summary-${readOnlyMiniCEXData?.summary?.encounterId}`}>
          <Page size="A4" style={styles.body}>
            <Text style={styles.title}>Mini-CEX Summary</Text>
            {/* Applicant details */}
            <Section title={'Applicant Details'} />
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{ width: '60%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'Applicant:'}
                      content={`${readOnlyMiniCEXData?.summary?.applicantLastName}, ${readOnlyMiniCEXData?.summary?.applicantRestOfName}`}
                    />
                  </View>
                </View>
                <View style={{ width: '40%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'Encounter ID:'}
                      content={readOnlyMiniCEXData.summary?.encounterId}
                    />
                  </View>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={{ width: '60%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup label={'USMLEID:'} content={readOnlyMiniCEXData.summary?.usmleId} />
                  </View>
                </View>
                <View style={{ width: '40%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'Date Requested:'}
                      content={
                        readOnlyMiniCEXData?.summary?.createdAt
                          ? moment(readOnlyMiniCEXData?.summary?.createdAt)
                              .utc()
                              .format(PATHWAY6_DATE_FORMAT)
                          : 'N/A'
                      }
                    />
                  </View>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={{ width: '60%' }}>
                  <View style={styles.tableCell}></View>
                </View>
                <View style={{ width: '40%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'Date Accepted:'}
                      content={
                        readOnlyMiniCEXData?.summary?.acceptedAt
                          ? moment(readOnlyMiniCEXData?.summary?.acceptedAt)
                              .utc()
                              .format(PATHWAY6_DATE_FORMAT)
                          : 'N/A'
                      }
                    />
                  </View>
                </View>
              </View>
            </View>
            {/* Physician Evaluator Details */}
            <Section title={'Physician Evaluator Details'} />
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{ width: '60%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'Name:'}
                      content={getPhysicianName(
                        currentPhysicianData?.profile?.lastName,
                        currentPhysicianData?.profile?.restOfName
                      )}
                    />
                  </View>
                </View>
                <View style={{ width: '40%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup label={'Primary E-mail:'} content={currentPhysicianData?.email} />
                  </View>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={{ width: '100%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'Date of Birth:'}
                      content={
                        currentPhysicianData?.profile?.dateOfBirth
                          ? moment(currentPhysicianData?.profile?.dateOfBirth).format(
                              PATHWAY6_DATE_FORMAT
                            )
                          : 'N/A'
                      }
                    />
                  </View>
                </View>
              </View>
              {/* Licenses are not on PDF => Commented out just in case need it in future */}
              {/* <Text style={styles.sectionSubTitle}>Licensure Information:</Text>
          {readOnlyMiniCEXData.summary.physicianLicensureInfo.map((li) => {
            return (
              <View style={styles.tableRow}>
                <View style={{ width: '50%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup label={'Authority:'} content={li.orgName} />
                  </View>
                </View>
                <View style={{ width: '50%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'License / Registration Number:'}
                      content={li.licenseNumber}
                    />
                  </View>
                </View>
              </View>
            );
          })} */}
            </View>
            {/* Encounter */}
            <Section title={'Encounter'} />
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{ width: '60%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'Date of Encounter:'}
                      content={convertDateFromTZTime(
                        readOnlyMiniCEXData?.form?.encounter?.encounterDate!
                      )}
                    />
                  </View>
                </View>
                <View style={{ width: '40%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'Clinical Setting:'}
                      content={
                        _.find(EncounterSettings, {
                          key: readOnlyMiniCEXData?.form?.encounter?.encounterSetting!,
                        })?.value ?? 'N/A'
                      }
                    />
                  </View>
                </View>
              </View>

              <Text style={styles.sectionSubTitle}>
                Facility / Hospital at which encounter took place:
              </Text>
              <View style={styles.tableRow}>
                <View style={{ width: '60%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'Name:'}
                      content={readOnlyMiniCEXData?.form?.encounter?.facility?.name}
                    />
                  </View>
                </View>
                <View style={{ width: '40%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'State/Province:'}
                      content={readOnlyMiniCEXData?.form?.encounter?.facility?.state}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ width: '60%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'Country:'}
                      content={readOnlyMiniCEXData?.form?.encounter?.facility?.country?.name}
                    />
                  </View>
                </View>
                <View style={{ width: '40%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'City:'}
                      content={readOnlyMiniCEXData?.form?.encounter?.facility?.city}
                    />
                  </View>
                </View>
              </View>

              <Text style={styles.sectionSubTitle}>Patient:</Text>
              <View style={styles.tableRow}>
                <View style={{ width: '10%' }}>
                  <View style={styles.tableCell}>
                    <Text style={{ fontSize: 11 }}>Age:</Text>
                    <InfoGroup
                      label={'Years:'}
                      content={readOnlyMiniCEXData?.form?.encounter?.patient?.ageYear}
                    />
                  </View>
                </View>
                <View style={{ width: '50%' }}>
                  <View style={styles.tableCell}>
                    <Text style={{ fontSize: 11 }}> </Text>
                    <InfoGroup
                      label={'Months:'}
                      content={
                        readOnlyMiniCEXData?.form?.encounter?.patient?.ageMonth
                          ? formatMonths(readOnlyMiniCEXData?.form?.encounter?.patient?.ageMonth)
                          : 'N/A'
                      }
                    />
                  </View>
                </View>
                <View style={{ width: '40%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'Gender:'}
                      content={readOnlyMiniCEXData?.form?.encounter?.patient?.gender}
                    />
                  </View>
                </View>
              </View>
            </View>

            {/* Mini-CEX Responses */}
            <Section title={'Mini-CEX Responses'} />
            <View style={styles.table}>
              {readOnlyMiniCEXData?.form?.evaluation?.categories &&
              readOnlyMiniCEXData?.form?.evaluation?.categories.length > 0 ? (
                readOnlyMiniCEXData?.form?.evaluation?.categories?.map((cate) => {
                  return (
                    <View key={cate?.name!}>
                      <Text style={styles.sectionSubTitleMiniCEX}>
                        {EvaluationCategory[cate?.name!]}:
                      </Text>
                      <View style={styles.tableRow}>
                        <View style={{ width: '10%' }}>
                          <View style={styles.tableCell}>
                            <InfoGroup label={'Score:'} content={cate?.score} />
                          </View>
                        </View>
                        <View style={{ width: '90%' }}>
                          <View style={styles.tableCell}>
                            <InfoGroup label={'Level:'} content={GetScoreCategory(cate?.score!)} />
                          </View>
                        </View>
                      </View>

                      {cate?.reasonText ? (
                        <>
                          <View style={styles.tableCell}>
                            <Text style={[styles.label, { paddingLeft: 5 }]}>
                              Observations made during the clinical encounter that led to the score:
                            </Text>
                          </View>
                          <View style={styles.tableRow}>
                            <View style={{ width: '100%' }}>
                              <View style={styles.tableCell}>
                                <Text style={styles.label}>{cate.reasonText}</Text>
                              </View>
                            </View>
                          </View>
                        </>
                      ) : null}
                    </View>
                  );
                })
              ) : (
                <View style={{ marginTop: 10, marginBottom: 5, marginLeft: 10 }}>
                  <InfoGroup label={''} content={'N/A'} />
                </View>
              )}

              {getAdditionalFeedbackArea()}
            </View>
            <Section title="" />
            {readOnlyMiniCEXData?.summary?.acceptedAt ? (
              <View style={{ marginTop: 10, marginBottom: 5, marginLeft: 20 }}>
                <InfoGroup
                  label={'Request Accepted On:'}
                  content={
                    readOnlyMiniCEXData.summary.acceptedAt
                      ? moment(readOnlyMiniCEXData.summary.acceptedAt)
                          .utc()
                          .format(PATHWAY6_DATE_FORMAT)
                      : 'N/A'
                  }
                />
              </View>
            ) : (
              <></>
            )}
            {readOnlyMiniCEXData?.summary?.completedAt ? (
              <View style={{ marginTop: 10, marginLeft: 20 }}>
                <InfoGroup
                  label={'Request Completed On:'}
                  content={
                    readOnlyMiniCEXData.summary.completedAt
                      ? moment(readOnlyMiniCEXData.summary.completedAt)
                          .utc()
                          .format(PATHWAY6_DATE_FORMAT)
                      : 'N/A'
                  }
                />
              </View>
            ) : (
              <></>
            )}
          </Page>
        </Document>
      ) : null}
    </>
  );
};

export default MiniCEXPDFV3;
