import { UiPortalConfigType } from '../../../../../generated';
import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';
import {
  Pathway2ReviewChecklistVerificationV1,
  Pathway2ReviewChecklistVerificationV2,
  Pathway2ReviewChecklistVerificationV3,
  Pathway2ReviewChecklistVerificationV4,
} from './pathway2-archived-case-review-checklist-versions';

export const Pathway2ArchivedCaseReviewChecklistVerificationFactory = (
  uiConfig: UiPortalConfigType
) => {
  for (const page of uiConfig?.config?.pages || []) {
    if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
      for (const component of page?.components || []) {
        if (component?.componentName === COMPONENT_NAME.PATHWAY2_CHECKLIST) {
          switch (component?.version) {
            case 1:
              return Pathway2ReviewChecklistVerificationV1;
            case 2:
              return Pathway2ReviewChecklistVerificationV2;
            case 3:
              return Pathway2ReviewChecklistVerificationV3;
            case 4:
            default:
              return Pathway2ReviewChecklistVerificationV4;
          }
        }
      }
    }
  }
  return null;
};
