import { UiPortalConfigType } from '../../../../../generated';
import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';
import { Pathway6ReviewChecklistMiniCEXEvaluationV1, Pathway6ReviewChecklistMiniCEXEvaluationV2 } from './pathway6-archived-case-review-checklist-versions';

export const Pathway6ArchivedCaseReviewChecklistMiniCEXEvaluationFactory = (
  uiConfig: UiPortalConfigType
) => {
  for (const page of uiConfig?.config?.pages || []) {
    if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
      for (const component of page?.components || []) {
        if (component?.componentName === COMPONENT_NAME.PATHWAY6_CHECKLIST) {
          switch (component?.version) {
            case 1:
              return Pathway6ReviewChecklistMiniCEXEvaluationV1;
            case 2:
            default:
              return Pathway6ReviewChecklistMiniCEXEvaluationV2;
          }
        }
      }
    }
  }
  return null;
};
