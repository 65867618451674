import { Tabs } from 'antd';
import { FC } from 'react';
import { ArchivedCaseAssessmentsEligibilityContainer } from '../../shared-components/assessments-eligibility/archived-case-assessments-eligibility-container';
import { ArchivedCaseAssessmentsOETContainer } from '../../shared-components/assessments-oet/archived-case-assessments-oet-container';
import { useParams } from 'react-router-dom';

const TabPaneName = {
  Eligibility: 'Eligibility',
  OET: 'OET',
};

interface Pathway1ArchivedCaseAssessmentsProps {}
export const Pathway1ArchivedCaseAssessments: FC<Pathway1ArchivedCaseAssessmentsProps> = (
  props
) => {
  const { caseId } = useParams();

  const eligibilityTab = () => (
    <ArchivedCaseAssessmentsEligibilityContainer caseId={caseId ?? ''} />
  );

  const oetTab = () => <ArchivedCaseAssessmentsOETContainer caseId={caseId ?? ''} />;

  return (
    <>
      <Tabs defaultActiveKey={TabPaneName.Eligibility}>
        <Tabs.TabPane tab={TabPaneName.Eligibility} key={TabPaneName.Eligibility}>
          {eligibilityTab()}
        </Tabs.TabPane>
        <Tabs.TabPane tab={TabPaneName.OET} key={TabPaneName.OET}>
          {oetTab()}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
