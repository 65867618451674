import { Col, Collapse, Descriptions, Row, Space, Table, Typography } from 'antd';
import moment from 'moment';
import { InferProps } from 'prop-types';
import { FC } from 'react';
import { C_ApplicantType, PastAttemptsType, UiPortalConfigType } from '../../../generated';
import { DATE_FORMAT } from '../../constants';
import { render } from '@testing-library/react';
import RejectionReasonsFactory from '../../../utils/rejection-reason/rejection-reasons-factory';
import _ from 'lodash';
const { Title } = Typography;
const { Panel } = Collapse;
const { Column } = Table;

const CaseOverviewApplicantInformationPropsTypes = {};

export interface ComponentProps {
  initialValues: C_ApplicantType;
  pastAttempts: PastAttemptsType[];
  versionConfig: UiPortalConfigType;
}

export type CaseOverviewApplicantInformationProps = InferProps<
  typeof CaseOverviewApplicantInformationPropsTypes
> &
  ComponentProps;

const CaseOverviewApplicantInformation: FC<CaseOverviewApplicantInformationProps> = ({
  initialValues,
  pastAttempts,
  versionConfig,
}) => {
  var ExamHistory = () => {
    if (typeof initialValues === 'undefined' || initialValues === null) {
      return <></>;
    }
    var examNames = initialValues?.examHistory?.map((x) => x?.examName) as [string];
    var uniqueExamNames = examNames
      .filter((value: string, index) => examNames.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b));
    var results = uniqueExamNames?.map((examName) => {
      var examResults = initialValues?.examHistory?.filter((value) => value?.examName === examName);
      return (
        <Descriptions.Item label={examName} key={examName}>
          <Space direction="vertical">
            {examResults?.map((exam) => {
              return (
                <div key={exam?.examDate!}>
                  {moment(exam?.examDate).format(DATE_FORMAT)} - <b>{exam?.result}</b>
                </div>
              );
            })}
          </Space>
        </Descriptions.Item>
      );
    });

    return (
      <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical" column={1}>
        {results}
      </Descriptions>
    );
  };

  const applicationHistoryTableColumns = [
    {
      title: 'Pathway Season',
      dataIndex: 'pathwaySeason',
      key: 'pathwaySeason',
      align: 'center',
    },
    {
      title: 'Application Type',
      dataIndex: 'applicationType',
      key: 'applicationType',
      align: 'center',
    },
    {
      title: 'Pathway #',
      dataIndex: 'pathway',
      key: 'pathway',
      align: 'center',
    },
    {
      title: 'Submitted On',
      dataIndex: 'submittedAt',
      key: 'submittedAt',
      render: (value: string) => {
        return value ? moment(value).format(DATE_FORMAT) : 'N/A';
      },
      align: 'center',
    },
    {
      title: 'Review Completed On',
      dataIndex: 'completedAt',
      key: 'completedAt',
      render: (value: string) => {
        return value ? moment(value).format(DATE_FORMAT) : 'N/A';
      },
      align: 'center',
    },
    {
      title: 'Decision',
      dataIndex: 'output',
      key: 'output',
      align: 'center',
    },
    {
      title: 'Rejection Reason',
      dataIndex: 'rejectionReason',
      key: 'rejectionReason',
      render: (value: string[]) => {
        return value?.map((reason) => {
          return (
            <div key={reason!}>
              {_.find(RejectionReasonsFactory(versionConfig), { key: reason })?.value}
            </div>
          );
        });
      },
      align: 'center',
    },
    {
      title: 'Is Refunded?',
      dataIndex: 'refundIssued',
      key: 'refundIssued',
      align: 'center',
    },
  ] as any;
  const applicationHistoryTable = () => {
    return (
      <Table
        dataSource={pastAttempts}
        scroll={{ y: 240 }}
        size="small"
        className="ecfmg-small-table"
        columns={applicationHistoryTableColumns}
      />
    );
  };

  return (
    <>
      <Collapse style={{ minWidth: '100%' }}>
        <Panel header="Information on File" key="1">
          <Row>
            <Col md={12} xl={5}>
              <Title level={3}>Applicant Details</Title>
              <Descriptions
                className="ecfmg-small-descriptions"
                size="small"
                layout="vertical"
                column={1}
              >
                <Descriptions.Item label="EMail">{initialValues?.email}</Descriptions.Item>
                <Descriptions.Item label="Date of Birth">
                  {initialValues?.dateOfBirth
                    ? moment(initialValues?.dateOfBirth).format(DATE_FORMAT)
                    : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Degree School">
                  {initialValues?.gradSchool}
                </Descriptions.Item>
                <Descriptions.Item label="Degree">{initialValues?.degree}</Descriptions.Item>
                <Descriptions.Item label="Grad Date">{initialValues?.gradYear}</Descriptions.Item>
              </Descriptions>
            </Col>

            {initialValues && initialValues?.examHistory && initialValues?.examHistory?.length > 0 && (
              <Col md={12} xl={5}>
                <Title level={3}>Exam History</Title>
                <ExamHistory />
              </Col>
            )}

            <Col md={24} xl={14}>
              <Title level={3}>Restrictions</Title>
              <Table
                dataSource={initialValues?.restrictions as any}
                scroll={{ y: 240 }}
                size="small"
                className="ecfmg-small-table"
              >
                <Column title="Date" dataIndex="startDate" key="startDate" />
                <Column title="Reason" dataIndex="reason" key="reason" />
                <Column title="Level" dataIndex="level" key="level" />
                <Column title="Release Date" dataIndex="releaseDate" key="releaseDate" />
                <Column title="User" dataIndex="user" key="user" />
                <Column title="Comments" dataIndex="comments" key="comments" />
              </Table>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <br />
      {pastAttempts?.length > 0 ? (
        <Collapse style={{ minWidth: '100%' }}>
          <Panel header="Application History" key="2">
            {applicationHistoryTable()}
          </Panel>
        </Collapse>
      ) : null}
    </>
  );
};

export default {
  Component: CaseOverviewApplicantInformation,
};
