import { FC, useState, useEffect } from 'react';
import { Form, Space, Button, Row, Col, Select, Popconfirm, message, Typography } from 'antd';
import { SendOutlined, CheckOutlined } from '@ant-design/icons';
import PropTypes, { InferProps } from 'prop-types';
import { useMutation } from '@apollo/client/react';
import {
  CaseMgmtPathway1DocumentReviewChecklistReviewDocument,
  CaseSummaryType,
  C_ApplicantType,
} from '../../../../../../generated';
const { Title } = Typography;

const { Option } = Select;

const STATUS_OPTIONS = [
  { key: 'license-review-in-process', value: 'License review in process' },
  {
    key: 'awaiting-applicant-resolution-license',
    value: 'Awaiting applicant resolution –License',
  },
  {
    key: 'awaiting-applicant-resolution-translation',
    value: 'Awaiting applicant resolution –Translation',
  },
  {
    key: 'exception-review-escalation-required',
    value: 'Exception Review/Escalation Required',
  },
  {
    key: 'awaiting-licensure-document-from-issuing-authority',
    value: 'Awaiting Licensure Document from issuing authority',
  },
  {
    key: 'awaiting-licensure-document-from-applicant',
    value: 'Awaiting Licensure Document from applicant',
  },
  { key: 'sent-for-verification', value: 'Sent for Verification' },
  { key: 'resent-for-verification', value: 'Resent for Verification' },
  {
    key: 'verification-received-in-review',
    value: 'Verification received; In Review',
  },
  {
    key: 'verification-rejected-and-resent',
    value: 'Verification Rejected and Resent',
  },
  {
    key: 'verification-review-complete',
    value: 'Verification review complete',
  },
];

const Pathway1DocumentReviewChecklistPropsTypes = {
  checklist: PropTypes.shape({
    licensureUnrestrictedLicense: PropTypes.string,
    licensureIssuedAppropriateAuthority: PropTypes.string,
    licensureApplicantLicenseOnPoint: PropTypes.string,
    licensureDisciplinaryAction: PropTypes.string,
    licensureNameValidation: PropTypes.string,
    licensureCompleteLegible: PropTypes.string,
    licensureTranslationStatus: PropTypes.string,
    licensureDocumentIssueDateAcceptable: PropTypes.string,
    verificationAuthorized: PropTypes.string,
    verificationPrimarySource: PropTypes.string,
    verificationActiveLicense: PropTypes.string,
    verificationGoodStanding: PropTypes.string,
  }).isRequired,
  checklistMetaData: PropTypes.shape({
    regulatoryAuthorityRef: PropTypes.shape({
      orgName: PropTypes.string,
      countryName: PropTypes.string,
      _id: PropTypes.string,
    }),
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        docId: PropTypes.string,
        docType: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  }),
  checklistState: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  parentDataVersion: PropTypes.number,
  onUpdateDataVersion: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  checklistStatus: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export interface ComponentProps {
  caseSummary: CaseSummaryType;
  applicant: C_ApplicantType;
}

export type Pathway1DocumentReviewChecklistProps = InferProps<
  typeof Pathway1DocumentReviewChecklistPropsTypes
> &
  ComponentProps;

const Pathway1DocumentReviewChecklistV4: FC<Pathway1DocumentReviewChecklistProps> = ({
  checklist,
  caseSummary,
  checklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  checklistStatus,
  applicant,
  redirect,
  refetch,
  checklistMetaData,
}) => {
  const [licenseStatus, setLicenseStatus] = useState<string | undefined | null>(undefined);
  const [licensureUnrestrictedLicense, setLicensureUnrestrictedLicense] =
    useState<string | undefined | null>(undefined);
  const [licensureIssuedAppropriateAuthority, setLicensureIssuedAppropriateAuthority] =
    useState<string | undefined | null>(undefined);
  const [licensureApplicantLicenseOnPoint, setLicensureApplicantLicenseOnPoint] =
    useState<string | undefined | null>(undefined);

  const [licensureDisciplinaryAction, setLicensureDisciplinaryAction] =
    useState<string | undefined | null>(undefined);
  const [licensureNameValidation, setLicensureNameValidation] =
    useState<string | undefined | null>(undefined);
  const [licensureCompleteLegible, setLicensureCompleteLegible] =
    useState<string | undefined | null>(undefined);
  const [licensureTranslationStatus, setLicensureTranslationStatus] =
    useState<string | undefined | null>(undefined);
  const [verificationAuthorized, setVerificationAuthorized] =
    useState<string | undefined | null>(undefined);
  const [verificationPrimarySource, setVerificationPrimarySource] =
    useState<string | undefined | null>(undefined);
  const [verificationActiveLicense, setVerificationActiveLicense] =
    useState<string | undefined | null>(undefined);
  const [verificationGoodStanding, setVerificationGoodStanding] =
    useState<string | undefined | null>(undefined);
  const [licensureDocumentIssueDateAcceptable, setLicensureDocumentIssueDateAcceptable] =
    useState<string | undefined | null>(undefined);
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);
  const [recordIdentifier, setRecordIdentifier] = useState<string | undefined>(undefined);
  const [setPathway1Checklist] = useMutation(CaseMgmtPathway1DocumentReviewChecklistReviewDocument);
  useEffect(() => {
    if (recordId) {
      if (recordIdentifier !== recordId) {
        form.resetFields();
        setRecordIdentifier(recordId);
      }
    }
    if (typeof licenseStatus == 'undefined' && checklistState) {
      setLicenseStatus(checklistState);
      form.setFieldsValue({ licenseStatus: checklistState });
    }
    if (checklist) {
      if (typeof licensureUnrestrictedLicense == 'undefined') {
        setLicensureUnrestrictedLicense(checklist.licensureUnrestrictedLicense);
        form.setFieldsValue({
          licensureUnrestrictedLicense: checklist.licensureUnrestrictedLicense,
        });
      }
      if (typeof licensureIssuedAppropriateAuthority == 'undefined') {
        setLicensureIssuedAppropriateAuthority(checklist.licensureIssuedAppropriateAuthority);
        form.setFieldsValue({
          licensureIssuedAppropriateAuthority: checklist.licensureIssuedAppropriateAuthority,
        });
      }
      if (typeof licensureApplicantLicenseOnPoint == 'undefined') {
        setLicensureApplicantLicenseOnPoint(checklist.licensureApplicantLicenseOnPoint);
        form.setFieldsValue({
          licensureApplicantLicenseOnPoint: checklist.licensureApplicantLicenseOnPoint,
        });
      }
      if (typeof licensureDisciplinaryAction == 'undefined') {
        setLicensureDisciplinaryAction(checklist.licensureDisciplinaryAction);
        form.setFieldsValue({
          licensureDisciplinaryAction: checklist.licensureDisciplinaryAction,
        });
      }
      if (typeof licensureNameValidation == 'undefined') {
        setLicensureNameValidation(checklist.licensureNameValidation);
        form.setFieldsValue({
          licensureNameValidation: checklist.licensureNameValidation,
        });
      }
      if (typeof licensureCompleteLegible == 'undefined') {
        setLicensureCompleteLegible(checklist.licensureCompleteLegible);
        form.setFieldsValue({
          licensureCompleteLegible: checklist.licensureCompleteLegible,
        });
      }
      if (typeof licensureTranslationStatus == 'undefined') {
        setLicensureTranslationStatus(checklist.licensureTranslationStatus);
        form.setFieldsValue({
          licensureTranslationStatus: checklist.licensureTranslationStatus,
        });
      }
      if (typeof verificationAuthorized == 'undefined') {
        setVerificationAuthorized(checklist.verificationAuthorized);
        form.setFieldsValue({
          verificationAuthorized: checklist.verificationAuthorized,
        });
      }
      if (typeof verificationPrimarySource == 'undefined') {
        setVerificationPrimarySource(checklist.verificationPrimarySource);
        form.setFieldsValue({
          verificationPrimarySource: checklist.verificationPrimarySource,
        });
      }
      if (typeof verificationActiveLicense == 'undefined') {
        setVerificationActiveLicense(checklist.verificationActiveLicense);
        form.setFieldsValue({
          verificationActiveLicense: checklist.verificationActiveLicense,
        });
      }
      if (typeof verificationGoodStanding == 'undefined') {
        setVerificationGoodStanding(checklist.verificationGoodStanding);
        form.setFieldsValue({
          verificationGoodStanding: checklist.verificationGoodStanding,
        });
      }
      if (typeof licensureDocumentIssueDateAcceptable == 'undefined') {
        setLicensureDocumentIssueDateAcceptable(checklist.licensureDocumentIssueDateAcceptable);
        form.setFieldsValue({
          licensureDocumentIssueDateAcceptable: checklist.licensureDocumentIssueDateAcceptable,
        });
      }
    }
    if (parentDataVersion) {
      if (!dataVersion) {
        setDataVersion(parentDataVersion);
      } else if (dataVersion < parentDataVersion) {
        setDataVersion(parentDataVersion);
      }
    }
  });

  const [form] = Form.useForm();

  const selectOptions = () => {
    return (
      <>
        <Option value=""> </Option>
        <Option value="No">No</Option>
        <Option value="Yes">Yes</Option>
        <Option value="N/A">N/A</Option>
      </>
    );
  };

  const disableChecklist: boolean =
    checklistStatus?.toUpperCase() === 'APPROVED' ||
    checklistStatus?.toUpperCase() === 'REJECTED' ||
    caseSummary?.pathwayEligibilityStatus?.toUpperCase() === 'COMPLETED' ||
    caseSummary?.eligibilityReviewAssigneeId !== sessionStorage.getItem('userIdToken');

  const checklistSubheader = checklistMetaData?.regulatoryAuthorityRef
    ? `${checklistMetaData?.regulatoryAuthorityRef?.countryName} - ${checklistMetaData?.regulatoryAuthorityRef?.orgName}`
    : checklistMetaData?.documents
    ? `${checklistMetaData?.documents[0]?.title}`
    : null;

  const onFinish = async () => {
    const savedData = {
      licensureUnrestrictedLicense: licensureUnrestrictedLicense || '',
      licensureIssuedAppropriateAuthority: licensureIssuedAppropriateAuthority || '',
      licensureApplicantLicenseOnPoint: licensureApplicantLicenseOnPoint || '',
      licensureDisciplinaryAction: licensureDisciplinaryAction || '',
      licensureNameValidation: licensureNameValidation || '',
      licensureCompleteLegible: licensureCompleteLegible || '',
      licensureTranslationStatus: licensureTranslationStatus || '',
      licensureDocumentIssueDateAcceptable: licensureDocumentIssueDateAcceptable || '',
      verificationAuthorized: verificationAuthorized || '',
      verificationPrimarySource: verificationPrimarySource || '',
      verificationActiveLicense: verificationActiveLicense || '',
      verificationGoodStanding: verificationGoodStanding || '',
    };

    await setPathway1Checklist({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion!,
        recordId: recordId,
        isComplete: true,
        checklistResponse: savedData,
        checklistState: licenseStatus || '',
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_pathway1_setReview?.caseSummary;
        setDataVersion(casePortalResponse?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.dataVersion);
        refetch();
        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onSaveAndReturnClick = async () => {
    const savedData = {
      licensureUnrestrictedLicense: licensureUnrestrictedLicense || '',
      licensureIssuedAppropriateAuthority: licensureIssuedAppropriateAuthority || '',
      licensureApplicantLicenseOnPoint: licensureApplicantLicenseOnPoint || '',
      licensureDisciplinaryAction: licensureDisciplinaryAction || '',
      licensureNameValidation: licensureNameValidation || '',
      licensureCompleteLegible: licensureCompleteLegible || '',
      licensureTranslationStatus: licensureTranslationStatus || '',
      licensureDocumentIssueDateAcceptable: licensureDocumentIssueDateAcceptable || '',
      verificationAuthorized: verificationAuthorized || '',
      verificationPrimarySource: verificationPrimarySource || '',
      verificationActiveLicense: verificationActiveLicense || '',
      verificationGoodStanding: verificationGoodStanding || '',
    };

    // var temp = moment().subtract(1, "day").tz("America/New_York").format();

    await setPathway1Checklist({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion!,
        recordId: recordId,
        isComplete: false,
        checklistResponse: savedData,
        checklistState: licenseStatus || '',
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_pathway1_setReview?.caseSummary;
        setDataVersion(casePortalResponse?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.dataVersion);
        refetch();
        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };
  return (
    <>
      <Form
        size="small"
        form={form}
        onFinish={onFinish}
        initialValues={{
          licenseStatus: checklistState,
          licensureUnrestrictedLicense: checklist?.licensureUnrestrictedLicense,
          licensureIssuedAppropriateAuthority: checklist?.licensureIssuedAppropriateAuthority,
          licensureApplicantLicenseOnPoint: checklist?.licensureApplicantLicenseOnPoint,
          licensureDisciplinaryAction: checklist?.licensureDisciplinaryAction,
          licensureNameValidation: checklist?.licensureNameValidation,
          licensureCompleteLegible: checklist?.licensureCompleteLegible,
          licensureTranslationStatus: checklist?.licensureTranslationStatus,
          licensureDocumentIssueDateAcceptable: checklist?.licensureDocumentIssueDateAcceptable,
          verificationAuthorized: checklist?.verificationAuthorized,
          verificationPrimarySource: checklist?.verificationPrimarySource,
          verificationActiveLicense: checklist?.verificationActiveLicense,
          verificationGoodStanding: checklist?.verificationGoodStanding,
        }}
      >
        <div style={{ border: '1px black solid', padding: '15px' }}>
          <Title level={3}>Pathway 1 Review</Title>
          <Title level={4}>{checklistSubheader}</Title>
          <hr style={{ borderTop: '1px black solid' }} />
          <Row justify="end">
            <Col>
              <Button shape="round" icon={<SendOutlined />} size={'small'}>
                <a href={`mailto:${applicant?.email}?subject=ECFMG%20Pathway%20Application`}>
                  {' Contact Applicant'}
                </a>
              </Button>
            </Col>
          </Row>

          <Title level={4}>License Status</Title>
          <Row>
            <Col span={24}>
              <Form.Item
                name="licenseStatus"
                rules={[
                  {
                    required: true,
                    message: 'Please select a license status',
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setLicenseStatus(value)}
                  disabled={disableChecklist}
                >
                  {STATUS_OPTIONS.map((option) => (
                    <Option value={option.key} key={option.key}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Title level={4}>Licensure Review</Title>
          <Row>
            <Col span={4}>
              <Form.Item
                name="licensureUnrestrictedLicense"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => {
                    form.setFieldsValue({
                      licensureUnrestrictedLicense: value,
                    });
                    setLicensureUnrestrictedLicense(value);
                  }}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>Confirms full, unrestricted license/registration</Col>
          </Row>

          <Row>
            <Col span={4}>
              <Form.Item
                name="licensureIssuedAppropriateAuthority"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setLicensureIssuedAppropriateAuthority(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>Issued by appropriate authority</Col>
          </Row>

          <Row>
            <Col span={4}>
              <Form.Item
                name="licensureApplicantLicenseOnPoint"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setLicensureApplicantLicenseOnPoint(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>
              Applicant appears to have held license at any point on or after January 1, 2019
            </Col>
          </Row>

          <Row>
            <Col span={4}>
              <Form.Item
                name="licensureDisciplinaryAction"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setLicensureDisciplinaryAction(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>Document does not indicate disciplinary action</Col>
          </Row>

          <Row>
            <Col span={4}>
              <Form.Item
                name="licensureNameValidation"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setLicensureNameValidation(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>Name Ok</Col>
          </Row>

          <Row>
            <Col span={4}>
              <Form.Item
                name="licensureCompleteLegible"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setLicensureCompleteLegible(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>Document complete and legible</Col>
          </Row>

          <Row>
            <Col span={4}>
              <Form.Item
                name="licensureTranslationStatus"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setLicensureTranslationStatus(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>Translation meets ECFMG requirements</Col>
          </Row>

          <Row>
            <Col span={4}>
              <Form.Item
                name="licensureDocumentIssueDateAcceptable"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setLicensureDocumentIssueDateAcceptable(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>
              Document Issue Date Acceptable
            </Col>
          </Row>

          <Title level={4}>Verification Review</Title>
          <Row>
            <Col span={4}>
              <Form.Item
                name="verificationAuthorized"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setVerificationAuthorized(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>Verification completed by authorized official</Col>
          </Row>

          <Row>
            <Col span={4}>
              <Form.Item
                name="verificationPrimarySource"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setVerificationPrimarySource(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>Verification returned from primary source</Col>
          </Row>

          <Row>
            <Col span={4}>
              <Form.Item
                name="verificationActiveLicense"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setVerificationActiveLicense(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>
              Confirms applicant held an active license at any point since January 1, 2019
            </Col>
          </Row>

          <Row>
            <Col span={4}>
              <Form.Item
                name="verificationGoodStanding"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setVerificationGoodStanding(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>
              Confirms applicant is in good standing and/or did not have any disciplinary action
            </Col>
          </Row>

          <Row justify="end">
            <Col>
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={form.submit}
                disabled={disableChecklist}
              >
                <Button
                  type="primary"
                  shape="round"
                  icon={<CheckOutlined />}
                  size={'small'}
                  disabled={disableChecklist}
                >
                  Complete Review
                </Button>
              </Popconfirm>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Col>
              <Space>
                <Button
                  shape="round"
                  onClick={() => redirect(`/case-management/case-detail/${caseSummary?.usmleId}`)}
                >
                  Cancel
                </Button>
                <Button
                  shape="round"
                  type="primary"
                  onClick={onSaveAndReturnClick}
                  disabled={disableChecklist}
                >
                  Save and Return
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default Pathway1DocumentReviewChecklistV4;
