import { Card, List, Typography } from 'antd';
import { FC } from 'react';
import { ArchivedCasePathway345ReviewType, UiPortalConfigType } from '../../../../../generated';
import { CHECKLIST_EVALUATION_MAPPINGS } from '../../../../constants';
import { Pathway345ArchivedCaseReviewChecklistEligibilityFactory } from '../pathway345-archived-case-review-checklist-versions/pathway345-archived-case-review-checklist-eligibility-factory';
import { Pathway345ArchivedCaseReviewChecklistAttestationFactory } from '../pathway345-archived-case-review-checklist-versions/pathway345-archived-case-review-checklist-attestation-factory';

const { Title, Text } = Typography;

const STATUS_OPTIONS = [
  {
    key: 'license-eligibility-review-in-process',
    value: 'Eligibility review in process',
  },
  {
    key: 'license-awaiting-applicant-resolution',
    value: 'Awaiting applicant resolution',
  },
  {
    key: 'license-exception-review-escalation-reqired',
    value: 'Exception Review/Escalation Required',
  },
  {
    key: 'license-attestation-received-in-review',
    value: 'Attestation received; In Review',
  },
  {
    key: 'license-awaiting-attestation-document',
    value: 'Awaiting Attestation Document',
  },
  {
    key: 'license-attestation-request-sent-to-school',
    value: 'Attestation Request Sent to School',
  },
  {
    key: 'license-attestation-rejected-and-resent',
    value: 'Attestation Rejected and Resent',
  },
  {
    key: 'license-attestation-review-complete',
    value: 'Attestation review complete',
  },
  {
    key: 'license-attestation-rejected-by-school',
    value: 'Attestation rejected by school',
  },
];

interface Pathway345ArchivedCaseChecklistProps {
  data: ArchivedCasePathway345ReviewType;
  uiConfig: UiPortalConfigType
}



const getColor = (value: string) => {
  switch (value) {
    case 'Yes':
      return 'green';
    case 'No':
      return 'red';
    default:
      return 'black';
  }
};

export const Pathway345ArchivedCaseChecklist: FC<Pathway345ArchivedCaseChecklistProps> = (
  props
) => {
  const pathway345EligibilityMap = Pathway345ArchivedCaseReviewChecklistEligibilityFactory(props.uiConfig)
  const pathway345AttestationMap = Pathway345ArchivedCaseReviewChecklistAttestationFactory(props.uiConfig)
  
  // Building Checklist Structure and Data
  let pathway345ChecklistData = [
    <Text style={{ fontWeight: 'bold' }} key={1}>
      License Status
    </Text>,
    <div key={2}>
      {STATUS_OPTIONS.find((status) => status.key === props.data?.checklistState)?.value}
    </div>,
    <Text style={{ fontWeight: 'bold' }} key={3}>
      Eligibility Evaluation
    </Text>,
  ];

  let tempChecklist = props.data?.checklist as Record<string, string>;

  for (const key in props.data?.checklist) {
    if (!pathway345EligibilityMap?.[key]) {
      continue;
    }

    pathway345ChecklistData.push(
      <table width="100%">
        <tr>
          <td>{pathway345EligibilityMap[key]}</td>
          <td align="right" style={{ color: getColor(tempChecklist[key]) }}>
            {CHECKLIST_EVALUATION_MAPPINGS.find((eva) => eva.key === tempChecklist[key])?.value}
          </td>
        </tr>
      </table>
    );
  }

  // New Section for Verification
  pathway345ChecklistData.push(
    <Text style={{ fontWeight: 'bold' }}>
      Attestation Evaluation
    </Text>
  );

  for (const key in props.data?.checklist) {
    if (!pathway345AttestationMap?.[key]) {
      continue;
    }

    pathway345ChecklistData.push(
      <table width="100%">
        <tr>
          <td>{pathway345AttestationMap[key]}</td>
          <td align="right" style={{ color: getColor(tempChecklist[key]) }}>
            {CHECKLIST_EVALUATION_MAPPINGS.find((eva) => eva.key === tempChecklist[key])?.value}
          </td>
        </tr>
      </table>
    );
  }

  return (
    <Card
      bodyStyle={{ paddingTop: '5px', paddingBottom: '5px' }}
      style={{
        width: '50%',
        borderRadius: '10px',
        border: '1px solid var(--Neutral-4, #F0F0F0)',
        background: 'var(--Neutral-1, #FFF)',
      }}
      title={
        <Title level={5} style={{ fontWeight: 'bold' }}>
          Pathway 3-5 Checklist
        </Title>
      }
    >
      <List
        size="small"
        dataSource={pathway345ChecklistData}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </Card>
  );
};
