import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './index.less';
import App from './App';

import { BrowserRouter } from 'react-router-dom';
import MsalProvider from './components/msal-react-lite';

import FeatureFlagProvider from './components/feature-flag-react-lite';
import featureFlagConfig from './config/feature-flag-config';
import MsalProviderConfig from './config/msal-config';
import ReactIdleTimer from './components/core/react-idle-timer';

import { LOGIN_TYPE } from './components/constants';
import GA4React from 'ga-4-react';
import { CachePurgeProvider } from './contexts/components/CachePurgeContext';

const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID as string;
const ga4react = new GA4React(gaMeasurementId, {
  debug_mode: process.env.NODE_ENV !== 'production',
});

(async () => {
  try {
    await ga4react.initialize();
  } catch (error) {
    console.error(error);
  }

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <MsalProvider config={MsalProviderConfig}>
          <FeatureFlagProvider config={featureFlagConfig}>
            <CachePurgeProvider>
              <ReactIdleTimer>
                <App />
              </ReactIdleTimer>
            </CachePurgeProvider>
          </FeatureFlagProvider>
        </MsalProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
