import { Table } from 'antd';
import { FC } from 'react';
import { PATHWAY6_SCORE_CATEGORIES } from '../../../../constants';

export interface Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableDataType {
  id: string;
  [PATHWAY6_SCORE_CATEGORIES.MEDICAL_INTERVIEWING]: number;
  [PATHWAY6_SCORE_CATEGORIES.PHYSICAL_EXAMINATION]: number;
  [PATHWAY6_SCORE_CATEGORIES.COMMUNICATION_PROFESSIONALISM]: number;
  [PATHWAY6_SCORE_CATEGORIES.CLINICAL_REASONING]: number;
}

interface Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableProps {
  tableData: Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableDataType[];
}
export const Pathway6ArchivedCaseAssessmentsMiniCEXScoresTable: FC<Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableProps> =
  (props) => {
    // if id === "Average", bold the text
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (
          text: string,
          record: Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableDataType
        ) => {
          return record.id === 'Average' ? <b>{text}</b> : text;
        },
      },
      {
        title: 'Interviewing',
        dataIndex: PATHWAY6_SCORE_CATEGORIES.MEDICAL_INTERVIEWING,
        key: PATHWAY6_SCORE_CATEGORIES.MEDICAL_INTERVIEWING,
        render: (
          text: string,
          record: Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableDataType
        ) => {
          return record.id === 'Average' ? <b>{text}</b> : text;
        },
        align: 'center',
      },
      {
        title: 'Physical',
        dataIndex: PATHWAY6_SCORE_CATEGORIES.PHYSICAL_EXAMINATION,
        key: PATHWAY6_SCORE_CATEGORIES.PHYSICAL_EXAMINATION,
        render: (
          text: string,
          record: Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableDataType
        ) => {
          return record.id === 'Average' ? <b>{text}</b> : text;
        },
        align: 'center',
      },
      {
        title: 'Communication',
        dataIndex: PATHWAY6_SCORE_CATEGORIES.COMMUNICATION_PROFESSIONALISM,
        key: PATHWAY6_SCORE_CATEGORIES.COMMUNICATION_PROFESSIONALISM,
        render: (
          text: string,
          record: Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableDataType
        ) => {
          return record.id === 'Average' ? <b>{text}</b> : text;
        },
        align: 'center',
      },
      {
        title: 'Reasoning',
        dataIndex: PATHWAY6_SCORE_CATEGORIES.CLINICAL_REASONING,
        key: PATHWAY6_SCORE_CATEGORIES.CLINICAL_REASONING,
        render: (
          text: string,
          record: Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableDataType
        ) => {
          return record.id === 'Average' ? <b>{text}</b> : text;
        },
        align: 'center',
      }, 
    ] as any;
    return (
      <Table
        columns={columns}
        dataSource={props.tableData}
        pagination={false}
        size="small"
        // className="ecfmg-small-table"
      />
    );
  };
