//this file is use for archival purposes. There should be no need to edit this file.
import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Space, Button, Row, Col, Select, Popconfirm, message, Typography } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import { SendOutlined, CheckOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client/react';
import {
  CaseMgmtPathway3DocumentReviewChecklistReviewDocument,
  CaseSummaryType,
  C_ApplicantType,
} from '../../../../../../generated';
const { Title } = Typography;

const { Option } = Select;

const STATUS_OPTIONS = [
  {
    key: 'license-eligibility-review-in-process',
    value: 'Eligibility review in process',
  },
  {
    key: 'license-awaiting-applicant-resolution',
    value: 'Awaiting applicant resolution',
  },
  {
    key: 'license-exception-review-escalation-reqired',
    value: 'Exception Review/Escalation Required',
  },
  {
    key: 'license-attestation-received-in-review',
    value: 'Attestation received; In Review',
  },
  {
    key: 'license-awaiting-attestation-document',
    value: 'Awaiting Attestation Document',
  },
  {
    key: 'license-attestation-request-sent-to-school',
    value: 'Attestation Request Sent to School',
  },
  {
    key: 'license-attestation-rejected-and-resent',
    value: 'Attestation Rejected and Resent',
  },
  {
    key: 'license-attestation-review-complete',
    value: 'Attestation review complete',
  },
  {
    key: 'license-attestation-rejected-by-school',
    value: 'Attestation rejected by school',
  },
];

const Pathway3DocumentReviewChecklistPropsTypes = {
  checklist: PropTypes.shape({
    licenseStatus: PropTypes.string.isRequired,
    eligibilityMedicalSchool: PropTypes.string.isRequired,
    eligibilityGradDateEligible: PropTypes.string.isRequired,
    attestationComplete: PropTypes.string.isRequired,
    attestationAuthorizedOfficial: PropTypes.string.isRequired,
    attestationTitleOk: PropTypes.string.isRequired,
    attestationSignatureMatch: PropTypes.string.isRequired,
    attestationSealOk: PropTypes.string.isRequired,
    attestationSignatureDate: PropTypes.string.isRequired,
    attestationSentPrimarySource: PropTypes.string.isRequired,
    attestationCompletedByMedicalSchool: PropTypes.string.isRequired,
  }).isRequired,
  // caseSummary: PropTypes.shape({
  //   usmleId: PropTypes.string.isRequired,
  //   eligibilityReviewAssigneeId: PropTypes.string.isRequired,
  //   pathwayEligibilityStatus: PropTypes.string.isRequired,
  // }),
  checklistState: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  parentDataVersion: PropTypes.number,
  onUpdateDataVersion: PropTypes.func.isRequired,
  // applicant: PropTypes.shape({
  //   email: PropTypes.string.isRequired,
  // }),
  redirect: PropTypes.func.isRequired,
  checklistStatus: PropTypes.string.isRequired,
};

export interface ComponentProps {
  caseSummary: CaseSummaryType;
  applicant: C_ApplicantType;
}

export type Pathway3DocumentReviewChecklistProps = InferProps<
  typeof Pathway3DocumentReviewChecklistPropsTypes
> &
  ComponentProps;

const Pathway3DocumentReviewChecklist: FC<Pathway3DocumentReviewChecklistProps> = ({
  checklist,
  caseSummary,
  checklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  applicant,
  redirect,
  checklistStatus,
}) => {
  //states to keep track of values chosen
  const [licenseStatus, setLicenseStatus] = useState<string | undefined>(undefined);
  const [eligibilityMedicalSchoolMatched, setEligibilitySchoolMatched] =
    useState<string | undefined>(undefined);

  const [eligibilityGradDateEligible, setEligibilityGradDateEligible] =
    useState<string | undefined>(undefined);
  const [attestationComplete, setAttestationComplete] = useState<string | undefined>(undefined);
  const [attestationAuthorizedOfficial, setAttestationAuthorizedOfficial] =
    useState<string | undefined>(undefined);
  const [attestationTitleOK, setAttestationTitleOK] = useState<string | undefined>(undefined);
  const [attestationSignatureMatch, setAttestationSignatureMatch] =
    useState<string | undefined>(undefined);
  const [attestationSealOK, setAttestationSealOK] = useState<string | undefined>(undefined);
  const [attestationSignatureDateOK, setAttestationSignatureDateOK] =
    useState<string | undefined>(undefined);
  const [attestationSentPrimarySource, setAttestationSentPrimarySource] =
    useState<string | undefined>(undefined);
  const [attestationCompletedMedicalSchool, setAttestationCompletedMedicalSchool] =
    useState<string | undefined>(undefined);
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);
  const [setPathway345Checklist] = useMutation(
    CaseMgmtPathway3DocumentReviewChecklistReviewDocument
  );

  useEffect(() => {
    if (checklistState && typeof licenseStatus == 'undefined') {
      setLicenseStatus(checklistState);
      form.setFieldsValue({
        licenseStatus: checklistState,
      });
    }
    if (parentDataVersion) {
      setDataVersion(parentDataVersion);
    }
    if (checklist) {
      if (typeof eligibilityMedicalSchoolMatched == 'undefined') {
        setEligibilitySchoolMatched(checklist?.eligibilityMedicalSchool);
        form.setFieldsValue({
          eligibilityMedicalSchoolMatch: checklist.eligibilityMedicalSchool,
        });
      }
      if (typeof eligibilityGradDateEligible == 'undefined') {
        setEligibilityGradDateEligible(checklist?.eligibilityGradDateEligible);
        form.setFieldsValue({
          eligibilityGradDateEligible: checklist.eligibilityGradDateEligible,
        });
      }
      if (typeof attestationComplete == 'undefined') {
        setAttestationComplete(checklist?.attestationComplete);
        form.setFieldsValue({
          attestationComplete: checklist.attestationComplete,
        });
      }
      if (typeof attestationAuthorizedOfficial == 'undefined') {
        setAttestationAuthorizedOfficial(checklist?.attestationAuthorizedOfficial);
        form.setFieldsValue({
          attestationAuthorizedOfficial: checklist.attestationAuthorizedOfficial,
        });
      }
      if (typeof attestationTitleOK == 'undefined') {
        setAttestationTitleOK(checklist?.attestationTitleOk);
        form.setFieldsValue({
          attestationTitleOK: checklist.attestationTitleOk,
        });
      }
      if (typeof attestationSignatureMatch == 'undefined') {
        setAttestationSignatureMatch(checklist?.attestationSignatureMatch);
        form.setFieldsValue({
          attestationSignatureMatch: checklist.attestationSignatureMatch,
        });
      }
      if (typeof attestationSealOK == 'undefined') {
        setAttestationSealOK(checklist?.attestationSealOk);
        form.setFieldsValue({
          attestationSealOK: checklist.attestationSealOk,
        });
      }
      if (typeof attestationSignatureDateOK == 'undefined') {
        setAttestationSignatureDateOK(checklist?.attestationSignatureDate);
        form.setFieldsValue({
          attestationSignatureDateOK: checklist.attestationSignatureDate,
        });
      }
      if (typeof attestationSentPrimarySource == 'undefined') {
        setAttestationSentPrimarySource(checklist?.attestationSentPrimarySource);
        form.setFieldsValue({
          attestationSentPrimarySource: checklist.attestationSentPrimarySource,
        });
      }
      if (typeof attestationCompletedMedicalSchool == 'undefined') {
        setAttestationCompletedMedicalSchool(checklist?.attestationCompletedByMedicalSchool);
        form.setFieldsValue({
          attestationCompletedByMedicalSchool: checklist.attestationCompletedByMedicalSchool,
        });
      }
    }
  });

  const [form] = Form.useForm();
  const selectOptions = () => {
    return (
      <>
        <Option value=""> </Option>
        <Option value="No">No</Option>
        <Option value="Yes">Yes</Option>
        <Option value="N/A">N/A</Option>
      </>
    );
  };

  const disableChecklist: boolean =
    checklistStatus?.toUpperCase() === 'APPROVED' ||
    checklistStatus?.toUpperCase() === 'REJECTED' ||
    caseSummary?.pathwayEligibilityStatus?.toUpperCase() === 'COMPLETED' ||
    caseSummary?.eligibilityReviewAssigneeId !== sessionStorage.getItem('userIdToken');

  const onSaveAndReturnClick = async () => {
    const savedData = {
      eligibilityMedicalSchool: eligibilityMedicalSchoolMatched || '',
      eligibilityGradDateEligible: eligibilityGradDateEligible || '',
      attestationComplete: attestationComplete || '',
      attestationAuthorizedOfficial: attestationAuthorizedOfficial || '',
      attestationTitleOk: attestationTitleOK || '',
      attestationSignatureMatch: attestationSignatureMatch || '',
      attestationSealOk: attestationSealOK || '',
      attestationSignatureDate: attestationSignatureDateOK || '',
      attestationSentPrimarySource: attestationSentPrimarySource || '',
      attestationCompletedByMedicalSchool: attestationCompletedMedicalSchool || '',
    };

    var tempRecordId: any = recordId;
    if (tempRecordId?.toUpperCase() === 'OveridePath'.toUpperCase()) {
      tempRecordId = null;
    }
    await setPathway345Checklist({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion!,
        recordId: tempRecordId,
        isComplete: false,
        checklistResponse: savedData,
        checklistState: licenseStatus || '',
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_pathway345_setReview?.caseSummary;
        setDataVersion(casePortalResponse?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.dataVersion);
        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onFinish = async () => {
    const savedData = {
      eligibilityMedicalSchool: eligibilityMedicalSchoolMatched || '',
      eligibilityGradDateEligible: eligibilityGradDateEligible || '',
      attestationComplete: attestationComplete || '',
      attestationAuthorizedOfficial: attestationAuthorizedOfficial || '',
      attestationTitleOk: attestationTitleOK || '',
      attestationSignatureMatch: attestationSignatureMatch || '',
      attestationSealOk: attestationSealOK || '',
      attestationSignatureDate: attestationSignatureDateOK || '',
      attestationSentPrimarySource: attestationSentPrimarySource || '',
      attestationCompletedByMedicalSchool: attestationCompletedMedicalSchool || '',
    };

    var tempRecordId: any = recordId;
    if (tempRecordId?.toUpperCase() === 'OveridePath'.toUpperCase()) {
      tempRecordId = null;
    }

    await setPathway345Checklist({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion!,
        recordId: tempRecordId,
        isComplete: true,
        checklistResponse: savedData,
        checklistState: licenseStatus || '',
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_pathway345_setReview?.caseSummary;
        setDataVersion(casePortalResponse?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.dataVersion);
        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };
  return (
    <>
      <Form
        form={form}
        onFieldsChange={(changedFields, allFields) => {
          console.log(changedFields);
        }}
        onFinish={onFinish}
        initialValues={{
          licenseStatus: checklist?.licenseStatus,
          eligibilityMedicalSchoolMatch: checklist?.eligibilityMedicalSchool,
          eligibilityGradDateEligible: checklist?.eligibilityGradDateEligible,
          attestationComplete: checklist?.attestationComplete,
          attestationAuthorizedOfficial: checklist?.attestationAuthorizedOfficial,
          attestationTitleOK: checklist?.attestationTitleOk,
          attestationSignatureMatch: checklist?.attestationSignatureMatch,
          attestationSealOK: checklist?.attestationSealOk,
          attestationSignatureDateOK: checklist?.attestationSignatureDate,
          attestationSentPrimarySource: checklist?.attestationSentPrimarySource,
          attestationCompletedByMedicalSchool: checklist?.attestationCompletedByMedicalSchool,
        }}
        size="small"
      >
        <Row>
          <Col span={24}>
            <div style={{ border: '1px black solid', padding: '15px' }}>
              <Title level={3}>Pathway 3-5 Review</Title>
              <hr style={{ borderTop: '1px black solid' }} />
              <Row justify="end">
                <Col>
                  <Button shape="round" icon={<SendOutlined />} size={'small'}>
                    <a href={`mailto:${applicant?.email}?subject=ECFMG%20Pathway%20Application`}>
                      {' Contact Applicant'}
                    </a>
                  </Button>
                </Col>
              </Row>

              <Title level={4}>License Status</Title>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="licenseStatus"
                    rules={[
                      {
                        required: true,
                        message: 'Please select a license status',
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: any) => setLicenseStatus(value)}
                      disabled={disableChecklist}
                    >
                      {STATUS_OPTIONS.map((option) => (
                        <Option value={option.key} key={option.key}>
                          {option.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Title level={4}>Eligibility Evaluation</Title>
              <Row>
                <Col span={4}>
                  <Form.Item
                    name="eligibilityMedicalSchoolMatch"
                    validateTrigger="onSubmit"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: '70px' }}
                      onChange={(value: any) => setEligibilitySchoolMatched(value)}
                      value={eligibilityMedicalSchoolMatched}
                      disabled={disableChecklist}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>Medical School matches ECFMG Record</Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Form.Item
                    name="eligibilityGradDateEligible"
                    validateTrigger="onSubmit"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: any) => setEligibilityGradDateEligible(value)}
                      value={eligibilityGradDateEligible}
                      style={{ width: '70px' }}
                      disabled={disableChecklist}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col> Graduation date on or after January 1, 2018</Col>
              </Row>

              <Title level={4}>Attestation Evaluation</Title>
              <Row>
                <Col span={4}>
                  <Form.Item
                    name="attestationComplete"
                    validateTrigger="onSubmit"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: any) => setAttestationComplete(value)}
                      style={{ width: '70px' }}
                      value={attestationComplete}
                      disabled={disableChecklist}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col> Attestation Complete</Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Form.Item
                    name="attestationAuthorizedOfficial"
                    validateTrigger="onSubmit"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: any) => setAttestationAuthorizedOfficial(value)}
                      value={attestationAuthorizedOfficial}
                      style={{ width: '70px' }}
                      disabled={disableChecklist}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col> Attestation Completed by Authorized Official</Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Form.Item
                    name="attestationTitleOK"
                    validateTrigger="onSubmit"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: any) => setAttestationTitleOK(value)}
                      style={{ width: '70px' }}
                      value={attestationTitleOK}
                      disabled={disableChecklist}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col> Official Title Ok</Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Form.Item
                    name="attestationSignatureMatch"
                    validateTrigger="onSubmit"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: any) => setAttestationSignatureMatch(value)}
                      style={{ width: '70px' }}
                      value={attestationSignatureMatch}
                      disabled={disableChecklist}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col> Authorized Official Signature Match</Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Form.Item
                    name="attestationSealOK"
                    validateTrigger="onSubmit"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: any) => setAttestationSealOK(value)}
                      style={{ width: '70px' }}
                      value={attestationSealOK}
                      disabled={disableChecklist}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col> Seal Ok</Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Form.Item
                    name="attestationSignatureDateOK"
                    validateTrigger="onSubmit"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: any) => setAttestationSignatureDateOK(value)}
                      style={{ width: '70px' }}
                      value={attestationSignatureDateOK}
                      disabled={disableChecklist}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col> Signature Date OK</Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Form.Item
                    name="attestationSentPrimarySource"
                    validateTrigger="onSubmit"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: any) => setAttestationSentPrimarySource(value)}
                      style={{ width: '70px' }}
                      value={attestationSentPrimarySource}
                      disabled={disableChecklist}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col> Attestation Sent to ECFMG by Primary Source</Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Form.Item
                    name="attestationCompletedByMedicalSchool"
                    validateTrigger="onSubmit"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                  >
                    <Select
                      onChange={(value: any) => setAttestationCompletedMedicalSchool(value)}
                      style={{ width: '70px' }}
                      value={attestationCompletedMedicalSchool}
                      disabled={disableChecklist}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col> Attestation completed by medical school of record</Col>
              </Row>

              <Row justify="end">
                <Col>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={form.submit}
                    disabled={disableChecklist}
                  >
                    <Button
                      type="primary"
                      shape="round"
                      icon={<CheckOutlined />}
                      size={'small'}
                      disabled={disableChecklist}
                    >
                      Complete Review
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
              <br />
              <Row justify="end">
                <Col>
                  <Space>
                    <Button
                      shape="round"
                      onClick={() => {
                        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      shape="round"
                      type="primary"
                      onClick={onSaveAndReturnClick}
                      disabled={disableChecklist}
                    >
                      Save and Return
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Pathway3DocumentReviewChecklist;
