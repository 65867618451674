import { Table } from 'antd';
import { FC, Key, useState } from 'react';
import { ArchivedCaseOetReviewType } from '../../../../generated';
import ArchivedCaseOetCheclist from './archived-case-oet-checklist';
import ArchivedCaseOetInfoCard from './archived-case-oet-info-card';

interface ArchivedCaseOetReviewProps {
  data: ArchivedCaseOetReviewType[];
}

const ArchivedCaseOetReview: FC<ArchivedCaseOetReviewProps> = (props) => {
  const [currentOetEntry, setCurrentOetEntry] = useState(
    props.data ? props.data[0] : null
  );
  const [currentRowKeys, setCurrentRowKeys] = useState([currentOetEntry?.summary?._id])

  const rowSelection = {
    selectedRowKeys: currentRowKeys as Key[],
    onChange: (selectedRowKeys: any, selectedRows: any) => {
        setCurrentRowKeys(selectedRowKeys);
        setCurrentOetEntry(selectedRows[0]);
    },
  };

  let modifiedData: any = props.data;
  const tableData = modifiedData.map((entry: any) => ({
    key: entry?.summary?._id,
    title: entry?.summary?.title,
    status: entry?.summary?.status,
    name: entry?.summary?.updatedBy?.name
  }));

  const pathwayTableColumns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Last Reviewed By',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Assigned Case Manager',
      dataIndex: 'status',
      key: 'status',
    },
  ];
  return (
    <>
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        columns={pathwayTableColumns}
        dataSource={tableData}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ArchivedCaseOetCheclist data={currentOetEntry ?? {}}/>
        <ArchivedCaseOetInfoCard data={currentOetEntry ?? {}}/>
      </div>
    </>
  );
};

export default ArchivedCaseOetReview
