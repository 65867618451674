import React, { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Table } from 'antd';
import moment from 'moment';
import { PATHWAY6_DATE_FORMAT, CEX_STATUS } from '../../../../../constants';

const columns = [
  {
    title: 'Status',
    dataIndex: 'state',
    key: 'state',
    render: (status: any, record: any) => (
      <div>{record.state !== undefined ? getStatus(CEX_STATUS, record.state) : ''}</div>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (timestamp: any, record: any) => (
      <div>
        {record.timestamp !== undefined
          ? moment(record.timestamp).utc().format(`${PATHWAY6_DATE_FORMAT}, h:mm:ss a`)
          : ''}
      </div>
    ),
  },
];

const getStatus = (obj: any, key: any) => {
  return obj[key];
};

const Pathway6MiniCEXStatusHistoryPropTypes = {
  stateModel: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  encounterId: PropTypes.string.isRequired,
};

export type Pathway6MiniCEXStatusHistoryProps = InferProps<
  typeof Pathway6MiniCEXStatusHistoryPropTypes
>;
const Pathway6MiniCEXStatusHistory: FC<Pathway6MiniCEXStatusHistoryProps> = ({
  stateModel,
  encounterId,
}) => {
  return (
    <>
      Encounter ID: {encounterId}
      <br />
      <br />
      <Table dataSource={stateModel} columns={columns} size="small" />
      <p style={{ fontSize: '11px', marginTop: '10px' }}>
        All times are calculated using Coordinated Universal Time (UTC)
      </p>
    </>
  );
};

export default Pathway6MiniCEXStatusHistory;
