import React, { FC } from 'react';
import { Divider, Space } from 'antd';

const ReviewPathway6Information: FC<any> = () => {
  return (
    <>
      <div>
        <Divider plain orientation="left">
          <Space size="middle">
            <b>Pathway 6</b>
          </Space>
        </Divider>

        <div>
          I am applying to Pathway 6. I certify that I have read and understand the eligibility
          requirements for Pathway 6.
        </div>
      </div>
    </>
  );
};

export default {
  Component: ReviewPathway6Information,
};
