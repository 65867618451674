import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  message,
  Spin,
  Tabs,
  Descriptions,
  Button,
  Modal,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  Input,
  Divider,
  DatePicker,
  Select,
  Alert,
  Tag,
  Typography,
  Form,
  Popover,
  Space,
} from 'antd';
import { EditOutlined, ReloadOutlined, SyncOutlined } from '@ant-design/icons';
import '../../../App.css';
import { useMutation, useQuery } from '@apollo/client/react';
import PhysicianCompletedTable from './physician-completed-table';
import PhysicianActiveTable from './physician-active-table';
import ActivityFeed from '../../case-management/activity-feed';
import { LicenseCardList } from '../../case-management/pathways/pathway6/pathway6-document-review/physician-license-card-list';
import { DATE_FORMAT, getPhysicianName, TAG_STATUSES, TAG_STATUS_MAPPING } from '../../constants';
import { Helmet } from 'react-helmet';
import {
  CaseMgmtPhysicianDetailCasePortalDocument,
  PhysicianLicenseRecordType,
  MiniCexResponseType,
  ActivityLogType,
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptOutDocument,
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptInDocument,
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditNameDocument,
  PhysicianResponseType,
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditDoBDocument,
  CasePortal_PhyMgmt_BlockDocument,
  TagsDocument,
  CasePortal_PhyMgmt_UnblockDocument,
  A_Applicant,
  PhysicianDetailCasePortalPhyMgmtSnyncRegistrationDocument,
} from '../../../generated';
import moment from 'moment';
import EmailList from './secondary-email-list/email-list';

const { TabPane } = Tabs;
const { Option } = Select;
const { Title } = Typography;

const dashboardStates = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
};

const optOptions = {
  OPT_IN: 'Opt In',
  OPT_OUT: 'Opt Out',
};

const STATUS_TAGS = [TAG_STATUSES.BLOCKED]; // for dropdown

export interface IPhysicianDetailData {
  casePortal: {
    physician: {
      oid: string;
      allowedActions: string[];
      email: string;
      physicianId: string;
      dataVersion: number;
      applicantsEvaluatedByPhysician: {
        usmleId: string;
      };
      configActive: {
        minicex: {
          maxApplicantsAllowedToBeEvaluatedByPhysician: number;
        };
      };
      minicex: {
        accountStatus: string;
        profileStatus: string;
      };
      profile: {
        lastName: string;
        restOfName: string;
        licenses: {
          licenseNumber: string;
          orgName: string;
          physicianRegAuthorityRef: number;
          addedAt: string;
          removedAt: string;
          countryName: string;
        }[];
      };
      activityLog: {
        timestamp: string;
        user: {
          role: string;
          name: string;
        };
        logType: string;
        message: string;
      }[];
    };
    minicexByPhysician: {
      dataVersion: number;
      encounterId: string;
      summary: {
        dataVersion: number;
        allowedActions: string[];
        physicianEmail: string;
        physicianLastNameProvidedByApplicant: string;
        physicianRestOfNameProvidedByApplicant: string;
        physicianLastNameProvidedByPhysician: string;
        physicianRestOfNameProvidedByPhysician: string;
        applicantLastName: string;
        applicantRestOfName: string;
        usmleId: string;
        encounterId: string;
        state: string;
        acceptedAt: string;
        createdAt: string;
        completedAt: string;
        expiresAt: string;
        stateModel: {
          state: string;
          timestamp: string;
        }[];
        isActive: boolean;
        rejectionReason: string;
        rejectionReasonComments: string;
      };
      form: {
        encounter: {
          encounterDate: string;
          encounterSetting: string;
          facility: {
            name: string;
            city: string;
            state: string;
            country: {
              name: string;
            };
          };
          patient: {
            age: number;
            gender: string;
          };
        };
        evaluation: {
          categories: {
            name: string;
            score: number;
            reasonText: string;
            isAdditionalInfoRequired: boolean;
          }[];
          additionalInfoText: string;
        };
      };
    }[];
  };
}

export interface IPhysicianDetailVars {
  email: string;
  responseType: string;
}

export interface IEditNameData {
  dataVersion: number;
  profile: {
    lastName: string;
    restOfName: string;
  };
}

export interface IEditNameVars {
  dataVersion?: number;
  email: string;
  input: PhysicianNameInput;
}

interface PhysicianNameInput {
  email: string;
  lastName: string;
  restOfName: string;
}
export interface IEditDateOfBirthData {
  dataVersion: number;
  profile: {
    dateOfBirth: string;
  };
}

export interface IEditDateOfBirthVars {
  dataVersion?: number;
  email: string;
  input: PhysicianDateOfBirthInput;
}
interface PhysicianDateOfBirthInput {
  dateOfBirth: string;
}

interface IBlockPhysicianVars {
  dataVersion: number;
  email: string;
}

export interface IProps {
  casePortal: {
    __typename?: 'CasePortalResponseType';
    physician: {
      __typename?: 'PhysicianResponseType';
      oid: string;
      allowedActions: string[];
      email: string;
      physicianId: string;
      dataVersion: number;
      configActive: {
        __typename?: 'PhysicianConfigType';
        minicex: {
          __typename?: 'PhysicianConfigMiniCEXType';
          maxApplicantsAllowedToBeEvaluatedByPhysician: number;
        };
      };
      applicantsEvaluatedByPhysician: {
        __typename?: [A_Applicant];
        usmleId: string;
      };
      minicex: {
        __typename?: 'PhysicianMinicexAccountType';
        accountStatus: string;
        profileStatus: string;
      };
      profile: {
        __typename?: 'PhysicianProfileType';
        lastName?: string;
        restOfName?: string;
        dateOfBirth?: Date;
        isHoldingFacultyAppointment?: boolean;
        medicalSpecialty?: string;
        secondaryEmails?: string[];
        usmleId?: string;
        licenses: {
          __typename?: 'PhysicianLicenseRecordType';
          licenseNumber: string;
          orgName: string;
          physicianRegAuthorityRef: number;
        }[];
      };
      activityLog: {
        __typename?: 'ActivityLogType';
        timestamp: string;
        user: {
          __typename?: 'ActivityLogUserType';
          role: string;
          name: string;
        };
        logType: string;
        message: string;
      }[];
    };
    minicexByPhysician: [];
  };
}

const PhysicianDetail: FC<any> = (props) => {
  let { physicianEmail } = useParams<any>();
  const [selectedView, setSelectedView] = useState<string>(dashboardStates.PENDING);
  const [dataVersion, setDataVersion] = useState<number>();
  const [showOptOutModal, setShowOptOutModal] = useState<boolean>(false);
  const [editPhysicianNameModalVisible, setEditPhysicianNameModalVisible] =
    useState<boolean>(false);
  const [editPhysicianDateofBirthModalVisible, setEditPhysicianDateofBirthModalVisible] =
    useState<boolean>(false);
  const [editLastNameText, setEditLastNameText] = useState<string>('');
  const [editRestOfNameText, setEditRestOfNameText] = useState<string>('');
  const [confirmEmailText, setConfirmEmailText] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<any>();
  const [lastNameLabelColor, setLastNameLabelColor] = useState<string>('black');
  const [confirmEmailLabelColor, setConfirmEmailLabelColor] = useState<string>('black');
  const [dateOfBirthLabelColor, setDateOfBirthLabelColor] = useState<string>('black');
  const { loading, data, error } = useQuery(CaseMgmtPhysicianDetailCasePortalDocument, {
    variables: { email: physicianEmail as string, responseType: selectedView },
  });
  const [selectedStatusTags, setSelectedStatusTags] = useState<string[]>([]);
  const [statusTagOptions, setStatusTagOptions] = useState<{ key: string; value: string }[]>([]);
  const [isBlockPhysicianModalVisible, setIsBlockPhysicianModalVisible] = useState(false);
  const [isUnblockPhysicianModalVisible, setIsUnblockPhysicianModalVisible] = useState(false);
  const [confirmedPrimaryEmail, setConfirmedPrimaryEmail] = useState('');

  const {
    loading: getStatusTagsLoading,
    data: getStatusTagsData,
    error: getStatusTagsError,
  } = useQuery(TagsDocument, {
    variables: {
      email: physicianEmail as string,
    },
  });
  const [unblockForm] = Form.useForm();
  const [blockForm] = Form.useForm();
  const [optOut] = useMutation(CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptOutDocument, {
    refetchQueries: [
      {
        query: CaseMgmtPhysicianDetailCasePortalDocument,
        variables: { email: physicianEmail, responseType: selectedView },
      },
    ],
  });

  const [optIn] = useMutation(CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptInDocument, {
    refetchQueries: [
      {
        query: CaseMgmtPhysicianDetailCasePortalDocument,
        variables: { email: physicianEmail, responseType: selectedView },
      },
    ],
  });

  const [editName] = useMutation<IEditNameData, IEditNameVars>(
    CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditNameDocument,
    {
      refetchQueries: [
        {
          query: CaseMgmtPhysicianDetailCasePortalDocument,
          variables: { email: physicianEmail, responseType: selectedView },
        },
      ],
    }
  );
  const [editDateOfBirth] = useMutation<IEditDateOfBirthData, IEditDateOfBirthVars>(
    CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditDoBDocument,
    {
      refetchQueries: [
        {
          query: CaseMgmtPhysicianDetailCasePortalDocument,
          variables: { email: physicianEmail, responseType: selectedView },
        },
      ],
    }
  );

  const [blockPhysician] = useMutation(CasePortal_PhyMgmt_BlockDocument, {
    refetchQueries: [
      {
        query: CaseMgmtPhysicianDetailCasePortalDocument,
        variables: { email: physicianEmail, responseType: selectedView },
      },
    ],
  });

  const [unblockPhysician] = useMutation(CasePortal_PhyMgmt_UnblockDocument, {
    refetchQueries: [
      {
        query: CaseMgmtPhysicianDetailCasePortalDocument,
        variables: { email: physicianEmail, responseType: selectedView },
      },
    ],
  });

  const [syncRegistration, { loading: syncRegistrationLoading }] = useMutation(
    PhysicianDetailCasePortalPhyMgmtSnyncRegistrationDocument,
    {
      refetchQueries: [
        {
          query: CaseMgmtPhysicianDetailCasePortalDocument,
          variables: { email: physicianEmail, responseType: selectedView },
        },
      ],
    }
  );

  const onPreventMouseDown = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    setStatusTagOptions(
      TAG_STATUS_MAPPING.map((status) => {
        return { key: status.value, value: status.value };
      })
    );
  }, [selectedStatusTags]);

  useEffect(() => {
    setDataVersion(data?.casePortal?.physician?.dataVersion!);

    //Set controls in edit name modal
    if (typeof data?.casePortal?.physician?.profile?.lastName !== 'undefined') {
      setEditLastNameText(data?.casePortal?.physician?.profile?.lastName!);
    }
    if (typeof data?.casePortal?.physician?.profile?.restOfName !== 'undefined') {
      setEditRestOfNameText(data?.casePortal?.physician?.profile?.restOfName!);
    }

    //Set controls in edit date of birth modal
    if (typeof data?.casePortal?.physician?.profile?.dateOfBirth !== 'undefined') {
      setDateOfBirth(moment(data?.casePortal?.physician?.profile?.dateOfBirth!, 'YYYY-MM-DD'));
    }
    setConfirmEmailLabelColor('black');
  }, [data]);

  useEffect(() => {
    if (getStatusTagsData) {
      let tags = getStatusTagsData.casePortal?.physician?.minicex?.tags?.actionTags;
      //map tags from data
      updateStatusTags(tags);
    }
  }, [getStatusTagsData]);

  const onOptIn = async () => {
    await optIn({
      variables: {
        dataVersion: dataVersion!,
        email: physicianEmail as string,
      },
    })
      .then((data: any) => {
        message.success(`Successfully Opted In`);
      })
      .catch((error: any) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onOptOut = async () => {
    await optOut({
      variables: {
        dataVersion: dataVersion!,
        email: physicianEmail as string,
      },
    })
      .then((data: any) => {
        message.success(`Successfully Opted Out`);
      })
      .catch((error: any) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onEditName = async () => {
    await editName({
      variables: {
        dataVersion: dataVersion ? +dataVersion : -1,
        email: physicianEmail as string,
        input: {
          email: confirmEmailText.trim(),
          lastName: editLastNameText.trim(),
          restOfName: editRestOfNameText.trim(),
        },
      },
    })
      .then((editNameData) => {
        setEditPhysicianNameModalVisible(false);
        message.success(`Successfully Edited Physician Name`);
        onCloseEditNameModal();
      })
      .catch((editNameError: any) => {
        //only grab the first error
        const graphQLErrorReference =
          editNameError?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${editNameError.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };
  const onEditDateOfBirth = async () => {
    await editDateOfBirth({
      variables: {
        dataVersion: dataVersion ? +dataVersion : -1,
        email: physicianEmail as string,
        input: {
          dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
        },
      },
    })
      .then((editDateOfBirthData) => {
        setEditPhysicianDateofBirthModalVisible(false);
        message.success(`Successfully Edited Physician Date of Birth`);
        onCloseEditDateOfBirthModal();
      })
      .catch((editDoBError: any) => {
        //only grab the first error
        const graphQLErrorReference = editDoBError?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${editDoBError.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const renderOptButton = () => {
    const opt = data?.casePortal?.physician?.allowedActions;
    var optType;
    if (opt?.includes('optIn')) {
      optType = optOptions.OPT_IN;
    } else if (opt?.includes('optOut')) {
      optType = optOptions.OPT_OUT;
    }

    return (
      <>
        {optType === optOptions.OPT_IN ? (
          <Popconfirm onConfirm={(e) => onOptIn()} title="Are you sure?">
            <Button type="primary">Opt In</Button>
          </Popconfirm>
        ) : null}
        {optType === optOptions.OPT_OUT ? (
          <Button type="primary" onClick={(e) => setShowOptOutModal(true)}>
            Opt Out
          </Button>
        ) : null}
      </>
    );
  };

  const renderEditNameButton = () => {
    const opt = data?.casePortal?.physician?.allowedActions;
    if (opt?.includes('editName')) {
      return (
        <Tooltip placement="top" title={'Edit Name'} mouseEnterDelay={1}>
          <EditOutlined
            style={{ marginLeft: '5px', color: '#3f4373' }}
            onClick={() => setEditPhysicianNameModalVisible(true)}
          />
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };

  const renderEditDoBButton = () => {
    const opt = data?.casePortal?.physician?.allowedActions;
    if (opt?.includes('editDoB')) {
      return (
        <Tooltip placement="top" title={'Edit Date of Birth'} mouseEnterDelay={1}>
          <EditOutlined
            style={{ marginLeft: '5px', color: '#3f4373' }}
            onClick={() => setEditPhysicianDateofBirthModalVisible(true)}
          />
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };

  const applicantName = () => {
    const profile = data?.casePortal?.physician?.profile;
    return getPhysicianName(profile?.lastName, profile?.restOfName);
  };

  const onChangeLastNameText = (value: string) => {
    if (value.trim() !== '') {
      setLastNameLabelColor('black');
    }
  };

  const onClickConfirmNameEdit = () => {
    if (editLastNameText.trim() === '') {
      message.error('Last Name is required');
      setLastNameLabelColor('red');
    } else {
      if (
        confirmEmailText.trim().toLowerCase() === data?.casePortal?.physician?.email?.toLowerCase()
      ) {
        onEditName();
      } else {
        if (confirmEmailText.trim().length > 0) {
          message.error('Physician primary e-mail does not match');
        } else {
          message.error('Please confirm Physician primary e-mail');
        }
        //Highlight text red when user enters email incorrectly
        setConfirmEmailLabelColor('red');
      }
    }
  };
  const onClickConfirmDateOfBirthEdit = () => {
    if (dateOfBirth === undefined) {
      message.error('Date of Birth is required');
      setDateOfBirthLabelColor('red');
    } else {
      if (
        confirmEmailText.trim().toLowerCase() === data?.casePortal?.physician?.email?.toLowerCase()
      ) {
        onEditDateOfBirth();
      } else {
        if (confirmEmailText.trim().length > 0) {
          message.error('Physician primary e-mail does not match');
        } else {
          message.error('Please confirm Physician primary e-mail');
        }
        //Highlight text red when user enters email incorrectly
        setConfirmEmailLabelColor('red');
      }
    }
  };

  const onCloseEditNameModal = () => {
    setConfirmEmailText('');
    setLastNameLabelColor('black');
    setConfirmEmailLabelColor('black');
    if (data?.casePortal?.physician?.profile?.lastName) {
      setEditLastNameText(data?.casePortal?.physician?.profile?.lastName);
    }
    if (data?.casePortal?.physician?.profile?.restOfName) {
      setEditRestOfNameText(data?.casePortal?.physician?.profile?.restOfName);
    }
  };

  const onCloseEditDateOfBirthModal = () => {
    setConfirmEmailText('');
    setDateOfBirthLabelColor('black');
    if (data?.casePortal?.physician?.profile?.dateOfBirth) {
      setDateOfBirth(moment(data?.casePortal?.physician?.profile?.dateOfBirth, 'YYYY-MM-DD'));
    }
  };

  const convertDataVersionToNumber = (dataVersionToConvert: string | undefined) => {
    if (dataVersionToConvert) {
      return +dataVersionToConvert;
    } else {
      return -1;
    }
  };
  const applicantLimit = () => {
    let applicants = data?.casePortal?.physician?.applicantsEvaluatedByPhysician!;
    let maxApplicants =
      data?.casePortal?.physician?.physicianConfig?.config?.minicex
        ?.maxApplicantsAllowedToBeEvaluatedByPhysician!;
    return applicants?.length >= maxApplicants;
  };

  const licenseCardList = () => {
    return (
      <>
        <Title level={2}>Licensure Information</Title>
        {!!data?.casePortal?.physician?.profile?.licenses ? (
          <LicenseCardList
            licenses={data.casePortal.physician.profile.licenses as PhysicianLicenseRecordType[]}
            isEditable={false}
          />
        ) : null}
      </>
    );
  };

  const physicianActiveTable = () => {
    return (
      <>
        {!!data?.casePortal?.minicexByPhysician ? (
          <PhysicianActiveTable
            minicexList={data.casePortal.minicexByPhysician as MiniCexResponseType[]}
            refetchQuery={CaseMgmtPhysicianDetailCasePortalDocument}
          />
        ) : null}
      </>
    );
  };

  const physicianClosedTable = () => {
    return (
      <>
        {!!data?.casePortal?.minicexByPhysician && !!data?.casePortal?.physician ? (
          <PhysicianCompletedTable
            minicexList={data.casePortal.minicexByPhysician as MiniCexResponseType[]}
            physicianData={data.casePortal.physician as PhysicianResponseType}
          />
        ) : null}
      </>
    );
  };

  const activityFeed = () => {
    return (
      <>
        <Divider plain orientation="left">
          Activity Feed
        </Divider>
        {!!data?.casePortal?.physician?.activityLog ? (
          <ActivityFeed.Component
            activityLog={data.casePortal.physician.activityLog as ActivityLogType[]}
            parentDataVersion={convertDataVersionToNumber(dataVersion?.toString())}
            refetch={async () => {
              window.location.reload();
            }}
            onUpdateDataVersion={() => {}}
            showAddCaseNote={false}
          />
        ) : null}
      </>
    );
  };

  //map tags from data
  const updateStatusTags = (tags: (string | null | undefined)[] | undefined | null) => {
    var mappedStatusTags: string[] = [];
    if (tags) {
      tags.forEach((tag) => {
        switch (tag) {
          //blocked
          case TAG_STATUS_MAPPING[0].key: {
            mappedStatusTags.push(TAG_STATUS_MAPPING[0].value);
          }
        }
      });
    }

    setSelectedStatusTags(mappedStatusTags);
  };

  const tagRender = (tag: any) => {
    switch (tag.value) {
      //blocked
      case TAG_STATUS_MAPPING[0].value: {
        return (
          <Tag
            color={'red'}
            onMouseDown={onPreventMouseDown}
            closable={tag.closable}
            onClose={onStatusTagRemoved}
            visible={true}
          >
            {tag.value}
          </Tag>
        );
      }
      default: {
        return <Tag>{TAG_STATUS_MAPPING[0].value}</Tag>;
      }
    }
  };

  const onStatusSelected = (item: any) => {
    //blocked
    if (item === TAG_STATUS_MAPPING[0].value) {
      onSelectBlockPhysician();
    }
  };

  const onStatusTagRemoved = (item: any) => {
    setIsUnblockPhysicianModalVisible(true);
  };

  const onSelectBlockPhysician = () => {
    setIsBlockPhysicianModalVisible(true);
  };

  const onConfirmBlockPhysician = async () => {
    blockForm.validateFields(['blockConfirmedEmail']).then(async (values: any) => {
      if (confirmedPrimaryEmail && confirmedPrimaryEmail === data?.casePortal?.physician?.email) {
        await blockPhysician({
          variables: {
            dataVersion: dataVersion ? +dataVersion : -1,
            email: physicianEmail as string,
          },
        })
          .then((response: any) => {
            console.log(response);
            let tags = response.data.CasePortal_PhyMgmt_block.minicex.tags.actionTags;
            updateStatusTags(tags);
            setIsBlockPhysicianModalVisible(false);
            setConfirmedPrimaryEmail('');
            blockForm.resetFields();
            message.success('Physician evaluator has been blocked');
          })
          .catch((error: any) => {
            //only grab the first error
            const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
            message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
          });
      } else {
        if (confirmedPrimaryEmail.trim().length > 0) {
          message.error('Physician primary e-mail does not match');
        }
      }
    });
  };

  const onConfirmUnblockPhysician = async () => {
    unblockForm.validateFields(['unblockConfirmedEmail']).then(async (values: any) => {
      if (confirmedPrimaryEmail && confirmedPrimaryEmail === data?.casePortal?.physician?.email) {
        await unblockPhysician({
          variables: {
            dataVersion: dataVersion ? +dataVersion : -1,
            email: physicianEmail as string,
          },
        })
          .then((response: any) => {
            console.log(response);
            let tags = response.data.CasePortal_PhyMgmt_unblock.minicex.tags.actionTags;
            updateStatusTags(tags);
            setIsUnblockPhysicianModalVisible(false);
            setConfirmedPrimaryEmail('');
            unblockForm.resetFields();
            message.success('Physician evaluator has been unblocked');
          })
          .catch((error: any) => {
            //only grab the first error
            const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
            message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
          });
      } else {
        if (confirmedPrimaryEmail.trim().length > 0) {
          message.error('Physician primary e-mail does not match');
        }
      }
    });
  };
  const onRefreshAccountStatusClick = async () => {
    syncRegistration({
      variables: {
        email: physicianEmail as string,
        dataVersion: dataVersion ? +dataVersion : -1,
      },
    })
      .then((response: any) => {
        message.success('Account status has been refreshed');
      })
      .catch((error: any) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const renderDescriptions = () => {
    return !!data?.casePortal?.physician?.profile &&
      !!data?.casePortal?.physician?.email &&
      !!data?.casePortal?.physician?.minicex?.accountStatus ? (
      <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical" column={5}>
        <Descriptions.Item label="Name">
          {applicantName()}
          {renderEditNameButton()}
        </Descriptions.Item>
        <Descriptions.Item label="Primary E-mail">
          {data.casePortal.physician.email ?? 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Space>
            <Typography.Text>{data.casePortal.physician.minicex.accountStatus}</Typography.Text>

            {data.casePortal.physician.minicex.accountStatus === 'INVITED' ? (
              <Tooltip title="Refresh Status">
                <Button
                  loading={syncRegistrationLoading}
                  onClick={onRefreshAccountStatusClick}
                  type="link"
                  icon={
                    <SyncOutlined
                      style={{ color: 'green', fontSize: '12px', fontWeight: 'bold' }}
                    />
                  }
                ></Button>
              </Tooltip>
            ) : null}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="USMLE ID / ECFMG ID">
          {data.casePortal.physician.profile.usmleId ?? 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item>{''}</Descriptions.Item>

        <Descriptions.Item label="Date of Birth">
          {data.casePortal.physician.profile.dateOfBirth
            ? moment(data.casePortal.physician.profile.dateOfBirth).format(DATE_FORMAT)
            : 'N/A'}
          {renderEditDoBButton()}
        </Descriptions.Item>
        <Descriptions.Item label="Medical Specialty">
          {data.casePortal.physician.profile.medicalSpecialty ?? 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Holds Faculty Appointment">
          {data.casePortal.physician.profile.isHoldingFacultyAppointment
            ? data.casePortal.physician.profile.isHoldingFacultyAppointment
            : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Additional E-mail">
          {!!data.casePortal.physician.profile.secondaryEmails &&
          data.casePortal.physician.profile.secondaryEmails.length > 0 ? (
            <EmailList
              secondaryEmails={data.casePortal.physician.profile.secondaryEmails as string[]}
            ></EmailList>
          ) : (
            'N/A'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Action Tags">
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            showArrow
            onSelect={onStatusSelected}
            onDeselect={onStatusTagRemoved}
            onMouseDown={onPreventMouseDown}
            tagRender={tagRender}
            value={selectedStatusTags}
            options={statusTagOptions}
          />
        </Descriptions.Item>
      </Descriptions>
    ) : null;
  };

  const renderOptOutDescriptions = () => {
    return !!data?.casePortal?.physician?.profile && data?.casePortal?.physician?.email ? (
      <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
        <Descriptions.Item label="Name">{applicantName()}</Descriptions.Item>
        <Descriptions.Item label="Primary E-mail">
          {data.casePortal.physician.email ?? 'N/A'}
        </Descriptions.Item>
      </Descriptions>
    ) : null;
  };

  return (
    <>
      <Helmet>
        <title>Physician Detail</title>
      </Helmet>
      {loading || getStatusTagsLoading ? (
        <Spin></Spin>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <Title level={2}>
                Physician Details <div style={{ float: 'right' }}> {renderOptButton()}</div>
              </Title>
              <div style={{ display: 'flex' }}>{renderDescriptions()}</div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>{licenseCardList()}</Col>
          </Row>
          {applicantLimit() ? (
            <Row>
              <Col span={24}>
                <Alert
                  message={
                    'Physician cannot receive anymore new applicants because maximum applicant limit has been reached'
                  }
                  type="warning"
                  showIcon
                ></Alert>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col span={24}>
              <Tabs defaultActiveKey={selectedView} onChange={(key: any) => setSelectedView(key)}>
                <TabPane key={dashboardStates.PENDING} tab="Active">
                  {physicianActiveTable()}
                </TabPane>
                <TabPane key={dashboardStates.COMPLETED} tab="Closed">
                  {physicianClosedTable()}
                </TabPane>
              </Tabs>
            </Col>
          </Row>
          <Row style={{ marginTop: -25 }}>
            <Col offset={12}>{activityFeed()}</Col>
          </Row>

          <Modal
            visible={showOptOutModal}
            closable={false}
            footer={[
              <Button
                onClick={(e) => {
                  setShowOptOutModal(false);
                }}
              >
                Cancel
              </Button>,
              <Popconfirm
                title="Are you sure?"
                onConfirm={(e) => {
                  onOptOut();
                  setShowOptOutModal(false);
                }}
              >
                <Button key="submit" type="primary" danger>
                  Yes, Opt-Out Evaluator
                </Button>
              </Popconfirm>,
            ]}
          >
            <>
              <div>Are you sure you want to opt-out this evaluator?</div>
              <div style={{ border: 'solid', borderWidth: '1px' }}>
                {renderOptOutDescriptions()}
              </div>
              <p>
                <i>
                  Warning: Opting out the evaluator will reject all of their pending and in progress
                  Mini-CEX requests
                </i>
              </p>
            </>
          </Modal>
          <Modal
            visible={editPhysicianNameModalVisible}
            title={'Physician Name Edit'}
            width={'400px'}
            closable={false}
            destroyOnClose
            onCancel={() => {
              setEditPhysicianNameModalVisible(false);
              onCloseEditNameModal();
            }}
            footer={[
              <Button
                onClick={() => {
                  setEditPhysicianNameModalVisible(false);
                  onCloseEditNameModal();
                }}
              >
                Cancel
              </Button>,
              <Button type="primary" onClick={() => onClickConfirmNameEdit()}>
                Confirm
              </Button>,
            ]}
          >
            <Row>
              <Col>{`Primary E-mail: ${data?.casePortal?.physician?.email}`}</Col>
            </Row>
            <Row>
              <Col>
                <div style={{ color: lastNameLabelColor }}>{'Last Name:'}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="text"
                  value={editLastNameText}
                  onChange={(e) => {
                    setEditLastNameText(e.target.value);
                    onChangeLastNameText(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>{'Rest of Name:'}</Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="text"
                  value={editRestOfNameText}
                  onChange={(e) => setEditRestOfNameText(e.target.value)}
                ></Input>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <div style={{ color: confirmEmailLabelColor }}>
                  {"Enter Physician's primary e-mail to confirm:"}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="text"
                  value={confirmEmailText}
                  onChange={(e) => setConfirmEmailText(e.target.value)}
                ></Input>
              </Col>
            </Row>
          </Modal>
          <Modal
            visible={editPhysicianDateofBirthModalVisible}
            title={'Physician Date of Birth Edit'}
            width={'400px'}
            closable={false}
            destroyOnClose
            onCancel={() => {
              setEditPhysicianDateofBirthModalVisible(false);
              onCloseEditDateOfBirthModal();
            }}
            footer={[
              <Button
                onClick={() => {
                  setEditPhysicianDateofBirthModalVisible(false);
                  onCloseEditDateOfBirthModal();
                }}
              >
                Cancel
              </Button>,
              <Button type="primary" onClick={() => onClickConfirmDateOfBirthEdit()}>
                Confirm
              </Button>,
            ]}
          >
            <Row>
              <Col>{`Primary E-mail: ${data?.casePortal?.physician?.email}`}</Col>
            </Row>
            <Row>
              <Col>
                <div style={{ color: dateOfBirthLabelColor }}>{'Date of Birth:'}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <DatePicker
                  format={DATE_FORMAT}
                  disabledDate={(currentDate: any) => {
                    return currentDate && currentDate.valueOf() > Date.now();
                  }}
                  allowClear={false}
                  value={moment(dateOfBirth, 'YYYY-MM-DD')}
                  onChange={(e: any) => {
                    setDateOfBirth(e);
                  }}
                ></DatePicker>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <div style={{ color: confirmEmailLabelColor }}>
                  {"Enter Physician's primary e-mail to confirm:"}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="text"
                  value={confirmEmailText}
                  onChange={(e) => setConfirmEmailText(e.target.value)}
                ></Input>
              </Col>
            </Row>
          </Modal>

          <Modal
            title="Block Physician Evaluator"
            width={'400px'}
            closable={false}
            destroyOnClose
            maskClosable={false}
            visible={isBlockPhysicianModalVisible}
            footer={[
              <Button
                onClick={() => {
                  setConfirmedPrimaryEmail('');
                  setIsBlockPhysicianModalVisible(false);
                  blockForm.resetFields();
                }}
              >
                Cancel
              </Button>,
              <Button type="primary" onClick={() => onConfirmBlockPhysician()}>
                Confirm
              </Button>,
            ]}
          >
            <Row>
              <Col>
                Primary E-mail: <b>{data?.casePortal?.physician?.email}</b>
              </Col>
            </Row>
            <Row>
              <Col>Are you sure you wish to block this physician evaluator's account?</Col>
            </Row>
            <br />

            <Row>
              <Col span={24}>
                <Form form={blockForm} id="blockForm" layout="vertical">
                  <Form.Item
                    label="Enter Physician's primary e-mail to confirm:"
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm Physician primary e-mail',
                      },
                    ]}
                    required
                    name="blockConfirmedEmail"
                  >
                    <Input
                      value={confirmedPrimaryEmail}
                      style={{ width: '100%' }}
                      onChange={(e: any) => {
                        setConfirmedPrimaryEmail(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>

          <Modal
            title="Unblock Physician Evaluator"
            width={'400px'}
            closable={false}
            destroyOnClose
            maskClosable={false}
            visible={isUnblockPhysicianModalVisible}
            footer={[
              <Button
                onClick={() => {
                  setConfirmedPrimaryEmail('');
                  setIsUnblockPhysicianModalVisible(false);
                  unblockForm.resetFields();
                }}
              >
                Cancel
              </Button>,
              <Button type="primary" onClick={() => onConfirmUnblockPhysician()}>
                Confirm
              </Button>,
            ]}
          >
            <Row>
              <Col>
                Primary E-mail: <b>{data?.casePortal?.physician?.email}</b>
              </Col>
            </Row>
            <Row>
              <Col>Are you sure you wish to unblock this physician evaluator's account?</Col>
            </Row>
            <br />

            <Row>
              <Col span={24}>
                <Form layout="vertical" form={unblockForm} id="unblockForm" name="unblockForm">
                  <Form.Item
                    label="Enter Physician's primary e-mail to confirm:"
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm Physician primary e-mail',
                      },
                    ]}
                    required
                    name="unblockConfirmedEmail"
                  >
                    <Input
                      value={confirmedPrimaryEmail}
                      style={{ width: '100%' }}
                      onChange={(e: any) => {
                        setConfirmedPrimaryEmail(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>
        </>
      )}
    </>
  );
};

export default PhysicianDetail;
