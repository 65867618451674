import React, { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Row, Col, Descriptions, Divider, message, Spin, Typography } from 'antd';
import ActivityFeed from '../../../activity-feed';
import OetdocumentReviewApplicantDetails from './oet-document-review-applicant-details';
import { useQuery, useMutation } from '@apollo/client';
import { AdditionalDocumentCardList } from '../../additional-document/additional-document-card-list';
import _ from 'lodash';
import { UploadDocument } from '../../upload-document/upload-document';
import { DATE_FORMAT } from '../../../../constants';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { case_ } from '../../../../schema/casePortal-case';
import {
  ActivityLogType,
  CaseMgmtOetDocumentReviewLayoutCasePortalAddAndDeleteAdditionDocumentsDocument,
  CaseMgmtOetDocumentReviewLayoutCasePortalDocument,
  CaseSummaryType,
  C_ApplicantType,
} from '../../../../../generated';
import ApplicantSummaryFactory from '../../applicant-summary/applicant-summary-factory';
import OetdocumentReviewChecklistFactory from './oet-document-review-checklist-factory';
const { Title } = Typography;

export interface ComponentProp {
  casePortal: {
    case: case_;
  };
}

const OetdocumentReviewLayout: FC<any> = (props) => {
  const navigate = useNavigate();
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const [disableActions, setDisableActions] = useState<boolean>(false);
  let { usmleId, documentId } = useParams<any>();

  const { loading, error, data, refetch } = useQuery(
    CaseMgmtOetDocumentReviewLayoutCasePortalDocument,
    {
      variables: { usmleId: usmleId },
    }
  );
  const [additionalDocuments, setAdditionalDocuments] = useState<any>([]);

  const [addAndDeleteAdditionalDocumentsMutation] = useMutation(
    CaseMgmtOetDocumentReviewLayoutCasePortalAddAndDeleteAdditionDocumentsDocument,
    {
      refetchQueries: [
        {
          query: CaseMgmtOetDocumentReviewLayoutCasePortalDocument,
          variables: { usmleId: usmleId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  useEffect(() => {
    if (data) {
      const applicantProvidedData =
        data?.casePortal?.case?.languageAssessment?.applicantProvidedData;
      const staffProvidedData = data?.casePortal?.case?.languageAssessment?.staffProvidedData;
      const joinData = applicantProvidedData?.concat(staffProvidedData ?? {});
      setSelectedDocument(joinData?.find((record: any) => record._id === documentId));
      setDisableActions(
        data?.casePortal?.case?.languageAssessment?.applicantProvidedData?.[0]?.review?.status?.toUpperCase() ===
          'APPROVED' ||
          data?.casePortal?.case?.languageAssessment?.applicantProvidedData?.[0]?.review?.status?.toUpperCase() ===
            'REJECTED' ||
          data?.casePortal?.case?.caseSummary?.englishEligibilityStatus?.toUpperCase() ===
            'COMPLETED'
      );
    }
    if (data?.casePortal?.case?.caseSummary?.dataVersion) {
      setDataVersion(data.casePortal.case.caseSummary.dataVersion);
    }
    const retrievedAdditionalDocuments: any = [];
    _.forEach(data?.casePortal?.case?.additionalDocuments, (element) => {
      retrievedAdditionalDocuments.push(element?.document);
    });
    setAdditionalDocuments(retrievedAdditionalDocuments);
  }, [data]);

  const onUpdateDataVersionFromChild = async (dataVersion: number) => {
    setDataVersion(dataVersion);
  };

  //Get the blob name for azure storage by saving in mongo
  async function getBlobname(filename: string, docType: string) {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));
    var uniqueDocIds: any = [];
    deepCopyDocuments.map((doc: any) => {
      uniqueDocIds.push(doc.docId);
      delete doc.__typename;
    });
    const documentToUpload = {
      docType: docType,
      title: filename,
    };
    deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
      return {
        document: element,
      };
    });
    deepCopyDocuments.push({ document: documentToUpload });
    return addAndDeleteAdditionalDocumentsMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        input: deepCopyDocuments,
      },
    })
      .then((data) => {
        setDataVersion(data?.data?.CasePortal_additionalDocuments?.caseSummary?.dataVersion);
        const addedDocuments = data?.data?.CasePortal_additionalDocuments?.additionalDocuments; //debug this

        var docs: any = [];
        _.forEach(addedDocuments, (data) => {
          docs.push(data?.document);
        });
        var thisDocGuid = '';
        docs.map((doc: any) => {
          //The most recent document won't exist in the uniqueDocIds, so that is the one for this document
          if (!uniqueDocIds.includes(doc.docId)) {
            thisDocGuid = doc.docId;
          }
        });
        setAdditionalDocuments(docs);
        refetch();
        return thisDocGuid;
      })
      .catch((error) => {
        refetch();
        console.log(error);
        return 'error';
      });
  }

  const onDeleteDocument = async (docId: any) => {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));

    deepCopyDocuments = _.remove(deepCopyDocuments, (document: any) => {
      return document.docId !== docId;
    });

    deepCopyDocuments.map((doc: any) => {
      delete doc.__typename;
    });

    deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
      return {
        document: element,
      };
    });

    return addAndDeleteAdditionalDocumentsMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        input: deepCopyDocuments,
      },
    })
      .then((res: any) => {
        message.success('Delete successfully!');
        refetch();
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        refetch();
      });
  };

  const [form] = Form.useForm();

  if (loading) {
    return <Spin></Spin>;
  }

  return (
    <>
      <Helmet>
        <title>OET Review</title>
      </Helmet>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={24} lg={24} xl={12}>
          {!!data?.casePortal?.case?.applicant && !!data?.casePortal?.case?.caseSummary ? (
            <OetdocumentReviewApplicantDetails.Component
              applicant={data.casePortal.case.applicant}
              caseSummary={data.casePortal.case.caseSummary}
            />
          ) : null}
          <Divider plain orientation="center">
            <b>OET Information</b>
          </Divider>
          {data?.casePortal?.case?.oetScore?.listeningScore !== null ||
          data?.casePortal?.case?.oetScore?.readingScore !== null ||
          data?.casePortal?.case?.oetScore?.writingScore !== null ||
          data?.casePortal?.case?.oetScore?.speakingScore !== null ? (
            <div>
              <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
                <Descriptions.Item label="Name">{`${data?.casePortal?.case?.oetScore?.examineeLastName}, ${data?.casePortal?.case?.oetScore?.examineeFirstName}`}</Descriptions.Item>
                <Descriptions.Item label="Listening Score">
                  {data?.casePortal?.case?.oetScore?.listeningScore}
                </Descriptions.Item>
                <Descriptions.Item label="Reading Score">
                  {data?.casePortal?.case?.oetScore?.readingScore}
                </Descriptions.Item>
                <Descriptions.Item label="Writing Score">
                  {data?.casePortal?.case?.oetScore?.writingScore}
                </Descriptions.Item>
                <Descriptions.Item label="Speaking Score">
                  {data?.casePortal?.case?.oetScore?.speakingScore}
                </Descriptions.Item>
                <Descriptions.Item label="Outcome">
                  {data?.casePortal?.case?.oetScore?.result}
                </Descriptions.Item>
                <Descriptions.Item label="Date of Birth">
                  {data?.casePortal?.case?.oetScore?.dateOfBirth
                    ? moment(data?.casePortal?.case?.oetScore?.dateOfBirth).format(DATE_FORMAT)
                    : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Exam Date">
                  {data?.casePortal?.case?.oetScore?.examDate
                    ? moment(data?.casePortal?.case?.oetScore?.examDate).format(DATE_FORMAT)
                    : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="USMLE ID">
                  {data?.casePortal?.case?.oetScore?.usmleId?.padStart(8, '0')}
                </Descriptions.Item>
                <Descriptions.Item label="OET Applicant ID">
                  {data?.casePortal?.case?.oetScore?.candidateId}
                </Descriptions.Item>
                <Descriptions.Item label="Testing Center">
                  {data?.casePortal?.case?.oetScore?.testingCenter}
                </Descriptions.Item>
              </Descriptions>
            </div>
          ) : (
            <div>OET match has not been established.</div>
          )}
          <Divider plain orientation="center">
            <b>Additional Documents</b>
          </Divider>
          <Row>
            <Col span={23}>
              {additionalDocuments.length === 0 ? (
                <div>There is no additional document added.</div>
              ) : (
                <AdditionalDocumentCardList
                  numberOfCardsPerRow={3}
                  documents={additionalDocuments}
                  onDelete={(docId) => onDeleteDocument(docId)}
                  disabled={disableActions}
                />
              )}
              <UploadDocument
                getGuid={(filename) => getBlobname(filename, 'Additional Document')}
                showIcon={false}
                label="Add Document"
                //disabled because we want to allow document uploads at all times for supporting docs. Ex: refund supporting docs
                // disabled={disableActions}
              />
            </Col>
          </Row>

          <Divider plain orientation="center">
            <b>Applicant Provided Information (read only)</b>
          </Divider>
          <ApplicantSummaryFactory
            usmleID={usmleId}
            versionConfig={data?.casePortal?.case?.uiConfig}
          ></ApplicantSummaryFactory>
        </Col>

        <Col sm={24} lg={24} xl={12}>
          <div>
            {!!data?.casePortal?.case?.caseSummary && data?.casePortal?.case?.applicant ? (
              <OetdocumentReviewChecklistFactory
                versionConfig={data.casePortal.case.uiConfig}
                caseSummary={data.casePortal.case.caseSummary as CaseSummaryType}
                checklist={selectedDocument?.review?.checklist}
                checklistState={selectedDocument?.review?.checklistState}
                recordId={documentId as string}
                parentDataVersion={dataVersion}
                onUpdateDataVersion={(dataVersion: number) => {
                  onUpdateDataVersionFromChild(dataVersion);
                }}
                applicant={data.casePortal.case.applicant as C_ApplicantType}
                redirect={(url: string) => {
                  navigate(url);
                }}
                checklistStatus={selectedDocument?.review?.status}
              />
            ) : null}
            <br />
            <Title level={3}>Activity Feed</Title>
            <hr style={{ borderTop: '1px black solid' }} />
            {!!data?.casePortal?.case?.activityLog && !!data?.casePortal?.case?.caseSummary ? (
              <ActivityFeed.Component
                activityLog={data?.casePortal?.case?.activityLog as ActivityLogType[]}
                caseSummary={data?.casePortal?.case?.caseSummary!}
                parentDataVersion={dataVersion}
                onUpdateDataVersion={(dataVersion: number) => {
                  onUpdateDataVersionFromChild(dataVersion);
                }}
                refetch={() => {
                  refetch();
                }}
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OetdocumentReviewLayout;
