import React, { FC, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Modal, Divider, Button } from 'antd';
import moment from 'moment';
import PropTypes, { InferProps } from 'prop-types';
import { DATE_FORMAT } from '../../../constants';
import ViewPDFWrapper from '../../../ViewPDF-wrapper';
import { useFeatureFlags } from '../../../feature-flag-react-lite';

const ReviewCSExamPropTypes = {
  pathway2: PropTypes.shape({
    userResponse: PropTypes.shape({
      hasPassedCSExamInRecentPast: PropTypes.bool,
      hasPassedFromQualifiedSchoolInRecentPast: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.shape({
          oscExamRef: PropTypes.shape({
            _id: PropTypes.string,
            examCode: PropTypes.string,
            examShortTitle: PropTypes.string,
            examFullName: PropTypes.string,
          }),
          schoolRef: PropTypes.shape({
            _id: PropTypes.number,
            countryName: PropTypes.string,
            name: PropTypes.string,
          }),
          examDate: PropTypes.string,
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
};

export type ReviewCSExamProps = InferProps<typeof ReviewCSExamPropTypes>;

// Q-E
const ReviewCSExamInformationV2: FC<ReviewCSExamProps> = ({ pathway2 }) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const { GetFeatureFlagByName } = useFeatureFlags();

  var applicantProvidedData = undefined;
  if (pathway2?.applicantProvidedData && pathway2?.applicantProvidedData.length > 0) {
    applicantProvidedData = pathway2.applicantProvidedData[0]; //onlye one is submitted by user
  }
  const userResponse = pathway2?.userResponse;
  const examName = applicantProvidedData?.data?.oscExamRef?.examFullName ?? '';
  const examDate = applicantProvidedData?.data?.examDate ?? '';
  const hasPassedCSExamInRecentPast = pathway2?.userResponse?.hasPassedCSExamInRecentPast;
  const hasPassedFromQualifiedSchoolInRecentPast =
    pathway2?.userResponse?.hasPassedFromQualifiedSchoolInRecentPast;
  const documents = applicantProvidedData?.data?.documents as any;
  const schoolInformation = applicantProvidedData?.data?.schoolRef;
  const previewDocument = async (document: any) => {
    setSelectedDocument(document);
    setShowPreviewPdfModal(true);
  };

  const viewPdfClosed = async () => {
    setShowPreviewPdfModal(false);
  };

  return (
    <>
      {userResponse ? (
        <div>
          <Divider plain orientation="left">
            <b>Pathway 2</b>
          </Divider>
          <div>
            {hasPassedCSExamInRecentPast ? (
              <div>
                <p>
                  I successfully completed an OSCE required for medical licensure or registration on
                  or after January 1, 2018 that ECFMG has deemed acceptable for Pathway 2.
                </p>
                {examName ? (
                  <div>
                    {' '}
                    Exam name: <b> {examName}</b>
                    <br />
                    <br />
                  </div>
                ) : null}
                {examDate ? (
                  <div>
                    Exam date:<b> {moment(examDate).format(DATE_FORMAT)}</b>
                    <br />
                    <br />
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                <p id="has-passed-CSExam-in-recent-past-text">
                  I attend or have graduated from a medical school that either: A) administers an
                  OSCE specifically required by the medical regulatory authority (MRA) in that
                  country, and I have successfully completed that exam; OR B) is located in a
                  country where the MRA requires an OSCE for licensure of international medical
                  graduates but does not mandate that same licensure requirement for graduates of
                  domestic medical schools. My graduation date is on or after January 1, 2018.
                </p>
                <p>
                  Medical School: <b>{schoolInformation?.name}</b>
                </p>
                <p>
                  Graduation or Expected Graduation Date:{' '}
                  <b>
                    {moment(applicantProvidedData?.data?.examDate).format('MM/DD/YYYY').toString()}
                  </b>
                </p>
              </>
            )}
            <br />
            <br />
          </div>
          <div>
            {documents?.map((document: any) => {
              if (document.docType === 'Pathway2Document') {
                return (
                  <div key={document.docId}>
                    {hasPassedCSExamInRecentPast ? <div>Exam Documentation:</div> : null}
                    {hasPassedFromQualifiedSchoolInRecentPast ? (
                      <div>Final Medical Diploma: </div>
                    ) : null}
                    <Button
                      icon={<SearchOutlined />}
                      shape="round"
                      size="small"
                      onClick={() => previewDocument(document)}
                    >
                      {document.title}
                    </Button>
                    <br />
                    <br />
                  </div>
                );
              } else if (document.docType === 'Pathway2TranslatedDocument') {
                return (
                  <div key={document.docId}>
                    {hasPassedCSExamInRecentPast ? <div>Translated Exam Documentation:</div> : null}
                    {hasPassedFromQualifiedSchoolInRecentPast ? (
                      <div>Translated Medical Diploma: </div>
                    ) : null}
                    <Button
                      icon={<SearchOutlined />}
                      shape="round"
                      size="small"
                      onClick={() => previewDocument(document)}
                    >
                      {document.title}
                    </Button>
                    <br />
                    <br />
                  </div>
                );
              }
            })}

            <Modal
              title={selectedDocument?.title}
              visible={showPreviewPdfModal}
              width="fit-content"
              closable
              bodyStyle={{ minWidth: '20vw', maxHeight:'80vh' }}
              okText="Continue"
              footer={null}
              onCancel={() => viewPdfClosed()}
            >
              {showPreviewPdfModal ? (
                <ViewPDFWrapper
                  filename={selectedDocument.title}
                  docId={selectedDocument.docId}
                ></ViewPDFWrapper>
              ) : (
                ''
              )}
            </Modal>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default {
  Component: ReviewCSExamInformationV2,
};
