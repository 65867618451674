import React, { FC, useState } from 'react';
import { Descriptions, Card, Row, Col, Tooltip, Button, Space, Modal, Popconfirm } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import PropTypes, { InferProps } from 'prop-types';
import { Typography } from 'antd';
import ViewPDFWrapper from '../../../ViewPDF-wrapper';
const { Paragraph } = Typography;

const additionalDocumentCardPropTypes = {
  onDelete: PropTypes.func,
  displayValues: PropTypes.shape({
    docId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    docType: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export type AdditionalDocumentCardProps = InferProps<typeof additionalDocumentCardPropTypes>;

export const AdditionalDocumentCard: FC<AdditionalDocumentCardProps> = ({
  onDelete,
  displayValues,
  disabled,
}) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);

  return (
    <div className="space-align-block">
      <Card style={{ height: '132', width: '132' }}>
        <Descriptions title="" size="small" layout="vertical">
          <Descriptions.Item>
            <Tooltip title={displayValues.title}>
              <Paragraph strong ellipsis={{ rows: 2 }}>
                {displayValues.title}
              </Paragraph>
            </Tooltip>
          </Descriptions.Item>
        </Descriptions>
        <Row>
          <Col span="24" style={{ textAlign: 'right' }}>
            <Space>
              <Tooltip title="Preview">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<SearchOutlined />}
                  onClick={() => setShowPreviewPdfModal(true)}
                />
              </Tooltip>

              <Popconfirm
                title="Are you sure you want to delete this?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDelete && onDelete()}
                disabled={disabled}
              >
                <Tooltip title="Delete">
                  <Button shape="circle" icon={<DeleteOutlined />} disabled={disabled} />
                </Tooltip>
              </Popconfirm>
            </Space>
          </Col>
        </Row>
      </Card>

      <Modal
        title={displayValues.title}
        visible={showPreviewPdfModal}
        width="fit-content"
        closable
        bodyStyle={{ minWidth: '20vw', maxHeight:'80vh' }}
        okText="Continue"
        footer={null}
        onCancel={() => setShowPreviewPdfModal(false)}
      >
        {showPreviewPdfModal ? (
          <ViewPDFWrapper
            filename={displayValues.title}
            docId={displayValues.docId}
          ></ViewPDFWrapper>
        ) : (
          ''
        )}
      </Modal>
    </div>
  );
};

AdditionalDocumentCard.defaultProps = {
  onDelete: () => {},
};
