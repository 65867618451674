import { useQuery } from '@apollo/client';
import { FC, useEffect } from 'react';
import { ComponentQueryLoader } from '../../../utils/molecules/component-query-loader';
import { CaseMgmtCaseListFilterModelRegulatoryAuthorityCasePortalDocument, CaseMgmtCaseListFilterModelSchoolCasePortalDocument } from '../../../generated';
import CaseListFilterModal from "./case-list-filter-modal"

export interface CaseListFilterModalContainerProps {
    initialValues: any,
    caseFilterInput: any,
    initialViewList: any,
    onCancel: () => void,
    onSave: (filter: any, dashboardInput: any, updateview: any) => void,
    onDelete: (_id: any) => void,
    chooseViewCallback: (viewId: any) => void,
}
export const CaseListFilterModalContainer: FC<CaseListFilterModalContainerProps> = (props) => {
  const { data: regulatoryAuthorityData, loading: regulatoryAuthorityLoading, error: regulatoryAuthorityError } = useQuery(
    CaseMgmtCaseListFilterModelRegulatoryAuthorityCasePortalDocument
  );

  const { data: schoolData, loading: schoolLoading, error: schoolError } = useQuery(
    CaseMgmtCaseListFilterModelSchoolCasePortalDocument
  );

  return (
    <ComponentQueryLoader
      loading={regulatoryAuthorityLoading || schoolLoading }
      hasData={regulatoryAuthorityData?.regulatoryAuthority && schoolData?.school}
      hasDataComponent={
        <CaseListFilterModal.Component
          initialValues={props.initialValues}
          caseFilterInput={props.caseFilterInput}
          initialViewList={props.initialViewList}
          chooseViewCallback={props.chooseViewCallback}
          onCancel={props.onCancel}
          onSave={props.onSave}
          onDelete={props.onDelete}
          schoolList={schoolData?.school}
          regulatoryAuthorityList={regulatoryAuthorityData?.regulatoryAuthority}
        />
      }
      noDataComponent={undefined}
      error={regulatoryAuthorityError || schoolError}
      loadingRows={1}
    />
  );
};
