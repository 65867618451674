import React, { FC } from "react";
import { AdditionalDocumentCard } from "./additional-document-card";
import PropTypes, { InferProps } from "prop-types";
import { Col } from "antd";

const additionalDocumentCardListPropTypes = {
  onDelete: PropTypes.func,
  numberOfCardsPerRow: PropTypes.number.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      docId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      docType: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export type AdditionalDocumentCardListProps = InferProps<
  typeof additionalDocumentCardListPropTypes
>;

export const AdditionalDocumentCardList: FC<AdditionalDocumentCardListProps> = ({
  onDelete,
  numberOfCardsPerRow,
  documents,
  disabled
}) => {
  const cardList = documents.map((document) => (
    <Col
      key={document.docId}
      lg={24 / numberOfCardsPerRow}
      md={24 / (numberOfCardsPerRow + 1)}
      xs={12}
    >
      <AdditionalDocumentCard
        key={document.docId}
        displayValues={document}
        onDelete={() => onDelete && onDelete(document.docId)}
        disabled={disabled}
      />
    </Col>
  ));

  return (
    <div
      className="space-align-container"
      style={{ backgroundColor: "#f7f7f7" }}
    >
      {cardList}
    </div>
  );
};

AdditionalDocumentCard.defaultProps = {
  onDelete: () => {},
};
