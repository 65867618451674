export const Pathway1ReviewChecklistLicensureV1: Record<string, string> = {
  licensureUnrestrictedLicense: "Confirms full, unrestricted license/registration (2022) OR License/registration acceptable to ECFMG (2021 only)",
  licensureIssuedAppropriateAuthority: "Issued by appropriate authority",
  licensureApplicantLicenseOnPoint: "Applicant appears to have held license at any point on or after January 1, 2015",
  licensureDisciplinaryAction: "Document does not indicate disciplinary action",
  licensureNameValidation: "Name Ok",
  licensureCompleteLegible: "Document complete and legible",
  licensureTranslationStatus: "Translation meets ECFMG requirements",  
}

export const Pathway1ReviewChecklistVerificationV1: Record<string, string> = {
  verificationAuthorized:"Verification completed by authorized official",
  verificationPrimarySource: "Verification returned from primary source",
  verificationActiveLicense: "Confirms applicant held an active license at any point since January 1, 2015",
  verificationGoodStanding:"Confirms applicant is in good standing and/or did not have any disciplinary action"
}


export const Pathway1ReviewChecklistLicensureV2: Record<string, string> = {
  licensureUnrestrictedLicense: "Confirms full, unrestricted license/registration",
  licensureIssuedAppropriateAuthority: "Issued by appropriate authority",
  licensureApplicantLicenseOnPoint:"Applicant appears to have held license at any point on or after January 1, 2018",
  licensureDisciplinaryAction:"Document does not indicate disciplinary action",
  licensureNameValidation:"Name Ok",
  licensureCompleteLegible:"Document complete and legible",
  licensureTranslationStatus:"Translation meets ECFMG requirements",  
}

export const Pathway1ReviewChecklistVerificationV2: Record<string, string> = {
  verificationAuthorized:"Verification completed by authorized official",
  verificationPrimarySource:"Verification returned from primary source",
  verificationActiveLicense:"Confirms applicant held an active license at any point since January 1, 2018",
  verificationGoodStanding:"Confirms applicant is in good standing and/or did not have any disciplinary action"
}

export const Pathway1ReviewChecklistLicensureV3: Record<string, string> = {
  licensureUnrestrictedLicense: "Confirms full, unrestricted license/registration",
  licensureIssuedAppropriateAuthority: "Issued by appropriate authority",
  licensureApplicantLicenseOnPoint:"Applicant appears to have held license at any point on or after January 1, 2019",
  licensureDisciplinaryAction:"Document does not indicate disciplinary action",
  licensureNameValidation:"Name Ok",
  licensureCompleteLegible:"Document complete and legible",
  licensureTranslationStatus:"Translation meets ECFMG requirements",
}

export const Pathway1ReviewChecklistVerificationV3: Record<string, string> = {
  verificationAuthorized:"Verification completed by authorized official",
  verificationPrimarySource:"Verification returned from primary source",
  verificationActiveLicense:"Confirms applicant held an active license at any point since January 1, 2019",
  verificationGoodStanding:"Confirms applicant is in good standing and/or did not have any disciplinary action"
}


export const Pathway1ReviewChecklistLicensureV4: Record<string, string> = {
  licensureUnrestrictedLicense: "Confirms full, unrestricted license/registration",
  licensureIssuedAppropriateAuthority: "Issued by appropriate authority",
  licensureApplicantLicenseOnPoint:"Applicant appears to have held license at any point on or after January 1, 2019",
  licensureDisciplinaryAction:"Document does not indicate disciplinary action",
  licensureNameValidation:"Name Ok",
  licensureCompleteLegible:"Document complete and legible",
  licensureTranslationStatus:"Translation meets ECFMG requirements",
  licensureDocumentIssueDateAcceptable: "Document Issue Date Acceptable",
}

export const Pathway1ReviewChecklistVerificationV4: Record<string, string> = {
  verificationAuthorized:"Verification completed by authorized official",
  verificationPrimarySource:"Verification returned from primary source",
  verificationActiveLicense:"Confirms applicant held an active license at any point since January 1, 2019",
  verificationGoodStanding:"Confirms applicant is in good standing and/or did not have any disciplinary action"
}

export const Pathway1ReviewChecklistLicensureV5: Record<string, string> = {
  licensureUnrestrictedLicense: "Confirms full, unrestricted license/registration",
  licensureIssuedAppropriateAuthority: "Issued by appropriate authority",
  licensureApplicantLicenseOnPoint:"Applicant appears to have held license at any point on or after January 1, 2020",
  licensureDisciplinaryAction:"Document does not indicate disciplinary action",
  licensureNameValidation:"Name Ok",
  licensureCompleteLegible:"Document complete and legible",
  licensureTranslationStatus:"Translation meets ECFMG requirements",
  licensureDocumentIssueDateAcceptable: "Document issue date acceptable",
}

export const Pathway1ReviewChecklistVerificationV5: Record<string, string> = {
  verificationAuthorized:"Verification completed by authorized official",
  verificationPrimarySource:"Verification returned from primary source",
  verificationActiveLicense:"Confirms applicant held an active license at any point since January 1, 2020",
  verificationGoodStanding:"Confirms applicant is in good standing and/or did not have any disciplinary action"
}