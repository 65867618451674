export const Pathway345ReviewChecklistEligibilityV1: Record<string, string> = {
  eligibilityMedicalSchool:"Medical School matches ECFMG Record",
  eligibilityGradDateEligible:"Graduation date on or after January 1, 2018",
}

export const Pathway345ReviewChecklistAttestationV1: Record<string, string> = {
  attestationComplete:"Attestation Complete",
  attestationAuthorizedOfficial:"Attestation Completed by Authorized Official",
  attestationTitleOk:"Official Title Ok",
  attestationSignatureMatch:"Authorized Official Signature Match",
  attestationSealOk:"Seal Ok",
  attestationSignatureDate:"Signature Date OK",
  attestationSentPrimarySource:"Attestation Sent to ECFMG by Primary Source",
  attestationCompletedByMedicalSchool:"Attestation completed by medical school of record",
}

export const Pathway345ReviewChecklistEligibilityV2: Record<string, string> = {
  eligibilityMedicalSchool:"Medical School matches ECFMG Record",
  eligibilityGradDateEligible:"Graduation date on or after January 1, 2021",
}

export const Pathway345ReviewChecklistAttestationV2: Record<string, string> = {
  attestationComplete:"Attestation Complete",
  attestationAuthorizedOfficial:"Attestation Completed by Authorized Official",
  attestationTitleOk:"Official Title Ok",
  attestationSignatureMatch:"Authorized Official Signature Match",
  attestationSealOk:"Seal Ok",
  attestationSignatureDate:"Signature Date OK",
  attestationSentPrimarySource:"Attestation Sent to ECFMG by Primary Source",
  attestationCompletedByMedicalSchool:"Attestation completed by medical school of record",
}


export const Pathway345ReviewChecklistEligibilityV3: Record<string, string> = {
  eligibilityMedicalSchool:"Medical School matches ECFMG Record",
  eligibilityGradDateEligible:"Graduation date on or after January 1, 2022",
}

export const Pathway345ReviewChecklistAttestationV3: Record<string, string> = {
  attestationComplete:"Attestation Complete",
  attestationAuthorizedOfficial:"Attestation Completed by Authorized Official",
  attestationTitleOk:"Official Title Ok",
  attestationSignatureMatch:"Authorized Official Signature Match",
  attestationSealOk:"Seal Ok",
  attestationSignatureDate:"Signature Date OK",
  attestationSentPrimarySource:"Attestation Sent to ECFMG by Primary Source",
  attestationCompletedByMedicalSchool:"Attestation completed by medical school of record",
}


export const Pathway3ReviewChecklistEligibilityV4: Record<string, string> = {
  eligibilityMedicalSchool:"Medical School matches ECFMG Record",
  eligibilityGradDateEligible:"Graduation date on or after January 1, 2023",
}

export const Pathway3ReviewChecklistAttestationV4: Record<string, string> = {
  attestationComplete:"Attestation Complete",
  attestationAuthorizedOfficial:"Attestation Completed by Authorized Official",
  attestationTitleOk:"Official Title Ok",
  attestationSignatureMatch:"Authorized Official Signature Match",
  attestationSealOk:"Seal Ok",
  attestationSignatureDate:"Signature Date OK",
  attestationSentPrimarySource:"Attestation Sent to ECFMG by Primary Source",
  attestationCompletedByMedicalSchool:"Attestation completed by medical school of record",
}

