export const PathwayXReviewChecklistGeneralEligibilityV1: Record<string, string> = {
  applicantIsNotCertified: 'Applicant is not Certified',
  applicantNotValidStep2CSScore:
    'Applicant does not have Step 2 CS score that is valid for ECFMG Certification',
  applicantHasNotSubmittedAppForCert: 'Applicant has not submitted an App for Cert',
  applicantHasPurchasedToken2021Match: 'Applicant has purchased a Token for 2021 Match',
  applicantNotBarredByUSMLE:
    'Applicant is not barred by USMLE from taking an exam from August 1, 2020 to January 31, 2021',
  ecfmgRestrictionsDoNotPreventAppExamination:
    'ECFMG Restrictions do not prevent application for examination',
  step1PassedWithOneOrNoFails: 'Step 1 passed, 1 or no fails',
  step2CKPassedWithOneOrNoFails: 'Step 2 CK passed, 1 or no fails',
};

export const PathwayXReviewChecklistExceptionEligibilityV1: Record<string, string> = {
  exceptionApproved: 'Exception Approved',
};

export const PathwayXReviewChecklistPathEligibilityV1: Record<string, string> = {
  applicantEligiblePathway1: 'Applicant is eligible for Pathway 1',
  pathway1DocsVerified: 'Pathway 1 documents verified',
  applicantEligiblePathway2: 'Applicant is eligible for Pathway 2',
  pathway2DocsVerified: 'Pathway 2 documents verified',
  applicantEligiblePathway345: 'Applicant is eligible for Pathway 3/4/5',
  pathway345DocsVerified: 'Pathway 3/4/5 documents verified',
};

export const PathwayXReviewChecklistGeneralEligibilityV2: Record<string, string> = {
  applicantIsNotCertified: 'Applicant is not Certified',
  applicantNotValidStep2CSScore:
    'Applicant does not have Step 2 CS score that is valid for ECFMG Certification',
  applicantHasNotSubmittedAppForCert: 'Applicant has not submitted an App for Cert',
  applicantHasPurchasedToken2021Match: 'Applicant has purchased a Token for 2021 Match',
  applicantNotBarredByUSMLE:
    'Applicant is not barred by USMLE from taking an exam from August 1, 2020 to January 31, 2021',
  ecfmgRestrictionsDoNotPreventAppExamination:
    'ECFMG Restrictions do not prevent application for examination',
  step1PassedWithOneOrNoFails: 'Step 1 passed, 1 or no fails',
  step2CKPassedWithOneOrNoFails: 'Step 2 CK passed, 1 or no fails',
};

export const PathwayXReviewChecklistExceptionEligibilityV2: Record<string, string> = {
  exceptionApproved: 'Exception Approved',
};

export const PathwayXReviewChecklistPathEligibilityV2: Record<string, string> = {
  applicantEligiblePathway1: 'Applicant is eligible for Pathway 1',
  pathway1DocsVerified: 'Pathway 1 documents verified',
  applicantEligiblePathway2: 'Applicant is eligible for Pathway 2',
  pathway2DocsVerified: 'Pathway 2 documents verified',
  applicantEligiblePathway345: 'Applicant is eligible for Pathway 3/4/5',
  pathway345DocsVerified: 'Pathway 3/4/5 documents verified',
};
