import { Card, List, Typography } from 'antd';
import { ArchivedCasePathway1ReviewType, UiPortalConfigType } from '../../../../generated';
import { FC } from 'react';
import { CHECKLIST_EVALUATION_MAPPINGS } from '../../../constants';
import { Pathway1ArchivedCaseReviewChecklistLicensureFactory } from './pathway1-archived-case-review-checklist-versions/pathway1-archived-case-review-checklist-licensure-factory';
import { Pathway1ArchivedCaseReviewChecklistVerificationFactory } from './pathway1-archived-case-review-checklist-versions/pathway1-archived-case-review-checklist-verification-factory';

const { Title, Text } = Typography;

const STATUS_OPTIONS = [
  { key: 'license-review-in-process', value: 'License review in process' },
  {
    key: 'awaiting-applicant-resolution-license',
    value: 'Awaiting applicant resolution –License',
  },
  {
    key: 'awaiting-applicant-resolution-translation',
    value: 'Awaiting applicant resolution –Translation',
  },
  {
    key: 'exception-review-escalation-required',
    value: 'Exception Review/Escalation Required',
  },
  {
    key: 'awaiting-licensure-document-from-issuing-authority',
    value: 'Awaiting Licensure Document from issuing authority',
  },
  {
    key: 'awaiting-licensure-document-from-applicant',
    value: 'Awaiting Licensure Document from applicant',
  },
  { key: 'sent-for-verification', value: 'Sent for Verification' },
  { key: 'resent-for-verification', value: 'Resent for Verification' },
  {
    key: 'verification-received-in-review',
    value: 'Verification received; In Review',
  },
  {
    key: 'verification-rejected-and-resent',
    value: 'Verification Rejected and Resent',
  },
  {
    key: 'verification-review-complete',
    value: 'Verification review complete',
  },
];

interface Pathway1ArchivedCaseChecklistProps {
  data: ArchivedCasePathway1ReviewType;
  uiConfig: UiPortalConfigType
}



const getColor = (value: string) => {
  switch (value) {
    case 'Yes':
      return 'green';
    case 'No':
      return 'red';
    default:
      return 'black';
  }
};

export const Pathway1ArchivedCaseChecklist: FC<Pathway1ArchivedCaseChecklistProps> = (props) => {


  const pathway1LicensureMap = Pathway1ArchivedCaseReviewChecklistLicensureFactory(props.uiConfig)
  const pathway1VerificationMap = Pathway1ArchivedCaseReviewChecklistVerificationFactory(props.uiConfig)
 
  // Building Checklist Structure and Data
  let pathway1ChecklistData = [
    <Text style={{ fontWeight: 'bold' }}>License Status</Text>,
    <div>{STATUS_OPTIONS.find((status) => status.key === props.data?.checklistState)?.value}</div>,
    <Text style={{ fontWeight: 'bold' }}>Licensure Review</Text>,
  ];

  let tempChecklist = props.data?.checklist as Record<string, string>;

  for (const key in props.data?.checklist) {
    if (!pathway1LicensureMap?.[key]) {
      continue;
    }

    pathway1ChecklistData.push(
      <table width="100%">
        <tr>
          <td>{pathway1LicensureMap[key]}</td>
          <td align="right" style={{ color: getColor(tempChecklist[key]) }}>
            {CHECKLIST_EVALUATION_MAPPINGS.find((eva) => eva.key === tempChecklist[key])?.value}
          </td>
        </tr>
      </table>
    );
  }

  // New Section for Verification
  pathway1ChecklistData.push(<Text style={{ fontWeight: 'bold' }}>Verification Review</Text>);

  for (const key in props.data?.checklist) {
    if (!pathway1VerificationMap?.[key]) {
      continue;
    }

    pathway1ChecklistData.push(
      <table width="100%">
        <tr>
          <td>{pathway1VerificationMap[key]}</td>
          <td align="right" style={{ color: getColor(tempChecklist[key]) }}>
            {CHECKLIST_EVALUATION_MAPPINGS.find((eva) => eva.key === tempChecklist[key])?.value}
          </td>
        </tr>
      </table>
    );
  }

  return (
    <Card
      bodyStyle={{ paddingTop: '5px', paddingBottom: '5px' }}
      style={{
        width: '50%',
        borderRadius: '10px',
        border: '1px solid var(--Neutral-4, #F0F0F0)',
        background: 'var(--Neutral-1, #FFF)',
      }}
      title={
        <Title level={5} style={{ fontWeight: 'bold' }}>
          Pathway 1 - {props.data?.summary?.title} Checklist
        </Title>
      }
    >
      <List
        size="small"
        dataSource={pathway1ChecklistData}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </Card>
  );
};
