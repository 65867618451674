import { Card, Descriptions } from 'antd';
import Title from 'antd/lib/typography/Title';
import { FC } from 'react';
import { ArchivedCasePathway1ReviewType } from '../../../../generated';
import dayjs from 'dayjs';
import { LICENSURE_DOCUMENT_STATUS } from '../../../constants';
import { ArchivedCaseDocument } from '../../shared-components/archived-case-document';

interface Pathway1ArchivedCaseInfoCardProps {
  data: ArchivedCasePathway1ReviewType;
}
export const Pathway1ArchivedCaseInfoCard: FC<Pathway1ArchivedCaseInfoCardProps> = (props) => {

  const documentContent = props.data.data?.documents?.map((document: any) => (
    <div key={document.docId} style={{marginBottom: 10}}>
      <ArchivedCaseDocument title={document.title} docId={document.docId} />
    </div>
  ));

  return (
    <>
      <Card
        style={{
          width: '45%',
          height: '50%',
          borderRadius: '10px',
          border: '1px solid var(--Neutral-4, #F0F0F0)',
          background: 'var(--Neutral-1, #FFF)',
          display: 'block',
        }}
        title={
          <Title level={5} style={{ fontWeight: 'bold' }}>
            {props.data?.summary?.title}
          </Title>
        }
      >
        <Descriptions
          className={'ecfmg-small-descriptions'}
          layout="vertical"
          bordered={false}
          size="small"
          column={3}
          style={{ paddingBottom: '20px' }}
        >
          <Descriptions.Item label="Issue Date">
            {props?.data?.data?.licenseIssueDate ? dayjs(props?.data?.data?.licenseIssueDate).format('MM/DD/YYYY') : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Expiration Date">
            {props?.data?.data?.licenseExpiryDate ? dayjs(props?.data?.data?.licenseExpiryDate).format('MM/DD/YYYY') : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Licensure Document Status">
            {LICENSURE_DOCUMENT_STATUS.find((element) => element.key === props?.data?.data?.licensureDocumentStatus)?.value ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="Currently Licensed">
            {props?.data?.data?.isCurrentlyLicensed ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Disciplinary Action">
            {props?.data?.data?.hasDisciplinaryAction ? 'Yes' : 'No'}
          </Descriptions.Item>
        </Descriptions>
        <div>{documentContent}</div>
      </Card>
    </>
  );
};
