import React, { FC, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { GetUserGroup } from './utils/helperFunctions';
import LayoutLogin from './components/layout-login';
import LayoutCaseManagment from './components/case-management/layout-case-management';
import LayoutPhysicianManagement from './components/physician-management/layout-physician-management';
import './App.css';
import ApolloConnection from './components/core/apollo-connection';
import RequireAuth from './components/requireauth';
import { useMsal } from './components/msal-react-lite';
import { LOGIN_TYPE } from './components/constants';
import { useGA4React } from 'ga-4-react';
import LayoutArchivedCaseManagement from './components/archived-cases/layout-archived-case-list';
import LayoutMedicalSchoolOfficials from './components/medical-school-officials/layout-medical-school-officials';

const App: FC<any> = (props) => {
  const { getAuthResult2 } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  let isRendered = React.useRef(false); //Used to make Async code not get called on every render.

  const ga = useGA4React();
  const location = useLocation();
  const userGroup = GetUserGroup();

  useEffect(() => {
    if (ga) {
      ga.pageview(location.pathname);
    }
  }, [location, ga]);

  useEffect(() => {
    (async () => {
      // IIFE to make async code work in a non-async Functional Component
      if (!isRendered.current) {
        var authResult = await getAuthResult2(LOGIN_TYPE.Administration);
        console.log(`authResult:${authResult}`);
        setIsAuthenticated(authResult ? true : false);
      }
    })();

    return () => {
      isRendered.current = true;
    };
  }, [isAuthenticated]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ApolloConnection>
            <LayoutLogin isAuthenticated={isAuthenticated} />
          </ApolloConnection>
        }
      />

      <Route
        path="/case-management/*"
        element={
          <ApolloConnection AuthenticationIdentifier={LOGIN_TYPE.Administration}>
            <RequireAuth isAuthenticated={isAuthenticated}>
              <LayoutCaseManagment />
            </RequireAuth>
          </ApolloConnection>
        }
      />
      <Route
        path="/archived-cases/*"
        element={
          <ApolloConnection AuthenticationIdentifier={LOGIN_TYPE.Administration}>
            <RequireAuth isAuthenticated={isAuthenticated}>
              <LayoutArchivedCaseManagement />
            </RequireAuth>
          </ApolloConnection>
        }
      />
       <Route
        path="/medical-school-officials/*"
        element={
          <ApolloConnection AuthenticationIdentifier={LOGIN_TYPE.Administration}>
            <RequireAuth isAuthenticated={isAuthenticated}>
              <LayoutMedicalSchoolOfficials />
            </RequireAuth>
          </ApolloConnection>
        }
      />
      <Route
        path="/physician-management/*"
        element={
          userGroup?.includes('Finance') ? undefined : (
            <ApolloConnection AuthenticationIdentifier={LOGIN_TYPE.Administration}>
              <RequireAuth isAuthenticated={isAuthenticated}>
                <LayoutPhysicianManagement />
              </RequireAuth>
            </ApolloConnection>
          )
        }
      />
    </Routes>
  );
};

export default App;
