import { Button, Modal } from 'antd';
import { FC, useState } from 'react';
import ViewPDFWrapper from '../../ViewPDF-wrapper';
import { SearchOutlined } from '@ant-design/icons';

interface ArchivedCaseDocumentProps {
  title: string;
  docId: string;
}
export const ArchivedCaseDocument: FC<ArchivedCaseDocumentProps> = (props) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);

  return (
    <>
      <Button
    icon={<SearchOutlined />}
    shape="round"
    size="small"
    onClick={() => setShowPreviewPdfModal(true)}
  >
    {props.title}
  </Button>
      <Modal
        title={props.title}
        visible={showPreviewPdfModal}
        width="fit-content"
        closable
        bodyStyle={{ minWidth: '20vw', maxHeight:'80vh' }}
        okText="Continue"
        onCancel={() => setShowPreviewPdfModal(false)}
        footer={null}
      >
       {showPreviewPdfModal ? (
                <ViewPDFWrapper
                  filename={props.title}
                  docId={props.docId}
                ></ViewPDFWrapper>
              ) : (
                ''
              )}
      </Modal>
    </>
  );
};
