import { Button, message, Modal, Spin } from 'antd';
import { FC, useState } from 'react';
import SummaryPDFViewer from '../../../SummaryPDFViewer';

interface ApplicationSummaryPDFButtonProps {
  usmleId?: string;
  caseId?: string;
  getApplicationSummaryPDFMutation: any;
  responseDataFieldName: string;
  style?: any;
  buttonType?: 'primary' | 'default' | 'dashed' | 'link' | 'text' | undefined;
}
export const ApplicationSummaryPDFButton: FC<ApplicationSummaryPDFButtonProps> = (props) => {
  const [showSummaryPDf, setShowSummaryPDF] = useState<boolean>(false);
  const [summaryPDFName, setSummaryPDFName] = useState<string | undefined>(undefined);
  const [summaryPDFRetryCount, setSummaryPDFRetryCount] = useState<number>(0);
  const [pdfLoading, setPdfLoading] = useState(false);

  const applicantSummaryPDF = async () => {
    setPdfLoading(true);
    props
      .getApplicationSummaryPDFMutation({
        variables: props.usmleId
          ? {
              usmleId: props.usmleId,
            }
          : {
              caseId: props.caseId,
            },
      })
      .then((data: any) => {
        if (data?.data?.[props.responseDataFieldName]) {
          setShowSummaryPDF(true);
          console.log('CasePortal_SubmissionSummary' + data?.data?.[props.responseDataFieldName]);
          setSummaryPDFName(data?.data?.[props.responseDataFieldName]);
        } else {
          message.error('Error fetching PDF');
        }
      })
      .catch((error: any) => {
        if (error?.graphQLErrors[0]?.extensions?.code === 'RETRY' && summaryPDFRetryCount < 3) {
          setSummaryPDFRetryCount(summaryPDFRetryCount + 1);

          setTimeout(
            () => applicantSummaryPDF(),
            parseInt(process.env.REACT_APP_SUBMISSION_SUMMARY_RETRY_DELAY ?? '10')
          );
        } else {
          //only grab the first error
          const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
          message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        }
      })
      .finally(() => {
        setPdfLoading(false);
      });
  };

  const viewPdfClosed = async () => {
    setShowSummaryPDF(false);
    setSummaryPDFName(undefined);
  };
  return (
    <>
      <Button
        style={props.style}
        type={props.buttonType ?? 'default'}
        onClick={applicantSummaryPDF}
        loading={pdfLoading}
      >
        Application Summary
      </Button>

      <Modal
        title={document?.title}
        visible={showSummaryPDf}
        width="fit-content"
        closable
        bodyStyle={{ minWidth: '20vw', maxHeight: '80vh' }}
        okText="Continue"
        footer={null}
        onCancel={viewPdfClosed}
      >
        {summaryPDFName ? (
          <>
            {
              <SummaryPDFViewer
                filename={`Application Summary - ${props.usmleId}`}
                docId={summaryPDFName}
              ></SummaryPDFViewer>
            }
          </>
        ) : (
          <Spin />
        )}
      </Modal>
    </>
  );
};
