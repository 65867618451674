import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Pathway2ArchivedCaseReview } from './pathway2-archived-case-review';
import { ArchivedCaseReviewsType, ArchivedCaseType, Pathway2ArchivedCaseReviewContainerArchivedCaseDocument, UiPortalConfigType } from '../../../../generated';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';
import { useQuery } from '@apollo/client';

const Pathway2ArchivedCaseReviewContainer: FC<any> = () => {
  const { caseId } = useParams();

    const {
      loading: caseLoading,
      error: caseError,
      data: caseData,
    } = useQuery(Pathway2ArchivedCaseReviewContainerArchivedCaseDocument, {
      variables: {
        caseId: caseId ?? ''
      },
    });

  return (
    <ComponentQueryLoader
      loading={caseLoading}
      hasData={caseData?.archivedCase !== undefined}
      hasDataComponent={
        <Pathway2ArchivedCaseReview data={caseData?.archivedCase?.reviews as ArchivedCaseReviewsType} uiConfig={caseData?.archivedCase?.uiConfig as UiPortalConfigType} />
      }
      error={caseError}
    />
  );
};

export default Pathway2ArchivedCaseReviewContainer;
