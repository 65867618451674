import { FC } from 'react';
import {
  ArchivedCaseType,
  Pathway345ArchivedCaseAssessmentsEmswpContainerArchivedCaseDocument,
} from '../../../../../generated';
import { Pathway345ArchiveCaseAssessmentsEMSWP } from './pathway345-archived-case-assessments-emswp';
import { ComponentQueryLoader } from '../../../../../utils/molecules/component-query-loader';
import { useQuery } from '@apollo/client';

interface Pathway345ArchiveCaseAssessmentsEMSWPContainerProps {
  caseId: string;
}
export const Pathway345ArchiveCaseAssessmentsEMSWPContainer: FC<Pathway345ArchiveCaseAssessmentsEMSWPContainerProps> =
  (props) => {
    const {
      data: caseData,
      loading,
      error,
    } = useQuery(Pathway345ArchivedCaseAssessmentsEmswpContainerArchivedCaseDocument, {
      variables: { caseId: props.caseId ?? '' },
    });
    return (
      <ComponentQueryLoader
        loading={loading}
        hasData={caseData?.archivedCase !== undefined}
        hasDataComponent={
          <Pathway345ArchiveCaseAssessmentsEMSWP
            archivedCaseData={caseData?.archivedCase as ArchivedCaseType}
          />
        }
        error={error}
      />
    );
  };
