import React, { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Card, List } from 'antd';
import {PhysicianLicenseRecordType} from '../../../../../generated'

const LiceseCardListPropTypes = {
  onListChangedCallback: PropTypes.func,
  isEditable: PropTypes.bool,
};

export interface ComponentProps {
  licenses: PhysicianLicenseRecordType[]
}

type LicenseCardListProps = InferProps<typeof LiceseCardListPropTypes> & ComponentProps;

export const LicenseCardList: FC<LicenseCardListProps> = ({ licenses }) => {
  return (
    <>
      <List
        grid={{
          gutter: 16,
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4,
        }}
        dataSource={licenses}
        renderItem={(item) => (
          <List.Item>
            <Card style={{ border: 'solid 1px black' }}>
              <div style={{ fontSize: '0.7rem' }}>
                <em>Authority:</em>
              </div>
              <div>
                <b>{item?.orgName}</b>
              </div>
              <div style={{ fontSize: '0.7rem' }}>
                <em>License/Registration Number:</em>
              </div>
              <div>
                <b>{item?.licenseNumber}</b>
              </div>
            </Card>
          </List.Item>
        )}
      />
    </>
  );
};

LicenseCardList.defaultProps = {
  licenses: [],
  onListChangedCallback: () => {
    // default function
  },
  isEditable: true,
};
