import React, { FC, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Modal, Divider, Button } from 'antd';
import moment from 'moment';
import PropTypes, { InferProps } from 'prop-types';
import { DATE_FORMAT } from '../../../constants';
import ViewPDFWrapper from '../../../ViewPDF-wrapper';
import { useFeatureFlags } from '../../../feature-flag-react-lite';

const ReviewCSExamPropTypes = {
  pathway2: PropTypes.shape({
    userResponse: PropTypes.shape({
      hasPassedFromQualifiedSchoolInRecentPast: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.shape({
          oscExamRef: PropTypes.shape({
            _id: PropTypes.string,
            examCode: PropTypes.string,
            examShortTitle: PropTypes.string,
            examFullName: PropTypes.string,
          }),
          schoolRef: PropTypes.shape({
            _id: PropTypes.number,
            countryName: PropTypes.string,
            name: PropTypes.string,
          }),
          examDate: PropTypes.string,
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
};

export type ReviewCSExamProps = InferProps<typeof ReviewCSExamPropTypes>;

// Q-E
const ReviewCSExamInformationV4: FC<ReviewCSExamProps> = ({ pathway2 }) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const { GetFeatureFlagByName } = useFeatureFlags();

  var applicantProvidedData = undefined;
  if (pathway2?.applicantProvidedData && pathway2?.applicantProvidedData.length > 0) {
    applicantProvidedData = pathway2.applicantProvidedData[0]; //onlye one is submitted by user
  }
  const userResponse = pathway2?.userResponse;
  const hasPassedFromQualifiedSchoolInRecentPast =
    pathway2?.userResponse?.hasPassedFromQualifiedSchoolInRecentPast;
  const documents = applicantProvidedData?.data?.documents as any;
  const schoolInformation = applicantProvidedData?.data?.schoolRef;
  const previewDocument = async (document: any) => {
    setSelectedDocument(document);
    setShowPreviewPdfModal(true);
  };

  const viewPdfClosed = async () => {
    setShowPreviewPdfModal(false);
  };

  return (
    <>
      {userResponse ? (
        <div>
          <Divider plain orientation="left">
            <b>Pathway 2</b>
          </Divider>
          <div>
            <>
              <p>
                My medical school administers an OSCE specifically required by the medical
                regulatory authority (MRA) in that country and my graduation date is on or after
                January 1, 2021.
              </p>
              <p>
                Medical School: <b>{schoolInformation?.name}</b>
              </p>
            </>

            <br />
            <br />
          </div>
          <div>
            {documents?.map((document: any) => {
              if (document.docType === 'Pathway2Document') {
                return (
                  <div key={document.docId}>
                    {hasPassedFromQualifiedSchoolInRecentPast ? (
                      <div>Final Medical Diploma: </div>
                    ) : null}
                    <Button
                      icon={<SearchOutlined />}
                      shape="round"
                      size="small"
                      onClick={() => previewDocument(document)}
                    >
                      {document.title}
                    </Button>
                    <br />
                    <br />
                  </div>
                );
              } else if (document.docType === 'Pathway2TranslatedDocument') {
                return (
                  <div key={document.docId}>
                    {hasPassedFromQualifiedSchoolInRecentPast ? (
                      <div>English Translation: </div>
                    ) : null}
                    <Button
                      icon={<SearchOutlined />}
                      shape="round"
                      size="small"
                      onClick={() => previewDocument(document)}
                    >
                      {document.title}
                    </Button>
                    <br />
                    <br />
                  </div>
                );
              }
            })}

            <Modal
              title={selectedDocument?.title}
              visible={showPreviewPdfModal}
              width="fit-content"
              closable
              bodyStyle={{ minWidth: '20vw', maxHeight:'80vh' }}
              okText="Continue"
              footer={null}
              onCancel={() => viewPdfClosed()}
            >
              {showPreviewPdfModal ? (
                <ViewPDFWrapper
                  filename={selectedDocument.title}
                  docId={selectedDocument.docId}
                ></ViewPDFWrapper>
              ) : (
                ''
              )}
            </Modal>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default {
  Component: ReviewCSExamInformationV4,
};
