import { FC } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import ArchivedCaseAdditionalDocuments from './archived-case-additional-documents';
import { AdditionalDocument, ArchivedCaseAdditionalDocumentsContainerArchivedCaseDocument } from '../../../../generated';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';

const ArchivedCaseAdditionalDocumentsContainer: FC<any> = () => {
  const { caseId } = useParams();

    const {
      loading: caseLoading,
      error: caseError,
      data: caseData,
    } = useQuery(ArchivedCaseAdditionalDocumentsContainerArchivedCaseDocument, {
      variables: {
        caseId: caseId ?? ''
      },
    });

  

  return (
    <ComponentQueryLoader
      loading={caseLoading}
      hasData={caseData?.archivedCase?.additionalDocuments?.length !== undefined && caseData?.archivedCase?.additionalDocuments?.length > 0}
      hasDataComponent={
        <ArchivedCaseAdditionalDocuments
          documents={caseData?.archivedCase?.additionalDocuments as AdditionalDocument[]}
        />
      }
      error={caseError}
      noDataComponent={<div>No documents found.</div>}
    />
  );
};

export default ArchivedCaseAdditionalDocumentsContainer;
