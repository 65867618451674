import { FeatureFlagConfig } from "../components/feature-flag-react-lite";
import defaultValues from "./feature-flag-default-values.json";
import { AxiosRequestConfig } from "axios";

var axiosHeaders = async (config: AxiosRequestConfig) => {
  config.headers["Cache-Control"] = `no-cache`;
  config.headers["Pragma"] = `no-cache`;
  config.headers["Expires"] = `0`;

  return config;
};

var featureFlagConfig: FeatureFlagConfig = {
  cache: 30 * 1000,
  url: process.env.REACT_APP_FEATURE_FLAG_URL ?? "",
  fallbackFlagValues: defaultValues,
  axiosRequestConfig: axiosHeaders,
};
export default featureFlagConfig;
