import React, { FC, useState } from 'react';
import { Button, Table, Modal, message, Row, Col } from 'antd';
import { CloseCircleOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { DocumentNode, useMutation } from '@apollo/client';
import PropTypes, { InferProps } from 'prop-types';
import moment from 'moment';
import Pathway6RejectMiniCEXRequest from '../../case-management/pathways/pathway6/pathway6-document-review/modals/pathway6-reject-miniCEX-request';
import { CEX_STATUS, ADMIN_ALLOWED_ACTIONS, DATE_FORMAT } from '../../constants';
import {
  CaseMgmtPhysicianActiveTableCasePortal_MiniCex_RejectDocument,
  MiniCexResponseType,
} from '../../../generated';

const PhysicianEncounterActiveTablePropTypes = {
  refetchQuery: PropTypes.object,
};

export interface ComponentProps {
  minicexList: MiniCexResponseType[];
}

export type PhysicianEncounterActiveTableProps = InferProps<
  typeof PhysicianEncounterActiveTablePropTypes
> &
  ComponentProps;

interface RejectMiniCEX {
  encounterId: string;
  minicexDataVersion: number;
}

const PhysicianActiveTable: FC<PhysicianEncounterActiveTableProps> = ({
  minicexList,
  refetchQuery,
}) => {
  const [showEncounterHistoryModal, setShowEncounterHistoryModal] = useState<boolean>(false);
  const [currentRecord, setCurrentRecord] = useState<any>();
  const [isRejectMiniCEXVisible, setIsRejectMiniCEXVisible] = useState(false);
  let { physicianEmail } = useParams<any>();

  const [rejectMiniCEX, { loading: rejectMiniCEXLoading }] = useMutation(
    CaseMgmtPhysicianActiveTableCasePortal_MiniCex_RejectDocument,
    {
      refetchQueries: [
        {
          query: refetchQuery as DocumentNode,
          variables: { email: physicianEmail, responseType: 'PENDING' },
        },
      ],
    }
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'encounterId',
      key: 'encounterId',
      render: (text: any, record: any) => {
        return <>{record.summary.encounterId}</>;
      },
    },
    {
      title: 'USMLE ID',
      dataIndex: 'usmleid',
      key: 'usmleid',
      render: (text: any, record: any) => {
        return <>{record.summary.usmleId}</>;
      },
    },
    {
      title: 'Applicant Last Name',
      dataIndex: 'applicantLastName',
      key: 'applicantLastName',
      render: (text: any, record: any) => {
        return <>{record.summary.applicantLastName}</>;
      },
    },
    {
      title: 'Applicant Rest of Name',
      dataIndex: 'applicantRestOfName',
      key: 'applicantRestOfName',
      render: (text: any, record: any) => {
        return <>{record.summary.applicantRestOfName}</>;
      },
    },

    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      render: (state: any, record: any) => (
        <>
          {record.summary.state !== undefined ? (
            <Row wrap={false} justify="end">
              <Col
                flex="20px"
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
              >
                <InfoCircleOutlined
                  style={{ fontSize: '18px' }}
                  onClick={() => {
                    setCurrentRecord(record);
                    setShowEncounterHistoryModal(true);
                  }}
                />
              </Col>
              <Col flex="auto" offset={1}>
                {getStatus(CEX_STATUS, record.summary.state)}
              </Col>
            </Row>
          ) : null}
        </>
      ),
    },
    {
      title: 'Date Requested',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any, record: any) => (
        <div>
          {record.summary.createdAt !== undefined ? (
            <div>{moment(record.summary.createdAt).utc().format(DATE_FORMAT)}</div>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    {
      title: 'Date Due',
      dataIndex: 'expiresAt',
      key: 'expiresAt',
      render: (expiresAt: any, record: any) => (
        <div>
          {record.summary.expiresAt !== undefined ? (
            <div>{moment(record.summary.expiresAt).utc().format(DATE_FORMAT)}</div>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'allowedActions',
      key: 'allowedActions',
      render: (allowedActions: any, record: any, index: any) => renderButtons(record, index),
    },
  ];

  const historyColumns = [
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      render: (state: any, record: any) => (
        <div>
          {record.state !== undefined ? (
            <div>{getStatus(CEX_STATUS, record?.state) + ' '}</div>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: any, record: any) => (
        <div>
          {record.timestamp !== undefined ? (
            <div>{moment(record.timestamp).utc().format(`${DATE_FORMAT}, h:mm:ss a`)}</div>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
  ];

  const onRejectMiniCEX = async () => {
    await rejectMiniCEX({
      variables: {
        encounterId: currentRecord.encounterId,
        minicexDataVersion: currentRecord.dataVersion,
      },
    })
      .then((result) => {
        message.success('Successfully rejected minicex');
      })
      .catch((error) => {
        console.log('ERROR REJECTING', error);
      });

    setIsRejectMiniCEXVisible(false);
  };

  const renderButtons = (record: any, index: any) => {
    if (record?.summary?.allowedActions.includes(ADMIN_ALLOWED_ACTIONS.VIEW)) {
      return (
        <Button
          onClick={() => {
            setCurrentRecord(record);
          }}
          type="primary"
          style={{ width: '100px', fontSize: '14px' }}
          icon={<SearchOutlined />}
        >
          View
        </Button>
      );
    } else if (record?.summary?.allowedActions.includes(ADMIN_ALLOWED_ACTIONS.REJECT)) {
      return (
        <Button
          style={{ width: '100px', fontSize: '14px' }}
          type="primary"
          danger
          icon={<CloseCircleOutlined />}
          onClick={() => {
            setCurrentRecord(record);
            setIsRejectMiniCEXVisible(true);
          }}
        >
          Reject
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const getStatus = (obj: any, key: any) => {
    return obj[key];
  };

  return (
    <>
      <Table columns={columns} dataSource={minicexList as any} size="middle"></Table>
      <p style={{ fontSize: '11px', marginTop: '10px' }}>
        All times are calculated using Coordinated Universal Time (UTC)
      </p>
      <Modal
        title="Status History"
        visible={showEncounterHistoryModal}
        closable={false}
        footer={[
          <Button key="close" type="primary" onClick={() => setShowEncounterHistoryModal(false)}>
            Close
          </Button>,
        ]}
      >
        Encounter ID: {currentRecord?.encounterId}
        <br />
        <br />
        <Table columns={historyColumns} dataSource={currentRecord?.summary?.stateModel}></Table>
        <p style={{ fontSize: '11px', marginTop: '10px' }}>
          All times are calculated using Coordinated Universal Time (UTC)
        </p>
      </Modal>

      <Modal
        title="Reject Mini-CEX Request?"
        visible={isRejectMiniCEXVisible}
        closable={false}
        width={'450px'}
        footer={[
          <Button key="cancel" onClick={() => setIsRejectMiniCEXVisible(false)}>
            Cancel
          </Button>,
          <Button key="rejectMiniCEX" onClick={() => onRejectMiniCEX()} danger type="primary">
            Reject Mini-CEX Request
          </Button>,
        ]}
      >
        <Pathway6RejectMiniCEXRequest currentRecord={currentRecord?.summary} />
      </Modal>
    </>
  );
};
export default PhysicianActiveTable;
