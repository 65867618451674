import { FC } from 'react';
import { useParams } from 'react-router-dom';
import ArchivedCaseFinance from './archived-case-finance';
import { ArchivedCaseFinanceContainerArchivedCaseDocument, ArchivedCaseFinanceType } from '../../../../generated';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';
import { useQuery } from '@apollo/client';

const ArchivedCaseFinanceContainer: FC<any> = () => {
  const { caseId } = useParams();

    const {
      loading: caseLoading,
      error: caseError,
      data: caseData,
    } = useQuery(ArchivedCaseFinanceContainerArchivedCaseDocument, {
      variables: {
        caseId: caseId ?? ''
      },
    });
    
  return (
    <ComponentQueryLoader
      loading={caseLoading}
      hasData={caseData !== undefined}
      hasDataComponent={<ArchivedCaseFinance data={caseData?.archivedCase?.finance as ArchivedCaseFinanceType} />}
      error={caseError}
      noDataComponent={<div>No finance data found.</div>}
    />
  );
};

export default ArchivedCaseFinanceContainer;
