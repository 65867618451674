import React, { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import logo from './logo.svg';
import { Input, Button, Typography } from 'antd';
import './../App.css';
import { unmountComponentAtNode } from 'react-dom';
import Maintenance from './maintenance/maintenance';
import ImpendingMaintenance from './maintenance/impendingMaintenance';
import { useMsal } from './msal-react-lite';
import { LOGIN_TYPE } from './constants';
import { Helmet } from 'react-helmet';
const { Title } = Typography;
const loginType = LOGIN_TYPE.Administration;

const Login: FC<any> = (props) => {
  const { login, logout, getAuthToken, getAuthResult } = useMsal();
  const [authenticated, setAuthenticated] = useState('not authenticated');
  const location = useLocation();
  const navigate = useNavigate();
  //const Auth = new auth();

  var loginAction = async () => {
    await login(loginType);
    var idToken = await getAuthResult(loginType);
    var authToken = await getAuthToken(loginType);

    if (idToken) {
      sessionStorage.setItem('userIdToken', idToken.uniqueId);
      sessionStorage.setItem('msal.idtoken', JSON.stringify(idToken.idTokenClaims));
    }
    if (authToken !== null || authToken !== undefined) {
      console.log('user is authenticated');
      console.log(authToken);

      setAuthenticated('authenticated');
      navigate(0); //Reloads page so that MSAL knows you're logged in. (ugly - should remove if we have time to test and figure out)
    }
  };

  return (
    <>
      <Helmet>
        <title>Pathways Administration</title>
      </Helmet>
      <div style={{ width: '100%' }}>
        <ImpendingMaintenance ShowImpendingMessageImmediately={true}>
          <Maintenance>
            <div style={{ width: '500px', margin: 'auto', padding: '24px', textAlign: 'center' }}>
              {/* <hr style={{ backgroundColor: '#3f4373' }} /> */}
              <div>
                <Title level={2}>CASE MANAGEMENT PORTAL</Title>
                <br />
                <div style={{ textAlign: 'center' }}>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => loginAction()}
                    style={{ minWidth: '150px' }}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </Maintenance>
        </ImpendingMaintenance>
      </div>
    </>
  );
};

export default Login;
