import { FC } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import ArchivedCaseExport from './archived-case-export';
import { ArchivedCaseExportContainerArchivedCaseDocument, ArchivedCaseType } from '../../../../generated';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';

const ArchivedCaseExportContainer: FC<any> = () => {
  const { caseId } = useParams();

    const {
      loading: caseLoading,
      error: caseError,
      data: caseData,
    } = useQuery(ArchivedCaseExportContainerArchivedCaseDocument, {
      variables: {
        caseId: caseId ?? ''
      },
    });

  return (
    <ComponentQueryLoader
      loading={caseLoading}
      hasData={caseData?.archivedCase !== undefined}
      hasDataComponent={<ArchivedCaseExport data={caseData as ArchivedCaseType} />}
      error={caseError}
    />
  );
};

export default ArchivedCaseExportContainer;
