import { FC } from 'react';
import {
  C_ApplicantType,
  CaseSummaryType,
  MiniCexChecklist,
  UiPortalConfigType,
} from '../../../../../../generated';

import { COMPONENT_NAME, PAGE_NAME } from '../../../../../utils/version-mappings';
import Pathway6DocumentReviewChecklist from './pathway6-document-review-checklist';
import Pathway6DocumentReviewChecklistV2 from './pathway6-document-review-checklist-v2';

export interface Pathway6DocumentReviewChecklistFactoryProps {
  checklist: MiniCexChecklist;
  versionConfig: UiPortalConfigType;
  caseSummary: CaseSummaryType;
  checklistState: string;
  recordId: string;
  parentDataVersion: number;
  applicant: C_ApplicantType;
  onUnlockChecklist: () => void;
  onUpdateDataVersion: (dataVersion: number) => void;
  checklistStatus: string;
  isLocked: boolean;
  redirect: (url: string) => void;
}

const Pathway6DocumentReviewChecklistFactory: FC<Pathway6DocumentReviewChecklistFactoryProps> = ({
  versionConfig,
  checklist,
  caseSummary,
  checklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  checklistStatus,
  applicant,
  redirect,
  isLocked,
  onUnlockChecklist,
}) => {
  const getPathway6Checklist = () => {
    return versionConfig?.config?.pages?.map((page) => {
      if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
        return page?.components?.map((component) => {
          if (component?.componentName === COMPONENT_NAME.PATHWAY6_CHECKLIST) {
            switch (component.version) {
              case 1:
                return (
                  <Pathway6DocumentReviewChecklist
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    checklistStatus={checklistStatus}
                    isLocked={isLocked}
                    onUnlockChecklist={onUnlockChecklist}
                  />
                );
              case 2:
              default:
                return (
                  <Pathway6DocumentReviewChecklistV2
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    checklistStatus={checklistStatus}
                    isLocked={isLocked}
                    onUnlockChecklist={onUnlockChecklist}
                  />
                );
            }
          }
        });
      }
    });
  };

  return <div>{getPathway6Checklist()}</div>;
};

export default Pathway6DocumentReviewChecklistFactory;
