import { FC, useState, useEffect } from 'react';
import { Button, Table, Modal, Col, Row, Tabs } from 'antd';
import { InfoCircleOutlined, SearchOutlined, SelectOutlined } from '@ant-design/icons';
import { InferProps } from 'prop-types';
import {
  PATHWAY6_DATE_FORMAT,
  CEX_STATUS,
  ADMIN_ALLOWED_ACTIONS,
  getPhysicianName,
  WITHDRAW_STATUSES,
  CEX_STATUS_KEYS,
} from '../../../../constants';
import Pathway6ReviewPhysicianDetails from './modals/pathway6-review-physician-details';
import Pathway6MiniCEXStatusHistory from './modals/pathway6-miniCEX-status-history';
import Pathway6ReviewRejectedMiniCEX from './pathway6-review-rejected-miniCEX';
import moment from 'moment';
import _ from 'lodash';
import { MiniCexResponseType, PhysicianResponseType } from '../../../../../generated';
import { getMiniCEXAttestationPDF, getMiniCEXPDF } from './pathway6-pdf-helpers';
import Pathway6ReviewRejectedMiniCEXFactory from './pathway6-review-rejected-miniCEX-factory';
const { TabPane } = Tabs;

const Pathway6MiniCEXInactiveTablePropTypes = {};

export interface ComponentProps {
  minicexList: MiniCexResponseType[];
  physicianByMiniCEX: PhysicianResponseType[];
}

export type Pathway6MiniCEXInactiveTableProps = InferProps<
  typeof Pathway6MiniCEXInactiveTablePropTypes
> &
  ComponentProps;

const Pathway6MiniCEXInactiveTable: FC<Pathway6MiniCEXInactiveTableProps> = ({
  minicexList,
  physicianByMiniCEX,
}) => {
  const [isReviewRejectedMiniCEXVisible, setIsReviewRejectedMiniCEXVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<any>();
  const [isReviewPhysicianDetailsModalVisible, setIsReviewPhysicianDetailsModalVisible] =
    useState(false);
  const [isStatusHistoryVisible, setIsStatusHistoryVisible] = useState(false);
  const [currentPhysicianData, setCurrentPhysicianData] = useState<any>();
  const [summaryList, setSummaryList] = useState<any>();
  const [readOnlyMiniCEXData, setReadOnlyMiniCEXData] = useState<MiniCexResponseType>();
  const [isReadOnlyMiniCEXVisible, setIsReadOnlyMiniCEXVisible] = useState(false);
  useEffect(() => {
    setCurrentPhysicianData(
      physicianByMiniCEX?.find((item) => item?.email === currentRecord?.physicianEmail)
    );
  }, [currentRecord]);

  useEffect(() => {
    //Get summary from minicexList
    setSummaryList(
      minicexList?.map((item: any) => ({
        ...item.summary,
      }))
    );
  }, [minicexList]);

  const onPhysicianNameClicked = () => {
    setIsReviewPhysicianDetailsModalVisible(true);
  };

  const getPhysicianNameByEmail = (email: any) => {
    var currentPhysicianRecord: any = physicianByMiniCEX?.find((item) => item?.email === email);

    return getPhysicianName(
      currentPhysicianRecord?.profile?.lastName,
      currentPhysicianRecord?.profile?.restOfName
    );
  };

  const renderButtons = (record: any, index: any) => {
    if (
      record.allowedActions.includes(ADMIN_ALLOWED_ACTIONS.VIEW) &&
      _.indexOf(WITHDRAW_STATUSES, record.state) === -1
    ) {
      return (
        <Button
          key={record?.encounterId!}
          onClick={() => {
            setCurrentRecord(record);
            setReadOnlyMiniCEXData(minicexList[index]);
            if (record.rejectionReason) {
              setIsReviewRejectedMiniCEXVisible(true);
            } else if (record.state === CEX_STATUS_KEYS.MINICEX_CANCELED_BY_STAFF) {
              setIsReadOnlyMiniCEXVisible(true);
            }
          }}
          type="primary"
          style={{ fontSize: '14px', width: '100px', marginLeft: '5px' }}
          icon={<SearchOutlined />}
        >
          View
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const getStatus = (obj: any, key: any) => {
    return obj[key];
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'encounterId',
      key: 'encounterId',
      render: (text: any, record: any) => (
        <div style={{ marginLeft: '5px' }}>{record.encounterId}</div>
      ),
    },
    {
      title: 'Name from Physician',
      key: 'physicianNameProvidedByPhysician',
      render: (physicianNameProvidedByPhysician: any, record: any) => (
        <div style={{ marginLeft: '5px' }}>
          {
            <a
              onClick={() => {
                setCurrentRecord(record);
                onPhysicianNameClicked();
              }}
            >
              {' '}
              {getPhysicianNameByEmail(record.physicianEmail)}{' '}
            </a>
          }
        </div>
      ),
    },
    {
      title: 'Date Requested',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any, record: any) => (
        <div style={{ marginLeft: '5px' }}>
          {record.createdAt !== undefined
            ? moment(record.createdAt).utc().format(PATHWAY6_DATE_FORMAT)
            : ''}
        </div>
      ),
    },
    {
      title: 'Last Action',
      dataIndex: 'completedAt',
      key: 'completedAt',
      render: (completedAt: any, record: any) => (
        <div style={{ marginLeft: '5px' }}>
          {moment(record.completedAt).utc().format(PATHWAY6_DATE_FORMAT)}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      render: (state: any, record: any) => (
        <div style={{ marginLeft: '5px' }}>
          {record.state !== undefined ? (
            <Row wrap={false}>
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <InfoCircleOutlined
                  style={{ fontSize: '15px' }}
                  onClick={() => {
                    setCurrentRecord(record);
                    setIsStatusHistoryVisible(true);
                  }}
                />
              </Col>
              <Col offset={1}>{getStatus(CEX_STATUS, record.state)}</Col>
            </Row>
          ) : null}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'allowedActions',
      key: 'allowedActions',
      render: (allowedActions: any, record: any, index: any) => renderButtons(record, index),
    },
  ];

  return (
    <>
      <Table
        pagination={false}
        columns={columns}
        dataSource={summaryList as any}
        size="small"
        bordered
        tableLayout="auto"
        className="ecfmg-small-table-pathway6"
      ></Table>
      <p style={{ fontSize: '11px', marginTop: '10px' }}>
        All times are calculated using Coordinated Universal Time (UTC)
      </p>
      <Modal
        title="Review Physician Details"
        visible={isReviewPhysicianDetailsModalVisible}
        onCancel={() => setIsReviewPhysicianDetailsModalVisible(false)}
        width={'80vw'}
        footer={[
          <Button
            key="viewEvaluatorRequests"
            onClick={() => {
              window.open(
                `${window.location.protocol}//${window.location.host}/physician-management/physician-detail/${currentRecord?.physicianEmail}`,
                '_blank'
              );
            }}
          >
            View Evaluator Requests <SelectOutlined />
          </Button>,
          <Button key="close" onClick={() => setIsReviewPhysicianDetailsModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <Pathway6ReviewPhysicianDetails
          currentRecord={currentRecord}
          currentPhysicianData={currentPhysicianData}
        />
      </Modal>
      <Modal
        title="Status History"
        visible={isStatusHistoryVisible}
        closable={false}
        width={'450px'}
        footer={[
          <Button key="close" type="primary" onClick={() => setIsStatusHistoryVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <Pathway6MiniCEXStatusHistory
          stateModel={currentRecord?.stateModel}
          encounterId={currentRecord?.encounterId}
        />
      </Modal>
      <Modal
        title="Review Rejected Mini-CEX"
        visible={isReviewRejectedMiniCEXVisible}
        width={'70%'}
        onCancel={() => {
          setIsReviewRejectedMiniCEXVisible(false);
        }}
        footer={[
          <Button type="primary" onClick={() => setIsReviewRejectedMiniCEXVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <Pathway6ReviewRejectedMiniCEXFactory
          miniCEX={readOnlyMiniCEXData}
          physician={currentPhysicianData}
        />
      </Modal>

      <Modal
        title="Review Completed Mini-CEX"
        visible={isReadOnlyMiniCEXVisible}
        width="fit-content"
        bodyStyle={{ minWidth: '30vw' }}
        onCancel={() => {
          setIsReadOnlyMiniCEXVisible(false);
        }}
        destroyOnClose
        footer={[]}
      >
        <Tabs type="card">
          <TabPane tab="Mini-CEX Summary" key="1">
            {getMiniCEXPDF(readOnlyMiniCEXData, currentPhysicianData)}
          </TabPane>

          <TabPane tab="Mini-CEX Evaluation Attestation" key="2">
            {getMiniCEXAttestationPDF(readOnlyMiniCEXData, currentPhysicianData)}
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};
export default Pathway6MiniCEXInactiveTable;
