import { Card, List, Typography } from 'antd';
import { ArchivedCaseOetReviewType } from '../../../../generated';
import { FC } from 'react';
import { CHECKLIST_EVALUATION_MAPPINGS } from '../../../constants';

const { Title, Text } = Typography;

const OET_STATUS = [
  { key: 'score-not-yet-received', value: 'Score not yet received' },
  { key: 'score-received', value: 'Score Received' },
  { key: 'awaiting-applicant-feedback', value: 'Awaiting applicant feedback' },
  { key: 'score-review-in-process', value: 'Score Review in process' },
  {
    key: 'exception-review-escalation-reqired',
    value: 'Exception Review/Escalation Required',
  },
  { key: 'score-review-complete', value: 'Score Review complete' },
];

interface ArchivedCaseOetCheclistProps {
  data: ArchivedCaseOetReviewType;
}

const oetChecklistMap: Record<string, string> = {
  documentCompleteLegible: 'Document complete and legible',
  examDateValidation: 'Date of examination on or after January 1, 2023',
  nameValid: 'Name OK',
  scoreAcceptable: 'Score acceptable to ECFMG',
  scoreConfirmed: 'Score Confirmed with Primary Source',
};

const getColor = (value: string) => {
  switch (value) {
    case 'Yes':
      return 'green';
    case 'No':
      return 'red';
    default:
      return 'black';
  }
};

export const ArchivedCaseOetCheclist: FC<ArchivedCaseOetCheclistProps> = (props) => {
  // Building Checklist Structure and Data
  let oetChecklistData = [
    <Text style={{ fontWeight: 'bold', fontSize: '' }}>OET Status</Text>,
    <div>
      {OET_STATUS.find((oetStatus) => oetStatus.key === props.data?.checklistState)?.value}
    </div>,
    <Text style={{ fontWeight: 'bold' }}>Evaluation</Text>,
  ];

  let tempChecklist = props.data?.checklist as Record<string, string>;

  for (const key in props.data?.checklist) {
    if (!oetChecklistMap[key]) {
      continue;
    }
    oetChecklistData.push(
      <table width="100%">
        <tr>
          <td>{oetChecklistMap[key]}</td>
          <td align="right" style={{ color: getColor(tempChecklist[key]) }}>
            {CHECKLIST_EVALUATION_MAPPINGS.find((eva) => eva.key === tempChecklist[key])?.value}
          </td>
        </tr>
      </table>
    );
  }

  return (
    <Card
      bodyStyle={{ paddingTop: '5px', paddingBottom: '5px' }}
      style={{
        width: '50%',
        borderRadius: '10px',
        border: '1px solid var(--Neutral-4, #F0F0F0)',
        background: 'var(--Neutral-1, #FFF)',
      }}
      title={
        <Title level={5} style={{ fontWeight: 'bold' }}>
          OET Review Checklist
        </Title>
      }
    >
      <List
        size="small"
        dataSource={oetChecklistData}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </Card>
  );
};

export default ArchivedCaseOetCheclist;
