import React, { FC, useEffect } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
  from,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { useMsal } from '../msal-react-lite';
import { LOGIN_TYPE } from '../constants';

const apolloUrl =
  process.env.REACT_APP_GRAPH_SERVER !== null &&
  typeof process.env.REACT_APP_GRAPH_SERVER !== 'undefined'
    ? `${process.env.REACT_APP_GRAPH_SERVER}`
    : 'http://localhost:7071/api/graphql';

const ApolloConnection: FC<any> = (props) => {
  const { getAuthToken, getIsLoggedIn } = useMsal();

  const hasAuth =
    props.AuthenticationIdentifier !== null &&
    typeof props.AuthenticationIdentifier !== 'undefined';

  const withToken = setContext(async (_, { headers }) => {
    
    if (hasAuth) {
      const token = await getAuthToken(LOGIN_TYPE.Administration);
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : null,
          portal: 'CaseManagement',
        },
      };
    } else {
      return {
        headers: {
          ...headers,
        },
      };
    }
  });

  const httpLink = createHttpLink({
    uri: apolloUrl,
  });

  // const httpLink = new BatchHttpLink({
  //   uri: apolloUrl,
  //   batchMax: 20, // No more than 5 operations per batch
  //   batchInterval: 50 // Wait no more than 20ms after first batched operation
  // });

  const cache = new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'CaseResponseType':
          return `CaseResponseType:${(responseObject as any).caseSummary._id}`;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  });

  const client = new ApolloClient({
    link: from([withToken, httpLink]),
    cache: cache,
  });

  useEffect(() => {
    if (!getIsLoggedIn(LOGIN_TYPE.Administration)) {
      (async () => {
        await client.resetStore(); //clear Apollo cache when user loggs off
      })();
    }
  }, [getIsLoggedIn, props.AuthenticationIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
/*<ReactIdleTimer>
        <App />
      </ReactIdleTimer> */
export default ApolloConnection;
