import { pdf } from '@react-pdf/renderer';
import {
  MiniCexResponseType,
  PhysicianResponseType
} from '../../../../../generated';
import SummaryPDFViewer from '../../../../SummaryPDFViewer';
import AttestationPDFFactory from './pathway6-attestation-pdf-factory';
import PhysicianMiniCEXPDFFactory from './physician-minicex-pdf-factory';

export const getMiniCEXAttestationPDF = (
  readOnlyMiniCEXData?: MiniCexResponseType,
  currentPhysicianData?: PhysicianResponseType
) => {
  var blobData = pdf(
    <AttestationPDFFactory
      readOnlyMiniCEXData={readOnlyMiniCEXData}
      currentPhysicianData={currentPhysicianData}
    />
  ).toBlob();

  return (
    <SummaryPDFViewer
      filename={`Mini-CEX Evaluation Attestation - ${readOnlyMiniCEXData?.encounterId}`}
      docId=""
      blobPromise={blobData}
    ></SummaryPDFViewer>
  );
};

export const getMiniCEXPDF = (readOnlyMiniCEXData?: MiniCexResponseType, currentPhysicianData?: PhysicianResponseType) => {
  var blobData = pdf(
    <PhysicianMiniCEXPDFFactory
      readOnlyMiniCEXData={readOnlyMiniCEXData}
      currentPhysicianData={currentPhysicianData}
    />
  ).toBlob();

  return (
    <SummaryPDFViewer
      filename={`Mini-CEX Summary - ${readOnlyMiniCEXData?.encounterId}`}
      docId=""
      blobPromise={blobData}
    ></SummaryPDFViewer>
  );
};
