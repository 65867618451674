import { FC, useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import { ActivityLogType, ActivityLogUserType } from '../../../../generated';
import {
  compareDate,
  compareString,
  getDateFromTimestamp,
  getSortedActivityLog,
} from '../../../../utils/helperFunctions';

const { Text } = Typography;

interface ArchivedCaseNotesProps {
  activityLog: ActivityLogType[];
}

const ArchivedActivityFeed: FC<ArchivedCaseNotesProps> = ({ activityLog }) => {
  const [sortedActivityLog, setSortedActivityLog] =
    useState<ActivityLogType[] | undefined>(undefined);
  useEffect(() => {
    if (activityLog) {
      setSortedActivityLog(getSortedActivityLog(activityLog));
    }
  }, [activityLog]);

  const columns = [
    {
      title: 'Date Created',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: string) => <Text>{getDateFromTimestamp(timestamp)}</Text>,
      sorter: (rec1: any, rec2: any) => compareDate(rec1.timestamp, rec2.timestamp, true),
    },
    {
      title: 'Source',
      dataIndex: 'user',
      key: 'user',
      render: (user: ActivityLogUserType) => <Text>{user?.name}</Text>,
      sorter: (rec1: any, rec2: any) => compareString(rec1.user?.name, rec2.user?.name),
    },
    {
      title: 'Details',
      dataIndex: 'message',
      key: 'message',
      render: (message: string) => <Text>{message}</Text>,
      sorter: (rec1: any, rec2: any) => compareString(rec1.message, rec2.message),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={sortedActivityLog}
      bordered
      pagination={false}
      size="small"
    />
  );
};

export default ArchivedActivityFeed;
