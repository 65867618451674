import React, { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Divider } from 'antd';
import { CaseSummaryType, Pathway345 } from '../../../../generated';
import moment from 'moment';

const ReviewEducationPropTypes = {};

export interface ComponentProps {
  pathway345: Pathway345;
}

export type ReviewEducationProps = InferProps<typeof ReviewEducationPropTypes> & ComponentProps;

//Q-F
const ReviewEducationInformationV2: FC<ReviewEducationProps> = ({ pathway345 }) => {
  var userResponse = pathway345?.userResponse;
  var applicantProvidedData = pathway345?.applicantProvidedData as any;

  const convertPathwayNumber = (pathway: any) => {
    switch (pathway) {
      case 'PATHWAY3':
        return '3';
      case 'PATHWAY4':
        return '4';
      case 'PATHWAY5':
        return '5';
      default:
        return '';
    }
  };

  return (
    <>
      {userResponse ? (
        <div>
          <Divider plain orientation="left">
            <b>Pathway 3, 4, or 5</b>
          </Divider>

          {userResponse.isGradSchoolEligible &&
          applicantProvidedData &&
          applicantProvidedData.length > 0 ? (
            <div>
              <p>
                Graduation date or expected date:{' '}
                <b>
                  {moment(applicantProvidedData[0].data.gradDate).format('MM/DD/YYYY').toString()}
                </b>
              </p>
              <p>
                Country in which your medical school is located:{' '}
                <b>{applicantProvidedData[0].data.schoolRef?.countryName}</b>
              </p>
              <p>
                Your medical School: <b>{applicantProvidedData[0].data.schoolRef?.name}</b>
              </p>
              <br />
              <br />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default {
  Component: ReviewEducationInformationV2,
};
