import moment from 'moment';
import { toInteger } from 'lodash';

//convert dd/mm/yyyy to mm/dd/yyyy
// const convertDate = (date: any) => {
//   if (date) {
//     var dateElements = date?.split("/");
//     return `${dateElements[1].padStart(2, "0")}/${dateElements[0].padStart(
//       2,
//       "0"
//     )}/${dateElements[2]}`;
//   } else {
//     return "";
//   }
// };

//Label for the buttons creating views on case list and oet list
export const CUSTOMIZE_VIEWS_LABEL = 'Customize Views';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_WITH_TIME = 'MM/DD/YYYY HH:mm:ss';
export const PATHWAY6_DATE_FORMAT = 'DD-MMM-YYYY';

//default view id used in case list and oet list
export const DEFAULT_VIEW_ID = 'all-cases';

//default view name used in case list and oet list
export const DEFAULT_VIEW_TEXT = 'All Cases';

export const DASHBOARD_TYPE = {
  Global: 'global',
  User: 'user',
};

//used in custom view modal
export const VIEW_DETAIL_DISPLAY_MODE = {
  AddNew: 'Add New View',
  Edit: 'View Details',
};

export const LICENSURE_DOCUMENT_STATUS = [
  { key: 'requestedSentToECFMG', value: 'Requested to be sent to ECFMG' },
  { key: 'requestedWillSend', value: 'Requested and will send when received' },
  { key: 'issuedInLast90Days', value: 'Issued in last 90 days' },
  { key: 'doNotHaveCannotObtain', value: 'Do not have, cannot obtain' },
];

//used in views for oet
export const MATCH_STATUS = {
  MATCHED: 'Matched',
  UNMATCHED: 'Unmatched',
};

export const CASE_LIST_COLUMN_KEYS = {
  column_usmle_id: 'column-usmle-id',
  column_days_since_last_update: 'column-days-since-last-update',
  submittedAt: 'submittedAt',
  column_pathway: 'column-pathway',
  column_case_status: 'column-case-status',
  column_general_eligibility: 'column-general-eligibility',
  column_oet_case_status: 'column-oet-case-status',
  column_pathway_case_status: 'column-pathway-case-status',
  column_qa_rework: 'column-qa-rework',
  column_assigned_to: 'column-assigned-to',
  column_last_doc_upload_timestamp: 'column-last-doc-upload-timestamp',
};

export const OET_LIST_COLUMN_KEYS = {
  candidateId: 'candidateId',
  usmleId: 'usmleId',
  matched: 'matched',
  testingCenter: 'testingCenter',
  examDate: 'examDate',
  examineeFirstName: 'examineeFirstName',
  examineeLastName: 'examineeLastName',
  dateOfBirth: 'dateOfBirth',
  gender: 'gender',
  listeningScore: 'listeningScore',
  readingScore: 'readingScore',
  writingScore: 'writingScore',
  speakingScore: 'speakingScore',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  createdUser: 'createdUser',
  updatedUser: 'updatedUser',
  result: 'result',
};

export const OET_TABLE_COLUMNS = [
  {
    title: 'OET Applicant ID',
    dataIndex: 'candidateId',
    key: OET_LIST_COLUMN_KEYS.candidateId,
  },
  {
    title: 'USMLE ID',
    dataIndex: 'usmleId',
    key: OET_LIST_COLUMN_KEYS.usmleId,
    render: (text: any, record: any) => <div>{record.usmleId?.padStart(8, '0')}</div>,
  },
  {
    title: 'Match Status',
    dataIndex: 'matched',
    key: OET_LIST_COLUMN_KEYS.matched,
    sorter: (a: any, b: any) => {
      if (a.matched) return b.matched ? a.matched.localeCompare(b.matched) : -1;
      if (b.matched) return a.matched ? b.matched.localeCompare(a.matched) : 1;
    },
  },
  {
    title: 'Testing Center',
    dataIndex: 'testingCenter',
    key: OET_LIST_COLUMN_KEYS.testingCenter,
    //sort alphabetically
    sorter: (a: any, b: any) => {
      if (a.testingCenter)
        return b.testingCenter ? a.testingCenter.localeCompare(b.testingCenter) : -1;
      if (b.testingCenter)
        return a.testingCenter ? b.testingCenter.localeCompare(a.testingCenter) : 1;
    },
  },
  {
    title: 'Test Date',
    dataIndex: 'examDate',
    key: OET_LIST_COLUMN_KEYS.examDate,
    //sort by date
    sorter: (a: any, b: any) => moment(a.examDate).unix() - moment(b.examDate).unix(),
    render: (text: any, record: any) => (
      <div>{record.examDate ? moment(record.examDate).format(DATE_FORMAT) : ''}</div>
    ),
  },
  {
    title: 'First Name',
    dataIndex: 'examineeFirstName',
    key: OET_LIST_COLUMN_KEYS.examineeFirstName,
    sorter: (a: any, b: any) => {
      if (a.examineeFirstName)
        return b.examineeFirstName ? a.examineeFirstName.localeCompare(b.examineeFirstName) : -1;
      if (b.examineeFirstName)
        return a.examineeFirstName ? b.examineeFirstName.localeCompare(a.examineeFirstName) : 1;
    },
  },
  {
    title: 'Last Name',
    dataIndex: 'examineeLastName',
    key: OET_LIST_COLUMN_KEYS.examineeLastName,
    sorter: (a: any, b: any) => {
      if (a.examineeLastName)
        return b.examineeLastName ? a.examineeLastName.localeCompare(b.examineeLastName) : -1;
      if (b.examineeLastName)
        return a.examineeLastName ? b.examineeLastName.localeCompare(a.examineeLastName) : 1;
    },
  },
  {
    title: 'Date of Birth',
    dataIndex: 'dateOfBirth',
    key: OET_LIST_COLUMN_KEYS.dateOfBirth,
    sorter: (a: any, b: any) => moment(a.dateOfBirth).unix() - moment(b.dateOfBirth).unix(),
    render: (text: any, record: any) => (
      <div>{record.dateOfBirth ? moment(record.dateOfBirth).format(DATE_FORMAT) : ''}</div>
    ),
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: OET_LIST_COLUMN_KEYS.gender,
    sorter: (a: any, b: any) => {
      if (a.gender) return b.gender ? a.gender.localeCompare(b.gender) : -1;
      if (b.gender) return a.gender ? b.gender.localeCompare(a.gender) : 1;
    },
  },
  {
    title: 'Listening Score',
    dataIndex: 'listeningScore',
    key: OET_LIST_COLUMN_KEYS.listeningScore,
    sorter: (a: any, b: any) => {
      if (a.listeningScore)
        return b.listeningScore ? toInteger(a.listeningScore) - toInteger(b.listeningScore) : -1;
      if (b.listeningScore)
        return a.listeningScore ? toInteger(b.listeningScore) - toInteger(a.listeningScore) : 1;
    },
  },
  {
    title: 'Reading Score',
    dataIndex: 'readingScore',
    key: OET_LIST_COLUMN_KEYS.readingScore,
    sorter: (a: any, b: any) => {
      if (a.readingScore)
        return b.readingScore ? toInteger(a.readingScore) - toInteger(b.readingScore) : -1;
      if (b.readingScore)
        return a.readingScore ? toInteger(b.readingScore) - toInteger(a.readingScore) : 1;
    },
  },
  {
    title: 'Writing Score',
    dataIndex: 'writingScore',
    key: OET_LIST_COLUMN_KEYS.writingScore,
    sorter: (a: any, b: any) => {
      if (a.writingScore)
        return b.writingScore ? toInteger(a.writingScore) - toInteger(b.writingScore) : -1;
      if (b.writingScore)
        return a.writingScore ? toInteger(b.writingScore) - toInteger(a.writingScore) : 1;
    },
  },
  {
    title: 'Speaking Score',
    dataIndex: 'speakingScore',
    key: OET_LIST_COLUMN_KEYS.speakingScore,
    sorter: (a: any, b: any) => {
      if (a.speakingScore)
        return b.speakingScore ? toInteger(a.speakingScore) - toInteger(b.speakingScore) : -1;
      if (b.speakingScore)
        return a.speakingScore ? toInteger(b.speakingScore) - toInteger(a.speakingScore) : 1;
    },
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: OET_LIST_COLUMN_KEYS.createdAt,
    sorter: (a: any, b: any) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: OET_LIST_COLUMN_KEYS.updatedAt,
    sorter: (a: any, b: any) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
  },
  {
    title: 'Created User',
    dataIndex: 'createdUser',
    key: OET_LIST_COLUMN_KEYS.createdUser,
    sorter: (a: any, b: any) => {
      if (a.createdUser) return b.createdUser ? a.createdUser.localeCompare(b.createdUser) : -1;
      if (b.createdUser) return a.createdUser ? b.createdUser.localeCompare(a.createdUser) : 1;
    },
  },
  {
    title: 'Updated User',
    dataIndex: 'updatedUser',
    key: OET_LIST_COLUMN_KEYS.updatedUser,
    sorter: (a: any, b: any) => {
      if (a.updatedUser) return b.updatedUser ? a.updatedUser.localeCompare(b.updatedUser) : -1;
      if (b.updatedUser) return a.updatedUser ? b.updatedUser.localeCompare(a.updatedUser) : 1;
    },
  },
  {
    title: 'Outcome',
    dataIndex: 'result',
    key: OET_LIST_COLUMN_KEYS.result,
    sorter: (a: any, b: any) => {
      if (a.result) return b.result ? a.result.localeCompare(b.result) : -1;
      if (b.result) return a.result ? b.result.localeCompare(a.result) : 1;
    },
  },
];
//DEPRECATE AFTER OCTOBER 2020 RELEASE
export const CASE_LIST_TABLE_COLUMNS = [
  {
    title: 'USMLE ID',
    dataIndex: 'usmleId',
    key: CASE_LIST_COLUMN_KEYS.column_usmle_id,
  },
  {
    title: 'Days Since Last Update',
    dataIndex: 'updatedAt',
    key: CASE_LIST_COLUMN_KEYS.column_days_since_last_update,
    align: 'center',
    sorter: (a: any, b: any) => a.updatedAt - b.updatedAt,
  },
  {
    title: 'Document Last Upload Date',
    dataIndex: 'lastDocUploadTimestamp',
    key: CASE_LIST_COLUMN_KEYS.column_last_doc_upload_timestamp,
    align: 'center',
    sorter: (a: any, b: any) => {
      if (a.lastDocUploadTimestamp)
        return b.lastDocUploadTimestamp
          ? moment(a.lastDocUploadTimestamp).unix() - moment(b.lastDocUploadTimestamp).unix()
          : -1;
      if (b.lastDocUploadTimestamp)
        return a.lastDocUploadTimestamp
          ? moment(b.lastDocUploadTimestamp).unix() - moment(a.lastDocUploadTimestamp).unix()
          : 1;
    },
  },
  {
    title: 'Submission Date',
    dataIndex: 'submittedAt',
    key: CASE_LIST_COLUMN_KEYS.submittedAt,
    sorter: (a: any, b: any) => {
      if (a.submittedAt)
        return b.submittedAt ? moment(a.submittedAt).unix() - moment(b.submittedAt).unix() : -1;
      if (b.submittedAt)
        return a.submittedAt ? moment(b.submittedAt).unix() - moment(a.submittedAt).unix() : 1;
    },
  },
  {
    title: 'Pathway',
    dataIndex: 'currentPathway',
    key: CASE_LIST_COLUMN_KEYS.column_pathway,
    sorter: (a: any, b: any) => {
      if (a.currentPathway)
        return b.currentPathway ? a.currentPathway.localeCompare(b.currentPathway) : -1;
      if (b.currentPathway)
        return a.currentPathway ? b.currentPathway.localeCompare(a.currentPathway) : 1;
    },
  },
  {
    title: 'Case Status',
    dataIndex: 'caseState',
    key: CASE_LIST_COLUMN_KEYS.column_case_status,
    sorter: (a: any, b: any) => {
      if (a.caseState) return b.caseState ? a.caseState.localeCompare(b.caseState) : -1;
      if (b.caseState) return a.caseState ? b.caseState.localeCompare(a.caseState) : 1;
    },
  },
  {
    title: 'General Eligibility',
    dataIndex: 'generalEligibilityStatus',
    key: CASE_LIST_COLUMN_KEYS.column_general_eligibility,
    sorter: (a: any, b: any) => {
      if (a.generalEligibilityStatus)
        return b.generalEligibilityStatus
          ? a.generalEligibilityStatus.localeCompare(b.generalEligibilityStatus)
          : -1;
      if (b.generalEligibilityStatus)
        return a.generalEligibilityStatus
          ? b.generalEligibilityStatus.localeCompare(a.generalEligibilityStatus)
          : 1;
    },
  },
  {
    title: 'OET Case Status',
    dataIndex: 'englishEligibilityOutput',
    key: CASE_LIST_COLUMN_KEYS.column_oet_case_status,
    sorter: (a: any, b: any) => {
      if (a.englishEligibilityOutput)
        return b.englishEligibilityOutput
          ? a.englishEligibilityOutput.localeCompare(b.englishEligibilityOutput)
          : -1;
      if (b.englishEligibilityOutput)
        return a.englishEligibilityOutput
          ? b.englishEligibilityOutput.localeCompare(a.englishEligibilityOutput)
          : 1;
    },
  },
  {
    title: 'Pathway Case Status',
    dataIndex: 'pathwayEligibilityOutput',
    key: CASE_LIST_COLUMN_KEYS.column_pathway_case_status,
    sorter: (a: any, b: any) => {
      if (a.pathwayEligibilityOutput)
        return b.pathwayEligibilityOutput
          ? a.pathwayEligibilityOutput.localeCompare(b.pathwayEligibilityOutput)
          : -1;
      if (b.pathwayEligibilityOutput)
        return a.pathwayEligibilityOutput
          ? b.pathwayEligibilityOutput.localeCompare(a.pathwayEligibilityOutput)
          : 1;
    },
  },

  {
    title: 'Assigned To',
    dataIndex: 'eligibilityReviewAssigneeName',
    key: CASE_LIST_COLUMN_KEYS.column_assigned_to,
    sorter: (a: any, b: any) => {
      if (a.eligibilityReviewAssigneeName)
        return b.eligibilityReviewAssigneeName
          ? a.eligibilityReviewAssigneeName.localeCompare(b.eligibilityReviewAssigneeName)
          : -1;
      if (b.eligibilityReviewAssigneeName)
        return a.eligibilityReviewAssigneeName
          ? b.eligibilityReviewAssigneeName.localeCompare(a.eligibilityReviewAssigneeName)
          : 1;
    },
  },
];

export const OET_DETAIL_TABLE_COLUMNS = [
  {
    title: 'Examinee Name',
    dataIndex: 'cases',
    key: 'cases',
    render: (text: any, record: any) => <div>{`${record.lastName}, ${record.firstName}`}</div>,
  },
  {
    title: 'USMLE ID',
    dataIndex: 'usmleId',
    key: 'usmleId',
  },
  {
    title: 'OET Applicant ID',
    dataIndex: 'oetCandidateId',
    key: 'oetCandidateId',
  },
];

export const NUMBER_OF_RECORDS_PER_PAGE = [
  {
    key: 25,
    value: 25,
  },
  {
    key: 50,
    value: 50,
  },
  {
    key: 100,
    value: 100,
  },
  {
    key: 150,
    value: 150,
  },
  {
    key: 200,
    value: 200,
  },
];

export const SCREEN_TYPE = {
  CASE_LIST: 'Case List',
  OET: 'OET',
  OET_DETAIL: 'OET Detail',
};

//case state lookup values
export const CASE_STATE = [
  { key: 'ApplicationCreation', value: 'Application Created' },
  { key: 'ApplicationSubmitted', value: 'Application Submitted' },
  { key: 'EligibilityReview', value: 'Eligibility Review' },
  { key: 'QAReady', value: 'QA Ready' },
  { key: 'QAReview', value: 'QA Review' },
  { key: 'ApplicationCompleted', value: 'Application Completed' },
];

//Pathway case status lookup values
export const PATHWAY_ELIGIBILITY_STATUS = [
  //pathway 1
  { key: '1-not-started', value: '(1) Not Started' },
  { key: '1-in-progress', value: '(1) In Progress' },
  { key: '1-approved', value: '(1) Approved' },
  { key: '1-rejected', value: '(1) Rejected' },
  {
    key: '1-license-review-in-process',
    value: '(1) License review in process',
  },
  {
    key: '1-awaiting-applicant-resolution-license',
    value: '(1) Awaiting applicant resolution –License',
  },
  {
    key: '1-awaiting-applicant-resolution-translation',
    value: '(1) Awaiting applicant resolution –Translation',
  },
  {
    key: '1-exception-review-escalation-required',
    value: '(1) Exception Review/Escalation Required',
  },
  {
    key: '1-awaiting-licensure-document-from-issuing-authority',
    value: '(1) Awaiting Licensure Document from issuing authority',
  },
  {
    key: '1-awaiting-licensure-document-from-applicant',
    value: '(1) Awaiting Licensure Document from applicant',
  },
  { key: '1-sent-for-verification', value: '(1) Sent for Verification' },
  { key: '1-resent-for-verification', value: '(1) Resent for Verification' },
  {
    key: '1-verification-received-in-review',
    value: '(1) Verification received; In Review',
  },
  {
    key: '1-verification-rejected-and-resent',
    value: '(1) Verification Rejected and Resent',
  },
  {
    key: '1-verification-review-complete',
    value: '(1) Verification review complete',
  },
  //pathway 2
  { key: '2-not-started', value: '(2) Not Started' },
  { key: '2-approved', value: '(2) Approved' },
  { key: '2-rejected', value: '(2) Rejected' },
  // { key: '2-osce-review-in-process', value: '(2) OSCE review in process' },
  // {
  //   key: '2-awaiting-applicant-resolution-osce',
  //   value: '(2) Awaiting applicant resolution –OSCE',
  // },
  {
    key: '2-awaiting-attestation-document',
    value: '(2) Awaiting Attestation Document',
  },
  {
    key: '2-exception-review-escalation-reqired',
    value: '(2) Exception Review/Escalation Required',
  },
  { key: '2-sent-for-verification', value: '(2) Sent for Verification' },
  { key: '2-resent-for-verification', value: '(2) Resent for Verification' },
  {
    key: '2-verification-received-in-review',
    value: '(2) Verification received; In Review',
  },
  {
    key: '2-verification-rejected-and-resent',
    value: '(2) Verification Rejected and Resent',
  },
  {
    key: '2-verification-review-complete',
    value: '(2) Verification review complete',
  },
  {
    key: '2-attestation-received-in-review',
    value: '(2) Attestation received; In Review',
  },
  {
    key: '2-attestation-rejected-and-resent',
    value: '(2) Attestation Rejected and Resent',
  },
  {
    key: '2-attestation-review-complete',
    value: '(2) Attestation review complete',
  },
  //pathway3
  { key: '3-not-started', value: '(3) Not Started' },
  { key: '3-approved', value: '(3) Approved' },
  { key: '3-rejected', value: '(3) Rejected' },
  {
    key: '3-license-eligibility-review-in-process',
    value: '(3) Eligibility review in process',
  },
  {
    key: '3-license-awaiting-applicant-resolution',
    value: '(3) Awaiting applicant resolution',
  },
  {
    key: '3-license-exception-review-escalation-reqired',
    value: '(3) Exception Review/Escalation Required',
  },
  {
    key: '3-license-attestation-received-in-review',
    value: '(3) Attestation received; In Review',
  },
  {
    key: '3-license-attestation-request-sent-to-school',
    value: '(3) Attestation Request Sent to School',
  },
  {
    key: '3-license-awaiting-attestation-document',
    value: '(3) Awaiting Attestation Document',
  },
  {
    key: '3-license-attestation-rejected-and-resent',
    value: '(3) Attestation Rejected and Resent',
  },
  {
    key: '3-license-attestation-review-complete',
    value: '(3) Attestation review complete',
  },
  {
    key: '3-license-attestation-rejected-by-school',
    value: '(3) Attestation rejected by school',
  },
  //pathway 4
  { key: '4-not-started', value: '(4) Not Started' },
  { key: '4-approved', value: '(4) Approved' },
  { key: '4-rejected', value: '(4) Rejected' },
  {
    key: '4-license-eligibility-review-in-process',
    value: '(4) Eligibility review in process',
  },
  {
    key: '4-license-awaiting-applicant-resolution',
    value: '(4) Awaiting applicant resolution',
  },
  {
    key: '4-license-exception-review-escalation-reqired',
    value: '(4) Exception Review/Escalation Required',
  },
  {
    key: '4-license-attestation-received-in-review',
    value: '(4) Attestation received; In Review',
  },
  {
    key: '4-license-attestation-request-sent-to-school',
    value: '(4) Attestation Request Sent to School',
  },
  {
    key: '4-license-awaiting-attestation-document',
    value: '(4) Awaiting Attestation Document',
  },
  {
    key: '4-license-attestation-rejected-and-resent',
    value: '(4) Attestation Rejected and Resent',
  },
  {
    key: '4-license-attestation-review-complete',
    value: '(4) Attestation review complete',
  },
  {
    key: '4-license-attestation-rejected-by-school',
    value: '(4) Attestation rejected by school',
  },
  //pathway 5
  { key: '5-not-started', value: '(5) Not Started' },
  { key: '5-approved', value: '(5) Approved' },
  { key: '5-rejected', value: '(5) Rejected' },
  {
    key: '5-license-eligibility-review-in-process',
    value: '(5) Eligibility review in process',
  },
  {
    key: '5-license-awaiting-applicant-resolution',
    value: '(5) Awaiting applicant resolution',
  },
  {
    key: '5-license-exception-review-escalation-reqired',
    value: '(5) Exception Review/Escalation Required',
  },
  {
    key: '5-license-attestation-received-in-review',
    value: '(5) Attestation received; In Review',
  },
  {
    key: '5-license-attestation-request-sent-to-school',
    value: '(5) Attestation Request Sent to School',
  },
  {
    key: '5-license-awaiting-attestation-document',
    value: '(5) Awaiting Attestation Document',
  },
  {
    key: '5-license-attestation-rejected-and-resent',
    value: '(5) Attestation Rejected and Resent',
  },
  {
    key: '5-license-attestation-review-complete',
    value: '(5) Attestation review complete',
  },
  {
    key: '5-license-attestation-rejected-by-school',
    value: '(5) Attestation rejected by school',
  },
  //Pathway 6
  { key: '6-approved', value: '(6) Approved' },
  { key: '6-rejected', value: '(6) Rejected' },
  { key: '6-pending', value: '(6) Pending' },
  { key: '6-minicex-review-complete', value: '(6) Mini-CEX Review Complete' },
  { key: '6-committee-review-required', value: '(6) Committee Review Required' },
  { key: '6-committee-review-in-progress', value: '(6) Committee Review in Progress' },
  {
    key: '6-committee-review-complete-in-appeal-period',
    value: '(6) Committee Review Complete, In appeal period',
  },
  { key: '6-appeal-requested', value: '(6) Appeal Requested' },
  { key: '6-appeal-in-progress', value: '(6) Appeal in Progress' },
  //Pathway X
  {
    key: 'X-not-started',
    value: '(X) Not Started',
  },
  {
    key: 'X-in-progress',
    value: '(X) In Progress',
  },
  {
    key: 'X-approved',
    value: '(X) Approved',
  },
  {
    key: 'X-rejected',
    value: '(X) Rejected',
  },
  {
    key: 'X-exception-exception-request-in-process',
    value: '(X) Exception request in process',
  },
  {
    key: 'X-awaiting-applicant-resolution',
    value: '(X) Awaiting applicant resolution',
  },
  {
    key: 'X-exception-request-under-review-with-operations',
    value: '(X) Exception request under review with Operations Leadership',
  },
  {
    key: 'X-exception-request-under-review-with-enterprise',
    valeu: '(X) Exception request under review with Enterprise Leadership',
  },
  {
    key: 'X-exception-review-complete',
    valeu: '(X) Exception review complete',
  },
  {
    key: 'X-pathway-exception-request-in-process',
    value: '(X) Exception request in process',
  },
  {
    key: 'X-pathway-awaiting-applicant-resolution',
    value: '(X) Awaiting applicant resolution',
  },
  {
    key: 'X-pathway-awaiting-verification-licensure-document',
    value: '(X) Awaiting Verification of Licensure Document (Pathway 1)',
  },
  // {
  //   key: 'x-pathway-awaiting-verification-osce-document',
  //   value: '(X) Awaiting Verification of OSCE Document (Pathway 2)',
  // },
  {
    key: 'X-pathway-awaiting-attestation-pathway-2',
    value: '(X) Awaiting Attestation (Pathway 2)',
  },
  {
    key: 'X-pathway-awaiting-attestation-pathway-345',
    value: '(X) Awaiting Attestation (Pathway 3, 4, 5)',
  },
  {
    key: 'X-pathway-document-received',
    value: '(X) Pathway document received; In Review',
  },
  {
    key: 'X-pathway-verification-rejected-and-resent',
    value: '(X) Pathway verification Rejected and Resent',
  },
  {
    key: 'X-pathway-verification-complete',
    value: '(X) Pathway verification review complete',
  },
];

//oet case status lookup values
export const ENGLISH_ELIGIBILITY_STATUS = [
  { key: 'not-started', value: 'Not Started' },
  { key: 'approved', value: 'Approved' },
  { key: 'rejected', value: 'Rejected' },
  { key: 'score-not-yet-received', value: 'Score not yet received' },
  { key: 'score-received', value: 'Score Received from OET' },
  { key: 'awaiting-applicant-feedback', value: 'Awaiting applicant feedback' },
  { key: 'score-review-in-process', value: 'Score Review in process' },
  {
    key: 'exception-review-escalation-reqired',
    value: 'Exception Review/EscalationRequired',
  },
  { key: 'score-review-complete', value: 'Score Review complete' },
];

export const GENERAL_ELIGIBILITY_STATUS = [
  {
    key: 'Approved',
    value: 'Approved',
  },
  {
    key: 'Rejected',
    value: 'Rejected',
  },
  {
    key: 'Pending',
    value: 'Pending',
  },
  {
    key: 'NA',
    value: 'NA',
  },
];

export const OET_RESULTS_COLOR = {
  GREEN: '#90EE90',
  RED: '#FFCCCB',
};

export const OET_SEARCH_BY_OPTIONS = {
  LAST_NAME: 'searchByLastName',
  FIRST_NAME: 'searchByFirstName',
  USMLE_ID: 'searchByUSMLEID',
  CANDIDATE_ID: 'searchByCandidateID',
};

export const PATHWAY_NAMES = [
  { key: 'PATHWAY1', value: 'Pathway 1' },
  { key: 'PATHWAY2', value: 'Pathway 2' },
  { key: 'PATHWAY3', value: 'Pathway 3' },
  { key: 'PATHWAY4', value: 'Pathway 4' },
  { key: 'PATHWAY5', value: 'Pathway 5' },
  { key: 'PATHWAY6', value: 'Pathway 6' },
  { key: 'PATHWAYX', value: 'Exception' },
];

export const LOGIN_TYPE = {
  Administration: 'Administration',
};

export const GetScoreCategory = (score: number) => {
  if (score <= 3) {
    return 'Performs at the level of a medical school student beginning clinical training';
  }
  if (score <= 6) {
    return 'Performs at the level of a medical school graduate';
  }
  return 'Performs at the level of an experienced doctor/ practicing physician';
};

export const EvaluationCategory: Record<string, string> = {
  MEDICAL_INTERVIEWING: 'Medical Interviewing Skills',
  PHYSICAL_EXAMINATION: 'Physical Examination Skills',
  COMMUNICATION_PROFESSIONALISM: 'Professionalism / Communication Skills',
  CLINICAL_REASONING: 'Clinical Reasoning & Judgement',
};

export const ADMIN_ALLOWED_ACTIONS = {
  VIEW: 'VIEW',
  REJECT: 'REJECT',
  CANCEL: 'CANCEL',
};

export const getPhysicianName = (lastName: any, restOfName: any) => {
  if (!lastName && !restOfName) {
    return 'NEW EVALUATOR';
  } else if (lastName && !restOfName) {
    return lastName;
  } else {
    return `${lastName}, ${restOfName}`;
  }
};

//Convert unix epoch in miliseconds to UTC
export const convertEpochToUTC = (epochTime: any) => {
  if (epochTime !== undefined && epochTime !== null) {
    return moment(+epochTime)
      .utc()
      .format(PATHWAY6_DATE_FORMAT);
  } else {
    return '';
  }
};

export const convertDateFromTZTime = (tzTime: string) => {
  if (tzTime) {
    return moment(tzTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ').utc().format('DD-MMM-YYYY');
  }

  return 'N/A';
};

export const MINICEX_REJECTION_REASONS = [
  {
    key: 'INCORRECT_PHYSICIAN_NAME',
    value: 'I am not the physician evaluator named here.',
  },
  { key: 'RELATIVE', value: 'I am a relative of this applicant' },
  {
    key: 'PHYSICIAN_DOES_NOT_MEET_CRITERIA',
    value: `I do not meet the Criteria for Acceptable Physician Evaluators as outlined on the ECFMG website.`,
  },
  {
    key: 'ENCOUNTER_DOES_NOT_MEET_CRITERIA',
    value: `I am unable to observe and evaluate this applicant in an encounter that meets the Criteria for Acceptable Clinical Encounters as outlined on the ECFMG website.`,
  },
  { key: 'OTHER', value: 'Other' },
];

export const MINICEX_REJECTION_REASON_KEYS = {
  INCORRECT_PHYSICIAN_NAME: 'INCORRECT_PHYSICIAN_NAME',
  RELATIVE: 'RELATIVE',
  PHYSICIAN_DOES_NOT_MEET_CRITERIA: 'PHYSICIAN_DOES_NOT_MEET_CRITERIA',
  ENCOUNTER_DOES_NOT_MEET_CRITERIA: 'ENCOUNTER_DOES_NOT_MEET_CRITERIA',
  OTHER: 'OTHER',
};

export const CEX_STATUS = {
  MINICEX_CREATED_PHYSICIAN_INVITE_PENDING: 'Request submission pending',
  MINICEX_REJECTED_PHYSICIAN_INVITE_FAILED: 'Request rejected - e-mail undeliverable',
  MINICEX_CREATED_PHYSICIAN_INVITE_SENT: 'New',
  MINICEX_CREATED_PHYSICIAN_REGISTERED: 'New',
  MINICEX_REJECTED_BY_PHYSICIAN: 'Rejected',
  MINICEX_ACCEPTED: 'In progress',
  MINICEX_COMPLETED: 'Mini-CEX complete',
  MINICEX_EXPIRED: 'Expired',
  MINICEX_WITHDRAWN: 'Withdrawn by applicant',
  MINICEX_REJECTED_BY_SYSTEM: 'Withdrawn',
  MINICEX_REJECTED_BY_STAFF: 'Withdrawn',
  MINICEX_CANCELED_BY_STAFF: 'Cancelled',
};

// these won't have View button
export const WITHDRAW_STATUSES = [
  'MINICEX_WITHDRAWN',
  'MINICEX_REJECTED_BY_SYSTEM',
  'MINICEX_REJECTED_BY_STAFF',
];

export const CEX_STATUS_KEYS = {
  MINICEX_CREATED_PHYSICIAN_INVITE_PENDING: 'MINICEX_CREATED_PHYSICIAN_INVITE_PENDING',
  MINICEX_REJECTED_PHYSICIAN_INVITE_FAILED: 'MINICEX_REJECTED_PHYSICIAN_INVITE_FAILED',
  MINICEX_CREATED_PHYSICIAN_INVITE_SENT: 'MINICEX_CREATED_PHYSICIAN_INVITE_SENT',
  MINICEX_CREATED_PHYSICIAN_REGISTERED: 'MINICEX_CREATED_PHYSICIAN_REGISTERED',
  MINICEX_REJECTED_BY_PHYSICIAN: 'MINICEX_REJECTED_BY_PHYSICIAN',
  MINICEX_ACCEPTED: 'MINICEX_ACCEPTED',
  MINICEX_COMPLETED: 'MINICEX_COMPLETED',
  MINICEX_EXPIRED: 'MINICEX_EXPIRED',
  MINICEX_WITHDRAWN: 'MINICEX_WITHDRAWN',
  MINICEX_REJECTED_BY_SYSTEM: 'MINICEX_REJECTED_BY_SYSTEM',
  MINICEX_REJECTED_BY_STAFF: 'MINICEX_REJECTED_BY_STAFF',
  MINICEX_CANCELED_BY_STAFF: 'MINICEX_CANCELED_BY_STAFF',
};
export const PATHWAY6_SUBCASE_STATUSES = [
  { key: 'pending', value: 'Pending' },
  { key: 'minicex-review-complete', value: 'Mini-CEX Review Complete' },
  { key: 'committee-review-required', value: 'Committee Review Required' },
  { key: 'committee-review-in-progress', value: 'Committee Review in Progress' },
  {
    key: 'committee-review-complete-in-appeal-period',
    value: 'Committee Review Complete, In appeal period',
  },
  { key: 'appeal-requested', value: 'Appeal Requested' },
  { key: 'appeal-in-progress', value: 'Appeal in Progress' },
];

export const PATHWAY6_RESPONSE_TYPE = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export enum PATHWAY6_SCORE_CATEGORIES {
  MEDICAL_INTERVIEWING = 'MEDICAL_INTERVIEWING',
  PHYSICAL_EXAMINATION = 'PHYSICAL_EXAMINATION',
  COMMUNICATION_PROFESSIONALISM = 'COMMUNICATION_PROFESSIONALISM',
  CLINICAL_REASONING = 'CLINICAL_REASONING',
}

export enum SummarySectionsIndication {
  // 03307790
  Pw6, // step2CSFailed = true
  // 10164861
  Login_Pw12_2b, // step2CSFailed = false, schoolPathway = PATHWAY2, isYearEligible = true
  // 03572310
  Pw12_2b_6, // step2CSFailed = false, schoolPathway = PATHWAY2, isYearEligible = false
  // 09849340
  Pw12_2b_345, // step2CSFailed = false, schoolPathway = PATHWAY3 or PATHWAY4 or PATHWAY5, isYearEligible = true
  // 06517056, 08048639
  Pw12_2b_345_6, // step2CSFailed = false, schoolPathway != PATHWAY3 or PATHWAY4 or PATHWAY5 || isYearEligible = false
}

export const getSummarySectionIndication = (
  isStep2CSFailed: any,
  schoolPathway: any,
  isYearEligible: any
) => {
  if (isStep2CSFailed) {
    return SummarySectionsIndication.Pw6;
  }
  if (schoolPathway === 'PATHWAY2') {
    if (isYearEligible) {
      return SummarySectionsIndication.Login_Pw12_2b;
    }
    return SummarySectionsIndication.Pw12_2b_6;
  }
  if (
    schoolPathway === 'PATHWAY3' ||
    schoolPathway === 'PATHWAY4' ||
    schoolPathway === 'PATHWAY5'
  ) {
    if (isYearEligible) {
      return SummarySectionsIndication.Pw12_2b_345;
    }
    return SummarySectionsIndication.Pw12_2b_345_6;
  }
  return SummarySectionsIndication.Pw12_2b_345_6;
};

export const EncounterSettings = [
  { key: 'IN-PATIENT', value: 'In-Patient' },
  { key: 'OUTPATIENT', value: 'Outpatient' },
  { key: 'EMERGENCY', value: 'Emergency Department' },
  { key: 'OTHER', value: 'Other' },
];

export enum SORT_FIELD {
  ASSIGNED_TO = 'AssignedTo',
  CASE_STATUS = 'CaseStatus',
  DOCUMENT_LAST_UPDATE_DATE = 'DocumentLastUpdateDate',
  GENERAL_ELIGIBILITY = 'GeneralEligibility',
  LAST_UPDATE_DATE = 'LastUpdateDate',
  OET_CASE_STATUS = 'OETCaseStatus',
  PATHWAY = 'Pathway',
  PATHWAYS_CASE_STATUS = 'PathwaysCaseStatus',
  SUBMISSION_DATE = 'SubmissionDate',
}

export enum SORT_ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
  NOSORT = 'NOSORT',
}

export const TAG_STATUSES = {
  BLOCKED: 'Block',
};

export const TAG_STATUS_MAPPING = [
  {
    key: 'block',
    value: 'Block',
  },
];

export const formatMonths = (months: string) => {
  const monthDict: { [key: string]: string } = {
    NA: 'N/A',
    'less-than-1-month': 'Less than 1',
    '1-month': '1',
    '2-months': '2',
    '3-months': '3',
    '4-months': '4',
    '5-months': '5',
    '6-months': '6',
    '7-months': '7',
    '8-months': '8',
    '9-months': '9',
    '10-months': '10',
    '11-months': '11',
  };
  return monthDict[months] || 'N/A';
};

export const CHECKLIST_EVALUATION_MAPPINGS = [
  {key: "Yes", value: "Yes"},
  {key: "No", value: "No"},
  {key: "Not Applicable", value: "N/A"}
]