import React, { FC, useState } from "react";
import { Divider } from "antd";
import moment from "moment";
import PropTypes, { InferProps } from "prop-types";
import { DATE_FORMAT } from "../../../constants";

const ReviewLanguageAssessmentPropTypes = {
  languageAssessment: PropTypes.shape({
    userResponse: PropTypes.shape({
      hasPassedExam: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.shape({
      data: PropTypes.shape({
        candidateId: PropTypes.string,
        examDate: PropTypes.string,
        documents: PropTypes.shape({
          docType: PropTypes.string,
          title: PropTypes.string,
          docId: PropTypes.string,
        }),
      }),
    }),
  }),
};

export type ReviewLanguageAssessmentProps = InferProps<
  typeof ReviewLanguageAssessmentPropTypes
>;

//Q-H
const ReviewLanguageAssessmentInformation: FC<
  ReviewLanguageAssessmentProps
> = ({ languageAssessment }) => {
  const userResponse = languageAssessment?.userResponse;
  const applicantProvidedData = languageAssessment?.applicantProvidedData as any;

  return (
    <>
      {(userResponse && applicantProvidedData && applicantProvidedData.length) >
        0 && (
        <div>
          <Divider plain orientation="left">
            <b>English Language Proficiency Eligibility</b>
          </Divider>
          {applicantProvidedData.map((languageAssessmentData: any) => {
            return (
              <div>
                {languageAssessmentData.data.examDate && (
                  <div>
                    Exam date:{" "}
                    <b>
                      {moment(languageAssessmentData.data.examDate).format(
                        DATE_FORMAT
                      )}
                    </b>
                    <br />
                    <br />
                  </div>
                )}

                {languageAssessmentData.data.candidateId && (
                  <div>
                    Candidate Id:{" "}
                    <b>{languageAssessmentData.data.candidateId}</b>
                    <br />
                    <br />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default {
  Component: ReviewLanguageAssessmentInformation,
};
