import { Card, Col, List, Row, Table } from 'antd';
import { FC } from 'react';
import {
  ArchivedCaseFinanceRevenueRecognitionType,
  ArchivedCaseFinanceType,
} from '../../../../generated';
import dayjs from 'dayjs';

interface ArchivedCaseFinanceProps {
  data: ArchivedCaseFinanceType;
}

const ArchivedCaseFinance: FC<ArchivedCaseFinanceProps> = (props) => {
  const { data } = props;

  const revenueRecognitionColumns = [
    {
      title: 'Amount ($)',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'GL Debit Account ID',
      dataIndex: 'debitGlAccount',
      key: 'debitGlAccount',
    },
    {
      title: 'GL Credit Account ID',
      dataIndex: 'creditGlAccount',
      key: 'creditGlAccount',
    },
    {
      title: 'Timestamp',
      dataIndex: 'revenueRecognizedAt',
      key: 'revenueRecognizedAt',
    },
  ];

  return (
    <>
      <Card
        style={{ marginBottom: '20px', borderRadius: '12px' }}
        title="Total"
        bodyStyle={{ paddingTop: '5px', paddingBottom: '5px' }}
      >
        <List>
          <List.Item>
            <Row style={{ width: '100%' }}>
              <Col span={4}>Payment</Col>
              <Col span={6}>
                Amount: {data?.payment?.amt != undefined ? '$' + data?.payment?.amt : 'N/A'}
              </Col>
              <Col span={6}>PNREF#: {data?.payment?.pnRef ?? 'N/A'}</Col>
              <Col span={8}>
                Timestamp:{' '}
                {data?.payment?.transTime
                  ? dayjs(data?.payment?.transTime).format('MM/DD/YYYY hh:mm:ss A')
                  : 'N/A'}
              </Col>
            </Row>
          </List.Item>
          {data.refund && (
            <List.Item>
              <Row style={{ width: '100%' }}>
                <Col span={4}>Refund</Col>
                <Col span={6}>
                  Amount: {data.refund?.amt !== undefined ? '$' + data.refund?.amt : 'N/A'}
                </Col>
                <Col span={6}>PNREF#: {data.refund?.pnRef ?? 'N/A'}</Col>
                <Col span={8}>
                  Timestamp:{' '}
                  {data.refund?.transTime
                    ? dayjs(data.refund?.transTime).format('MM/DD/YYYY hh:mm:ss A')
                    : 'N/A'}
                </Col>
              </Row>
            </List.Item>
          )}
        </List>
      </Card>
      <Table
        columns={revenueRecognitionColumns}
        dataSource={data.revenueRecognition as ArchivedCaseFinanceRevenueRecognitionType[]}
        bordered
        pagination={false}
      />
    </>
  );
};

export default ArchivedCaseFinance;
