import { UiPortalConfigType } from '../../../../../generated';
import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';
import {
  Pathway1ReviewChecklistLicensureV1,
  Pathway1ReviewChecklistLicensureV2,
  Pathway1ReviewChecklistLicensureV3,
  Pathway1ReviewChecklistLicensureV4,
  Pathway1ReviewChecklistLicensureV5,
} from './pathway1-archived-case-review-checklist-versions';

export const Pathway1ArchivedCaseReviewChecklistLicensureFactory = (
  uiConfig: UiPortalConfigType
) => {
  for (const page of uiConfig?.config?.pages || []) {
    if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
      for (const component of page?.components || []) {
        if (component?.componentName === COMPONENT_NAME.PATHWAY1_CHECKLIST) {
          switch (component?.version) {
            case 1:
              return Pathway1ReviewChecklistLicensureV1;
            case 2:
              return Pathway1ReviewChecklistLicensureV2;
            case 3:
              return Pathway1ReviewChecklistLicensureV3;
            case 4:
              return Pathway1ReviewChecklistLicensureV4;
            case 5:
            default:
              return Pathway1ReviewChecklistLicensureV5;
          }
        }
      }
    }
  }
  return null;
};
