import React, { FC } from "react";
import { AuthorityCard } from "./authority-card";
import PropTypes, { InferProps } from "prop-types";

const authorityCardListPropTypes = {
  onEdit: PropTypes.func,
  authorities: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      data: PropTypes.shape({
        regulatoryAuthorityRef: PropTypes.shape({
          _id: PropTypes.string.isRequired,
          orgName: PropTypes.string.isRequired,
          countryName: PropTypes.string.isRequired,
        }).isRequired,
        licenseIssueDate: PropTypes.instanceOf(Date).isRequired,
        licenseExpiryDate: PropTypes.instanceOf(Date),
        isCurrentlyLicensed: PropTypes.bool.isRequired,
        hasDisciplinaryAction: PropTypes.bool.isRequired,
        licensureDocumentStatus: PropTypes.string,
      }).isRequired,
    }).isRequired
  ).isRequired,
};

export type AuthorityCardListProps = InferProps<
  typeof authorityCardListPropTypes
>;

export const AuthorityCardList: FC<AuthorityCardListProps> = ({
  onEdit,
  authorities,
}) => {
  const cardList = authorities.map((authority) => (
    <AuthorityCard
      key={authority._id}
      displayValues={authority}
      onEdit={() => onEdit && onEdit(authority._id)}
    />
  ));

  return (
    <div
      className="space-align-container"
      style={{ backgroundColor: "#f7f7f7" }}
    >
      {cardList}
    </div>
  );
};

AuthorityCard.defaultProps = {
  onEdit: () => {},
};
