import { FC } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import ArchivedActivityFeed from './archived-activity-feed';
import {
  ActivityLogType,
  ArchivedActivityFeedContainerArchivedCaseDocument,
} from '../../../../generated';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';

const ArchivedActivityFeedContainer: FC<any> = () => {
  const { caseId } = useParams();

  const {
    loading: caseLoading,
    error: caseError,
    data: caseData,
  } = useQuery(ArchivedActivityFeedContainerArchivedCaseDocument, {
    variables: {
      caseId: caseId ?? '',
    },
  });

  return (
    <ComponentQueryLoader
      loading={caseLoading}
      hasData={caseData?.archivedCase !== undefined}
      hasDataComponent={
        <ArchivedActivityFeed activityLog={caseData?.archivedCase?.activityLog as ActivityLogType[]} />
      }
      error={caseError}
    />
  );
};

export default ArchivedActivityFeedContainer;
