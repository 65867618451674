import React from "react";
import { Navigate } from "react-router-dom";

 const RequireAuth:any = (params:any) => {
    console.log(`params.isAuthenticated :${params.isAuthenticated }`);
    console.log(params);
    if(typeof params.isAuthenticated === 'undefined'){
        //still loading
        return <div>Loading MSAL...</div>
    }
    return (params.isAuthenticated === true ?  (params.children): (<Navigate to='/' />) );
}
export default RequireAuth;