import { Descriptions, Divider, Typography } from 'antd';
import { FC } from 'react';
import { ArchivedCaseAssessmentOetType } from '../../../../generated';
import dayjs from 'dayjs';

interface ArchivedCaseAssessmentsOETProps {
  data: ArchivedCaseAssessmentOetType;
}
export const ArchivedCaseAssessmentsOET: FC<ArchivedCaseAssessmentsOETProps> = (
  props
) => {
  return (
    <>
      {/* Applicant */}
      <Typography.Title level={4}>Applicant</Typography.Title>
      <Descriptions
        column={4}
        layout="vertical"
        style={{ marginLeft: '10px' }}
        labelStyle={{ fontSize: '10px' }}
      >
        <Descriptions.Item label="First Name" style={{ paddingBottom: '5px' }}>
          <Typography.Text>{props.data?.examineeFirstName}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Last Name" style={{ paddingBottom: '5px' }}>
          <Typography.Text>{props.data?.examineeLastName}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Date of Birth" style={{ paddingBottom: '5px' }}>
          <Typography.Text>
            {props.data?.dateOfBirth ? dayjs(props.data?.dateOfBirth).format('MM/DD/YYYY') : 'N/A'}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="USMLE ID" style={{ paddingBottom: '5px' }}>
          <Typography.Text>{props.data?.usmleId}</Typography.Text>
        </Descriptions.Item>
      </Descriptions>

      <Divider style={{ margin: '5px 0 10px 0 ' }} />

      {/* Exam */}
      <Typography.Title level={4}>Exam</Typography.Title>
      <Descriptions
        column={4}
        layout="vertical"
        style={{ marginLeft: '10px' }}
        labelStyle={{ fontSize: '10px' }}
      >
        <Descriptions.Item label="Candidate ID" style={{ paddingBottom: '5px' }}>
          <Typography.Text>{props.data?.candidateId}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Exam Date" style={{ paddingBottom: '5px' }}>
          <Typography.Text>
            {props.data?.examDate ? dayjs(props.data?.examDate).format('MM/DD/YYYY') : 'N/A'}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Testing Center" style={{ paddingBottom: '5px' }}>
          <Typography.Text>{props.data?.testingCenter}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Result" style={{ paddingBottom: '5px' }}>
          <Typography.Text>{props.data?.result}</Typography.Text>
        </Descriptions.Item>
      </Descriptions>

      <Divider style={{ margin: '5px 0 10px 0 ' }} />

      {/* Scores */}
      <Typography.Title level={4}>Scores</Typography.Title>
      <Descriptions
        column={4}
        layout="vertical"
        style={{ marginLeft: '10px' }}
        labelStyle={{ fontSize: '10px' }}
      >
        <Descriptions.Item label="Reading" style={{ paddingBottom: '5px' }}>
          <Typography.Text>{props.data?.readingScore}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Listening" style={{ paddingBottom: '5px' }}>
          <Typography.Text>{props.data?.listeningScore}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Writing" style={{ paddingBottom: '5px' }}>
          <Typography.Text>{props.data?.writingScore}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Speaking" style={{ paddingBottom: '5px' }}>
          <Typography.Text>{props.data?.speakingScore}</Typography.Text>
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
