import { Button, Modal, Tabs } from 'antd';
import { FC, useState } from 'react';
import ReviewRejectedMiniCEXFactory from '../../../../case-management/pathways/pathway6/pathway6-document-review/pathway6-review-rejected-miniCEX-factory';
import {
  getMiniCEXAttestationPDF,
  getMiniCEXPDF,
} from '../../../../case-management/pathways/pathway6/pathway6-document-review/pathway6-pdf-helpers';
import { SearchOutlined } from '@ant-design/icons';
import { MiniCexResponseType, PhysicianResponseType } from '../../../../../generated';
import { ADMIN_ALLOWED_ACTIONS } from '../../../../constants';

interface Pathway6ArchivedCaseAssessmentsViewMiniCexButtonProps {
  readOnlyMiniCEXData: MiniCexResponseType;
  currentPhysicianData: PhysicianResponseType;
  rejectionReason: string;
}
export const Pathway6ArchivedCaseAssessmentsViewMiniCexButton: FC<Pathway6ArchivedCaseAssessmentsViewMiniCexButtonProps> =
  (props) => {
    const [isReadOnlyMiniCEXVisible, setIsReadOnlyMiniCEXVisible] = useState(false);
    const [isReviewRejectedMiniCEXVisible, setIsReviewRejectedMiniCEXVisible] = useState(false);
    return (
      <>
        {props.readOnlyMiniCEXData?.summary?.allowedActions?.includes(
          ADMIN_ALLOWED_ACTIONS.VIEW
        ) && (
          <Button
            onClick={() => {
              if (props.rejectionReason) {
                setIsReviewRejectedMiniCEXVisible(true);
              } else {
                setIsReadOnlyMiniCEXVisible(true);
              }
            }}
            type="primary"
            style={{ fontSize: '14px', width: '100px', marginLeft: '5px' }}
            icon={<SearchOutlined />}
          >
            View
          </Button>
        )}

        <Modal
          title="Review Completed Mini-CEX"
          visible={isReadOnlyMiniCEXVisible}
          width="fit-content"
          bodyStyle={{ minWidth: '30vw' }}
          onCancel={() => {
            setIsReadOnlyMiniCEXVisible(false);
          }}
          destroyOnClose
          footer={[]}
        >
          <Tabs type="card">
            <Tabs.TabPane tab="Mini-CEX Summary" key="1">
              {getMiniCEXPDF(props.readOnlyMiniCEXData, props.currentPhysicianData)}
            </Tabs.TabPane>

            <Tabs.TabPane tab="Mini-CEX Evaluation Attestation" key="2">
              {getMiniCEXAttestationPDF(props.readOnlyMiniCEXData, props.currentPhysicianData)}
            </Tabs.TabPane>
          </Tabs>
        </Modal>

        <Modal
          title="Review Rejected Mini-CEX"
          visible={isReviewRejectedMiniCEXVisible}
          width={'60%'}
          onCancel={() => {
            setIsReviewRejectedMiniCEXVisible(false);
          }}
          footer={[
            <Button type="primary" onClick={() => setIsReviewRejectedMiniCEXVisible(false)}>
              Close
            </Button>,
          ]}
        >
          <ReviewRejectedMiniCEXFactory
            miniCEX={props.readOnlyMiniCEXData}
            physician={props.currentPhysicianData}
          />
        </Modal>
      </>
    );
  };
