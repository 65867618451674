import { FC, useState, useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ApplicantSummaryV2 from './versions/applicant-summary-v2';
import ApplicantSummaryV3 from './versions/applicant-summary-v3';
import ApplicantSummaryV4 from './versions/applicant-summary-v4';
import { UiPageConfigType, UiPortalConfigType } from '../../../../generated';
import ApplicantSummaryV5 from './versions/applicant-summary-v5';
import ApplicantSummaryV6 from './versions/applicant-summary-v6';

const COMPONENT_NAME = 'application-summary-review';
const PAGE_NAME = 'application-summary';

const ComponentPropTypes = {
  usmleID: PropTypes.string,
};

export interface IProps {
  versionConfig: UiPortalConfigType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes & IProps>;

const ApplicantSummaryFactory: FC<ComponentProps> = ({ versionConfig, usmleID }) => {
  const getApplicantSummary = () => {
    return versionConfig?.config?.pages.map((page: UiPageConfigType) => {
      if (page?.pageName === PAGE_NAME) {
        return page?.components?.map((component) => {
          if (component?.componentName === COMPONENT_NAME) {
            switch (component.version) {
              case 2:
                return <ApplicantSummaryV2 usmleID={usmleID} />;
              case 3:
                return <ApplicantSummaryV3 usmleID={usmleID} />;
              case 4:
                return <ApplicantSummaryV4 usmleID={usmleID} />;
              case 5: 
                return <ApplicantSummaryV5 usmleID={usmleID} />;
              case 6: 
                return <ApplicantSummaryV6 usmleID={usmleID} />;
              default:
                return <div>Version not found</div>;
            }
          }
        });
      }
    });
  };

  return <div>{getApplicantSummary()}</div>;
};

export default ApplicantSummaryFactory;
