import React, { FC, useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Space,
  DatePicker,
  Button,
  Input,
  InputNumber,
  Popconfirm,
  Typography,
  Select,
} from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import moment from 'moment-timezone';
const { Title } = Typography;

export const RULE_DETAIL_DISPLAY_MODE = {
  ADD_NEW: 'add new',
  EDIT: 'edit',
  NONE: 'none',
};
export const RuleDetailsType = {
  displayMode: PropTypes.string,
  onCancelCallBack: PropTypes.func.isRequired,
  onSaveCallBack: PropTypes.func.isRequired,
  onDeactivateCallBack: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    configName: PropTypes.string.isRequired, //rule name
    applicationType: PropTypes.string.isRequired,
    dateFrom: PropTypes.instanceOf(Date).isRequired,
    version: PropTypes.number.isRequired,
    glAccountCashControl: PropTypes.string.isRequired,
    glAccountUnearned: PropTypes.string.isRequired,
    glAccountEarned: PropTypes.string.isRequired,
    glAccountRefundControl: PropTypes.string.isRequired,
    standardCaseFee: PropTypes.number.isRequired,
    standardCaseInitialIntake: PropTypes.number.isRequired,
    exceptionCaseFee: PropTypes.number.isRequired,
    exceptionCaseInitialIntake: PropTypes.number.isRequired,
  }),
};

export type RuleDetailsProps = InferProps<typeof RuleDetailsType>;

const dateFormat = 'MM/DD/YYY';
var _id: any;
var configNameInput: any;
const RuleDetails: FC<RuleDetailsProps> = ({
  displayMode,
  initialValues,
  onSaveCallBack,
  onCancelCallBack,
  onDeactivateCallBack,
}) => {
  const [configName, setConfigName] = useState<string | undefined>(
    initialValues ? initialValues.configName : undefined
  );
  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    initialValues?.dateFrom ? initialValues.dateFrom : undefined
  );
  const [version, setVersion] = useState(initialValues?.version);
  const [glAccountCashControl, setGlAccountCashControl] = useState<string | undefined>(
    initialValues?.glAccountCashControl ? initialValues.glAccountCashControl : undefined
  );
  const [glAccountUnearned, setGlAccountUnearned] = useState<any>(
    initialValues?.glAccountUnearned ? initialValues.glAccountUnearned : undefined
  );
  const [glAccountEarned, setGlAccountEarned] = useState<any>(
    initialValues?.glAccountEarned ? initialValues.glAccountEarned : undefined
  );
  const [glAccountRefundControl, setGlAccountRefundControl] = useState<any>(
    initialValues?.glAccountRefundControl ? initialValues.glAccountRefundControl : undefined
  );
  const [standardCaseFee, setStandardCaseFee] = useState<any>(
    initialValues?.standardCaseFee ? initialValues.standardCaseFee : 0
  );
  const [standardCaseInitialIntake, setStandardCaseInitialIntake] = useState<any>(
    initialValues?.standardCaseInitialIntake ? initialValues.standardCaseInitialIntake : 0
  );
  const [exceptionCaseFee, setExceptionCaseFee] = useState<any>(
    initialValues?.exceptionCaseFee ? initialValues.exceptionCaseFee : 0
  );
  const [exceptionCaseInitialIntake, setExceptionCaseInitialIntake] = useState<any>(
    initialValues?.exceptionCaseInitialIntake ? initialValues?.exceptionCaseInitialIntake : 0
  );
  const [applicationType, setApplicationType] = useState<any>(initialValues?.applicationType ? initialValues.applicationType: undefined)

  //control loading on Save and Deactivate buttons
  const [isProcessingSaving, setIsProcessingSaving] = useState(false);
  const [isProcessingDeactivation, setIsProcessingDeactivation] = useState(false);
  //const [isProcessingDone, setIsProcessingDone] = useState(true);

  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      console.log('initialValues:');
      console.log(initialValues);
      //initialization
      _id = initialValues._id;
      setConfigName(initialValues.configName);
      setApplicationType(initialValues.applicationType)
      setDateFrom(moment(initialValues.dateFrom).local().toDate());
      setVersion(initialValues.version);
      setExceptionCaseFee(initialValues.exceptionCaseFee ? initialValues.exceptionCaseFee : 0);
      setExceptionCaseInitialIntake(
        initialValues.exceptionCaseInitialIntake ? initialValues.exceptionCaseInitialIntake : 0
      );
      setGlAccountCashControl(initialValues.glAccountCashControl);
      setGlAccountEarned(initialValues.glAccountEarned);
      setGlAccountRefundControl(initialValues.glAccountRefundControl);
      setGlAccountUnearned(initialValues.glAccountUnearned);
      setStandardCaseFee(initialValues.standardCaseFee ? initialValues.standardCaseFee : 0);
      setStandardCaseInitialIntake(
        initialValues.standardCaseInitialIntake ? initialValues.standardCaseInitialIntake : 0
      );
      //set field values on form
      form.setFieldsValue({
        configName: initialValues ? initialValues.configName : undefined,
        applicationType: initialValues ? initialValues.applicationType : undefined,
        dateFrom: initialValues?.dateFrom ? moment(initialValues.dateFrom).local() : undefined,
        version: initialValues?.version,
        glAccountCashControl: initialValues?.glAccountCashControl
          ? initialValues.glAccountCashControl
          : undefined,
        glAccountUnearned: initialValues?.glAccountUnearned
          ? initialValues.glAccountUnearned
          : undefined,
        glAccountEarned: initialValues?.glAccountEarned ? initialValues.glAccountEarned : undefined,
        glAccountRefundControl: initialValues?.glAccountRefundControl
          ? initialValues.glAccountRefundControl
          : undefined,
        standardCaseFee: initialValues?.standardCaseFee ? initialValues.standardCaseFee : 0,
        standardCaseInitialIntake: initialValues?.standardCaseInitialIntake
          ? initialValues.standardCaseInitialIntake
          : 0,
        exceptionCaseFee: initialValues?.exceptionCaseFee ? initialValues.exceptionCaseFee : 0,
        exceptionCaseInitialIntake: initialValues?.exceptionCaseInitialIntake
          ? initialValues?.exceptionCaseInitialIntake
          : 0,
      });
      configNameInput.focus();
    }
  }, [initialValues]);

  const checkIsFuture = (rule: any, value: any) => {
    if (displayMode === RULE_DETAIL_DISPLAY_MODE.EDIT) {
      return Promise.resolve();
    }

    if ((value && moment(value).isBefore(moment())) || moment(value) === moment()) {
      return Promise.reject('Effective date can only be in the future');
    }
    return Promise.resolve();
  };

  const onFinish = async () => {
    setIsProcessingSaving(true);
    // setIsProcessingDone(false);
    //prepare data for saving
    const toBeSavedData = {
      _id: _id,
      version: version,
      configName: configName,
      applicationType: applicationType,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      glAccountCashControl: glAccountCashControl?.trim(),
      glAccountUnearned: glAccountUnearned?.trim(),
      glAccountEarned: glAccountEarned?.trim(),
      glAccountRefundControl: glAccountRefundControl?.trim(),
      standardCaseFee: standardCaseFee,
      standardCaseInitialIntake: standardCaseInitialIntake,
      exceptionCaseFee: exceptionCaseFee,
      exceptionCaseInitialIntake: exceptionCaseInitialIntake,
    };
    await onSaveCallBack(toBeSavedData);
    setIsProcessingSaving(false);
    //setIsProcessingDone(true);
  };

  const onDeactivate = async () => {
    setIsProcessingDeactivation(true);
    await onDeactivateCallBack(_id, version);
    setIsProcessingDeactivation(false);
  };
  console.log(moment(dateFrom));

  const onDatePickerChange = async (value: any) => {
    value ? setDateFrom(moment(value).toDate()) : setDateFrom(undefined);
  };

  return (
    <div
      style={{
        display: displayMode === RULE_DETAIL_DISPLAY_MODE.NONE ? 'none' : 'block',
      }}
    >
      <Row>
        <Col>
          {displayMode === RULE_DETAIL_DISPLAY_MODE.ADD_NEW ? (
            <Title level={4}>Add New Rule</Title>
          ) : (
            <Title level={4}>Rule Details</Title>
          )}
        </Col>
      </Row>
      <Form form={form} layout={'vertical'} size="small" onFinish={onFinish}>
        <div style={{ border: '1px black solid', padding: '15px' }}>
          <Row>
            <Col md={24} lg={12}>
              <Form.Item
                label="Rule Name"
                name="configName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a Rule Name',
                  },
                ]}
              >
                <Input
                  ref={(input) => {
                    configNameInput = input;
                  }}
                  value={configName}
                  onChange={(e) => setConfigName(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Application Type"
                name="applicationType"
                rules={[
                  {
                    required: true,
                    message: 'Please enter an Application Type',
                  },
                ]}
              >
                <Select
                  value={applicationType}
                  onChange={(e) => setApplicationType(e)}
                  disabled={displayMode === RULE_DETAIL_DISPLAY_MODE.EDIT}
                  options={[
                    {
                      value: 'New',
                      label: 'New',
                    },
                    {
                      value: 'Revalidation',
                      label: 'Revalidation',
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={24} lg={12}>
              <Form.Item
                label="Effective Date"
                name="dateFrom"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Effective date',
                  },
                  { validator: checkIsFuture },
                ]}
              >
                <DatePicker
                  value={dateFrom ? moment(dateFrom) : undefined}
                  onChange={(value) => onDatePickerChange(value)}
                  disabled={displayMode === RULE_DETAIL_DISPLAY_MODE.EDIT}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={24} lg={12}>
              <Row>
                <Col span="12">
                  <Form.Item
                    label="Standard Case Charge"
                    name="standardCaseFee"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Standard Case Charge',
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={(value) => (value ? setStandardCaseFee(value) : undefined)}
                      disabled={displayMode === RULE_DETAIL_DISPLAY_MODE.EDIT}
                      value={standardCaseFee}
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) =>
                        typeof value !== 'undefined' ? value.replace(/\$\s?|(,*)/g, '') : ''
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span="12">
                  <Form.Item
                    label="Revenue Recognized Immediately"
                    name="standardCaseInitialIntake"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Revenue Recognized Immediately',
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={(value) =>
                        value ? setStandardCaseInitialIntake(value) : undefined
                      }
                      disabled={displayMode === RULE_DETAIL_DISPLAY_MODE.EDIT}
                      value={standardCaseInitialIntake}
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) =>
                        typeof value !== 'undefined' ? value.replace(/\$\s?|(,*)/g, '') : ''
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span="12">
                  <Form.Item
                    label="Exception Case Charge"
                    name="exceptionCaseFee"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Exception Case Charge',
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={(value) => (value ? setExceptionCaseFee(value) : undefined)}
                      disabled={displayMode === RULE_DETAIL_DISPLAY_MODE.EDIT}
                      value={exceptionCaseFee}
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) =>
                        typeof value !== 'undefined' ? value.replace(/\$\s?|(,*)/g, '') : ''
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span="12">
                  <Form.Item
                    label="Revenue Recognized Immediately"
                    name="exceptionCaseInitialIntake"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Revenue Recognized Immediately',
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={(value) =>
                        value ? setExceptionCaseInitialIntake(value) : undefined
                      }
                      disabled={displayMode === RULE_DETAIL_DISPLAY_MODE.EDIT}
                      value={exceptionCaseInitialIntake}
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) =>
                        typeof value !== 'undefined' ? value.replace(/\$\s?|(,*)/g, '') : ''
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Title level={4}>Revenue Recognized When Decision is Made (Approve/Deny)</Title>
                  <div>Remainder of Initial Payment</div>
                </Col>
              </Row>
            </Col>
            <Col md={24} lg={12}>
              <Title level={4}>GL Account Settings</Title>
              <Form.Item
                label="Cash Control"
                name="glAccountCashControl"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Cash Control',
                  },
                  {
                    pattern: /^\d{3}-\d{4}-\d{3}$/,
                    message: 'Please enter a valid account number',
                  },
                ]}
              >
                <Input
                  onChange={(e) => setGlAccountCashControl(e.target.value)}
                  disabled={displayMode === RULE_DETAIL_DISPLAY_MODE.EDIT}
                  value={glAccountCashControl}
                />
              </Form.Item>
              <Form.Item
                label="Pathways Unearned Revenue"
                name="glAccountUnearned"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Pathways Unearned Revenue',
                  },
                  {
                    pattern: /^\d{3}-\d{4}-\d{3}$/,
                    message: 'Please enter a valid account number',
                  },
                ]}
              >
                <Input
                  disabled={displayMode === RULE_DETAIL_DISPLAY_MODE.EDIT}
                  value={glAccountUnearned}
                  onChange={(e) => setGlAccountUnearned(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Certification Pathways ER"
                name="glAccountEarned"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Certification Pathways ER',
                  },
                  {
                    pattern: /^\d{3}-\d{4}-\d{3}$/,
                    message: 'Please enter a valid account number',
                  },
                ]}
              >
                <Input
                  disabled={displayMode === RULE_DETAIL_DISPLAY_MODE.EDIT}
                  value={glAccountEarned}
                  onChange={(e) => setGlAccountEarned(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Refund Control"
                name="glAccountRefundControl"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Refund Control',
                  },
                  {
                    pattern: /^\d{3}-\d{4}-\d{3}$/,
                    message: 'Please enter a valid account number',
                  },
                ]}
              >
                <Input
                  onChange={(e) => setGlAccountRefundControl(e.target.value)}
                  disabled={displayMode === RULE_DETAIL_DISPLAY_MODE.EDIT}
                  value={glAccountRefundControl}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Space direction={'horizontal'}>
                  <Button
                    onClick={onCancelCallBack}
                    disabled={isProcessingSaving || isProcessingDeactivation}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    loading={isProcessingSaving}
                    disabled={isProcessingSaving || isProcessingDeactivation}
                  >
                    Save
                  </Button>
                  <Popconfirm
                    disabled={
                      displayMode === RULE_DETAIL_DISPLAY_MODE.ADD_NEW ||
                      isProcessingSaving ||
                      isProcessingDeactivation
                    }
                    title={
                      <span>
                        Disable this rule for all newly created applications.
                        <br />
                        This can not be undone. <b>Continue ?</b>
                      </span>
                    }
                    okText="Yes"
                    cancelText="No"
                    onConfirm={onDeactivate}
                    placement="topRight"
                  >
                    <Button
                      disabled={
                        displayMode === RULE_DETAIL_DISPLAY_MODE.ADD_NEW ||
                        isProcessingSaving ||
                        isProcessingDeactivation
                      }
                      loading={isProcessingDeactivation}
                    >
                      Disable
                    </Button>
                  </Popconfirm>
                </Space>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default RuleDetails;

RuleDetails.defaultProps = {
  onSaveCallBack: () => {},
  onCancelCallBack: () => {},
  onDeactivateCallBack: () => {},
  displayMode: RULE_DETAIL_DISPLAY_MODE.NONE,
};
