import { FC, useState } from 'react';

import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import _ from 'lodash';
import PropTypes, { InferProps, any } from 'prop-types';
import {
  CASE_LIST_TABLE_COLUMNS,
  CUSTOMIZE_VIEWS_LABEL,
  DASHBOARD_TYPE,
  GENERAL_ELIGIBILITY_STATUS,
  PATHWAY6_SUBCASE_STATUSES,
  PATHWAY_NAMES,
  VIEW_DETAIL_DISPLAY_MODE,
} from '../../constants';
import { useFeatureFlags } from '../../feature-flag-react-lite';

const { Title } = Typography;

const { Option, OptGroup } = Select;
const CaseListFilterModalPropsTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  chooseViewCallback: PropTypes.func.isRequired,
  caseFilterInput: PropTypes.shape({
    usmleId: PropTypes.string,
    caseState: PropTypes.arrayOf(PropTypes.string),
    applicationType: PropTypes.arrayOf(PropTypes.string),
    currentPathway: PropTypes.arrayOf(PropTypes.string),
    pathwayEligibilityStatus: PropTypes.arrayOf(PropTypes.string),
    englishEligibilityStatus: PropTypes.arrayOf(PropTypes.string),
    generalEligibilityStatus: PropTypes.arrayOf(PropTypes.string),
    school: PropTypes.arrayOf(PropTypes.string),
    regulatoryAuthority: PropTypes.arrayOf(PropTypes.string),
  }),
  initialValues: PropTypes.shape({
    showFilterModal: PropTypes.bool.isRequired,
  }).isRequired,
  initialViewList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      viewName: PropTypes.string.isRequired,
      viewType: PropTypes.string.isRequired,
      viewFilters: PropTypes.shape({
        caseState: PropTypes.arrayOf(PropTypes.string),
        applicationType: PropTypes.arrayOf(PropTypes.string),
        generalEligibilityStatus: PropTypes.arrayOf(PropTypes.string),
        pathwayEligibilityStatus: PropTypes.arrayOf(PropTypes.string),
        englishEligibilityStatus: PropTypes.arrayOf(PropTypes.string),
        pathway: PropTypes.arrayOf(PropTypes.string),
        school: PropTypes.arrayOf(PropTypes.string),
        regulatoryAuthority: PropTypes.arrayOf(PropTypes.string),
      }).isRequired,
      displayColumns: PropTypes.arrayOf(PropTypes.string),
    }).isRequired
  ),
  processing: PropTypes.bool,
  schoolList: any,
  regulatoryAuthorityList: any,
};

//Save Request Data
interface ViewFiltersInput {
  caseState: string[];
  applicationType: string[];
  generalEligibilityStatus?: string[];
  pathwayEligibilityStatus: string[];
  englishEligibilityStatus: string[];
  pathway: string[];
  school: string[];
  regulatoryAuthority: string[];
}

interface ViewsInput {
  _id?: string;
  viewName: string;
  viewFilters: ViewFiltersInput;
  displayColumns: string[];
}

//=====================================
export type CaseListFilterModalProps = InferProps<typeof CaseListFilterModalPropsTypes>;

const VIEW_LIST_TABLE_SELECTION_MODE = {
  Single: 'radio',
  Multi: 'checkbox',
};

const CaseListFilterModal: FC<CaseListFilterModalProps> = ({
  initialValues,
  caseFilterInput,
  initialViewList,
  onCancel,
  onSave,
  onDelete,
  chooseViewCallback,
  schoolList,
  regulatoryAuthorityList,
}) => {
  const [disabledEditting, setDisabledEditting] = useState(false);
  const [viewName, setViewName] = useState<string | undefined>('');
  const [isProcessingSave, setIsProcessingSave] = useState(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const [displayMode, setDisplayMode] = useState(VIEW_DETAIL_DISPLAY_MODE.AddNew);
  const [loadedViewId, setLoadedViewId] = useState<string | undefined>(undefined);
  const [caseStateArray, setCaseStatusArray] = useState<any>([]);
  const [applicationTypeArray, setApplicationTypeArray] = useState<any>([]);
  const [generalEligibilityArray, setGeneralEligibilityArray] = useState<any>([]);
  const [columnsToDisplayArray, setColumnsToDisplayArray] = useState<any>([]);
  const [pathArray, setPathArray] = useState<any>([]);
  const [pathwayEligibilityStatusArray, setPathwayEligibilityStatusArray] = useState<any>([]);
  const [englishEligibilityStatusArray, setEnglishEligibilityStatusArray] = useState<any>([]);
  const [schoolListArray, setSchoolListArray] = useState<any>([]);
  const [regulatoryAuthorityListArray, setRegulatoryAuthorityListArray] = useState<any>([]);
  const [selectedViewId, setSelectedViewId] = useState<any>(undefined); //Id used to update view
  const [isDeleteButtonHidden, setIsDeleteButtonHidden] = useState(true);
  const [viewListTableSelectionMode, setViewListTableSelectionMode] = useState(
    VIEW_LIST_TABLE_SELECTION_MODE.Single
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [form] = Form.useForm();
  const viewList: any = JSON.parse(JSON.stringify(initialViewList));
  viewList.forEach((view: any) => {
    view.key = view._id;
  });
  const { GetFeatureFlagByName } = useFeatureFlags();

  async function onSaveClick() {
    //Create CaseFilterInput object
    var CaseFilterInput = {
      usmleId: '',
      caseState: [] as string[],
      applicationType: [] as string[],
      currentPathway: [] as string[],
      pathwayEligibilityStatus: [] as string[],
      englishEligibilityStatus: [] as string[],
      generalEligibilityStatus: [] as string[],
      school: [] as string[],
      regulatoryAuthority: [] as string[],
    };

    CaseFilterInput.caseState = caseStateArray;
    CaseFilterInput.applicationType = applicationTypeArray
    CaseFilterInput.pathwayEligibilityStatus = pathwayEligibilityStatusArray;
    CaseFilterInput.englishEligibilityStatus = englishEligibilityStatusArray;
    CaseFilterInput.generalEligibilityStatus = generalEligibilityArray;
    CaseFilterInput.currentPathway = pathArray;
    CaseFilterInput.school = schoolListArray;
    CaseFilterInput.regulatoryAuthority = regulatoryAuthorityListArray;

    setIsProcessingSave(true);
    if (!selectedViewId) {
      //add new
      let dashboardInput = addview();
      await onSave(CaseFilterInput, dashboardInput, false);
      onClickNewView();
    } else {
      //update
      let dashboardInput = updateview();
      await onSave(CaseFilterInput, dashboardInput, true);
    }
    setIsProcessingSave(false);
  }

  //prepare data to save into database
  const addview = () => {
    const viewFiltersInput: ViewFiltersInput = {
      caseState: caseStateArray,
      applicationType: applicationTypeArray,
      pathwayEligibilityStatus: pathwayEligibilityStatusArray,
      englishEligibilityStatus: englishEligibilityStatusArray,
      generalEligibilityStatus: generalEligibilityArray,
      pathway: pathArray,
      school: schoolListArray,
      regulatoryAuthority: regulatoryAuthorityListArray,
    };

    const inputForAddAndSave: ViewsInput = {
      _id: selectedViewId,
      viewName: viewName ?? '',
      viewFilters: viewFiltersInput,
      displayColumns: columnsToDisplayArray,
    };

    return inputForAddAndSave;
  };

  const updateview = () => {
    const viewFiltersInput: ViewFiltersInput = {
      caseState: caseStateArray,
      applicationType: applicationTypeArray,
      pathwayEligibilityStatus: pathwayEligibilityStatusArray,
      englishEligibilityStatus: englishEligibilityStatusArray,
      generalEligibilityStatus: generalEligibilityArray,
      pathway: pathArray,
      school: schoolListArray,
      regulatoryAuthority: regulatoryAuthorityListArray,
    };

    const viewsInput: ViewsInput = {
      _id: selectedViewId,
      viewName: viewName ?? '',
      viewFilters: viewFiltersInput,
      displayColumns: columnsToDisplayArray,
    };
    console.log(updateview, viewsInput);

    return viewsInput;
  };

  const onClickDelete = async () => {
    setIsProcessingDelete(true);
    await onDelete(selectedViewId);
    setIsProcessingDelete(false);
    onClickNewView(); //Clear modal data
  };

  const onClickNewView = () => {
    setDisabledEditting(false);
    setDisplayMode(VIEW_DETAIL_DISPLAY_MODE.AddNew);
    //UI updates
    setIsDeleteButtonHidden(true);

    //Set loaded view name to nothing
    setLoadedViewId(undefined);

    //clear view name
    setViewName(undefined);
    form.setFieldsValue({
      viewName: undefined,
    });

    //Reset filter criteria on screen
    setCaseStatusArray([]);
    setApplicationTypeArray([]);
    setGeneralEligibilityArray([]);
    setPathArray([]);
    setPathwayEligibilityStatusArray([]);
    setEnglishEligibilityStatusArray([]);
    setSchoolListArray([]);
    setRegulatoryAuthorityListArray([]);
    setColumnsToDisplayArray([]);

    //Clear dashboard IDs
    setSelectedViewId(undefined); //Id used to update view

    //clear list selection
    setSelectedRowKeys([]);
  };

  const populateViewDetails = async (viewId: any) => {
    const chosenView = initialViewList?.find((view: any) => {
      return view._id === viewId;
    });

    if (chosenView?.viewType === DASHBOARD_TYPE.Global) {
      setDisabledEditting(true);
    } else {
      setDisabledEditting(false);
    }

    //Delete button visible
    setIsDeleteButtonHidden(false);

    setLoadedViewId(viewId);

    setSelectedViewId(chosenView?._id);
    setViewName(chosenView?.viewName);
    form.setFieldsValue({
      viewName: chosenView?.viewName,
    });

    //Set filter criteria to screen
    setCaseStatusArray(chosenView?.viewFilters.caseState);
    setApplicationTypeArray(chosenView?.viewFilters.applicationType);
    setGeneralEligibilityArray(chosenView?.viewFilters.generalEligibilityStatus);
    setPathArray(chosenView?.viewFilters.pathway);
    setPathwayEligibilityStatusArray(chosenView?.viewFilters.pathwayEligibilityStatus);
    setEnglishEligibilityStatusArray(chosenView?.viewFilters.englishEligibilityStatus);
    setSchoolListArray(chosenView?.viewFilters.school);
    setRegulatoryAuthorityListArray(chosenView?.viewFilters.regulatoryAuthority);
    setColumnsToDisplayArray(chosenView?.displayColumns);
  };

  //select by clicking the radio button
  const onSelectedRowKeysChange = (selectedRowKeys: any) => {
    setDisplayMode(VIEW_DETAIL_DISPLAY_MODE.Edit);
    setSelectedRowKeys(selectedRowKeys);
    populateViewDetails(selectedRowKeys[0]);
  };

  //select by clicking a row
  const selectRow = async (record: any) => {
    setDisplayMode(VIEW_DETAIL_DISPLAY_MODE.Edit);
    setSelectedRowKeys([record.key]);
    populateViewDetails(record.key);
  };

  const onRowDoubleClick = async (record: any) => {
    chooseViewCallback(record.key);
  };

  const CASE_LIST_TABLE_FILTERS = [
    {
      title: 'USMLE ID',
      key: 'column-usmle-id',
    },
    {
      title: 'Days Since Last Update',
      key: 'column-days-since-last-update',
    },
    {
      title: 'Submission Date',
      key: 'submittedAt',
    },
    {
      title: 'Pathway',
      key: 'column-pathway',
    },
    {
      title: 'Case Status',
      key: 'column-case-status',
    },
    {
      title: 'General Eligibility',
      key: 'column-general-eligibility',
    },
    {
      title: 'OET Case Status',
      key: 'column-oet-case-status',
    },
    {
      title: 'Pathway Case Status',
      key: 'column-pathway-case-status',
    },

    {
      title: 'Assigned To',
      key: 'column-assigned-to',
    },
    {
      title: 'QA Rework',
      key: 'column-qa-rework',
    },
    {
      title: 'Document Last Uploaded Date',
      key: 'column-last-doc-upload-timestamp',
    },
  ];

  return (
    <Modal
      title={CUSTOMIZE_VIEWS_LABEL}
      visible={initialValues.showFilterModal}
      width="80vw"
      footer={null}
      onCancel={() => onCancel()}
    >
      <Row gutter={10}>
        <Col lg={8} md={8} sm={24}>
          <Table
            title={() => (
              <Row>
                <Col span={8}>
                  <Title level={3}>View List</Title>
                </Col>
                <Col style={{ textAlign: 'right' }} span={16}>
                  <Button onClick={() => onClickNewView()}>Add View</Button>
                </Col>
              </Row>
            )}
            scroll={{ x: '100%', y: 320 }}
            bordered
            size="middle"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  selectRow(record);
                }, // click row
                onDoubleClick: (event) => {
                  onRowDoubleClick(record);
                },
              };
            }}
            rowSelection={{
              type: viewListTableSelectionMode as 'radio' | 'checkbox' | undefined,

              selectedRowKeys: selectedRowKeys,
              onChange: onSelectedRowKeysChange,
            }}
            dataSource={viewList ?? []}
            columns={[{ dataIndex: 'viewName' }]}
            pagination={false}
            // pagination={{
            //   pageSize: 5,
            //   position: ["bottomCenter"],
            // }}
            style={{ height: '400px' }}
          ></Table>
        </Col>
        <Col xs={0} md={1}>
          <div
            style={{
              paddingLeft: '50%',
              marginRight: '50%',
              minHeight: '100%',
              borderRight: '1px solid black',
            }}
          ></div>
        </Col>
        <Col lg={15} md={15} sm={24}>
          <Form
            form={form}
            layout={'horizontal'}
            size="small"
            onFinish={() => onSaveClick()}
            //style={{ border: "solid 1px ", padding: "5px", height: "480px" }}
          >
            <Row>
              <Col span={24}>
                <Title level={3} style={{ marginTop: '7px' }}>
                  {displayMode}
                </Title>
                <hr style={{ borderTop: '1px black solid' }} />
                <Form.Item
                  name="viewName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter view name',
                    },
                  ]}
                  label="View Name"
                >
                  <Input
                    disabled={disabledEditting}
                    value={viewName}
                    onChange={(e) => setViewName(e.target.value)}
                    allowClear
                  />
                </Form.Item>

                <Form.Item label="Case Status">
                  <Select
                    disabled={disabledEditting}
                    defaultValue={caseFilterInput?.caseState as string[]}
                    value={caseStateArray}
                    onChange={(e) => setCaseStatusArray(e)}
                    mode="multiple"
                    allowClear
                  >
                    <Option value="ApplicationCreation">Application Created</Option>
                    <Option value="ApplicationSubmitted">Application Submitted</Option>
                    <Option value="EligibilityReview">Eligibility Review</Option>
                    <Option value="QAReady">QA Ready</Option>
                    <Option value="QAReview">QA Review</Option>
                    <Option value="ApplicationCompleted">Application Completed</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Application Type">
                  <Select
                    disabled={disabledEditting}
                    defaultValue={caseFilterInput?.applicationType as string[]}
                    value={applicationTypeArray}
                    onChange={(e) => setApplicationTypeArray(e)}
                    mode="multiple"
                    allowClear
                  >
                    <Option value="New">New</Option>
                    <Option value="Revalidation">Revalidation</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="General Eligibility">
                  <Select
                    disabled={disabledEditting}
                    allowClear
                    defaultValue={caseFilterInput?.generalEligibilityStatus as string[]}
                    value={generalEligibilityArray}
                    onChange={(e) => setGeneralEligibilityArray(e)}
                    mode="multiple"
                  >
                    {_.map(GENERAL_ELIGIBILITY_STATUS, (element) => (
                      <Option value={element.key} key={element.key}>
                        {element.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Columns to Display">
                  <Select
                    disabled={disabledEditting}
                    mode="multiple"
                    value={columnsToDisplayArray}
                    onChange={(e) => setColumnsToDisplayArray(e)}
                    allowClear
                  >
                    {GetFeatureFlagByName('QAReworkFlag') === 'true'
                      ? _.map(CASE_LIST_TABLE_FILTERS, (element) => (
                          <Option value={element.key} key={element.key}>
                            {element.title}
                          </Option>
                        ))
                      : _.map(CASE_LIST_TABLE_COLUMNS, (element) => (
                          <Option value={element.key} key={element.key}>
                            {element.title}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Pathway">
                  <Select
                    disabled={disabledEditting}
                    mode="multiple"
                    defaultValue={caseFilterInput?.currentPathway as string[]}
                    value={pathArray}
                    onChange={(e) => setPathArray(e)}
                    allowClear
                  >
                    {_.map(PATHWAY_NAMES, (element) => (
                      <Option value={element.key} key={element.key}>
                        {element.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Pathway Eligibility Status">
                  <Select
                    disabled={disabledEditting}
                    allowClear
                    mode="multiple"
                    defaultValue={caseFilterInput?.pathwayEligibilityStatus as string[]}
                    value={pathwayEligibilityStatusArray}
                    onChange={(e) => setPathwayEligibilityStatusArray(e)}
                  >
                    <OptGroup label="Pathway 1">
                      <Option value="1-not-started">(1) Not Started</Option>
                      <Option value="1-in-progress">(1) In Progress</Option>
                      <Option value="1-approved">(1) Approved</Option>
                      <Option value="1-rejected">(1) Rejected</Option>
                      <Option value="1-license-review-in-process">
                        (1) License review in process
                      </Option>
                      <Option value="1-awaiting-applicant-resolution-license">
                        (1) Awaiting applicant resolution –License
                      </Option>
                      <Option value="1-awaiting-applicant-resolution-translation">
                        (1) Awaiting applicant resolution –Translation
                      </Option>
                      <Option value="1-exception-review-escalation-required">
                        (1) Exception Review/Escalation Required
                      </Option>
                      <Option value="1-awaiting-licensure-document-from-issuing-authority">
                        (1) Awaiting Licensure Document from issuing authority
                      </Option>
                      <Option value="1-awaiting-licensure-document-from-applicant">
                        (1) Awaiting Licensure Document from applicant
                      </Option>
                      <Option value="1-sent-for-verification">(1) Sent for Verification</Option>
                      <Option value="1-resent-for-verification">(1) Resent for Verification</Option>
                      <Option value="1-verification-received-in-review">
                        (1) Verification received; In Review
                      </Option>
                      <Option value="1-verification-rejected-and-resent">
                        (1) Verification Rejected and Resent
                      </Option>
                      <Option value="1-verification-review-complete">
                        (1) Verification review complete
                      </Option>
                    </OptGroup>
                    <OptGroup label="Pathway 2">
                      <Option value="2-not-started">(2) Not Started</Option>
                      <Option value="2-approved">(2) Approved</Option>
                      <Option value="2-rejected">(2) Rejected</Option>
                      {/* <Option value="2-osce-review-in-process">(2) OSCE review in process</Option> */}
                      {/* <Option value="2-awaiting-applicant-resolution-osce">
                        (2) Awaiting applicant resolution –OSCE
                      </Option> */}
                      <Option value="2-exception-review-escalation-reqired">
                        (2) Exception Review/Escalation Required
                      </Option>
                      <Option value="2-awaiting-attestation-document">
                        (2) Awaiting Attestation Document
                      </Option>

                      <Option value="2-sent-for-verification">(2) Sent for Verification</Option>
                      <Option value="2-resent-for-verification">(2) Resent for Verification</Option>
                      <Option value="2-verification-received-in-review">
                        (2) Verification received; In Review
                      </Option>
                      <Option value="2-verification-rejected-and-resent">
                        (2) Verification Rejected and Resent
                      </Option>
                      <Option value="2-verification-review-complete">
                        (2) Verification review complete
                      </Option>
                      <Option value="2-attestation-received-in-review">
                        (2) Attestation received; In Review
                      </Option>
                      <Option value="2-attestation-rejected-and-resent">
                        (2) Attestation Rejected and Resent
                      </Option>
                      <Option value="2-attestation-review-complete">
                        (2) Attestation review complete
                      </Option>
                    </OptGroup>
                    <OptGroup label="Pathway 3">
                      <Option value="3-not-started">(3) Not Started</Option>
                      <Option value="3-approved">(3) Approved</Option>
                      <Option value="3-rejected">(3) Rejected</Option>
                      <Option value="3-license-eligibility-review-in-process">
                        (3) Eligibility review in process
                      </Option>
                      <Option value="3-license-awaiting-applicant-resolution">
                        (3) Awaiting applicant resolution
                      </Option>
                      <Option value="3-license-exception-review-escalation-reqired">
                        (3) Exception Review/Escalation Required
                      </Option>
                      <Option value="3-license-attestation-request-sent-to-school">
                        (3) Attestation Request Sent to School
                      </Option>
                      <Option value="3-license-awaiting-attestation-document">
                        (3) Awaiting Attestation Document
                      </Option>
                      <Option value="3-license-attestation-received-in-review">
                        (3) Attestation received; In Review
                      </Option>
                      <Option value="3-license-attestation-rejected-and-resent">
                        (3) Attestation Rejected and Resent
                      </Option>
                      <Option value="3-license-attestation-review-complete">
                        (3) Attestation review complete
                      </Option>
                    </OptGroup>
                    <OptGroup label="Pathway 4">
                      <Option value="4-not-started">(4) Not Started</Option>
                      <Option value="4-approved">(4) Approved</Option>
                      <Option value="4-rejected">(4) Rejected</Option>
                      <Option value="4-license-eligibility-review-in-process">
                        (4) Eligibility review in process
                      </Option>
                      <Option value="4-license-awaiting-applicant-resolution">
                        (4) Awaiting applicant resolution
                      </Option>
                      <Option value="4-license-exception-review-escalation-reqired">
                        (4) Exception Review/Escalation Required
                      </Option>
                      <Option value="4-license-awaiting-attestation-document">
                        (4) Awaiting Attestation Document
                      </Option>
                      <Option value="4-license-attestation-request-sent-to-school">
                        (4) Attestation Request Sent to School
                      </Option>
                      <Option value="4-license-attestation-received-in-review">
                        (4) Attestation received; In Review
                      </Option>
                      <Option value="4-license-attestation-rejected-and-resent">
                        (4) Attestation Rejected and Resent
                      </Option>
                      <Option value="4-license-attestation-review-complete">
                        (4) Attestation review complete
                      </Option>
                    </OptGroup>
                    <OptGroup label="Pathway 5">
                      <Option value="5-not-started">(5) Not Started</Option>
                      <Option value="5-approved">(5) Approved</Option>
                      <Option value="5-rejected">(5) Rejected</Option>
                      <Option value="5-license-eligibility-review-in-process">
                        (5) Eligibility review in process
                      </Option>
                      <Option value="5-license-awaiting-applicant-resolution">
                        (5) Awaiting applicant resolution
                      </Option>
                      <Option value="5-license-exception-review-escalation-reqired">
                        (5) Exception Review/Escalation Required
                      </Option>
                      <Option value="5-license-awaiting-attestation-document">
                        (5) Awaiting Attestation Document
                      </Option>
                      <Option value="5-license-attestation-request-sent-to-school">
                        (5) Attestation Request Sent to School
                      </Option>
                      <Option value="5-license-attestation-received-in-review">
                        (5) Attestation received; In Review
                      </Option>
                      <Option value="5-license-attestation-rejected-and-resent">
                        (5) Attestation Rejected and Resent
                      </Option>
                      <Option value="5-license-attestation-review-complete">
                        (5) Attestation review complete
                      </Option>
                    </OptGroup>
                    <OptGroup label="Pathway 6">
                      <Option value="6-approved">(6) Approved</Option>
                      <Option value="6-rejected">(6) Rejected</Option>
                      {PATHWAY6_SUBCASE_STATUSES.map((option) => (
                        <Option
                          value={`6-${option.key}`}
                          key={option.key}
                        >{`(6) ${option.value}`}</Option>
                      ))}
                    </OptGroup>
                    <OptGroup label="Pathway X">
                      <Option value="X-not-started">(X) Not Started</Option>
                      <Option value="X-in-progress">(X) In Progress</Option>
                      <Option value="X-approved">(X) Approved</Option>
                      <Option value="X-rejected">(X) Rejected</Option>
                      <Option value="X-exception-exception-request-in-process">
                        (X) Exception request in process
                      </Option>
                      <Option value="X-awaiting-applicant-resolution">
                        (X) Awaiting applicant resolution
                      </Option>
                      <Option value="X-exception-request-under-review-with-operations">
                        (X) Exception request under review with Operations Leadership
                      </Option>
                      <Option value="X-exception-request-under-review-with-enterprise">
                        (X) Exception request under review with Enterprise Leadership
                      </Option>
                      <Option value="X-exception-review-complete">
                        (X) Exception review complete
                      </Option>
                      <Option value="X-pathway-exception-request-in-process">
                        (X) Exception request in process
                      </Option>
                      <Option value="X-pathway-awaiting-applicant-resolution">
                        (X) Awaiting applicant resolution
                      </Option>
                      <Option value="X-pathway-awaiting-verification-licensure-document">
                        (X) Awaiting Verification of Licensure Document (Pathway 1)
                      </Option>
                      {/* <Option value="x-pathway-awaiting-verification-osce-document">
                        (X) Awaiting Verification of OSCE Document (Pathway 2)
                      </Option> */}
                      <Option value="X-pathway-awaiting-attestation-pathway-2">
                        (X) Awaiting Attestation (Pathway 2)
                      </Option>
                      <Option value="X-pathway-awaiting-attestation-pathway-345">
                        (X) Awaiting Attestation (Pathway 3, 4, 5)
                      </Option>
                      <Option value="X-pathway-document-received">
                        (X) Pathway document received; In Review
                      </Option>
                      <Option value="X-pathway-verification-rejected-and-resent">
                        (X) Pathway verification Rejected and Resent
                      </Option>
                      <Option value="X-pathway-verification-complete">
                        (X) Pathway verification review complete
                      </Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
                <Form.Item label="OET Eligibility Status">
                  <Select
                    disabled={disabledEditting}
                    allowClear
                    mode="multiple"
                    defaultValue={caseFilterInput?.englishEligibilityStatus as string[]}
                    value={englishEligibilityStatusArray}
                    onChange={(e) => setEnglishEligibilityStatusArray(e)}
                  >
                    <Option value="not-started">Not Started</Option>
                    <Option value="approved">Approved</Option>
                    <Option value="rejected">Rejected</Option>
                    <Option value="score-not-yet-received">Score not yet received</Option>
                    <Option value="score-received">Score Received from OET</Option>
                    <Option value="awaiting-applicant-feedback">Awaiting applicant feedback</Option>
                    <Option value="score-review-in-process">Score Review in process</Option>
                    <Option value="exception-review-escalation-reqired">
                      Exception Review/EscalationRequired
                    </Option>
                    <Option value="score-review-complete">Score Review complete</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="School">
                  <Select
                    disabled={disabledEditting}
                    allowClear
                    mode="multiple"
                    defaultValue={caseFilterInput?.school as string[]}
                    filterOption={(input, option: any) =>
                      (option?.children.toLowerCase() ?? '').includes(input.toLowerCase())
                    }
                    value={schoolListArray}
                    onChange={(e) => setSchoolListArray(e)}
                  >
                    {schoolList.map((option: any) => (
                      <Option value={option._id}>{option.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Regulatory Authority">
                  <Select
                    disabled={disabledEditting}
                    allowClear
                    mode="multiple"
                    defaultValue={caseFilterInput?.regulatoryAuthority as string[]}
                    filterOption={(input, option: any) =>
                      (option?.children.toLowerCase() ?? '').includes(input.toLowerCase())
                    }
                    value={regulatoryAuthorityListArray}
                    onChange={(e) => setRegulatoryAuthorityListArray(e)}
                  >
                    {regulatoryAuthorityList.map((option: any) => (
                      <Option key={option._id} value={option._id}>
                        {option.orgName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div style={{ width: '100%', textAlign: 'left' }}>
                  <Space direction={'horizontal'}>
                    {/* s<Button onClick={() => onCancel()}>Cancel</Button> */}
                    <Button
                      htmlType="submit"
                      loading={isProcessingSave}
                      disabled={isProcessingSave || isProcessingDelete || disabledEditting}
                    >
                      Save View
                    </Button>
                    <Popconfirm
                      title="Are you sure？"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => onClickDelete()}
                      disabled={isProcessingSave || isProcessingDelete || disabledEditting}
                    >
                      <Button
                        hidden={isDeleteButtonHidden}
                        loading={isProcessingDelete}
                        disabled={isProcessingSave || isProcessingDelete || disabledEditting}
                      >
                        Delete View
                      </Button>
                    </Popconfirm>
                  </Space>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default {
  Component: CaseListFilterModal,
};
