import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ArchivedCaseReviewsType, PathwayXArchivedCaseReviewContainerArchivedCaseDocument, UiPortalConfigType, } from '../../../../generated';
import { useQuery } from '@apollo/client';
import { PathwayXArchivedCaseReview } from './pathway-x-archived-case-review';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';

const PathwayXArchivedCaseReviewContainer: FC<any> = () => {
  const { caseId } = useParams();

    const {
      loading: caseLoading,
      error: caseError,
      data: caseData,
    } = useQuery(PathwayXArchivedCaseReviewContainerArchivedCaseDocument, {
      variables: {
        caseId: caseId ?? ''
      },
    });

  return (
    <ComponentQueryLoader
      loading={caseLoading}
      hasData={caseData?.archivedCase !== undefined}
      hasDataComponent={
        <PathwayXArchivedCaseReview data={caseData?.archivedCase?.reviews as ArchivedCaseReviewsType} uiConfig= {caseData?.archivedCase?.uiConfig as UiPortalConfigType}/>
      }
      error={caseError}
    />
  );
};

export default PathwayXArchivedCaseReviewContainer;
