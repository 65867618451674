import { Layout } from 'antd';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import '../../App.css';
import FooterLayout from '../footer-layout';
import ImpendingMaintenance from '../maintenance/impendingMaintenance';
import Maintenance from '../maintenance/maintenance';
import { MedicalSchoolOfficials } from './medical-school-officials';
import { MedicalSchoolOfficialsHeader } from './medical-school-officials-header';
import MedicalSchoolOfficialsLeftNav from './medical-school-officials-left-nav';

const { Content } = Layout;

const LayoutMedicalSchoolOfficials: FC<any> = (props) => {
  return (
    <>
      <Helmet>
        <title>Medical School Officials</title>
      </Helmet>
      <Maintenance>
        <Layout className="layout">
          <MedicalSchoolOfficialsHeader />
          <Layout hasSider>
            <MedicalSchoolOfficialsLeftNav />
            <Layout
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 auto',
                overflowY: 'scroll',
                height: 'calc(100vh - 64px)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 auto',
                }}
              >
                <Content style={{ margin: '0 16px 0', minHeight: 'inherit' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100%',
                      borderRadius: '0 0 0.8rem 0.8rem',
                      background: '#FFFFFF',
                      padding: '20px',
                    }}
                  >
                    <ImpendingMaintenance ShowImpendingMessageImmediately={false}>
                      <Routes>
                        <Route path={'/'} element={<MedicalSchoolOfficials />} />
                      </Routes>
                    </ImpendingMaintenance>
                  </div>
                </Content>

                <FooterLayout />
              </div>
            </Layout>
          </Layout>
        </Layout>
      </Maintenance>
    </>
  );
};

export default LayoutMedicalSchoolOfficials;
