import { Layout } from 'antd';
import { func } from 'prop-types';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import '../../App.css';
import FooterLayout from '../footer-layout';
import ImpendingMaintenance from '../maintenance/impendingMaintenance';
import Maintenance from '../maintenance/maintenance';
import archivedCaseHeader from './archived-case-header';
import ArchivedCaseLeftNav from './archived-case-left-nav';
import ArchivedCaseListContainer from './archived-case-list/archived-case-list-container';
import PathwayXArchivedCase from './pathway-x/pathway-x-archived-case';
import Pathway1ArchivedCase from './pathway1/pathway1-archived-case';
import Pathway2ArchivedCase from './pathway2/pathway2-archived-case';
import Pathway345ArchivedCase from './pathway345/pathway345-archived-case';
import Pathway6ArchivedCase from './pathway6/pathway6-archived-case';

const { Content } = Layout;

const LayoutArchivedCaseManagement: FC<any> = (props) => {
  return (
    <>
      <Helmet>
        <title>Archived Cases</title>
      </Helmet>
      <Maintenance>
        <Layout className="layout">
          <archivedCaseHeader.Component onRequestCaseLoad={func} onRequestCaseAdd={func} />
          <Layout hasSider>
            <ArchivedCaseLeftNav />
            <Layout
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 auto',
                overflowY: 'scroll',
                height: 'calc(100vh - 64px)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 auto',
                }}
              >
                <Content style={{ margin: '0 16px 0', minHeight: 'inherit' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100%',
                      borderRadius: '0 0 0.8rem 0.8rem',
                      background: '#FFFFFF',
                      padding: '20px',
                    }}
                  >
                    <ImpendingMaintenance ShowImpendingMessageImmediately={false}>
                      <Routes>
                        <Route path={'/'} element={null} />
                        <Route path={`/:usmleId`} element={<ArchivedCaseListContainer />} />
                        <Route
                          path={'/:usmleId/pathway1/:caseId'}
                          element={<Pathway1ArchivedCase />}
                        />
                        <Route
                          path={'/:usmleId/pathway2/:caseId'}
                          element={<Pathway2ArchivedCase />}
                        />
                        <Route
                          path={'/:usmleId/pathway3/:caseId'}
                          element={<Pathway345ArchivedCase />}
                        />
                        <Route
                          path={'/:usmleId/pathway4/:caseId'}
                          element={<Pathway345ArchivedCase />}
                        />
                        <Route
                          path={'/:usmleId/pathway5/:caseId'}
                          element={<Pathway345ArchivedCase />}
                        />
                        <Route
                          path={'/:usmleId/pathway6/:caseId'}
                          element={<Pathway6ArchivedCase />}
                        />
                        <Route
                          path={'/:usmleId/pathwayx/:caseId'}
                          element={<PathwayXArchivedCase />}
                        />
                      </Routes>
                    </ImpendingMaintenance>
                  </div>
                </Content>

                <FooterLayout />
              </div>
            </Layout>
          </Layout>
        </Layout>
      </Maintenance>
    </>
  );
};

export default LayoutArchivedCaseManagement;
