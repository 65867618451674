import React, { FC, useState, useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, message, Modal, Input, Form, Checkbox, Alert } from 'antd';
import { useMutation } from '@apollo/client/react';
import {
  CaseMgmtPathway6CancelMinicexCasePortalMiniCexCancelDocument,
  CaseMgmtPathway6DocumentReviewLayoutPhysicianByMiniCexDocument,
  CaseMgmtPathway6DocumentReviewLayoutCasePortalDocument,
  MiniCexSummaryType,
} from '../../../../../generated';
import FormItem from 'antd/lib/form/FormItem';
import { PATHWAY6_RESPONSE_TYPE } from '../../../../constants';
import { ERROR_MESSAGES_MAP, ERROR_MESSAGES } from '../../../../../utils/error-messages';
import { DeleteOutlined } from '@ant-design/icons';

const ComponentPropTypes = {};

export interface IProps {
  minicexSummary: MiniCexSummaryType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const Pathway6CancelMinicex: FC<ComponentProps> = ({ minicexSummary }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showCancelEncounterModal, setshowCancelEncounterModal] = useState<boolean>(false);
  const [confirmationValue, setConfirmationValue] = useState<string>('');
  const [currentRecord, setCurrentRecord] = useState<MiniCexSummaryType>(minicexSummary);

  const [isMiniCEXAssessmentResetConfirmed, setIsMiniCEXAssessmentResetConfirmed] =
    useState<boolean>(false);
  const [showAssessmentAlreadyCompletedMessage, setShowAssessmentAlreadyCompletedMessage] =
    useState<boolean>(false);
  const [cancelMiniCEX, { loading: cancelMiniCEXLoading }] = useMutation(
    CaseMgmtPathway6CancelMinicexCasePortalMiniCexCancelDocument,
    {
      refetchQueries: [
        {
          query: CaseMgmtPathway6DocumentReviewLayoutPhysicianByMiniCexDocument,
          variables: {
            filter: {
              usmleId: minicexSummary?.usmleId,
              responseType: PATHWAY6_RESPONSE_TYPE.ACTIVE,
            },
          },
        },
        {
          query: CaseMgmtPathway6DocumentReviewLayoutCasePortalDocument,
          variables: {
            usmleId: minicexSummary?.usmleId,
            filter: {
              usmleId: minicexSummary.usmleId,
              responseType: PATHWAY6_RESPONSE_TYPE.ACTIVE,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const cancelMinicexEncounter = async () => {
    if (currentRecord.dataVersion === undefined) {
      return;
    }

    await cancelMiniCEX({
      variables: {
        encounterId: currentRecord?.encounterId!,
        minicexDataVersion: currentRecord?.dataVersion!,
        isMiniCEXAssessmentResetConfirmed: isMiniCEXAssessmentResetConfirmed,
      },
    })
      .then((data: any) => {
        console.log('Application Archived');
        message.success(`Encounter ${currentRecord?.encounterId} cancelled successfully!`);
        setshowCancelEncounterModal(false);
        setShowAssessmentAlreadyCompletedMessage(false);
        navigate(0);
      })
      .catch((error) => {
        //only grab the first error
        //const graphQLErrorReference = ERROR_MESSAGES_MAP.includes(error?.message);
        if (
          error?.graphQLErrors?.[0]?.message === ERROR_MESSAGES.MINICEX_ASSESSMENT_ALREADY_COMPLETED
        ) {
          setShowAssessmentAlreadyCompletedMessage(true);
        } else {
          const graphQLErrorReference = ERROR_MESSAGES_MAP.find(
            (err: any) => err.key === error?.graphQLErrors?.[0]?.message
          );
          message.error(
            `${
              graphQLErrorReference?.value
                ? graphQLErrorReference?.value
                : error?.graphQLErrors?.[0]?.message
            } - Reference ID : ${error.graphQLErrors[0].extensions.referenceId}`
          );
        }
      });
  };

  const onModalCancel = () => {
    form.resetFields();
    setConfirmationValue('');
    setshowCancelEncounterModal(false);
    setIsMiniCEXAssessmentResetConfirmed(false);
    setShowAssessmentAlreadyCompletedMessage(false);
  };

  const onModalOk = () => {
    if (confirmationValue !== currentRecord?.encounterId) {
      message.error('Encounter ID does not match');
      return;
    }

    cancelMinicexEncounter();
  };

  return (
    <div>
      <Button
        type={'primary'}
        danger
        style={{ fontSize: '14px', width:'100px', marginLeft: '5px' }}
        onClick={() => setshowCancelEncounterModal(true)}
        icon={<DeleteOutlined />}
      >
        Cancel
      </Button>

      <Modal
        title="Cancel Minicex"
        key={minicexSummary?.encounterId!}
        visible={showCancelEncounterModal}
        confirmLoading={cancelMiniCEXLoading}
        closable={false}
        destroyOnClose={true}
        afterClose={() => onModalCancel()}
        footer={[
          <Button key="cancel" onClick={() => onModalCancel()}>
            Cancel
          </Button>,

          <Button form="submitForm" key="submit" htmlType="submit" type="primary">
            Confirm
          </Button>,
        ]}
      >
        <Form id="submitForm" form={form} layout={'horizontal'} onFinish={() => onModalOk()}>
          <p>Encounter ID: {currentRecord?.encounterId}</p>
          <p>Please enter the Encounter ID to remove this encounter</p>

          <Form.Item
            initialValue={confirmationValue}
            label="Please enter Encounter ID"
            name="RequestID"
            rules={[{ required: true, message: 'Please enter Encounter ID' }]}
          >
            <Input
              value={confirmationValue}
              onChange={(e) => setConfirmationValue(e.target.value)}
            />
          </Form.Item>

          {showAssessmentAlreadyCompletedMessage ? (
            <>
              <Alert
                type="warning"
                message="All Mini-CEX are complete for this applicant and the scoring algorithm has run. Are
                you sure you wish to remove this encounter?"
              />
              <Form.Item
                name="minicexResetConfirmation"
                rules={[
                  {
                    required: showAssessmentAlreadyCompletedMessage,
                    message: 'Please check to confirm',
                    validator: (rule, value, callback) => {
                      if (!isMiniCEXAssessmentResetConfirmed) {
                        callback('Please check to confirm');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                <Checkbox
                  onChange={() =>
                    setIsMiniCEXAssessmentResetConfirmed(!isMiniCEXAssessmentResetConfirmed)
                  }
                  checked={isMiniCEXAssessmentResetConfirmed}
                >
                  I agree
                </Checkbox>
              </Form.Item>
            </>
          ) : null}
        </Form>
      </Modal>
    </div>
  );
};

export default {
  Component: Pathway6CancelMinicex,
};
