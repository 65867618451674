import { FC } from 'react';
import { ArchivedCaseOetReviewType } from '../../../../generated';
import { Card, Descriptions } from 'antd';
import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';
import { ArchivedCaseDocument } from '../archived-case-document';

interface OetArchivedCaseInfoCardProps {
  data: ArchivedCaseOetReviewType;
}
export const ArchivedCaseOetInfoCard: FC<OetArchivedCaseInfoCardProps> = (props) => {
  
  const documentContent = props.data.data?.documents?.map((document: any) => (
    <div key={document.docId} style={{marginBottom: 10}}>
      <ArchivedCaseDocument title={document.title} docId={document.docId} />
    </div>
  ));

  return (
    <>
      <Card
        style={{
          width: '45%',
          height: '50%',
          borderRadius: '10px',
          border: '1px solid var(--Neutral-4, #F0F0F0)',
          background: 'var(--Neutral-1, #FFF)',
        }}
        title={
          <Title level={5} style={{ fontWeight: 'bold' }}>
            {props.data?.summary?.title}
          </Title>
        }
      >
        <Descriptions
          className={'ecfmg-small-descriptions'}
          layout="vertical"
          bordered={false}
          size="small"
          column={3}
          style={{ paddingBottom: '20px' }}
        >
          <Descriptions.Item label="Candidate ID">
            {props?.data?.data?.candidateId}
          </Descriptions.Item>
          <Descriptions.Item label="Exam Date" style={{ float: 'right' }}>
            {props?.data?.data?.examDate ? dayjs(props?.data?.data?.examDate).format('MM/DD/YYYY') : ''}
          </Descriptions.Item>
        </Descriptions>
        {documentContent}
      </Card>
    </>
  );
};

export default ArchivedCaseOetInfoCard;
