import { FC } from 'react';
import { AdditionalDocumentCard } from './archived-case-card';
import { Col } from 'antd';
import { AdditionalDocument } from '../../../../generated';

interface ArchivedCaseAdditionalDocumentsProps {
  documents: AdditionalDocument[];
}

const ArchivedCaseAdditionalDocuments: FC<ArchivedCaseAdditionalDocumentsProps> = ({ documents,}) => {

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {documents.map((doc) => (
           <Col key={doc.document?.docId} lg={4} md={6} xs={24} style={{ padding: '8px' }}>
          <AdditionalDocumentCard
            displayValues={doc}
            disabled={false}
          />
          </Col>
        ))}
      </div>
    </div>
  );
};

export default ArchivedCaseAdditionalDocuments;
