import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Row, Col, Divider, message, Spin, Typography } from 'antd';
import ActivityFeed from '../../../activity-feed';
import Pathway3DocumentReviewApplicantDetails from './pathway3-document-review-applicant-details';
import { useQuery, useMutation } from '@apollo/client';
import { AdditionalDocumentCardList } from '../../additional-document/additional-document-card-list';
import { UploadDocument } from '../../upload-document/upload-document';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import _ from 'lodash';
import { case_ } from '../../../../schema/casePortal-case';
import {
  ActivityLogType,
  CaseMgmtPathway3DocumentReviewLayoutAddAndDeleteDocumentDocument,
  CaseSummaryType,
  CastMgmtPathway3DocumentReviewLayoutCasePortalDocument,
  C_ApplicantType,
} from '../../../../../generated';
import ApplicantSummaryFactory from '../../applicant-summary/applicant-summary-factory';
import Pathway3EMSWPAttestationLayout from './pathway3-emswp-attestation-layout';
import Pathway3DocumentReviewChecklistFactory from './pathway3-document-review-checklist-factory';
const { Title } = Typography;

export interface ComponentProp {
  casePortal: {
    case: case_;
  };
}

var isUploadSuccessful = false;

const Pathway3DocumentReviewLayout: FC<any> = (props) => {
  const navigate = useNavigate();
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  let { usmleId, documentId } = useParams<any>();

  const { loading, error, data, refetch } = useQuery(
    CastMgmtPathway3DocumentReviewLayoutCasePortalDocument,
    {
      variables: { usmleId: usmleId },
    }
  );

  const [additionalDocuments, setAdditionalDocuments] = useState<any>([]);
  const [addAndDeleteAdditionalDocumentsMutation] = useMutation(
    CaseMgmtPathway3DocumentReviewLayoutAddAndDeleteDocumentDocument,
    {
      refetchQueries: [
        {
          query: CastMgmtPathway3DocumentReviewLayoutCasePortalDocument,
          variables: { usmleId: usmleId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  useEffect(() => {
    if (data) {
      const applicantProvidedData = data?.casePortal?.case?.pathway345?.applicantProvidedData;
      const staffProvidedData = data?.casePortal?.case?.pathway345?.staffProvidedData;
      const joinData = applicantProvidedData?.concat(staffProvidedData ?? {});
      setSelectedDocument(joinData?.find((record: any) => record._id === documentId));
    }
    if (data?.casePortal?.case?.caseSummary?.dataVersion) {
      setDataVersion(data.casePortal.case.caseSummary.dataVersion);
    }

    const retrievedAdditionalDocuments: any = [];
    _.forEach(data?.casePortal?.case?.additionalDocuments, (element) => {
      retrievedAdditionalDocuments.push(element?.document);
    });
    setAdditionalDocuments(retrievedAdditionalDocuments);
  }, [data]);

  const onUpdateDataVersionFromChild = async (dataVersion: number) => {
    setDataVersion(dataVersion);
  };

  const disableDocumentUpload: boolean =
    selectedDocument?.review?.status?.toUpperCase() === 'APPROVED' ||
    selectedDocument?.review?.status?.toUpperCase() === 'REJECTED' ||
    data?.casePortal?.case?.caseSummary?.pathwayEligibilityStatus?.toUpperCase() === 'COMPLETED';

  //Get the blob name for azure storage by saving in mongo
  async function getBlobname(filename: string, docType: string) {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));
    var uniqueDocIds: any = [];
    deepCopyDocuments.map((doc: any) => {
      uniqueDocIds.push(doc.docId);
      delete doc.__typename;
    });
    const documentToUpload = {
      docType: docType,
      title: filename,
    };
    deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
      return {
        document: element,
      };
    });
    deepCopyDocuments.push({ document: documentToUpload });
    return addAndDeleteAdditionalDocumentsMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        input: deepCopyDocuments,
      },
    })
      .then((data) => {
        setDataVersion(data?.data?.CasePortal_additionalDocuments?.caseSummary?.dataVersion);
        const addedDocuments = data?.data?.CasePortal_additionalDocuments?.additionalDocuments; //debug this

        var docs: any = [];
        _.forEach(addedDocuments, (data) => {
          docs.push(data?.document);
        });
        var thisDocGuid = '';
        docs.map((doc: any) => {
          //The most recent document won't exist in the uniqueDocIds, so that is the one for this document
          if (!uniqueDocIds.includes(doc.docId)) {
            thisDocGuid = doc.docId;
          }
        });
        setAdditionalDocuments(docs);
        isUploadSuccessful = true;
        refetch();
        return thisDocGuid;
      })
      .catch((error) => {
        isUploadSuccessful = false;
        refetch();
        console.log(error);
        return 'error';
      });
  }

  const addNewDocToAzure = (document: any, sasToken: string) => {
    if (!isUploadSuccessful) {
      alert('There are errors while uploading!');
      return;
    }
    //Call upload function to blob
    axios
      .put(sasToken, new Blob([document], { type: 'application/pdf' }), {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
      })
      .then(
        (res) => {
          console.log(res);
          if (res.status === 201) {
            //setDocuments(documents.concat(newDoc));
            alert('Upload successfully!');
          } else {
            alert('There are problems with the uploading.');
          }
        },
        (err) => {
          console.log(err);
          alert('Upload failed!');
        }
      );
  };

  const onDeleteDocument = async (docId: any) => {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));

    deepCopyDocuments = _.remove(deepCopyDocuments, (document: any) => {
      return document.docId !== docId;
    });

    deepCopyDocuments.map((doc: any) => {
      delete doc.__typename;
    });

    deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
      return {
        document: element,
      };
    });
    return addAndDeleteAdditionalDocumentsMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        input: deepCopyDocuments,
      },
    })
      .then((res: any) => {
        message.success('Delete successfully!');
        refetch();
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        refetch();
      });
  };

  const [form] = Form.useForm();

  if (loading) {
    return <Spin></Spin>;
  }

  return (
    <>
      <Helmet>
        <title>Pathway 3-5 Review</title>
      </Helmet>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={24} lg={24} xl={12}>
          {!!data?.casePortal?.case?.applicant && !!data?.casePortal?.case?.caseSummary ? (
            <Pathway3DocumentReviewApplicantDetails.Component
              applicant={data.casePortal.case.applicant}
              caseSummary={data.casePortal.case.caseSummary}
            />
          ) : null}

          <Pathway3EMSWPAttestationLayout casePortal={data?.casePortal} />

          <Divider plain orientation="center">
            <b>Additional Documents</b>
          </Divider>
          <Row>
            <Col span={23}>
              {additionalDocuments.length === 0 ? (
                <div>There is no additional document added.</div>
              ) : (
                <AdditionalDocumentCardList
                  numberOfCardsPerRow={3}
                  documents={additionalDocuments}
                  onDelete={(docId) => onDeleteDocument(docId)}
                  disabled={disableDocumentUpload}
                />
              )}
              <UploadDocument
                getGuid={(filename) => getBlobname(filename, 'Additional Document')}
                showIcon={false}
                label="Add Document"
                //disabled because we want to allow document uploads at all times for supporting docs. Ex: refund supporting docs
                //disabled={disableDocumentUpload}
              />
            </Col>
          </Row>
          <Divider plain orientation="center">
            <b>Applicant Provided Information (read only)</b>
          </Divider>
          <ApplicantSummaryFactory
            usmleID={usmleId}
            versionConfig={data?.casePortal?.case?.uiConfig}
          ></ApplicantSummaryFactory>
        </Col>
        <Col sm={24} lg={24} xl={11}>
          <div>
            {!!data?.casePortal?.case?.caseSummary &&
            !!data?.casePortal?.case?.applicant &&
            !!data?.casePortal?.case?.uiConfig ? (
              <Pathway3DocumentReviewChecklistFactory
                caseSummary={data.casePortal.case.caseSummary as CaseSummaryType}
                checklist={selectedDocument?.review?.checklist}
                checklistState={selectedDocument?.review?.checklistState}
                recordId={documentId as string}
                parentDataVersion={dataVersion}
                onUpdateDataVersion={(dataVersion: number) => {
                  onUpdateDataVersionFromChild(dataVersion);
                }}
                applicant={data.casePortal.case.applicant as C_ApplicantType}
                redirect={(url: string) => {
                  navigate(url);
                }}
                checklistStatus={selectedDocument?.review?.status}
                versionConfig={data?.casePortal?.case?.uiConfig}
              />
            ) : null}
            <br />
            <Title level={3}>Activity Feed</Title>
            <hr style={{ borderTop: '1px black solid' }} />
            {!!data?.casePortal?.case?.activityLog && !!data?.casePortal?.case?.caseSummary ? (
              <ActivityFeed.Component
                activityLog={data.casePortal.case.activityLog as ActivityLogType[]}
                caseSummary={data.casePortal.case.caseSummary}
                parentDataVersion={dataVersion}
                onUpdateDataVersion={(dataVersion: number) => {
                  onUpdateDataVersionFromChild(dataVersion);
                }}
                refetch={() => {
                  refetch();
                }}
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Pathway3DocumentReviewLayout;
