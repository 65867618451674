import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ArchivedCaseReviewsType, Pathway6ArchivedCaseReviewContainerArchivedCaseDocument, UiPortalConfigType } from '../../../../generated';
import { useQuery } from '@apollo/client';
import { Pathway6ArchivedCaseReview } from './pathway6-archived-case-review';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';

const Pathway6ArchivedCaseReviewContainer: FC<any> = () => {
  const { caseId } = useParams();

    const {
      loading: caseLoading,
      error: caseError,
      data: caseData,
    } = useQuery(Pathway6ArchivedCaseReviewContainerArchivedCaseDocument, {
      variables: {
        caseId: caseId ?? ''
      },
    });

  return (
    <ComponentQueryLoader
      loading={caseLoading}
      hasData={caseData?.archivedCase !== undefined}
      hasDataComponent={
        <Pathway6ArchivedCaseReview data={caseData?.archivedCase?.reviews as ArchivedCaseReviewsType} uiConfig={caseData?.archivedCase?.uiConfig as UiPortalConfigType}/>
      }
      error={caseError}
    />
  );
};

export default Pathway6ArchivedCaseReviewContainer;
