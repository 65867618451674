import { FC } from 'react';
import { Col, Row, Typography, Layout, Grid } from 'antd';
const { Link } = Typography;
const { Footer } = Layout;
const { useBreakpoint } = Grid;

const FooterLayout: FC<any> = (props) => {
  const screens = useBreakpoint();

  const SmallScreenFooter = () => {
    return (
      <Footer>
        <Row>
          <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
            <Link
              href="https://www.ecfmg.org/annc/terms.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </Link>
            &nbsp; |&nbsp;
            <Link
              href="https://www.ecfmg.org/annc/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </Link>
          </div>
          <br />
          <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
            © 2021-2024 Educational Commission for Foreign Medical Graduates (ECFMG®). All rights
            reserved.
          </div>
        </Row>
      </Footer>
    );
  };

  const LargeScreenFooter = () => {
    return (
      <Footer>
        <Row
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '10px',
            marginTop: '7px',
          }}
        >
          <Col span={16} style={{ alignSelf: 'center' }}>
            <Link
              href="https://www.ecfmg.org/annc/terms.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </Link>
            &nbsp; |&nbsp;
            <Link
              href="https://www.ecfmg.org/annc/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </Link>
            <br /> © 2021-2024 Educational Commission for Foreign Medical Graduates (ECFMG®). All
            rights reserved.
          </Col>
        </Row>
      </Footer>
    );
  };

  const MediumScreenFooter = () => {
    return (
      <Footer>
        <Row
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '10px',
            marginTop: '7px',
          }}
        >
          <Col span={24} style={{ alignSelf: 'center' }}>
            <Link
              href="https://www.ecfmg.org/annc/terms.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </Link>
            &nbsp; |&nbsp;
            <Link
              href="https://www.ecfmg.org/annc/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </Link>
            <br />© 2021-2024 Educational Commission for Foreign Medical Graduates (ECFMG®). All
            rights reserved.
          </Col>
        </Row>
      </Footer>
    );
  };

  return (
    <>
      {screens.lg ? (
        <LargeScreenFooter />
      ) : screens.md ? (
        <MediumScreenFooter />
      ) : (
        <SmallScreenFooter />
      )}
    </>
  );
};

export default FooterLayout;
