import { pdf } from '@react-pdf/renderer';
import { Button, Modal, Typography } from 'antd';
import { FC, useState } from 'react';
import { EMSWPAttestationPDFFactory } from '../../case-management/pathways/pathway3/pathway3-document-review/emswp-attestation-pdf-factory';
import {
  ArchivedCaseType,
  CaseSummaryType,
  Pathway345,
  UiPortalConfigType,
} from '../../../generated';
import SummaryPDFViewer from '../../SummaryPDFViewer';

interface ArchivedCaseAttestationSummaryButtonProps {
  data: ArchivedCaseType;
}
export const ArchivedCaseAttestationSummaryButton: FC<ArchivedCaseAttestationSummaryButtonProps> = (
  props
) => {
  const [isAttestationSummaryModalVisible, setIsAttestationSummaryModalVisible] = useState(false);
  const GetEmswpAttestationPDF = () => {
    var blobData = pdf(
      <EMSWPAttestationPDFFactory
        versionConfig={props?.data?.uiConfig as UiPortalConfigType}
        caseSummary={
          {
            usmleId: props.data?.applicant?.profile?.usmleId,
            firstName: props.data?.applicant?.profile?.firstName,
            lastName: props.data?.applicant?.profile?.lastName,
            dateOfBirth: props.data?.applicant?.profile?.dateOfBirth,
          } as CaseSummaryType
        }
        pathway345={
          {
            applicantProvidedData: [
              {
                data: {
                  schoolRef: {
                    name: props.data?.reviews?.pathway345?.[0]?.data?.schoolRef?.name,
                    countryName: props.data?.reviews?.pathway345?.[0]?.data?.schoolRef?.countryName,
                  },
                },
                attestation: {
                  output: props.data?.assessments?.emswp?.output,
                  outputReason: props.data?.assessments?.emswp?.outputReason,
                  outputReasonText: props.data?.assessments?.emswp?.outputReasonText,
                  updatedTimestamp: props.data?.assessments?.emswp?.updatedTimestamp,
                  updatedBy: {
                    name: props.data?.assessments?.emswp?.updatedBy?.name,
                  },
                },
              },
            ],
          } as Pathway345
        }
      />
    ).toBlob();

    return (
      <SummaryPDFViewer
        filename={`Attestation Summary - ${props?.data?.applicant?.profile?.usmleId}`}
        docId=""
        blobPromise={blobData}
      ></SummaryPDFViewer>
    );
  };

  return (
    <>
      <Button type="link" onClick={() => setIsAttestationSummaryModalVisible(true)}>
        <Typography.Text underline>Attestation Summary</Typography.Text>
      </Button>

      <Modal
        title={'Attestation Summary'}
        visible={isAttestationSummaryModalVisible}
        width="fit-content"
        closable
        bodyStyle={{ minWidth: '20vw' }}
        okText="Continue"
        footer={null}
        onCancel={() => setIsAttestationSummaryModalVisible(false)}
      >
        <GetEmswpAttestationPDF />
      </Modal>
    </>
  );
};
