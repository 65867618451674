import React, { FC, useState, useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { Descriptions, Row, Col, Table } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants';
import { toInteger } from 'lodash';
import { useFeatureFlags } from '../../feature-flag-react-lite';
import { CaseResponseType } from '../../../generated';

const CaseOverviewSummaryPropsTypes = {
  refetch: PropTypes.func,
};

export interface ComponentProps {
  initialValues: CaseResponseType;
}

export type CaseOverviewSummaryProps = InferProps<typeof CaseOverviewSummaryPropsTypes> &
  ComponentProps;

type paramType = {
  caseId: string;
};
const CaseOverview: FC<CaseOverviewSummaryProps> = ({ initialValues, refetch }) => {
  let { caseId } = useParams<paramType>();
  console.log(`param:${caseId}`);
  const { GetFeatureFlagByName } = useFeatureFlags();

  const [dateSubmitted, setDateSubmitted] = useState<Date | undefined>(
    initialValues?.caseSummary?.submittedAt
      ? moment(initialValues.caseSummary.submittedAt).toDate()
      : undefined
    //Update this when we actually get a date
  );
  const [lastActivity, setLastActivity] = useState<Date | undefined>(
    moment(initialValues?.caseSummary?.updatedAt).toDate() ?? undefined
    //Calculate this when we actually get a date
  );

  const [qaReworkTimestamp, setQAReworkTimestamp] = useState<any>(
    initialValues?.caseSummary?.qaReworkTimestamp
      ? moment(initialValues?.caseSummary?.qaReworkTimestamp).toDate()
      : undefined
  );

  const rearrangedSummary = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="USMLE ID">
                {initialValues?.caseSummary?.usmleId}
              </Descriptions.Item>
              <Descriptions.Item label="Name">{`${initialValues?.caseSummary?.lastName}, ${initialValues?.caseSummary?.firstName}`}</Descriptions.Item>
            </Descriptions>
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="Case Status">
                {initialValues?.caseSummary?.caseState}
              </Descriptions.Item>

              {initialValues?.caseSummary?.caseState === 'ApplicationCompleted' ? (
                <Descriptions.Item label="Case Decision">
                  {initialValues?.caseSummary?.output}
                </Descriptions.Item>
              ) : null}

              {qaReworkTimestamp ? (
                <Descriptions.Item label="QA Rework">
                  {moment(toInteger(qaReworkTimestamp)).format(DATE_FORMAT)}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="Submission Date">
                {dateSubmitted ? moment(dateSubmitted).format(DATE_FORMAT) : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Last Activity">
                {lastActivity
                  ? moment({ hours: 0 }).diff(lastActivity, 'days') + ' days ago'
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Last Oracle Refresh Time">
                {initialValues?.caseSummary?.updatedAt
                  ? moment(initialValues?.caseSummary.updatedAt).format(DATE_FORMAT)
                  : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="Eligibility Check">
                {initialValues?.caseSummary?.isEligible ? 'Pass' : 'Fail'}
              </Descriptions.Item>

              {initialValues?.caseSummary?.output &&
              initialValues?.caseSummary?.caseState === 'ApplicationComplete' ? (
                <Descriptions.Item label="Case Decision">
                  {initialValues?.caseSummary?.output}
                </Descriptions.Item>
              ) : null}

              <Descriptions.Item label="General Eligibility">
                {initialValues?.caseSummary?.generalEligibilityStatus}
                {initialValues?.caseSummary?.generalEligibilityOutputReason
                  ? ` - ${initialValues?.caseSummary?.generalEligibilityOutputReason}`
                  : null}
              </Descriptions.Item>
              <Descriptions.Item label="Application Type">
                {initialValues.caseSummary?.applicationType ?? 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {GetFeatureFlagByName('QAReworkFlag') === 'true' ? (
        rearrangedSummary()
      ) : (
        <Row>
          <Col span={24}>
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="USMLE ID">
                {initialValues?.caseSummary?.usmleId}
              </Descriptions.Item>
              <Descriptions.Item label="Submission Date">
                {dateSubmitted ? moment(dateSubmitted).format(DATE_FORMAT) : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Last Activity">
                {lastActivity
                  ? moment({ hours: 0 }).diff(lastActivity, 'days') + ' days ago'
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Eligibility Check">
                {initialValues?.caseSummary?.isEligible ? 'Pass' : 'Fail'}
              </Descriptions.Item>
              <Descriptions.Item label="Name">{`${initialValues?.caseSummary?.lastName}, ${initialValues?.caseSummary?.firstName}`}</Descriptions.Item>
              <Descriptions.Item label="Case Status">
                {initialValues?.caseSummary?.caseState}
              </Descriptions.Item>
              {initialValues?.caseSummary?.output ? (
                <Descriptions.Item label="Case Output">
                  {initialValues?.caseSummary?.output}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Last Oracle Refresh Time">
                {initialValues?.caseSummary?.updatedAt
                  ? moment(initialValues?.caseSummary.updatedAt).format(DATE_FORMAT)
                  : 'N/A'}
              </Descriptions.Item>

              <Descriptions.Item label="General Eligibility">
                {initialValues?.caseSummary?.generalEligibilityStatus}
              </Descriptions.Item>
              {initialValues?.caseSummary?.generalEligibilityOutputReason ? (
                <Descriptions.Item label="General Eligibility Output Reason">
                  {initialValues?.caseSummary?.generalEligibilityOutputReason}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Application Type">
                {initialValues.caseSummary?.applicationType ?? 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      )}
    </>
  );
};

export default {
  Component: CaseOverview,
};
