import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Divider, message, Spin, Typography } from 'antd';
import { useMatch, useParams, useNavigate } from 'react-router-dom';
import ActivityFeed from '../../../activity-feed';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client/react';
import PathwayXDocumentReviewChecklistFactory from './pathwayX-document-review-checklist-factory';
import PathwayXDocumentReviewApplicantDetails from './pathwayX-document-review-applicant-details';
import { AdditionalDocumentCardList } from '../../additional-document/additional-document-card-list';
import { UploadDocument } from '../../upload-document/upload-document';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import {
  CaseMgmtPathwayXDocumentReviewLayoutCasePortalDocument,
  ActivityLogType,
  CaseSummaryType,
  CaseMgmtPathwayXDocumentReviewLayoutAddAndDeleteDocumentDocument,
} from '../../../../../generated';
import ApplicantSummaryFactory from '../../applicant-summary/applicant-summary-factory';
const { Title } = Typography;

var isUploadSuccessful = false;

const PathwayXDocumentReviewLayout: FC<any> = (props) => {
  const navigate = useNavigate();
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  let { usmleId, documentId } = useParams<any>();

  const { loading, error, data, refetch } = useQuery(
    CaseMgmtPathwayXDocumentReviewLayoutCasePortalDocument,
    {
      variables: { usmleId: usmleId },
    }
  );
  const [additionalDocuments, setAdditionalDocuments] = useState<any>([]);
  const [addAndDeleteAdditionalDocumentsMutation] = useMutation(
    CaseMgmtPathwayXDocumentReviewLayoutAddAndDeleteDocumentDocument,
    {
      refetchQueries: [
        {
          query: CaseMgmtPathwayXDocumentReviewLayoutCasePortalDocument,
          variables: { usmleId: usmleId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  useEffect(() => {
    if (data) {
      const applicantProvidedData = data?.casePortal?.case?.pathwayX;
      setSelectedDocument(applicantProvidedData);
    }
    if (data?.casePortal?.case?.caseSummary?.dataVersion) {
      setDataVersion(data.casePortal.case.caseSummary.dataVersion);
    }
    const retrievedAdditionalDocuments: any = [];
    _.forEach(data?.casePortal?.case?.additionalDocuments, (element) => {
      retrievedAdditionalDocuments.push(element?.document);
    });
    setAdditionalDocuments(retrievedAdditionalDocuments);
  }, [data]);
  if (error) {
    console.log('Error retrieving applicant info', error);
  }

  const disableChecklist: boolean =
    data?.casePortal?.case?.pathwayX?.review?.status?.toUpperCase() === 'APPROVED' ||
    data?.casePortal?.case?.pathwayX?.review?.status?.toUpperCase() === 'REJECTED' ||
    data?.casePortal?.case?.caseSummary?.pathwayEligibilityStatus?.toUpperCase() === 'COMPLETED' ||
    data?.casePortal?.case?.caseSummary?.eligibilityReviewAssigneeId !==
      sessionStorage.getItem('userIdToken');

  const onUpdateDataVersionFromChild = async (dataVersion: number) => {
    setDataVersion(dataVersion);
  };
  //Get the blob name for azure storage by saving in mongo
  async function getBlobname(filename: string, docType: string) {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));
    var uniqueDocIds: any = [];
    deepCopyDocuments.map((doc: any) => {
      uniqueDocIds.push(doc.docId);
      delete doc.__typename;
    });
    const documentToUpload = {
      docType: docType,
      title: filename,
    };
    deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
      return {
        document: element,
      };
    });
    deepCopyDocuments.push({ document: documentToUpload });
    return addAndDeleteAdditionalDocumentsMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        input: deepCopyDocuments,
      },
    })
      .then((data) => {
        setDataVersion(data?.data?.CasePortal_additionalDocuments?.caseSummary?.dataVersion);
        const addedDocuments = data?.data?.CasePortal_additionalDocuments?.additionalDocuments; //debug this

        var docs: any = [];
        _.forEach(addedDocuments, (data) => {
          docs.push(data?.document);
        });
        var thisDocGuid = '';
        docs.map((doc: any) => {
          //The most recent document won't exist in the uniqueDocIds, so that is the one for this document
          if (!uniqueDocIds.includes(doc.docId)) {
            thisDocGuid = doc.docId;
          }
        });
        setAdditionalDocuments(docs);
        isUploadSuccessful = true;
        refetch();
        return thisDocGuid;
      })
      .catch((error) => {
        isUploadSuccessful = false;
        refetch();
        console.log(error);
        return 'error';
      });
  }

  const onDeleteDocument = async (docId: any) => {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));

    deepCopyDocuments = _.remove(deepCopyDocuments, (document: any) => {
      return document.docId !== docId;
    });

    deepCopyDocuments.map((doc: any) => {
      delete doc.__typename;
    });

    deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
      return {
        document: element,
      };
    });
    return addAndDeleteAdditionalDocumentsMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        input: deepCopyDocuments,
      },
    })
      .then((res: any) => {
        message.success('Delete successfully!');
        refetch();
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        refetch();
      });
  };

  if (loading) {
    return <Spin></Spin>;
  }

  return (
    <>
      <Helmet>
        <title>Pathway X Review</title>
      </Helmet>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={24} lg={24} xl={12}>
          {!!data?.casePortal?.case?.applicant && !!data?.casePortal?.case?.caseSummary ? (
            <PathwayXDocumentReviewApplicantDetails.Component
              applicant={data.casePortal.case.applicant}
              caseSummary={data.casePortal.case.caseSummary}
            />
          ) : null}
          <Divider plain orientation="center">
            <b>Additional Documents</b>
          </Divider>
          <Row>
            <Col span={23}>
              {additionalDocuments.length === 0 ? (
                <div>There is no additional document added.</div>
              ) : (
                <AdditionalDocumentCardList
                  numberOfCardsPerRow={3}
                  documents={additionalDocuments}
                  onDelete={(docId) => onDeleteDocument(docId)}
                  disabled={disableChecklist}
                />
              )}
              <UploadDocument
                getGuid={(filename) => getBlobname(filename, 'Additional Document')}
                showIcon={false}
                label="Add Document"
                //disabled because we want to allow document uploads at all times for supporting docs. Ex: refund supporting docs
                //disabled={disableDocumentUpload}
              />
            </Col>
          </Row>

          <Divider plain orientation="center">
            <b>Applicant Provided Information (read only)</b>
          </Divider>
          <ApplicantSummaryFactory
            usmleID={usmleId}
            versionConfig={data?.casePortal?.case?.uiConfig}
          ></ApplicantSummaryFactory>
        </Col>
        <Col sm={24} lg={24} xl={12}>
          {!!data?.casePortal?.case?.caseSummary &&
          !!data?.casePortal?.case?.applicant &&
          !!data?.casePortal?.case?.uiConfig ? (
            <PathwayXDocumentReviewChecklistFactory
              caseSummary={data.casePortal.case.caseSummary}
              checklist={selectedDocument?.review?.checklist}
              exceptionChecklistState={selectedDocument?.review?.exceptionChecklistState}
              pathwayChecklistState={selectedDocument?.review?.pathwayChecklistState}
              recordId={selectedDocument?._id}
              parentDataVersion={dataVersion}
              onUpdateDataVersion={(dataVersion: number) => {
                onUpdateDataVersionFromChild(dataVersion);
              }}
              applicant={data.casePortal.case.applicant}
              redirect={(url: string) => {
                navigate(url);
              }}
              checklistStatus={data?.casePortal?.case?.pathwayX?.review?.status as string}
              versionConfig={data?.casePortal?.case?.uiConfig}
            />
          ) : null}
        <br />
          <Title level={3}>Activity Feed</Title>
          <hr style={{ borderTop: '1px black solid' }} />
          {!!data?.casePortal?.case?.activityLog && !!data?.casePortal?.case?.caseSummary ? (
            <ActivityFeed.Component
              activityLog={data.casePortal.case.activityLog as ActivityLogType[]}
              caseSummary={data.casePortal.case.caseSummary}
              parentDataVersion={dataVersion}
              onUpdateDataVersion={(dataVersion: number) => {
                onUpdateDataVersionFromChild(dataVersion);
              }}
              refetch={() => {
                refetch();
              }}
            />
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default PathwayXDocumentReviewLayout;
