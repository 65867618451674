import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, Input, Button } from 'antd';
import ECFMGLogo from '../../logos/ECFMG_NoTag_WHITE_TM_forSmall.svg';

const { Header } = Layout;

const PhysicianHeader: FC<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState('2');
  const [email, setEmail] = useState<string | undefined>();

  const menuItemSelected = async (e: any) => {
    setSelectedMenu(e.key);
    switch (e.key) {
      case '1':
        navigate(`/`);
        break;
      case '2':
        navigate(`/physician-management`);
        break;
    }
  };

  const onSearchClick = async () => {
    if (email && email !== '') {
      var trimmedEmail = email.trim();
      setEmail(trimmedEmail);
      navigate(`physician-detail/${trimmedEmail}`);
    }
  };

  return (
    <Header style={{ position: 'fixed', zIndex: 1, width: '100%', padding: '0 10px' }}>
      <img className="logo" src={ECFMGLogo} />
      <Menu
        onClick={(e) => menuItemSelected(e)}
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[selectedMenu]}
        selectable={false}
      >
        <Menu.Item key="2">Physician List</Menu.Item>

        <Menu.Item className="searchbar">
          <div
            style={{
              clear: 'left',
              float: 'right',
              display: 'inline-block',
              minHeight: '64px',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Input
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onPressEnter={() => onSearchClick()}
              placeholder="E-mail"
              style={{ maxWidth: '200px' }}
            />
            &nbsp; &nbsp;
            <Button onClick={() => onSearchClick()} size="small">
              Search
            </Button>
            &nbsp; &nbsp;
          </div>
        </Menu.Item>
        <Menu.Item key="1" style={{ marginLeft: 'auto' }}>
          {sessionStorage.getItem('msal.idtoken') ? 'Logout' : 'Login'}
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default {
  Component: PhysicianHeader,
};
