import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Input, Button } from 'antd';
import ECFMGLogo from '../../logos/ECFMG_NoTag_WHITE_TM_forSmall.svg';

const { Header } = Layout;

const ArchivedCaseHeader: FC<any> = () => {
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState('2');
  const [usmleid, setUsmleid] = useState<string | undefined>();

  const menuItemSelected = async (e: any) => {
    setSelectedMenu(e.key);
    switch (e.key) {
      case '1':
        navigate(`/`);
        break;
      case '2':
        navigate(`/archived-cases`);
        break;
    }
  };

  const onSearchClick = async () => {
    if (usmleid && usmleid !== '') {
      navigate(`/archived-cases/${usmleid}`);
      setUsmleid('');
    }
  };

  return (
    <Header style={{  zIndex: 1, width: '100%', padding: '0 10px' }}>
      <img className="logo" src={ECFMGLogo} alt=""/>
      <Menu
        onClick={(e) => menuItemSelected(e)}
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[selectedMenu]}
        selectable={false}
      >
        <Menu.Item key="2">Archived Cases</Menu.Item>

        <Menu.Item className="searchbar">
          <div
            style={{
              clear: 'left',
              float: 'right',
              display: 'inline-block',
              minHeight: '64px',
            }}
          >
            <Input
              size="small"
              value={usmleid}
              onChange={(e) => setUsmleid(e.target.value)}
              onPressEnter={() => onSearchClick()}
              placeholder="USMLE ID"
              style={{ maxWidth: '200px' }}
            />
            &nbsp; &nbsp;
            <Button onClick={() => onSearchClick()} size="small">
              Find
            </Button>
            &nbsp; &nbsp;
          </div>
        </Menu.Item>
        <Menu.Item key="1" style={{ marginLeft: 'auto' }}>
          {sessionStorage.getItem('msal.idtoken') ? 'Logout' : 'Login'}
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default {
  Component: ArchivedCaseHeader,
};
