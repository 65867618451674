import React, { FC, Suspense } from 'react';
import { InferProps } from 'prop-types';
import {
  PhysicianResponseType,
  MiniCexResponseType,
  MiniCexSummaryType,
} from '../../../../../generated';
import ReviewRejectedMiniCEXV2 from './pathway6-review-rejected-miniCEXV2';

const PAGE_NAME = 'minicex-rejection-summary';
const MINICEX_COMPONENT_NAME = 'minicex-rejection-summary';

interface IProps {
  __typename?: 'ReviewRejectedMiniCEX';
  physician?: PhysicianResponseType;
  miniCEX?: MiniCexResponseType;
}

export type ComponentProps = InferProps<IProps>;

const ReviewRejectedMiniCEXFactory: FC<IProps> = ({ physician, miniCEX }) => {
  const ReviewRejectedMiniCEX = React.lazy(() => import('./pathway6-review-rejected-miniCEX'));

  const renderVersion = () => {
    const uiConfig = miniCEX?.uiConfig;
    var miniCEXVersionNum = 0;

    uiConfig?.config?.pages?.map((page) => {
      if (page?.pageName === PAGE_NAME) {
        page.components?.map((component) => {
          if (component?.componentName === MINICEX_COMPONENT_NAME) {
            miniCEXVersionNum = component?.version || 0;
          }
        });
      }
    });

    switch (miniCEXVersionNum) {
      case 1: {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <ReviewRejectedMiniCEX
              physician={physician}
              miniCEX={miniCEX?.summary as MiniCexSummaryType}
            />
          </Suspense>
        );
      }
      case 2: {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <ReviewRejectedMiniCEXV2
              physician={physician}
              miniCEX={miniCEX?.summary as MiniCexSummaryType}
            />
          </Suspense>
        );
      }
      default: {
        return <div></div>;
      }
    }
  };

  return renderVersion();
};

export default ReviewRejectedMiniCEXFactory;
