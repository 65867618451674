import React, { FC, Suspense } from 'react';
import { useFeatureFlags } from '../components/feature-flag-react-lite';
import PropTypes, { InferProps } from 'prop-types';
import { Spin } from 'antd';

const ViewPDFWrapperPropTypes = {
  filename: PropTypes.string.isRequired,
  docId: PropTypes.string.isRequired,
};

export type ViewPDFWrapperProps = InferProps<typeof ViewPDFWrapperPropTypes>;

//pass in filename for props
const ViewPDFWrapper: FC<ViewPDFWrapperProps> = ({ filename, docId }) => {
  const { GetFeatureFlagByName } = useFeatureFlags();
  const ViewPDF = React.lazy(() => import('./ViewPDF'));
  const ViewPDF3 = React.lazy(() => import('./ViewPDF3'));

  return GetFeatureFlagByName('PDFViewerEnhancement') === 'true' ? (
    <Suspense fallback={<Spin>Loading ...</Spin>}>
      <ViewPDF3 filename={filename} docId={docId}></ViewPDF3>
    </Suspense>
  ) : (
    <Suspense fallback={<Spin>Loading ...</Spin>}>
      <ViewPDF filename={filename} docId={docId}></ViewPDF>
    </Suspense>
  );
};

export default ViewPDFWrapper;
