import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Input, Button, Popconfirm, Spin } from 'antd';
import { useQuery } from '@apollo/client';
import PropTypes, { InferProps } from 'prop-types';
import { GetUserGroup } from '../..//utils/helperFunctions';
import { useFeatureFlags } from '../feature-flag-react-lite';
import { caseCount } from '../schema/casePortal-caseCount';
import { CaseMgmtCaseHeaderCasePortalDocument } from '../../generated';
import ECFMGLogo from '../../logos/ECFMG_NoTag_WHITE_TM_forSmall.svg';

const { Header } = Layout;

const CaseHeaderPropsTypes = {
  onRequestCaseLoad: PropTypes.func.isRequired,
  onRequestCaseAdd: PropTypes.func.isRequired,
};

export interface ComponentProp {
  casePortal: {
    caseCount: caseCount;
  };
}

export type CaseHeaderProps = InferProps<typeof CaseHeaderPropsTypes>;

const CaseHeader: FC<CaseHeaderProps> = ({ onRequestCaseLoad, onRequestCaseAdd }) => {
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState('2');
  const [usmleId, setUsmleId] = useState<string | undefined>();
  const [featureFlagPreventAddExceptionCase, setFeatureFlagPreventAddExceptionCase] =
    useState<string>('false');

  const { GetFeatureFlagByName } = useFeatureFlags();
  const { loading, error, data } = useQuery(CaseMgmtCaseHeaderCasePortalDocument);

  const menuItemSelected = async (e: any) => {
    setSelectedMenu(e.key);
    switch (e.key) {
      case '1':
        navigate(`/`);
        break;
      case '2':
        navigate(`/case-management`);
        break;
    }
  };

  useEffect(() => {
    setFeatureFlagPreventAddExceptionCase(GetFeatureFlagByName('PreventAddExceptionCase'));
  }, []);

  const onFindClick = async (usmleId: any) => {
    await onRequestCaseLoad(usmleId?.trim());
    setUsmleId(undefined);
  };

  const LOGIN_TEXT = 'Login';
  const LOGOUT_TEXT = 'Logout';

  return (
    <Header style={{ zIndex: 2, width: '100%', padding: '0 10px' }}>
      <img className="logo" src={ECFMGLogo} />
      <Menu
        onClick={(e: any) => menuItemSelected(e)}
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[selectedMenu]}
        selectable={false}
      >
        {sessionStorage.getItem('msal.idtoken') ? <Menu.Item key="2">Case List</Menu.Item> : null}
        <Menu.Item className="header">
          <div
            style={{
              clear: 'left',
              float: 'right',
              display: 'inline-block',
              minHeight: '64px',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="caseHeader"
              style={{
                padding: '5px',
                height: '100%',
                verticalAlign: 'middle',
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  minHeight: '40px',
                  padding: '5px 15px',
                }}
              >
                {!!data?.casePortal?.caseCount?.eligibilityReview ? (
                  <div
                    style={{
                      fontSize: '20px',
                      verticalAlign: 'middle',
                      minHeight: '100%',
                      paddingRight: '5px',
                    }}
                  >
                    {data.casePortal.caseCount.eligibilityReview}
                  </div>
                ) : null}
                <div
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    minHeight: '100%',
                    maxWidth: '80px',
                  }}
                >
                  Under Review Cases
                </div>
              </div>
            </div>
            <div
              className="caseHeader"
              style={{
                padding: '5px',
                height: '100%',
                verticalAlign: 'middle',
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  minHeight: '40px',
                  padding: '5px 15px',
                }}
              >
                {!!data?.casePortal?.caseCount?.applicationSubmitted ? (
                  <div
                    style={{
                      fontSize: '20px',
                      verticalAlign: 'middle',
                      minHeight: '100%',
                      paddingRight: '5px',
                    }}
                  >
                    {data.casePortal.caseCount.applicationSubmitted}
                  </div>
                ) : null}
                <div
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    minHeight: '100%',
                    maxWidth: '80px',
                  }}
                >
                  Submitted Cases
                </div>
              </div>
            </div>
            <div
              className="caseHeader"
              style={{
                padding: '5px',
                height: '100%',
                verticalAlign: 'middle',
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  minHeight: '40px',
                  padding: '5px 15px',
                }}
              >
                {!!data?.casePortal?.caseCount?.assignedToUser ? (
                  <div
                    style={{
                      fontSize: '20px',
                      verticalAlign: 'middle',
                      minHeight: '100%',
                      paddingRight: '5px',
                    }}
                  >
                    {data.casePortal.caseCount.assignedToUser}
                  </div>
                ) : null}
                <div
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    minHeight: '100%',
                    maxWidth: '80px',
                  }}
                >
                  My Cases
                </div>
              </div>
            </div>
          </div>
        </Menu.Item>
        <Menu.Item className="header">
          <Input
            size="small"
            value={usmleId}
            onChange={(e) => setUsmleId(e.target.value)}
            onPressEnter={() => onFindClick(usmleId?.trim())}
            placeholder="USMLE ID"
            style={{ maxWidth: '100px' }}
          />
          &nbsp; &nbsp;
          <Button onClick={() => onFindClick(usmleId?.trim())} size="small">
            Find
          </Button>
          &nbsp; &nbsp;
          {GetUserGroup() &&
          GetUserGroup()?.includes('CaseManager') &&
          !(featureFlagPreventAddExceptionCase === 'true') ? (
            <Popconfirm title={'Are you sure?'} onConfirm={() => onRequestCaseAdd(usmleId?.trim())}>
              <Button size="small">Add Exception</Button>
            </Popconfirm>
          ) : null}
        </Menu.Item>
        <Menu.Item key="1" style={{ marginLeft: 'auto' }}>
          {sessionStorage.getItem('msal.idtoken') ? LOGOUT_TEXT : LOGIN_TEXT}
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default {
  Component: CaseHeader,
};
