import { Card, List,Typography } from 'antd';
import { ArchivedCasePathway2ReviewType, UiPortalConfigType } from '../../../../generated';
import { FC } from 'react';
import { CHECKLIST_EVALUATION_MAPPINGS } from '../../../constants';
import { Pathway2ArchivedCaseReviewChecklistOSCEFactory } from './pathway2-archived-case-review-checklist-versions/pathway2-archived-case-review-checklist-osce-factory';
import { Pathway2ArchivedCaseReviewChecklistVerificationFactory } from './pathway2-archived-case-review-checklist-versions/pathway2-archived-case-review-checklist-verification-factory';

const { Title, Text } = Typography;

const STATUS_OPTIONS = [
  { key: 'awaiting-attestation-document', value: 'Awaiting Attestation Document' },
  {
    key: 'exception-review-escalation-reqired',
    value: 'Exception Review/Escalation Required',
  },
  {
    key: 'sent-for-verification',
    value: 'Sent for Verification',
  },
  {
    key: 'resent-for-verification',
    value: 'Resent for Verification',
  },
  {
    key: 'verification-received-in-review',
    value: 'Verification received; In Review',
  },
  {
    key: 'verification-rejected-and-resent',
    value: 'Verification Rejected and Resent',
  },
  { key: 'verification-review-complete', value: 'Verification review complete' },
  { key: 'attestation-received-in-review', value: 'Attestation received; In Review' },
  {
    key: 'attestation-rejected-and-resent',
    value: 'Attestation Rejected and Resent',
  },
  {
    key: 'attestation-review-complete',
    value: 'Attestation review complete',
  },
];

interface Pathway2ArchivedCaseChecklistProps {
  data: ArchivedCasePathway2ReviewType;
  uiConfig: UiPortalConfigType
}


const getColor = (value: string) => {
  switch (value) {
    case 'Yes':
      return 'green';
    case 'No':
      return 'red';
    default:
      return 'black';
  }
};

export const Pathway2ArchivedCaseChecklist: FC<Pathway2ArchivedCaseChecklistProps> = (props) => {
  const pathway2OsceMap = Pathway2ArchivedCaseReviewChecklistOSCEFactory(props.uiConfig);
  
  const pathway2VerificationMap  = Pathway2ArchivedCaseReviewChecklistVerificationFactory(props.uiConfig);
 
 
  // Building Checklist Structure and Data
  let pathway2ChecklistData = [
    <Text style={{ fontWeight: 'bold' }}>
      OSCE Status
    </Text>,
    <div>{STATUS_OPTIONS.find((status) => status.key === props.data?.checklistState)?.value}</div>,
    <Text style={{ fontWeight: 'bold' }}>
      OSCE Review
    </Text>,
  ];

  let tempChecklist = props.data?.checklist as Record<string, string>;

  for (const key in props.data?.checklist) {
    if (!pathway2OsceMap?.[key]) {
      continue;
    }
    pathway2ChecklistData.push(
      <table width="100%">
        <tr>
          <td>{pathway2OsceMap[key]}</td>
          <td align="right" style={{ color: getColor(tempChecklist[key]) }}>
            {CHECKLIST_EVALUATION_MAPPINGS.find((eva) => eva.key === tempChecklist[key])?.value}
          </td>
        </tr>
      </table>
    );
  }

  // New Section for Verification
  pathway2ChecklistData.push(
    <Text style={{ fontWeight: 'bold' }}>
      Verification Review
    </Text>
  );

  for (const key in props.data?.checklist) {
    if (!pathway2VerificationMap?.[key]) {
      continue;
    }
    pathway2ChecklistData.push(
      <table width="100%">
        <tr>
          <td>{pathway2VerificationMap[key]}</td>
          <td align="right" style={{ color: getColor(tempChecklist[key]) }}>
            {CHECKLIST_EVALUATION_MAPPINGS.find((eva) => eva.key === tempChecklist[key])?.value}
          </td>
        </tr>
      </table>
    );
  }

  return (
    <Card
      bodyStyle={{ paddingTop: '5px', paddingBottom: '5px' }}
      style={{
        width: '50%',
        borderRadius: '10px',
        border: '1px solid var(--Neutral-4, #F0F0F0)',
        background: 'var(--Neutral-1, #FFF)',
      }}
      title={
        <Title level={5} style={{ fontWeight: 'bold' }}>
          Pathway 2 Checklist
        </Title>
      }
    >
      <List
        size="small"
        dataSource={pathway2ChecklistData}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </Card>
  );
};
