export const PAGE_NAME = {
    APPLICANT_REJECTION_REASONS: "application-rejection-reasons",
    CASE_CHECKLIST: "case-checklist",
}

export const COMPONENT_NAME = {
    APPLICANT_REJECTION_REASONS: "application-rejection-reasons",
    OET_CHECKLIST: 'oet-checklist',
    PATHWAY1_CHECKLIST: "pathway1-checklist",
    PATHWAY2_CHECKLIST: "pathway2-checklist",
    PATHWAY345_CHECKLIST: "pathway345-checklist",
    PATHWAY6_CHECKLIST: "pathway6-checklist",
    PATHWAYX_CHECKLIST: "pathwayX-checklist",
}