import React, { FC, useState, useEffect } from 'react';
import { Form, Space, Button, Row, Col, Select, Popconfirm, message, Typography } from 'antd';
import { SendOutlined, CheckOutlined } from '@ant-design/icons';
import PropTypes, { InferProps } from 'prop-types';
import { useMutation } from '@apollo/client/react';
import {
  CaseMgmtOetDocumentReviewChecklistReviewDocument,
  CaseSummaryType,
  C_ApplicantType,
} from '../../../../../../generated';
const { Title } = Typography;

const { Option } = Select;

const OET_STATUS = [
  { key: 'score-not-yet-received', value: 'Score not yet received' },
  { key: 'score-received', value: 'Score Received' },
  { key: 'awaiting-applicant-feedback', value: 'Awaiting applicant feedback' },
  { key: 'score-review-in-process', value: 'Score Review in process' },
  {
    key: 'exception-review-escalation-reqired',
    value: 'Exception Review/Escalation Required',
  },
  { key: 'score-review-complete', value: 'Score Review complete' },
];

const OETDocumentReviewChecklistPropsTypes = {
  checklist: PropTypes.shape({
    licenseStatus: PropTypes.string,
    scoreConfirmed: PropTypes.string,
    scoreAcceptable: PropTypes.string,
    examDateValidation: PropTypes.string,
    nameValid: PropTypes.string,
    documentCompleteLegible: PropTypes.string,
  }).isRequired,
  checklistState: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  parentDataVersion: PropTypes.number,
  onUpdateDataVersion: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  checklistStatus: PropTypes.string.isRequired,
};

export interface ComponentProps {
  caseSummary: CaseSummaryType;
  applicant: C_ApplicantType;
}

export type OETDocumentReviewChecklistProps = InferProps<
  typeof OETDocumentReviewChecklistPropsTypes
> &
  ComponentProps;

const OetdocumentReviewChecklistV2: FC<OETDocumentReviewChecklistProps> = ({
  checklist,
  caseSummary,
  checklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  applicant,
  redirect,
  checklistStatus,
}) => {
  const [licenseStatus, setLicenseStatus] = useState<string | undefined | null>(undefined);
  const [scoreConfirmed, setScoreConfirmed] = useState<string | undefined | null>(undefined);
  const [scoreAcceptable, setScoreAcceptable] = useState<string | undefined | null>(undefined);
  const [examDateValidation, setExamDateValidation] =
    useState<string | undefined | null>(undefined);
  const [nameValid, setNameValid] = useState<string | undefined | null>(undefined);
  const [documentCompleteLegible, setDocumentCompleteLegible] =
    useState<string | undefined | null>(undefined);
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);
  const [setOETChecklist] = useMutation(CaseMgmtOetDocumentReviewChecklistReviewDocument);

  useEffect(() => {
    if (parentDataVersion) {
      setDataVersion(parentDataVersion);
    }
    if (checklistState && typeof licenseStatus == 'undefined') {
      setLicenseStatus(checklistState);
      form.setFieldsValue({
        licenseStatus: checklistState,
      });
    }
    if (checklist) {
      if (typeof scoreConfirmed == 'undefined') {
        setScoreConfirmed(checklist?.scoreConfirmed);
        form.setFieldsValue({
          scoreConfirmed: checklist.scoreConfirmed,
        });
      }
      if (typeof scoreAcceptable == 'undefined') {
        setScoreAcceptable(checklist.scoreAcceptable);
        form.setFieldsValue({
          scoreAcceptable: checklist.scoreAcceptable,
        });
      }
      if (typeof examDateValidation == 'undefined') {
        setExamDateValidation(checklist.examDateValidation);
        form.setFieldsValue({
          examDateValidation: checklist.examDateValidation,
        });
      }
      if (typeof nameValid == 'undefined') {
        setNameValid(checklist.nameValid);
        form.setFieldsValue({
          nameValid: checklist.nameValid,
        });
      }
      if (typeof documentCompleteLegible == 'undefined') {
        setDocumentCompleteLegible(checklist.documentCompleteLegible);
        form.setFieldsValue({
          documentCompleteLegible: checklist.documentCompleteLegible,
        });
      }
    }
  });

  const selectOptions = () => {
    return (
      <>
        <Option value=""> </Option>
        <Option value="No">No</Option>
        <Option value="Yes">Yes</Option>
        <Option value="Not Applicable">N/A</Option>
      </>
    );
  };

  const disableChecklist: boolean =
    checklistStatus?.toUpperCase() === 'APPROVED' ||
    checklistStatus?.toUpperCase() === 'REJECTED' ||
    caseSummary?.englishEligibilityStatus?.toUpperCase() === 'COMPLETED' ||
    caseSummary?.eligibilityReviewAssigneeId !== sessionStorage.getItem('userIdToken');

  const onSaveAndReturnClick = async () => {
    const savedData = {
      scoreConfirmed: scoreConfirmed || '',
      scoreAcceptable: scoreAcceptable || '',
      examDateValidation: examDateValidation || '',
      nameValid: nameValid || '',
      documentCompleteLegible: documentCompleteLegible || '',
    };

    await setOETChecklist({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion!,
        recordId: recordId,
        isComplete: false,
        checklistResponse: savedData,
        checklistState: licenseStatus || '',
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_languageAssessment_setReview?.caseSummary;
        setDataVersion(casePortalResponse?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.dataVersion);
        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onFinish = async () => {
    const savedData = {
      scoreConfirmed: scoreConfirmed || '',
      scoreAcceptable: scoreAcceptable || '',
      examDateValidation: examDateValidation || '',
      nameValid: nameValid || '',
      documentCompleteLegible: documentCompleteLegible || '',
    };

    await setOETChecklist({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion!,
        recordId: recordId,
        isComplete: true,
        checklistResponse: savedData,
        checklistState: licenseStatus || '',
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_languageAssessment_setReview?.caseSummary;
        setDataVersion(casePortalResponse?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.dataVersion);
        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        form={form}
        onFieldsChange={(changedFields, allFields) => {
          console.log(changedFields);
        }}
        onFinish={onFinish}
        initialValues={{
          licenseStatus: checklist?.licenseStatus,
          scoreConfirmed: checklist?.scoreConfirmed,
          scoreAcceptable: checklist?.scoreAcceptable,
          examDateValidation: checklist?.examDateValidation,
          nameValid: checklist?.nameValid,
          documentCompleteLegible: checklist?.documentCompleteLegible,
        }}
      >
        <div style={{ border: '1px black solid', padding: '15px' }}>
          <Title level={3}>OET Review</Title>
          <hr style={{ borderTop: '1px black solid' }} />
          <Row justify="end">
            <Col>
              <Button shape="round" icon={<SendOutlined />} size={'small'}>
                <a href={`mailto:${applicant?.email}?subject=ECFMG%20Pathway%20Application`}>
                  {' Contact Applicant'}
                </a>
              </Button>
            </Col>
          </Row>

          <Title level={4}>OET Status</Title>
          <Row>
            <Col span={24}>
              <Form.Item
                name="licenseStatus"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setLicenseStatus(value)}
                  disabled={disableChecklist}
                >
                  {OET_STATUS.map((status) => (
                    <Option value={status.key} key={status.key}>
                      {status.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Title level={4}>Evaluation</Title>
          <Row>
            <Col span={4}>
              <Form.Item
                name="scoreConfirmed"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setScoreConfirmed(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Score Confirmed with Primary Source</Col>
          </Row>
          <Row>
            <Col span={4}>
              <Form.Item
                name="scoreAcceptable"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setScoreAcceptable(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Score acceptable to ECFMG</Col>
          </Row>
          <Row>
            <Col span={4}>
              <Form.Item
                name="examDateValidation"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setExamDateValidation(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Date of examination on or after January 1, 2021</Col>
          </Row>
          <Row>
            <Col span={4}>
              <Form.Item
                name="nameValid"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setNameValid(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Name OK</Col>
          </Row>
          <Row>
            <Col span={4}>
              <Form.Item
                name="documentCompleteLegible"
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: <div style={{ width: '200px' }}>Please select an answer</div>,
                  },
                ]}
              >
                <Select
                  onChange={(value: any) => setDocumentCompleteLegible(value)}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Document complete and legible</Col>
          </Row>
          <Row justify="end">
            <Col>
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={form.submit}
                disabled={disableChecklist}
              >
                <Button
                  type="primary"
                  shape="round"
                  icon={<CheckOutlined />}
                  size={'small'}
                  disabled={disableChecklist}
                >
                  Complete Review
                </Button>
              </Popconfirm>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Col>
              <Space>
                <Button
                  shape="round"
                  onClick={() => {
                    redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  shape="round"
                  type="primary"
                  onClick={onSaveAndReturnClick}
                  disabled={disableChecklist}
                >
                  Save and Return
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default OetdocumentReviewChecklistV2;
