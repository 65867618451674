import React, { FC, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Modal, Divider, Button } from 'antd';
import moment from 'moment';
import PropTypes, { InferProps } from 'prop-types';
import { DATE_FORMAT } from '../../../constants';
import ViewPDFWrapper from '../../../ViewPDF-wrapper';
import { useFeatureFlags } from '../../../feature-flag-react-lite';

const ReviewCSExamPropTypes = {
  pathway2: PropTypes.shape({
    userResponse: PropTypes.shape({
      hasPassedCSExamInRecentPast: PropTypes.bool,
      hasPassedFromQualifiedSchoolInRecentPast: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.shape({
          oscExamRef: PropTypes.shape({
            _id: PropTypes.string,
            examCode: PropTypes.string,
            examShortTitle: PropTypes.string,
            examFullName: PropTypes.string,
          }),
          schoolRef: PropTypes.shape({
            _id: PropTypes.number,
            countryName: PropTypes.string,
            name: PropTypes.string,
          }),
          examDate: PropTypes.string,
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
};

export type ReviewCSExamProps = InferProps<typeof ReviewCSExamPropTypes>;

// Q-E
const ReviewCSExamInformationV3: FC<ReviewCSExamProps> = ({ pathway2 }) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const { GetFeatureFlagByName } = useFeatureFlags();

  var applicantProvidedData = undefined;
  if (pathway2?.applicantProvidedData && pathway2?.applicantProvidedData.length > 0) {
    applicantProvidedData = pathway2.applicantProvidedData[0]; //onlye one is submitted by user
  }
  const userResponse = pathway2?.userResponse;
  const examName = applicantProvidedData?.data?.oscExamRef?.examFullName ?? '';
  const examDate = applicantProvidedData?.data?.examDate ?? '';
  const hasPassedCSExamInRecentPast = pathway2?.userResponse?.hasPassedCSExamInRecentPast;
  const hasPassedFromQualifiedSchoolInRecentPast =
    pathway2?.userResponse?.hasPassedFromQualifiedSchoolInRecentPast;
  const documents = applicantProvidedData?.data?.documents as any;
  const schoolInformation = applicantProvidedData?.data?.schoolRef;
  const previewDocument = async (document: any) => {
    setSelectedDocument(document);
    setShowPreviewPdfModal(true);
  };

  const viewPdfClosed = async () => {
    setShowPreviewPdfModal(false);
  };

  return (
    <>
      {userResponse ? (
        <div>
          <Divider plain orientation="left">
            <b>Pathway 2</b>
          </Divider>
          <div>
            {hasPassedCSExamInRecentPast ? (
              <div>
                <p>
                  Did you successfully complete, on or after January 1, 2018, an OSCE required for
                  medical licensure or registration that ECFMG has determined is acceptable for
                  Pathway 2? <b>{hasPassedCSExamInRecentPast ? 'Yes' : 'No'}</b>
                </p>
                {examName ? (
                  <div>
                    {' '}
                    Exam name: <b> {examName}</b>
                    <br />
                    <br />
                  </div>
                ) : null}
                {examDate ? (
                  <div>
                    Exam date:<b> {moment(examDate).format(DATE_FORMAT)}</b>
                    <br />
                    <br />
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                <p id="has-passed-CSExam-in-recent-past-text">
                  Did you successfully complete, on or after January 1, 2018, an OSCE required for
                  medical licensure or registration that ECFMG has determined is acceptable for
                  Pathway 2? <b>{hasPassedCSExamInRecentPast ? 'Yes' : 'No'}</b>
                </p>
                <p>
                  My medical school administers an OSCE specifically required by the medical
                  regulatory authority (MRA) in that country and my graduation date is on or after
                  January 1, 2018.
                </p>
                <p>
                  Medical School: <b>{schoolInformation?.name}</b>
                </p>
              </>
            )}
            <br />
            <br />
          </div>
          <div>
            {documents?.map((document: any) => {
              if (document.docType === 'Pathway2Document') {
                return (
                  <div key={document.docId}>
                    {hasPassedCSExamInRecentPast ? <div>Supporting Document:</div> : null}
                    {hasPassedFromQualifiedSchoolInRecentPast ? (
                      <div>Final Medical Diploma: </div>
                    ) : null}
                    <Button
                      icon={<SearchOutlined />}
                      shape="round"
                      size="small"
                      onClick={() => previewDocument(document)}
                    >
                      {document.title}
                    </Button>
                    <br />
                    <br />
                  </div>
                );
              } else if (document.docType === 'Pathway2TranslatedDocument') {
                return (
                  <div key={document.docId}>
                    {hasPassedCSExamInRecentPast ? (
                      <div>Translated Supporting Document:</div>
                    ) : null}
                    {hasPassedFromQualifiedSchoolInRecentPast ? (
                      <div>English Translation: </div>
                    ) : null}
                    <Button
                      icon={<SearchOutlined />}
                      shape="round"
                      size="small"
                      onClick={() => previewDocument(document)}
                    >
                      {document.title}
                    </Button>
                    <br />
                    <br />
                  </div>
                );
              }
            })}

            <Modal
              title={selectedDocument?.title}
              visible={showPreviewPdfModal}
              width="fit-content"
              closable
              bodyStyle={{ minWidth: '20vw', maxHeight:'80vh' }}
              okText="Continue"
              footer={null}
              onCancel={() => viewPdfClosed()}
            >
              {showPreviewPdfModal ? (
                <ViewPDFWrapper
                  filename={selectedDocument.title}
                  docId={selectedDocument.docId}
                ></ViewPDFWrapper>
              ) : (
                ''
              )}
            </Modal>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default {
  Component: ReviewCSExamInformationV3,
};
