import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Descriptions, Typography } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../constants';
const { Title } = Typography;
const ReviewApplicantDetailsPropTypes = {
  caseSummary: PropTypes.shape({
    usmleId: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    submittedAt: PropTypes.string,
    currentPathway: PropTypes.string,
  }).isRequired,
  applicant: PropTypes.shape({
    email: PropTypes.string,
    dateOfBirth: PropTypes.string,
    gradSchool: PropTypes.string,
    gradYear: PropTypes.string,
    lastDataRefreshTimestamp: PropTypes.string,
  }),
};

export type ReviewApplicantDetailsProps = InferProps<typeof ReviewApplicantDetailsPropTypes>;

const OetdocumentReviewApplicantDetails: FC<ReviewApplicantDetailsProps> = ({
  caseSummary,
  applicant,
}) => {
  return (
    <>
      <Title level={3}>Applicant Details</Title>
      <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
        <Descriptions.Item label="Name">{`${caseSummary?.lastName} ,${caseSummary?.firstName}`}</Descriptions.Item>
        <Descriptions.Item label="USMLE ID">{caseSummary?.usmleId}</Descriptions.Item>
        <Descriptions.Item label="Submission Date">
          {caseSummary?.submittedAt ? moment(caseSummary?.submittedAt).format(DATE_FORMAT) : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Suggested Pathway">
          {caseSummary?.currentPathway}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{applicant?.email}</Descriptions.Item>
        <Descriptions.Item label="Date of Birth">
          {applicant?.dateOfBirth ? moment(applicant?.dateOfBirth).format(DATE_FORMAT) : 'N/A'}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default {
  Component: OetdocumentReviewApplicantDetails,
};
