import { FC, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Row, Col, Divider, message, Spin, Typography } from 'antd';
import ActivityFeed from '../../../activity-feed';
import Pathway1ReviewApplicantDetails from '../pathway1-review-applicant-details';
import { useQuery, useMutation } from '@apollo/client';
import { AdditionalDocumentCardList } from '../../additional-document/additional-document-card-list';
import _ from 'lodash';
import axios from 'axios';
import { UploadDocument } from '../../upload-document/upload-document';
import { Helmet } from 'react-helmet';
import { case_ } from '../../../../schema/casePortal-case';
import {
  ActivityLogType,
  CaseMgmtPathway1DocumentReviewLayoutAddAndDeleteDocumentDocument,
  CaseMgmtPathway1DocumentReviewLayoutAddLicenseDocument,
  CaseMgmtPathway1DocumentReviewLayoutCasePortalDocument,
  CaseMgmtPathway1DocumentReviewLayoutDeleteLicenseDocument,
  CaseSummaryType,
  C_ApplicantType,
} from '../../../../../generated';
import ApplicantSummaryFactory from '../../applicant-summary/applicant-summary-factory';
import Pathway1DocumentReviewChecklistFactory from './pathway1-document-review-checklist-factory';
const { Title } = Typography;

export interface ComponentProp {
  casePortal: {
    case: case_;
  };
}

const ADDITIONAL_DOC_TYPES = {
  license: 'Additional License',
  document: 'Additional Document',
};
var isUploadSuccessful = false;

const Pathway1DocumentReview: FC<any> = (props) => {
  const navigate = useNavigate();
  let { usmleId, documentId } = useParams<any>();
  const { loading, error, data, refetch } = useQuery(
    CaseMgmtPathway1DocumentReviewLayoutCasePortalDocument,
    {
      variables: { usmleId: usmleId },
    }
  );
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>();
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const [additionalLicenses, setAdditionalLicenses] = useState<any>([]);
  const [additionalDocuments, setAdditionalDocuments] = useState<any>([]);
  const [addAdditionalLicenseMutation] = useMutation(
    CaseMgmtPathway1DocumentReviewLayoutAddLicenseDocument,
    {
      refetchQueries: [
        {
          query: CaseMgmtPathway1DocumentReviewLayoutCasePortalDocument,
          variables: { usmleId: usmleId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const [deleteAdditionalLicenseMutation] = useMutation(
    CaseMgmtPathway1DocumentReviewLayoutDeleteLicenseDocument,
    {
      refetchQueries: [
        {
          query: CaseMgmtPathway1DocumentReviewLayoutCasePortalDocument,
          variables: { usmleId: usmleId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [addAndDeleteAdditionalDocumentsMutation] = useMutation(
    CaseMgmtPathway1DocumentReviewLayoutAddAndDeleteDocumentDocument,
    {
      refetchQueries: [
        {
          query: CaseMgmtPathway1DocumentReviewLayoutCasePortalDocument,
          variables: { usmleId: usmleId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  if (selectedDocument?._id !== documentId && data) {
    /* load selected document*/
    const applicantProvided = data?.casePortal?.case?.pathway1?.applicantProvidedData ?? [];
    const staffProvided = data?.casePortal?.case?.pathway1?.staffProvidedData ?? [];
    const joinData = applicantProvided.concat(staffProvided);

    setSelectedDocument(joinData?.find((record: any) => record._id === documentId));
  }

  const disableDocumentUpload: boolean =
    selectedDocument?.review?.status?.toUpperCase() === 'APPROVED' ||
    selectedDocument?.review?.status?.toUpperCase() === 'REJECTED' ||
    data?.casePortal?.case?.caseSummary?.pathwayEligibilityStatus?.toUpperCase() === 'COMPLETED';

  const disableLicenseUpload: boolean =
    selectedDocument?.review?.status?.toUpperCase() === 'APPROVED' ||
    selectedDocument?.review?.status?.toUpperCase() === 'REJECTED' ||
    data?.casePortal?.case?.caseSummary?.pathwayEligibilityStatus?.toUpperCase() === 'COMPLETED' ||
    data?.casePortal?.case?.caseSummary?.eligibilityReviewAssigneeId !==
      sessionStorage.getItem('userIdToken');

  useEffect(() => {
    if (data) {
      setDataVersion(data?.casePortal?.case?.caseSummary?.dataVersion!);
      const staffProvidedData = data.casePortal?.case?.pathway1?.staffProvidedData;
      var retrievedAdditionalLicenses: any = [];
      if (staffProvidedData) {
        _.forEach(staffProvidedData, (license) => {
          retrievedAdditionalLicenses.push(license?.data?.documents?.[0]);
        });
      }
      setAdditionalLicenses(retrievedAdditionalLicenses);

      const retrievedAdditionalDocuments: any = [];
      _.forEach(data.casePortal?.case?.additionalDocuments, (element) => {
        retrievedAdditionalDocuments.push(element?.document);
      });
      setAdditionalDocuments(retrievedAdditionalDocuments);
    }
  }, [data]);

  //Get the blob name for azure storage by saving in mongo
  async function getBlobname(filename: string, docType: string) {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalLicenses));
    var uniqueDocIds: any = [];
    deepCopyDocuments.map((doc: any) => {
      uniqueDocIds.push(doc.docId);
      delete doc.__typename;
    });
    const documentToUpload = {
      docType: docType,
      title: filename,
    };
    deepCopyDocuments.push(documentToUpload);
    if (docType === ADDITIONAL_DOC_TYPES.license) {
      return await addAdditionalLicenseMutation({
        variables: {
          usmleId: usmleId as string,
          dataVersion: dataVersion!,
          input: { documents: [documentToUpload] },
        },
      })
        .then((data) => {
          setDataVersion(data?.data?.CasePortal_pathway1_setData?.caseSummary?.dataVersion);
          const addedLicenses =
            data?.data?.CasePortal_pathway1_setData?.pathway1?.staffProvidedData; //debug this

          var docs: any = [];
          _.forEach(addedLicenses, (license) => {
            if (license?.data?.documents && license?.data?.documents.length > 0) {
              docs.push(license?.data?.documents[0]);
            }
          });
          var thisDocGuid = '';
          docs.map((doc: any) => {
            //The most recent document won't exist in the uniqueDocIds, so that is the one for this document
            if (!uniqueDocIds.includes(doc.docId)) {
              thisDocGuid = doc.docId;
            }
          });
          setAdditionalLicenses(docs);
          isUploadSuccessful = true;
          refetch();
          return thisDocGuid;
        })
        .catch((error) => {
          isUploadSuccessful = false;
          console.log(error);
          refetch();
          return 'error';
        });
    } else {
      var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));
      var uniqueDocIds: any = [];
      deepCopyDocuments.map((doc: any) => {
        uniqueDocIds.push(doc.docId);
        delete doc.__typename;
      });
      const documentToUpload = {
        docType: docType,
        title: filename,
      };
      deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
        return {
          document: element,
        };
      });
      deepCopyDocuments.push({ document: documentToUpload });
      return addAndDeleteAdditionalDocumentsMutation({
        variables: {
          usmleId: usmleId as string,
          dataVersion: dataVersion!,
          input: deepCopyDocuments,
        },
      })
        .then((data) => {
          setDataVersion(data?.data?.CasePortal_additionalDocuments?.caseSummary?.dataVersion);
          const addedDocuments = data?.data?.CasePortal_additionalDocuments?.additionalDocuments; //debug this

          var docs: any = [];
          _.forEach(addedDocuments, (data) => {
            docs.push(data?.document);
          });
          var thisDocGuid = '';
          docs.map((doc: any) => {
            //The most recent document won't exist in the uniqueDocIds, so that is the one for this document
            if (!uniqueDocIds.includes(doc.docId)) {
              thisDocGuid = doc.docId;
            }
          });
          setAdditionalDocuments(docs);
          isUploadSuccessful = true;
          refetch();
          return thisDocGuid;
        })
        .catch((error) => {
          isUploadSuccessful = false;
          console.log(error);
          refetch();
          return 'error';
        });
    }
  }

  const addNewDocToAzure = (document: any, sasToken: string) => {
    if (!isUploadSuccessful) {
      alert('There are errors while uploading!');
      return;
    }
    //Call upload function to blob
    axios
      .put(sasToken, new Blob([document], { type: 'application/pdf' }), {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
      })
      .then(
        (res) => {
          console.log(res);
          if (res.status === 201) {
            //setDocuments(documents.concat(newDoc));
            //page refresh
            navigate(0);
            alert('Upload successfully!');
          } else {
            alert('There are problems with the uploading.');
          }
        },
        (err) => {
          console.log(err);
          alert('Upload failed!');
        }
      );
  };
  const onDeleteLicense = async (docId: any) => {
    const staffProvidedData = data?.casePortal?.case?.pathway1?.staffProvidedData;
    const recordId = _.find(
      staffProvidedData,
      (license) => license?.data?.documents?.[0]?.docId === docId
    )?._id;
    await deleteAdditionalLicenseMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        recordId: recordId!,
      },
    })
      .then((res: any) => {
        message.success('Delete successfully!');
        navigate(`/case-management/case-detail/${usmleId}/pathway-1`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        refetch();
      });
  };

  const onDeleteDocument = async (docId: any) => {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));

    deepCopyDocuments = _.remove(deepCopyDocuments, (document: any) => {
      return document.docId !== docId;
    });

    deepCopyDocuments.map((doc: any) => {
      delete doc.__typename;
    });

    deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
      return {
        document: element,
      };
    });

    return addAndDeleteAdditionalDocumentsMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        input: deepCopyDocuments,
      },
    })
      .then((res: any) => {
        message.success('Delete successfully!');
        refetch();
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        refetch();
      });
  };

  const onUpdateDataVersionFromChild = async (dataVersion: number) => {
    setDataVersion(dataVersion);
  };

  const [form] = Form.useForm();
  //reload the page when license upload is done
  const isLicenseUploaded = (value: boolean) => {
    navigate(0);
  };

  if (loading) {
    return <Spin></Spin>;
  }

  return (
    <>
      <Helmet>
        <title>Pathway 1 Review</title>
      </Helmet>
      <Row>
        <Title level={2}>Pathway 1 Document Review</Title>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col md={24} lg={24} xl={12}>
          {!!data?.casePortal?.case?.applicant && !!data?.casePortal?.case?.caseSummary ? (
            <Pathway1ReviewApplicantDetails.Component
              applicant={data.casePortal.case.applicant}
              caseSummary={data.casePortal.case.caseSummary}
            />
          ) : null}
          <Divider plain orientation="center">
            <b>Applicant Provided Information (read only)</b>
          </Divider>
          <ApplicantSummaryFactory
            usmleID={usmleId}
            versionConfig={data?.casePortal?.case?.uiConfig}
          ></ApplicantSummaryFactory>

          <Divider plain orientation="center">
            <b>Additional Documents</b>
          </Divider>
          <Row>
            <Col span={23}>
              {additionalDocuments.length === 0 ? (
                <div>There is no additional document added.</div>
              ) : (
                <AdditionalDocumentCardList
                  numberOfCardsPerRow={3}
                  documents={additionalDocuments}
                  onDelete={(docId) => onDeleteDocument(docId)}
                  disabled={disableDocumentUpload}
                />
              )}
              <UploadDocument
                getGuid={(filename) => getBlobname(filename, ADDITIONAL_DOC_TYPES.document)}
                showIcon={false}
                label="Add Document"
                //disabled because we want to allow document uploads at all times for supporting docs. Ex: refund supporting docs
                //disabled={disableDocumentUpload}
              />
            </Col>
          </Row>

          <Divider plain orientation="center">
            <b>Additional Licenses</b>
          </Divider>
          <Row>
            <Col span={23}>
              {additionalLicenses.length === 0 ? (
                <div>There is no additional license added.</div>
              ) : (
                <AdditionalDocumentCardList
                  numberOfCardsPerRow={3}
                  documents={additionalLicenses}
                  onDelete={(docId) => onDeleteLicense(docId)}
                  disabled={disableLicenseUpload}
                />
              )}
              <UploadDocument
                getGuid={(filename) => getBlobname(filename, ADDITIONAL_DOC_TYPES.license)}
                showIcon={false}
                label="Add License"
                disabled={disableLicenseUpload}
                updateIsUploadDone={(val: boolean) => {
                  isLicenseUploaded(val);
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col md={24} lg={24} xl={12}>
          {!!data?.casePortal?.case?.caseSummary &&
          data?.casePortal?.case?.applicant &&
          data?.casePortal?.case?.uiConfig ? (
            <Pathway1DocumentReviewChecklistFactory
              key={selectedDocument?._id}
              caseSummary={data.casePortal.case.caseSummary as CaseSummaryType}
              checklist={selectedDocument?.review?.checklist}
              checklistState={selectedDocument?.review?.checklistState}
              recordId={selectedDocument?._id}
              parentDataVersion={dataVersion}
              onUpdateDataVersion={(dataVersion: number) => {
                onUpdateDataVersionFromChild(dataVersion);
              }}
              checklistStatus={selectedDocument?.review?.status}
              applicant={data.casePortal.case.applicant as C_ApplicantType}
              redirect={(url: string) => {
                navigate(url);
              }}
              refetch={() => {
                refetch();
              }}
              checklistMetaData={selectedDocument?.data}
              versionConfig={data.casePortal.case.uiConfig}
            />
          ) : null}
          <br />
          <Title level={3}>Activity Feed</Title>
          <hr style={{ borderTop: '1px black solid' }} />
          {!!data?.casePortal?.case?.activityLog && !!data?.casePortal?.case?.caseSummary ? (
            <ActivityFeed.Component
              activityLog={data.casePortal.case.activityLog as ActivityLogType[]}
              caseSummary={data.casePortal.case.caseSummary}
              parentDataVersion={dataVersion}
              onUpdateDataVersion={(dataVersion: number) => {
                onUpdateDataVersionFromChild(dataVersion);
              }}
              refetch={() => {
                refetch();
              }}
            />
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default Pathway1DocumentReview;
