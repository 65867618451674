import { Button, Popover, Table, message } from 'antd';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArchivedCaseSummaryType } from '../../../generated';
import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';
import { InfoCircleOutlined } from '@ant-design/icons';
interface ArchiveCaseListProps {
  data: any;
}

const ArchivedCaseList: FC<ArchiveCaseListProps> = (props) => {
  const navigate = useNavigate();

  const navigateToCaseDetail = (pathwayNumber: string, caseId: string) => {
    if (!pathwayNumber) {
      message.error('Pathway Number is missing');
      return;
    }
    const route = `/archived-cases/${usmleId}/${pathwayNumber}/${caseId}`;
    navigate(route);
  };

  const { usmleId } = useParams();
  const columns: any = [
    {
      title: 'Action',
      render: (currentCase: ArchivedCaseSummaryType) => (
        <Button
          type="link"
          onClick={() => {
            navigateToCaseDetail(currentCase.pathwayNumber ?? '', currentCase._id);
          }}
        >
          View
        </Button>
      ),
      align: 'center',
    },
    {
      title: 'Pathways Season',
      dataIndex: 'pathwaySeason',
      key: 'pathwaySeason',
      align: 'center',
    },
    {
      title: 'Pathway',
      dataIndex: 'pathwayNumber',
      key: 'pathwayNumber',
      align: 'center',
    },
    {
      title: 'Application Type',
      dataIndex: 'applicationType',
      key: 'applicationType',
      align: 'center',
    },
    {
      title: 'Decision',
      dataIndex: 'decisionOutput',
      key: 'decisionOutput',
      align: 'center',
    },
    {
      title: 'Submitted On',
      dataIndex: 'submittedOn',
      key: 'submittedOn',
      render: (date: string) => (
        <div>
          {date ? (
            dayjs(date).format('MM/DD/YYYY')
          ) : (
            <Popover content={"Check activity log for details"} title="">
              <InfoCircleOutlined />
            </Popover>
          )}
        </div>
      ),
      align: 'center',
    },
    {
      title: 'Review Completed On',
      dataIndex: 'reviewCompletedOn',
      key: 'reviewCompletedOn',
      render: (date: string) => <div>{date ? dayjs(date).format('MM/DD/YYYY') : 'N/A'}</div>,
      align: 'center',
    },
  ];
  return (
    <Table
      className="ecfmg-small-table"
      columns={columns}
      dataSource={props.data}
      pagination={{
        pageSize: 5,
      }}
      bordered
      title={() => (
        <Title level={4} style={{ textAlign: 'center' }}>
          Search Result for USMLE ID "{usmleId}"
        </Title>
      )}
    />
  );
};

export default ArchivedCaseList;
