import { Card } from 'antd';
import Title from 'antd/lib/typography/Title';
import { FC } from 'react';
import { ArchivedCasePathwayXReviewType } from '../../../../generated';
import { ArchivedCaseDocument } from '../../shared-components/archived-case-document';

interface PathwayXArchivedCaseInfoCardProps {
  data: ArchivedCasePathwayXReviewType;
}
export const PathwayXArchivedCaseInfoCard: FC<PathwayXArchivedCaseInfoCardProps> = (props) => {
  
  const documentContent = props.data.data?.documents?.map((document: any) => (
    <div key={document.docId} style={{marginBottom: 10}}>
      <ArchivedCaseDocument title={document.title} docId={document.docId} />
    </div>
  ));

  return (
    <>
      <Card
        style={{
          width: '45%',
          height: '50%',
          borderRadius: '10px',
          border: '1px solid var(--Neutral-4, #F0F0F0)',
          background: 'var(--Neutral-1, #FFF)',
          display: 'block',
        }}
        title={
          <Title level={5} style={{ fontWeight: 'bold' }}>
            Exception Request
          </Title>
        }
      >
       <p
       className="ecfmg-small-descriptions"
       style={{ paddingBottom: '20px', fontSize: 'small' }}
       >
        {props?.data?.data?.requestText}
      </p>
      <div>{documentContent}</div>
      </Card>
    </>
  );
};
