import { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  CaseSummaryType,
  C_ApplicantType,
  UiPageConfigType,
  UiPortalConfigType,
} from '../../../../../generated';

import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';
import PathwayXDocumentReviewChecklist from './checklist-versions/pathwayX-document-review-checklist';
import PathwayXDocumentReviewChecklistV2 from './checklist-versions/pathwayX-document-review-checklist-v2';

const ComponentPropTypes = {
  checklist: PropTypes.shape({
    pathwayStatus: PropTypes.string,
    exceptionStatus: PropTypes.string,
    applicantIsNotCertified: PropTypes.string,
    applicantNotValidStep2CSScore: PropTypes.string,
    applicantHasNotSubmittedAppForCert: PropTypes.string,
    applicantHasPurchasedToken2021Match: PropTypes.string,
    applicantNotBarredByUSMLE: PropTypes.string,
    ecfmgRestrictionsDoNotPreventAppExamination: PropTypes.string,
    step1PassedWithOneOrNoFails: PropTypes.string,
    step2CKPassedWithOneOrNoFails: PropTypes.string,
    exceptionApproved: PropTypes.string,
    applicantEligiblePathway1: PropTypes.string,
    pathway1DocsVerified: PropTypes.string,
    applicantEligiblePathway2: PropTypes.string,
    pathway2DocsVerified: PropTypes.string,
    applicantEligiblePathway345: PropTypes.string,
    pathway345DocsVerified: PropTypes.string,
  }),
  pathwayChecklistState: PropTypes.string.isRequired,
  exceptionChecklistState: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  parentDataVersion: PropTypes.number,
  onUpdateDataVersion: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  checklistStatus: PropTypes.string.isRequired,
};

export interface IProps {
  versionConfig: UiPortalConfigType;
  caseSummary: CaseSummaryType;
  applicant: C_ApplicantType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes & IProps>;

const PathwayXDocumentReviewChecklistFactory: FC<ComponentProps> = ({
  versionConfig,
  checklist,
  caseSummary,
  pathwayChecklistState,
  exceptionChecklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  applicant,
  redirect,
  checklistStatus,
}) => {
  const getPathwayXChecklist = () => {
    return versionConfig?.config?.pages.map((page: UiPageConfigType) => {
      if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
        return page?.components?.map((component) => {
          if (component?.componentName === COMPONENT_NAME.PATHWAYX_CHECKLIST) {
            switch (component.version) {
              case 1:
                return (
                  <PathwayXDocumentReviewChecklist.Component
                    caseSummary={caseSummary}
                    checklist={checklist}
                    exceptionChecklistState={exceptionChecklistState}
                    pathwayChecklistState={pathwayChecklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    checklistStatus={checklistStatus}
                  />
                );
                case 2:
              default:
                return (
                  <PathwayXDocumentReviewChecklistV2.Component
                    caseSummary={caseSummary}
                    checklist={checklist}
                    exceptionChecklistState={exceptionChecklistState}
                    pathwayChecklistState={pathwayChecklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    checklistStatus={checklistStatus}
                  />
                );
            }
          }
        });
      }
    });
  };

  return <div>{getPathwayXChecklist()}</div>;
};

export default PathwayXDocumentReviewChecklistFactory;
