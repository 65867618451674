import { FC } from 'react';
import { MiniCexResponseType } from '../../../../../generated';
import Pathway6AttestationPdf from './pathway6-attestation-pdf';
import Pathway6AttestationPdfV2 from './pathway6-attestation-pdfV2';
import Pathway6AttestationPdfV3 from './pathway6-attestation-pdfV3';

const PAGE_NAME = 'minicex-attestation';
const MINICEX_COMPONENT_NAME = 'minicex-attestation';

export interface MiniCEXAttestationPDFProps {
  readOnlyMiniCEXData?: MiniCexResponseType;
  currentPhysicianData?: any;
}

const AttestationPDFFactory: FC<MiniCEXAttestationPDFProps> = ({
  readOnlyMiniCEXData,
  currentPhysicianData,
}) => {
  const renderVersion = () => {
    var miniCEXVersionNum = 0;

    readOnlyMiniCEXData?.uiConfig?.config?.pages?.map((page) => {
      if (page?.pageName === PAGE_NAME) {
        page.components?.map((component) => {
          if (component?.componentName === MINICEX_COMPONENT_NAME) {
            miniCEXVersionNum = component?.version || 0;
          }
        });
      }
    });

    switch (miniCEXVersionNum) {
      case 1: {
        return (
          <Pathway6AttestationPdf
            readOnlyMiniCEXData={readOnlyMiniCEXData}
            currentPhysicianData={currentPhysicianData}
          />
        );
      }
      case 2: {
        return (
          <Pathway6AttestationPdfV2
            readOnlyMiniCEXData={readOnlyMiniCEXData}
            currentPhysicianData={currentPhysicianData}
          />
        );
      }
      case 3: {
        return (
          <Pathway6AttestationPdfV3
            readOnlyMiniCEXData={readOnlyMiniCEXData}
            currentPhysicianData={currentPhysicianData}
          />
        );
      }
      default: {
        return (
          <Pathway6AttestationPdfV3
            readOnlyMiniCEXData={readOnlyMiniCEXData}
            currentPhysicianData={currentPhysicianData}
          />
        );
      }
    }
  };

  return renderVersion();
};

export default AttestationPDFFactory;
