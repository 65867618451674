import { UiPortalConfigType } from '../../../../../generated';
import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';

import {
  Pathway345ReviewChecklistAttestationV1,
  Pathway345ReviewChecklistAttestationV2,
  Pathway345ReviewChecklistAttestationV3,
  Pathway3ReviewChecklistAttestationV4,
} from './pathway345-archived-case-review-checklist-versions';

export const Pathway345ArchivedCaseReviewChecklistAttestationFactory = (
  uiConfig: UiPortalConfigType
) => {
  for (const page of uiConfig?.config?.pages || []) {
    if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
      for (const component of page?.components || []) {
        if (component?.componentName === COMPONENT_NAME.PATHWAY345_CHECKLIST) {
          switch (component?.version) {
            case 1:
              return Pathway345ReviewChecklistAttestationV1;
            case 2:
              return Pathway345ReviewChecklistAttestationV2;
            case 3:
              return Pathway345ReviewChecklistAttestationV3;
            case 4:
            default:
              return Pathway3ReviewChecklistAttestationV4;
          }
        }
      }
    }
  }
  return null;
};
