import React, { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Divider } from 'antd';
import { CaseSummaryType, Pathway345 } from '../../../../generated';

const ReviewEducationPropTypes = {};

export interface ComponentProps {
  caseSummary: CaseSummaryType;
  pathway345: Pathway345;
}

export type ReviewEducationProps = InferProps<typeof ReviewEducationPropTypes> & ComponentProps;

//Q-F
const ReviewEducationInformationV6: FC<ReviewEducationProps> = ({ pathway345, caseSummary }) => {
  var userResponse = pathway345?.userResponse;
  var applicantProvidedData = pathway345?.applicantProvidedData as any;

  const convertPathwayNumber = (pathway: any) => {
    switch (pathway) {
      case 'PATHWAY3':
        return '3';
      case 'PATHWAY4':
        return '4';
      case 'PATHWAY5':
        return '5';
      default:
        return '';
    }
  };

  return (
    <>
      {userResponse ? (
        <div>
          <Divider plain orientation="left">
            <b>Pathway 3, 4, or 5</b>
          </Divider>

          {userResponse.isGradSchoolEligible ? (
            <div>
              My medical school is eligible for Pathway{' '}
              {convertPathwayNumber(caseSummary?.currentPathway)} and my graduation date is on or
              after January 1, 2023.
              <br />
              <br />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default {
  Component: ReviewEducationInformationV6,
};
