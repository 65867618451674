import { FC } from 'react';
import { MiniCexResponseType, PhysicianResponseType } from '../../../../../generated';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { getPhysicianName, PATHWAY6_DATE_FORMAT } from '../../../../constants';
import moment from 'moment';
import { MiniCEXAttestationPDFProps } from './pathway6-attestation-pdf-factory';

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 15,
    margin: 2,
  },

  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 18,
    marginBottom: 15,
  },

  sectionTitle: { fontFamily: 'Helvetica-Bold', fontSize: 14 },
  sectionSubTitle: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
    marginBottom: 5,
  },

  horizontalLine: {
    borderTop: 1,
    borderColor: 'lightgrey',
    height: 1,
    marginBottom: 5,
    marginTop: 2,
  },

  label: {
    fontFamily: 'Times-Roman',
    fontSize: 8,
  },

  content: {
    fontSize: 12,
  },

  subContent: {
    fontSize: 12,
    paddingLeft: 20,
    paddingBottom: 5,
    paddingTop: 5,
  },

  table: {
    display: 'flex',
    width: '100%',
    marginBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
  },

  tableGreen: {
    display: 'flex',
    width: '100%',
    marginBottom: 5,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#dff0d8',
  },

  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    paddingLeft: 10,
    marginBottom: 10,
  },

  tableCell: {
    fontSize: 10,
  },

  // tableCellRed: {
  //   padding: '5px',
  //   fontSize: 12,
  //   backgroundColor: '#f2dede',
  // },

  hyperlink: {
    color: 'blue',
    textDecoration: 'underline',
  },
});

const Section: FC<any> = (props) => {
  return (
    <>
      <Text style={styles.sectionTitle}>{props.title}</Text>
      <View style={styles.horizontalLine}></View>
    </>
  );
};

const InfoGroup: FC<any> = (props) => {
  return (
    <>
      <Text style={styles.label}>{props.label}</Text>
      <Text style={styles.content}>{props.content}</Text>
    </>
  );
};

const Pathway6AttestationPdfV3: FC<MiniCEXAttestationPDFProps> = ({
  readOnlyMiniCEXData,
  currentPhysicianData,
}) => {
  return (
    <Document
      title={`Mini-CEX Evaluation Attestation-${readOnlyMiniCEXData?.summary?.encounterId}`}
    >
      <Page size="A4" style={styles.body}>
        <Text style={styles.title}>Mini-CEX Evaluation Attestation</Text>
        <Section title={'Applicant Details'} />
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={{ width: '60%' }}>
              <View style={styles.tableCell}>
                <InfoGroup
                  label={'Applicant: '}
                  content={`${readOnlyMiniCEXData?.summary?.applicantLastName}, ${readOnlyMiniCEXData?.summary?.applicantRestOfName}`}
                />
              </View>
            </View>
            <View style={{ width: '40%' }}>
              <View style={styles.tableCell}>
                <InfoGroup
                  label={'Encounter ID: '}
                  content={readOnlyMiniCEXData?.summary?.encounterId}
                />
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '60%' }}>
              <View style={styles.tableCell}>
                <InfoGroup label={'USMLE ID: '} content={readOnlyMiniCEXData?.summary?.usmleId} />
              </View>
            </View>
            <View style={{ width: '40%' }}>
              <View style={styles.tableCell}>
                <InfoGroup
                  label={'Date Requested: '}
                  content={moment(readOnlyMiniCEXData?.summary?.createdAt)
                    .utc()
                    .format(PATHWAY6_DATE_FORMAT)}
                />
              </View>
            </View>
          </View>
        </View>
        <Section title={'Physician Evaluator Details'} />
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={{ width: '60%' }}>
              <View style={styles.tableCell}>
                <InfoGroup
                  label={'Name:'}
                  content={getPhysicianName(
                    currentPhysicianData?.profile?.lastName,
                    currentPhysicianData?.profile?.restOfName
                  )}
                />
              </View>
            </View>

            <View style={{ width: '40%' }}>
              <View style={styles.tableCell}>
                <InfoGroup label={'Primary E-mail Address:'} content={currentPhysicianData?.email} />
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '100%' }}>
              <View style={styles.tableCell}>
                <InfoGroup
                  label={'Date of Birth:'}
                  content={
                    currentPhysicianData?.profile?.dateOfBirth
                      ? moment(currentPhysicianData?.profile?.dateOfBirth).format(
                          PATHWAY6_DATE_FORMAT
                        )
                      : 'N/A'
                  }
                />
              </View>
            </View>
          </View>
        </View>
        <Section title={'Mini-CEX Attestation by Physician Evaluator'} />
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={{ width: '100%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  I have agreed to observe and evaluate the above-named applicant in a clinical
                  encounter and provide this evaluation to the Educational Commission for Foreign
                  Medical Graduates (ECFMG), a division of Intealth, using ECFMG's Mini-Clinical Evaluation Exercise
                  (Mini-CEX). I certify that:
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • The information I have provided is true and correct.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • I currently hold a full, unrestricted license to practice medicine without
                  supervision in the jurisdiction where this clinical encounter took place.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • I have held a full, unrestricted license to practice medicine without
                  supervision for a minimum of five (5) years.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • I have reviewed the{' '}
                  <a
                    style={styles.hyperlink}
                    href={'https://www.ecfmg.org/annc/privacy.html'}
                    rel={'noreferrer'}
                    target={'_blank'}
                  >
                    Intealth Privacy Notice.
                  </a>{' '}
                  I accept the terms of the Intealth Privacy Notice and consent to the collection and
                  processing of my personal information to assist ECFMG in making determinations of
                  applicant eligibility for ECFMG Certification.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • I consent to participate in this process and for ECFMG to use my contact
                  information for the purpose of contacting me about the Mini-CEX and this
                  evaluation.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • I have not received and will not receive compensation to complete this
                  evaluation from the above-named applicant.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • I have not received and will not receive compensation to complete this
                  evaluation from any third party other than what is provided as my salary or wage
                  from the institution where I am employed and where the clinical encounter took
                  place.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • This applicant is not my relative. For purposes of this evaluation, “relative”
                  is defined as a spouse, child, grandchild, parent, grandparent, sibling, uncle,
                  aunt, nephew, niece, and/or cousin.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • I have directly observed this individual in a clinical encounter and am
                  providing an accurate assessment of this individual's clinical skills to ECFMG, as
                  evaluated using ECFMG's Mini-CEX for Pathway 6.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • The encounter was a real, in-person clinical encounter. I understand that
                  telemedicine, virtual, and standardized patient encounters are not acceptable.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • The encounter took place in a formal clinical setting, such as an outpatient
                  office, an urgent care facility, an emergency room, or a hospital.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • The encounter was primary care/general practice in nature and not
                  subspecialized.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • The encounter focused on the diagnosis and treatment of acute and/or chronic
                  illness(es) in which each of the four components of the Mini-CEX (Medical
                  Interviewing Skills, Physical Examination Skills, Professionalism/Communication
                  Skills, Clinical Reasoning & Judgment) were assessed.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • I understand that the responses I provide via the Mini-CEX will be used to
                  evaluate this individual's application to meet the clinical and communication
                  skills requirements for ECFMG Certification for the purpose of entering graduate
                  medical education (GME) in the United States.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • ECFMG may contact me to confirm or clarify any information provided through this
                  Mini-CEX evaluation.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: '80%' }}>
              <View style={styles.tableCell}>
                <Text style={styles.content}>
                  • I have taken any and all steps necessary to ensure that the clinical encounter
                  was conducted in compliance with all applicable licensure, regulatory, and/or
                  legal requirements.
                </Text>
              </View>
            </View>
          </View>
          <Section title={'Physician Response'} />
          <View style={styles.tableGreen}>
            <View style={styles.tableRow}>
              <View style={{ width: '100%' }}>
                <View style={styles.tableCell}>
                  <Text style={styles.content}>
                    I certify that I have read, understand, and agree to the statements in this
                    Mini-CEX Attestation by Physician Evaluator.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ width: '90%' }}>
                <View style={styles.tableCell}>
                  <Text style={styles.content}>
                    • I attest that I am completing an evaluation of a clinical encounter for the
                    applicant listed above. This applicant is either previously known to me or I
                    have confirmed this applicant’s identity by reviewing his/her valid photo
                    identification prior to the encounter.
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={{ width: '90%' }}>
                <View style={styles.tableCell}>
                  <Text style={styles.content}>
                    • Has the applicant enrolled in a clinical rotation with you or your group now
                    or in the past?{' '}
                    {readOnlyMiniCEXData?.form?.attestation?.hasApplicantEnrolledInClinicalRotation}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <Section title="" />
          {readOnlyMiniCEXData?.summary?.acceptedAt ? (
            <View style={{ marginTop: 10, marginBottom: 5, marginLeft: 20 }}>
              <InfoGroup
                label={'Request Accepted On:'}
                content={moment(readOnlyMiniCEXData?.summary?.acceptedAt)
                  .utc()
                  .format(PATHWAY6_DATE_FORMAT)}
              />
            </View>
          ) : (
            <></>
          )}
          {readOnlyMiniCEXData?.summary?.completedAt ? (
            <View style={{ marginTop: 10, marginLeft: 20 }}>
              <InfoGroup
                label={'Request Completed On:'}
                content={moment(readOnlyMiniCEXData?.summary?.completedAt)
                  .utc()
                  .format(PATHWAY6_DATE_FORMAT)}
              />
            </View>
          ) : (
            <></>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default Pathway6AttestationPdfV3;
