import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ArchivedCaseReviewsType, Pathway1ArchivedCaseReviewContainerArchivedCaseDocument, UiPortalConfigType } from '../../../../generated';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';
import { Pathway1ArchivedCaseReview } from './pathway1-archived-case-review';
import { useQuery } from '@apollo/client';

const Pathway1ArchivedCaseReviewContainer: FC<any> = () => {
  const { caseId } = useParams();

    const {
      loading: caseLoading,
      error: caseError,
      data: caseData,
    } = useQuery(Pathway1ArchivedCaseReviewContainerArchivedCaseDocument, {
      variables: {
        caseId: caseId ?? ''
      },
    });

  
  return (
    <ComponentQueryLoader
      loading={caseLoading}
      hasData={caseData?.archivedCase !== undefined}
      hasDataComponent={
        <Pathway1ArchivedCaseReview data={caseData?.archivedCase?.reviews as ArchivedCaseReviewsType} uiConfig= {caseData?.archivedCase?.uiConfig as UiPortalConfigType}/>
      }
      error={caseError}
    />
  );
};

export default Pathway1ArchivedCaseReviewContainer;
