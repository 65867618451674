import { UiPortalConfigType } from '../../../../../generated';
import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';
import {
  PathwayXReviewChecklistExceptionEligibilityV1,
  PathwayXReviewChecklistExceptionEligibilityV2,
} from './pathway-x-archived-case-review-checklist-versions';

export const PathwayXArchivedCaseReviewChecklistExceptionEligibilityFactory = (
  uiConfig: UiPortalConfigType
) => {
  for (const page of uiConfig?.config?.pages || []) {
    if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
      for (const component of page?.components || []) {
        if (component?.componentName === COMPONENT_NAME.PATHWAYX_CHECKLIST) {
          switch (component?.version) {
            case 1:
              return PathwayXReviewChecklistExceptionEligibilityV1;
            case 2:
            default:
              return PathwayXReviewChecklistExceptionEligibilityV2;
          }
        }
      }
    }
  }
  return null;
};
