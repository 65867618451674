export const ERROR_MESSAGES = {
  MINICEX_ASSESSMENT_ALREADY_COMPLETED: 'MINICEX_ASSESSMENT_ALREADY_COMPLETED',
  CASE_NOT_IN_REVIEW_STATE: 'CASE_NOT_IN_REVIEW_STATE',
  NOT_ASSIGNED_CASE_MANAGER: 'NOT_ASSIGNED_CASE_MANAGER',
};

export const ERROR_MESSAGES_MAP = [
  {
    key: 'MINICEX_ASSESSMENT_ALREADY_COMPLETED',
    value:
      'All Mini-CEX are complete for this applicant and the scoring algorithm has run. Are you sure you wish to remove this encounter?',
  },
  {
    key: 'CASE_NOT_IN_REVIEW_STATE',
    value: 'This case is not currently in Eligibility Review status',
  },
  {
    key: 'NOT_ASSIGNED_CASE_MANAGER',
    value: 'This case is not assigned to you',
  },
];
