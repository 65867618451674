import { FC } from 'react';
import { ArchivedCasePathway2ReviewType } from '../../../../generated';
import { Card, Descriptions } from 'antd';
import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';
import { ArchivedCaseDocument } from '../../shared-components/archived-case-document';

interface Pathway2ArchivedCaseInfoCardProps {
  data: ArchivedCasePathway2ReviewType;
}
export const Pathway2ArchivedCaseInfoCard: FC<Pathway2ArchivedCaseInfoCardProps> = (props) => {

  const documentContent = props.data.data?.documents?.map((document: any) => (
    <div key={document.docId} style={{marginBottom: 10}}>
      <ArchivedCaseDocument title={document.title} docId={document.docId} />
    </div>
  ));

  const getExamDateOrGraduationYear = () => {
    if (props?.data?.data?.examDate) {
      if (props?.data?.data?.examDate.length === 4) {
        return props?.data?.data?.examDate;
      }
      return dayjs(props?.data?.data?.examDate).format('MM/DD/YYYY');
    }
    return 'N/A';
  };

  return (
    <>
      <Card
        style={{
          width: '45%',
          height: '50%',
          borderRadius: '10px',
          border: '1px solid var(--Neutral-4, #F0F0F0)',
          background: 'var(--Neutral-1, #FFF)',
          display: 'block',
        }}
        title={
          <Title level={5} style={{ fontWeight: 'bold' }}>
            CS Exam Data
          </Title>
        }
      >
        <Descriptions
          className={'ecfmg-small-descriptions'}
          layout="vertical"
          bordered={false}
          size="small"
          column={3}
          style={{ paddingBottom: '20px' }}
        >
          <Descriptions.Item label="School Name">
            {props?.data?.data?.schoolRef?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Exam Date/Graduation Year">
            {getExamDateOrGraduationYear()}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Graduation Year">{'missing'}</Descriptions.Item> */}
          <Descriptions.Item label="OSCE Exam">
            {props?.data?.data?.oscExamRef?.examFullName}
          </Descriptions.Item>
        </Descriptions>
        <div>{documentContent}</div>
      </Card>
    </>
  );
};
