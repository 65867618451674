import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { case_ } from '../schema/casePortal-case';
import { CaseMgmtRedirectOetCasePortalDocument } from '../../generated';

export interface ComponentProp {
  casePortal: {
    case: case_;
  };
}

type paramType = {
  usmleId: string;
};

const RedirectOET: FC<any> = () => {
  let { usmleId } = useParams<paramType>();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(CaseMgmtRedirectOetCasePortalDocument, {
    variables: {
      usmleId: usmleId,
    },
  });

  if (error) {
    console.log('WE GOT AN ERROR', error);
  }

  if (data) {
    var applicantCase = data.casePortal?.case;
    var useApplicantProvidedData = applicantCase?.caseSummary?.currentPathway;
    navigate(
      `/case-management/case-detail/${applicantCase?.caseSummary?.usmleId}/oet/${
        (useApplicantProvidedData
          ? applicantCase?.languageAssessment?.applicantProvidedData?.[0]?._id
          : applicantCase?.languageAssessment?.staffProvidedData?.[0]?._id) ?? 'OveridePath'
      }`
    );
  }
  if(loading) {
    return <Spin></Spin>
  }

  return <div></div>;
};

export default {
  Component: RedirectOET,
};
