import { Table, Tabs } from 'antd';
import { FC, Key, useState } from 'react';
import { ArchivedCaseOetReviewType, ArchivedCaseReviewsType, UiPortalConfigType } from '../../../../generated';
import ArchivedCaseOetReview from '../../shared-components/oet/archived-case-oet-review';
import { Pathway2ArchivedCaseInfoCard } from './pathway2-archived-case-info-card';
import { Pathway2ArchivedCaseChecklist } from './pathway2-archived-case-checklist';

const TabPaneName = {
  Pathway2: 'Pathway 2',
  OET: 'OET',
};

interface Pathway2ArchivedCaseReviewProps {
  data: ArchivedCaseReviewsType;
  uiConfig: UiPortalConfigType
}
export const Pathway2ArchivedCaseReview: FC<Pathway2ArchivedCaseReviewProps> = (props) => {
  const [currentPathwayLicense, setCurrentPathwayLicense] = useState(
    props.data.pathway2 ? props.data.pathway2[0] : null
  );
  const [currentRowKeys, setCurrentRowKeys] = useState([currentPathwayLicense?.summary?._id]);

  const rowSelection = {
    selectedRowKeys: currentRowKeys as Key[],
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setCurrentRowKeys(selectedRowKeys);
      setCurrentPathwayLicense(selectedRows[0]);
    },
  };

  const tableData = props?.data?.pathway2?.map((entry: any) => ({
    key: entry?.summary?._id,
    title: entry?.summary?.title,
    status: entry?.summary?.status,
    name: entry?.summary?.updatedBy?.name
  }));

  const pathwayTableColumns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'name',
    },
    {
      title: 'Last Reviewed By',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'name',
    },
  ];

  const pathway2Tab = (
    <Tabs.TabPane tab={TabPaneName.Pathway2} key={TabPaneName.Pathway2}>
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        columns={pathwayTableColumns}
        dataSource={tableData}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Pathway2ArchivedCaseChecklist data={currentPathwayLicense ?? {}} uiConfig={props.uiConfig}/>
        <Pathway2ArchivedCaseInfoCard data={currentPathwayLicense ?? {}} />
      </div>
    </Tabs.TabPane>
  );

  const OetTab = (
    <Tabs.TabPane tab={TabPaneName.OET} key={TabPaneName.OET}>
      <ArchivedCaseOetReview data={props.data.oet as ArchivedCaseOetReviewType[]} />
    </Tabs.TabPane>
  );

  return (
    <>
      <Tabs defaultActiveKey="1">
        {pathway2Tab}
        {OetTab}
      </Tabs>
    </>
  );
};
