import {
  DownloadOutlined,
  RedoOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import React, { FC, useState } from 'react';

interface ViewPDFToolBarProps {
  filePath: string;
  filename: string;
  blobData?: Blob;
  scale: number;
  rotateAngle: number;
  onScaleChangedCallback: (scale: number) => void;
  onRotationChangedCallback: (rotateAngle: number) => void;
}

const ViewPDFToolBar: FC<ViewPDFToolBarProps> = ({
  filePath,
  filename,
  blobData,
  scale,
  rotateAngle,
  onScaleChangedCallback,
  onRotationChangedCallback,
}) => {
  const [curScale, setCurScale] = useState(scale);
  const [curRotateAngle, setCurRotateAngle] = useState(rotateAngle);
  const downloadToLocal = async () => {
    var blobObject = undefined;
    var blob = undefined;
    if (blobData) {
      blobObject = blobData;
    } else {
      blob = await fetch(filePath).then((r) => r.blob());
      blobObject = new Blob([blob], { type: 'application/pdf' });
    }

    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blobObject);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onZooming = (s: number) => {
    if (s >= 0.75 && s <= 2) {
      // scale range: 75% ~ 200%
      setCurScale(s);
      onScaleChangedCallback(s);
    }
  };

  const resetZoom = () => {
    setCurScale(1);
    onScaleChangedCallback(1);
  };

  const onRotate = (angle: number) => {
    setCurRotateAngle(angle % 360);
    onRotationChangedCallback(angle % 360);
  };

  const resetRotation = () => {
    setCurRotateAngle(0);
    onRotationChangedCallback(0);
  };

  return (
    <div style={{ backgroundColor: 'black', padding: 5, width: '100%' }}>
      <div style={{ textAlign: 'center' }}>
        <Space size="large">
          {/* page  */}
          {/* zoom */}
          <Space>
            <Tooltip title="Zoom Out">
              <Button
                icon={<ZoomOutOutlined />}
                onClick={() => onZooming(scale - 0.25)}
                disabled={curScale === 0.5}
              />
            </Tooltip>
            <Popconfirm title="Reset Zoom" okText="Reset" onConfirm={() => resetZoom()}>
              <Button style={{ color: 'black' }}>{`${curScale * 100}%`}</Button>
            </Popconfirm>
            <Tooltip title="Zoom In">
              <Button
                icon={<ZoomInOutlined />}
                onClick={() => onZooming(scale + 0.25)}
                disabled={curScale === 2}
              />
            </Tooltip>
          </Space>
          {/* rotate */}
          <Space>
            <Tooltip title="Rotate Left">
              <Button icon={<UndoOutlined />} onClick={() => onRotate(curRotateAngle - 90)} />
            </Tooltip>
            <Popconfirm title="Reset Rotation" okText="Reset" onConfirm={() => resetRotation()}>
              <Button style={{ color: 'black', width: 50 }}>{`  ${curRotateAngle}  `}</Button>
            </Popconfirm>
            <Tooltip title="Rotate Right">
              <Button icon={<RedoOutlined />} onClick={() => onRotate(curRotateAngle + 90)} />
            </Tooltip>
          </Space>
          {/* download */}
          <Tooltip title="Download Document">
            <Button onClick={downloadToLocal}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
        </Space>
      </div>
    </div>
  );
};

export default ViewPDFToolBar;
