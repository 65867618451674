import { Descriptions, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { ArchivedCaseType } from '../../../../../generated';
import { ArchivedCaseAttestationSummaryButton } from '../../../shared-components/archived-case-attestation-summary-button';

interface Pathway345ArchiveCaseAssessmentsEMSWPProps {
  archivedCaseData: ArchivedCaseType;
}
export const Pathway345ArchiveCaseAssessmentsEMSWP: FC<Pathway345ArchiveCaseAssessmentsEMSWPProps> = (
  props
) => {
  return (
    <>
      <Descriptions
        column={props.archivedCaseData?.assessments?.emswp?.output === 'Rejected' ? 4 : 3}
        layout="vertical"
        style={{ marginLeft: '10px' }}
        labelStyle={{ fontSize: '10px' }}
      >
        <Descriptions.Item label="Status" style={{ paddingBottom: '5px' }}>
          <Typography.Text>{props.archivedCaseData?.assessments?.emswp?.output}</Typography.Text>
        </Descriptions.Item>
        {props.archivedCaseData?.assessments?.emswp?.output === 'Rejected' && (
          <Descriptions.Item label="Reason" style={{ paddingBottom: '5px' }}>
            <Typography.Text>
              {props.archivedCaseData?.assessments?.emswp?.outputReason}
            </Typography.Text>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Attested At" style={{ paddingBottom: '5px' }}>
          <Typography.Text>
            {props.archivedCaseData?.assessments?.emswp?.updatedTimestamp
              ? dayjs(props.archivedCaseData?.assessments?.emswp?.updatedTimestamp).format(
                  'MM/DD/YYYY hh:mm:ss A'
                )
              : 'N/A'}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Attested By" style={{ paddingBottom: '5px' }}>
          <Typography.Text>
            {props.archivedCaseData?.assessments?.emswp?.updatedBy?.name}
          </Typography.Text>
        </Descriptions.Item>
      </Descriptions>

      {props.archivedCaseData?.assessments?.emswp?.output && (
        <Row justify="end">
          <ArchivedCaseAttestationSummaryButton data={props.archivedCaseData} />
        </Row>
      )}
    </>
  );
};
