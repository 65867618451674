import { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  CaseSummaryType,
  C_ApplicantType,
  UiPageConfigType,
  UiPortalConfigType,
} from '../../../../../generated';
import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';
import OetdocumentReviewChecklistV2 from './checklist-versions/oet-document-review-checklist-v2';
import OetdocumentReviewChecklistV1 from './checklist-versions/oet-document-review-checklist-v1';
import OetdocumentReviewChecklistV3 from './checklist-versions/oet-document-review-checklist-v3';
import OetdocumentReviewChecklistV4 from './checklist-versions/oet-document-review-checklist-v4';

const OETDocumentReviewChecklistPropsTypes = {
  checklist: PropTypes.shape({
    licenseStatus: PropTypes.string,
    scoreConfirmed: PropTypes.string,
    scoreAcceptable: PropTypes.string,
    examDateValidation: PropTypes.string,
    nameValid: PropTypes.string,
    documentCompleteLegible: PropTypes.string,
  }).isRequired,
  checklistState: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  parentDataVersion: PropTypes.number,
  onUpdateDataVersion: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  checklistStatus: PropTypes.string.isRequired,
};

export interface ComponentProps {
  versionConfig: UiPortalConfigType;
  caseSummary: CaseSummaryType;
  applicant: C_ApplicantType;
}

export type OETDocumentReviewChecklistProps = InferProps<
  typeof OETDocumentReviewChecklistPropsTypes & ComponentProps
>;

const OetdocumentReviewChecklistFactory: FC<OETDocumentReviewChecklistProps> = ({
  versionConfig,
  checklist,
  caseSummary,
  checklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  applicant,
  redirect,
  checklistStatus,
}) => {
  const getOETChecklist = () => {
    var OETChecklist;
    versionConfig?.config?.pages.map((page: UiPageConfigType) => {
      if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
        page?.components?.map((component) => {
          if (component?.componentName === COMPONENT_NAME.OET_CHECKLIST) {
            switch (component.version) {
              case 1:
                OETChecklist = (
                  <OetdocumentReviewChecklistV1
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => onUpdateDataVersion(dataVersion)}
                    applicant={applicant}
                    redirect={(url: string) => redirect(url)}
                    checklistStatus={checklistStatus}
                  />
                );
                break;
              case 2:
                //return the latest version by default
                OETChecklist = (
                  <OetdocumentReviewChecklistV2
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => onUpdateDataVersion(dataVersion)}
                    applicant={applicant}
                    redirect={(url: string) => redirect(url)}
                    checklistStatus={checklistStatus}
                  />
                );
                break;
              case 3:
                OETChecklist = (
                  <OetdocumentReviewChecklistV3
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => onUpdateDataVersion(dataVersion)}
                    applicant={applicant}
                    redirect={(url: string) => redirect(url)}
                    checklistStatus={checklistStatus}
                  />
                );
                break;

                case 4:
                default:
                  //return the latest version by default
                  OETChecklist = (
                    <OetdocumentReviewChecklistV4
                      caseSummary={caseSummary}
                      checklist={checklist}
                      checklistState={checklistState}
                      recordId={recordId}
                      parentDataVersion={parentDataVersion}
                      onUpdateDataVersion={(dataVersion: number) => onUpdateDataVersion(dataVersion)}
                      applicant={applicant}
                      redirect={(url: string) => redirect(url)}
                      checklistStatus={checklistStatus}
                    />
                  );
                  break;
            }
          }
        });
      }
    });
    if (OETChecklist === null || typeof OETChecklist === 'undefined') {
      OETChecklist = (
        <OetdocumentReviewChecklistV1
          caseSummary={caseSummary}
          checklist={checklist}
          checklistState={checklistState}
          recordId={recordId}
          parentDataVersion={parentDataVersion}
          onUpdateDataVersion={(dataVersion: number) => onUpdateDataVersion(dataVersion)}
          applicant={applicant}
          redirect={(url: string) => redirect(url)}
          checklistStatus={checklistStatus}
        />
      );
    }
    return OETChecklist;
  };

  return <div>{getOETChecklist()}</div>;
};

export default OetdocumentReviewChecklistFactory;
