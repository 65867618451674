import PropTypes, { InferProps } from 'prop-types';
import React, { FC, useState } from 'react';
import { Button, Upload, Input, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import { CaseMgmtUploadDocumentUploadDocumentDocument } from '../../../../generated';

const uploadDocumentPropsTypes = {
  onUploadSuccessCallback: PropTypes.func,
  getGuid: PropTypes.func.isRequired,
  GuidValue: PropTypes.string,
  showIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  uploadedFileName: PropTypes.string,
  label: PropTypes.string,
  updateIsUploadDone: PropTypes.func,
};

export type UploadDocumentProps = InferProps<typeof uploadDocumentPropsTypes>;

export const UploadDocument: FC<UploadDocumentProps> = ({
  onUploadSuccessCallback,
  getGuid,
  disabled,
  showIcon,
  uploadedFileName,
  label,
  updateIsUploadDone,
}) => {
  const [uploadDocument] = useMutation(CaseMgmtUploadDocumentUploadDocumentDocument);
  const [isProcessingUpload, setIsProcessingUpload] = useState(false);
  const [isUploadDone, setIsUploadDone] = useState(false);
  const [isUploadFailed, setisUploadFailed] = useState(false);
  async function beforeUpload(file: any) {
    setIsProcessingUpload(true);
    setIsUploadDone(false);
    const documentName = file.name;

    //TODO: apollo call to update database -> filename, documentType, DocID (GUID)
    //Returns the blobName
    var documentGuid = await getGuid(documentName);

    //Generate SAS with blobName
    const results = await uploadDocument({
      variables: {
        fileName: documentGuid,
      },
    })
      .then(async (res) => {
        console.log(res);
        const sasToken = res?.data?.uploadDocument?.token?.uri;
        uploadToAzure(file, sasToken);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error[0]?.message;
        message.error(errorMessage);
        setisUploadFailed(true);
        setIsProcessingUpload(false);
        setIsUploadDone(true);
      });
  }

  const uploadProps = {
    showUploadList: false,
    beforeUpload(file: any, fileList: any) {
      console.log('BEFORE UPLOAD');
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        message.error('File must smaller than 10MB');
        return false;
      }
      if (file.type !== 'application/pdf') {
        message.error('File must be a pdf');
        return false;
      }
      //overloaded facade since UPLOAD cannot take async functions
      beforeUpload(file);

      return false;
    },
    accept: '.pdf',
  };

  const uploadToAzure = async (file: any, SASToken: any) => {
    await axios
      .put(SASToken, new Blob([file], { type: 'application/pdf' }), {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
      })
      .then((result: any) => {
        if (result.status === 201) {
          setisUploadFailed(false);
        } else {
          setisUploadFailed(true);
        }
        setIsProcessingUpload(false);
        setIsUploadDone(true);
        if (updateIsUploadDone) {
          updateIsUploadDone(true);
        }
        if (onUploadSuccessCallback) {
          onUploadSuccessCallback(file.name, SASToken);
        }
        message.success('Upload successfully!');
      })
      .catch((error) => {
        message.error(error.toString());
        setisUploadFailed(true);
        setIsProcessingUpload(false);
        setIsUploadDone(true);
      });
  };

  return (
    <>
      <Upload {...uploadProps} accept={'application/pdf'}>
        <div style={{ display: 'inline' }}>
          {uploadedFileName !== '' ? (
            <Input
              type="text"
              value={uploadedFileName!}
              readOnly
              disabled={disabled || isProcessingUpload ? true : false}
            />
          ) : (
            <></>
          )}
          <Button
            loading={isProcessingUpload}
            type="primary"
            disabled={disabled || isProcessingUpload ? true : false}
          >
            {showIcon ? <UploadOutlined /> : ''}
            {label ? label : ''}
          </Button>
        </div>
      </Upload>
    </>
  );
};

UploadDocument.defaultProps = {
  onUploadSuccessCallback: () => {},
  getGuid: () => {},
  showIcon: true,
  disabled: false,
  uploadedFileName: '',
  label: '',
};
