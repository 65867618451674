import React, { FC, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Modal, Card, Descriptions, Button, Divider, Typography } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import moment from 'moment';
import { LICENSURE_DOCUMENT_STATUS } from '../../../constants';
import { useFeatureFlags } from '../../../feature-flag-react-lite';
import ViewPDFWrapper from '../../../ViewPDF-wrapper';
const { Title } = Typography;

const ReviewLicensePropTypes = {
  pathway1: PropTypes.shape({
    userResponse: PropTypes.shape({
      isCurrentlyLicensed: PropTypes.bool,
      wasLicensedInRecentPast: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.shape({
      data: PropTypes.shape({
        licensureDocumentStatus: PropTypes.string,
        licenseIssueDate: PropTypes.string,
        licenseExpiryDate: PropTypes.string,
        isCurrentlyLicensed: PropTypes.bool,
        hasDisciplinaryAction: PropTypes.bool,
        regulatoryAuthorityRef: PropTypes.shape({
          _id: PropTypes.number,
          orgName: PropTypes.string,
          countryName: PropTypes.string,
        }),
        documents: PropTypes.shape({
          title: PropTypes.string,
          docId: PropTypes.string,
        }),
      }),
    }),
  }),
};

export type ReviewLicenseProps = InferProps<typeof ReviewLicensePropTypes>;

//Q-A, Q-B
const ReviewLicenseInformationV6: FC<ReviewLicenseProps> = ({ pathway1 }) => {
  const [showPreviewPdfModal, setShowPreviewPdfModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState('');
  const supportingData = pathway1?.applicantProvidedData as any;
  const userResponse = pathway1?.userResponse;

  var questionList = <div></div>;
  var licenseList = <div></div>;
  function capitalizeFirstLetterEachWord(str: string) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
  }

  const { GetFeatureFlagByName } = useFeatureFlags();

  const parseLicensureDocStatus = (status: string) => {
    return LICENSURE_DOCUMENT_STATUS.find((element) => element.key === status)?.value ?? '';
  };

  //Check user responses
  if (userResponse !== undefined && userResponse !== null) {
    if (userResponse.isCurrentlyLicensed) {
      //Pathway 1a
      questionList = (
        <div>
          Do you currently hold a license/registration (full or provisional) to practice medicine
          without supervision in any country/jurisdiction?{' '}
          <b>{userResponse.isCurrentlyLicensed ? 'Yes' : 'No'}</b>
        </div>
      );
    } else {
      //Pathway 1b
      questionList = (
        <div>
          <div>
            Do you currently hold a license/registration (full or provisional) to practice medicine
            without supervision in any country/jurisdiction?{' '}
            <b>{userResponse.isCurrentlyLicensed ? 'Yes' : 'No'}</b>
          </div>
          <div>
            Have you held a license/registration (full or provisional) to practice medicine without
            supervision in any country/jurisdiction at any time on or after January 1, 2020?{' '}
            <b>{userResponse.wasLicensedInRecentPast ? 'Yes' : 'No'}</b>
          </div>
        </div>
      );
    }
  }

  //Get all license data
  var licenseNum = 1;
  if (
    supportingData !== undefined &&
    (userResponse?.isCurrentlyLicensed || userResponse?.wasLicensedInRecentPast)
  ) {
    licenseList = supportingData.map((licenseData: any) => {
      var licensureDocStatus = parseLicensureDocStatus(licenseData.data.licensureDocumentStatus);
      var docList = <div></div>;

      //Did the applicant upload documents?
      if (licenseData.data.documents.length > 0) {
        docList = licenseData.data.documents.map((document: any) => {
          if (document.title) {
            //Check that title is not null
            return (
              <div key={document.docId}>
                License/Registration: &nbsp;{' '}
                <Button
                  icon={<SearchOutlined />}
                  shape="round"
                  size="small"
                  onClick={async () => {
                    setSelectedPdf(document.docId);
                    await previewDocument();
                  }}
                >
                  {document.title}
                </Button>
                <br />
                <br />
                <Modal
                  title={document?.title}
                  visible={showPreviewPdfModal && selectedPdf === document.docId}
                  width="fit-content"
                  closable
                  bodyStyle={{ minWidth: '20vw', maxHeight:'80vh' }}
                  okText="Continue"
                  footer={null}
                  onCancel={() => viewPdfClosed()}
                >
                  {showPreviewPdfModal && selectedPdf === document.docId ? (
                    <ViewPDFWrapper
                      filename={document.title}
                      docId={document.docId}
                    ></ViewPDFWrapper>
                  ) : (
                    ''
                  )}
                </Modal>
              </div>
            );
          } else {
            return <div></div>;
          }
        });
      }

      return (
        <>
          <Card size="small">
            <Title level={4}>License {licenseNum++}</Title>
            <Descriptions
              className={'ecfmg-small-descriptions'}
              layout={'vertical'}
              bordered={false}
              column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
              size="small"
            >
              <Descriptions.Item label="License Issue Date">
                {licenseData.data.licenseIssueDate
                  ? GetFeatureFlagByName('LicenseDateTimeConversionFix') === 'true'
                    ? moment(licenseData.data.licenseIssueDate.substring(0, 10)).format(
                        'MM/DD/YYYY'
                      )
                    : moment(licenseData.data.licenseIssueDate).format('MM/DD/YYYY')
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="License Expiry Date">
                {licenseData.data.licenseExpiryDate
                  ? GetFeatureFlagByName('LicenseDateTimeConversionFix') === 'true'
                    ? moment(licenseData.data.licenseExpiryDate.substring(0, 10)).format(
                        'MM/DD/YYYY'
                      )
                    : moment(licenseData.data.licenseExpiryDate).format('MM/DD/YYYY')
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Licensure Document Status">
                {licensureDocStatus}
              </Descriptions.Item>
              <Descriptions.Item label="Currently Licensed">
                {licenseData.data.isCurrentlyLicensed ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label="Disciplinary Action">
                {licenseData.data.hasDisciplinaryAction ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label="Regulatory Authority">
                {licenseData.data.regulatoryAuthorityRef &&
                  `${
                    licenseData.data.regulatoryAuthorityRef.orgName
                  } - ${capitalizeFirstLetterEachWord(
                    licenseData.data.regulatoryAuthorityRef.countryName
                  )}`}
              </Descriptions.Item>
            </Descriptions>
            {docList}
          </Card>
          <br />
        </>
      );
    });
  }

  const previewDocument = async () => {
    setShowPreviewPdfModal(true);
  };

  const viewPdfClosed = async () => {
    setShowPreviewPdfModal(false);
  };

  return (
    <>
      {(supportingData && supportingData.length !== 0) || userResponse ? (
        <div style={{ marginBottom: 5 }}>
          <Divider plain orientation="left">
            <b>Pathway 1</b>
          </Divider>
          {questionList}
          <br />
          {licenseList}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default {
  Component: ReviewLicenseInformationV6,
};
