import { UiPortalConfigType } from '../../../../../generated';
import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';
import {
  Pathway1ReviewChecklistVerificationV1,
  Pathway1ReviewChecklistVerificationV2,
  Pathway1ReviewChecklistVerificationV3,
  Pathway1ReviewChecklistVerificationV4,
  Pathway1ReviewChecklistVerificationV5,
} from './pathway1-archived-case-review-checklist-versions';

export const Pathway1ArchivedCaseReviewChecklistVerificationFactory = (
  uiConfig: UiPortalConfigType
) => {
  for (const page of uiConfig?.config?.pages || []) {
    if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
      for (const component of page?.components || []) {
        if (component?.componentName === COMPONENT_NAME.PATHWAY1_CHECKLIST) {
          switch (component?.version) {
            case 1:
              return Pathway1ReviewChecklistVerificationV1;
            case 2:
              return Pathway1ReviewChecklistVerificationV2;
            case 3:
              return Pathway1ReviewChecklistVerificationV3;
            case 4:
              return Pathway1ReviewChecklistVerificationV4;
            case 5:
            default:
              return Pathway1ReviewChecklistVerificationV5;
          }
        }
      }
    }
  }
  return null;
};
