import { FC } from 'react';
import { ArchivedCaseAssessmentEligibilityType } from '../../../../generated';
import { Descriptions, Divider, Typography } from 'antd';

interface ArchivedCaseAssessmentsEligibilityProps {
  data: ArchivedCaseAssessmentEligibilityType;
}
export const ArchivedCaseAssessmentsEligibility: FC<ArchivedCaseAssessmentsEligibilityProps> =
  (props) => {
    return (
      <>
        <Typography.Title level={4}>Eligibility PreChecks</Typography.Title>
        <Descriptions
          column={1}
          layout="vertical"
          style={{ marginLeft: '10px' }}
          labelStyle={{ fontSize: '10px' }}
        >
          <Descriptions.Item label="Status" style={{ paddingBottom: '5px' }}>
            <Typography.Text>{props?.data?.eligibilityPreCheck?.output}</Typography.Text>
          </Descriptions.Item>
          {props.data?.eligibilityPreCheck?.output === 'Rejected' && (
            <Descriptions.Item label="Reason" style={{ paddingBottom: '5px' }}>
              <Typography.Text>{props.data?.eligibilityPreCheck?.outputReason}</Typography.Text>
            </Descriptions.Item>
          )}
        </Descriptions>
        <Divider style={{ margin: '5px 0 10px 0 ' }} />
        <Typography.Title level={4}>General English Checks</Typography.Title>
        <Descriptions
          column={2}
          layout="vertical"
          style={{ marginLeft: '10px' }}
          labelStyle={{ fontSize: '10px' }}
        >
          <Descriptions.Item label="Status" style={{ paddingBottom: '5px' }}>
            <Typography.Text>{props.data?.generalEligibility?.output}</Typography.Text>
          </Descriptions.Item>
          {props.data?.generalEligibility?.output === 'Rejected' && (
            <Descriptions.Item label="Reason" style={{ paddingBottom: '5px' }}>
              <Typography.Text>{props.data?.generalEligibility?.outputReason}</Typography.Text>
            </Descriptions.Item>
          )}
        </Descriptions>
      </>
    );
  };
