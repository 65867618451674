import React, { FC } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Layout, message } from 'antd';
import '../../App.css';
import CaseHeader from './case-header';
import CaseLeftNav from './case-left-nav';
import CaseListLayout from './case-list/case-list-layout';
import CaseOverviewLayout from './case-overview/case-overview-layout';
import Pathway1Layout from './pathways/pathway1/pathway1-layout';
import Pathway1DocumentReviewLayout from './pathways/pathway1/pathway1-document-review/pathway1-document-review-layout';
import Pathway2DocumentReviewLayout from './pathways/pathway2/pathway2-document-review/pathway2-document-review-layout';
import Pathway3DocumentReviewLayout from './pathways/pathway3/pathway3-document-review/pathway3-document-review-layout';
import PathwayXDocumentReviewLayout from './pathways/pathwayX/pathwayX-document-review/pathwayX-document-review-layout';
import Pathway6DocumentReviewLayout from './pathways/pathway6/pathway6-document-review/pathway6-document-review-layout';
import OetdocumentReviewLayout from './pathways/oet-review/oet-document-review/oet-document-review-layout';
import FinanceSettingsLayout from '../finance-settings/finance-settings-layout';
import RedirectPathway from './redirect-pathway';
import RedirectOET from './redirect-oet';
import { useMutation } from '@apollo/client/react';
import { GetUserGroup } from '../../utils/helperFunctions';
import { validateUsmle } from '../../utils/usmleValidator';
import ImpendingMaintenance from '../maintenance/impendingMaintenance';
import Maintenance from '../maintenance/maintenance';
import LayoutPhysicianManagement from '../physician-management/layout-physician-management';
import { Helmet } from 'react-helmet';
import {
  CaseMgmtLayoutCaseManagementCheckCaseExistsDocument,
  CaseMgmtLayoutCaseManagementCreateExceptionCaseDocument,
} from '../../generated';
import FooterLayout from '../footer-layout';

const { Content, Footer } = Layout;

const LayoutCaseManagment: FC<any> = (props) => {
  const navigate = useNavigate();
  const [createExceptionCase] = useMutation(
    CaseMgmtLayoutCaseManagementCreateExceptionCaseDocument
  );
  const [checkUsmleId] = useMutation(CaseMgmtLayoutCaseManagementCheckCaseExistsDocument);

  const userGroup = GetUserGroup();

  var handleCaseLoad = async (usmleId: string) => {
    //+ makes it check if it is a number
    if (isNaN(+usmleId)) {
      message.error('USMLE ID must be a number');
    } else if (usmleId.length !== 8) {
      message.error(`Invalid USMLE ID`);
    } else if (!validateUsmle(usmleId)) {
      message.error('Invalid USMLE ID');
    } else {
      await checkUsmleId({ variables: { usmleId: usmleId } })
        .then((data: any) => {
          if (data.data.CasePortal_checkCaseExists) {
            navigate(`/case-management/case-detail/${usmleId}`);
          } else {
            message.error(`UsmleID ${usmleId} not found.`);
          }
        })
        .catch((error: any) => {
          //only grab the first error
          const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
          message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        });
    }
  };
  var handleCaseAdd = async (usmleId: string) => {
    //+ makes it check if it is a number
    if (isNaN(+usmleId)) {
      message.error('USMLE ID must be a number');
    } else if (usmleId.length !== 8) {
      message.error(`Invalid USMLE ID`);
    } else if (!validateUsmle(usmleId)) {
      message.error('Invalid USMLE ID');
    } else {
      await createExceptionCase({
        variables: {
          usmleId: usmleId,
        },
      })
        .then((data: any) => {
          navigate(`/case-management/case-detail/${usmleId}`);
        })
        .catch((error: any) => {
          //only grab the first error
          const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
          message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Case List</title>
      </Helmet>
      <Maintenance>
        <Layout className="layout">
          <CaseHeader.Component
            onRequestCaseLoad={(usmleId: string) => handleCaseLoad(usmleId)}
            onRequestCaseAdd={(usmleId: string) => handleCaseAdd(usmleId)}
          />
          <Layout hasSider>
            <CaseLeftNav />
            <Layout
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 auto',
                overflowY: 'scroll',
                height: 'calc(100vh - 64px)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 auto',
                }}
              >
                <Content
                  //className="site-layout"
                  style={{ margin: '0 16px 0', minHeight: 'inherit' }}
                >
                  <div
                    // className="site-layout-background"
                    style={{
                      // flex: '1 auto',
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100%',
                      borderRadius: '0 0 0.8rem 0.8rem',
                      background: '#FFFFFF',
                      padding: '20px',
                    }}
                  >
                    <ImpendingMaintenance ShowImpendingMessageImmediately={false}>
                      <Routes>
                        <Route path={'/'} element={<CaseListLayout />} />
                        <Route
                          path={`/case-detail/:usmleId`}
                          element={<CaseOverviewLayout.Component {...props} />}
                        />
                        <Route
                          path={`/case-detail/:usmleId/pathway`}
                          element={<RedirectPathway.Component />}
                        />
                        <Route
                          path={`/case-detail/:usmleId/oet`}
                          element={<RedirectOET.Component />}
                        />
                        <Route
                          path={`/case-detail/:usmleId/pathway-1`}
                          element={<Pathway1Layout />}
                        />
                        <Route
                          path={`/case-detail/:usmleId/pathway-1/document-review/:documentId`}
                          element={<Pathway1DocumentReviewLayout />}
                        />
                        <Route
                          path={`/case-detail/:usmleId/pathway-2/:documentId`}
                          element={<Pathway2DocumentReviewLayout />}
                        />
                        <Route
                          path={`/case-detail/:usmleId/pathway-3/:documentId`}
                          element={<Pathway3DocumentReviewLayout />}
                        />
                        <Route
                          path={`/case-detail/:usmleId/pathway-4/:documentId`}
                          element={<Pathway3DocumentReviewLayout />}
                        />
                        <Route
                          path={`/case-detail/:usmleId/pathway-5/:documentId`}
                          element={<Pathway3DocumentReviewLayout />}
                        />
                        <Route
                          path={`/case-detail/:usmleId/oet/:documentId`}
                          element={<OetdocumentReviewLayout />}
                        />
                        <Route
                          path={`/case-detail/:usmleId/pathway-X/:documentId`}
                          element={<PathwayXDocumentReviewLayout />}
                        />
                        <Route
                          path={`/case-detail/:usmleId/pathway-6/:documentId`}
                          element={<Pathway6DocumentReviewLayout />}
                        />
                        <Route
                          path={`/physician-management`}
                          element={<LayoutPhysicianManagement />}
                        />
                        <Route
                          path={`/finance-settings`}
                          element={
                            userGroup?.includes('Finance') ? <FinanceSettingsLayout /> : undefined
                          }
                        />
                      </Routes>
                    </ImpendingMaintenance>
                  </div>
                </Content>

                <FooterLayout />
              </div>
            </Layout>
          </Layout>
        </Layout>
      </Maintenance>
    </>
  );
};

export default LayoutCaseManagment;
