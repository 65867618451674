import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ArchivedCaseReviewsType, Pathway345ArchivedCaseReviewContainerArchivedCaseDocument, UiPortalConfigType } from '../../../../../generated';
import { useQuery } from '@apollo/client';
import { Pathway345ArchivedCaseReview } from './pathway345-archived-case-review';
import { ComponentQueryLoader } from '../../../../../utils/molecules/component-query-loader';

const Pathway345ArchivedCaseReviewContainer: FC<any> = () => {
  const { caseId } = useParams();

    const {
      loading: caseLoading,
      error: caseError,
      data: caseData,
    } = useQuery(Pathway345ArchivedCaseReviewContainerArchivedCaseDocument, {
      variables: {
        caseId: caseId ?? ''
      },
    });

  return (
    <ComponentQueryLoader
      loading={caseLoading}
      hasData={caseData?.archivedCase !== undefined}
      hasDataComponent={
        <Pathway345ArchivedCaseReview data={caseData?.archivedCase?.reviews as ArchivedCaseReviewsType} uiConfig= {caseData?.archivedCase?.uiConfig as UiPortalConfigType}/>
      }
      error={caseError}
    />
  );
};

export default Pathway345ArchivedCaseReviewContainer;
