import { UiPortalConfigType } from "../../../../../generated";
import { COMPONENT_NAME, PAGE_NAME } from "../../../../utils/version-mappings";
import { Pathway2ReviewChecklistOSCEV1, Pathway2ReviewChecklistOSCEV2, Pathway2ReviewChecklistOSCEV3, Pathway2ReviewChecklistOSCEV4 } from "./pathway2-archived-case-review-checklist-versions";

export const Pathway2ArchivedCaseReviewChecklistOSCEFactory = (
  uiConfig: UiPortalConfigType
) => {
  for (const page of uiConfig?.config?.pages || []) {
    if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
      for (const component of page?.components || []) {
        if (component?.componentName === COMPONENT_NAME.PATHWAY2_CHECKLIST) {
          switch (component?.version) {
            case 1:
              return Pathway2ReviewChecklistOSCEV1;
            case 2:
              return Pathway2ReviewChecklistOSCEV2;
            case 3:
              return Pathway2ReviewChecklistOSCEV3;
            case 4:
            default:
              return Pathway2ReviewChecklistOSCEV4;
          }
        }
      }
    }
  }
  return null;
};