import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  CosmosObjectIdString: any;
  Currency: any;
  DID: any;
  Date: any;
  DateString: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  SafeInt: any;
  Time: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type A_Applicant = {
  __typename?: 'A_Applicant';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  usmleId?: Maybe<Scalars['String']>;
};

export type A_CaseDetails = {
  __typename?: 'A_CaseDetails';
  applicationType?: Maybe<Scalars['String']>;
  currentPathway?: Maybe<Scalars['String']>;
  isApplicationCreationAllowed?: Maybe<Scalars['Boolean']>;
  isExceptionCase?: Maybe<Scalars['Boolean']>;
  isRequiredDataProvided?: Maybe<Scalars['Boolean']>;
  output?: Maybe<Scalars['String']>;
  outputReason?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathwaySeason?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateString']>;
  submittedPathway?: Maybe<Scalars['String']>;
};

export type A_Progress = {
  __typename?: 'A_Progress';
  englishEligibility?: Maybe<Array<Maybe<A_ProgressStatus>>>;
  generalEligibility?: Maybe<Array<Maybe<A_ProgressStatus>>>;
  pathwayEligibility?: Maybe<Array<Maybe<A_ProgressStatus>>>;
};

export type A_ProgressStatus = {
  __typename?: 'A_ProgressStatus';
  checklistRecord?: Maybe<Scalars['String']>;
  checklistState?: Maybe<Scalars['String']>;
};

export type ActivityLogType = {
  __typename?: 'ActivityLogType';
  logType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateString']>;
  user?: Maybe<ActivityLogUserType>;
};

export type ActivityLogUserType = {
  __typename?: 'ActivityLogUserType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type AddMiniCexInput = {
  physicianEmail: Scalars['String'];
  physicianLastNameProvidedByApplicant: Scalars['String'];
  physicianRestOfNameProvidedByApplicant: Scalars['String'];
};

export type AdditionalDocument = {
  __typename?: 'AdditionalDocument';
  document?: Maybe<Document>;
  notes?: Maybe<Scalars['String']>;
};

export type AdditionalDocumentInput = {
  document?: InputMaybe<DocumentInput>;
  notes?: InputMaybe<Scalars['String']>;
};

export type ApplicantLoginPayloadType = {
  __typename?: 'ApplicantLoginPayloadType';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  usmleId: Scalars['String'];
};

export type ApplicantPortalLoginResponseType = {
  __typename?: 'ApplicantPortalLoginResponseType';
  applicant?: Maybe<ApplicantLoginPayloadType>;
  case?: Maybe<CaseLoginPayloadType>;
  token?: Maybe<Scalars['String']>;
};

export type ApplicantPortalMutationResponseType = {
  __typename?: 'ApplicantPortalMutationResponseType';
  data?: Maybe<ApplicantPortalResponseType>;
  status?: Maybe<Scalars['String']>;
};

export type ApplicantPortalResponseType = {
  __typename?: 'ApplicantPortalResponseType';
  _id?: Maybe<Scalars['ID']>;
  applicant?: Maybe<A_Applicant>;
  caseConfig?: Maybe<CaseConfigType>;
  caseDetails?: Maybe<A_CaseDetails>;
  eligibilityPreCheck?: Maybe<EligibilityPreCheckResponseType>;
  finance?: Maybe<Finance>;
  graduation?: Maybe<GraduationType>;
  languageAssessment?: Maybe<LanguageAssessment>;
  minicexList?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  pastAttempts?: Maybe<Array<Maybe<PastAttemptsType>>>;
  pathway1?: Maybe<Pathway1>;
  pathway2?: Maybe<Pathway2>;
  pathway6?: Maybe<Pathway6>;
  pathway345?: Maybe<Pathway345>;
  pathwayX?: Maybe<ExceptionRequest>;
  progress?: Maybe<A_Progress>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type ApplicantPortalResponseTypeMinicexListArgs = {
  filter: MiniCexListFilterApplicantInput;
};

export type ArchivedCaseApplicantGraduationType = {
  __typename?: 'ArchivedCaseApplicantGraduationType';
  country?: Maybe<Scalars['String']>;
  credComments?: Maybe<Scalars['String']>;
  credStatusCode?: Maybe<Scalars['String']>;
  degree?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type ArchivedCaseApplicantProfileType = {
  __typename?: 'ArchivedCaseApplicantProfileType';
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  usmleId?: Maybe<Scalars['String']>;
};

export type ArchivedCaseApplicantType = {
  __typename?: 'ArchivedCaseApplicantType';
  examHistory?: Maybe<Array<Maybe<ExamHistoryType>>>;
  graduation?: Maybe<ArchivedCaseApplicantGraduationType>;
  profile?: Maybe<ArchivedCaseApplicantProfileType>;
  restrictions?: Maybe<Array<Maybe<RestrictionType>>>;
};

export type ArchivedCaseAssessmentEligibilityOutputType = {
  __typename?: 'ArchivedCaseAssessmentEligibilityOutputType';
  output?: Maybe<Scalars['String']>;
  outputReason?: Maybe<Scalars['String']>;
};

export type ArchivedCaseAssessmentEligibilityType = {
  __typename?: 'ArchivedCaseAssessmentEligibilityType';
  eligibilityPreCheck?: Maybe<ArchivedCaseAssessmentEligibilityOutputType>;
  generalEligibility?: Maybe<ArchivedCaseAssessmentEligibilityOutputType>;
};

export type ArchivedCaseAssessmentMinicexType = {
  __typename?: 'ArchivedCaseAssessmentMinicexType';
  assessment?: Maybe<MiniCexAssessmentType>;
  minicexList?: Maybe<Array<Maybe<MiniCexResponseType>>>;
};

export type ArchivedCaseAssessmentMinicexTypeMinicexListArgs = {
  filter: ArchivedMiniCexListFilterCaseManagerInput;
};

export type ArchivedCaseAssessmentOetType = {
  __typename?: 'ArchivedCaseAssessmentOETType';
  candidateId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  examDate?: Maybe<Scalars['String']>;
  examineeFirstName?: Maybe<Scalars['String']>;
  examineeLastName?: Maybe<Scalars['String']>;
  listeningScore?: Maybe<Scalars['Float']>;
  readingScore?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['String']>;
  speakingScore?: Maybe<Scalars['Float']>;
  testingCenter?: Maybe<Scalars['String']>;
  usmleId?: Maybe<Scalars['String']>;
  writingScore?: Maybe<Scalars['Float']>;
};

export type ArchivedCaseAssessmentsType = {
  __typename?: 'ArchivedCaseAssessmentsType';
  eligibility?: Maybe<ArchivedCaseAssessmentEligibilityType>;
  emswp?: Maybe<EducationAttestation>;
  minicex?: Maybe<ArchivedCaseAssessmentMinicexType>;
  oet?: Maybe<ArchivedCaseAssessmentOetType>;
};

export type ArchivedCaseAuditType = {
  __typename?: 'ArchivedCaseAuditType';
  archivedAt?: Maybe<Scalars['DateString']>;
  completedAt?: Maybe<Scalars['DateString']>;
  createdAt?: Maybe<Scalars['DateString']>;
  submittedAt?: Maybe<Scalars['DateString']>;
};

export type ArchivedCaseDecisionType = {
  __typename?: 'ArchivedCaseDecisionType';
  executedBy: UserType;
  output: Scalars['String'];
  outputReason?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ArchivedCaseDetailsType = {
  __typename?: 'ArchivedCaseDetailsType';
  applicationType?: Maybe<Scalars['String']>;
  currentPathway?: Maybe<Scalars['String']>;
  decision?: Maybe<ArchivedCaseDecisionType>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  pathwaySeason?: Maybe<Scalars['Int']>;
  submittedPathway?: Maybe<Scalars['String']>;
};

export type ArchivedCaseFilterInput = {
  usmleId: Scalars['String'];
};

export type ArchivedCaseFinanceRevenueRecognitionType = {
  __typename?: 'ArchivedCaseFinanceRevenueRecognitionType';
  amount?: Maybe<Scalars['Int']>;
  creditGlAccount?: Maybe<Scalars['String']>;
  debitGlAccount?: Maybe<Scalars['String']>;
  pnref?: Maybe<Scalars['String']>;
  revenueRecognizedAt?: Maybe<Scalars['DateString']>;
};

export type ArchivedCaseFinanceType = {
  __typename?: 'ArchivedCaseFinanceType';
  payment?: Maybe<Payment>;
  refund?: Maybe<Refund>;
  revenueRecognition?: Maybe<Array<Maybe<ArchivedCaseFinanceRevenueRecognitionType>>>;
};

export type ArchivedCaseOetReviewType = {
  __typename?: 'ArchivedCaseOetReviewType';
  checklist?: Maybe<LanguageAssessmentChecklist>;
  checklistState?: Maybe<Scalars['String']>;
  data?: Maybe<LanguageAssessmentData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway1ReviewType = {
  __typename?: 'ArchivedCasePathway1ReviewType';
  checklist?: Maybe<LicenseChecklist>;
  checklistState?: Maybe<Scalars['String']>;
  data?: Maybe<LicenseData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway2ReviewType = {
  __typename?: 'ArchivedCasePathway2ReviewType';
  checklist?: Maybe<CsExamChecklist>;
  checklistState?: Maybe<Scalars['String']>;
  data?: Maybe<CsExamData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway6ReviewType = {
  __typename?: 'ArchivedCasePathway6ReviewType';
  checklist?: Maybe<MiniCexChecklist>;
  checklistState?: Maybe<Scalars['String']>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway345ReviewType = {
  __typename?: 'ArchivedCasePathway345ReviewType';
  checklist?: Maybe<EducationChecklist>;
  checklistState?: Maybe<Scalars['String']>;
  data?: Maybe<EducationData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathwayXReviewType = {
  __typename?: 'ArchivedCasePathwayXReviewType';
  checklist?: Maybe<ExceptionRequestChecklist>;
  data?: Maybe<ExceptionRequestData>;
  exceptionChecklistState?: Maybe<Scalars['String']>;
  pathwayChecklistState?: Maybe<Scalars['String']>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCaseReviewSummaryType = {
  __typename?: 'ArchivedCaseReviewSummaryType';
  _id: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars['DateString']>;
};

export type ArchivedCaseReviewsType = {
  __typename?: 'ArchivedCaseReviewsType';
  oet?: Maybe<Array<Maybe<ArchivedCaseOetReviewType>>>;
  pathway1?: Maybe<Array<Maybe<ArchivedCasePathway1ReviewType>>>;
  pathway2?: Maybe<Array<Maybe<ArchivedCasePathway2ReviewType>>>;
  pathway6?: Maybe<Array<Maybe<ArchivedCasePathway6ReviewType>>>;
  pathway345?: Maybe<Array<Maybe<ArchivedCasePathway345ReviewType>>>;
  pathwayX?: Maybe<Array<Maybe<ArchivedCasePathwayXReviewType>>>;
};

export type ArchivedCaseSummaryType = {
  __typename?: 'ArchivedCaseSummaryType';
  _id: Scalars['String'];
  applicationType?: Maybe<Scalars['String']>;
  decisionOutput?: Maybe<Scalars['String']>;
  pathwayNumber?: Maybe<Scalars['String']>;
  pathwaySeason?: Maybe<Scalars['Int']>;
  reviewCompletedOn?: Maybe<Scalars['DateString']>;
  submittedOn?: Maybe<Scalars['DateString']>;
};

export type ArchivedCaseType = {
  __typename?: 'ArchivedCaseType';
  _id: Scalars['String'];
  activityLog?: Maybe<Array<Maybe<ActivityLogType>>>;
  additionalDocuments?: Maybe<Array<Maybe<AdditionalDocument>>>;
  applicant?: Maybe<ArchivedCaseApplicantType>;
  assessments?: Maybe<ArchivedCaseAssessmentsType>;
  audit?: Maybe<ArchivedCaseAuditType>;
  caseDetails?: Maybe<ArchivedCaseDetailsType>;
  externalId?: Maybe<Scalars['String']>;
  finance?: Maybe<ArchivedCaseFinanceType>;
  reviews?: Maybe<ArchivedCaseReviewsType>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type ArchivedMiniCexListFilterCaseManagerInput = {
  caseId: Scalars['String'];
  responseType: Scalars['String'];
};

export type AssessmentCategoryType = {
  __typename?: 'AssessmentCategoryType';
  avgScore?: Maybe<Scalars['Float']>;
  failedEncounters?: Maybe<Array<Maybe<FailedEncounterType>>>;
  isFailedByAvgScore?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type AttestMiniCexInput = {
  hasApplicantEnrolledInClinicalRotation: Scalars['String'];
};

export type AttestationRecordFilterInput = {
  attestationOutput?: InputMaybe<Scalars['String']>;
  usmleId?: InputMaybe<Scalars['String']>;
};

export type AttestationRecordType = {
  __typename?: 'AttestationRecordType';
  attestationOutput?: Maybe<Scalars['String']>;
  completeTimestamp?: Maybe<Scalars['DateString']>;
  completedBy?: Maybe<Scalars['String']>;
  dataVersion?: Maybe<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gradSchool?: Maybe<Scalars['String']>;
  gradSchoolCountry?: Maybe<Scalars['String']>;
  gradYear?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  rejectionReason?: Maybe<Scalars['String']>;
  rejectionReasonText?: Maybe<Scalars['String']>;
  requestTimestamp?: Maybe<Scalars['DateString']>;
  uiConfig?: Maybe<UiPortalConfigType>;
  usmleId?: Maybe<Scalars['String']>;
};

export type AuthorityPortalResponseType = {
  __typename?: 'AuthorityPortalResponseType';
  attestationRecords?: Maybe<Array<Maybe<AttestationRecordType>>>;
};

export type AuthorityPortalResponseTypeAttestationRecordsArgs = {
  filter?: InputMaybe<AttestationRecordFilterInput>;
};

export type CsExam = {
  __typename?: 'CSExam';
  _id?: Maybe<Scalars['ID']>;
  data?: Maybe<CsExamData>;
  review?: Maybe<CsExamReview>;
};

export type CsExamChecklist = {
  __typename?: 'CSExamChecklist';
  osceDocumentCompleteLegible?: Maybe<Scalars['String']>;
  osceExaminationAcceptable?: Maybe<Scalars['String']>;
  osceExaminationAfterJuly1?: Maybe<Scalars['String']>;
  osceExaminationPassed?: Maybe<Scalars['String']>;
  osceNameOK?: Maybe<Scalars['String']>;
  osceTranslation?: Maybe<Scalars['String']>;
  verificationAcceptableDateConfirmed?: Maybe<Scalars['String']>;
  verificationCompleted?: Maybe<Scalars['String']>;
  verificationPassedExamConfirmed?: Maybe<Scalars['String']>;
  verificationReturned?: Maybe<Scalars['String']>;
};

export type CsExamChecklistInput = {
  osceDocumentCompleteLegible: Scalars['String'];
  osceExaminationAcceptable: Scalars['String'];
  osceExaminationAfterJuly1: Scalars['String'];
  osceExaminationPassed: Scalars['String'];
  osceNameOK: Scalars['String'];
  osceTranslation: Scalars['String'];
  verificationAcceptableDateConfirmed: Scalars['String'];
  verificationCompleted: Scalars['String'];
  verificationPassedExamConfirmed: Scalars['String'];
  verificationReturned: Scalars['String'];
};

export type CsExamData = {
  __typename?: 'CSExamData';
  _id?: Maybe<Scalars['ID']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  examDate?: Maybe<Scalars['String']>;
  oscExamRef?: Maybe<OscexamResponseType>;
  schoolRef?: Maybe<SchoolResponseType>;
};

export type CsExamDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  examDate?: InputMaybe<Scalars['String']>;
  oscExamRef?: InputMaybe<OscexamInput>;
  schoolRef?: InputMaybe<SchoolInput>;
};

export type CsExamInput = {
  data?: InputMaybe<CsExamDataInput>;
};

export type CsExamReview = {
  __typename?: 'CSExamReview';
  checklist?: Maybe<CsExamChecklist>;
  checklistState?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars['DateString']>;
};

export type CsExamReviewInput = {
  checklist?: InputMaybe<CsExamChecklistInput>;
  checklistState?: InputMaybe<Scalars['String']>;
};

export type C_ApplicantType = {
  __typename?: 'C_ApplicantType';
  country?: Maybe<Scalars['String']>;
  credComments?: Maybe<Scalars['String']>;
  credStatusCode?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  degree?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  examHistory?: Maybe<Array<Maybe<ExamHistoryType>>>;
  gradSchool?: Maybe<Scalars['String']>;
  gradSchoolId?: Maybe<Scalars['Int']>;
  gradYear?: Maybe<Scalars['String']>;
  graduation?: Maybe<GraduationType>;
  isStep2CSFailed?: Maybe<Scalars['Boolean']>;
  lastDataRefreshTimestamp?: Maybe<Scalars['DateString']>;
  restrictions?: Maybe<Array<Maybe<RestrictionType>>>;
  schoolEligibility?: Maybe<SchoolEligibilityType>;
};

/**  Required to enable Apollo Cache Control  */
export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CaseConfigRecordType = {
  __typename?: 'CaseConfigRecordType';
  minEligibleGradDate?: Maybe<Scalars['Date']>;
  minEligibleOETTestDate?: Maybe<Scalars['Date']>;
  pathwaySeason?: Maybe<Scalars['Int']>;
};

export type CaseConfigType = {
  __typename?: 'CaseConfigType';
  caseConfigRef?: Maybe<Scalars['CosmosObjectIdString']>;
  config?: Maybe<CaseConfigRecordType>;
};

export type CaseCountType = {
  __typename?: 'CaseCountType';
  applicationCompleted?: Maybe<Scalars['Int']>;
  applicationCreation?: Maybe<Scalars['Int']>;
  applicationSubmitted?: Maybe<Scalars['Int']>;
  assignedToUser?: Maybe<Scalars['Int']>;
  eligibilityReview?: Maybe<Scalars['Int']>;
  qaReady?: Maybe<Scalars['Int']>;
  qaReview?: Maybe<Scalars['Int']>;
};

export type CaseFilterInput = {
  applicationType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  assignedToUser?: InputMaybe<Scalars['Boolean']>;
  candidateId?: InputMaybe<Scalars['String']>;
  caseState?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  currentPathway?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  englishEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName?: InputMaybe<Scalars['String']>;
  generalEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName?: InputMaybe<Scalars['String']>;
  pathwayEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  regulatoryAuthority?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  school?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  usmleId?: InputMaybe<Scalars['String']>;
};

export type CaseListPaginationInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};

export type CaseListPaginationType = {
  __typename?: 'CaseListPaginationType';
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
  totalPages: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type CaseListResponseType = {
  __typename?: 'CaseListResponseType';
  cases?: Maybe<Array<Maybe<CaseSummaryType>>>;
  pagination: CaseListPaginationType;
};

export type CaseLoginPayloadType = {
  __typename?: 'CaseLoginPayloadType';
  applicationType?: Maybe<Scalars['String']>;
  isApplicationCreationAllowed?: Maybe<Scalars['Boolean']>;
  isExceptionCase?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
};

export type CaseNoteInput = {
  text: Scalars['String'];
};

export type CasePortalResponseType = {
  __typename?: 'CasePortalResponseType';
  case?: Maybe<CaseResponseType>;
  caseCount?: Maybe<CaseCountType>;
  caseList?: Maybe<CaseListResponseType>;
  cases?: Maybe<Array<Maybe<CaseSummaryType>>>;
  getOETRecordCount?: Maybe<Scalars['Int']>;
  getOETUnMatchedCount?: Maybe<Scalars['Int']>;
  glDistributionConfig?: Maybe<Array<Maybe<GlDistributionConfig>>>;
  glDistributionReport?: Maybe<Array<Maybe<GlDistributionReportResponseType>>>;
  minicexByPhysician?: Maybe<Array<Maybe<MiniCexResponseType>>>;
  oetPortal?: Maybe<OetPortalResponseType>;
  physician?: Maybe<PhysicianResponseType>;
  physicianByMiniCEX?: Maybe<Array<Maybe<PhysicianResponseType>>>;
  physicians?: Maybe<Array<Maybe<PhysicianResponseType>>>;
};

export type CasePortalResponseTypeCaseArgs = {
  usmleId?: InputMaybe<Scalars['String']>;
};

export type CasePortalResponseTypeCaseListArgs = {
  filter?: InputMaybe<CaseFilterInput>;
  pagination: CaseListPaginationInput;
};

export type CasePortalResponseTypeCasesArgs = {
  filter?: InputMaybe<CaseFilterInput>;
};

export type CasePortalResponseTypeGlDistributionConfigArgs = {
  _id?: InputMaybe<Scalars['ID']>;
};

export type CasePortalResponseTypeGlDistributionReportArgs = {
  filter?: InputMaybe<GlDistributionReportFilterInput>;
};

export type CasePortalResponseTypeMinicexByPhysicianArgs = {
  filter: MiniCexByPhysicianFilterCaseManagerInput;
};

export type CasePortalResponseTypePhysicianArgs = {
  email: Scalars['String'];
};

export type CasePortalResponseTypePhysicianByMiniCexArgs = {
  filter: PhysicianByMiniCexFilterCaseManagerInput;
};

export type CaseResponseStatusType = {
  __typename?: 'CaseResponseStatusType';
  response?: Maybe<ResponseStatusType>;
  usmleId?: Maybe<Scalars['String']>;
};

export type CaseResponseType = {
  __typename?: 'CaseResponseType';
  _id?: Maybe<Scalars['String']>;
  activityLog?: Maybe<Array<Maybe<ActivityLogType>>>;
  additionalDocuments?: Maybe<Array<Maybe<AdditionalDocument>>>;
  applicant?: Maybe<C_ApplicantType>;
  caseConfig?: Maybe<CaseConfigType>;
  caseSummary?: Maybe<CaseSummaryType>;
  finance?: Maybe<Finance>;
  languageAssessment?: Maybe<LanguageAssessment>;
  oetScore?: Maybe<OetResponseType>;
  pastAttempts?: Maybe<Array<Maybe<PastAttemptsType>>>;
  pathway1?: Maybe<Pathway1>;
  pathway2?: Maybe<Pathway2>;
  pathway6?: Maybe<Pathway6>;
  pathway345?: Maybe<Pathway345>;
  pathwayX?: Maybe<ExceptionRequest>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type CaseSummaryType = {
  __typename?: 'CaseSummaryType';
  _id?: Maybe<Scalars['String']>;
  applicationType?: Maybe<Scalars['String']>;
  caseState?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateString']>;
  currentPathway?: Maybe<Scalars['String']>;
  dataVersion?: Maybe<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  eligibilityReviewAssigneeId?: Maybe<Scalars['String']>;
  eligibilityReviewAssigneeName?: Maybe<Scalars['String']>;
  englishEligibilityOutput?: Maybe<Scalars['String']>;
  englishEligibilityStatus?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  generalEligibilityOutput?: Maybe<Scalars['String']>;
  generalEligibilityOutputReason?: Maybe<Scalars['String']>;
  generalEligibilityStatus?: Maybe<Scalars['String']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  isExceptionCase?: Maybe<Scalars['Boolean']>;
  isPathwayChanged?: Maybe<Scalars['Boolean']>;
  lastDocUploadTimestamp?: Maybe<Scalars['DateString']>;
  lastName?: Maybe<Scalars['String']>;
  oetCandidateId?: Maybe<Scalars['String']>;
  oetExamDate?: Maybe<Scalars['String']>;
  output?: Maybe<Scalars['String']>;
  outputReason?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathwayEligibilityOutput?: Maybe<Scalars['String']>;
  pathwayEligibilityStatus?: Maybe<Scalars['String']>;
  pathwaySeason?: Maybe<Scalars['Int']>;
  qaReviewAssigneeId?: Maybe<Scalars['String']>;
  qaReviewAssigneeName?: Maybe<Scalars['String']>;
  qaReworkTimestamp?: Maybe<Scalars['DateString']>;
  submittedAt?: Maybe<Scalars['DateString']>;
  submittedPathway?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateString']>;
  usmleId?: Maybe<Scalars['String']>;
};

export type CategoryAdditionalInfoType = {
  __typename?: 'CategoryAdditionalInfoType';
  maxScore?: Maybe<Scalars['Int']>;
  minScore?: Maybe<Scalars['Int']>;
};

export type CategoryQualificationType = {
  __typename?: 'CategoryQualificationType';
  minAvgScoreAcrossAllRequests?: Maybe<Scalars['Int']>;
  minScore?: Maybe<Scalars['Int']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  _id?: Maybe<Scalars['CosmosObjectIdString']>;
  dashboardType?: Maybe<Scalars['String']>;
  lastAccessedCaseView?: Maybe<LastAccessedView>;
  lastAccessedOETView?: Maybe<LastAccessedView>;
  oetViews?: Maybe<Array<Maybe<OetViews>>>;
  schemaVersion?: Maybe<Scalars['String']>;
  views?: Maybe<Array<Maybe<Views>>>;
};

export type DashboardInput = {
  dashboardType?: InputMaybe<Scalars['String']>;
  oetViews?: InputMaybe<Array<InputMaybe<OetViewsInput>>>;
  schemaVersion?: InputMaybe<Scalars['String']>;
  views?: InputMaybe<Array<InputMaybe<ViewsInput>>>;
};

export type DeleteDashboardResponse = {
  __typename?: 'DeleteDashboardResponse';
  success?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  docId?: Maybe<Scalars['String']>;
  docType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uploadedBy?: Maybe<DocumentUploadUser>;
};

export type DocumentInput = {
  docId?: InputMaybe<Scalars['String']>;
  docType: Scalars['String'];
  title: Scalars['String'];
  uploadedBy?: InputMaybe<DocumentUploadUserInput>;
};

export type DocumentUploadUser = {
  __typename?: 'DocumentUploadUser';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DocumentUploadUserInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Education = {
  __typename?: 'Education';
  _id?: Maybe<Scalars['ID']>;
  attestation?: Maybe<EducationAttestation>;
  data?: Maybe<EducationData>;
  review?: Maybe<EducationReview>;
};

export type EducationAttestation = {
  __typename?: 'EducationAttestation';
  output?: Maybe<Scalars['String']>;
  outputReason?: Maybe<Scalars['String']>;
  outputReasonText?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars['DateString']>;
};

export type EducationChecklist = {
  __typename?: 'EducationChecklist';
  attestationAuthorizedOfficial?: Maybe<Scalars['String']>;
  attestationComplete?: Maybe<Scalars['String']>;
  attestationCompletedByMedicalSchool?: Maybe<Scalars['String']>;
  attestationSealOk?: Maybe<Scalars['String']>;
  attestationSentPrimarySource?: Maybe<Scalars['String']>;
  attestationSignatureDate?: Maybe<Scalars['String']>;
  attestationSignatureMatch?: Maybe<Scalars['String']>;
  attestationTitleOk?: Maybe<Scalars['String']>;
  eligibilityGradDateEligible?: Maybe<Scalars['String']>;
  eligibilityMedicalSchool?: Maybe<Scalars['String']>;
};

export type EducationChecklistInput = {
  attestationAuthorizedOfficial: Scalars['String'];
  attestationComplete: Scalars['String'];
  attestationCompletedByMedicalSchool: Scalars['String'];
  attestationSealOk: Scalars['String'];
  attestationSentPrimarySource: Scalars['String'];
  attestationSignatureDate: Scalars['String'];
  attestationSignatureMatch: Scalars['String'];
  attestationTitleOk: Scalars['String'];
  eligibilityGradDateEligible: Scalars['String'];
  eligibilityMedicalSchool: Scalars['String'];
};

export type EducationData = {
  __typename?: 'EducationData';
  _id?: Maybe<Scalars['ID']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  gradDate?: Maybe<Scalars['String']>;
  schoolRef?: Maybe<SchoolResponseType>;
};

export type EducationDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  gradDate?: InputMaybe<Scalars['String']>;
  schoolRef?: InputMaybe<SchoolInput>;
};

export type EducationInput = {
  data?: InputMaybe<EducationDataInput>;
};

export type EducationReview = {
  __typename?: 'EducationReview';
  checklist?: Maybe<EducationChecklist>;
  checklistState?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars['DateString']>;
};

export type EducationReviewInput = {
  checklist?: InputMaybe<EducationChecklistInput>;
  checklistState?: InputMaybe<Scalars['String']>;
};

export type Eligibility = {
  __typename?: 'Eligibility';
  eligibilityCheckTimestamp?: Maybe<Scalars['DateString']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  responseCode?: Maybe<Scalars['String']>;
  responseMsg?: Maybe<Scalars['String']>;
};

export type EligibilityPreCheckResponseType = {
  __typename?: 'EligibilityPreCheckResponseType';
  eligibility?: Maybe<Eligibility>;
  isStep2CSFailed?: Maybe<Scalars['Boolean']>;
  lastScreenName?: Maybe<Scalars['String']>;
};

export type EventRepublishResponseType = {
  __typename?: 'EventRepublishResponseType';
  id?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
};

export type ExamHistoryType = {
  __typename?: 'ExamHistoryType';
  examDate?: Maybe<Scalars['String']>;
  examName?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
};

export type ExceptionRequest = {
  __typename?: 'ExceptionRequest';
  _id?: Maybe<Scalars['ID']>;
  data?: Maybe<ExceptionRequestData>;
  review?: Maybe<ExceptionRequestReview>;
};

export type ExceptionRequestChecklist = {
  __typename?: 'ExceptionRequestChecklist';
  applicantEligiblePathway1?: Maybe<Scalars['String']>;
  applicantEligiblePathway2?: Maybe<Scalars['String']>;
  applicantEligiblePathway345?: Maybe<Scalars['String']>;
  applicantHasNotSubmittedAppForCert?: Maybe<Scalars['String']>;
  applicantHasPurchasedToken2021Match?: Maybe<Scalars['String']>;
  applicantIsNotCertified?: Maybe<Scalars['String']>;
  applicantNotBarredByUSMLE?: Maybe<Scalars['String']>;
  applicantNotValidStep2CSScore?: Maybe<Scalars['String']>;
  ecfmgRestrictionsDoNotPreventAppExamination?: Maybe<Scalars['String']>;
  exceptionApproved?: Maybe<Scalars['String']>;
  pathway1DocsVerified?: Maybe<Scalars['String']>;
  pathway2DocsVerified?: Maybe<Scalars['String']>;
  pathway345DocsVerified?: Maybe<Scalars['String']>;
  step1PassedWithOneOrNoFails?: Maybe<Scalars['String']>;
  step2CKPassedWithOneOrNoFails?: Maybe<Scalars['String']>;
};

export type ExceptionRequestChecklistInput = {
  applicantEligiblePathway1: Scalars['String'];
  applicantEligiblePathway2: Scalars['String'];
  applicantEligiblePathway345: Scalars['String'];
  applicantHasNotSubmittedAppForCert: Scalars['String'];
  applicantHasPurchasedToken2021Match: Scalars['String'];
  applicantIsNotCertified: Scalars['String'];
  applicantNotBarredByUSMLE: Scalars['String'];
  applicantNotValidStep2CSScore: Scalars['String'];
  ecfmgRestrictionsDoNotPreventAppExamination: Scalars['String'];
  exceptionApproved: Scalars['String'];
  pathway1DocsVerified: Scalars['String'];
  pathway2DocsVerified: Scalars['String'];
  pathway345DocsVerified: Scalars['String'];
  step1PassedWithOneOrNoFails: Scalars['String'];
  step2CKPassedWithOneOrNoFails: Scalars['String'];
};

export type ExceptionRequestData = {
  __typename?: 'ExceptionRequestData';
  documents?: Maybe<Array<Maybe<Document>>>;
  requestText?: Maybe<Scalars['String']>;
};

export type ExceptionRequestDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  requestText?: InputMaybe<Scalars['String']>;
};

export type ExceptionRequestInput = {
  data?: InputMaybe<ExceptionRequestDataInput>;
};

export type ExceptionRequestReview = {
  __typename?: 'ExceptionRequestReview';
  checklist?: Maybe<ExceptionRequestChecklist>;
  exceptionChecklistState?: Maybe<Scalars['String']>;
  pathwayChecklistState?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars['DateString']>;
};

export type ExceptionRequestReviewInput = {
  checklist?: InputMaybe<ExceptionRequestChecklistInput>;
  exceptionChecklistState?: InputMaybe<Scalars['String']>;
  pathwayChecklistState?: InputMaybe<Scalars['String']>;
};

export type FailedEncounterType = {
  __typename?: 'FailedEncounterType';
  encounterId?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

export type Finance = {
  __typename?: 'Finance';
  caseFee?: Maybe<Scalars['Int']>;
  payment?: Maybe<Payment>;
  refund?: Maybe<Refund>;
};

export type GlDistributionConfig = {
  __typename?: 'GLDistributionConfig';
  _id?: Maybe<Scalars['ID']>;
  applicationType?: Maybe<Scalars['String']>;
  configName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateString']>;
  createdBy?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  exceptionCaseFee?: Maybe<Scalars['Int']>;
  exceptionCaseInitialIntake?: Maybe<Scalars['Int']>;
  glAccountCashControl?: Maybe<Scalars['String']>;
  glAccountEarned?: Maybe<Scalars['String']>;
  glAccountRefundControl?: Maybe<Scalars['String']>;
  glAccountUnearned?: Maybe<Scalars['String']>;
  standardCaseFee?: Maybe<Scalars['Int']>;
  standardCaseInitialIntake?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateString']>;
  updatedBy?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type GlDistributionConfigEditInput = {
  configName: Scalars['String'];
};

export type GlDistributionConfigInput = {
  applicationType: Scalars['String'];
  configName: Scalars['String'];
  dateFrom: Scalars['DateString'];
  exceptionCaseFee: Scalars['Int'];
  exceptionCaseInitialIntake: Scalars['Int'];
  glAccountCashControl: Scalars['String'];
  glAccountEarned: Scalars['String'];
  glAccountRefundControl: Scalars['String'];
  glAccountUnearned: Scalars['String'];
  standardCaseFee: Scalars['Int'];
  standardCaseInitialIntake: Scalars['Int'];
};

export type GenericResponseType = {
  __typename?: 'GenericResponseType';
  code?: Maybe<Scalars['String']>;
  dataVersion?: Maybe<Scalars['Int']>;
  msg?: Maybe<Scalars['String']>;
};

export type GraduationType = {
  __typename?: 'GraduationType';
  country?: Maybe<Scalars['String']>;
  gradSchoolId?: Maybe<Scalars['Int']>;
  isYearEligible?: Maybe<Scalars['Boolean']>;
  school?: Maybe<Scalars['String']>;
  schoolEligibility?: Maybe<SchoolEligibilityType>;
  year?: Maybe<Scalars['String']>;
};

export type JobEligibilityChecksResponseType = {
  __typename?: 'JobEligibilityChecksResponseType';
  eligibilityPreCheck?: Maybe<JobResponseType>;
  generalEligibility?: Maybe<JobResponseType>;
  jobId?: Maybe<Scalars['String']>;
  totalTimeTaken?: Maybe<Scalars['Int']>;
};

export type JobResponseType = {
  __typename?: 'JobResponseType';
  failureCount?: Maybe<Scalars['Int']>;
  jobId?: Maybe<Scalars['String']>;
  jobStatus?: Maybe<Scalars['String']>;
  successCount?: Maybe<Scalars['Int']>;
  timeTaken?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type LanguageAssessment = {
  __typename?: 'LanguageAssessment';
  applicantProvidedData?: Maybe<Array<Maybe<LanguageAssessmentType>>>;
  staffProvidedData?: Maybe<Array<Maybe<LanguageAssessmentType>>>;
  userResponse?: Maybe<LanguageAssessmentUserResponse>;
};

export type LanguageAssessmentChecklist = {
  __typename?: 'LanguageAssessmentChecklist';
  documentCompleteLegible?: Maybe<Scalars['String']>;
  examDateValidation?: Maybe<Scalars['String']>;
  nameValid?: Maybe<Scalars['String']>;
  scoreAcceptable?: Maybe<Scalars['String']>;
  scoreConfirmed?: Maybe<Scalars['String']>;
};

export type LanguageAssessmentChecklistInput = {
  documentCompleteLegible: Scalars['String'];
  examDateValidation: Scalars['String'];
  nameValid: Scalars['String'];
  scoreAcceptable: Scalars['String'];
  scoreConfirmed: Scalars['String'];
};

export type LanguageAssessmentData = {
  __typename?: 'LanguageAssessmentData';
  _id?: Maybe<Scalars['ID']>;
  candidateId?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  examDate?: Maybe<Scalars['String']>;
};

export type LanguageAssessmentDataInput = {
  candidateId?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  examDate?: InputMaybe<Scalars['String']>;
};

export type LanguageAssessmentInput = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<LanguageAssessmentTypeInput>>>;
  userResponse?: InputMaybe<LanguageAssessmentUserResponseInput>;
};

export type LanguageAssessmentReview = {
  __typename?: 'LanguageAssessmentReview';
  checklist?: Maybe<LanguageAssessmentChecklist>;
  checklistState?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars['DateString']>;
};

export type LanguageAssessmentReviewInput = {
  checklist?: InputMaybe<LanguageAssessmentChecklistInput>;
  checklistState?: InputMaybe<Scalars['String']>;
};

export type LanguageAssessmentType = {
  __typename?: 'LanguageAssessmentType';
  _id?: Maybe<Scalars['ID']>;
  data?: Maybe<LanguageAssessmentData>;
  review?: Maybe<LanguageAssessmentReview>;
};

export type LanguageAssessmentTypeInput = {
  data?: InputMaybe<LanguageAssessmentDataInput>;
};

export type LanguageAssessmentUserResponse = {
  __typename?: 'LanguageAssessmentUserResponse';
  hasPassedExam?: Maybe<Scalars['Boolean']>;
};

export type LanguageAssessmentUserResponseInput = {
  hasPassedExam?: InputMaybe<Scalars['Boolean']>;
};

export type LastAccessedView = {
  __typename?: 'LastAccessedView';
  assignedToMe?: Maybe<Scalars['Boolean']>;
  recordsPerPage?: Maybe<Scalars['Int']>;
  viewId?: Maybe<Scalars['CosmosObjectIdString']>;
};

export type LastAccessedViewInput = {
  assignedToMe?: InputMaybe<Scalars['Boolean']>;
  recordsPerPage?: InputMaybe<Scalars['Int']>;
  viewId?: InputMaybe<Scalars['CosmosObjectIdString']>;
};

export type License = {
  __typename?: 'License';
  _id?: Maybe<Scalars['ID']>;
  data?: Maybe<LicenseData>;
  review?: Maybe<LicenseReview>;
};

export type LicenseChecklist = {
  __typename?: 'LicenseChecklist';
  licensureApplicantLicenseOnPoint?: Maybe<Scalars['String']>;
  licensureCompleteLegible?: Maybe<Scalars['String']>;
  licensureDisciplinaryAction?: Maybe<Scalars['String']>;
  licensureDocumentIssueDateAcceptable?: Maybe<Scalars['String']>;
  licensureIssuedAppropriateAuthority?: Maybe<Scalars['String']>;
  licensureNameValidation?: Maybe<Scalars['String']>;
  licensureTranslationStatus?: Maybe<Scalars['String']>;
  licensureUnrestrictedLicense?: Maybe<Scalars['String']>;
  verificationActiveLicense?: Maybe<Scalars['String']>;
  verificationAuthorized?: Maybe<Scalars['String']>;
  verificationGoodStanding?: Maybe<Scalars['String']>;
  verificationPrimarySource?: Maybe<Scalars['String']>;
};

export type LicenseChecklistInput = {
  licensureApplicantLicenseOnPoint: Scalars['String'];
  licensureCompleteLegible: Scalars['String'];
  licensureDisciplinaryAction: Scalars['String'];
  licensureDocumentIssueDateAcceptable: Scalars['String'];
  licensureIssuedAppropriateAuthority: Scalars['String'];
  licensureNameValidation: Scalars['String'];
  licensureTranslationStatus: Scalars['String'];
  licensureUnrestrictedLicense: Scalars['String'];
  verificationActiveLicense: Scalars['String'];
  verificationAuthorized: Scalars['String'];
  verificationGoodStanding: Scalars['String'];
  verificationPrimarySource: Scalars['String'];
};

export type LicenseData = {
  __typename?: 'LicenseData';
  _id?: Maybe<Scalars['ID']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  hasDisciplinaryAction?: Maybe<Scalars['Boolean']>;
  isCurrentlyLicensed?: Maybe<Scalars['Boolean']>;
  licenseExpiryDate?: Maybe<Scalars['String']>;
  licenseIssueDate?: Maybe<Scalars['String']>;
  licensureDocumentStatus?: Maybe<Scalars['String']>;
  regulatoryAuthorityRef?: Maybe<RegulatoryAuthorityResponseType>;
};

export type LicenseDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  hasDisciplinaryAction?: InputMaybe<Scalars['Boolean']>;
  isCurrentlyLicensed?: InputMaybe<Scalars['Boolean']>;
  licenseExpiryDate?: InputMaybe<Scalars['String']>;
  licenseIssueDate?: InputMaybe<Scalars['String']>;
  licensureDocumentStatus?: InputMaybe<Scalars['String']>;
  regulatoryAuthorityRef?: InputMaybe<RegulatoryAuthorityInput>;
};

export type LicenseInput = {
  data?: InputMaybe<LicenseDataInput>;
};

export type LicenseReview = {
  __typename?: 'LicenseReview';
  checklist?: Maybe<LicenseChecklist>;
  checklistState?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars['DateString']>;
};

export type LicenseReviewInput = {
  checklist?: InputMaybe<LicenseChecklistInput>;
  checklistState?: InputMaybe<Scalars['String']>;
};

export type MiniCexAdditionalInfoInput = {
  additionalInfoText?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<InputMaybe<MiniCexCategoryAdditionalInfoInput>>>;
};

export type MiniCexAssessmentType = {
  __typename?: 'MiniCEXAssessmentType';
  categories?: Maybe<Array<Maybe<AssessmentCategoryType>>>;
  output?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateString']>;
};

export type MiniCexAttestationType = {
  __typename?: 'MiniCEXAttestationType';
  attestedAt?: Maybe<Array<Maybe<Scalars['DateString']>>>;
  hasApplicantEnrolledInClinicalRotation?: Maybe<Scalars['String']>;
};

export type MiniCexByPhysicianFilterCaseManagerInput = {
  email: Scalars['String'];
  responseType?: InputMaybe<Scalars['String']>;
};

export type MiniCexCategoryAdditionalInfoInput = {
  name?: InputMaybe<Scalars['String']>;
  reasonText?: InputMaybe<Scalars['String']>;
};

export type MiniCexCategoryScoreInput = {
  name?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
};

export type MiniCexCategoryType = {
  __typename?: 'MiniCEXCategoryType';
  isAdditionalInfoRequired?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  reasonText?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

export type MiniCexChecklist = {
  __typename?: 'MiniCEXChecklist';
  minicexComplete?: Maybe<Scalars['String']>;
  minicexScoreAcceptable?: Maybe<Scalars['String']>;
};

export type MiniCexChecklistInput = {
  minicexComplete: Scalars['String'];
  minicexScoreAcceptable: Scalars['String'];
};

export type MiniCexConfigMgmtType = {
  __typename?: 'MiniCEXConfigMgmtType';
  config?: Maybe<MiniCexConfigRecordType>;
  configName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateString']>;
  createdUser?: Maybe<Scalars['String']>;
  dataVersion?: Maybe<Scalars['Int']>;
  dateFrom?: Maybe<Scalars['DateString']>;
  isActive?: Maybe<Scalars['Boolean']>;
  minicexConfigRef?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateString']>;
  updatedUser?: Maybe<Scalars['String']>;
};

export type MiniCexConfigRecordType = {
  __typename?: 'MiniCEXConfigRecordType';
  deadline?: Maybe<Array<Maybe<MiniCexDeadlineConfigType>>>;
  evaluationCategory?: Maybe<Array<Maybe<MiniCexEvaluationCategoryConfigType>>>;
  pathwaySeason?: Maybe<Scalars['String']>;
  submission?: Maybe<MiniCexSubmissionConfigType>;
};

export type MiniCexConfigType = {
  __typename?: 'MiniCEXConfigType';
  config?: Maybe<MiniCexConfigRecordType>;
  minicexConfigRef?: Maybe<Scalars['CosmosObjectIdString']>;
};

export type MiniCexCountryInput = {
  name?: InputMaybe<Scalars['String']>;
  ref?: InputMaybe<Scalars['String']>;
};

export type MiniCexCountryType = {
  __typename?: 'MiniCEXCountryType';
  name?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
};

export type MiniCexData = {
  __typename?: 'MiniCEXData';
  _id?: Maybe<Scalars['ID']>;
  acknowledgedTimestamp?: Maybe<Scalars['DateString']>;
  documents?: Maybe<Array<Maybe<Document>>>;
};

export type MiniCexDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
};

export type MiniCexDeadlineConfigType = {
  __typename?: 'MiniCEXDeadlineConfigType';
  deadlineInDays?: Maybe<Scalars['Int']>;
  deadlineName?: Maybe<Scalars['String']>;
};

export type MiniCexEncounterInput = {
  encounterDate?: InputMaybe<Scalars['String']>;
  encounterSetting?: InputMaybe<Scalars['String']>;
  facility?: InputMaybe<MiniCexFacilityInput>;
  patient?: InputMaybe<MiniCexPatientInput>;
};

export type MiniCexEncounterType = {
  __typename?: 'MiniCEXEncounterType';
  encounterDate?: Maybe<Scalars['String']>;
  encounterSetting?: Maybe<Scalars['String']>;
  facility?: Maybe<MiniCexFacilityType>;
  patient?: Maybe<MiniCexPatientType>;
};

export type MiniCexEvaluationCategoryConfigType = {
  __typename?: 'MiniCEXEvaluationCategoryConfigType';
  additionalInfo?: Maybe<CategoryAdditionalInfoType>;
  categoryName?: Maybe<Scalars['String']>;
  qualification?: Maybe<CategoryQualificationType>;
};

export type MiniCexEvaluationInput = {
  categories?: InputMaybe<Array<InputMaybe<MiniCexCategoryScoreInput>>>;
};

export type MiniCexEvaluationType = {
  __typename?: 'MiniCEXEvaluationType';
  additionalInfoText?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<MiniCexCategoryType>>>;
};

export type MiniCexFacilityInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<MiniCexCountryInput>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type MiniCexFacilityType = {
  __typename?: 'MiniCEXFacilityType';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<MiniCexCountryType>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type MiniCexFormProgressType = {
  __typename?: 'MiniCEXFormProgressType';
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type MiniCexFormType = {
  __typename?: 'MiniCEXFormType';
  attestation?: Maybe<MiniCexAttestationType>;
  encounter?: Maybe<MiniCexEncounterType>;
  evaluation?: Maybe<MiniCexEvaluationType>;
  progress?: Maybe<Array<Maybe<MiniCexFormProgressType>>>;
};

export type MiniCexListFilterApplicantInput = {
  responseType: Scalars['String'];
};

export type MiniCexListFilterCaseManagerInput = {
  responseType: Scalars['String'];
  usmleId: Scalars['String'];
};

export type MiniCexListFilterPhysicianInput = {
  responseType: Scalars['String'];
};

export type MiniCexNotificationType = {
  __typename?: 'MiniCEXNotificationType';
  name?: Maybe<Scalars['String']>;
  recipient?: Maybe<UserType>;
  status?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateString']>;
};

export type MiniCexPatientInput = {
  age?: InputMaybe<Scalars['Int']>;
  ageMonth?: InputMaybe<Scalars['String']>;
  ageYear?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['String']>;
};

export type MiniCexPatientType = {
  __typename?: 'MiniCEXPatientType';
  age?: Maybe<Scalars['Int']>;
  ageMonth?: Maybe<Scalars['String']>;
  ageYear?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
};

export type MiniCexResponseType = {
  __typename?: 'MiniCEXResponseType';
  dataVersion?: Maybe<Scalars['Int']>;
  encounterId?: Maybe<Scalars['String']>;
  form?: Maybe<MiniCexFormType>;
  minicexConfig?: Maybe<MiniCexConfigType>;
  minicexId?: Maybe<Scalars['String']>;
  summary?: Maybe<MiniCexSummaryType>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type MiniCexReview = {
  __typename?: 'MiniCEXReview';
  checklist?: Maybe<MiniCexChecklist>;
  checklistState?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars['DateString']>;
};

export type MiniCexReviewInput = {
  checklist?: InputMaybe<MiniCexChecklistInput>;
  checklistState?: InputMaybe<Scalars['String']>;
};

export type MiniCexStateModelType = {
  __typename?: 'MiniCEXStateModelType';
  state?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateString']>;
};

export type MiniCexSubmissionConfigType = {
  __typename?: 'MiniCEXSubmissionConfigType';
  maxRequestPerPhysician?: Maybe<Scalars['Int']>;
  totalRequestCount?: Maybe<Scalars['Int']>;
};

export type MiniCexSummaryType = {
  __typename?: 'MiniCEXSummaryType';
  acceptedAt?: Maybe<Scalars['DateString']>;
  allowedActions?: Maybe<Array<Maybe<Scalars['String']>>>;
  applicantLastName?: Maybe<Scalars['String']>;
  applicantRestOfName?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateString']>;
  createdAt?: Maybe<Scalars['DateString']>;
  dataVersion?: Maybe<Scalars['Int']>;
  encounterId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateString']>;
  isActive?: Maybe<Scalars['Boolean']>;
  minicexId?: Maybe<Scalars['String']>;
  notifications?: Maybe<Array<Maybe<MiniCexNotificationType>>>;
  pathwaySeason?: Maybe<Scalars['Int']>;
  physicianEmail?: Maybe<Scalars['String']>;
  physicianLastNameProvidedByApplicant?: Maybe<Scalars['String']>;
  physicianLastNameProvidedByPhysician?: Maybe<Scalars['String']>;
  physicianRestOfNameProvidedByApplicant?: Maybe<Scalars['String']>;
  physicianRestOfNameProvidedByPhysician?: Maybe<Scalars['String']>;
  rejectionReason?: Maybe<Scalars['String']>;
  rejectionReasonComments?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateModel?: Maybe<Array<Maybe<MiniCexStateModelType>>>;
  usmleId?: Maybe<Scalars['String']>;
};

export type MiniCexSurveyInput = {
  questions?: InputMaybe<Array<InputMaybe<MiniCexSurveyQuestionInput>>>;
};

export type MiniCexSurveyQuestionInput = {
  questionId?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type Mutation = {
  __typename?: 'Mutation';
  AdminPortal_events_republish?: Maybe<Array<Maybe<EventRepublishResponseType>>>;
  ApplicantPortal_MiniCEX_add?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  ApplicantPortal_MiniCEX_remove?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  ApplicantPortal_checkPaymentExists?: Maybe<Scalars['Boolean']>;
  ApplicantPortal_login?: Maybe<ApplicantPortalLoginResponseType>;
  ApplicantPortal_pathway1a?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway1b?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway2a?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway2b?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway6?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway345?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathwayX?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_runEligibilityPreCheck?: Maybe<EligibilityPreCheckResponseType>;
  AuthorityPortal_attest?: Maybe<Scalars['String']>;
  AuthorityPortal_reject?: Maybe<Scalars['String']>;
  CasePortal_AuthorityMgmt_syncAccountFromB2C?: Maybe<ResponseStatusType>;
  CasePortal_MiniCEX_cancel?: Maybe<MiniCexResponseType>;
  CasePortal_MiniCEX_reject?: Maybe<MiniCexResponseType>;
  CasePortal_PhyMgmt_block?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_editDoB?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_editName?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_optIn?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_optOut?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_syncRegistration?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_unblock?: Maybe<PhysicianResponseType>;
  CasePortal_SubmissionSummary?: Maybe<Scalars['String']>;
  CasePortal_addCaseNote?: Maybe<CaseResponseType>;
  CasePortal_additionalDocuments?: Maybe<CaseResponseType>;
  CasePortal_archivedCase_SubmissionSummary?: Maybe<Scalars['String']>;
  CasePortal_assignEligibilityReviewTask?: Maybe<CaseResponseType>;
  CasePortal_assignQAReviewTask?: Maybe<CaseResponseType>;
  CasePortal_case_archive?: Maybe<CaseResponseStatusType>;
  CasePortal_case_purge?: Maybe<CaseResponseStatusType>;
  CasePortal_checkCaseExists?: Maybe<Scalars['Boolean']>;
  CasePortal_convertToExceptionCase?: Maybe<CaseResponseType>;
  CasePortal_createExceptionCase?: Maybe<CaseResponseType>;
  CasePortal_glDistributionReport?: Maybe<Array<Maybe<GlDistributionReportResponseType>>>;
  CasePortal_issuePaperCheck?: Maybe<GenericResponseType>;
  CasePortal_languageAssessment_setReview?: Maybe<CaseResponseType>;
  CasePortal_overridePathway?: Maybe<CaseResponseType>;
  CasePortal_pathway1_deleteRecord?: Maybe<CaseResponseType>;
  CasePortal_pathway1_setData?: Maybe<CaseResponseType>;
  CasePortal_pathway1_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathway2_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathway6_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathway6_unlockReview?: Maybe<CaseResponseType>;
  CasePortal_pathway345_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathwayX_setReview?: Maybe<CaseResponseType>;
  CasePortal_process_generalEligibility?: Maybe<CaseResponseType>;
  CasePortal_qa_acceptDecision?: Maybe<CaseResponseType>;
  CasePortal_qa_approve?: Maybe<CaseResponseType>;
  CasePortal_qa_overrideDecision?: Maybe<CaseResponseType>;
  CasePortal_qa_reject?: Maybe<CaseResponseType>;
  CasePortal_qa_reworkRequired?: Maybe<CaseResponseType>;
  CasePortal_reopenCase?: Maybe<CaseResponseType>;
  CasePortal_review_reworkRequired?: Maybe<CaseResponseType>;
  FeatureFlag_SASToken?: Maybe<SasToken>;
  GLDistributionConfig_create?: Maybe<GlDistributionConfig>;
  GLDistributionConfig_deactivate?: Maybe<ResponseStatusType>;
  GLDistributionConfig_edit?: Maybe<GlDistributionConfig>;
  Job_processEligibilityChecks?: Maybe<JobEligibilityChecksResponseType>;
  PhysicianPortal_Mgmt_optOut?: Maybe<PhysicianResponseType>;
  PhysicianPortal_Mgmt_optOut_noauth?: Maybe<ResponseStatusType>;
  PhysicianPortal_Mgmt_setProfile?: Maybe<PhysicianResponseType>;
  PhysicianPortal_MiniCEX_accept?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_attest?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_complete?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_get?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_reject?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setAdditionalInfo?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setEncounter?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setEvaluation?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setSurvey?: Maybe<ResponseStatusType>;
  PhysicianRegAuthority?: Maybe<Array<Maybe<PhysicianRegAuthorityResponseType>>>;
  /** IGNORE: Dummy field necessary for the Mutation type to be valid */
  _empty?: Maybe<Scalars['String']>;
  addDashboard?: Maybe<Dashboard>;
  addOETViewFilters?: Maybe<Dashboard>;
  addViewFilters?: Maybe<Dashboard>;
  deleteDashboard?: Maybe<DeleteDashboardResponse>;
  deleteOETViewFilter?: Maybe<DeleteDashboardResponse>;
  deleteViewFilter?: Maybe<DeleteDashboardResponse>;
  deleteViewFilters?: Maybe<DeleteDashboardResponse>;
  requestRefund?: Maybe<RequestPayload>;
  requestSecureToken?: Maybe<TokenPayload>;
  saveLastAccessedView?: Maybe<Dashboard>;
  saveOETViewFilters?: Maybe<Dashboard>;
  saveViewFilters?: Maybe<Dashboard>;
  uploadDocument?: Maybe<SasToken>;
  validateEmail?: Maybe<ValidationResult>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAdminPortal_Events_RepublishArgs = {
  parentIds: Array<Scalars['String']>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_MiniCex_AddArgs = {
  input: AddMiniCexInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_MiniCex_RemoveArgs = {
  dataVersion: Scalars['Int'];
  encounterId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_LoginArgs = {
  password: Scalars['String'];
  userid: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway1aArgs = {
  input: Pathway1Input;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway1bArgs = {
  input: Pathway1Input;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway2aArgs = {
  input: Pathway2Input;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway2bArgs = {
  input?: InputMaybe<Pathway2Input>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_PathwayXArgs = {
  input: ExceptionRequestInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAuthorityPortal_AttestArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAuthorityPortal_RejectArgs = {
  dataVersion: Scalars['Int'];
  rejectionReason: Scalars['String'];
  rejectionReasonText?: InputMaybe<Scalars['String']>;
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AuthorityMgmt_SyncAccountFromB2CArgs = {
  userName?: InputMaybe<Scalars['String']>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_MiniCex_CancelArgs = {
  encounterId: Scalars['String'];
  isMiniCEXAssessmentResetConfirmed: Scalars['Boolean'];
  minicexDataVersion: Scalars['Int'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_MiniCex_RejectArgs = {
  encounterId: Scalars['String'];
  minicexDataVersion: Scalars['Int'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_BlockArgs = {
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_EditDoBArgs = {
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
  input: PhysicianDoBInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_EditNameArgs = {
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
  input?: InputMaybe<PhysicianNameInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_OptInArgs = {
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_OptOutArgs = {
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
  input?: InputMaybe<PhysicianOptOutInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_SyncRegistrationArgs = {
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_UnblockArgs = {
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_SubmissionSummaryArgs = {
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AddCaseNoteArgs = {
  dataVersion: Scalars['Int'];
  input: CaseNoteInput;
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AdditionalDocumentsArgs = {
  dataVersion: Scalars['Int'];
  input: Array<AdditionalDocumentInput>;
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_ArchivedCase_SubmissionSummaryArgs = {
  caseId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AssignEligibilityReviewTaskArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AssignQaReviewTaskArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Case_ArchiveArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Case_PurgeArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_CheckCaseExistsArgs = {
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_ConvertToExceptionCaseArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_CreateExceptionCaseArgs = {
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_GlDistributionReportArgs = {
  filter?: InputMaybe<GlDistributionReportFilterInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_IssuePaperCheckArgs = {
  amount: Scalars['Int'];
  dataVersion?: InputMaybe<Scalars['Int']>;
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_LanguageAssessment_SetReviewArgs = {
  dataVersion: Scalars['Int'];
  input: LanguageAssessmentReviewInput;
  isComplete: Scalars['Boolean'];
  recordId?: InputMaybe<Scalars['ID']>;
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_OverridePathwayArgs = {
  dataVersion: Scalars['Int'];
  input: OverridePathwayInput;
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway1_DeleteRecordArgs = {
  dataVersion: Scalars['Int'];
  recordId: Scalars['ID'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway1_SetDataArgs = {
  dataVersion: Scalars['Int'];
  input: LicenseDataInput;
  recordId?: InputMaybe<Scalars['ID']>;
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway1_SetReviewArgs = {
  dataVersion: Scalars['Int'];
  input: LicenseReviewInput;
  isComplete: Scalars['Boolean'];
  recordId: Scalars['ID'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway2_SetReviewArgs = {
  dataVersion: Scalars['Int'];
  input: CsExamReviewInput;
  isComplete: Scalars['Boolean'];
  recordId?: InputMaybe<Scalars['ID']>;
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway6_SetReviewArgs = {
  dataVersion: Scalars['Int'];
  input: MiniCexReviewInput;
  isComplete: Scalars['Boolean'];
  recordId?: InputMaybe<Scalars['ID']>;
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway6_UnlockReviewArgs = {
  dataVersion: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
  recordId: Scalars['ID'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway345_SetReviewArgs = {
  dataVersion: Scalars['Int'];
  input: EducationReviewInput;
  isComplete: Scalars['Boolean'];
  recordId?: InputMaybe<Scalars['ID']>;
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PathwayX_SetReviewArgs = {
  dataVersion: Scalars['Int'];
  input: ExceptionRequestReviewInput;
  isComplete: Scalars['Boolean'];
  recordId: Scalars['ID'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Process_GeneralEligibilityArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_AcceptDecisionArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_ApproveArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_OverrideDecisionArgs = {
  comments: Scalars['String'];
  dataVersion: Scalars['Int'];
  decision: Scalars['String'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_RejectArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_ReworkRequiredArgs = {
  dataVersion: Scalars['Int'];
  processGEChecksOnQARework: Scalars['Boolean'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_ReopenCaseArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Review_ReworkRequiredArgs = {
  dataVersion: Scalars['Int'];
  usmleId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationGlDistributionConfig_CreateArgs = {
  input: GlDistributionConfigInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationGlDistributionConfig_DeactivateArgs = {
  _id: Scalars['ID'];
  version: Scalars['Int'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationGlDistributionConfig_EditArgs = {
  _id: Scalars['ID'];
  input: GlDistributionConfigEditInput;
  version: Scalars['Int'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_Mgmt_OptOutArgs = {
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
  input?: InputMaybe<PhysicianOptOutInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_Mgmt_OptOut_NoauthArgs = {
  email: Scalars['String'];
  input?: InputMaybe<PhysicianOptOutInput>;
  token: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_Mgmt_SetProfileArgs = {
  dataVersion: Scalars['Int'];
  input: PhysicianProfileInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_AcceptArgs = {
  dataVersion: Scalars['Int'];
  encounterId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_AttestArgs = {
  dataVersion: Scalars['Int'];
  encounterId: Scalars['String'];
  input?: InputMaybe<AttestMiniCexInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_CompleteArgs = {
  dataVersion: Scalars['Int'];
  encounterId: Scalars['String'];
  preSubmissionSurvey?: InputMaybe<PhysicianMiniCexPreSubmissionSurveyInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_GetArgs = {
  encounterId: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_RejectArgs = {
  dataVersion: Scalars['Int'];
  encounterId: Scalars['String'];
  input: RejectMiniCexInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetAdditionalInfoArgs = {
  dataVersion: Scalars['Int'];
  encounterId: Scalars['String'];
  input: MiniCexAdditionalInfoInput;
  isComplete: Scalars['Boolean'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetEncounterArgs = {
  dataVersion: Scalars['Int'];
  encounterId: Scalars['String'];
  input: MiniCexEncounterInput;
  isComplete: Scalars['Boolean'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetEvaluationArgs = {
  dataVersion: Scalars['Int'];
  encounterId: Scalars['String'];
  input: MiniCexEvaluationInput;
  isComplete: Scalars['Boolean'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetSurveyArgs = {
  dataVersion: Scalars['Int'];
  encounterId: Scalars['String'];
  input?: InputMaybe<MiniCexSurveyInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianRegAuthorityArgs = {
  input?: InputMaybe<PhysicianRegAuthorityInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAddDashboardArgs = {
  input: DashboardInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAddOetViewFiltersArgs = {
  input: OetViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAddViewFiltersArgs = {
  input: ViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteDashboardArgs = {
  _id: Scalars['ID'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteOetViewFilterArgs = {
  oetViewId: Scalars['ID'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteViewFilterArgs = {
  viewId: Scalars['ID'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteViewFiltersArgs = {
  dashboardId: Scalars['ID'];
  dashboardType: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationRequestRefundArgs = {
  requestArgument?: InputMaybe<RefundRequestArgument>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationRequestSecureTokenArgs = {
  requestArgument?: InputMaybe<TokenRequestArgument>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationSaveLastAccessedViewArgs = {
  input: LastAccessedViewInput;
  viewType: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationSaveOetViewFiltersArgs = {
  input: OetViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationSaveViewFiltersArgs = {
  input: ViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationUploadDocumentArgs = {
  fileName: Scalars['String'];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationValidateEmailArgs = {
  email: Scalars['String'];
};

export type OetInput = {
  candidateId: Scalars['String'];
  dateOfBirth?: InputMaybe<Scalars['String']>;
  examDate?: InputMaybe<Scalars['String']>;
  examineeFirstName?: InputMaybe<Scalars['String']>;
  examineeLastName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  isMatched?: InputMaybe<Scalars['Boolean']>;
  listeningScore?: InputMaybe<Scalars['Float']>;
  readingScore?: InputMaybe<Scalars['Float']>;
  result?: InputMaybe<Scalars['String']>;
  schemaVersion: Scalars['String'];
  speakingScore?: InputMaybe<Scalars['Float']>;
  testingCenter?: InputMaybe<Scalars['String']>;
  usmleId?: InputMaybe<Scalars['String']>;
  writingScore?: InputMaybe<Scalars['Float']>;
};

export type OetViewFilters = {
  __typename?: 'OETViewFilters';
  matchState?: Maybe<Scalars['Boolean']>;
  resultState?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OetViewFiltersInput = {
  matchState?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resultState?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OetViews = {
  __typename?: 'OETViews';
  _id?: Maybe<Scalars['CosmosObjectIdString']>;
  displayColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  viewFilters?: Maybe<OetViewFilters>;
  viewName?: Maybe<Scalars['String']>;
};

export type OetViewsInput = {
  _id?: InputMaybe<Scalars['CosmosObjectIdString']>;
  displayColumns?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewFilters?: InputMaybe<OetViewFiltersInput>;
  viewName?: InputMaybe<Scalars['String']>;
};

export type OetPortalResponseType = {
  __typename?: 'OetPortalResponseType';
  oetscores?: Maybe<Array<Maybe<OetResponseType>>>;
};

export type OetResponseType = {
  __typename?: 'OetResponseType';
  _id?: Maybe<Scalars['String']>;
  candidateId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateString']>;
  createdUser?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  examDate?: Maybe<Scalars['String']>;
  examineeFirstName?: Maybe<Scalars['String']>;
  examineeLastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  listeningScore?: Maybe<Scalars['String']>;
  matched?: Maybe<Scalars['String']>;
  matchedCandidateId?: Maybe<Scalars['String']>;
  matchedCaseState?: Maybe<Scalars['String']>;
  matchedDateOfBirth?: Maybe<Scalars['String']>;
  matchedEnglishEligibilityStatus?: Maybe<Scalars['String']>;
  matchedFirstName?: Maybe<Scalars['String']>;
  matchedLastName?: Maybe<Scalars['String']>;
  matchedUsmleId?: Maybe<Scalars['String']>;
  matchedexamDate?: Maybe<Scalars['String']>;
  readingScore?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  speakingScore?: Maybe<Scalars['String']>;
  testingCenter?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateString']>;
  updatedUser?: Maybe<Scalars['String']>;
  usmleId?: Maybe<Scalars['String']>;
  writingScore?: Maybe<Scalars['String']>;
};

export type OverridePathwayInput = {
  currentPathway: Scalars['String'];
  usmleId: Scalars['String'];
};

export type PastAttemptsType = {
  __typename?: 'PastAttemptsType';
  applicationType?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateString']>;
  output?: Maybe<Scalars['String']>;
  pathway?: Maybe<Scalars['String']>;
  pathwaySeason?: Maybe<Scalars['String']>;
  refundIssued?: Maybe<Scalars['String']>;
  rejectionReason?: Maybe<Array<Maybe<Scalars['String']>>>;
  submittedAt?: Maybe<Scalars['DateString']>;
};

export type Pathway1 = {
  __typename?: 'Pathway1';
  applicantProvidedData?: Maybe<Array<Maybe<License>>>;
  staffProvidedData?: Maybe<Array<Maybe<License>>>;
  userResponse?: Maybe<Pathway1UserResponse>;
};

export type Pathway1Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<LicenseInput>>>;
  userResponse?: InputMaybe<Pathway1UserResponseInput>;
};

export type Pathway1UserResponse = {
  __typename?: 'Pathway1UserResponse';
  isCurrentlyLicensed?: Maybe<Scalars['Boolean']>;
  wasLicensedInRecentPast?: Maybe<Scalars['Boolean']>;
};

export type Pathway1UserResponseInput = {
  isCurrentlyLicensed?: InputMaybe<Scalars['Boolean']>;
  wasLicensedInRecentPast?: InputMaybe<Scalars['Boolean']>;
};

export type Pathway2 = {
  __typename?: 'Pathway2';
  applicantProvidedData?: Maybe<Array<Maybe<CsExam>>>;
  staffProvidedData?: Maybe<Array<Maybe<CsExam>>>;
  userResponse?: Maybe<Pathway2UserResponse>;
};

export type Pathway2Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<CsExamInput>>>;
  userResponse?: InputMaybe<Pathway2UserResponseInput>;
};

export type Pathway2UserResponse = {
  __typename?: 'Pathway2UserResponse';
  hasPassedCSExamInRecentPast?: Maybe<Scalars['Boolean']>;
  hasPassedFromQualifiedSchoolInRecentPast?: Maybe<Scalars['Boolean']>;
};

export type Pathway2UserResponseInput = {
  hasPassedCSExamInRecentPast?: InputMaybe<Scalars['Boolean']>;
  hasPassedFromQualifiedSchoolInRecentPast?: InputMaybe<Scalars['Boolean']>;
};

export type Pathway6 = {
  __typename?: 'Pathway6';
  applicantProvidedData?: Maybe<Array<Maybe<Pathway6MiniCexType>>>;
  minicexList?: Maybe<Array<Maybe<MiniCexResponseType>>>;
  staffProvidedData?: Maybe<Array<Maybe<Pathway6MiniCexType>>>;
  userResponse?: Maybe<Pathway6UserResponse>;
};

export type Pathway6MinicexListArgs = {
  filter: MiniCexListFilterCaseManagerInput;
};

export type Pathway6Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<Pathway6MiniCexInput>>>;
  userResponse?: InputMaybe<Pathway6UserResponseInput>;
};

export type Pathway6MiniCexInput = {
  data?: InputMaybe<MiniCexDataInput>;
};

export type Pathway6MiniCexType = {
  __typename?: 'Pathway6MiniCEXType';
  _id?: Maybe<Scalars['ID']>;
  areAllMiniCEXComplete?: Maybe<Scalars['Boolean']>;
  assessment?: Maybe<MiniCexAssessmentType>;
  data?: Maybe<MiniCexData>;
  review?: Maybe<MiniCexReview>;
};

export type Pathway6UserResponse = {
  __typename?: 'Pathway6UserResponse';
  hasAgreedToProvidePhysicianInfo?: Maybe<Scalars['Boolean']>;
};

export type Pathway6UserResponseInput = {
  hasAgreedToProvidePhysicianInfo?: InputMaybe<Scalars['Boolean']>;
};

export type Pathway345 = {
  __typename?: 'Pathway345';
  applicantProvidedData?: Maybe<Array<Maybe<Education>>>;
  staffProvidedData?: Maybe<Array<Maybe<Education>>>;
  userResponse?: Maybe<Pathway345UserResponse>;
};

export type Pathway345Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<EducationInput>>>;
  userResponse?: InputMaybe<Pathway345UserResponseInput>;
};

export type Pathway345UserResponse = {
  __typename?: 'Pathway345UserResponse';
  isGradDateInAcceptableRange?: Maybe<Scalars['Boolean']>;
  isGradSchoolEligible?: Maybe<Scalars['Boolean']>;
};

export type Pathway345UserResponseInput = {
  isGradDateInAcceptableRange?: InputMaybe<Scalars['Boolean']>;
  isGradSchoolEligible?: InputMaybe<Scalars['Boolean']>;
};

export type Payment = {
  __typename?: 'Payment';
  amt?: Maybe<Scalars['Int']>;
  pnRef?: Maybe<Scalars['String']>;
  transTime?: Maybe<Scalars['DateString']>;
};

export type PhysicianByMiniCexFilterCaseManagerInput = {
  responseType?: InputMaybe<Scalars['String']>;
  usmleId: Scalars['String'];
};

export type PhysicianConfigRecordType = {
  __typename?: 'PhysicianConfigRecordType';
  minicex?: Maybe<PhysicianMiniCexConfigType>;
};

export type PhysicianConfigType = {
  __typename?: 'PhysicianConfigType';
  config?: Maybe<PhysicianConfigRecordType>;
  physicianConfigRef?: Maybe<Scalars['CosmosObjectIdString']>;
};

export type PhysicianDoBInput = {
  dateOfBirth: Scalars['Date'];
};

export type PhysicianFaimerSurveyQuestionType = {
  __typename?: 'PhysicianFaimerSurveyQuestionType';
  comments?: Maybe<Scalars['String']>;
  questionDescription?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
};

export type PhysicianFaimerSurveyType = {
  __typename?: 'PhysicianFaimerSurveyType';
  completedAt?: Maybe<Scalars['Date']>;
  pathwaySeason?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<Maybe<PhysicianFaimerSurveyQuestionType>>>;
};

export type PhysicianLicenseInput = {
  _id?: InputMaybe<Scalars['String']>;
  countryName: Scalars['String'];
  licenseNumber: Scalars['String'];
  orgName: Scalars['String'];
  physicianRegAuthorityRef: Scalars['Int'];
};

export type PhysicianLicenseRecordType = {
  __typename?: 'PhysicianLicenseRecordType';
  _id?: Maybe<Scalars['String']>;
  addedAt?: Maybe<Scalars['DateString']>;
  countryName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  physicianRegAuthorityRef?: Maybe<Scalars['Int']>;
  removedAt?: Maybe<Scalars['DateString']>;
};

export type PhysicianMiniCexConfigType = {
  __typename?: 'PhysicianMiniCEXConfigType';
  maxApplicantsAllowedToBeEvaluatedByPhysician?: Maybe<Scalars['Int']>;
};

export type PhysicianMiniCexPreSubmissionSurveyInput = {
  questions?: InputMaybe<Array<InputMaybe<PhysicianMiniCexPreSubmissionSurveyQuestionInput>>>;
};

export type PhysicianMiniCexPreSubmissionSurveyQuestionInput = {
  comments?: InputMaybe<Scalars['String']>;
  questionDescription?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

export type PhysicianMinicexAccountType = {
  __typename?: 'PhysicianMinicexAccountType';
  accountStatus?: Maybe<Scalars['String']>;
  accountStatusComments?: Maybe<Scalars['String']>;
  faimerSurveyCurrentPathwaySeason?: Maybe<PhysicianFaimerSurveyType>;
  optOut?: Maybe<PhysicianOptOutType>;
  profileStatus?: Maybe<Scalars['String']>;
  profileStatusComments?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<PhysicianTagsType>;
};

export type PhysicianNameInput = {
  email: Scalars['String'];
  lastName: Scalars['String'];
  restOfName?: InputMaybe<Scalars['String']>;
};

export type PhysicianOptOutInput = {
  reason?: InputMaybe<Scalars['String']>;
  reasonComments?: InputMaybe<Scalars['String']>;
};

export type PhysicianOptOutType = {
  __typename?: 'PhysicianOptOutType';
  lastAccountStatus?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reasonComments?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateString']>;
};

export type PhysicianPortalResponseType = {
  __typename?: 'PhysicianPortalResponseType';
  minicex?: Maybe<MiniCexResponseType>;
  minicexList?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  physician?: Maybe<PhysicianResponseType>;
};

export type PhysicianPortalResponseTypeMinicexArgs = {
  encounterId: Scalars['String'];
};

export type PhysicianPortalResponseTypeMinicexListArgs = {
  filter: MiniCexListFilterPhysicianInput;
};

export type PhysicianProfileInput = {
  dateOfBirth: Scalars['Date'];
  isHoldingFacultyAppointment: Scalars['String'];
  lastName: Scalars['String'];
  licenses?: InputMaybe<Array<InputMaybe<PhysicianLicenseInput>>>;
  medicalSpecialty?: InputMaybe<Scalars['String']>;
  restOfName?: InputMaybe<Scalars['String']>;
  secondaryEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  usmleId?: InputMaybe<Scalars['String']>;
};

export type PhysicianProfileType = {
  __typename?: 'PhysicianProfileType';
  dateOfBirth?: Maybe<Scalars['Date']>;
  isHoldingFacultyAppointment?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenses?: Maybe<Array<Maybe<PhysicianLicenseRecordType>>>;
  medicalSpecialty?: Maybe<Scalars['String']>;
  removedLicenses?: Maybe<Array<Maybe<PhysicianLicenseRecordType>>>;
  restOfName?: Maybe<Scalars['String']>;
  secondaryEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  usmleId?: Maybe<Scalars['String']>;
};

export type PhysicianResponseType = {
  __typename?: 'PhysicianResponseType';
  activityLog?: Maybe<Array<Maybe<ActivityLogType>>>;
  allowedActions?: Maybe<Array<Maybe<Scalars['String']>>>;
  applicantsEvaluatedByPhysician?: Maybe<Array<Maybe<A_Applicant>>>;
  dataVersion?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  minicex?: Maybe<PhysicianMinicexAccountType>;
  oid?: Maybe<Scalars['String']>;
  physicianConfig?: Maybe<PhysicianConfigType>;
  physicianId?: Maybe<Scalars['String']>;
  profile?: Maybe<PhysicianProfileType>;
};

export type PhysicianResponseTypeApplicantsEvaluatedByPhysicianArgs = {
  email?: InputMaybe<Scalars['String']>;
};

export type PhysicianTagsType = {
  __typename?: 'PhysicianTagsType';
  actionTags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PhysiciansByArchivedMinicexFilterCaseManagerInput = {
  caseId: Scalars['String'];
  responseType?: InputMaybe<Scalars['String']>;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type Query = {
  __typename?: 'Query';
  /** IGNORE: Dummy field necessary for the Query type to be valid */
  _empty?: Maybe<Scalars['String']>;
  applicantPortal?: Maybe<ApplicantPortalResponseType>;
  archivedCase?: Maybe<ArchivedCaseType>;
  archivedCases?: Maybe<Array<Maybe<ArchivedCaseSummaryType>>>;
  authorityPortal?: Maybe<AuthorityPortalResponseType>;
  casePortal?: Maybe<CasePortalResponseType>;
  country?: Maybe<Array<Maybe<CountryResponseType>>>;
  downloadDocument?: Maybe<SasToken>;
  getDashboards?: Maybe<Array<Maybe<Dashboard>>>;
  oscexam?: Maybe<Array<Maybe<OscexamResponseType>>>;
  physicianPortal?: Maybe<PhysicianPortalResponseType>;
  physicianRegAuthority?: Maybe<Array<Maybe<PhysicianRegAuthorityResponseType>>>;
  physiciansByArchivedMinicex?: Maybe<Array<Maybe<PhysicianResponseType>>>;
  regulatoryAuthority?: Maybe<Array<Maybe<RegulatoryAuthorityResponseType>>>;
  school?: Maybe<Array<Maybe<SchoolResponseType>>>;
  serverDate: Scalars['DateString'];
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryArchivedCaseArgs = {
  caseId: Scalars['String'];
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryArchivedCasesArgs = {
  filter: ArchivedCaseFilterInput;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryDownloadDocumentArgs = {
  fileName: Scalars['String'];
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryPhysicianRegAuthorityArgs = {
  input?: InputMaybe<PhysicianRegAuthorityInput>;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryPhysiciansByArchivedMinicexArgs = {
  filter: PhysiciansByArchivedMinicexFilterCaseManagerInput;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QuerySchoolArgs = {
  pathway?: InputMaybe<Scalars['String']>;
};

export type Refund = {
  __typename?: 'Refund';
  amt?: Maybe<Scalars['Int']>;
  pnRef?: Maybe<Scalars['String']>;
  transTime?: Maybe<Scalars['DateString']>;
};

export type RefundRequestArgument = {
  amount?: InputMaybe<Scalars['String']>;
  caseWorker?: InputMaybe<Scalars['String']>;
  usmleID?: InputMaybe<Scalars['String']>;
};

export type RejectMiniCexInput = {
  rejectionReason: Scalars['String'];
  rejectionReasonComments?: InputMaybe<Scalars['String']>;
};

export type RequestPayload = {
  __typename?: 'RequestPayload';
  pnRef?: Maybe<Scalars['String']>;
  responseMessage?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
};

export type ResponseStatusType = {
  __typename?: 'ResponseStatusType';
  code?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
};

export type RestrictionType = {
  __typename?: 'RestrictionType';
  comments?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  releaseDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type SasToken = {
  __typename?: 'SASToken';
  token?: Maybe<Token>;
};

export type SchoolEligibilityType = {
  __typename?: 'SchoolEligibilityType';
  isSchoolEMSWPMember?: Maybe<Scalars['Boolean']>;
  schoolPathway?: Maybe<Scalars['String']>;
};

export type SchoolInput = {
  _id?: InputMaybe<Scalars['Int']>;
  countryName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type SchoolResponseType = {
  __typename?: 'SchoolResponseType';
  _id?: Maybe<Scalars['Int']>;
  countryName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SearchOetInput = {
  candidateId?: InputMaybe<Scalars['String']>;
  examineeFirstName?: InputMaybe<Scalars['String']>;
  examineeLastName?: InputMaybe<Scalars['String']>;
  matched?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  output?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  usmleId?: InputMaybe<Scalars['String']>;
};

export type Token = {
  __typename?: 'Token';
  token: Scalars['String'];
  uri: Scalars['String'];
};

export type TokenPayload = {
  __typename?: 'TokenPayload';
  alreadyPaid?: Maybe<Scalars['String']>;
  errorDetails?: Maybe<Scalars['String']>;
  secureToken?: Maybe<Scalars['String']>;
  secureTokenId?: Maybe<Scalars['String']>;
};

export type TokenRequestArgument = {
  acknowledgeTimestamp?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  billToCity?: InputMaybe<Scalars['String']>;
  billToFirstName?: InputMaybe<Scalars['String']>;
  billToLastName?: InputMaybe<Scalars['String']>;
  billToMiddleName?: InputMaybe<Scalars['String']>;
  billToState?: InputMaybe<Scalars['String']>;
  billToStreet?: InputMaybe<Scalars['String']>;
  billToStreet2?: InputMaybe<Scalars['String']>;
  billToZip?: InputMaybe<Scalars['String']>;
  usmleID?: InputMaybe<Scalars['String']>;
};

export type UiComponentConfigType = {
  __typename?: 'UIComponentConfigType';
  componentName?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type UiConfigRecordType = {
  __typename?: 'UIConfigRecordType';
  pages?: Maybe<Array<Maybe<UiPageConfigType>>>;
  portal?: Maybe<Scalars['String']>;
};

export type UiPageConfigType = {
  __typename?: 'UIPageConfigType';
  components?: Maybe<Array<Maybe<UiComponentConfigType>>>;
  pageName?: Maybe<Scalars['String']>;
};

export type UiPortalConfigType = {
  __typename?: 'UIPortalConfigType';
  config?: Maybe<UiConfigRecordType>;
  uiConfigRef?: Maybe<Scalars['CosmosObjectIdString']>;
};

export type UserType = {
  __typename?: 'UserType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type ValidationResult = {
  __typename?: 'ValidationResult';
  errorMessage?: Maybe<Scalars['String']>;
  isValidated?: Maybe<Scalars['Boolean']>;
};

export type ViewFilters = {
  __typename?: 'ViewFilters';
  applicationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  caseState?: Maybe<Array<Maybe<Scalars['String']>>>;
  englishEligibilityStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  generalEligibilityStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathway?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathwayEligibilityStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  regulatoryAuthority?: Maybe<Array<Maybe<Scalars['Int']>>>;
  school?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ViewFiltersInput = {
  applicationType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  caseState?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  englishEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  generalEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pathway?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pathwayEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  regulatoryAuthority?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  school?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type Views = {
  __typename?: 'Views';
  _id?: Maybe<Scalars['CosmosObjectIdString']>;
  displayColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  viewFilters?: Maybe<ViewFilters>;
  viewName?: Maybe<Scalars['String']>;
};

export type ViewsInput = {
  _id?: InputMaybe<Scalars['CosmosObjectIdString']>;
  displayColumns?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewFilters?: InputMaybe<ViewFiltersInput>;
  viewName?: InputMaybe<Scalars['String']>;
};

export type CountryResponseType = {
  __typename?: 'countryResponseType';
  _id?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  isoCountryCode?: Maybe<Scalars['String']>;
};

export type GlDistributionReportFilterInput = {
  transactionDateFrom?: InputMaybe<Scalars['String']>;
  transactionDateTo?: InputMaybe<Scalars['String']>;
};

export type GlDistributionReportResponseType = {
  __typename?: 'glDistributionReportResponseType';
  amount?: Maybe<Scalars['Int']>;
  applicationType?: Maybe<Scalars['String']>;
  feeType?: Maybe<Scalars['String']>;
  glCreditAccountId?: Maybe<Scalars['String']>;
  glDebitAccountId?: Maybe<Scalars['String']>;
  paymentNetworkReferenceId?: Maybe<Scalars['String']>;
  transactionTimestamp?: Maybe<Scalars['DateString']>;
  transactionType?: Maybe<Scalars['String']>;
  trsAccountId?: Maybe<Scalars['String']>;
  usmleId?: Maybe<Scalars['String']>;
};

export type OscexamInput = {
  _id?: InputMaybe<Scalars['String']>;
  examCode?: InputMaybe<Scalars['String']>;
  examFullName?: InputMaybe<Scalars['String']>;
  examShortTitle?: InputMaybe<Scalars['String']>;
};

export type OscexamResponseType = {
  __typename?: 'oscexamResponseType';
  _id?: Maybe<Scalars['String']>;
  examCode?: Maybe<Scalars['String']>;
  examFullName?: Maybe<Scalars['String']>;
  examShortTitle?: Maybe<Scalars['String']>;
};

export type PhysicianRegAuthorityInput = {
  _id?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<Scalars['String']>;
};

export type PhysicianRegAuthorityResponseType = {
  __typename?: 'physicianRegAuthorityResponseType';
  _id?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
};

export type RegulatoryAuthorityInput = {
  _id?: InputMaybe<Scalars['Int']>;
  countryName?: InputMaybe<Scalars['String']>;
  orgName?: InputMaybe<Scalars['String']>;
};

export type RegulatoryAuthorityResponseType = {
  __typename?: 'regulatoryAuthorityResponseType';
  _id?: Maybe<Scalars['Int']>;
  countryName?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
};

export type CaseMgmtViewPdFdownloadDocumentQueryVariables = Exact<{
  filename: Scalars['String'];
}>;

export type CaseMgmtViewPdFdownloadDocumentQuery = {
  __typename?: 'Query';
  downloadDocument?:
    | {
        __typename?: 'SASToken';
        token?: { __typename?: 'Token'; token: string; uri: string } | null | undefined;
      }
    | null
    | undefined;
};

export type ArchivedCaseListContainerArchivedCasesQueryVariables = Exact<{
  filter: ArchivedCaseFilterInput;
}>;

export type ArchivedCaseListContainerArchivedCasesQuery = {
  __typename?: 'Query';
  archivedCases?:
    | Array<
        | {
            __typename?: 'ArchivedCaseSummaryType';
            _id: string;
            pathwaySeason?: number | null | undefined;
            pathwayNumber?: string | null | undefined;
            applicationType?: string | null | undefined;
            decisionOutput?: string | null | undefined;
            submittedOn?: any | null | undefined;
            reviewCompletedOn?: any | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type PathwayXArchivedCaseReviewContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type PathwayXArchivedCaseReviewContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        reviews?:
          | {
              __typename?: 'ArchivedCaseReviewsType';
              oet?:
                | Array<
                    | {
                        __typename?: 'ArchivedCaseOetReviewType';
                        checklistState?: string | null | undefined;
                        checklist?:
                          | {
                              __typename?: 'LanguageAssessmentChecklist';
                              documentCompleteLegible?: string | null | undefined;
                              examDateValidation?: string | null | undefined;
                              nameValid?: string | null | undefined;
                              scoreAcceptable?: string | null | undefined;
                              scoreConfirmed?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        data?:
                          | {
                              __typename?: 'LanguageAssessmentData';
                              _id?: string | null | undefined;
                              candidateId?: string | null | undefined;
                              examDate?: string | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        uploadedBy?:
                                          | {
                                              __typename?: 'DocumentUploadUser';
                                              id?: string | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              status?: string | null | undefined;
                              title: string;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                    role?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pathwayX?:
                | Array<
                    | {
                        __typename?: 'ArchivedCasePathwayXReviewType';
                        exceptionChecklistState?: string | null | undefined;
                        pathwayChecklistState?: string | null | undefined;
                        checklist?:
                          | {
                              __typename?: 'ExceptionRequestChecklist';
                              applicantEligiblePathway1?: string | null | undefined;
                              applicantEligiblePathway2?: string | null | undefined;
                              applicantEligiblePathway345?: string | null | undefined;
                              applicantHasNotSubmittedAppForCert?: string | null | undefined;
                              applicantHasPurchasedToken2021Match?: string | null | undefined;
                              applicantIsNotCertified?: string | null | undefined;
                              applicantNotBarredByUSMLE?: string | null | undefined;
                              applicantNotValidStep2CSScore?: string | null | undefined;
                              ecfmgRestrictionsDoNotPreventAppExamination?:
                                | string
                                | null
                                | undefined;
                              exceptionApproved?: string | null | undefined;
                              pathway1DocsVerified?: string | null | undefined;
                              pathway2DocsVerified?: string | null | undefined;
                              pathway345DocsVerified?: string | null | undefined;
                              step1PassedWithOneOrNoFails?: string | null | undefined;
                              step2CKPassedWithOneOrNoFails?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        data?:
                          | {
                              __typename?: 'ExceptionRequestData';
                              requestText?: string | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        uploadedBy?:
                                          | {
                                              __typename?: 'DocumentUploadUser';
                                              id?: string | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              status?: string | null | undefined;
                              title: string;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                    role?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        uiConfig?:
          | {
              __typename?: 'UIPortalConfigType';
              config?:
                | {
                    __typename?: 'UIConfigRecordType';
                    pages?:
                      | Array<
                          | {
                              __typename?: 'UIPageConfigType';
                              pageName?: string | null | undefined;
                              components?:
                                | Array<
                                    | {
                                        __typename?: 'UIComponentConfigType';
                                        componentName?: string | null | undefined;
                                        version?: number | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Pathway1ArchivedCaseReviewContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type Pathway1ArchivedCaseReviewContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        externalId?: string | null | undefined;
        reviews?:
          | {
              __typename?: 'ArchivedCaseReviewsType';
              oet?:
                | Array<
                    | {
                        __typename?: 'ArchivedCaseOetReviewType';
                        checklistState?: string | null | undefined;
                        checklist?:
                          | {
                              __typename?: 'LanguageAssessmentChecklist';
                              documentCompleteLegible?: string | null | undefined;
                              examDateValidation?: string | null | undefined;
                              nameValid?: string | null | undefined;
                              scoreAcceptable?: string | null | undefined;
                              scoreConfirmed?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        data?:
                          | {
                              __typename?: 'LanguageAssessmentData';
                              _id?: string | null | undefined;
                              candidateId?: string | null | undefined;
                              examDate?: string | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        uploadedBy?:
                                          | {
                                              __typename?: 'DocumentUploadUser';
                                              id?: string | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              status?: string | null | undefined;
                              title: string;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                    role?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pathway1?:
                | Array<
                    | {
                        __typename?: 'ArchivedCasePathway1ReviewType';
                        checklistState?: string | null | undefined;
                        checklist?:
                          | {
                              __typename?: 'LicenseChecklist';
                              licensureApplicantLicenseOnPoint?: string | null | undefined;
                              licensureCompleteLegible?: string | null | undefined;
                              licensureDisciplinaryAction?: string | null | undefined;
                              licensureDocumentIssueDateAcceptable?: string | null | undefined;
                              licensureIssuedAppropriateAuthority?: string | null | undefined;
                              licensureNameValidation?: string | null | undefined;
                              licensureTranslationStatus?: string | null | undefined;
                              licensureUnrestrictedLicense?: string | null | undefined;
                              verificationActiveLicense?: string | null | undefined;
                              verificationAuthorized?: string | null | undefined;
                              verificationGoodStanding?: string | null | undefined;
                              verificationPrimarySource?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        data?:
                          | {
                              __typename?: 'LicenseData';
                              _id?: string | null | undefined;
                              hasDisciplinaryAction?: boolean | null | undefined;
                              isCurrentlyLicensed?: boolean | null | undefined;
                              licenseExpiryDate?: string | null | undefined;
                              licenseIssueDate?: string | null | undefined;
                              licensureDocumentStatus?: string | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        uploadedBy?:
                                          | {
                                              __typename?: 'DocumentUploadUser';
                                              id?: string | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                              regulatoryAuthorityRef?:
                                | {
                                    __typename?: 'regulatoryAuthorityResponseType';
                                    _id?: number | null | undefined;
                                    countryName?: string | null | undefined;
                                    orgName?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              status?: string | null | undefined;
                              title: string;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                    role?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        uiConfig?:
          | {
              __typename?: 'UIPortalConfigType';
              config?:
                | {
                    __typename?: 'UIConfigRecordType';
                    pages?:
                      | Array<
                          | {
                              __typename?: 'UIPageConfigType';
                              pageName?: string | null | undefined;
                              components?:
                                | Array<
                                    | {
                                        __typename?: 'UIComponentConfigType';
                                        componentName?: string | null | undefined;
                                        version?: number | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Pathway2ArchivedCaseReviewContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type Pathway2ArchivedCaseReviewContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        externalId?: string | null | undefined;
        reviews?:
          | {
              __typename?: 'ArchivedCaseReviewsType';
              oet?:
                | Array<
                    | {
                        __typename?: 'ArchivedCaseOetReviewType';
                        checklistState?: string | null | undefined;
                        checklist?:
                          | {
                              __typename?: 'LanguageAssessmentChecklist';
                              documentCompleteLegible?: string | null | undefined;
                              examDateValidation?: string | null | undefined;
                              nameValid?: string | null | undefined;
                              scoreAcceptable?: string | null | undefined;
                              scoreConfirmed?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        data?:
                          | {
                              __typename?: 'LanguageAssessmentData';
                              _id?: string | null | undefined;
                              candidateId?: string | null | undefined;
                              examDate?: string | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        uploadedBy?:
                                          | {
                                              __typename?: 'DocumentUploadUser';
                                              id?: string | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              status?: string | null | undefined;
                              title: string;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                    role?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pathway2?:
                | Array<
                    | {
                        __typename?: 'ArchivedCasePathway2ReviewType';
                        checklistState?: string | null | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              title: string;
                              status?: string | null | undefined;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    role?: string | null | undefined;
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        checklist?:
                          | {
                              __typename?: 'CSExamChecklist';
                              osceExaminationAcceptable?: string | null | undefined;
                              osceExaminationPassed?: string | null | undefined;
                              osceExaminationAfterJuly1?: string | null | undefined;
                              osceNameOK?: string | null | undefined;
                              verificationCompleted?: string | null | undefined;
                              verificationReturned?: string | null | undefined;
                              verificationPassedExamConfirmed?: string | null | undefined;
                              verificationAcceptableDateConfirmed?: string | null | undefined;
                              osceDocumentCompleteLegible?: string | null | undefined;
                              osceTranslation?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        data?:
                          | {
                              __typename?: 'CSExamData';
                              _id?: string | null | undefined;
                              examDate?: string | null | undefined;
                              oscExamRef?:
                                | {
                                    __typename?: 'oscexamResponseType';
                                    _id?: string | null | undefined;
                                    examCode?: string | null | undefined;
                                    examShortTitle?: string | null | undefined;
                                    examFullName?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              schoolRef?:
                                | {
                                    __typename?: 'SchoolResponseType';
                                    _id?: number | null | undefined;
                                    name?: string | null | undefined;
                                    countryName?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        docId?: string | null | undefined;
                                        uploadedBy?:
                                          | {
                                              __typename?: 'DocumentUploadUser';
                                              id?: string | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        uiConfig?:
          | {
              __typename?: 'UIPortalConfigType';
              config?:
                | {
                    __typename?: 'UIConfigRecordType';
                    pages?:
                      | Array<
                          | {
                              __typename?: 'UIPageConfigType';
                              pageName?: string | null | undefined;
                              components?:
                                | Array<
                                    | {
                                        __typename?: 'UIComponentConfigType';
                                        componentName?: string | null | undefined;
                                        version?: number | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Pathway345ArchivedCaseAssessmentsEmswpContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type Pathway345ArchivedCaseAssessmentsEmswpContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        assessments?:
          | {
              __typename?: 'ArchivedCaseAssessmentsType';
              emswp?:
                | {
                    __typename?: 'EducationAttestation';
                    output?: string | null | undefined;
                    outputReason?: string | null | undefined;
                    updatedTimestamp?: any | null | undefined;
                    outputReasonText?: string | null | undefined;
                    updatedBy?:
                      | { __typename?: 'UserType'; name?: string | null | undefined }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        applicant?:
          | {
              __typename?: 'ArchivedCaseApplicantType';
              profile?:
                | {
                    __typename?: 'ArchivedCaseApplicantProfileType';
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        reviews?:
          | {
              __typename?: 'ArchivedCaseReviewsType';
              pathway345?:
                | Array<
                    | {
                        __typename?: 'ArchivedCasePathway345ReviewType';
                        data?:
                          | {
                              __typename?: 'EducationData';
                              _id?: string | null | undefined;
                              schoolRef?:
                                | {
                                    __typename?: 'SchoolResponseType';
                                    name?: string | null | undefined;
                                    countryName?: string | null | undefined;
                                    _id?: number | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Pathway345ArchivedCaseReviewContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type Pathway345ArchivedCaseReviewContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        reviews?:
          | {
              __typename?: 'ArchivedCaseReviewsType';
              oet?:
                | Array<
                    | {
                        __typename?: 'ArchivedCaseOetReviewType';
                        checklistState?: string | null | undefined;
                        checklist?:
                          | {
                              __typename?: 'LanguageAssessmentChecklist';
                              documentCompleteLegible?: string | null | undefined;
                              examDateValidation?: string | null | undefined;
                              nameValid?: string | null | undefined;
                              scoreAcceptable?: string | null | undefined;
                              scoreConfirmed?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        data?:
                          | {
                              __typename?: 'LanguageAssessmentData';
                              _id?: string | null | undefined;
                              candidateId?: string | null | undefined;
                              examDate?: string | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        uploadedBy?:
                                          | {
                                              __typename?: 'DocumentUploadUser';
                                              id?: string | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              status?: string | null | undefined;
                              title: string;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                    role?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pathway345?:
                | Array<
                    | {
                        __typename?: 'ArchivedCasePathway345ReviewType';
                        checklistState?: string | null | undefined;
                        checklist?:
                          | {
                              __typename?: 'EducationChecklist';
                              attestationAuthorizedOfficial?: string | null | undefined;
                              attestationComplete?: string | null | undefined;
                              attestationCompletedByMedicalSchool?: string | null | undefined;
                              attestationSealOk?: string | null | undefined;
                              attestationSentPrimarySource?: string | null | undefined;
                              attestationSignatureDate?: string | null | undefined;
                              attestationSignatureMatch?: string | null | undefined;
                              attestationTitleOk?: string | null | undefined;
                              eligibilityGradDateEligible?: string | null | undefined;
                              eligibilityMedicalSchool?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        data?:
                          | {
                              __typename?: 'EducationData';
                              _id?: string | null | undefined;
                              gradDate?: string | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        uploadedBy?:
                                          | {
                                              __typename?: 'DocumentUploadUser';
                                              id?: string | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                              schoolRef?:
                                | {
                                    __typename?: 'SchoolResponseType';
                                    _id?: number | null | undefined;
                                    countryName?: string | null | undefined;
                                    name?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              status?: string | null | undefined;
                              title: string;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                    role?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        uiConfig?:
          | {
              __typename?: 'UIPortalConfigType';
              config?:
                | {
                    __typename?: 'UIConfigRecordType';
                    pages?:
                      | Array<
                          | {
                              __typename?: 'UIPageConfigType';
                              pageName?: string | null | undefined;
                              components?:
                                | Array<
                                    | {
                                        __typename?: 'UIComponentConfigType';
                                        componentName?: string | null | undefined;
                                        version?: number | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Pathway6ArchivedCaseAssessmentsMiniCexInvalidTableContainerArchivedCaseQueryVariables =
  Exact<{
    caseId: Scalars['String'];
    filter: ArchivedMiniCexListFilterCaseManagerInput;
  }>;

export type Pathway6ArchivedCaseAssessmentsMiniCexInvalidTableContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        assessments?:
          | {
              __typename?: 'ArchivedCaseAssessmentsType';
              minicex?:
                | {
                    __typename?: 'ArchivedCaseAssessmentMinicexType';
                    minicexList?:
                      | Array<
                          | {
                              __typename?: 'MiniCEXResponseType';
                              encounterId?: string | null | undefined;
                              summary?:
                                | {
                                    __typename?: 'MiniCEXSummaryType';
                                    minicexId?: string | null | undefined;
                                    dataVersion?: number | null | undefined;
                                    pathwaySeason?: number | null | undefined;
                                    encounterId?: string | null | undefined;
                                    physicianEmail?: string | null | undefined;
                                    physicianLastNameProvidedByApplicant?:
                                      | string
                                      | null
                                      | undefined;
                                    physicianRestOfNameProvidedByApplicant?:
                                      | string
                                      | null
                                      | undefined;
                                    physicianLastNameProvidedByPhysician?:
                                      | string
                                      | null
                                      | undefined;
                                    physicianRestOfNameProvidedByPhysician?:
                                      | string
                                      | null
                                      | undefined;
                                    usmleId?: string | null | undefined;
                                    applicantLastName?: string | null | undefined;
                                    applicantRestOfName?: string | null | undefined;
                                    createdAt?: any | null | undefined;
                                    expiresAt?: any | null | undefined;
                                    acceptedAt?: any | null | undefined;
                                    completedAt?: any | null | undefined;
                                    state?: string | null | undefined;
                                    allowedActions?:
                                      | Array<string | null | undefined>
                                      | null
                                      | undefined;
                                    isActive?: boolean | null | undefined;
                                    rejectionReason?: string | null | undefined;
                                    rejectionReasonComments?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              form?:
                                | {
                                    __typename?: 'MiniCEXFormType';
                                    attestation?:
                                      | {
                                          __typename?: 'MiniCEXAttestationType';
                                          attestedAt?:
                                            | Array<any | null | undefined>
                                            | null
                                            | undefined;
                                          hasApplicantEnrolledInClinicalRotation?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    encounter?:
                                      | {
                                          __typename?: 'MiniCEXEncounterType';
                                          encounterDate?: string | null | undefined;
                                          encounterSetting?: string | null | undefined;
                                          facility?:
                                            | {
                                                __typename?: 'MiniCEXFacilityType';
                                                name?: string | null | undefined;
                                                city?: string | null | undefined;
                                                state?: string | null | undefined;
                                                country?:
                                                  | {
                                                      __typename?: 'MiniCEXCountryType';
                                                      ref?: string | null | undefined;
                                                      name?: string | null | undefined;
                                                    }
                                                  | null
                                                  | undefined;
                                              }
                                            | null
                                            | undefined;
                                          patient?:
                                            | {
                                                __typename?: 'MiniCEXPatientType';
                                                age?: number | null | undefined;
                                                ageYear?: number | null | undefined;
                                                ageMonth?: string | null | undefined;
                                                gender?: string | null | undefined;
                                              }
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    evaluation?:
                                      | {
                                          __typename?: 'MiniCEXEvaluationType';
                                          additionalInfoText?: string | null | undefined;
                                          categories?:
                                            | Array<
                                                | {
                                                    __typename?: 'MiniCEXCategoryType';
                                                    name?: string | null | undefined;
                                                    score?: number | null | undefined;
                                                    reasonText?: string | null | undefined;
                                                    isAdditionalInfoRequired?:
                                                      | boolean
                                                      | null
                                                      | undefined;
                                                  }
                                                | null
                                                | undefined
                                              >
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              uiConfig?:
                                | {
                                    __typename?: 'UIPortalConfigType';
                                    config?:
                                      | {
                                          __typename?: 'UIConfigRecordType';
                                          pages?:
                                            | Array<
                                                | {
                                                    __typename?: 'UIPageConfigType';
                                                    pageName?: string | null | undefined;
                                                    components?:
                                                      | Array<
                                                          | {
                                                              __typename?: 'UIComponentConfigType';
                                                              componentName?:
                                                                | string
                                                                | null
                                                                | undefined;
                                                              version?: number | null | undefined;
                                                            }
                                                          | null
                                                          | undefined
                                                        >
                                                      | null
                                                      | undefined;
                                                  }
                                                | null
                                                | undefined
                                              >
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Pathway6ArchivedCaseAssessmentsMiniCexInvalidTableContainerPhysicianByMiniCexQueryVariables =
  Exact<{
    filter: PhysiciansByArchivedMinicexFilterCaseManagerInput;
  }>;

export type Pathway6ArchivedCaseAssessmentsMiniCexInvalidTableContainerPhysicianByMiniCexQuery = {
  __typename?: 'Query';
  physiciansByArchivedMinicex?:
    | Array<
        | {
            __typename?: 'PhysicianResponseType';
            physicianId?: string | null | undefined;
            dataVersion?: number | null | undefined;
            email?: string | null | undefined;
            minicex?:
              | {
                  __typename?: 'PhysicianMinicexAccountType';
                  accountStatus?: string | null | undefined;
                }
              | null
              | undefined;
            profile?:
              | {
                  __typename?: 'PhysicianProfileType';
                  restOfName?: string | null | undefined;
                  lastName?: string | null | undefined;
                  dateOfBirth?: any | null | undefined;
                  licenses?:
                    | Array<
                        | {
                            __typename?: 'PhysicianLicenseRecordType';
                            physicianRegAuthorityRef?: number | null | undefined;
                            orgName?: string | null | undefined;
                            countryName?: string | null | undefined;
                            licenseNumber?: string | null | undefined;
                            addedAt?: any | null | undefined;
                            removedAt?: any | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type Pathway6ArchivedCaseAssessmentsMiniCexScoresTableContainerArchivedCaseQueryVariables =
  Exact<{
    caseId: Scalars['String'];
    filter: ArchivedMiniCexListFilterCaseManagerInput;
  }>;

export type Pathway6ArchivedCaseAssessmentsMiniCexScoresTableContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        assessments?:
          | {
              __typename?: 'ArchivedCaseAssessmentsType';
              minicex?:
                | {
                    __typename?: 'ArchivedCaseAssessmentMinicexType';
                    minicexList?:
                      | Array<
                          | {
                              __typename?: 'MiniCEXResponseType';
                              encounterId?: string | null | undefined;
                              form?:
                                | {
                                    __typename?: 'MiniCEXFormType';
                                    evaluation?:
                                      | {
                                          __typename?: 'MiniCEXEvaluationType';
                                          categories?:
                                            | Array<
                                                | {
                                                    __typename?: 'MiniCEXCategoryType';
                                                    name?: string | null | undefined;
                                                    score?: number | null | undefined;
                                                  }
                                                | null
                                                | undefined
                                              >
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    assessment?:
                      | {
                          __typename?: 'MiniCEXAssessmentType';
                          categories?:
                            | Array<
                                | {
                                    __typename?: 'AssessmentCategoryType';
                                    avgScore?: number | null | undefined;
                                    name?: string | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerArchivedCaseQueryVariables =
  Exact<{
    caseId: Scalars['String'];
    filter: ArchivedMiniCexListFilterCaseManagerInput;
  }>;

export type Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        assessments?:
          | {
              __typename?: 'ArchivedCaseAssessmentsType';
              minicex?:
                | {
                    __typename?: 'ArchivedCaseAssessmentMinicexType';
                    minicexList?:
                      | Array<
                          | {
                              __typename?: 'MiniCEXResponseType';
                              encounterId?: string | null | undefined;
                              summary?:
                                | {
                                    __typename?: 'MiniCEXSummaryType';
                                    minicexId?: string | null | undefined;
                                    dataVersion?: number | null | undefined;
                                    pathwaySeason?: number | null | undefined;
                                    encounterId?: string | null | undefined;
                                    physicianEmail?: string | null | undefined;
                                    physicianLastNameProvidedByApplicant?:
                                      | string
                                      | null
                                      | undefined;
                                    physicianRestOfNameProvidedByApplicant?:
                                      | string
                                      | null
                                      | undefined;
                                    physicianLastNameProvidedByPhysician?:
                                      | string
                                      | null
                                      | undefined;
                                    physicianRestOfNameProvidedByPhysician?:
                                      | string
                                      | null
                                      | undefined;
                                    usmleId?: string | null | undefined;
                                    applicantLastName?: string | null | undefined;
                                    applicantRestOfName?: string | null | undefined;
                                    createdAt?: any | null | undefined;
                                    expiresAt?: any | null | undefined;
                                    acceptedAt?: any | null | undefined;
                                    completedAt?: any | null | undefined;
                                    state?: string | null | undefined;
                                    allowedActions?:
                                      | Array<string | null | undefined>
                                      | null
                                      | undefined;
                                    isActive?: boolean | null | undefined;
                                    rejectionReason?: string | null | undefined;
                                    rejectionReasonComments?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              form?:
                                | {
                                    __typename?: 'MiniCEXFormType';
                                    attestation?:
                                      | {
                                          __typename?: 'MiniCEXAttestationType';
                                          attestedAt?:
                                            | Array<any | null | undefined>
                                            | null
                                            | undefined;
                                          hasApplicantEnrolledInClinicalRotation?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    encounter?:
                                      | {
                                          __typename?: 'MiniCEXEncounterType';
                                          encounterDate?: string | null | undefined;
                                          encounterSetting?: string | null | undefined;
                                          facility?:
                                            | {
                                                __typename?: 'MiniCEXFacilityType';
                                                name?: string | null | undefined;
                                                city?: string | null | undefined;
                                                state?: string | null | undefined;
                                                country?:
                                                  | {
                                                      __typename?: 'MiniCEXCountryType';
                                                      ref?: string | null | undefined;
                                                      name?: string | null | undefined;
                                                    }
                                                  | null
                                                  | undefined;
                                              }
                                            | null
                                            | undefined;
                                          patient?:
                                            | {
                                                __typename?: 'MiniCEXPatientType';
                                                age?: number | null | undefined;
                                                ageYear?: number | null | undefined;
                                                ageMonth?: string | null | undefined;
                                                gender?: string | null | undefined;
                                              }
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    evaluation?:
                                      | {
                                          __typename?: 'MiniCEXEvaluationType';
                                          additionalInfoText?: string | null | undefined;
                                          categories?:
                                            | Array<
                                                | {
                                                    __typename?: 'MiniCEXCategoryType';
                                                    name?: string | null | undefined;
                                                    score?: number | null | undefined;
                                                    reasonText?: string | null | undefined;
                                                    isAdditionalInfoRequired?:
                                                      | boolean
                                                      | null
                                                      | undefined;
                                                  }
                                                | null
                                                | undefined
                                              >
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              uiConfig?:
                                | {
                                    __typename?: 'UIPortalConfigType';
                                    config?:
                                      | {
                                          __typename?: 'UIConfigRecordType';
                                          pages?:
                                            | Array<
                                                | {
                                                    __typename?: 'UIPageConfigType';
                                                    pageName?: string | null | undefined;
                                                    components?:
                                                      | Array<
                                                          | {
                                                              __typename?: 'UIComponentConfigType';
                                                              componentName?:
                                                                | string
                                                                | null
                                                                | undefined;
                                                              version?: number | null | undefined;
                                                            }
                                                          | null
                                                          | undefined
                                                        >
                                                      | null
                                                      | undefined;
                                                  }
                                                | null
                                                | undefined
                                              >
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerPhysicianByMiniCexQueryVariables =
  Exact<{
    filter: PhysiciansByArchivedMinicexFilterCaseManagerInput;
  }>;

export type Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerPhysicianByMiniCexQuery = {
  __typename?: 'Query';
  physiciansByArchivedMinicex?:
    | Array<
        | {
            __typename?: 'PhysicianResponseType';
            physicianId?: string | null | undefined;
            dataVersion?: number | null | undefined;
            email?: string | null | undefined;
            minicex?:
              | {
                  __typename?: 'PhysicianMinicexAccountType';
                  accountStatus?: string | null | undefined;
                }
              | null
              | undefined;
            profile?:
              | {
                  __typename?: 'PhysicianProfileType';
                  restOfName?: string | null | undefined;
                  lastName?: string | null | undefined;
                  dateOfBirth?: any | null | undefined;
                  licenses?:
                    | Array<
                        | {
                            __typename?: 'PhysicianLicenseRecordType';
                            physicianRegAuthorityRef?: number | null | undefined;
                            orgName?: string | null | undefined;
                            countryName?: string | null | undefined;
                            licenseNumber?: string | null | undefined;
                            addedAt?: any | null | undefined;
                            removedAt?: any | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type Pathway6ArchivedCaseReviewContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type Pathway6ArchivedCaseReviewContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        reviews?:
          | {
              __typename?: 'ArchivedCaseReviewsType';
              oet?:
                | Array<
                    | {
                        __typename?: 'ArchivedCaseOetReviewType';
                        checklistState?: string | null | undefined;
                        checklist?:
                          | {
                              __typename?: 'LanguageAssessmentChecklist';
                              documentCompleteLegible?: string | null | undefined;
                              examDateValidation?: string | null | undefined;
                              nameValid?: string | null | undefined;
                              scoreAcceptable?: string | null | undefined;
                              scoreConfirmed?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        data?:
                          | {
                              __typename?: 'LanguageAssessmentData';
                              _id?: string | null | undefined;
                              candidateId?: string | null | undefined;
                              examDate?: string | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        uploadedBy?:
                                          | {
                                              __typename?: 'DocumentUploadUser';
                                              id?: string | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              status?: string | null | undefined;
                              title: string;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                    role?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pathway6?:
                | Array<
                    | {
                        __typename?: 'ArchivedCasePathway6ReviewType';
                        checklistState?: string | null | undefined;
                        checklist?:
                          | {
                              __typename?: 'MiniCEXChecklist';
                              minicexComplete?: string | null | undefined;
                              minicexScoreAcceptable?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              status?: string | null | undefined;
                              title: string;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                    role?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        uiConfig?:
          | {
              __typename?: 'UIPortalConfigType';
              config?:
                | {
                    __typename?: 'UIConfigRecordType';
                    pages?:
                      | Array<
                          | {
                              __typename?: 'UIPageConfigType';
                              pageName?: string | null | undefined;
                              components?:
                                | Array<
                                    | {
                                        __typename?: 'UIComponentConfigType';
                                        componentName?: string | null | undefined;
                                        version?: number | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ArchivedActivityFeedContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type ArchivedActivityFeedContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        activityLog?:
          | Array<
              | {
                  __typename?: 'ActivityLogType';
                  logType?: string | null | undefined;
                  message?: string | null | undefined;
                  timestamp?: any | null | undefined;
                  user?:
                    | {
                        __typename?: 'ActivityLogUserType';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                        role?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ArchivedCaseAdditionalDocumentsContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type ArchivedCaseAdditionalDocumentsContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        additionalDocuments?:
          | Array<
              | {
                  __typename?: 'AdditionalDocument';
                  notes?: string | null | undefined;
                  document?:
                    | {
                        __typename?: 'Document';
                        docId?: string | null | undefined;
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                        uploadedBy?:
                          | {
                              __typename?: 'DocumentUploadUser';
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ArchivedCaseApplicantContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type ArchivedCaseApplicantContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        externalId?: string | null | undefined;
        applicant?:
          | {
              __typename?: 'ArchivedCaseApplicantType';
              examHistory?:
                | Array<
                    | {
                        __typename?: 'ExamHistoryType';
                        examDate?: string | null | undefined;
                        examName?: string | null | undefined;
                        result?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              graduation?:
                | {
                    __typename?: 'ArchivedCaseApplicantGraduationType';
                    country?: string | null | undefined;
                    credComments?: string | null | undefined;
                    credStatusCode?: string | null | undefined;
                    degree?: string | null | undefined;
                    school?: string | null | undefined;
                    year?: string | null | undefined;
                  }
                | null
                | undefined;
              profile?:
                | {
                    __typename?: 'ArchivedCaseApplicantProfileType';
                    dateOfBirth?: string | null | undefined;
                    email?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    usmleId?: string | null | undefined;
                  }
                | null
                | undefined;
              restrictions?:
                | Array<
                    | {
                        __typename?: 'RestrictionType';
                        comments?: string | null | undefined;
                        level?: string | null | undefined;
                        reason?: string | null | undefined;
                        releaseDate?: string | null | undefined;
                        startDate?: string | null | undefined;
                        user?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ArchivedCaseAssessmentsEligibilityContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type ArchivedCaseAssessmentsEligibilityContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        assessments?:
          | {
              __typename?: 'ArchivedCaseAssessmentsType';
              eligibility?:
                | {
                    __typename?: 'ArchivedCaseAssessmentEligibilityType';
                    eligibilityPreCheck?:
                      | {
                          __typename?: 'ArchivedCaseAssessmentEligibilityOutputType';
                          output?: string | null | undefined;
                          outputReason?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    generalEligibility?:
                      | {
                          __typename?: 'ArchivedCaseAssessmentEligibilityOutputType';
                          output?: string | null | undefined;
                          outputReason?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ArchivedCaseAssessmentsOetContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type ArchivedCaseAssessmentsOetContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        assessments?:
          | {
              __typename?: 'ArchivedCaseAssessmentsType';
              oet?:
                | {
                    __typename?: 'ArchivedCaseAssessmentOETType';
                    examineeFirstName?: string | null | undefined;
                    examineeLastName?: string | null | undefined;
                    candidateId?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    examDate?: string | null | undefined;
                    testingCenter?: string | null | undefined;
                    readingScore?: number | null | undefined;
                    listeningScore?: number | null | undefined;
                    writingScore?: number | null | undefined;
                    speakingScore?: number | null | undefined;
                    result?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ArchivedCaseDetailsContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type ArchivedCaseDetailsContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        externalId?: string | null | undefined;
        audit?:
          | {
              __typename?: 'ArchivedCaseAuditType';
              archivedAt?: any | null | undefined;
              completedAt?: any | null | undefined;
              createdAt?: any | null | undefined;
              submittedAt?: any | null | undefined;
            }
          | null
          | undefined;
        caseDetails?:
          | {
              __typename?: 'ArchivedCaseDetailsType';
              applicationType?: string | null | undefined;
              currentPathway?: string | null | undefined;
              isRefunded?: boolean | null | undefined;
              pathwaySeason?: number | null | undefined;
              submittedPathway?: string | null | undefined;
              decision?:
                | {
                    __typename?: 'ArchivedCaseDecisionType';
                    output: string;
                    outputReason?: Array<string | null | undefined> | null | undefined;
                    executedBy: {
                      __typename?: 'UserType';
                      id?: string | null | undefined;
                      name?: string | null | undefined;
                      role?: string | null | undefined;
                    };
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        uiConfig?:
          | {
              __typename?: 'UIPortalConfigType';
              config?:
                | {
                    __typename?: 'UIConfigRecordType';
                    pages?:
                      | Array<
                          | {
                              __typename?: 'UIPageConfigType';
                              pageName?: string | null | undefined;
                              components?:
                                | Array<
                                    | {
                                        __typename?: 'UIComponentConfigType';
                                        componentName?: string | null | undefined;
                                        version?: number | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ArchivedCaseDetailsContainerApplicantSummarySubmissionSummaryMutationVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type ArchivedCaseDetailsContainerApplicantSummarySubmissionSummaryMutation = {
  __typename?: 'Mutation';
  CasePortal_archivedCase_SubmissionSummary?: string | null | undefined;
};

export type ArchivedCaseExportContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type ArchivedCaseExportContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        externalId?: string | null | undefined;
        additionalDocuments?:
          | Array<
              | {
                  __typename?: 'AdditionalDocument';
                  notes?: string | null | undefined;
                  document?:
                    | {
                        __typename?: 'Document';
                        docId?: string | null | undefined;
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                        uploadedBy?:
                          | {
                              __typename?: 'DocumentUploadUser';
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        activityLog?:
          | Array<
              | {
                  __typename?: 'ActivityLogType';
                  logType?: string | null | undefined;
                  message?: string | null | undefined;
                  timestamp?: any | null | undefined;
                  user?:
                    | {
                        __typename?: 'ActivityLogUserType';
                        id?: string | null | undefined;
                        name?: string | null | undefined;
                        role?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        applicant?:
          | {
              __typename?: 'ArchivedCaseApplicantType';
              examHistory?:
                | Array<
                    | {
                        __typename?: 'ExamHistoryType';
                        examDate?: string | null | undefined;
                        examName?: string | null | undefined;
                        result?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              graduation?:
                | {
                    __typename?: 'ArchivedCaseApplicantGraduationType';
                    country?: string | null | undefined;
                    credComments?: string | null | undefined;
                    credStatusCode?: string | null | undefined;
                    degree?: string | null | undefined;
                    school?: string | null | undefined;
                    year?: string | null | undefined;
                  }
                | null
                | undefined;
              profile?:
                | {
                    __typename?: 'ArchivedCaseApplicantProfileType';
                    dateOfBirth?: string | null | undefined;
                    email?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    usmleId?: string | null | undefined;
                  }
                | null
                | undefined;
              restrictions?:
                | Array<
                    | {
                        __typename?: 'RestrictionType';
                        comments?: string | null | undefined;
                        level?: string | null | undefined;
                        reason?: string | null | undefined;
                        releaseDate?: string | null | undefined;
                        startDate?: string | null | undefined;
                        user?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        assessments?:
          | {
              __typename?: 'ArchivedCaseAssessmentsType';
              eligibility?:
                | {
                    __typename?: 'ArchivedCaseAssessmentEligibilityType';
                    eligibilityPreCheck?:
                      | {
                          __typename?: 'ArchivedCaseAssessmentEligibilityOutputType';
                          output?: string | null | undefined;
                          outputReason?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    generalEligibility?:
                      | {
                          __typename?: 'ArchivedCaseAssessmentEligibilityOutputType';
                          output?: string | null | undefined;
                          outputReason?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              oet?:
                | {
                    __typename?: 'ArchivedCaseAssessmentOETType';
                    candidateId?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    examDate?: string | null | undefined;
                    examineeFirstName?: string | null | undefined;
                    examineeLastName?: string | null | undefined;
                    listeningScore?: number | null | undefined;
                    readingScore?: number | null | undefined;
                    result?: string | null | undefined;
                    speakingScore?: number | null | undefined;
                    testingCenter?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    writingScore?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        audit?:
          | {
              __typename?: 'ArchivedCaseAuditType';
              archivedAt?: any | null | undefined;
              completedAt?: any | null | undefined;
              createdAt?: any | null | undefined;
              submittedAt?: any | null | undefined;
            }
          | null
          | undefined;
        caseDetails?:
          | {
              __typename?: 'ArchivedCaseDetailsType';
              applicationType?: string | null | undefined;
              currentPathway?: string | null | undefined;
              isRefunded?: boolean | null | undefined;
              pathwaySeason?: number | null | undefined;
              submittedPathway?: string | null | undefined;
              decision?:
                | {
                    __typename?: 'ArchivedCaseDecisionType';
                    output: string;
                    outputReason?: Array<string | null | undefined> | null | undefined;
                    executedBy: {
                      __typename?: 'UserType';
                      id?: string | null | undefined;
                      name?: string | null | undefined;
                      role?: string | null | undefined;
                    };
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        finance?:
          | {
              __typename?: 'ArchivedCaseFinanceType';
              payment?:
                | {
                    __typename?: 'Payment';
                    amt?: number | null | undefined;
                    pnRef?: string | null | undefined;
                    transTime?: any | null | undefined;
                  }
                | null
                | undefined;
              refund?:
                | {
                    __typename?: 'Refund';
                    amt?: number | null | undefined;
                    pnRef?: string | null | undefined;
                    transTime?: any | null | undefined;
                  }
                | null
                | undefined;
              revenueRecognition?:
                | Array<
                    | {
                        __typename?: 'ArchivedCaseFinanceRevenueRecognitionType';
                        amount?: number | null | undefined;
                        creditGlAccount?: string | null | undefined;
                        debitGlAccount?: string | null | undefined;
                        pnref?: string | null | undefined;
                        revenueRecognizedAt?: any | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        reviews?:
          | {
              __typename?: 'ArchivedCaseReviewsType';
              oet?:
                | Array<
                    | {
                        __typename?: 'ArchivedCaseOetReviewType';
                        checklistState?: string | null | undefined;
                        checklist?:
                          | {
                              __typename?: 'LanguageAssessmentChecklist';
                              documentCompleteLegible?: string | null | undefined;
                              examDateValidation?: string | null | undefined;
                              nameValid?: string | null | undefined;
                              scoreAcceptable?: string | null | undefined;
                              scoreConfirmed?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        data?:
                          | {
                              __typename?: 'LanguageAssessmentData';
                              _id?: string | null | undefined;
                              candidateId?: string | null | undefined;
                              examDate?: string | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        uploadedBy?:
                                          | {
                                              __typename?: 'DocumentUploadUser';
                                              id?: string | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              status?: string | null | undefined;
                              title: string;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                    role?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pathway1?:
                | Array<
                    | {
                        __typename?: 'ArchivedCasePathway1ReviewType';
                        checklistState?: string | null | undefined;
                        checklist?:
                          | {
                              __typename?: 'LicenseChecklist';
                              licensureApplicantLicenseOnPoint?: string | null | undefined;
                              licensureCompleteLegible?: string | null | undefined;
                              licensureDisciplinaryAction?: string | null | undefined;
                              licensureDocumentIssueDateAcceptable?: string | null | undefined;
                              licensureIssuedAppropriateAuthority?: string | null | undefined;
                              licensureNameValidation?: string | null | undefined;
                              licensureTranslationStatus?: string | null | undefined;
                              licensureUnrestrictedLicense?: string | null | undefined;
                              verificationActiveLicense?: string | null | undefined;
                              verificationAuthorized?: string | null | undefined;
                              verificationGoodStanding?: string | null | undefined;
                              verificationPrimarySource?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        data?:
                          | {
                              __typename?: 'LicenseData';
                              _id?: string | null | undefined;
                              hasDisciplinaryAction?: boolean | null | undefined;
                              isCurrentlyLicensed?: boolean | null | undefined;
                              licenseExpiryDate?: string | null | undefined;
                              licenseIssueDate?: string | null | undefined;
                              licensureDocumentStatus?: string | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        uploadedBy?:
                                          | {
                                              __typename?: 'DocumentUploadUser';
                                              id?: string | null | undefined;
                                              name?: string | null | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                              regulatoryAuthorityRef?:
                                | {
                                    __typename?: 'regulatoryAuthorityResponseType';
                                    _id?: number | null | undefined;
                                    countryName?: string | null | undefined;
                                    orgName?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: 'ArchivedCaseReviewSummaryType';
                              _id: string;
                              status?: string | null | undefined;
                              title: string;
                              updatedTimestamp?: any | null | undefined;
                              updatedBy?:
                                | {
                                    __typename?: 'UserType';
                                    id?: string | null | undefined;
                                    name?: string | null | undefined;
                                    role?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        uiConfig?:
          | {
              __typename?: 'UIPortalConfigType';
              uiConfigRef?: any | null | undefined;
              config?:
                | {
                    __typename?: 'UIConfigRecordType';
                    portal?: string | null | undefined;
                    pages?:
                      | Array<
                          | {
                              __typename?: 'UIPageConfigType';
                              pageName?: string | null | undefined;
                              components?:
                                | Array<
                                    | {
                                        __typename?: 'UIComponentConfigType';
                                        componentName?: string | null | undefined;
                                        version?: number | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ArchivedCaseFinanceContainerArchivedCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;

export type ArchivedCaseFinanceContainerArchivedCaseQuery = {
  __typename?: 'Query';
  archivedCase?:
    | {
        __typename?: 'ArchivedCaseType';
        _id: string;
        externalId?: string | null | undefined;
        finance?:
          | {
              __typename?: 'ArchivedCaseFinanceType';
              payment?:
                | {
                    __typename?: 'Payment';
                    amt?: number | null | undefined;
                    pnRef?: string | null | undefined;
                    transTime?: any | null | undefined;
                  }
                | null
                | undefined;
              refund?:
                | {
                    __typename?: 'Refund';
                    amt?: number | null | undefined;
                    pnRef?: string | null | undefined;
                    transTime?: any | null | undefined;
                  }
                | null
                | undefined;
              revenueRecognition?:
                | Array<
                    | {
                        __typename?: 'ArchivedCaseFinanceRevenueRecognitionType';
                        amount?: number | null | undefined;
                        creditGlAccount?: string | null | undefined;
                        debitGlAccount?: string | null | undefined;
                        pnref?: string | null | undefined;
                        revenueRecognizedAt?: any | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtActivityFeedCasePortalFieldsFragment = {
  __typename?: 'CaseResponseType';
  activityLog?:
    | Array<
        | {
            __typename?: 'ActivityLogType';
            timestamp?: any | null | undefined;
            logType?: string | null | undefined;
            message?: string | null | undefined;
            user?:
              | {
                  __typename?: 'ActivityLogUserType';
                  role?: string | null | undefined;
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  caseSummary?:
    | {
        __typename?: 'CaseSummaryType';
        usmleId?: string | null | undefined;
        dataVersion?: number | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtActivityFeedAddCaseNoteMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  text: Scalars['String'];
}>;

export type CaseMgmtActivityFeedAddCaseNoteMutation = {
  __typename?: 'Mutation';
  CasePortal_addCaseNote?:
    | {
        __typename?: 'CaseResponseType';
        activityLog?:
          | Array<
              | {
                  __typename?: 'ActivityLogType';
                  message?: string | null | undefined;
                  timestamp?: any | null | undefined;
                  logType?: string | null | undefined;
                  user?:
                    | {
                        __typename?: 'ActivityLogUserType';
                        name?: string | null | undefined;
                        role?: string | null | undefined;
                        id?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseHeaderCasePortalQueryVariables = Exact<{ [key: string]: never }>;

export type CaseMgmtCaseHeaderCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        caseCount?:
          | {
              __typename?: 'CaseCountType';
              applicationSubmitted?: number | null | undefined;
              eligibilityReview?: number | null | undefined;
              assignedToUser?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseLeftNavCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtCaseLeftNavCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              _id?: string | null | undefined;
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    usmleId?: string | null | undefined;
                    currentPathway?: string | null | undefined;
                    submittedPathway?: string | null | undefined;
                    caseState?: string | null | undefined;
                  }
                | null
                | undefined;
              pathway1?:
                | {
                    __typename?: 'Pathway1';
                    applicantProvidedData?:
                      | Array<
                          | { __typename?: 'License'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | { __typename?: 'License'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathway2?:
                | {
                    __typename?: 'Pathway2';
                    applicantProvidedData?:
                      | Array<
                          | { __typename?: 'CSExam'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | { __typename?: 'CSExam'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathway345?:
                | {
                    __typename?: 'Pathway345';
                    applicantProvidedData?:
                      | Array<
                          | { __typename?: 'Education'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | { __typename?: 'Education'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathway6?:
                | {
                    __typename?: 'Pathway6';
                    applicantProvidedData?:
                      | Array<
                          | { __typename?: 'Pathway6MiniCEXType'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | { __typename?: 'Pathway6MiniCEXType'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathwayX?:
                | { __typename?: 'ExceptionRequest'; _id?: string | null | undefined }
                | null
                | undefined;
              languageAssessment?:
                | {
                    __typename?: 'LanguageAssessment';
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'LanguageAssessmentType';
                              _id?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | {
                              __typename?: 'LanguageAssessmentType';
                              _id?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseListFilterModalFieldsFragment = {
  __typename?: 'Dashboard';
  dashboardType?: string | null | undefined;
  views?:
    | Array<
        | {
            __typename?: 'Views';
            _id?: any | null | undefined;
            viewName?: string | null | undefined;
            displayColumns?: Array<string | null | undefined> | null | undefined;
            viewFilters?:
              | {
                  __typename?: 'ViewFilters';
                  caseState?: Array<string | null | undefined> | null | undefined;
                  applicationType?: Array<string | null | undefined> | null | undefined;
                  pathwayEligibilityStatus?: Array<string | null | undefined> | null | undefined;
                  englishEligibilityStatus?: Array<string | null | undefined> | null | undefined;
                  generalEligibilityStatus?: Array<string | null | undefined> | null | undefined;
                  pathway?: Array<string | null | undefined> | null | undefined;
                  school?: Array<number | null | undefined> | null | undefined;
                  regulatoryAuthority?: Array<number | null | undefined> | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CaseMgmtCaseListFilterModelRegulatoryAuthorityCasePortalQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CaseMgmtCaseListFilterModelRegulatoryAuthorityCasePortalQuery = {
  __typename?: 'Query';
  regulatoryAuthority?:
    | Array<
        | {
            __typename?: 'regulatoryAuthorityResponseType';
            _id?: number | null | undefined;
            countryName?: string | null | undefined;
            orgName?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CaseMgmtCaseListFilterModelSchoolCasePortalQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CaseMgmtCaseListFilterModelSchoolCasePortalQuery = {
  __typename?: 'Query';
  school?:
    | Array<
        | {
            __typename?: 'SchoolResponseType';
            _id?: number | null | undefined;
            countryName?: string | null | undefined;
            name?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CaseMgmtCaseListLayoutSaveLastAccessedViewMutationVariables = Exact<{
  input: LastAccessedViewInput;
}>;

export type CaseMgmtCaseListLayoutSaveLastAccessedViewMutation = {
  __typename?: 'Mutation';
  saveLastAccessedView?:
    | {
        __typename?: 'Dashboard';
        dashboardType?: string | null | undefined;
        lastAccessedCaseView?:
          | {
              __typename?: 'LastAccessedView';
              viewId?: any | null | undefined;
              recordsPerPage?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseListLayoutCasePortalQueryVariables = Exact<{
  filter?: InputMaybe<CaseFilterInput>;
  pagination: CaseListPaginationInput;
  usmleIDColumn: Scalars['Boolean'];
  daysSinceLastUpdateColumn: Scalars['Boolean'];
  submissionDateColumn: Scalars['Boolean'];
  pathwayColumn: Scalars['Boolean'];
  caseStatusColumn: Scalars['Boolean'];
  generalEligibilityColumn: Scalars['Boolean'];
  oetCaseStatusColumn: Scalars['Boolean'];
  pathwayCaseStatusColumn: Scalars['Boolean'];
  qaReworkColumn: Scalars['Boolean'];
  assignedToColumn: Scalars['Boolean'];
  lastDocUploadColumn: Scalars['Boolean'];
}>;

export type CaseMgmtCaseListLayoutCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        caseList?:
          | {
              __typename?: 'CaseListResponseType';
              cases?:
                | Array<
                    | {
                        __typename?: 'CaseSummaryType';
                        _id?: string | null | undefined;
                        usmleId?: string | null | undefined;
                        lastName?: string | null | undefined;
                        firstName?: string | null | undefined;
                        createdAt?: any | null | undefined;
                        applicationType?: string | null | undefined;
                        updatedAt?: any | null | undefined;
                        submittedAt?: any | null | undefined;
                        isEligible?: boolean | null | undefined;
                        isExceptionCase?: boolean | null | undefined;
                        caseState?: string | null | undefined;
                        output?: string | null | undefined;
                        outputReason?: Array<string | null | undefined> | null | undefined;
                        eligibilityReviewAssigneeId?: string | null | undefined;
                        eligibilityReviewAssigneeName?: string | null | undefined;
                        qaReviewAssigneeId?: string | null | undefined;
                        qaReviewAssigneeName?: string | null | undefined;
                        currentPathway?: string | null | undefined;
                        submittedPathway?: string | null | undefined;
                        qaReworkTimestamp?: any | null | undefined;
                        pathwayEligibilityStatus?: string | null | undefined;
                        pathwayEligibilityOutput?: string | null | undefined;
                        englishEligibilityStatus?: string | null | undefined;
                        englishEligibilityOutput?: string | null | undefined;
                        generalEligibilityStatus?: string | null | undefined;
                        lastDocUploadTimestamp?: any | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pagination: {
                __typename?: 'CaseListPaginationType';
                pageNumber: number;
                pageSize: number;
                sortField: string;
                sortOrder: string;
                totalPages: number;
                totalRecords: number;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseListAddViewFiltersMutationVariables = Exact<{
  input: ViewsInput;
}>;

export type CaseMgmtCaseListAddViewFiltersMutation = {
  __typename?: 'Mutation';
  addViewFilters?:
    | {
        __typename?: 'Dashboard';
        views?:
          | Array<
              | {
                  __typename?: 'Views';
                  _id?: any | null | undefined;
                  viewName?: string | null | undefined;
                  displayColumns?: Array<string | null | undefined> | null | undefined;
                  viewFilters?:
                    | {
                        __typename?: 'ViewFilters';
                        caseState?: Array<string | null | undefined> | null | undefined;
                        applicationType?: Array<string | null | undefined> | null | undefined;
                        pathwayEligibilityStatus?:
                          | Array<string | null | undefined>
                          | null
                          | undefined;
                        englishEligibilityStatus?:
                          | Array<string | null | undefined>
                          | null
                          | undefined;
                        generalEligibilityStatus?:
                          | Array<string | null | undefined>
                          | null
                          | undefined;
                        pathway?: Array<string | null | undefined> | null | undefined;
                        school?: Array<number | null | undefined> | null | undefined;
                        regulatoryAuthority?: Array<number | null | undefined> | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseListLayoutGetDashboardsQueryVariables = Exact<{ [key: string]: never }>;

export type CaseMgmtCaseListLayoutGetDashboardsQuery = {
  __typename?: 'Query';
  getDashboards?:
    | Array<
        | {
            __typename?: 'Dashboard';
            dashboardType?: string | null | undefined;
            lastAccessedCaseView?:
              | {
                  __typename?: 'LastAccessedView';
                  viewId?: any | null | undefined;
                  recordsPerPage?: number | null | undefined;
                  assignedToMe?: boolean | null | undefined;
                }
              | null
              | undefined;
            views?:
              | Array<
                  | {
                      __typename?: 'Views';
                      _id?: any | null | undefined;
                      viewName?: string | null | undefined;
                      displayColumns?: Array<string | null | undefined> | null | undefined;
                      viewFilters?:
                        | {
                            __typename?: 'ViewFilters';
                            caseState?: Array<string | null | undefined> | null | undefined;
                            applicationType?: Array<string | null | undefined> | null | undefined;
                            pathwayEligibilityStatus?:
                              | Array<string | null | undefined>
                              | null
                              | undefined;
                            englishEligibilityStatus?:
                              | Array<string | null | undefined>
                              | null
                              | undefined;
                            generalEligibilityStatus?:
                              | Array<string | null | undefined>
                              | null
                              | undefined;
                            pathway?: Array<string | null | undefined> | null | undefined;
                            school?: Array<number | null | undefined> | null | undefined;
                            regulatoryAuthority?:
                              | Array<number | null | undefined>
                              | null
                              | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CaseMgmtCaseListDeleteViewFiltersMutationVariables = Exact<{
  dashboardId: Scalars['ID'];
  dashboardType: Scalars['String'];
}>;

export type CaseMgmtCaseListDeleteViewFiltersMutation = {
  __typename?: 'Mutation';
  deleteViewFilters?:
    | { __typename?: 'DeleteDashboardResponse'; success?: string | null | undefined }
    | null
    | undefined;
};

export type CaseMgmtCaseListSaveViewFiltersMutationVariables = Exact<{
  input: ViewsInput;
}>;

export type CaseMgmtCaseListSaveViewFiltersMutation = {
  __typename?: 'Mutation';
  saveViewFilters?:
    | {
        __typename?: 'Dashboard';
        views?:
          | Array<
              | {
                  __typename?: 'Views';
                  _id?: any | null | undefined;
                  viewName?: string | null | undefined;
                  viewFilters?:
                    | {
                        __typename?: 'ViewFilters';
                        caseState?: Array<string | null | undefined> | null | undefined;
                        pathwayEligibilityStatus?:
                          | Array<string | null | undefined>
                          | null
                          | undefined;
                        englishEligibilityStatus?:
                          | Array<string | null | undefined>
                          | null
                          | undefined;
                        generalEligibilityStatus?:
                          | Array<string | null | undefined>
                          | null
                          | undefined;
                        pathway?: Array<string | null | undefined> | null | undefined;
                        school?: Array<number | null | undefined> | null | undefined;
                        regulatoryAuthority?: Array<number | null | undefined> | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseListTableCasePortalCasesFieldsFragment = {
  __typename?: 'CaseSummaryType';
  _id?: string | null | undefined;
  usmleId?: string | null | undefined;
  lastName?: string | null | undefined;
  firstName?: string | null | undefined;
  createdAt?: any | null | undefined;
  applicationType?: string | null | undefined;
  updatedAt?: any | null | undefined;
  submittedAt?: any | null | undefined;
  isEligible?: boolean | null | undefined;
  isExceptionCase?: boolean | null | undefined;
  caseState?: string | null | undefined;
  output?: string | null | undefined;
  outputReason?: Array<string | null | undefined> | null | undefined;
  eligibilityReviewAssigneeId?: string | null | undefined;
  eligibilityReviewAssigneeName?: string | null | undefined;
  qaReviewAssigneeId?: string | null | undefined;
  qaReviewAssigneeName?: string | null | undefined;
  currentPathway?: string | null | undefined;
  submittedPathway?: string | null | undefined;
  qaReworkTimestamp?: any | null | undefined;
  pathwayEligibilityStatus?: string | null | undefined;
  pathwayEligibilityOutput?: string | null | undefined;
  englishEligibilityStatus?: string | null | undefined;
  englishEligibilityOutput?: string | null | undefined;
  generalEligibilityStatus?: string | null | undefined;
  lastDocUploadTimestamp?: any | null | undefined;
};

export type CaseMgmtCaseListTableCasePortalPaginationFieldsFragment = {
  __typename?: 'CaseListPaginationType';
  pageNumber: number;
  pageSize: number;
  sortField: string;
  sortOrder: string;
  totalPages: number;
  totalRecords: number;
};

export type CaseMgmtCaseOverviewActionResetApplicationCasePortalCaseArchiveMutationVariables =
  Exact<{
    usmleId: Scalars['String'];
    dataVersion: Scalars['Int'];
  }>;

export type CaseMgmtCaseOverviewActionResetApplicationCasePortalCaseArchiveMutation = {
  __typename?: 'Mutation';
  CasePortal_case_archive?:
    | {
        __typename?: 'CaseResponseStatusType';
        usmleId?: string | null | undefined;
        response?:
          | {
              __typename?: 'ResponseStatusType';
              code?: string | null | undefined;
              msg?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewActionsCasePortalFieldsFragment = {
  __typename?: 'CaseResponseType';
  caseSummary?:
    | {
        __typename?: 'CaseSummaryType';
        usmleId?: string | null | undefined;
        dataVersion?: number | null | undefined;
        currentPathway?: string | null | undefined;
        eligibilityReviewAssigneeId?: string | null | undefined;
        qaReviewAssigneeId?: string | null | undefined;
        isExceptionCase?: boolean | null | undefined;
        caseState?: string | null | undefined;
        pathwayEligibilityStatus?: string | null | undefined;
        englishEligibilityStatus?: string | null | undefined;
        output?: string | null | undefined;
        _id?: string | null | undefined;
        applicationType?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewActionsOverridePathwayMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  input: OverridePathwayInput;
}>;

export type CaseMgmtCaseOverviewActionsOverridePathwayMutation = {
  __typename?: 'Mutation';
  CasePortal_overridePathway?:
    | {
        __typename?: 'CaseResponseType';
        activityLog?:
          | Array<
              | {
                  __typename?: 'ActivityLogType';
                  message?: string | null | undefined;
                  timestamp?: any | null | undefined;
                  logType?: string | null | undefined;
                  user?:
                    | {
                        __typename?: 'ActivityLogUserType';
                        name?: string | null | undefined;
                        role?: string | null | undefined;
                        id?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        caseSummary?:
          | {
              __typename?: 'CaseSummaryType';
              dataVersion?: number | null | undefined;
              currentPathway?: string | null | undefined;
              caseState?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewActionsConvertToExceptionCaseMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
}>;

export type CaseMgmtCaseOverviewActionsConvertToExceptionCaseMutation = {
  __typename?: 'Mutation';
  CasePortal_convertToExceptionCase?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | {
              __typename?: 'CaseSummaryType';
              dataVersion?: number | null | undefined;
              isExceptionCase?: boolean | null | undefined;
              currentPathway?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewActionsAssignEligibilityReviewTaskMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
}>;

export type CaseMgmtCaseOverviewActionsAssignEligibilityReviewTaskMutation = {
  __typename?: 'Mutation';
  CasePortal_assignEligibilityReviewTask?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | {
              __typename?: 'CaseSummaryType';
              dataVersion?: number | null | undefined;
              eligibilityReviewAssigneeName?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewActionsAssignQaReviewTaskMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
}>;

export type CaseMgmtCaseOverviewActionsAssignQaReviewTaskMutation = {
  __typename?: 'Mutation';
  CasePortal_assignQAReviewTask?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | {
              __typename?: 'CaseSummaryType';
              dataVersion?: number | null | undefined;
              qaReviewAssigneeName?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewActionsQaReworkRequiredMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  processGEChecksOnQARework: Scalars['Boolean'];
}>;

export type CaseMgmtCaseOverviewActionsQaReworkRequiredMutation = {
  __typename?: 'Mutation';
  CasePortal_qa_reworkRequired?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | {
              __typename?: 'CaseSummaryType';
              dataVersion?: number | null | undefined;
              currentPathway?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewActionsQaRejectMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
}>;

export type CaseMgmtCaseOverviewActionsQaRejectMutation = {
  __typename?: 'Mutation';
  CasePortal_qa_reject?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | {
              __typename?: 'CaseSummaryType';
              dataVersion?: number | null | undefined;
              currentPathway?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewActionsQaApproveMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
}>;

export type CaseMgmtCaseOverviewActionsQaApproveMutation = {
  __typename?: 'Mutation';
  CasePortal_qa_approve?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | {
              __typename?: 'CaseSummaryType';
              dataVersion?: number | null | undefined;
              currentPathway?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewActionsQaAcceptDecisionMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
}>;

export type CaseMgmtCaseOverviewActionsQaAcceptDecisionMutation = {
  __typename?: 'Mutation';
  CasePortal_qa_acceptDecision?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | {
              __typename?: 'CaseSummaryType';
              dataVersion?: number | null | undefined;
              currentPathway?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewActionsQaOverrideDecisionMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  decision: Scalars['String'];
  comments: Scalars['String'];
}>;

export type CaseMgmtCaseOverviewActionsQaOverrideDecisionMutation = {
  __typename?: 'Mutation';
  CasePortal_qa_overrideDecision?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | {
              __typename?: 'CaseSummaryType';
              dataVersion?: number | null | undefined;
              currentPathway?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseOverviewActionsCasePortalReopenCaseMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
}>;

export type CaseOverviewActionsCasePortalReopenCaseMutation = {
  __typename?: 'Mutation';
  CasePortal_reopenCase?:
    | {
        __typename?: 'CaseResponseType';
        _id?: string | null | undefined;
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewApplicantInformationCasePortalFieldsFragment = {
  __typename?: 'CaseResponseType';
  applicant?:
    | {
        __typename?: 'C_ApplicantType';
        email?: string | null | undefined;
        dateOfBirth?: string | null | undefined;
        country?: string | null | undefined;
        gradSchool?: string | null | undefined;
        gradYear?: string | null | undefined;
        degree?: string | null | undefined;
        lastDataRefreshTimestamp?: any | null | undefined;
        examHistory?:
          | Array<
              | {
                  __typename?: 'ExamHistoryType';
                  examName?: string | null | undefined;
                  examDate?: string | null | undefined;
                  result?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        restrictions?:
          | Array<
              | {
                  __typename?: 'RestrictionType';
                  reason?: string | null | undefined;
                  level?: string | null | undefined;
                  startDate?: string | null | undefined;
                  releaseDate?: string | null | undefined;
                  user?: string | null | undefined;
                  comments?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  pastAttempts?:
    | Array<
        | {
            __typename?: 'PastAttemptsType';
            applicationType?: string | null | undefined;
            completedAt?: any | null | undefined;
            output?: string | null | undefined;
            pathway?: string | null | undefined;
            pathwaySeason?: string | null | undefined;
            refundIssued?: string | null | undefined;
            rejectionReason?: Array<string | null | undefined> | null | undefined;
            submittedAt?: any | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  uiConfig?:
    | {
        __typename?: 'UIPortalConfigType';
        config?:
          | {
              __typename?: 'UIConfigRecordType';
              pages?:
                | Array<
                    | {
                        __typename?: 'UIPageConfigType';
                        pageName?: string | null | undefined;
                        components?:
                          | Array<
                              | {
                                  __typename?: 'UIComponentConfigType';
                                  componentName?: string | null | undefined;
                                  version?: number | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewLayoutCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtCaseOverviewLayoutCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    _id?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    lastName?: string | null | undefined;
                    firstName?: string | null | undefined;
                    submittedAt?: any | null | undefined;
                    updatedAt?: any | null | undefined;
                    isEligible?: boolean | null | undefined;
                    isExceptionCase?: boolean | null | undefined;
                    caseState?: string | null | undefined;
                    output?: string | null | undefined;
                    outputReason?: Array<string | null | undefined> | null | undefined;
                    eligibilityReviewAssigneeId?: string | null | undefined;
                    eligibilityReviewAssigneeName?: string | null | undefined;
                    qaReviewAssigneeId?: string | null | undefined;
                    qaReviewAssigneeName?: string | null | undefined;
                    currentPathway?: string | null | undefined;
                    submittedPathway?: string | null | undefined;
                    qaReworkTimestamp?: any | null | undefined;
                    pathwayEligibilityStatus?: string | null | undefined;
                    englishEligibilityStatus?: string | null | undefined;
                    generalEligibilityOutput?: string | null | undefined;
                    generalEligibilityStatus?: string | null | undefined;
                    generalEligibilityOutputReason?: string | null | undefined;
                    applicationType?: string | null | undefined;
                    dataVersion?: number | null | undefined;
                  }
                | null
                | undefined;
              applicant?:
                | {
                    __typename?: 'C_ApplicantType';
                    gradSchool?: string | null | undefined;
                    email?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    country?: string | null | undefined;
                    gradYear?: string | null | undefined;
                    degree?: string | null | undefined;
                    lastDataRefreshTimestamp?: any | null | undefined;
                    examHistory?:
                      | Array<
                          | {
                              __typename?: 'ExamHistoryType';
                              examName?: string | null | undefined;
                              examDate?: string | null | undefined;
                              result?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    restrictions?:
                      | Array<
                          | {
                              __typename?: 'RestrictionType';
                              reason?: string | null | undefined;
                              level?: string | null | undefined;
                              startDate?: string | null | undefined;
                              releaseDate?: string | null | undefined;
                              user?: string | null | undefined;
                              comments?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pastAttempts?:
                | Array<
                    | {
                        __typename?: 'PastAttemptsType';
                        applicationType?: string | null | undefined;
                        completedAt?: any | null | undefined;
                        output?: string | null | undefined;
                        pathway?: string | null | undefined;
                        pathwaySeason?: string | null | undefined;
                        refundIssued?: string | null | undefined;
                        rejectionReason?: Array<string | null | undefined> | null | undefined;
                        submittedAt?: any | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              activityLog?:
                | Array<
                    | {
                        __typename?: 'ActivityLogType';
                        timestamp?: any | null | undefined;
                        logType?: string | null | undefined;
                        message?: string | null | undefined;
                        user?:
                          | {
                              __typename?: 'ActivityLogUserType';
                              role?: string | null | undefined;
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              uiConfig?:
                | {
                    __typename?: 'UIPortalConfigType';
                    config?:
                      | {
                          __typename?: 'UIConfigRecordType';
                          pages?:
                            | Array<
                                | {
                                    __typename?: 'UIPageConfigType';
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: 'UIComponentConfigType';
                                              componentName?: string | null | undefined;
                                              version?: number | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtCaseOverviewSummaryCasePortalFieldsFragment = {
  __typename?: 'CaseResponseType';
  caseSummary?:
    | {
        __typename?: 'CaseSummaryType';
        _id?: string | null | undefined;
        usmleId?: string | null | undefined;
        lastName?: string | null | undefined;
        firstName?: string | null | undefined;
        submittedAt?: any | null | undefined;
        updatedAt?: any | null | undefined;
        isEligible?: boolean | null | undefined;
        isExceptionCase?: boolean | null | undefined;
        caseState?: string | null | undefined;
        output?: string | null | undefined;
        outputReason?: Array<string | null | undefined> | null | undefined;
        eligibilityReviewAssigneeId?: string | null | undefined;
        eligibilityReviewAssigneeName?: string | null | undefined;
        qaReviewAssigneeId?: string | null | undefined;
        qaReviewAssigneeName?: string | null | undefined;
        currentPathway?: string | null | undefined;
        submittedPathway?: string | null | undefined;
        qaReworkTimestamp?: any | null | undefined;
        pathwayEligibilityStatus?: string | null | undefined;
        englishEligibilityStatus?: string | null | undefined;
        generalEligibilityOutput?: string | null | undefined;
        generalEligibilityStatus?: string | null | undefined;
        generalEligibilityOutputReason?: string | null | undefined;
        applicationType?: string | null | undefined;
      }
    | null
    | undefined;
  applicant?:
    | { __typename?: 'C_ApplicantType'; gradSchool?: string | null | undefined }
    | null
    | undefined;
};

export type CaseMgmtFinanceActionsCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
  isFinanceUser: Scalars['Boolean'];
}>;

export type CaseMgmtFinanceActionsCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              caseSummary?:
                | { __typename?: 'CaseSummaryType'; usmleId?: string | null | undefined }
                | null
                | undefined;
              finance?:
                | {
                    __typename?: 'Finance';
                    payment?:
                      | { __typename?: 'Payment'; amt?: number | null | undefined }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtFinanceActionsRequestRefundMutationVariables = Exact<{
  caseWorker?: InputMaybe<Scalars['String']>;
  usmleID?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtFinanceActionsRequestRefundMutation = {
  __typename?: 'Mutation';
  requestRefund?:
    | {
        __typename?: 'RequestPayload';
        pnRef?: string | null | undefined;
        responseMessage?: string | null | undefined;
        result?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtFinanceActionsIssuePaperCheckMutationVariables = Exact<{
  usmleId: Scalars['String'];
  amount: Scalars['Int'];
}>;

export type CaseMgmtFinanceActionsIssuePaperCheckMutation = {
  __typename?: 'Mutation';
  CasePortal_issuePaperCheck?:
    | {
        __typename?: 'GenericResponseType';
        code?: string | null | undefined;
        msg?: string | null | undefined;
        dataVersion?: number | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtLayoutCaseManagementCreateExceptionCaseMutationVariables = Exact<{
  usmleId: Scalars['String'];
}>;

export type CaseMgmtLayoutCaseManagementCreateExceptionCaseMutation = {
  __typename?: 'Mutation';
  CasePortal_createExceptionCase?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtLayoutCaseManagementCheckCaseExistsMutationVariables = Exact<{
  usmleId: Scalars['String'];
}>;

export type CaseMgmtLayoutCaseManagementCheckCaseExistsMutation = {
  __typename?: 'Mutation';
  CasePortal_checkCaseExists?: boolean | null | undefined;
};

export type CaseMgmtApplicantSummaryFactoryConfigFieldsFragment = {
  __typename?: 'CaseResponseType';
  uiConfig?:
    | {
        __typename?: 'UIPortalConfigType';
        config?:
          | {
              __typename?: 'UIConfigRecordType';
              pages?:
                | Array<
                    | {
                        __typename?: 'UIPageConfigType';
                        pageName?: string | null | undefined;
                        components?:
                          | Array<
                              | {
                                  __typename?: 'UIComponentConfigType';
                                  componentName?: string | null | undefined;
                                  version?: number | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtApplicantSummaryCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtApplicantSummaryCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    isExceptionCase?: boolean | null | undefined;
                    submittedPathway?: string | null | undefined;
                    submittedAt?: any | null | undefined;
                    caseState?: string | null | undefined;
                    applicationType?: string | null | undefined;
                    _id?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    currentPathway?: string | null | undefined;
                  }
                | null
                | undefined;
              applicant?:
                | {
                    __typename?: 'C_ApplicantType';
                    email?: string | null | undefined;
                    isStep2CSFailed?: boolean | null | undefined;
                    graduation?:
                      | {
                          __typename?: 'GraduationType';
                          isYearEligible?: boolean | null | undefined;
                          schoolEligibility?:
                            | {
                                __typename?: 'SchoolEligibilityType';
                                schoolPathway?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathway1?:
                | {
                    __typename?: 'Pathway1';
                    userResponse?:
                      | {
                          __typename?: 'Pathway1UserResponse';
                          isCurrentlyLicensed?: boolean | null | undefined;
                          wasLicensedInRecentPast?: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'License';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'LicenseData';
                                    _id?: string | null | undefined;
                                    licensureDocumentStatus?: string | null | undefined;
                                    licenseIssueDate?: string | null | undefined;
                                    licenseExpiryDate?: string | null | undefined;
                                    isCurrentlyLicensed?: boolean | null | undefined;
                                    hasDisciplinaryAction?: boolean | null | undefined;
                                    regulatoryAuthorityRef?:
                                      | {
                                          __typename?: 'regulatoryAuthorityResponseType';
                                          _id?: number | null | undefined;
                                          orgName?: string | null | undefined;
                                          countryName?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | {
                              __typename?: 'License';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'LicenseData';
                                    _id?: string | null | undefined;
                                    licensureDocumentStatus?: string | null | undefined;
                                    licenseIssueDate?: string | null | undefined;
                                    licenseExpiryDate?: string | null | undefined;
                                    isCurrentlyLicensed?: boolean | null | undefined;
                                    hasDisciplinaryAction?: boolean | null | undefined;
                                    regulatoryAuthorityRef?:
                                      | {
                                          __typename?: 'regulatoryAuthorityResponseType';
                                          _id?: number | null | undefined;
                                          orgName?: string | null | undefined;
                                          countryName?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathway2?:
                | {
                    __typename?: 'Pathway2';
                    userResponse?:
                      | {
                          __typename?: 'Pathway2UserResponse';
                          hasPassedCSExamInRecentPast?: boolean | null | undefined;
                          hasPassedFromQualifiedSchoolInRecentPast?: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'CSExam';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'CSExamData';
                                    examDate?: string | null | undefined;
                                    oscExamRef?:
                                      | {
                                          __typename?: 'oscexamResponseType';
                                          _id?: string | null | undefined;
                                          examCode?: string | null | undefined;
                                          examShortTitle?: string | null | undefined;
                                          examFullName?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                    schoolRef?:
                                      | {
                                          __typename?: 'SchoolResponseType';
                                          _id?: number | null | undefined;
                                          name?: string | null | undefined;
                                          countryName?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              docType?: string | null | undefined;
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | {
                              __typename?: 'CSExam';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'CSExamData';
                                    examDate?: string | null | undefined;
                                    oscExamRef?:
                                      | {
                                          __typename?: 'oscexamResponseType';
                                          _id?: string | null | undefined;
                                          examCode?: string | null | undefined;
                                          examShortTitle?: string | null | undefined;
                                          examFullName?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                    schoolRef?:
                                      | {
                                          __typename?: 'SchoolResponseType';
                                          _id?: number | null | undefined;
                                          name?: string | null | undefined;
                                          countryName?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              docType?: string | null | undefined;
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathway345?:
                | {
                    __typename?: 'Pathway345';
                    userResponse?:
                      | {
                          __typename?: 'Pathway345UserResponse';
                          isGradSchoolEligible?: boolean | null | undefined;
                          isGradDateInAcceptableRange?: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'Education';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'EducationData';
                                    _id?: string | null | undefined;
                                    gradDate?: string | null | undefined;
                                    schoolRef?:
                                      | {
                                          __typename?: 'SchoolResponseType';
                                          _id?: number | null | undefined;
                                          name?: string | null | undefined;
                                          countryName?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | {
                              __typename?: 'Education';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'EducationData';
                                    _id?: string | null | undefined;
                                    gradDate?: string | null | undefined;
                                    schoolRef?:
                                      | {
                                          __typename?: 'SchoolResponseType';
                                          _id?: number | null | undefined;
                                          name?: string | null | undefined;
                                          countryName?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              languageAssessment?:
                | {
                    __typename?: 'LanguageAssessment';
                    userResponse?:
                      | {
                          __typename?: 'LanguageAssessmentUserResponse';
                          hasPassedExam?: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'LanguageAssessmentType';
                              data?:
                                | {
                                    __typename?: 'LanguageAssessmentData';
                                    candidateId?: string | null | undefined;
                                    examDate?: string | null | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              docType?: string | null | undefined;
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathwayX?:
                | {
                    __typename?: 'ExceptionRequest';
                    _id?: string | null | undefined;
                    data?:
                      | {
                          __typename?: 'ExceptionRequestData';
                          requestText?: string | null | undefined;
                          documents?:
                            | Array<
                                | {
                                    __typename?: 'Document';
                                    docId?: string | null | undefined;
                                    docType?: string | null | undefined;
                                    title?: string | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              uiConfig?:
                | {
                    __typename?: 'UIPortalConfigType';
                    config?:
                      | {
                          __typename?: 'UIConfigRecordType';
                          pages?:
                            | Array<
                                | {
                                    __typename?: 'UIPageConfigType';
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: 'UIComponentConfigType';
                                              componentName?: string | null | undefined;
                                              version?: number | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtApplicantSummarySubmissionSummaryMutationVariables = Exact<{
  usmleId: Scalars['String'];
}>;

export type CaseMgmtApplicantSummarySubmissionSummaryMutation = {
  __typename?: 'Mutation';
  CasePortal_SubmissionSummary?: string | null | undefined;
};

export type CaseMgmtQualificationReviewApplicantInfoFieldsFragment = {
  __typename?: 'CaseResponseType';
  caseSummary?:
    | {
        __typename?: 'CaseSummaryType';
        _id?: string | null | undefined;
        usmleId?: string | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        dateOfBirth?: string | null | undefined;
      }
    | null
    | undefined;
  applicant?:
    | {
        __typename?: 'C_ApplicantType';
        email?: string | null | undefined;
        isStep2CSFailed?: boolean | null | undefined;
        graduation?:
          | {
              __typename?: 'GraduationType';
              isYearEligible?: boolean | null | undefined;
              schoolEligibility?:
                | {
                    __typename?: 'SchoolEligibilityType';
                    schoolPathway?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtQualificationReviewCsExamFieldsFragment = {
  __typename?: 'CaseResponseType';
  pathway2?:
    | {
        __typename?: 'Pathway2';
        userResponse?:
          | {
              __typename?: 'Pathway2UserResponse';
              hasPassedCSExamInRecentPast?: boolean | null | undefined;
              hasPassedFromQualifiedSchoolInRecentPast?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: 'CSExam';
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: 'CSExamData';
                        examDate?: string | null | undefined;
                        oscExamRef?:
                          | {
                              __typename?: 'oscexamResponseType';
                              _id?: string | null | undefined;
                              examCode?: string | null | undefined;
                              examShortTitle?: string | null | undefined;
                              examFullName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        schoolRef?:
                          | {
                              __typename?: 'SchoolResponseType';
                              _id?: number | null | undefined;
                              name?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: 'Document';
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        staffProvidedData?:
          | Array<
              | {
                  __typename?: 'CSExam';
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: 'CSExamData';
                        examDate?: string | null | undefined;
                        oscExamRef?:
                          | {
                              __typename?: 'oscexamResponseType';
                              _id?: string | null | undefined;
                              examCode?: string | null | undefined;
                              examShortTitle?: string | null | undefined;
                              examFullName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        schoolRef?:
                          | {
                              __typename?: 'SchoolResponseType';
                              _id?: number | null | undefined;
                              name?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: 'Document';
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtQualificationReviewCsExamNotRecentPastFieldsFragment = {
  __typename?: 'CaseResponseType';
  pathway2?:
    | {
        __typename?: 'Pathway2';
        userResponse?:
          | {
              __typename?: 'Pathway2UserResponse';
              hasPassedCSExamInRecentPast?: boolean | null | undefined;
              hasPassedFromQualifiedSchoolInRecentPast?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: 'CSExam';
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: 'CSExamData';
                        examDate?: string | null | undefined;
                        oscExamRef?:
                          | {
                              __typename?: 'oscexamResponseType';
                              _id?: string | null | undefined;
                              examCode?: string | null | undefined;
                              examShortTitle?: string | null | undefined;
                              examFullName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        schoolRef?:
                          | {
                              __typename?: 'SchoolResponseType';
                              _id?: number | null | undefined;
                              name?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: 'Document';
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        staffProvidedData?:
          | Array<
              | {
                  __typename?: 'CSExam';
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: 'CSExamData';
                        examDate?: string | null | undefined;
                        oscExamRef?:
                          | {
                              __typename?: 'oscexamResponseType';
                              _id?: string | null | undefined;
                              examCode?: string | null | undefined;
                              examShortTitle?: string | null | undefined;
                              examFullName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        schoolRef?:
                          | {
                              __typename?: 'SchoolResponseType';
                              _id?: number | null | undefined;
                              name?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: 'Document';
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtQualificationReviewEducationFieldsFragment = {
  __typename?: 'CaseResponseType';
  pathway345?:
    | {
        __typename?: 'Pathway345';
        userResponse?:
          | {
              __typename?: 'Pathway345UserResponse';
              isGradSchoolEligible?: boolean | null | undefined;
              isGradDateInAcceptableRange?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: 'Education';
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: 'EducationData';
                        _id?: string | null | undefined;
                        gradDate?: string | null | undefined;
                        schoolRef?:
                          | {
                              __typename?: 'SchoolResponseType';
                              _id?: number | null | undefined;
                              name?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        staffProvidedData?:
          | Array<
              | {
                  __typename?: 'Education';
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: 'EducationData';
                        _id?: string | null | undefined;
                        gradDate?: string | null | undefined;
                        schoolRef?:
                          | {
                              __typename?: 'SchoolResponseType';
                              _id?: number | null | undefined;
                              name?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  caseSummary?:
    | { __typename?: 'CaseSummaryType'; currentPathway?: string | null | undefined }
    | null
    | undefined;
};

export type CaseMgmtQualificationReviewExceptionFieldsFragment = {
  __typename?: 'CaseResponseType';
  pathwayX?:
    | {
        __typename?: 'ExceptionRequest';
        _id?: string | null | undefined;
        data?:
          | {
              __typename?: 'ExceptionRequestData';
              requestText?: string | null | undefined;
              documents?:
                | Array<
                    | {
                        __typename?: 'Document';
                        docId?: string | null | undefined;
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtQualificationReviewLanguageAssessmentFieldsFragment = {
  __typename?: 'CaseResponseType';
  languageAssessment?:
    | {
        __typename?: 'LanguageAssessment';
        userResponse?:
          | {
              __typename?: 'LanguageAssessmentUserResponse';
              hasPassedExam?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: 'LanguageAssessmentType';
                  data?:
                    | {
                        __typename?: 'LanguageAssessmentData';
                        candidateId?: string | null | undefined;
                        examDate?: string | null | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: 'Document';
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtQualificationReviewLicenseFieldsFragment = {
  __typename?: 'CaseResponseType';
  pathway1?:
    | {
        __typename?: 'Pathway1';
        userResponse?:
          | {
              __typename?: 'Pathway1UserResponse';
              isCurrentlyLicensed?: boolean | null | undefined;
              wasLicensedInRecentPast?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: 'License';
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: 'LicenseData';
                        _id?: string | null | undefined;
                        licensureDocumentStatus?: string | null | undefined;
                        licenseIssueDate?: string | null | undefined;
                        licenseExpiryDate?: string | null | undefined;
                        isCurrentlyLicensed?: boolean | null | undefined;
                        hasDisciplinaryAction?: boolean | null | undefined;
                        regulatoryAuthorityRef?:
                          | {
                              __typename?: 'regulatoryAuthorityResponseType';
                              _id?: number | null | undefined;
                              orgName?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: 'Document';
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        staffProvidedData?:
          | Array<
              | {
                  __typename?: 'License';
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: 'LicenseData';
                        _id?: string | null | undefined;
                        licensureDocumentStatus?: string | null | undefined;
                        licenseIssueDate?: string | null | undefined;
                        licenseExpiryDate?: string | null | undefined;
                        isCurrentlyLicensed?: boolean | null | undefined;
                        hasDisciplinaryAction?: boolean | null | undefined;
                        regulatoryAuthorityRef?:
                          | {
                              __typename?: 'regulatoryAuthorityResponseType';
                              _id?: number | null | undefined;
                              orgName?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: 'Document';
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtOetDocumentReviewApplicantDetailsFieldsFragment = {
  __typename?: 'CaseResponseType';
  caseSummary?:
    | {
        __typename?: 'CaseSummaryType';
        usmleId?: string | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        currentPathway?: string | null | undefined;
        submittedAt?: any | null | undefined;
      }
    | null
    | undefined;
  applicant?:
    | {
        __typename?: 'C_ApplicantType';
        email?: string | null | undefined;
        dateOfBirth?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtOetDocumentReviewChecklistReviewMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  recordId: Scalars['ID'];
  isComplete: Scalars['Boolean'];
  checklistResponse?: InputMaybe<LanguageAssessmentChecklistInput>;
  checklistState?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtOetDocumentReviewChecklistReviewMutation = {
  __typename?: 'Mutation';
  CasePortal_languageAssessment_setReview?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtOetDocumentReviewLayoutCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtOetDocumentReviewLayoutCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    dataVersion?: number | null | undefined;
                    eligibilityReviewAssigneeId?: string | null | undefined;
                    englishEligibilityStatus?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    currentPathway?: string | null | undefined;
                    submittedAt?: any | null | undefined;
                  }
                | null
                | undefined;
              applicant?:
                | {
                    __typename?: 'C_ApplicantType';
                    email?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                  }
                | null
                | undefined;
              additionalDocuments?:
                | Array<
                    | {
                        __typename?: 'AdditionalDocument';
                        document?:
                          | {
                              __typename?: 'Document';
                              docType?: string | null | undefined;
                              docId?: string | null | undefined;
                              title?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              languageAssessment?:
                | {
                    __typename?: 'LanguageAssessment';
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'LanguageAssessmentType';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'LanguageAssessmentData';
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              docId?: string | null | undefined;
                                              docType?: string | null | undefined;
                                              title?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              review?:
                                | {
                                    __typename?: 'LanguageAssessmentReview';
                                    checklistState?: string | null | undefined;
                                    status?: string | null | undefined;
                                    checklist?:
                                      | {
                                          __typename?: 'LanguageAssessmentChecklist';
                                          nameValid?: string | null | undefined;
                                          scoreConfirmed?: string | null | undefined;
                                          scoreAcceptable?: string | null | undefined;
                                          examDateValidation?: string | null | undefined;
                                          documentCompleteLegible?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | {
                              __typename?: 'LanguageAssessmentType';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'LanguageAssessmentData';
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              docId?: string | null | undefined;
                                              docType?: string | null | undefined;
                                              uploadedBy?:
                                                | {
                                                    __typename?: 'DocumentUploadUser';
                                                    name?: string | null | undefined;
                                                  }
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              review?:
                                | {
                                    __typename?: 'LanguageAssessmentReview';
                                    checklistState?: string | null | undefined;
                                    status?: string | null | undefined;
                                    checklist?:
                                      | {
                                          __typename?: 'LanguageAssessmentChecklist';
                                          nameValid?: string | null | undefined;
                                          scoreConfirmed?: string | null | undefined;
                                          scoreAcceptable?: string | null | undefined;
                                          examDateValidation?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              oetScore?:
                | {
                    __typename?: 'OetResponseType';
                    readingScore?: string | null | undefined;
                    listeningScore?: string | null | undefined;
                    writingScore?: string | null | undefined;
                    speakingScore?: string | null | undefined;
                    result?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    examDate?: string | null | undefined;
                    candidateId?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    testingCenter?: string | null | undefined;
                    examineeLastName?: string | null | undefined;
                    examineeFirstName?: string | null | undefined;
                  }
                | null
                | undefined;
              activityLog?:
                | Array<
                    | {
                        __typename?: 'ActivityLogType';
                        timestamp?: any | null | undefined;
                        logType?: string | null | undefined;
                        message?: string | null | undefined;
                        user?:
                          | {
                              __typename?: 'ActivityLogUserType';
                              role?: string | null | undefined;
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              uiConfig?:
                | {
                    __typename?: 'UIPortalConfigType';
                    config?:
                      | {
                          __typename?: 'UIConfigRecordType';
                          pages?:
                            | Array<
                                | {
                                    __typename?: 'UIPageConfigType';
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: 'UIComponentConfigType';
                                              componentName?: string | null | undefined;
                                              version?: number | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtOetDocumentReviewLayoutCasePortalAddAndDeleteAdditionDocumentsMutationVariables =
  Exact<{
    usmleId: Scalars['String'];
    dataVersion: Scalars['Int'];
    input: Array<AdditionalDocumentInput> | AdditionalDocumentInput;
  }>;

export type CaseMgmtOetDocumentReviewLayoutCasePortalAddAndDeleteAdditionDocumentsMutation = {
  __typename?: 'Mutation';
  CasePortal_additionalDocuments?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        additionalDocuments?:
          | Array<
              | {
                  __typename?: 'AdditionalDocument';
                  notes?: string | null | undefined;
                  document?:
                    | {
                        __typename?: 'Document';
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                        docId?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway1DocumentReviewChecklistReviewMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  recordId: Scalars['ID'];
  isComplete: Scalars['Boolean'];
  checklistResponse?: InputMaybe<LicenseChecklistInput>;
  checklistState?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtPathway1DocumentReviewChecklistReviewMutation = {
  __typename?: 'Mutation';
  CasePortal_pathway1_setReview?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        pathway1?:
          | {
              __typename?: 'Pathway1';
              staffProvidedData?:
                | Array<
                    | {
                        __typename?: 'License';
                        review?:
                          | { __typename?: 'LicenseReview'; status?: string | null | undefined }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway1DocumentReviewLayoutCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtPathway1DocumentReviewLayoutCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              pathway1?:
                | {
                    __typename?: 'Pathway1';
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'License';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'LicenseData';
                                    licenseIssueDate?: string | null | undefined;
                                    licenseExpiryDate?: string | null | undefined;
                                    hasDisciplinaryAction?: boolean | null | undefined;
                                    regulatoryAuthorityRef?:
                                      | {
                                          __typename?: 'regulatoryAuthorityResponseType';
                                          orgName?: string | null | undefined;
                                          countryName?: string | null | undefined;
                                          _id?: number | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              review?:
                                | {
                                    __typename?: 'LicenseReview';
                                    checklistState?: string | null | undefined;
                                    status?: string | null | undefined;
                                    checklist?:
                                      | {
                                          __typename?: 'LicenseChecklist';
                                          licensureUnrestrictedLicense?: string | null | undefined;
                                          licensureIssuedAppropriateAuthority?:
                                            | string
                                            | null
                                            | undefined;
                                          licensureApplicantLicenseOnPoint?:
                                            | string
                                            | null
                                            | undefined;
                                          licensureDisciplinaryAction?: string | null | undefined;
                                          licensureNameValidation?: string | null | undefined;
                                          licensureCompleteLegible?: string | null | undefined;
                                          licensureTranslationStatus?: string | null | undefined;
                                          licensureDocumentIssueDateAcceptable?:
                                            | string
                                            | null
                                            | undefined;
                                          verificationAuthorized?: string | null | undefined;
                                          verificationPrimarySource?: string | null | undefined;
                                          verificationActiveLicense?: string | null | undefined;
                                          verificationGoodStanding?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | {
                              __typename?: 'License';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'LicenseData';
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              docId?: string | null | undefined;
                                              docType?: string | null | undefined;
                                              title?: string | null | undefined;
                                              uploadedBy?:
                                                | {
                                                    __typename?: 'DocumentUploadUser';
                                                    name?: string | null | undefined;
                                                  }
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              review?:
                                | {
                                    __typename?: 'LicenseReview';
                                    checklistState?: string | null | undefined;
                                    status?: string | null | undefined;
                                    checklist?:
                                      | {
                                          __typename?: 'LicenseChecklist';
                                          licensureUnrestrictedLicense?: string | null | undefined;
                                          licensureIssuedAppropriateAuthority?:
                                            | string
                                            | null
                                            | undefined;
                                          licensureApplicantLicenseOnPoint?:
                                            | string
                                            | null
                                            | undefined;
                                          licensureDisciplinaryAction?: string | null | undefined;
                                          licensureNameValidation?: string | null | undefined;
                                          licensureCompleteLegible?: string | null | undefined;
                                          licensureTranslationStatus?: string | null | undefined;
                                          licensureDocumentIssueDateAcceptable?:
                                            | string
                                            | null
                                            | undefined;
                                          verificationAuthorized?: string | null | undefined;
                                          verificationPrimarySource?: string | null | undefined;
                                          verificationActiveLicense?: string | null | undefined;
                                          verificationGoodStanding?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    dataVersion?: number | null | undefined;
                    eligibilityReviewAssigneeId?: string | null | undefined;
                    pathwayEligibilityStatus?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    submittedAt?: any | null | undefined;
                    currentPathway?: string | null | undefined;
                  }
                | null
                | undefined;
              applicant?:
                | {
                    __typename?: 'C_ApplicantType';
                    email?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    credStatusCode?: string | null | undefined;
                    credComments?: string | null | undefined;
                  }
                | null
                | undefined;
              additionalDocuments?:
                | Array<
                    | {
                        __typename?: 'AdditionalDocument';
                        document?:
                          | {
                              __typename?: 'Document';
                              docType?: string | null | undefined;
                              docId?: string | null | undefined;
                              title?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              uiConfig?:
                | {
                    __typename?: 'UIPortalConfigType';
                    config?:
                      | {
                          __typename?: 'UIConfigRecordType';
                          pages?:
                            | Array<
                                | {
                                    __typename?: 'UIPageConfigType';
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: 'UIComponentConfigType';
                                              componentName?: string | null | undefined;
                                              version?: number | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              activityLog?:
                | Array<
                    | {
                        __typename?: 'ActivityLogType';
                        timestamp?: any | null | undefined;
                        logType?: string | null | undefined;
                        message?: string | null | undefined;
                        user?:
                          | {
                              __typename?: 'ActivityLogUserType';
                              role?: string | null | undefined;
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway1DocumentReviewLayoutAddLicenseMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  recordId?: InputMaybe<Scalars['ID']>;
  input: LicenseDataInput;
}>;

export type CaseMgmtPathway1DocumentReviewLayoutAddLicenseMutation = {
  __typename?: 'Mutation';
  CasePortal_pathway1_setData?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        pathway1?:
          | {
              __typename?: 'Pathway1';
              staffProvidedData?:
                | Array<
                    | {
                        __typename?: 'License';
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: 'LicenseData';
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        docId?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway1DocumentReviewLayoutDeleteLicenseMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  recordId: Scalars['ID'];
}>;

export type CaseMgmtPathway1DocumentReviewLayoutDeleteLicenseMutation = {
  __typename?: 'Mutation';
  CasePortal_pathway1_deleteRecord?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        pathway1?:
          | {
              __typename?: 'Pathway1';
              staffProvidedData?:
                | Array<
                    | {
                        __typename?: 'License';
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: 'LicenseData';
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        docId?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway1DocumentReviewLayoutAddAndDeleteDocumentMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  input: Array<AdditionalDocumentInput> | AdditionalDocumentInput;
}>;

export type CaseMgmtPathway1DocumentReviewLayoutAddAndDeleteDocumentMutation = {
  __typename?: 'Mutation';
  CasePortal_additionalDocuments?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        additionalDocuments?:
          | Array<
              | {
                  __typename?: 'AdditionalDocument';
                  notes?: string | null | undefined;
                  document?:
                    | {
                        __typename?: 'Document';
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                        docId?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway1LayoutCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtPathway1LayoutCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              pathway1?:
                | {
                    __typename?: 'Pathway1';
                    staffProvidedData?:
                      | Array<
                          | {
                              __typename?: 'License';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'LicenseData';
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              docType?: string | null | undefined;
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'License';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'LicenseData';
                                    _id?: string | null | undefined;
                                    licenseIssueDate?: string | null | undefined;
                                    licenseExpiryDate?: string | null | undefined;
                                    hasDisciplinaryAction?: boolean | null | undefined;
                                    isCurrentlyLicensed?: boolean | null | undefined;
                                    licensureDocumentStatus?: string | null | undefined;
                                    regulatoryAuthorityRef?:
                                      | {
                                          __typename?: 'regulatoryAuthorityResponseType';
                                          orgName?: string | null | undefined;
                                          countryName?: string | null | undefined;
                                          _id?: number | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              docType?: string | null | undefined;
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              additionalDocuments?:
                | Array<
                    | {
                        __typename?: 'AdditionalDocument';
                        document?:
                          | {
                              __typename?: 'Document';
                              docType?: string | null | undefined;
                              docId?: string | null | undefined;
                              title?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    dataVersion?: number | null | undefined;
                    caseState?: string | null | undefined;
                    eligibilityReviewAssigneeId?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    submittedAt?: any | null | undefined;
                    currentPathway?: string | null | undefined;
                  }
                | null
                | undefined;
              uiConfig?:
                | {
                    __typename?: 'UIPortalConfigType';
                    config?:
                      | {
                          __typename?: 'UIConfigRecordType';
                          pages?:
                            | Array<
                                | {
                                    __typename?: 'UIPageConfigType';
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: 'UIComponentConfigType';
                                              componentName?: string | null | undefined;
                                              version?: number | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              applicant?:
                | {
                    __typename?: 'C_ApplicantType';
                    email?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    credStatusCode?: string | null | undefined;
                    credComments?: string | null | undefined;
                  }
                | null
                | undefined;
              activityLog?:
                | Array<
                    | {
                        __typename?: 'ActivityLogType';
                        timestamp?: any | null | undefined;
                        logType?: string | null | undefined;
                        message?: string | null | undefined;
                        user?:
                          | {
                              __typename?: 'ActivityLogUserType';
                              role?: string | null | undefined;
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway1LayoutAddLicenseMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  recordId?: InputMaybe<Scalars['ID']>;
  input: LicenseDataInput;
}>;

export type CaseMgmtPathway1LayoutAddLicenseMutation = {
  __typename?: 'Mutation';
  CasePortal_pathway1_setData?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        pathway1?:
          | {
              __typename?: 'Pathway1';
              staffProvidedData?:
                | Array<
                    | {
                        __typename?: 'License';
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: 'LicenseData';
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        docId?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway1LayoutDeleteLicenseMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  recordId: Scalars['ID'];
}>;

export type CaseMgmtPathway1LayoutDeleteLicenseMutation = {
  __typename?: 'Mutation';
  CasePortal_pathway1_deleteRecord?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        pathway1?:
          | {
              __typename?: 'Pathway1';
              staffProvidedData?:
                | Array<
                    | {
                        __typename?: 'License';
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: 'LicenseData';
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        docId?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway1LayoutAddAdditionalDocumentMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  input: Array<AdditionalDocumentInput> | AdditionalDocumentInput;
}>;

export type CaseMgmtPathway1LayoutAddAdditionalDocumentMutation = {
  __typename?: 'Mutation';
  CasePortal_additionalDocuments?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        additionalDocuments?:
          | Array<
              | {
                  __typename?: 'AdditionalDocument';
                  notes?: string | null | undefined;
                  document?:
                    | {
                        __typename?: 'Document';
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                        docId?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway1ReviewApplicantDetailsFieldsFragment = {
  __typename?: 'CaseResponseType';
  caseSummary?:
    | {
        __typename?: 'CaseSummaryType';
        usmleId?: string | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        submittedAt?: any | null | undefined;
        currentPathway?: string | null | undefined;
      }
    | null
    | undefined;
  applicant?:
    | {
        __typename?: 'C_ApplicantType';
        email?: string | null | undefined;
        dateOfBirth?: string | null | undefined;
        credStatusCode?: string | null | undefined;
        credComments?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway2DocumentReviewApplicantDetailsFieldsFragment = {
  __typename?: 'CaseResponseType';
  caseSummary?:
    | {
        __typename?: 'CaseSummaryType';
        usmleId?: string | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        currentPathway?: string | null | undefined;
        submittedAt?: any | null | undefined;
      }
    | null
    | undefined;
  applicant?:
    | {
        __typename?: 'C_ApplicantType';
        email?: string | null | undefined;
        dateOfBirth?: string | null | undefined;
        gradSchool?: string | null | undefined;
        gradYear?: string | null | undefined;
        lastDataRefreshTimestamp?: any | null | undefined;
        credStatusCode?: string | null | undefined;
        credComments?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway2DocumentReviewChecklistReviewMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  recordId?: InputMaybe<Scalars['ID']>;
  isComplete: Scalars['Boolean'];
  checklistResponse?: InputMaybe<CsExamChecklistInput>;
  checklistState?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtPathway2DocumentReviewChecklistReviewMutation = {
  __typename?: 'Mutation';
  CasePortal_pathway2_setReview?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        pathway2?:
          | {
              __typename?: 'Pathway2';
              staffProvidedData?:
                | Array<
                    | {
                        __typename?: 'CSExam';
                        review?:
                          | { __typename?: 'CSExamReview'; status?: string | null | undefined }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway2DocumentReviewLayoutCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtPathway2DocumentReviewLayoutCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    dataVersion?: number | null | undefined;
                    eligibilityReviewAssigneeId?: string | null | undefined;
                    pathwayEligibilityStatus?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    currentPathway?: string | null | undefined;
                    submittedAt?: any | null | undefined;
                  }
                | null
                | undefined;
              applicant?:
                | {
                    __typename?: 'C_ApplicantType';
                    email?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    gradSchool?: string | null | undefined;
                    gradYear?: string | null | undefined;
                    lastDataRefreshTimestamp?: any | null | undefined;
                    credStatusCode?: string | null | undefined;
                    credComments?: string | null | undefined;
                  }
                | null
                | undefined;
              additionalDocuments?:
                | Array<
                    | {
                        __typename?: 'AdditionalDocument';
                        document?:
                          | {
                              __typename?: 'Document';
                              docType?: string | null | undefined;
                              docId?: string | null | undefined;
                              title?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pathway2?:
                | {
                    __typename?: 'Pathway2';
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'CSExam';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'CSExamData';
                                    examDate?: string | null | undefined;
                                    oscExamRef?:
                                      | {
                                          __typename?: 'oscexamResponseType';
                                          _id?: string | null | undefined;
                                          examCode?: string | null | undefined;
                                          examFullName?: string | null | undefined;
                                          examShortTitle?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              review?:
                                | {
                                    __typename?: 'CSExamReview';
                                    checklistState?: string | null | undefined;
                                    status?: string | null | undefined;
                                    checklist?:
                                      | {
                                          __typename?: 'CSExamChecklist';
                                          osceExaminationAcceptable?: string | null | undefined;
                                          osceExaminationPassed?: string | null | undefined;
                                          osceExaminationAfterJuly1?: string | null | undefined;
                                          osceNameOK?: string | null | undefined;
                                          osceTranslation?: string | null | undefined;
                                          osceDocumentCompleteLegible?: string | null | undefined;
                                          verificationCompleted?: string | null | undefined;
                                          verificationReturned?: string | null | undefined;
                                          verificationPassedExamConfirmed?:
                                            | string
                                            | null
                                            | undefined;
                                          verificationAcceptableDateConfirmed?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | {
                              __typename?: 'CSExam';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'CSExamData';
                                    examDate?: string | null | undefined;
                                    oscExamRef?:
                                      | {
                                          __typename?: 'oscexamResponseType';
                                          _id?: string | null | undefined;
                                          examCode?: string | null | undefined;
                                          examFullName?: string | null | undefined;
                                          examShortTitle?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              review?:
                                | {
                                    __typename?: 'CSExamReview';
                                    checklistState?: string | null | undefined;
                                    status?: string | null | undefined;
                                    checklist?:
                                      | {
                                          __typename?: 'CSExamChecklist';
                                          osceExaminationAcceptable?: string | null | undefined;
                                          osceExaminationPassed?: string | null | undefined;
                                          osceExaminationAfterJuly1?: string | null | undefined;
                                          osceNameOK?: string | null | undefined;
                                          osceTranslation?: string | null | undefined;
                                          osceDocumentCompleteLegible?: string | null | undefined;
                                          verificationCompleted?: string | null | undefined;
                                          verificationReturned?: string | null | undefined;
                                          verificationPassedExamConfirmed?:
                                            | string
                                            | null
                                            | undefined;
                                          verificationAcceptableDateConfirmed?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              activityLog?:
                | Array<
                    | {
                        __typename?: 'ActivityLogType';
                        timestamp?: any | null | undefined;
                        logType?: string | null | undefined;
                        message?: string | null | undefined;
                        user?:
                          | {
                              __typename?: 'ActivityLogUserType';
                              role?: string | null | undefined;
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              uiConfig?:
                | {
                    __typename?: 'UIPortalConfigType';
                    config?:
                      | {
                          __typename?: 'UIConfigRecordType';
                          pages?:
                            | Array<
                                | {
                                    __typename?: 'UIPageConfigType';
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: 'UIComponentConfigType';
                                              componentName?: string | null | undefined;
                                              version?: number | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway2DocumentReviewLayoutCasePortalAddAndDeleteAdditionalDocumentMutationVariables =
  Exact<{
    usmleId: Scalars['String'];
    dataVersion: Scalars['Int'];
    input: Array<AdditionalDocumentInput> | AdditionalDocumentInput;
  }>;

export type CaseMgmtPathway2DocumentReviewLayoutCasePortalAddAndDeleteAdditionalDocumentMutation = {
  __typename?: 'Mutation';
  CasePortal_additionalDocuments?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        additionalDocuments?:
          | Array<
              | {
                  __typename?: 'AdditionalDocument';
                  notes?: string | null | undefined;
                  document?:
                    | {
                        __typename?: 'Document';
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                        docId?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway3DocumentReviewApplicantDetailsFieldsFragment = {
  __typename?: 'CaseResponseType';
  caseSummary?:
    | {
        __typename?: 'CaseSummaryType';
        usmleId?: string | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        submittedAt?: any | null | undefined;
        currentPathway?: string | null | undefined;
        dateOfBirth?: string | null | undefined;
      }
    | null
    | undefined;
  applicant?:
    | {
        __typename?: 'C_ApplicantType';
        email?: string | null | undefined;
        dateOfBirth?: string | null | undefined;
        gradSchool?: string | null | undefined;
        gradYear?: string | null | undefined;
        lastDataRefreshTimestamp?: any | null | undefined;
        credStatusCode?: string | null | undefined;
        credComments?: string | null | undefined;
        schoolEligibility?:
          | {
              __typename?: 'SchoolEligibilityType';
              isSchoolEMSWPMember?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway3DocumentReviewChecklistReviewMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  recordId?: InputMaybe<Scalars['ID']>;
  isComplete: Scalars['Boolean'];
  checklistResponse?: InputMaybe<EducationChecklistInput>;
  checklistState?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtPathway3DocumentReviewChecklistReviewMutation = {
  __typename?: 'Mutation';
  CasePortal_pathway345_setReview?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        pathway345?:
          | {
              __typename?: 'Pathway345';
              applicantProvidedData?:
                | Array<
                    | {
                        __typename?: 'Education';
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: 'EducationData';
                              gradDate?: string | null | undefined;
                              schoolRef?:
                                | {
                                    __typename?: 'SchoolResponseType';
                                    name?: string | null | undefined;
                                    countryName?: string | null | undefined;
                                    _id?: number | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        review?:
                          | {
                              __typename?: 'EducationReview';
                              checklistState?: string | null | undefined;
                              checklist?:
                                | {
                                    __typename?: 'EducationChecklist';
                                    eligibilityMedicalSchool?: string | null | undefined;
                                    eligibilityGradDateEligible?: string | null | undefined;
                                    attestationComplete?: string | null | undefined;
                                    attestationAuthorizedOfficial?: string | null | undefined;
                                    attestationTitleOk?: string | null | undefined;
                                    attestationSignatureMatch?: string | null | undefined;
                                    attestationSealOk?: string | null | undefined;
                                    attestationSignatureDate?: string | null | undefined;
                                    attestationSentPrimarySource?: string | null | undefined;
                                    attestationCompletedByMedicalSchool?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              staffProvidedData?:
                | Array<
                    | {
                        __typename?: 'Education';
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: 'EducationData';
                              gradDate?: string | null | undefined;
                              schoolRef?:
                                | {
                                    __typename?: 'SchoolResponseType';
                                    name?: string | null | undefined;
                                    countryName?: string | null | undefined;
                                    _id?: number | null | undefined;
                                  }
                                | null
                                | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: 'Document';
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        review?:
                          | {
                              __typename?: 'EducationReview';
                              checklistState?: string | null | undefined;
                              checklist?:
                                | {
                                    __typename?: 'EducationChecklist';
                                    eligibilityMedicalSchool?: string | null | undefined;
                                    eligibilityGradDateEligible?: string | null | undefined;
                                    attestationComplete?: string | null | undefined;
                                    attestationAuthorizedOfficial?: string | null | undefined;
                                    attestationTitleOk?: string | null | undefined;
                                    attestationSignatureMatch?: string | null | undefined;
                                    attestationSealOk?: string | null | undefined;
                                    attestationSignatureDate?: string | null | undefined;
                                    attestationSentPrimarySource?: string | null | undefined;
                                    attestationCompletedByMedicalSchool?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CastMgmtPathway3DocumentReviewLayoutCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
}>;

export type CastMgmtPathway3DocumentReviewLayoutCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              uiConfig?:
                | {
                    __typename?: 'UIPortalConfigType';
                    config?:
                      | {
                          __typename?: 'UIConfigRecordType';
                          portal?: string | null | undefined;
                          pages?:
                            | Array<
                                | {
                                    __typename?: 'UIPageConfigType';
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: 'UIComponentConfigType';
                                              componentName?: string | null | undefined;
                                              version?: number | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    dataVersion?: number | null | undefined;
                    eligibilityReviewAssigneeId?: string | null | undefined;
                    pathwayEligibilityStatus?: string | null | undefined;
                    isPathwayChanged?: boolean | null | undefined;
                    usmleId?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    submittedAt?: any | null | undefined;
                    currentPathway?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                  }
                | null
                | undefined;
              applicant?:
                | {
                    __typename?: 'C_ApplicantType';
                    email?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    gradSchool?: string | null | undefined;
                    gradYear?: string | null | undefined;
                    lastDataRefreshTimestamp?: any | null | undefined;
                    credStatusCode?: string | null | undefined;
                    credComments?: string | null | undefined;
                    schoolEligibility?:
                      | {
                          __typename?: 'SchoolEligibilityType';
                          isSchoolEMSWPMember?: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              additionalDocuments?:
                | Array<
                    | {
                        __typename?: 'AdditionalDocument';
                        document?:
                          | {
                              __typename?: 'Document';
                              docType?: string | null | undefined;
                              docId?: string | null | undefined;
                              title?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pathway345?:
                | {
                    __typename?: 'Pathway345';
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'Education';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'EducationData';
                                    gradDate?: string | null | undefined;
                                    schoolRef?:
                                      | {
                                          __typename?: 'SchoolResponseType';
                                          name?: string | null | undefined;
                                          countryName?: string | null | undefined;
                                          _id?: number | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              review?:
                                | {
                                    __typename?: 'EducationReview';
                                    checklistState?: string | null | undefined;
                                    status?: string | null | undefined;
                                    checklist?:
                                      | {
                                          __typename?: 'EducationChecklist';
                                          eligibilityMedicalSchool?: string | null | undefined;
                                          eligibilityGradDateEligible?: string | null | undefined;
                                          attestationComplete?: string | null | undefined;
                                          attestationAuthorizedOfficial?: string | null | undefined;
                                          attestationTitleOk?: string | null | undefined;
                                          attestationSignatureMatch?: string | null | undefined;
                                          attestationSealOk?: string | null | undefined;
                                          attestationSignatureDate?: string | null | undefined;
                                          attestationSentPrimarySource?: string | null | undefined;
                                          attestationCompletedByMedicalSchool?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              attestation?:
                                | {
                                    __typename?: 'EducationAttestation';
                                    output?: string | null | undefined;
                                    outputReason?: string | null | undefined;
                                    outputReasonText?: string | null | undefined;
                                    status?: string | null | undefined;
                                    updatedTimestamp?: any | null | undefined;
                                    updatedBy?:
                                      | {
                                          __typename?: 'UserType';
                                          id?: string | null | undefined;
                                          name?: string | null | undefined;
                                          role?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | {
                              __typename?: 'Education';
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: 'EducationData';
                                    gradDate?: string | null | undefined;
                                    schoolRef?:
                                      | {
                                          __typename?: 'SchoolResponseType';
                                          name?: string | null | undefined;
                                          countryName?: string | null | undefined;
                                          _id?: number | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              docId?: string | null | undefined;
                                              docType?: string | null | undefined;
                                              title?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              review?:
                                | {
                                    __typename?: 'EducationReview';
                                    checklistState?: string | null | undefined;
                                    status?: string | null | undefined;
                                    checklist?:
                                      | {
                                          __typename?: 'EducationChecklist';
                                          eligibilityMedicalSchool?: string | null | undefined;
                                          eligibilityGradDateEligible?: string | null | undefined;
                                          attestationComplete?: string | null | undefined;
                                          attestationAuthorizedOfficial?: string | null | undefined;
                                          attestationTitleOk?: string | null | undefined;
                                          attestationSignatureMatch?: string | null | undefined;
                                          attestationSealOk?: string | null | undefined;
                                          attestationSignatureDate?: string | null | undefined;
                                          attestationSentPrimarySource?: string | null | undefined;
                                          attestationCompletedByMedicalSchool?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              attestation?:
                                | {
                                    __typename?: 'EducationAttestation';
                                    output?: string | null | undefined;
                                    outputReason?: string | null | undefined;
                                    outputReasonText?: string | null | undefined;
                                    status?: string | null | undefined;
                                    updatedTimestamp?: any | null | undefined;
                                    updatedBy?:
                                      | {
                                          __typename?: 'UserType';
                                          id?: string | null | undefined;
                                          name?: string | null | undefined;
                                          role?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              activityLog?:
                | Array<
                    | {
                        __typename?: 'ActivityLogType';
                        timestamp?: any | null | undefined;
                        logType?: string | null | undefined;
                        message?: string | null | undefined;
                        user?:
                          | {
                              __typename?: 'ActivityLogUserType';
                              role?: string | null | undefined;
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway3DocumentReviewLayoutAddAndDeleteDocumentMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  input: Array<AdditionalDocumentInput> | AdditionalDocumentInput;
}>;

export type CaseMgmtPathway3DocumentReviewLayoutAddAndDeleteDocumentMutation = {
  __typename?: 'Mutation';
  CasePortal_additionalDocuments?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        additionalDocuments?:
          | Array<
              | {
                  __typename?: 'AdditionalDocument';
                  notes?: string | null | undefined;
                  document?:
                    | {
                        __typename?: 'Document';
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                        docId?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway6CancelMinicexCasePortalMiniCexCancelMutationVariables = Exact<{
  encounterId: Scalars['String'];
  minicexDataVersion: Scalars['Int'];
  isMiniCEXAssessmentResetConfirmed: Scalars['Boolean'];
}>;

export type CaseMgmtPathway6CancelMinicexCasePortalMiniCexCancelMutation = {
  __typename?: 'Mutation';
  CasePortal_MiniCEX_cancel?:
    | {
        __typename?: 'MiniCEXResponseType';
        dataVersion?: number | null | undefined;
        minicexId?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway6DocumentReviewApplicantDetailsFieldsFragment = {
  __typename?: 'CaseResponseType';
  caseSummary?:
    | {
        __typename?: 'CaseSummaryType';
        usmleId?: string | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        currentPathway?: string | null | undefined;
        submittedAt?: any | null | undefined;
      }
    | null
    | undefined;
  applicant?:
    | {
        __typename?: 'C_ApplicantType';
        email?: string | null | undefined;
        dateOfBirth?: string | null | undefined;
        gradSchool?: string | null | undefined;
        gradYear?: string | null | undefined;
        lastDataRefreshTimestamp?: any | null | undefined;
        credStatusCode?: string | null | undefined;
        credComments?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway6DocumentReviewChecklistReviewMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  recordId?: InputMaybe<Scalars['ID']>;
  isComplete: Scalars['Boolean'];
  checklistResponse?: InputMaybe<MiniCexChecklistInput>;
  checklistState?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtPathway6DocumentReviewChecklistReviewMutation = {
  __typename?: 'Mutation';
  CasePortal_pathway6_setReview?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway6DocumentReviewLayoutUnlockReviewMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  recordId: Scalars['ID'];
}>;

export type CaseMgmtPathway6DocumentReviewLayoutUnlockReviewMutation = {
  __typename?: 'Mutation';
  CasePortal_pathway6_unlockReview?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; _id?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway6DocumentReviewLayoutCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
  filter: MiniCexListFilterCaseManagerInput;
}>;

export type CaseMgmtPathway6DocumentReviewLayoutCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              _id?: string | null | undefined;
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    dataVersion?: number | null | undefined;
                    eligibilityReviewAssigneeId?: string | null | undefined;
                    pathwayEligibilityStatus?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    lastName?: string | null | undefined;
                    firstName?: string | null | undefined;
                    submittedAt?: any | null | undefined;
                    currentPathway?: string | null | undefined;
                  }
                | null
                | undefined;
              applicant?:
                | {
                    __typename?: 'C_ApplicantType';
                    email?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    gradSchool?: string | null | undefined;
                    gradYear?: string | null | undefined;
                    lastDataRefreshTimestamp?: any | null | undefined;
                    credStatusCode?: string | null | undefined;
                    credComments?: string | null | undefined;
                  }
                | null
                | undefined;
              additionalDocuments?:
                | Array<
                    | {
                        __typename?: 'AdditionalDocument';
                        document?:
                          | {
                              __typename?: 'Document';
                              docType?: string | null | undefined;
                              docId?: string | null | undefined;
                              title?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              uiConfig?:
                | {
                    __typename?: 'UIPortalConfigType';
                    config?:
                      | {
                          __typename?: 'UIConfigRecordType';
                          pages?:
                            | Array<
                                | {
                                    __typename?: 'UIPageConfigType';
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: 'UIComponentConfigType';
                                              componentName?: string | null | undefined;
                                              version?: number | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathway6?:
                | {
                    __typename?: 'Pathway6';
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'Pathway6MiniCEXType';
                              _id?: string | null | undefined;
                              areAllMiniCEXComplete?: boolean | null | undefined;
                              data?:
                                | {
                                    __typename?: 'MiniCEXData';
                                    _id?: string | null | undefined;
                                    acknowledgedTimestamp?: any | null | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              docType?: string | null | undefined;
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                              uploadedBy?:
                                                | {
                                                    __typename?: 'DocumentUploadUser';
                                                    id?: string | null | undefined;
                                                    name?: string | null | undefined;
                                                  }
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              review?:
                                | {
                                    __typename?: 'MiniCEXReview';
                                    checklistState?: string | null | undefined;
                                    status?: string | null | undefined;
                                    isLocked?: boolean | null | undefined;
                                    checklist?:
                                      | {
                                          __typename?: 'MiniCEXChecklist';
                                          minicexComplete?: string | null | undefined;
                                          minicexScoreAcceptable?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              assessment?:
                                | {
                                    __typename?: 'MiniCEXAssessmentType';
                                    categories?:
                                      | Array<
                                          | {
                                              __typename?: 'AssessmentCategoryType';
                                              name?: string | null | undefined;
                                              avgScore?: number | null | undefined;
                                              isFailedByAvgScore?: boolean | null | undefined;
                                              failedEncounters?:
                                                | Array<
                                                    | {
                                                        __typename?: 'FailedEncounterType';
                                                        encounterId?: string | null | undefined;
                                                        score?: number | null | undefined;
                                                      }
                                                    | null
                                                    | undefined
                                                  >
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | {
                              __typename?: 'Pathway6MiniCEXType';
                              _id?: string | null | undefined;
                              areAllMiniCEXComplete?: boolean | null | undefined;
                              data?:
                                | {
                                    __typename?: 'MiniCEXData';
                                    _id?: string | null | undefined;
                                    acknowledgedTimestamp?: any | null | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: 'Document';
                                              docType?: string | null | undefined;
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                              uploadedBy?:
                                                | {
                                                    __typename?: 'DocumentUploadUser';
                                                    id?: string | null | undefined;
                                                    name?: string | null | undefined;
                                                  }
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              review?:
                                | {
                                    __typename?: 'MiniCEXReview';
                                    checklistState?: string | null | undefined;
                                    status?: string | null | undefined;
                                    isLocked?: boolean | null | undefined;
                                    checklist?:
                                      | {
                                          __typename?: 'MiniCEXChecklist';
                                          minicexComplete?: string | null | undefined;
                                          minicexScoreAcceptable?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              assessment?:
                                | {
                                    __typename?: 'MiniCEXAssessmentType';
                                    categories?:
                                      | Array<
                                          | {
                                              __typename?: 'AssessmentCategoryType';
                                              name?: string | null | undefined;
                                              avgScore?: number | null | undefined;
                                              isFailedByAvgScore?: boolean | null | undefined;
                                              failedEncounters?:
                                                | Array<
                                                    | {
                                                        __typename?: 'FailedEncounterType';
                                                        encounterId?: string | null | undefined;
                                                        score?: number | null | undefined;
                                                      }
                                                    | null
                                                    | undefined
                                                  >
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    minicexList?:
                      | Array<
                          | {
                              __typename?: 'MiniCEXResponseType';
                              dataVersion?: number | null | undefined;
                              encounterId?: string | null | undefined;
                              summary?:
                                | {
                                    __typename?: 'MiniCEXSummaryType';
                                    dataVersion?: number | null | undefined;
                                    encounterId?: string | null | undefined;
                                    physicianEmail?: string | null | undefined;
                                    physicianLastNameProvidedByApplicant?:
                                      | string
                                      | null
                                      | undefined;
                                    physicianRestOfNameProvidedByApplicant?:
                                      | string
                                      | null
                                      | undefined;
                                    physicianLastNameProvidedByPhysician?:
                                      | string
                                      | null
                                      | undefined;
                                    physicianRestOfNameProvidedByPhysician?:
                                      | string
                                      | null
                                      | undefined;
                                    usmleId?: string | null | undefined;
                                    applicantLastName?: string | null | undefined;
                                    applicantRestOfName?: string | null | undefined;
                                    createdAt?: any | null | undefined;
                                    expiresAt?: any | null | undefined;
                                    acceptedAt?: any | null | undefined;
                                    completedAt?: any | null | undefined;
                                    state?: string | null | undefined;
                                    allowedActions?:
                                      | Array<string | null | undefined>
                                      | null
                                      | undefined;
                                    isActive?: boolean | null | undefined;
                                    rejectionReason?: string | null | undefined;
                                    rejectionReasonComments?: string | null | undefined;
                                    stateModel?:
                                      | Array<
                                          | {
                                              __typename?: 'MiniCEXStateModelType';
                                              state?: string | null | undefined;
                                              timestamp?: any | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              uiConfig?:
                                | {
                                    __typename?: 'UIPortalConfigType';
                                    config?:
                                      | {
                                          __typename?: 'UIConfigRecordType';
                                          pages?:
                                            | Array<
                                                | {
                                                    __typename?: 'UIPageConfigType';
                                                    pageName?: string | null | undefined;
                                                    components?:
                                                      | Array<
                                                          | {
                                                              __typename?: 'UIComponentConfigType';
                                                              componentName?:
                                                                | string
                                                                | null
                                                                | undefined;
                                                              version?: number | null | undefined;
                                                            }
                                                          | null
                                                          | undefined
                                                        >
                                                      | null
                                                      | undefined;
                                                  }
                                                | null
                                                | undefined
                                              >
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              form?:
                                | {
                                    __typename?: 'MiniCEXFormType';
                                    attestation?:
                                      | {
                                          __typename?: 'MiniCEXAttestationType';
                                          hasApplicantEnrolledInClinicalRotation?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    encounter?:
                                      | {
                                          __typename?: 'MiniCEXEncounterType';
                                          encounterDate?: string | null | undefined;
                                          encounterSetting?: string | null | undefined;
                                          facility?:
                                            | {
                                                __typename?: 'MiniCEXFacilityType';
                                                name?: string | null | undefined;
                                                city?: string | null | undefined;
                                                state?: string | null | undefined;
                                                country?:
                                                  | {
                                                      __typename?: 'MiniCEXCountryType';
                                                      name?: string | null | undefined;
                                                    }
                                                  | null
                                                  | undefined;
                                              }
                                            | null
                                            | undefined;
                                          patient?:
                                            | {
                                                __typename?: 'MiniCEXPatientType';
                                                age?: number | null | undefined;
                                                ageYear?: number | null | undefined;
                                                ageMonth?: string | null | undefined;
                                                gender?: string | null | undefined;
                                              }
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    evaluation?:
                                      | {
                                          __typename?: 'MiniCEXEvaluationType';
                                          additionalInfoText?: string | null | undefined;
                                          categories?:
                                            | Array<
                                                | {
                                                    __typename?: 'MiniCEXCategoryType';
                                                    name?: string | null | undefined;
                                                    score?: number | null | undefined;
                                                    reasonText?: string | null | undefined;
                                                    isAdditionalInfoRequired?:
                                                      | boolean
                                                      | null
                                                      | undefined;
                                                  }
                                                | null
                                                | undefined
                                              >
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              activityLog?:
                | Array<
                    | {
                        __typename?: 'ActivityLogType';
                        timestamp?: any | null | undefined;
                        logType?: string | null | undefined;
                        message?: string | null | undefined;
                        user?:
                          | {
                              __typename?: 'ActivityLogUserType';
                              role?: string | null | undefined;
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway6DocumentReviewLayoutPhysicianByMiniCexQueryVariables = Exact<{
  filter: PhysicianByMiniCexFilterCaseManagerInput;
}>;

export type CaseMgmtPathway6DocumentReviewLayoutPhysicianByMiniCexQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        physicianByMiniCEX?:
          | Array<
              | {
                  __typename?: 'PhysicianResponseType';
                  physicianId?: string | null | undefined;
                  dataVersion?: number | null | undefined;
                  email?: string | null | undefined;
                  minicex?:
                    | {
                        __typename?: 'PhysicianMinicexAccountType';
                        accountStatus?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  profile?:
                    | {
                        __typename?: 'PhysicianProfileType';
                        restOfName?: string | null | undefined;
                        lastName?: string | null | undefined;
                        dateOfBirth?: any | null | undefined;
                        licenses?:
                          | Array<
                              | {
                                  __typename?: 'PhysicianLicenseRecordType';
                                  physicianRegAuthorityRef?: number | null | undefined;
                                  orgName?: string | null | undefined;
                                  countryName?: string | null | undefined;
                                  licenseNumber?: string | null | undefined;
                                  addedAt?: any | null | undefined;
                                  removedAt?: any | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway6DocumentReviewLayoutAddAndDeleteDocumentMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  input: Array<AdditionalDocumentInput> | AdditionalDocumentInput;
}>;

export type CaseMgmtPathway6DocumentReviewLayoutAddAndDeleteDocumentMutation = {
  __typename?: 'Mutation';
  CasePortal_additionalDocuments?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        additionalDocuments?:
          | Array<
              | {
                  __typename?: 'AdditionalDocument';
                  notes?: string | null | undefined;
                  document?:
                    | {
                        __typename?: 'Document';
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                        docId?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway6MiniCexActiveTableRejectMutationVariables = Exact<{
  encounterId: Scalars['String'];
  minicexDataVersion: Scalars['Int'];
}>;

export type CaseMgmtPathway6MiniCexActiveTableRejectMutation = {
  __typename?: 'Mutation';
  CasePortal_MiniCEX_reject?:
    | {
        __typename?: 'MiniCEXResponseType';
        dataVersion?: number | null | undefined;
        encounterId?: string | null | undefined;
        summary?:
          | {
              __typename?: 'MiniCEXSummaryType';
              dataVersion?: number | null | undefined;
              encounterId?: string | null | undefined;
              physicianEmail?: string | null | undefined;
              physicianLastNameProvidedByPhysician?: string | null | undefined;
              physicianRestOfNameProvidedByPhysician?: string | null | undefined;
              usmleId?: string | null | undefined;
              applicantLastName?: string | null | undefined;
              applicantRestOfName?: string | null | undefined;
              createdAt?: any | null | undefined;
              expiresAt?: any | null | undefined;
              completedAt?: any | null | undefined;
              state?: string | null | undefined;
              allowedActions?: Array<string | null | undefined> | null | undefined;
              stateModel?:
                | Array<
                    | {
                        __typename?: 'MiniCEXStateModelType';
                        state?: string | null | undefined;
                        timestamp?: any | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway6TableLayoutMiniCexFieldsFragment = {
  __typename?: 'MiniCEXResponseType';
  dataVersion?: number | null | undefined;
  encounterId?: string | null | undefined;
  summary?:
    | {
        __typename?: 'MiniCEXSummaryType';
        dataVersion?: number | null | undefined;
        encounterId?: string | null | undefined;
        physicianEmail?: string | null | undefined;
        physicianLastNameProvidedByApplicant?: string | null | undefined;
        physicianRestOfNameProvidedByApplicant?: string | null | undefined;
        physicianLastNameProvidedByPhysician?: string | null | undefined;
        physicianRestOfNameProvidedByPhysician?: string | null | undefined;
        usmleId?: string | null | undefined;
        applicantLastName?: string | null | undefined;
        applicantRestOfName?: string | null | undefined;
        createdAt?: any | null | undefined;
        expiresAt?: any | null | undefined;
        acceptedAt?: any | null | undefined;
        completedAt?: any | null | undefined;
        state?: string | null | undefined;
        allowedActions?: Array<string | null | undefined> | null | undefined;
        isActive?: boolean | null | undefined;
        rejectionReason?: string | null | undefined;
        rejectionReasonComments?: string | null | undefined;
        stateModel?:
          | Array<
              | {
                  __typename?: 'MiniCEXStateModelType';
                  state?: string | null | undefined;
                  timestamp?: any | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  uiConfig?:
    | {
        __typename?: 'UIPortalConfigType';
        config?:
          | {
              __typename?: 'UIConfigRecordType';
              pages?:
                | Array<
                    | {
                        __typename?: 'UIPageConfigType';
                        pageName?: string | null | undefined;
                        components?:
                          | Array<
                              | {
                                  __typename?: 'UIComponentConfigType';
                                  componentName?: string | null | undefined;
                                  version?: number | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  form?:
    | {
        __typename?: 'MiniCEXFormType';
        attestation?:
          | {
              __typename?: 'MiniCEXAttestationType';
              hasApplicantEnrolledInClinicalRotation?: string | null | undefined;
            }
          | null
          | undefined;
        encounter?:
          | {
              __typename?: 'MiniCEXEncounterType';
              encounterDate?: string | null | undefined;
              encounterSetting?: string | null | undefined;
              facility?:
                | {
                    __typename?: 'MiniCEXFacilityType';
                    name?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    country?:
                      | { __typename?: 'MiniCEXCountryType'; name?: string | null | undefined }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              patient?:
                | {
                    __typename?: 'MiniCEXPatientType';
                    age?: number | null | undefined;
                    ageYear?: number | null | undefined;
                    ageMonth?: string | null | undefined;
                    gender?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        evaluation?:
          | {
              __typename?: 'MiniCEXEvaluationType';
              additionalInfoText?: string | null | undefined;
              categories?:
                | Array<
                    | {
                        __typename?: 'MiniCEXCategoryType';
                        name?: string | null | undefined;
                        score?: number | null | undefined;
                        reasonText?: string | null | undefined;
                        isAdditionalInfoRequired?: boolean | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathway6TableLayoutPhysicianDetailFieldsFragment = {
  __typename?: 'PhysicianResponseType';
  physicianId?: string | null | undefined;
  dataVersion?: number | null | undefined;
  email?: string | null | undefined;
  minicex?:
    | { __typename?: 'PhysicianMinicexAccountType'; accountStatus?: string | null | undefined }
    | null
    | undefined;
  profile?:
    | {
        __typename?: 'PhysicianProfileType';
        restOfName?: string | null | undefined;
        lastName?: string | null | undefined;
        dateOfBirth?: any | null | undefined;
        licenses?:
          | Array<
              | {
                  __typename?: 'PhysicianLicenseRecordType';
                  physicianRegAuthorityRef?: number | null | undefined;
                  orgName?: string | null | undefined;
                  countryName?: string | null | undefined;
                  licenseNumber?: string | null | undefined;
                  addedAt?: any | null | undefined;
                  removedAt?: any | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathwayXDocumentReviewApplicantDetailsFieldsFragment = {
  __typename?: 'CaseResponseType';
  caseSummary?:
    | {
        __typename?: 'CaseSummaryType';
        usmleId?: string | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        currentPathway?: string | null | undefined;
        submittedAt?: any | null | undefined;
      }
    | null
    | undefined;
  applicant?:
    | {
        __typename?: 'C_ApplicantType';
        email?: string | null | undefined;
        dateOfBirth?: string | null | undefined;
        gradSchool?: string | null | undefined;
        gradYear?: string | null | undefined;
        lastDataRefreshTimestamp?: any | null | undefined;
        credStatusCode?: string | null | undefined;
        credComments?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathwayXDocumentReviewChecklistReviewMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  recordId: Scalars['ID'];
  isComplete: Scalars['Boolean'];
  checklistResponse?: InputMaybe<ExceptionRequestChecklistInput>;
  pathwayChecklistState: Scalars['String'];
  exceptionChecklistState: Scalars['String'];
}>;

export type CaseMgmtPathwayXDocumentReviewChecklistReviewMutation = {
  __typename?: 'Mutation';
  CasePortal_pathwayX_setReview?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        pathwayX?:
          | {
              __typename?: 'ExceptionRequest';
              _id?: string | null | undefined;
              data?:
                | {
                    __typename?: 'ExceptionRequestData';
                    documents?:
                      | Array<
                          | {
                              __typename?: 'Document';
                              docId?: string | null | undefined;
                              docType?: string | null | undefined;
                              title?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              review?:
                | {
                    __typename?: 'ExceptionRequestReview';
                    pathwayChecklistState?: string | null | undefined;
                    exceptionChecklistState?: string | null | undefined;
                    checklist?:
                      | {
                          __typename?: 'ExceptionRequestChecklist';
                          applicantIsNotCertified?: string | null | undefined;
                          applicantNotValidStep2CSScore?: string | null | undefined;
                          applicantHasNotSubmittedAppForCert?: string | null | undefined;
                          applicantHasPurchasedToken2021Match?: string | null | undefined;
                          applicantNotBarredByUSMLE?: string | null | undefined;
                          ecfmgRestrictionsDoNotPreventAppExamination?: string | null | undefined;
                          step1PassedWithOneOrNoFails?: string | null | undefined;
                          step2CKPassedWithOneOrNoFails?: string | null | undefined;
                          exceptionApproved?: string | null | undefined;
                          applicantEligiblePathway1?: string | null | undefined;
                          pathway1DocsVerified?: string | null | undefined;
                          applicantEligiblePathway2?: string | null | undefined;
                          pathway2DocsVerified?: string | null | undefined;
                          applicantEligiblePathway345?: string | null | undefined;
                          pathway345DocsVerified?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathwayXDocumentReviewLayoutCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtPathwayXDocumentReviewLayoutCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    dataVersion?: number | null | undefined;
                    eligibilityReviewAssigneeId?: string | null | undefined;
                    pathwayEligibilityStatus?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    currentPathway?: string | null | undefined;
                    submittedAt?: any | null | undefined;
                  }
                | null
                | undefined;
              applicant?:
                | {
                    __typename?: 'C_ApplicantType';
                    email?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    gradSchool?: string | null | undefined;
                    gradYear?: string | null | undefined;
                    lastDataRefreshTimestamp?: any | null | undefined;
                    credStatusCode?: string | null | undefined;
                    credComments?: string | null | undefined;
                  }
                | null
                | undefined;
              additionalDocuments?:
                | Array<
                    | {
                        __typename?: 'AdditionalDocument';
                        document?:
                          | {
                              __typename?: 'Document';
                              docType?: string | null | undefined;
                              docId?: string | null | undefined;
                              title?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              pathwayX?:
                | {
                    __typename?: 'ExceptionRequest';
                    _id?: string | null | undefined;
                    data?:
                      | {
                          __typename?: 'ExceptionRequestData';
                          documents?:
                            | Array<
                                | {
                                    __typename?: 'Document';
                                    docId?: string | null | undefined;
                                    docType?: string | null | undefined;
                                    title?: string | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    review?:
                      | {
                          __typename?: 'ExceptionRequestReview';
                          pathwayChecklistState?: string | null | undefined;
                          exceptionChecklistState?: string | null | undefined;
                          status?: string | null | undefined;
                          checklist?:
                            | {
                                __typename?: 'ExceptionRequestChecklist';
                                applicantIsNotCertified?: string | null | undefined;
                                applicantNotValidStep2CSScore?: string | null | undefined;
                                applicantHasNotSubmittedAppForCert?: string | null | undefined;
                                applicantHasPurchasedToken2021Match?: string | null | undefined;
                                applicantNotBarredByUSMLE?: string | null | undefined;
                                ecfmgRestrictionsDoNotPreventAppExamination?:
                                  | string
                                  | null
                                  | undefined;
                                step1PassedWithOneOrNoFails?: string | null | undefined;
                                step2CKPassedWithOneOrNoFails?: string | null | undefined;
                                exceptionApproved?: string | null | undefined;
                                applicantEligiblePathway1?: string | null | undefined;
                                pathway1DocsVerified?: string | null | undefined;
                                applicantEligiblePathway2?: string | null | undefined;
                                pathway2DocsVerified?: string | null | undefined;
                                applicantEligiblePathway345?: string | null | undefined;
                                pathway345DocsVerified?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              activityLog?:
                | Array<
                    | {
                        __typename?: 'ActivityLogType';
                        timestamp?: any | null | undefined;
                        logType?: string | null | undefined;
                        message?: string | null | undefined;
                        user?:
                          | {
                              __typename?: 'ActivityLogUserType';
                              role?: string | null | undefined;
                              id?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              uiConfig?:
                | {
                    __typename?: 'UIPortalConfigType';
                    config?:
                      | {
                          __typename?: 'UIConfigRecordType';
                          pages?:
                            | Array<
                                | {
                                    __typename?: 'UIPageConfigType';
                                    pageName?: string | null | undefined;
                                    components?:
                                      | Array<
                                          | {
                                              __typename?: 'UIComponentConfigType';
                                              componentName?: string | null | undefined;
                                              version?: number | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPathwayXDocumentReviewLayoutAddAndDeleteDocumentMutationVariables = Exact<{
  usmleId: Scalars['String'];
  dataVersion: Scalars['Int'];
  input: Array<AdditionalDocumentInput> | AdditionalDocumentInput;
}>;

export type CaseMgmtPathwayXDocumentReviewLayoutAddAndDeleteDocumentMutation = {
  __typename?: 'Mutation';
  CasePortal_additionalDocuments?:
    | {
        __typename?: 'CaseResponseType';
        caseSummary?:
          | { __typename?: 'CaseSummaryType'; dataVersion?: number | null | undefined }
          | null
          | undefined;
        additionalDocuments?:
          | Array<
              | {
                  __typename?: 'AdditionalDocument';
                  notes?: string | null | undefined;
                  document?:
                    | {
                        __typename?: 'Document';
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                        docId?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtUploadDocumentUploadDocumentMutationVariables = Exact<{
  fileName: Scalars['String'];
}>;

export type CaseMgmtUploadDocumentUploadDocumentMutation = {
  __typename?: 'Mutation';
  uploadDocument?:
    | {
        __typename?: 'SASToken';
        token?: { __typename?: 'Token'; token: string; uri: string } | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtRedirectOetCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtRedirectOetCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              _id?: string | null | undefined;
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    usmleId?: string | null | undefined;
                    currentPathway?: string | null | undefined;
                    submittedPathway?: string | null | undefined;
                  }
                | null
                | undefined;
              languageAssessment?:
                | {
                    __typename?: 'LanguageAssessment';
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: 'LanguageAssessmentType';
                              _id?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | {
                              __typename?: 'LanguageAssessmentType';
                              _id?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtRedirectPathwayCasePortalQueryVariables = Exact<{
  usmleId?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtRedirectPathwayCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        case?:
          | {
              __typename?: 'CaseResponseType';
              caseSummary?:
                | {
                    __typename?: 'CaseSummaryType';
                    _id?: string | null | undefined;
                    usmleId?: string | null | undefined;
                    currentPathway?: string | null | undefined;
                    submittedPathway?: string | null | undefined;
                    isPathwayChanged?: boolean | null | undefined;
                  }
                | null
                | undefined;
              pathway1?:
                | {
                    __typename?: 'Pathway1';
                    applicantProvidedData?:
                      | Array<
                          | { __typename?: 'License'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | { __typename?: 'License'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathway2?:
                | {
                    __typename?: 'Pathway2';
                    applicantProvidedData?:
                      | Array<
                          | { __typename?: 'CSExam'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | { __typename?: 'CSExam'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathway345?:
                | {
                    __typename?: 'Pathway345';
                    applicantProvidedData?:
                      | Array<
                          | { __typename?: 'Education'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | { __typename?: 'Education'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathway6?:
                | {
                    __typename?: 'Pathway6';
                    applicantProvidedData?:
                      | Array<
                          | { __typename?: 'Pathway6MiniCEXType'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    staffProvidedData?:
                      | Array<
                          | { __typename?: 'Pathway6MiniCEXType'; _id?: string | null | undefined }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              pathwayX?:
                | { __typename?: 'ExceptionRequest'; _id?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtFinanceSettingsLayoutCasePortalQueryVariables = Exact<{ [key: string]: never }>;

export type CaseMgmtFinanceSettingsLayoutCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        glDistributionConfig?:
          | Array<
              | {
                  __typename?: 'GLDistributionConfig';
                  _id?: string | null | undefined;
                  configName?: string | null | undefined;
                  applicationType?: string | null | undefined;
                  dateFrom?: string | null | undefined;
                  version?: number | null | undefined;
                  glAccountCashControl?: string | null | undefined;
                  glAccountUnearned?: string | null | undefined;
                  glAccountEarned?: string | null | undefined;
                  glAccountRefundControl?: string | null | undefined;
                  standardCaseFee?: number | null | undefined;
                  standardCaseInitialIntake?: number | null | undefined;
                  exceptionCaseFee?: number | null | undefined;
                  exceptionCaseInitialIntake?: number | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtFinanceSettingsLayoutGlDistributionConfig_CreateMutationVariables = Exact<{
  configName: Scalars['String'];
  applicationType: Scalars['String'];
  dateFrom: Scalars['DateString'];
  glAccountCashControl: Scalars['String'];
  glAccountUnearned: Scalars['String'];
  glAccountEarned: Scalars['String'];
  glAccountRefundControl: Scalars['String'];
  standardCaseFee: Scalars['Int'];
  standardCaseInitialIntake: Scalars['Int'];
  exceptionCaseFee: Scalars['Int'];
  exceptionCaseInitialIntake: Scalars['Int'];
}>;

export type CaseMgmtFinanceSettingsLayoutGlDistributionConfig_CreateMutation = {
  __typename?: 'Mutation';
  GLDistributionConfig_create?:
    | {
        __typename?: 'GLDistributionConfig';
        _id?: string | null | undefined;
        configName?: string | null | undefined;
        applicationType?: string | null | undefined;
        dateFrom?: string | null | undefined;
        glAccountCashControl?: string | null | undefined;
        glAccountUnearned?: string | null | undefined;
        glAccountEarned?: string | null | undefined;
        glAccountRefundControl?: string | null | undefined;
        standardCaseFee?: number | null | undefined;
        standardCaseInitialIntake?: number | null | undefined;
        exceptionCaseFee?: number | null | undefined;
        exceptionCaseInitialIntake?: number | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtFinanceSettingsLayoutGlDistributionConfig_EditMutationVariables = Exact<{
  _id: Scalars['ID'];
  version: Scalars['Int'];
  input: GlDistributionConfigEditInput;
}>;

export type CaseMgmtFinanceSettingsLayoutGlDistributionConfig_EditMutation = {
  __typename?: 'Mutation';
  GLDistributionConfig_edit?:
    | {
        __typename?: 'GLDistributionConfig';
        _id?: string | null | undefined;
        configName?: string | null | undefined;
        applicationType?: string | null | undefined;
        dateFrom?: string | null | undefined;
        version?: number | null | undefined;
        glAccountCashControl?: string | null | undefined;
        glAccountUnearned?: string | null | undefined;
        glAccountEarned?: string | null | undefined;
        glAccountRefundControl?: string | null | undefined;
        standardCaseFee?: number | null | undefined;
        standardCaseInitialIntake?: number | null | undefined;
        exceptionCaseFee?: number | null | undefined;
        exceptionCaseInitialIntake?: number | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtFinanceSettingsLayoutGlDistributionConfig_DeactivateMutationVariables = Exact<{
  _id: Scalars['ID'];
  version: Scalars['Int'];
}>;

export type CaseMgmtFinanceSettingsLayoutGlDistributionConfig_DeactivateMutation = {
  __typename?: 'Mutation';
  GLDistributionConfig_deactivate?:
    | {
        __typename?: 'ResponseStatusType';
        code?: string | null | undefined;
        msg?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtTransactionHistoryDetailsCasePortalQueryVariables = Exact<{
  transactionDateFrom?: InputMaybe<Scalars['String']>;
  transactionDateTo?: InputMaybe<Scalars['String']>;
}>;

export type CaseMgmtTransactionHistoryDetailsCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        glDistributionReport?:
          | Array<
              | {
                  __typename?: 'glDistributionReportResponseType';
                  usmleId?: string | null | undefined;
                  trsAccountId?: string | null | undefined;
                  transactionType?: string | null | undefined;
                  transactionTimestamp?: any | null | undefined;
                  amount?: number | null | undefined;
                  paymentNetworkReferenceId?: string | null | undefined;
                  glDebitAccountId?: string | null | undefined;
                  glCreditAccountId?: string | null | undefined;
                  applicationType?: string | null | undefined;
                  feeType?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtMaintenanceServerTimeQueryVariables = Exact<{ [key: string]: never }>;

export type CaseMgmtMaintenanceServerTimeQuery = { __typename?: 'Query'; serverDate: any };

export type MedicalSchoolOfficialsSyncAccountFromB2CMutationVariables = Exact<{
  userName?: InputMaybe<Scalars['String']>;
}>;

export type MedicalSchoolOfficialsSyncAccountFromB2CMutation = {
  __typename?: 'Mutation';
  CasePortal_AuthorityMgmt_syncAccountFromB2C?:
    | {
        __typename?: 'ResponseStatusType';
        code?: string | null | undefined;
        msg?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPhysicianActiveTableCasePortal_MiniCex_RejectMutationVariables = Exact<{
  encounterId: Scalars['String'];
  minicexDataVersion: Scalars['Int'];
}>;

export type CaseMgmtPhysicianActiveTableCasePortal_MiniCex_RejectMutation = {
  __typename?: 'Mutation';
  CasePortal_MiniCEX_reject?:
    | {
        __typename?: 'MiniCEXResponseType';
        dataVersion?: number | null | undefined;
        encounterId?: string | null | undefined;
        summary?:
          | {
              __typename?: 'MiniCEXSummaryType';
              dataVersion?: number | null | undefined;
              encounterId?: string | null | undefined;
              physicianLastNameProvidedByPhysician?: string | null | undefined;
              physicianRestOfNameProvidedByPhysician?: string | null | undefined;
              usmleId?: string | null | undefined;
              applicantLastName?: string | null | undefined;
              applicantRestOfName?: string | null | undefined;
              createdAt?: any | null | undefined;
              expiresAt?: any | null | undefined;
              completedAt?: any | null | undefined;
              state?: string | null | undefined;
              allowedActions?: Array<string | null | undefined> | null | undefined;
              stateModel?:
                | Array<
                    | {
                        __typename?: 'MiniCEXStateModelType';
                        state?: string | null | undefined;
                        timestamp?: any | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPhysicianDetailCasePortalQueryVariables = Exact<{
  email: Scalars['String'];
  responseType: Scalars['String'];
}>;

export type CaseMgmtPhysicianDetailCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        physician?:
          | {
              __typename?: 'PhysicianResponseType';
              oid?: string | null | undefined;
              allowedActions?: Array<string | null | undefined> | null | undefined;
              email?: string | null | undefined;
              physicianId?: string | null | undefined;
              dataVersion?: number | null | undefined;
              applicantsEvaluatedByPhysician?:
                | Array<
                    | { __typename?: 'A_Applicant'; usmleId?: string | null | undefined }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              physicianConfig?:
                | {
                    __typename?: 'PhysicianConfigType';
                    config?:
                      | {
                          __typename?: 'PhysicianConfigRecordType';
                          minicex?:
                            | {
                                __typename?: 'PhysicianMiniCEXConfigType';
                                maxApplicantsAllowedToBeEvaluatedByPhysician?:
                                  | number
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              minicex?:
                | {
                    __typename?: 'PhysicianMinicexAccountType';
                    accountStatus?: string | null | undefined;
                    profileStatus?: string | null | undefined;
                  }
                | null
                | undefined;
              profile?:
                | {
                    __typename?: 'PhysicianProfileType';
                    lastName?: string | null | undefined;
                    restOfName?: string | null | undefined;
                    dateOfBirth?: any | null | undefined;
                    isHoldingFacultyAppointment?: string | null | undefined;
                    medicalSpecialty?: string | null | undefined;
                    secondaryEmails?: Array<string | null | undefined> | null | undefined;
                    usmleId?: string | null | undefined;
                    licenses?:
                      | Array<
                          | {
                              __typename?: 'PhysicianLicenseRecordType';
                              licenseNumber?: string | null | undefined;
                              orgName?: string | null | undefined;
                              physicianRegAuthorityRef?: number | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              activityLog?:
                | Array<
                    | {
                        __typename?: 'ActivityLogType';
                        timestamp?: any | null | undefined;
                        logType?: string | null | undefined;
                        message?: string | null | undefined;
                        user?:
                          | {
                              __typename?: 'ActivityLogUserType';
                              role?: string | null | undefined;
                              name?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        minicexByPhysician?:
          | Array<
              | {
                  __typename?: 'MiniCEXResponseType';
                  dataVersion?: number | null | undefined;
                  encounterId?: string | null | undefined;
                  summary?:
                    | {
                        __typename?: 'MiniCEXSummaryType';
                        dataVersion?: number | null | undefined;
                        allowedActions?: Array<string | null | undefined> | null | undefined;
                        physicianEmail?: string | null | undefined;
                        physicianLastNameProvidedByApplicant?: string | null | undefined;
                        physicianRestOfNameProvidedByApplicant?: string | null | undefined;
                        physicianLastNameProvidedByPhysician?: string | null | undefined;
                        physicianRestOfNameProvidedByPhysician?: string | null | undefined;
                        applicantLastName?: string | null | undefined;
                        applicantRestOfName?: string | null | undefined;
                        usmleId?: string | null | undefined;
                        encounterId?: string | null | undefined;
                        state?: string | null | undefined;
                        acceptedAt?: any | null | undefined;
                        createdAt?: any | null | undefined;
                        completedAt?: any | null | undefined;
                        expiresAt?: any | null | undefined;
                        isActive?: boolean | null | undefined;
                        rejectionReason?: string | null | undefined;
                        rejectionReasonComments?: string | null | undefined;
                        stateModel?:
                          | Array<
                              | {
                                  __typename?: 'MiniCEXStateModelType';
                                  state?: string | null | undefined;
                                  timestamp?: any | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  uiConfig?:
                    | {
                        __typename?: 'UIPortalConfigType';
                        config?:
                          | {
                              __typename?: 'UIConfigRecordType';
                              pages?:
                                | Array<
                                    | {
                                        __typename?: 'UIPageConfigType';
                                        pageName?: string | null | undefined;
                                        components?:
                                          | Array<
                                              | {
                                                  __typename?: 'UIComponentConfigType';
                                                  componentName?: string | null | undefined;
                                                  version?: number | null | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  form?:
                    | {
                        __typename?: 'MiniCEXFormType';
                        encounter?:
                          | {
                              __typename?: 'MiniCEXEncounterType';
                              encounterDate?: string | null | undefined;
                              encounterSetting?: string | null | undefined;
                              facility?:
                                | {
                                    __typename?: 'MiniCEXFacilityType';
                                    name?: string | null | undefined;
                                    city?: string | null | undefined;
                                    state?: string | null | undefined;
                                    country?:
                                      | {
                                          __typename?: 'MiniCEXCountryType';
                                          name?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              patient?:
                                | {
                                    __typename?: 'MiniCEXPatientType';
                                    age?: number | null | undefined;
                                    ageYear?: number | null | undefined;
                                    ageMonth?: string | null | undefined;
                                    gender?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        evaluation?:
                          | {
                              __typename?: 'MiniCEXEvaluationType';
                              additionalInfoText?: string | null | undefined;
                              categories?:
                                | Array<
                                    | {
                                        __typename?: 'MiniCEXCategoryType';
                                        name?: string | null | undefined;
                                        score?: number | null | undefined;
                                        reasonText?: string | null | undefined;
                                        isAdditionalInfoRequired?: boolean | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptOutMutationVariables = Exact<{
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
}>;

export type CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptOutMutation = {
  __typename?: 'Mutation';
  CasePortal_PhyMgmt_optOut?:
    | { __typename?: 'PhysicianResponseType'; dataVersion?: number | null | undefined }
    | null
    | undefined;
};

export type CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptInMutationVariables = Exact<{
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
}>;

export type CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptInMutation = {
  __typename?: 'Mutation';
  CasePortal_PhyMgmt_optIn?:
    | { __typename?: 'PhysicianResponseType'; dataVersion?: number | null | undefined }
    | null
    | undefined;
};

export type CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditNameMutationVariables = Exact<{
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
  input?: InputMaybe<PhysicianNameInput>;
}>;

export type CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditNameMutation = {
  __typename?: 'Mutation';
  CasePortal_PhyMgmt_editName?:
    | {
        __typename?: 'PhysicianResponseType';
        dataVersion?: number | null | undefined;
        profile?:
          | {
              __typename?: 'PhysicianProfileType';
              lastName?: string | null | undefined;
              restOfName?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditDoBMutationVariables = Exact<{
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
  input: PhysicianDoBInput;
}>;

export type CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditDoBMutation = {
  __typename?: 'Mutation';
  CasePortal_PhyMgmt_editDoB?:
    | {
        __typename?: 'PhysicianResponseType';
        dataVersion?: number | null | undefined;
        profile?:
          | { __typename?: 'PhysicianProfileType'; dateOfBirth?: any | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CasePortal_PhyMgmt_BlockMutationVariables = Exact<{
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
}>;

export type CasePortal_PhyMgmt_BlockMutation = {
  __typename?: 'Mutation';
  CasePortal_PhyMgmt_block?:
    | {
        __typename?: 'PhysicianResponseType';
        dataVersion?: number | null | undefined;
        minicex?:
          | {
              __typename?: 'PhysicianMinicexAccountType';
              tags?:
                | {
                    __typename?: 'PhysicianTagsType';
                    actionTags?: Array<string | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TagsQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type TagsQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        physician?:
          | {
              __typename?: 'PhysicianResponseType';
              minicex?:
                | {
                    __typename?: 'PhysicianMinicexAccountType';
                    tags?:
                      | {
                          __typename?: 'PhysicianTagsType';
                          actionTags?: Array<string | null | undefined> | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CasePortal_PhyMgmt_UnblockMutationVariables = Exact<{
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
}>;

export type CasePortal_PhyMgmt_UnblockMutation = {
  __typename?: 'Mutation';
  CasePortal_PhyMgmt_unblock?:
    | {
        __typename?: 'PhysicianResponseType';
        dataVersion?: number | null | undefined;
        minicex?:
          | {
              __typename?: 'PhysicianMinicexAccountType';
              tags?:
                | {
                    __typename?: 'PhysicianTagsType';
                    actionTags?: Array<string | null | undefined> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PhysicianDetailCasePortalPhyMgmtSnyncRegistrationMutationVariables = Exact<{
  dataVersion: Scalars['Int'];
  email: Scalars['String'];
}>;

export type PhysicianDetailCasePortalPhyMgmtSnyncRegistrationMutation = {
  __typename?: 'Mutation';
  CasePortal_PhyMgmt_syncRegistration?:
    | {
        __typename?: 'PhysicianResponseType';
        dataVersion?: number | null | undefined;
        minicex?:
          | {
              __typename?: 'PhysicianMinicexAccountType';
              accountStatus?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPhysicianListCasePortalQueryVariables = Exact<{ [key: string]: never }>;

export type CaseMgmtPhysicianListCasePortalQuery = {
  __typename?: 'Query';
  casePortal?:
    | {
        __typename?: 'CasePortalResponseType';
        physicians?:
          | Array<
              | {
                  __typename?: 'PhysicianResponseType';
                  dataVersion?: number | null | undefined;
                  email?: string | null | undefined;
                  allowedActions?: Array<string | null | undefined> | null | undefined;
                  profile?:
                    | {
                        __typename?: 'PhysicianProfileType';
                        lastName?: string | null | undefined;
                        restOfName?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  minicex?:
                    | {
                        __typename?: 'PhysicianMinicexAccountType';
                        tags?:
                          | {
                              __typename?: 'PhysicianTagsType';
                              actionTags?: Array<string | null | undefined> | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CaseMgmtPhysicianTablePhysiciansFieldsFragment = {
  __typename?: 'CasePortalResponseType';
  physicians?:
    | Array<
        | {
            __typename?: 'PhysicianResponseType';
            dataVersion?: number | null | undefined;
            email?: string | null | undefined;
            allowedActions?: Array<string | null | undefined> | null | undefined;
            profile?:
              | {
                  __typename?: 'PhysicianProfileType';
                  lastName?: string | null | undefined;
                  restOfName?: string | null | undefined;
                }
              | null
              | undefined;
            minicex?:
              | {
                  __typename?: 'PhysicianMinicexAccountType';
                  tags?:
                    | {
                        __typename?: 'PhysicianTagsType';
                        actionTags?: Array<string | null | undefined> | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CaseMgmtRejectionReasonsFactoryUiConfigFieldsFragment = {
  __typename?: 'CaseResponseType';
  uiConfig?:
    | {
        __typename?: 'UIPortalConfigType';
        config?:
          | {
              __typename?: 'UIConfigRecordType';
              pages?:
                | Array<
                    | {
                        __typename?: 'UIPageConfigType';
                        pageName?: string | null | undefined;
                        components?:
                          | Array<
                              | {
                                  __typename?: 'UIComponentConfigType';
                                  componentName?: string | null | undefined;
                                  version?: number | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const CaseMgmtActivityFeedCasePortalFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtActivityFeedCasePortalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLog' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'logType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtActivityFeedCasePortalFieldsFragment, unknown>;
export const CaseMgmtCaseListFilterModalFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtCaseListFilterModalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dashboard' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'views' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'viewName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewFilters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'caseState' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pathwayEligibilityStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'englishEligibilityStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'generalEligibilityStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pathway' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'school' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'regulatoryAuthority' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayColumns' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtCaseListFilterModalFieldsFragment, unknown>;
export const CaseMgmtCaseListTableCasePortalCasesFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtCaseListTableCasePortalCasesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseSummaryType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usmleId' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleIDColumn' } },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedAt' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'daysSinceLastUpdateColumn' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedAt' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'submissionDateColumn' },
                    },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExceptionCase' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseState' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'caseStatusColumn' } },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'output' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outputReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibilityReviewAssigneeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eligibilityReviewAssigneeName' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'assignedToColumn' } },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'qaReviewAssigneeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qaReviewAssigneeName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPathway' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'pathwayColumn' } },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedPathway' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qaReworkTimestamp' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'qaReworkColumn' } },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pathwayEligibilityStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pathwayEligibilityOutput' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'pathwayCaseStatusColumn' },
                    },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'englishEligibilityStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'englishEligibilityOutput' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'oetCaseStatusColumn' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generalEligibilityStatus' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'generalEligibilityColumn' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastDocUploadTimestamp' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'lastDocUploadColumn' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtCaseListTableCasePortalCasesFieldsFragment, unknown>;
export const CaseMgmtCaseListTableCasePortalPaginationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtCaseListTableCasePortalPaginationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseListPaginationType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pageNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortField' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtCaseListTableCasePortalPaginationFieldsFragment, unknown>;
export const CaseMgmtCaseOverviewActionsCasePortalFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewActionsCasePortalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eligibilityReviewAssigneeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qaReviewAssigneeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExceptionCase' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caseState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pathwayEligibilityStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'englishEligibilityStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtCaseOverviewActionsCasePortalFieldsFragment, unknown>;
export const CaseMgmtRejectionReasonsFactoryUiConfigFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtRejectionReasonsFactoryUIConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uiConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'components' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'componentName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtRejectionReasonsFactoryUiConfigFieldsFragment, unknown>;
export const CaseMgmtCaseOverviewApplicantInformationCasePortalFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewApplicantInformationCasePortalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CaseMgmtRejectionReasonsFactoryUIConfigFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradSchool' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'examHistory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'examName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'restrictions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'releaseDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'user' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastDataRefreshTimestamp' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pastAttempts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pathway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pathwaySeason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refundIssued' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtRejectionReasonsFactoryUiConfigFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewApplicantInformationCasePortalFieldsFragment,
  unknown
>;
export const CaseMgmtCaseOverviewSummaryCasePortalFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewSummaryCasePortalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEligible' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExceptionCase' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caseState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eligibilityReviewAssigneeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eligibilityReviewAssigneeName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qaReviewAssigneeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qaReviewAssigneeName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedPathway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qaReworkTimestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pathwayEligibilityStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'englishEligibilityStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'generalEligibilityOutput' } },
                { kind: 'Field', name: { kind: 'Name', value: 'generalEligibilityStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'generalEligibilityOutputReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gradSchool' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtCaseOverviewSummaryCasePortalFieldsFragment, unknown>;
export const CaseMgmtApplicantSummaryFactoryConfigFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtApplicantSummaryFactoryConfigFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uiConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'components' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'componentName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtApplicantSummaryFactoryConfigFieldsFragment, unknown>;
export const CaseMgmtQualificationReviewApplicantInfoFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtQualificationReviewApplicantInfoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'graduation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isYearEligible' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schoolEligibility' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'schoolPathway' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isStep2CSFailed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtQualificationReviewApplicantInfoFieldsFragment, unknown>;
export const CaseMgmtQualificationReviewCsExamFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtQualificationReviewCSExamFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pathway2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPassedCSExamInRecentPast' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPassedFromQualifiedSchoolInRecentPast' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicantProvidedData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'oscExamRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examCode' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'examShortTitle' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examFullName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'schoolRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'staffProvidedData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'oscExamRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examCode' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'examShortTitle' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examFullName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'schoolRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtQualificationReviewCsExamFieldsFragment, unknown>;
export const CaseMgmtQualificationReviewCsExamNotRecentPastFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtQualificationReviewCSExamNotRecentPastFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pathway2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPassedCSExamInRecentPast' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPassedFromQualifiedSchoolInRecentPast' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicantProvidedData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'oscExamRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examCode' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'examShortTitle' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examFullName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'schoolRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'staffProvidedData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'oscExamRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examCode' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'examShortTitle' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examFullName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'schoolRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtQualificationReviewCsExamNotRecentPastFieldsFragment, unknown>;
export const CaseMgmtQualificationReviewEducationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtQualificationReviewEducationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pathway345' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isGradSchoolEligible' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isGradDateInAcceptableRange' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicantProvidedData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gradDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'schoolRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'staffProvidedData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gradDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'schoolRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtQualificationReviewEducationFieldsFragment, unknown>;
export const CaseMgmtQualificationReviewExceptionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtQualificationReviewExceptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pathwayX' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'requestText' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtQualificationReviewExceptionFieldsFragment, unknown>;
export const CaseMgmtQualificationReviewLanguageAssessmentFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtQualificationReviewLanguageAssessmentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'languageAssessment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasPassedExam' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicantProvidedData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'candidateId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtQualificationReviewLanguageAssessmentFieldsFragment, unknown>;
export const CaseMgmtQualificationReviewLicenseFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtQualificationReviewLicenseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pathway1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isCurrentlyLicensed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wasLicensedInRecentPast' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicantProvidedData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'licensureDocumentStatus' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'licenseIssueDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'licenseExpiryDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isCurrentlyLicensed' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasDisciplinaryAction' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'regulatoryAuthorityRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'staffProvidedData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'licensureDocumentStatus' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'licenseIssueDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'licenseExpiryDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isCurrentlyLicensed' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasDisciplinaryAction' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'regulatoryAuthorityRef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtQualificationReviewLicenseFieldsFragment, unknown>;
export const CaseMgmtOetDocumentReviewApplicantDetailsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtOETDocumentReviewApplicantDetailsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtOetDocumentReviewApplicantDetailsFieldsFragment, unknown>;
export const CaseMgmtPathway1ReviewApplicantDetailsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtPathway1ReviewApplicantDetailsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credStatusCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credComments' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtPathway1ReviewApplicantDetailsFieldsFragment, unknown>;
export const CaseMgmtPathway2DocumentReviewApplicantDetailsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtPathway2DocumentReviewApplicantDetailsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradSchool' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastDataRefreshTimestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credStatusCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credComments' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtPathway2DocumentReviewApplicantDetailsFieldsFragment, unknown>;
export const CaseMgmtPathway3DocumentReviewApplicantDetailsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtPathway3DocumentReviewApplicantDetailsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradSchool' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastDataRefreshTimestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credStatusCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credComments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schoolEligibility' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isSchoolEMSWPMember' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtPathway3DocumentReviewApplicantDetailsFieldsFragment, unknown>;
export const CaseMgmtPathway6DocumentReviewApplicantDetailsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtPathway6DocumentReviewApplicantDetailsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradSchool' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastDataRefreshTimestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credStatusCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credComments' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtPathway6DocumentReviewApplicantDetailsFieldsFragment, unknown>;
export const CaseMgmtPathway6TableLayoutMiniCexFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtPathway6TableLayoutMiniCEXFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MiniCEXResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'encounterId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'encounterId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicianEmail' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'physicianLastNameProvidedByApplicant' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'physicianRestOfNameProvidedByApplicant' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'physicianLastNameProvidedByPhysician' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'physicianRestOfNameProvidedByPhysician' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicantLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicantRestOfName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stateModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'allowedActions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectionReasonComments' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uiConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'components' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'componentName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'form' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attestation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasApplicantEnrolledInClinicalRotation' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'encounter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'encounterDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'encounterSetting' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'facility' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patient' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ageYear' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ageMonth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'evaluation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reasonText' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isAdditionalInfoRequired' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInfoText' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtPathway6TableLayoutMiniCexFieldsFragment, unknown>;
export const CaseMgmtPathway6TableLayoutPhysicianDetailFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtPathway6TableLayoutPhysicianDetailFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhysicianResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'physicianId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minicex' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accountStatus' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'restOfName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'licenses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'physicianRegAuthorityRef' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'licenseNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtPathway6TableLayoutPhysicianDetailFieldsFragment, unknown>;
export const CaseMgmtPathwayXDocumentReviewApplicantDetailsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtPathwayXDocumentReviewApplicantDetailsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradSchool' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastDataRefreshTimestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credStatusCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credComments' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtPathwayXDocumentReviewApplicantDetailsFieldsFragment, unknown>;
export const CaseMgmtPhysicianTablePhysiciansFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseMgmtPhysicianTablePhysiciansFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CasePortalResponseType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'physicians' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'restOfName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minicex' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actionTags' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'allowedActions' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseMgmtPhysicianTablePhysiciansFieldsFragment, unknown>;
export const CaseMgmtViewPdFdownloadDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtViewPDFdownloadDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'token' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtViewPdFdownloadDocumentQuery,
  CaseMgmtViewPdFdownloadDocumentQueryVariables
>;
export const ArchivedCaseListContainerArchivedCasesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArchivedCaseListContainerArchivedCases' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArchivedCaseFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCases' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pathwaySeason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pathwayNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'decisionOutput' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reviewCompletedOn' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArchivedCaseListContainerArchivedCasesQuery,
  ArchivedCaseListContainerArchivedCasesQueryVariables
>;
export const PathwayXArchivedCaseReviewContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PathwayXArchivedCaseReviewContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documentCompleteLegible' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'examDateValidation' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'nameValid' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreAcceptable' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreConfirmed' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'checklistState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'candidateId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uploadedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathwayX' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantEligiblePathway1' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantEligiblePathway2' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantEligiblePathway345' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'applicantHasNotSubmittedAppForCert',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'applicantHasPurchasedToken2021Match',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantIsNotCertified' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantNotBarredByUSMLE' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantNotValidStep2CSScore' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ecfmgRestrictionsDoNotPreventAppExamination',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'exceptionApproved' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pathway1DocsVerified' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pathway2DocsVerified' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pathway345DocsVerified' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'step1PassedWithOneOrNoFails' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'step2CKPassedWithOneOrNoFails' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uploadedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'requestText' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'exceptionChecklistState' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pathwayChecklistState' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uiConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'config' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'components' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'componentName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PathwayXArchivedCaseReviewContainerArchivedCaseQuery,
  PathwayXArchivedCaseReviewContainerArchivedCaseQueryVariables
>;
export const Pathway1ArchivedCaseReviewContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Pathway1ArchivedCaseReviewContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documentCompleteLegible' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'examDateValidation' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'nameValid' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreAcceptable' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreConfirmed' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'checklistState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'candidateId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uploadedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway1' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'licensureApplicantLicenseOnPoint',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureCompleteLegible' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureDisciplinaryAction' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'licensureDocumentIssueDateAcceptable',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'licensureIssuedAppropriateAuthority',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureNameValidation' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureTranslationStatus' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureUnrestrictedLicense' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'verificationActiveLicense' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'verificationAuthorized' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'verificationGoodStanding' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'verificationPrimarySource' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'checklistState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uploadedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasDisciplinaryAction' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isCurrentlyLicensed' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licenseExpiryDate' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licenseIssueDate' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureDocumentStatus' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'regulatoryAuthorityRef' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'countryName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uiConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'config' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'components' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'componentName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Pathway1ArchivedCaseReviewContainerArchivedCaseQuery,
  Pathway1ArchivedCaseReviewContainerArchivedCaseQueryVariables
>;
export const Pathway2ArchivedCaseReviewContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Pathway2ArchivedCaseReviewContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documentCompleteLegible' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'examDateValidation' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'nameValid' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreAcceptable' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreConfirmed' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'checklistState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'candidateId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uploadedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway2' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'osceExaminationAcceptable' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'osceExaminationPassed' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'osceExaminationAfterJuly1' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'osceNameOK' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'verificationCompleted' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'verificationReturned' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'verificationPassedExamConfirmed',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'verificationAcceptableDateConfirmed',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'osceDocumentCompleteLegible' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'osceTranslation' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'checklistState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'oscExamRef' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'examCode' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'examShortTitle' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'examFullName' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'schoolRef' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'countryName' },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uploadedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uiConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'config' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'components' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'componentName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Pathway2ArchivedCaseReviewContainerArchivedCaseQuery,
  Pathway2ArchivedCaseReviewContainerArchivedCaseQueryVariables
>;
export const Pathway345ArchivedCaseAssessmentsEmswpContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Pathway345ArchivedCaseAssessmentsEMSWPContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emswp' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'outputReason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedTimestamp' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'outputReasonText' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway345' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'schoolRef' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'countryName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Pathway345ArchivedCaseAssessmentsEmswpContainerArchivedCaseQuery,
  Pathway345ArchivedCaseAssessmentsEmswpContainerArchivedCaseQueryVariables
>;
export const Pathway345ArchivedCaseReviewContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Pathway345ArchivedCaseReviewContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documentCompleteLegible' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'examDateValidation' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'nameValid' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreAcceptable' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreConfirmed' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'checklistState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'candidateId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uploadedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway345' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attestationAuthorizedOfficial' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attestationComplete' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'attestationCompletedByMedicalSchool',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attestationSealOk' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attestationSentPrimarySource' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attestationSignatureDate' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attestationSignatureMatch' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attestationTitleOk' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'eligibilityGradDateEligible' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'eligibilityMedicalSchool' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'checklistState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uploadedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'gradDate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'schoolRef' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'countryName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uiConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'config' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'components' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'componentName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Pathway345ArchivedCaseReviewContainerArchivedCaseQuery,
  Pathway345ArchivedCaseReviewContainerArchivedCaseQueryVariables
>;
export const Pathway6ArchivedCaseAssessmentsMiniCexInvalidTableContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'Pathway6ArchivedCaseAssessmentsMiniCEXInvalidTableContainerArchivedCase',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArchivedMiniCEXListFilterCaseManagerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minicex' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minicexList' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'encounterId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'summary' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'minicexId' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'dataVersion' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'pathwaySeason' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'encounterId' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'physicianEmail' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'physicianLastNameProvidedByApplicant',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'physicianRestOfNameProvidedByApplicant',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'physicianLastNameProvidedByPhysician',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'physicianRestOfNameProvidedByPhysician',
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'applicantLastName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'applicantRestOfName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'createdAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'expiresAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'acceptedAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'completedAt' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'allowedActions' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isActive' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'rejectionReason' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'rejectionReasonComments' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'form' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attestation' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'attestedAt' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'hasApplicantEnrolledInClinicalRotation',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'encounter' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'encounterDate' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'encounterSetting' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'facility' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'city' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'state' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'country' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ref' },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'patient' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'age' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'ageYear' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'ageMonth' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'gender' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'evaluation' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'categories' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'score' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'reasonText' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'isAdditionalInfoRequired',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'additionalInfoText' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uiConfig' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'config' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pages' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'pageName' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'components' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'componentName',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'version',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Pathway6ArchivedCaseAssessmentsMiniCexInvalidTableContainerArchivedCaseQuery,
  Pathway6ArchivedCaseAssessmentsMiniCexInvalidTableContainerArchivedCaseQueryVariables
>;
export const Pathway6ArchivedCaseAssessmentsMiniCexInvalidTableContainerPhysicianByMiniCexDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'Pathway6ArchivedCaseAssessmentsMiniCEXInvalidTableContainerPhysicianByMiniCEX',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PhysiciansByArchivedMinicexFilterCaseManagerInput' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'physiciansByArchivedMinicex' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'filter' },
                  value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'physicianId' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'minicex' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'accountStatus' } },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profile' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'restOfName' } },
                        { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                        { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'licenses' },
                          selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                              {
                                kind: 'Field',
                                name: { kind: 'Name', value: 'physicianRegAuthorityRef' },
                              },
                              { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
                              { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                              { kind: 'Field', name: { kind: 'Name', value: 'licenseNumber' } },
                              { kind: 'Field', name: { kind: 'Name', value: 'addedAt' } },
                              { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    Pathway6ArchivedCaseAssessmentsMiniCexInvalidTableContainerPhysicianByMiniCexQuery,
    Pathway6ArchivedCaseAssessmentsMiniCexInvalidTableContainerPhysicianByMiniCexQueryVariables
  >;
export const Pathway6ArchivedCaseAssessmentsMiniCexScoresTableContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableContainerArchivedCase',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArchivedMiniCEXListFilterCaseManagerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minicex' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minicexList' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'encounterId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'form' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'evaluation' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'categories' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'score' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assessment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'categories' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'avgScore' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Pathway6ArchivedCaseAssessmentsMiniCexScoresTableContainerArchivedCaseQuery,
  Pathway6ArchivedCaseAssessmentsMiniCexScoresTableContainerArchivedCaseQueryVariables
>;
export const Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'Pathway6ArchivedCaseAssessmentsMiniCEXValidTableContainerArchivedCase',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArchivedMiniCEXListFilterCaseManagerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minicex' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minicexList' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'encounterId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'summary' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'minicexId' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'dataVersion' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'pathwaySeason' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'encounterId' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'physicianEmail' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'physicianLastNameProvidedByApplicant',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'physicianRestOfNameProvidedByApplicant',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'physicianLastNameProvidedByPhysician',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'physicianRestOfNameProvidedByPhysician',
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'applicantLastName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'applicantRestOfName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'createdAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'expiresAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'acceptedAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'completedAt' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'allowedActions' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isActive' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'rejectionReason' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'rejectionReasonComments' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'form' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attestation' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'attestedAt' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'hasApplicantEnrolledInClinicalRotation',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'encounter' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'encounterDate' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'encounterSetting' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'facility' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'city' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'state' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'country' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'ref' },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'patient' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'age' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'ageYear' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'ageMonth' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'gender' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'evaluation' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'categories' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'score' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'reasonText' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'isAdditionalInfoRequired',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'additionalInfoText' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uiConfig' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'config' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pages' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'pageName' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'components' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'componentName',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'version',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerArchivedCaseQuery,
  Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerArchivedCaseQueryVariables
>;
export const Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerPhysicianByMiniCexDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'Pathway6ArchivedCaseAssessmentsMiniCEXValidTableContainerPhysicianByMiniCEX',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PhysiciansByArchivedMinicexFilterCaseManagerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'physiciansByArchivedMinicex' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'physicianId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minicex' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accountStatus' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'restOfName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'physicianRegAuthorityRef' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'licenseNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerPhysicianByMiniCexQuery,
  Pathway6ArchivedCaseAssessmentsMiniCexValidTableContainerPhysicianByMiniCexQueryVariables
>;
export const Pathway6ArchivedCaseReviewContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Pathway6ArchivedCaseReviewContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documentCompleteLegible' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'examDateValidation' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'nameValid' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreAcceptable' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreConfirmed' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'checklistState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'candidateId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uploadedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway6' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minicexComplete' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minicexScoreAcceptable' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'checklistState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uiConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'config' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'components' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'componentName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Pathway6ArchivedCaseReviewContainerArchivedCaseQuery,
  Pathway6ArchivedCaseReviewContainerArchivedCaseQueryVariables
>;
export const ArchivedActivityFeedContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArchivedActivityFeedContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityLog' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'logType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArchivedActivityFeedContainerArchivedCaseQuery,
  ArchivedActivityFeedContainerArchivedCaseQueryVariables
>;
export const ArchivedCaseAdditionalDocumentsContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArchivedCaseAdditionalDocumentsContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uploadedBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArchivedCaseAdditionalDocumentsContainerArchivedCaseQuery,
  ArchivedCaseAdditionalDocumentsContainerArchivedCaseQueryVariables
>;
export const ArchivedCaseApplicantContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArchivedCaseApplicantContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'examHistory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'examName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'credComments' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'credStatusCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'school' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'restrictions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'releaseDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'user' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArchivedCaseApplicantContainerArchivedCaseQuery,
  ArchivedCaseApplicantContainerArchivedCaseQueryVariables
>;
export const ArchivedCaseAssessmentsEligibilityContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArchivedCaseAssessmentsEligibilityContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eligibility' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eligibilityPreCheck' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'outputReason' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'generalEligibility' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'outputReason' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArchivedCaseAssessmentsEligibilityContainerArchivedCaseQuery,
  ArchivedCaseAssessmentsEligibilityContainerArchivedCaseQueryVariables
>;
export const ArchivedCaseAssessmentsOetContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArchivedCaseAssessmentsOETContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'examineeFirstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'examineeLastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'candidateId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'testingCenter' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'readingScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'listeningScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'writingScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'speakingScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArchivedCaseAssessmentsOetContainerArchivedCaseQuery,
  ArchivedCaseAssessmentsOetContainerArchivedCaseQueryVariables
>;
export const ArchivedCaseDetailsContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArchivedCaseDetailsContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decision' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'executedBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'outputReason' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRefunded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pathwaySeason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'submittedPathway' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uiConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'config' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'components' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'componentName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArchivedCaseDetailsContainerArchivedCaseQuery,
  ArchivedCaseDetailsContainerArchivedCaseQueryVariables
>;
export const ArchivedCaseDetailsContainerApplicantSummarySubmissionSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'ArchivedCaseDetailsContainerApplicantSummarySubmissionSummary',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_archivedCase_SubmissionSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArchivedCaseDetailsContainerApplicantSummarySubmissionSummaryMutation,
  ArchivedCaseDetailsContainerApplicantSummarySubmissionSummaryMutationVariables
>;
export const ArchivedCaseExportContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArchivedCaseExportContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uploadedBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityLog' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'logType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'examHistory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'examName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graduation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'credComments' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'credStatusCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'degree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'school' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'restrictions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'releaseDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'user' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assessments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eligibility' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eligibilityPreCheck' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'outputReason' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'generalEligibility' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'outputReason' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'candidateId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'examineeFirstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'examineeLastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'listeningScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'readingScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'speakingScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'testingCenter' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'writingScore' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decision' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'executedBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'outputReason' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRefunded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pathwaySeason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'submittedPathway' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pnRef' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refund' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pnRef' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'revenueRecognition' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'creditGlAccount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'debitGlAccount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pnref' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'revenueRecognizedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documentCompleteLegible' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'examDateValidation' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'nameValid' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreAcceptable' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scoreConfirmed' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'checklistState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'candidateId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uploadedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway1' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'licensureApplicantLicenseOnPoint',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureCompleteLegible' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureDisciplinaryAction' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'licensureDocumentIssueDateAcceptable',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'licensureIssuedAppropriateAuthority',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureNameValidation' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureTranslationStatus' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureUnrestrictedLicense' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'verificationActiveLicense' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'verificationAuthorized' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'verificationGoodStanding' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'verificationPrimarySource' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'checklistState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uploadedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasDisciplinaryAction' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isCurrentlyLicensed' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licenseExpiryDate' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licenseIssueDate' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'licensureDocumentStatus' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'regulatoryAuthorityRef' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'countryName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedTimestamp' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uiConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'config' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'components' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'componentName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'portal' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uiConfigRef' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArchivedCaseExportContainerArchivedCaseQuery,
  ArchivedCaseExportContainerArchivedCaseQueryVariables
>;
export const ArchivedCaseFinanceContainerArchivedCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArchivedCaseFinanceContainerArchivedCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pnRef' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refund' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pnRef' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'revenueRecognition' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'creditGlAccount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'debitGlAccount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pnref' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'revenueRecognizedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArchivedCaseFinanceContainerArchivedCaseQuery,
  ArchivedCaseFinanceContainerArchivedCaseQueryVariables
>;
export const CaseMgmtActivityFeedAddCaseNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtActivityFeedAddCaseNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_addCaseNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'text' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityLog' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'logType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtActivityFeedAddCaseNoteMutation,
  CaseMgmtActivityFeedAddCaseNoteMutationVariables
>;
export const CaseMgmtCaseHeaderCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtCaseHeaderCasePortal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationSubmitted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eligibilityReview' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assignedToUser' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseHeaderCasePortalQuery,
  CaseMgmtCaseHeaderCasePortalQueryVariables
>;
export const CaseMgmtCaseLeftNavCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtCaseLeftNavCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedPathway' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'caseState' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway1' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway2' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway345' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway6' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathwayX' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: '_id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageAssessment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseLeftNavCasePortalQuery,
  CaseMgmtCaseLeftNavCasePortalQueryVariables
>;
export const CaseMgmtCaseListFilterModelRegulatoryAuthorityCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtCaseListFilterModelRegulatoryAuthorityCasePortal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regulatoryAuthority' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseListFilterModelRegulatoryAuthorityCasePortalQuery,
  CaseMgmtCaseListFilterModelRegulatoryAuthorityCasePortalQueryVariables
>;
export const CaseMgmtCaseListFilterModelSchoolCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtCaseListFilterModelSchoolCasePortal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseListFilterModelSchoolCasePortalQuery,
  CaseMgmtCaseListFilterModelSchoolCasePortalQueryVariables
>;
export const CaseMgmtCaseListLayoutSaveLastAccessedViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseListLayoutSaveLastAccessedView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LastAccessedViewInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveLastAccessedView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'viewType' },
                value: { kind: 'StringValue', value: 'CaseView', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dashboardType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastAccessedCaseView' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recordsPerPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseListLayoutSaveLastAccessedViewMutation,
  CaseMgmtCaseListLayoutSaveLastAccessedViewMutationVariables
>;
export const CaseMgmtCaseListLayoutCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtCaseListLayoutCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseListPaginationInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleIDColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'daysSinceLastUpdateColumn' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'submissionDateColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pathwayColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseStatusColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'generalEligibilityColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oetCaseStatusColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pathwayCaseStatusColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'qaReworkColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assignedToColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastDocUploadColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseList' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pagination' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cases' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CaseMgmtCaseListTableCasePortalCasesFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pagination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CaseMgmtCaseListTableCasePortalPaginationFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtCaseListTableCasePortalCasesFieldsFragmentDoc.definitions,
    ...CaseMgmtCaseListTableCasePortalPaginationFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseListLayoutCasePortalQuery,
  CaseMgmtCaseListLayoutCasePortalQueryVariables
>;
export const CaseMgmtCaseListAddViewFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseListAddViewFilters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addViewFilters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'views' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'viewName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewFilters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'caseState' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pathwayEligibilityStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'englishEligibilityStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'generalEligibilityStatus' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'pathway' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'school' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'regulatoryAuthority' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayColumns' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseListAddViewFiltersMutation,
  CaseMgmtCaseListAddViewFiltersMutationVariables
>;
export const CaseMgmtCaseListLayoutGetDashboardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtCaseListLayoutGetDashboards' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDashboards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastAccessedCaseView' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'viewId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recordsPerPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'assignedToMe' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CaseMgmtCaseListFilterModalFields' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtCaseListFilterModalFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseListLayoutGetDashboardsQuery,
  CaseMgmtCaseListLayoutGetDashboardsQueryVariables
>;
export const CaseMgmtCaseListDeleteViewFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseListDeleteViewFilters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dashboardId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dashboardType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteViewFilters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dashboardId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dashboardId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dashboardType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dashboardType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseListDeleteViewFiltersMutation,
  CaseMgmtCaseListDeleteViewFiltersMutationVariables
>;
export const CaseMgmtCaseListSaveViewFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseListSaveViewFilters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveViewFilters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'views' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'viewName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewFilters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'caseState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pathwayEligibilityStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'englishEligibilityStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'generalEligibilityStatus' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'pathway' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'school' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'regulatoryAuthority' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseListSaveViewFiltersMutation,
  CaseMgmtCaseListSaveViewFiltersMutationVariables
>;
export const CaseMgmtCaseOverviewActionResetApplicationCasePortalCaseArchiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'CaseMgmtCaseOverviewActionResetApplicationCasePortalCaseArchive',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_case_archive' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'msg' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewActionResetApplicationCasePortalCaseArchiveMutation,
  CaseMgmtCaseOverviewActionResetApplicationCasePortalCaseArchiveMutationVariables
>;
export const CaseMgmtCaseOverviewActionsOverridePathwayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewActionsOverridePathway' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OverridePathwayInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_overridePathway' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityLog' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'logType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caseState' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewActionsOverridePathwayMutation,
  CaseMgmtCaseOverviewActionsOverridePathwayMutationVariables
>;
export const CaseMgmtCaseOverviewActionsConvertToExceptionCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewActionsConvertToExceptionCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_convertToExceptionCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isExceptionCase' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewActionsConvertToExceptionCaseMutation,
  CaseMgmtCaseOverviewActionsConvertToExceptionCaseMutationVariables
>;
export const CaseMgmtCaseOverviewActionsAssignEligibilityReviewTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewActionsAssignEligibilityReviewTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_assignEligibilityReviewTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eligibilityReviewAssigneeName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewActionsAssignEligibilityReviewTaskMutation,
  CaseMgmtCaseOverviewActionsAssignEligibilityReviewTaskMutationVariables
>;
export const CaseMgmtCaseOverviewActionsAssignQaReviewTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewActionsAssignQAReviewTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_assignQAReviewTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qaReviewAssigneeName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewActionsAssignQaReviewTaskMutation,
  CaseMgmtCaseOverviewActionsAssignQaReviewTaskMutationVariables
>;
export const CaseMgmtCaseOverviewActionsQaReworkRequiredDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewActionsQaReworkRequired' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'processGEChecksOnQARework' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_qa_reworkRequired' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'processGEChecksOnQARework' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'processGEChecksOnQARework' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewActionsQaReworkRequiredMutation,
  CaseMgmtCaseOverviewActionsQaReworkRequiredMutationVariables
>;
export const CaseMgmtCaseOverviewActionsQaRejectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewActionsQaReject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_qa_reject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewActionsQaRejectMutation,
  CaseMgmtCaseOverviewActionsQaRejectMutationVariables
>;
export const CaseMgmtCaseOverviewActionsQaApproveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewActionsQaApprove' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_qa_approve' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewActionsQaApproveMutation,
  CaseMgmtCaseOverviewActionsQaApproveMutationVariables
>;
export const CaseMgmtCaseOverviewActionsQaAcceptDecisionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewActionsQaAcceptDecision' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_qa_acceptDecision' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewActionsQaAcceptDecisionMutation,
  CaseMgmtCaseOverviewActionsQaAcceptDecisionMutationVariables
>;
export const CaseMgmtCaseOverviewActionsQaOverrideDecisionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewActionsQaOverrideDecision' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'decision' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comments' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_qa_overrideDecision' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'decision' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'decision' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comments' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comments' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewActionsQaOverrideDecisionMutation,
  CaseMgmtCaseOverviewActionsQaOverrideDecisionMutationVariables
>;
export const CaseOverviewActionsCasePortalReopenCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseOverviewActionsCasePortalReopenCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_reopenCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseOverviewActionsCasePortalReopenCaseMutation,
  CaseOverviewActionsCasePortalReopenCaseMutationVariables
>;
export const CaseMgmtCaseOverviewLayoutCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtCaseOverviewLayoutCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtCaseOverviewSummaryCasePortalFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtCaseOverviewApplicantInformationCasePortalFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtCaseOverviewActionsCasePortalFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtActivityFeedCasePortalFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtApplicantSummaryFactoryConfigFields',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtCaseOverviewSummaryCasePortalFieldsFragmentDoc.definitions,
    ...CaseMgmtCaseOverviewApplicantInformationCasePortalFieldsFragmentDoc.definitions,
    ...CaseMgmtCaseOverviewActionsCasePortalFieldsFragmentDoc.definitions,
    ...CaseMgmtActivityFeedCasePortalFieldsFragmentDoc.definitions,
    ...CaseMgmtApplicantSummaryFactoryConfigFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtCaseOverviewLayoutCasePortalQuery,
  CaseMgmtCaseOverviewLayoutCasePortalQueryVariables
>;
export const CaseMgmtFinanceActionsCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtFinanceActionsCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isFinanceUser' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'usmleId' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'finance' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'isFinanceUser' },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'payment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'amt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtFinanceActionsCasePortalQuery,
  CaseMgmtFinanceActionsCasePortalQueryVariables
>;
export const CaseMgmtFinanceActionsRequestRefundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtFinanceActionsRequestRefund' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caseWorker' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestRefund' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestArgument' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'caseWorker' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'caseWorker' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'usmleID' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleID' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'amount' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pnRef' } },
                { kind: 'Field', name: { kind: 'Name', value: 'responseMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtFinanceActionsRequestRefundMutation,
  CaseMgmtFinanceActionsRequestRefundMutationVariables
>;
export const CaseMgmtFinanceActionsIssuePaperCheckDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtFinanceActionsIssuePaperCheck' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_issuePaperCheck' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'msg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtFinanceActionsIssuePaperCheckMutation,
  CaseMgmtFinanceActionsIssuePaperCheckMutationVariables
>;
export const CaseMgmtLayoutCaseManagementCreateExceptionCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtLayoutCaseManagementCreateExceptionCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_createExceptionCase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtLayoutCaseManagementCreateExceptionCaseMutation,
  CaseMgmtLayoutCaseManagementCreateExceptionCaseMutationVariables
>;
export const CaseMgmtLayoutCaseManagementCheckCaseExistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtLayoutCaseManagementCheckCaseExists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_checkCaseExists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtLayoutCaseManagementCheckCaseExistsMutation,
  CaseMgmtLayoutCaseManagementCheckCaseExistsMutationVariables
>;
export const CaseMgmtApplicantSummaryCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtApplicantSummaryCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtQualificationReviewApplicantInfoFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtQualificationReviewLicenseFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtQualificationReviewCSExamFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtQualificationReviewCSExamNotRecentPastFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtQualificationReviewEducationFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtQualificationReviewLanguageAssessmentFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtQualificationReviewExceptionFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtApplicantSummaryFactoryConfigFields',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isExceptionCase' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedPathway' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'caseState' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtQualificationReviewApplicantInfoFieldsFragmentDoc.definitions,
    ...CaseMgmtQualificationReviewLicenseFieldsFragmentDoc.definitions,
    ...CaseMgmtQualificationReviewCsExamFieldsFragmentDoc.definitions,
    ...CaseMgmtQualificationReviewCsExamNotRecentPastFieldsFragmentDoc.definitions,
    ...CaseMgmtQualificationReviewEducationFieldsFragmentDoc.definitions,
    ...CaseMgmtQualificationReviewLanguageAssessmentFieldsFragmentDoc.definitions,
    ...CaseMgmtQualificationReviewExceptionFieldsFragmentDoc.definitions,
    ...CaseMgmtApplicantSummaryFactoryConfigFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtApplicantSummaryCasePortalQuery,
  CaseMgmtApplicantSummaryCasePortalQueryVariables
>;
export const CaseMgmtApplicantSummarySubmissionSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtApplicantSummarySubmissionSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_SubmissionSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtApplicantSummarySubmissionSummaryMutation,
  CaseMgmtApplicantSummarySubmissionSummaryMutationVariables
>;
export const CaseMgmtOetDocumentReviewChecklistReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtOETDocumentReviewChecklistReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checklistResponse' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LanguageAssessmentChecklistInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checklistState' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_languageAssessment_setReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isComplete' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checklist' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'checklistResponse' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checklistState' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'checklistState' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtOetDocumentReviewChecklistReviewMutation,
  CaseMgmtOetDocumentReviewChecklistReviewMutationVariables
>;
export const CaseMgmtOetDocumentReviewLayoutCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtOETDocumentReviewLayoutCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtOETDocumentReviewApplicantDetailsFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtActivityFeedCasePortalFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtApplicantSummaryFactoryConfigFields',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eligibilityReviewAssigneeId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'englishEligibilityStatus' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalDocuments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'document' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageAssessment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'documents' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docId' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docType' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'review' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklistState' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklist' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nameValid' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'scoreConfirmed' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'scoreAcceptable' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'examDateValidation' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'documentCompleteLegible',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'documents' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docId' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docType' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'uploadedBy' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'review' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklistState' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklist' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nameValid' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'scoreConfirmed' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'scoreAcceptable' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'examDateValidation' },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oetScore' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'readingScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'listeningScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'writingScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'speakingScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'examDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'candidateId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'testingCenter' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'examineeLastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'examineeFirstName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtOetDocumentReviewApplicantDetailsFieldsFragmentDoc.definitions,
    ...CaseMgmtActivityFeedCasePortalFieldsFragmentDoc.definitions,
    ...CaseMgmtApplicantSummaryFactoryConfigFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtOetDocumentReviewLayoutCasePortalQuery,
  CaseMgmtOetDocumentReviewLayoutCasePortalQueryVariables
>;
export const CaseMgmtOetDocumentReviewLayoutCasePortalAddAndDeleteAdditionDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'CaseMgmtOETDocumentReviewLayoutCasePortalAddAndDeleteAdditionDocuments',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AdditionalDocumentInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_additionalDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtOetDocumentReviewLayoutCasePortalAddAndDeleteAdditionDocumentsMutation,
  CaseMgmtOetDocumentReviewLayoutCasePortalAddAndDeleteAdditionDocumentsMutationVariables
>;
export const CaseMgmtPathway1DocumentReviewChecklistReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway1DocumentReviewChecklistReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checklistResponse' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LicenseChecklistInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checklistState' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_pathway1_setReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isComplete' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checklist' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'checklistResponse' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checklistState' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'checklistState' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pathway1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'staffProvidedData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'review' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway1DocumentReviewChecklistReviewMutation,
  CaseMgmtPathway1DocumentReviewChecklistReviewMutationVariables
>;
export const CaseMgmtPathway1DocumentReviewLayoutCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtPathway1DocumentReviewLayoutCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtApplicantSummaryFactoryConfigFields',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway1' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'licenseIssueDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'licenseExpiryDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'hasDisciplinaryAction' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'regulatoryAuthorityRef' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'orgName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'countryName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '_id' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'review' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklistState' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklist' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureUnrestrictedLicense',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureIssuedAppropriateAuthority',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureApplicantLicenseOnPoint',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureDisciplinaryAction',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureNameValidation',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureCompleteLegible',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureTranslationStatus',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureDocumentIssueDateAcceptable',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationAuthorized',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationPrimarySource',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationActiveLicense',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationGoodStanding',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'documents' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docId' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docType' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'uploadedBy' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'review' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklistState' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklist' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureUnrestrictedLicense',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureIssuedAppropriateAuthority',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureApplicantLicenseOnPoint',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureDisciplinaryAction',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureNameValidation',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureCompleteLegible',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureTranslationStatus',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'licensureDocumentIssueDateAcceptable',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationAuthorized',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationPrimarySource',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationActiveLicense',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationGoodStanding',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eligibilityReviewAssigneeId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pathwayEligibilityStatus' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalDocuments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'document' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtPathway1ReviewApplicantDetailsFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtActivityFeedCasePortalFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtApplicantSummaryFactoryConfigFieldsFragmentDoc.definitions,
    ...CaseMgmtPathway1ReviewApplicantDetailsFieldsFragmentDoc.definitions,
    ...CaseMgmtActivityFeedCasePortalFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway1DocumentReviewLayoutCasePortalQuery,
  CaseMgmtPathway1DocumentReviewLayoutCasePortalQueryVariables
>;
export const CaseMgmtPathway1DocumentReviewLayoutAddLicenseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway1DocumentReviewLayoutAddLicense' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LicenseDataInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_pathway1_setData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pathway1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'staffProvidedData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway1DocumentReviewLayoutAddLicenseMutation,
  CaseMgmtPathway1DocumentReviewLayoutAddLicenseMutationVariables
>;
export const CaseMgmtPathway1DocumentReviewLayoutDeleteLicenseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway1DocumentReviewLayoutDeleteLicense' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_pathway1_deleteRecord' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pathway1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'staffProvidedData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway1DocumentReviewLayoutDeleteLicenseMutation,
  CaseMgmtPathway1DocumentReviewLayoutDeleteLicenseMutationVariables
>;
export const CaseMgmtPathway1DocumentReviewLayoutAddAndDeleteDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway1DocumentReviewLayoutAddAndDeleteDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AdditionalDocumentInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_additionalDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway1DocumentReviewLayoutAddAndDeleteDocumentMutation,
  CaseMgmtPathway1DocumentReviewLayoutAddAndDeleteDocumentMutationVariables
>;
export const CaseMgmtPathway1LayoutCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtPathway1LayoutCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtApplicantSummaryFactoryConfigFields',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway1' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'documents' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docType' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docId' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'licenseIssueDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'licenseExpiryDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'hasDisciplinaryAction' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isCurrentlyLicensed' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'licensureDocumentStatus' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'regulatoryAuthorityRef' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'orgName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'countryName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '_id' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'documents' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docType' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docId' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalDocuments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'document' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'caseState' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eligibilityReviewAssigneeId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtPathway1ReviewApplicantDetailsFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtActivityFeedCasePortalFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtApplicantSummaryFactoryConfigFieldsFragmentDoc.definitions,
    ...CaseMgmtPathway1ReviewApplicantDetailsFieldsFragmentDoc.definitions,
    ...CaseMgmtActivityFeedCasePortalFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway1LayoutCasePortalQuery,
  CaseMgmtPathway1LayoutCasePortalQueryVariables
>;
export const CaseMgmtPathway1LayoutAddLicenseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway1LayoutAddLicense' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LicenseDataInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_pathway1_setData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pathway1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'staffProvidedData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway1LayoutAddLicenseMutation,
  CaseMgmtPathway1LayoutAddLicenseMutationVariables
>;
export const CaseMgmtPathway1LayoutDeleteLicenseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway1LayoutDeleteLicense' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_pathway1_deleteRecord' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pathway1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'staffProvidedData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway1LayoutDeleteLicenseMutation,
  CaseMgmtPathway1LayoutDeleteLicenseMutationVariables
>;
export const CaseMgmtPathway1LayoutAddAdditionalDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway1LayoutAddAdditionalDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AdditionalDocumentInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_additionalDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway1LayoutAddAdditionalDocumentMutation,
  CaseMgmtPathway1LayoutAddAdditionalDocumentMutationVariables
>;
export const CaseMgmtPathway2DocumentReviewChecklistReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway2DocumentReviewChecklistReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checklistResponse' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CSExamChecklistInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checklistState' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_pathway2_setReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isComplete' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checklist' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'checklistResponse' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checklistState' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'checklistState' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pathway2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'staffProvidedData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'review' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway2DocumentReviewChecklistReviewMutation,
  CaseMgmtPathway2DocumentReviewChecklistReviewMutationVariables
>;
export const CaseMgmtPathway2DocumentReviewLayoutCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtPathway2DocumentReviewLayoutCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtPathway2DocumentReviewApplicantDetailsFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtActivityFeedCasePortalFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtApplicantSummaryFactoryConfigFields',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eligibilityReviewAssigneeId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pathwayEligibilityStatus' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalDocuments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'document' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway2' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'examDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'oscExamRef' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '_id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'examCode' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'examFullName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'examShortTitle' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'review' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklist' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'osceExaminationAcceptable',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'osceExaminationPassed',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'osceExaminationAfterJuly1',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'osceNameOK' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'osceTranslation' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'osceDocumentCompleteLegible',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationCompleted',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationReturned',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationPassedExamConfirmed',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationAcceptableDateConfirmed',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklistState' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'examDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'oscExamRef' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '_id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'examCode' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'examFullName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'examShortTitle' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'review' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklist' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'osceExaminationAcceptable',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'osceExaminationPassed',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'osceExaminationAfterJuly1',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'osceNameOK' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'osceTranslation' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'osceDocumentCompleteLegible',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationCompleted',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationReturned',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationPassedExamConfirmed',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'verificationAcceptableDateConfirmed',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklistState' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtPathway2DocumentReviewApplicantDetailsFieldsFragmentDoc.definitions,
    ...CaseMgmtActivityFeedCasePortalFieldsFragmentDoc.definitions,
    ...CaseMgmtApplicantSummaryFactoryConfigFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway2DocumentReviewLayoutCasePortalQuery,
  CaseMgmtPathway2DocumentReviewLayoutCasePortalQueryVariables
>;
export const CaseMgmtPathway2DocumentReviewLayoutCasePortalAddAndDeleteAdditionalDocumentDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value: 'CaseMgmtPathway2DocumentReviewLayoutCasePortalAddAndDeleteAdditionalDocument',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'ListType',
                type: {
                  kind: 'NonNullType',
                  type: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AdditionalDocumentInput' },
                  },
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'CasePortal_additionalDocuments' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'usmleId' },
                  value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                },
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'dataVersion' },
                  value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
                },
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'caseSummary' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'additionalDocuments' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'document' },
                          selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                              { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                              { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                              { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                            ],
                          },
                        },
                        { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CaseMgmtPathway2DocumentReviewLayoutCasePortalAddAndDeleteAdditionalDocumentMutation,
    CaseMgmtPathway2DocumentReviewLayoutCasePortalAddAndDeleteAdditionalDocumentMutationVariables
  >;
export const CaseMgmtPathway3DocumentReviewChecklistReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway3DocumentReviewChecklistReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checklistResponse' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EducationChecklistInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checklistState' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_pathway345_setReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isComplete' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checklist' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'checklistResponse' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checklistState' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'checklistState' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pathway345' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicantProvidedData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'gradDate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'schoolRef' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'countryName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'review' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'checklistState' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'checklist' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'eligibilityMedicalSchool' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'eligibilityGradDateEligible',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attestationComplete' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'attestationAuthorizedOfficial',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attestationTitleOk' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'attestationSignatureMatch',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attestationSealOk' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attestationSignatureDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'attestationSentPrimarySource',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'attestationCompletedByMedicalSchool',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'staffProvidedData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'gradDate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'schoolRef' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'countryName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'review' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'checklistState' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'checklist' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'eligibilityMedicalSchool' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'eligibilityGradDateEligible',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attestationComplete' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'attestationAuthorizedOfficial',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attestationTitleOk' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'attestationSignatureMatch',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attestationSealOk' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attestationSignatureDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'attestationSentPrimarySource',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'attestationCompletedByMedicalSchool',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway3DocumentReviewChecklistReviewMutation,
  CaseMgmtPathway3DocumentReviewChecklistReviewMutationVariables
>;
export const CastMgmtPathway3DocumentReviewLayoutCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CastMgmtPathway3DocumentReviewLayoutCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtPathway3DocumentReviewApplicantDetailsFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtActivityFeedCasePortalFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtApplicantSummaryFactoryConfigFields',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uiConfig' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'config' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pages' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'components' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'componentName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'pageName' },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'portal' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eligibilityReviewAssigneeId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pathwayEligibilityStatus' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPathwayChanged' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalDocuments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'document' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway345' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'gradDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'schoolRef' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'countryName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '_id' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'review' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklistState' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklist' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'eligibilityMedicalSchool',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'eligibilityGradDateEligible',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationComplete',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationAuthorizedOfficial',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'attestationTitleOk' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationSignatureMatch',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'attestationSealOk' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationSignatureDate',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationSentPrimarySource',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationCompletedByMedicalSchool',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attestation' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'outputReason' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'outputReasonText' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'updatedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'role' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'updatedTimestamp' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'gradDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'schoolRef' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'countryName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '_id' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'documents' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docId' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docType' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'review' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklistState' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklist' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'eligibilityMedicalSchool',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'eligibilityGradDateEligible',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationComplete',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationAuthorizedOfficial',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'attestationTitleOk' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationSignatureMatch',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'attestationSealOk' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationSignatureDate',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationSentPrimarySource',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'attestationCompletedByMedicalSchool',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attestation' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'outputReason' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'outputReasonText' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'updatedBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'role' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'updatedTimestamp' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtPathway3DocumentReviewApplicantDetailsFieldsFragmentDoc.definitions,
    ...CaseMgmtActivityFeedCasePortalFieldsFragmentDoc.definitions,
    ...CaseMgmtApplicantSummaryFactoryConfigFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CastMgmtPathway3DocumentReviewLayoutCasePortalQuery,
  CastMgmtPathway3DocumentReviewLayoutCasePortalQueryVariables
>;
export const CaseMgmtPathway3DocumentReviewLayoutAddAndDeleteDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway3DocumentReviewLayoutAddAndDeleteDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AdditionalDocumentInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_additionalDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway3DocumentReviewLayoutAddAndDeleteDocumentMutation,
  CaseMgmtPathway3DocumentReviewLayoutAddAndDeleteDocumentMutationVariables
>;
export const CaseMgmtPathway6CancelMinicexCasePortalMiniCexCancelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway6CancelMinicexCasePortalMiniCEXCancel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'encounterId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'minicexDataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isMiniCEXAssessmentResetConfirmed' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_MiniCEX_cancel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'encounterId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'encounterId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minicexDataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'minicexDataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isMiniCEXAssessmentResetConfirmed' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isMiniCEXAssessmentResetConfirmed' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minicexId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway6CancelMinicexCasePortalMiniCexCancelMutation,
  CaseMgmtPathway6CancelMinicexCasePortalMiniCexCancelMutationVariables
>;
export const CaseMgmtPathway6DocumentReviewChecklistReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway6DocumentReviewChecklistReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checklistResponse' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MiniCEXChecklistInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checklistState' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_pathway6_setReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isComplete' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checklist' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'checklistResponse' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checklistState' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'checklistState' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway6DocumentReviewChecklistReviewMutation,
  CaseMgmtPathway6DocumentReviewChecklistReviewMutationVariables
>;
export const CaseMgmtPathway6DocumentReviewLayoutUnlockReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway6DocumentReviewLayoutUnlockReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_pathway6_unlockReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: '_id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway6DocumentReviewLayoutUnlockReviewMutation,
  CaseMgmtPathway6DocumentReviewLayoutUnlockReviewMutationVariables
>;
export const CaseMgmtPathway6DocumentReviewLayoutCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtPathway6DocumentReviewLayoutCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MiniCEXListFilterCaseManagerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtPathway6DocumentReviewApplicantDetailsFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtActivityFeedCasePortalFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtApplicantSummaryFactoryConfigFields',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eligibilityReviewAssigneeId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pathwayEligibilityStatus' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gradSchool' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gradYear' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastDataRefreshTimestamp' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalDocuments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'document' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uiConfig' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'config' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pages' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'pageName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'components' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'componentName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway6' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'acknowledgedTimestamp' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'documents' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docType' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docId' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'uploadedBy' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'review' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklistState' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklist' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'minicexComplete' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'minicexScoreAcceptable',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isLocked' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'assessment' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'categories' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'failedEncounters' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'encounterId' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'score' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avgScore' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isFailedByAvgScore' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'areAllMiniCEXComplete' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'acknowledgedTimestamp' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'documents' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docType' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'docId' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'uploadedBy' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'review' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklistState' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'checklist' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'minicexComplete' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'minicexScoreAcceptable',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isLocked' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'assessment' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'categories' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'failedEncounters' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'encounterId' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'score' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avgScore' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isFailedByAvgScore' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'areAllMiniCEXComplete' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minicexList' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CaseMgmtPathway6TableLayoutMiniCEXFields',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtPathway6DocumentReviewApplicantDetailsFieldsFragmentDoc.definitions,
    ...CaseMgmtActivityFeedCasePortalFieldsFragmentDoc.definitions,
    ...CaseMgmtApplicantSummaryFactoryConfigFieldsFragmentDoc.definitions,
    ...CaseMgmtPathway6TableLayoutMiniCexFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway6DocumentReviewLayoutCasePortalQuery,
  CaseMgmtPathway6DocumentReviewLayoutCasePortalQueryVariables
>;
export const CaseMgmtPathway6DocumentReviewLayoutPhysicianByMiniCexDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtPathway6DocumentReviewLayoutPhysicianByMiniCEX' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PhysicianByMiniCEXFilterCaseManagerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'physicianByMiniCEX' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtPathway6TableLayoutPhysicianDetailFields',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtPathway6TableLayoutPhysicianDetailFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway6DocumentReviewLayoutPhysicianByMiniCexQuery,
  CaseMgmtPathway6DocumentReviewLayoutPhysicianByMiniCexQueryVariables
>;
export const CaseMgmtPathway6DocumentReviewLayoutAddAndDeleteDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway6DocumentReviewLayoutAddAndDeleteDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AdditionalDocumentInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_additionalDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway6DocumentReviewLayoutAddAndDeleteDocumentMutation,
  CaseMgmtPathway6DocumentReviewLayoutAddAndDeleteDocumentMutationVariables
>;
export const CaseMgmtPathway6MiniCexActiveTableRejectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathway6MiniCEXActiveTableReject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'encounterId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'minicexDataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_MiniCEX_reject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'encounterId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'encounterId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minicexDataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'minicexDataVersion' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'encounterId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'encounterId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicianEmail' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'physicianLastNameProvidedByPhysician' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'physicianRestOfNameProvidedByPhysician' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applicantLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applicantRestOfName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stateModel' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'allowedActions' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathway6MiniCexActiveTableRejectMutation,
  CaseMgmtPathway6MiniCexActiveTableRejectMutationVariables
>;
export const CaseMgmtPathwayXDocumentReviewChecklistReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathwayXDocumentReviewChecklistReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checklistResponse' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ExceptionRequestChecklistInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pathwayChecklistState' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'exceptionChecklistState' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_pathwayX_setReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recordId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recordId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isComplete' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isComplete' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checklist' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'checklistResponse' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pathwayChecklistState' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pathwayChecklistState' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'exceptionChecklistState' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exceptionChecklistState' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pathwayX' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'review' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pathwayChecklistState' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'exceptionChecklistState' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checklist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantIsNotCertified' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantNotValidStep2CSScore' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'applicantHasNotSubmittedAppForCert',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'applicantHasPurchasedToken2021Match',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantNotBarredByUSMLE' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ecfmgRestrictionsDoNotPreventAppExamination',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'step1PassedWithOneOrNoFails' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'step2CKPassedWithOneOrNoFails' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'exceptionApproved' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantEligiblePathway1' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pathway1DocsVerified' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantEligiblePathway2' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pathway2DocsVerified' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicantEligiblePathway345' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pathway345DocsVerified' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathwayXDocumentReviewChecklistReviewMutation,
  CaseMgmtPathwayXDocumentReviewChecklistReviewMutationVariables
>;
export const CaseMgmtPathwayXDocumentReviewLayoutCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtPathwayXDocumentReviewLayoutCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtPathwayXDocumentReviewApplicantDetailsFields',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseMgmtActivityFeedCasePortalFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CaseMgmtApplicantSummaryFactoryConfigFields',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eligibilityReviewAssigneeId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pathwayEligibilityStatus' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalDocuments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'document' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathwayX' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'review' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pathwayChecklistState' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'exceptionChecklistState' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'checklist' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'applicantIsNotCertified' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'applicantNotValidStep2CSScore',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'applicantHasNotSubmittedAppForCert',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'applicantHasPurchasedToken2021Match',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'applicantNotBarredByUSMLE',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ecfmgRestrictionsDoNotPreventAppExamination',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'step1PassedWithOneOrNoFails',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'step2CKPassedWithOneOrNoFails',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'exceptionApproved' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'applicantEligiblePathway1',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'pathway1DocsVerified' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'applicantEligiblePathway2',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'pathway2DocsVerified' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'applicantEligiblePathway345',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'pathway345DocsVerified' },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtPathwayXDocumentReviewApplicantDetailsFieldsFragmentDoc.definitions,
    ...CaseMgmtActivityFeedCasePortalFieldsFragmentDoc.definitions,
    ...CaseMgmtApplicantSummaryFactoryConfigFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtPathwayXDocumentReviewLayoutCasePortalQuery,
  CaseMgmtPathwayXDocumentReviewLayoutCasePortalQueryVariables
>;
export const CaseMgmtPathwayXDocumentReviewLayoutAddAndDeleteDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPathwayXDocumentReviewLayoutAddAndDeleteDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AdditionalDocumentInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_additionalDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usmleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPathwayXDocumentReviewLayoutAddAndDeleteDocumentMutation,
  CaseMgmtPathwayXDocumentReviewLayoutAddAndDeleteDocumentMutationVariables
>;
export const CaseMgmtUploadDocumentUploadDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtUploadDocumentUploadDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'token' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtUploadDocumentUploadDocumentMutation,
  CaseMgmtUploadDocumentUploadDocumentMutationVariables
>;
export const CaseMgmtRedirectOetCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtRedirectOetCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedPathway' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageAssessment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtRedirectOetCasePortalQuery,
  CaseMgmtRedirectOetCasePortalQueryVariables
>;
export const CaseMgmtRedirectPathwayCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtRedirectPathwayCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'case' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'usmleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'usmleId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caseSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPathway' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedPathway' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPathwayChanged' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway1' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway2' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway345' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathway6' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicantProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffProvidedData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pathwayX' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: '_id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtRedirectPathwayCasePortalQuery,
  CaseMgmtRedirectPathwayCasePortalQueryVariables
>;
export const CaseMgmtFinanceSettingsLayoutCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtFinanceSettingsLayoutCasePortal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'glDistributionConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateFrom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'glAccountCashControl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'glAccountUnearned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'glAccountEarned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'glAccountRefundControl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'standardCaseFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'standardCaseInitialIntake' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exceptionCaseFee' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exceptionCaseInitialIntake' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtFinanceSettingsLayoutCasePortalQuery,
  CaseMgmtFinanceSettingsLayoutCasePortalQueryVariables
>;
export const CaseMgmtFinanceSettingsLayoutGlDistributionConfig_CreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtFinanceSettingsLayoutGLDistributionConfig_create' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'configName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'applicationType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateFrom' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateString' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'glAccountCashControl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'glAccountUnearned' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'glAccountEarned' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'glAccountRefundControl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'standardCaseFee' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardCaseInitialIntake' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'exceptionCaseFee' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exceptionCaseInitialIntake' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'GLDistributionConfig_create' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'configName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'configName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'applicationType' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateFrom' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'dateFrom' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'glAccountCashControl' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'glAccountCashControl' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'glAccountUnearned' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'glAccountUnearned' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'glAccountEarned' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'glAccountEarned' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'glAccountRefundControl' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'glAccountRefundControl' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'standardCaseFee' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'standardCaseFee' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'standardCaseInitialIntake' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'standardCaseInitialIntake' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'exceptionCaseFee' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exceptionCaseFee' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'exceptionCaseInitialIntake' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exceptionCaseInitialIntake' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'glAccountCashControl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'glAccountUnearned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'glAccountEarned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'glAccountRefundControl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'standardCaseFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'standardCaseInitialIntake' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exceptionCaseFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exceptionCaseInitialIntake' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtFinanceSettingsLayoutGlDistributionConfig_CreateMutation,
  CaseMgmtFinanceSettingsLayoutGlDistributionConfig_CreateMutationVariables
>;
export const CaseMgmtFinanceSettingsLayoutGlDistributionConfig_EditDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtFinanceSettingsLayoutGLDistributionConfig_edit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GLDistributionConfigEditInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'GLDistributionConfig_edit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: '_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'version' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'glAccountCashControl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'glAccountUnearned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'glAccountEarned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'glAccountRefundControl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'standardCaseFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'standardCaseInitialIntake' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exceptionCaseFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exceptionCaseInitialIntake' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtFinanceSettingsLayoutGlDistributionConfig_EditMutation,
  CaseMgmtFinanceSettingsLayoutGlDistributionConfig_EditMutationVariables
>;
export const CaseMgmtFinanceSettingsLayoutGlDistributionConfig_DeactivateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtFinanceSettingsLayoutGLDistributionConfig_deactivate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'GLDistributionConfig_deactivate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: '_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'version' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'msg' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtFinanceSettingsLayoutGlDistributionConfig_DeactivateMutation,
  CaseMgmtFinanceSettingsLayoutGlDistributionConfig_DeactivateMutationVariables
>;
export const CaseMgmtTransactionHistoryDetailsCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtTransactionHistoryDetailsCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionDateFrom' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionDateTo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'glDistributionReport' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'transactionDateFrom' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'transactionDateFrom' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'transactionDateTo' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'transactionDateTo' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trsAccountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transactionTimestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentNetworkReferenceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'glDebitAccountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'glCreditAccountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'feeType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtTransactionHistoryDetailsCasePortalQuery,
  CaseMgmtTransactionHistoryDetailsCasePortalQueryVariables
>;
export const CaseMgmtMaintenanceServerTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtMaintenanceServerTime' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'serverDate' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtMaintenanceServerTimeQuery,
  CaseMgmtMaintenanceServerTimeQueryVariables
>;
export const MedicalSchoolOfficialsSyncAccountFromB2CDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MedicalSchoolOfficialsSyncAccountFromB2C' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_AuthorityMgmt_syncAccountFromB2C' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'msg' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MedicalSchoolOfficialsSyncAccountFromB2CMutation,
  MedicalSchoolOfficialsSyncAccountFromB2CMutationVariables
>;
export const CaseMgmtPhysicianActiveTableCasePortal_MiniCex_RejectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPhysicianActiveTableCasePortal_MiniCEX_reject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'encounterId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'minicexDataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_MiniCEX_reject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'encounterId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'encounterId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minicexDataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'minicexDataVersion' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'encounterId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'encounterId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'physicianLastNameProvidedByPhysician' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'physicianRestOfNameProvidedByPhysician' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applicantLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applicantRestOfName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stateModel' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'allowedActions' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPhysicianActiveTableCasePortal_MiniCex_RejectMutation,
  CaseMgmtPhysicianActiveTableCasePortal_MiniCex_RejectMutationVariables
>;
export const CaseMgmtPhysicianDetailCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtPhysicianDetailCasePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'responseType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'physician' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'oid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'allowedActions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicianId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicantsEvaluatedByPhysician' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'email' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'usmleId' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'physicianConfig' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'config' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minicex' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'maxApplicantsAllowedToBeEvaluatedByPhysician',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minicex' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'accountStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'profileStatus' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'restOfName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isHoldingFacultyAppointment' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'medicalSpecialty' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmails' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'licenses' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'licenseNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'physicianRegAuthorityRef' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activityLog' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'logType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minicexByPhysician' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'email' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'responseType' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'responseType' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'encounterId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'summary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'allowedActions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'physicianEmail' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'physicianLastNameProvidedByApplicant' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'physicianRestOfNameProvidedByApplicant',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'physicianLastNameProvidedByPhysician' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'physicianRestOfNameProvidedByPhysician',
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'applicantLastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'applicantRestOfName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usmleId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'encounterId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stateModel' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rejectionReasonComments' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uiConfig' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'config' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pages' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'pageName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'components' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'componentName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'version' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'form' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'encounter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'encounterDate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'encounterSetting' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'facility' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'country' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'patient' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ageYear' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'ageMonth' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'evaluation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'categories' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'reasonText' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isAdditionalInfoRequired' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'additionalInfoText' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPhysicianDetailCasePortalQuery,
  CaseMgmtPhysicianDetailCasePortalQueryVariables
>;
export const CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptOutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPhysicianDetailCasePortal_PhyMgmt_optOut' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_PhyMgmt_optOut' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'NullValue' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptOutMutation,
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptOutMutationVariables
>;
export const CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptInDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPhysicianDetailCasePortal_PhyMgmt_optIn' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_PhyMgmt_optIn' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptInMutation,
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_OptInMutationVariables
>;
export const CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPhysicianDetailCasePortal_PhyMgmt_editName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PhysicianNameInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_PhyMgmt_editName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'restOfName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditNameMutation,
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditNameMutationVariables
>;
export const CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditDoBDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaseMgmtPhysicianDetailCasePortal_PhyMgmt_editDoB' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PhysicianDoBInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_PhyMgmt_editDoB' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditDoBMutation,
  CaseMgmtPhysicianDetailCasePortal_PhyMgmt_EditDoBMutationVariables
>;
export const CasePortal_PhyMgmt_BlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CasePortal_PhyMgmt_block' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_PhyMgmt_block' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minicex' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actionTags' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CasePortal_PhyMgmt_BlockMutation,
  CasePortal_PhyMgmt_BlockMutationVariables
>;
export const TagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Tags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'physician' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minicex' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'actionTags' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TagsQuery, TagsQueryVariables>;
export const CasePortal_PhyMgmt_UnblockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CasePortal_PhyMgmt_unblock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_PhyMgmt_unblock' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minicex' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actionTags' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CasePortal_PhyMgmt_UnblockMutation,
  CasePortal_PhyMgmt_UnblockMutationVariables
>;
export const PhysicianDetailCasePortalPhyMgmtSnyncRegistrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PhysicianDetailCasePortalPhyMgmtSnyncRegistration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasePortal_PhyMgmt_syncRegistration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataVersion' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataVersion' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dataVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minicex' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accountStatus' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhysicianDetailCasePortalPhyMgmtSnyncRegistrationMutation,
  PhysicianDetailCasePortalPhyMgmtSnyncRegistrationMutationVariables
>;
export const CaseMgmtPhysicianListCasePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseMgmtPhysicianListCasePortal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'casePortal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CaseMgmtPhysicianTablePhysiciansFields' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CaseMgmtPhysicianTablePhysiciansFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CaseMgmtPhysicianListCasePortalQuery,
  CaseMgmtPhysicianListCasePortalQueryVariables
>;
