import { Col, Descriptions, Divider, Row } from 'antd';
import moment from 'moment';
import { FC } from 'react';
import {
  MiniCexSummaryType,
  PhysicianLicenseRecordType,
  PhysicianResponseType,
} from '../../../../../../generated';
import { getPhysicianName, PATHWAY6_DATE_FORMAT } from '../../../../../constants';
import { LicenseCardList } from '../physician-license-card-list';

interface Pathway6ReviewPhysicianDetailsProps {
  currentRecord?: MiniCexSummaryType;
  currentPhysicianData?: PhysicianResponseType;
}
const Pathway6ReviewPhysicianDetails: FC<Pathway6ReviewPhysicianDetailsProps> = ({
  currentRecord,
  currentPhysicianData,
}) => {
  return (
    <>
      <div style={{ border: '2px solid', padding: '5px' }}>
        <Descriptions layout="vertical" column={4} size="small">
          <Descriptions.Item label="Applicant">{`${currentRecord?.applicantLastName}, ${currentRecord?.applicantRestOfName}`}</Descriptions.Item>
          <Descriptions.Item label="USMLEID">{currentRecord?.usmleId}</Descriptions.Item>
          <Descriptions.Item label="Encounter ID">{currentRecord?.encounterId}</Descriptions.Item>
          <Descriptions.Item label="Date Requested">
            {currentRecord?.createdAt !== undefined
              ? moment(currentRecord?.createdAt).utc().format(PATHWAY6_DATE_FORMAT)
              : ''}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <Divider orientation="left">
        <b>Physician Details</b>
      </Divider>

      <Descriptions layout="vertical" column={4} size="small">
        <Descriptions.Item label="Name from Physician">
          {getPhysicianName(
            currentPhysicianData?.profile?.lastName,
            currentPhysicianData?.profile?.restOfName
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Name from Applicant">
          {getPhysicianName(
            currentRecord?.physicianLastNameProvidedByApplicant,
            currentRecord?.physicianRestOfNameProvidedByApplicant
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Primary E-mail Address">
          {currentRecord?.physicianEmail}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {currentPhysicianData?.minicex?.accountStatus}
        </Descriptions.Item>
      </Descriptions>
      <Row>
        <Col style={{ fontSize: 14 }}>
          <b>Licensure Information:</b>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <LicenseCardList
            licenses={currentPhysicianData?.profile?.licenses as PhysicianLicenseRecordType[]}
            isEditable={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default Pathway6ReviewPhysicianDetails;
