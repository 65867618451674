import { Button, Card, Modal, Popover, Steps } from 'antd';
import Title from 'antd/lib/typography/Title';
import { FC, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArchivedCaseType } from '../../../../generated';
import ArchivedCaseExportContainer from './archived-case-export-container';
import dayjs from 'dayjs';
import RejectionReasonsFactory from '../../../../utils/rejection-reason/rejection-reasons-factory';
import _ from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ApplicationSummaryPDFButton } from '../../../case-management/pathways/applicant-summary/application-summary-pdf-button';

interface ArchivedCaseDetailsProps {
  data: ArchivedCaseType;
  getApplicantSummaryPDF: any;
  responseDataFieldName: string;
}

const ArchivedCaseDetails: FC<ArchivedCaseDetailsProps> = (props) => {
  const { usmleId, caseId } = useParams();
  const { Step } = Steps;

  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const handlePreview = () => {
    setShowPreviewModal(true);
  };

  const handleCloseModal = () => {
    setShowPreviewModal(false);
  };

  const submittedDescription = (
    <div>
      <b style={{ fontSize: '16px' }}>{props.data.caseDetails?.submittedPathway}</b>
      <br />
      <ApplicationSummaryPDFButton
        buttonType="link"
        style={{ marginLeft: '-20px' }}
        caseId={caseId}
        getApplicationSummaryPDFMutation={props.getApplicantSummaryPDF}
        responseDataFieldName={props.responseDataFieldName}
      />
    </div>
  );

  const completedDescription = (
    <div>
      <b style={{ fontSize: '16px' }}>{props.data.caseDetails?.currentPathway}</b>
      {props.data.caseDetails?.isRefunded && (
        <div style={{ color: 'red', fontWeight: '700', fontSize: '14px' }}>Refunded</div>
      )}
    </div>
  );

  const titleLine = (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', flexGrow: 3 }}>
        <Title level={2} style={{ marginRight: '40px', marginTop: '0.4em' }}>
          USMLE ID {usmleId}
        </Title>
        <Title level={5}>
          Season {props.data.caseDetails?.pathwaySeason} | {props.data.caseDetails?.applicationType}
        </Title>
      </div>
      <Button onClick={handlePreview}>Export Raw Data (JSON)</Button>
      <Modal
        title={'Raw Data (JSON)'}
        visible={showPreviewModal}
        onCancel={handleCloseModal}
        footer={null}
      >
        <ArchivedCaseExportContainer />
      </Modal>
    </div>
  );

  const breadCrumb = (
    <Title level={5}>
      <Link to={'/archived-cases'}>Archived Cases</Link> /
      <Link to={`/archived-cases/${usmleId}`}> USMLE ID {usmleId}</Link> /<b> Case {caseId}</b>
    </Title>
  );

  return (
    <div>
      {breadCrumb}
      {titleLine}
      <div style={{ display: 'flex', flexGrow: 3 }}>
        <Card style={{ marginRight: '10px', flex: 3 }}>
          <Steps progressDot current={3}>
            <Step
              title="Created"
              subTitle={
                props.data.audit?.createdAt
                  ? dayjs(props.data.audit?.createdAt).format('MM/DD/YYYY')
                  : 'N/A'
              }
            />
            <Step
              title="Submitted"
              subTitle={
                props.data.audit?.submittedAt ? (
                  dayjs(props.data.audit?.submittedAt).format('MM/DD/YYYY')
                ) : (
                  <Popover content={'Check activity log for details'} title="">
                    <InfoCircleOutlined />
                  </Popover>
                )
              }
              description={submittedDescription}
            />
            <Step
              title="Completed"
              subTitle={
                props.data.audit?.completedAt
                  ? dayjs(props.data.audit?.completedAt).format('MM/DD/YYYY')
                  : 'N/A'
              }
              description={completedDescription}
            />
            <Step
              title="Archived"
              subTitle={
                props.data.audit?.archivedAt
                  ? dayjs(props.data.audit?.archivedAt).format('MM/DD/YYYY')
                  : 'N/A'
              }
            />
          </Steps>
        </Card>
        <Card style={{ flex: 1, lineHeight: '22px', fontStyle: 'normal' }}>
          <div
            style={{
              borderRadius: '5px',
              background:
                props.data.caseDetails?.decision?.output === 'Approved' ? '#097D5A' : '#D11D5C',
              color: '#FFFFFF',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: '800',
              marginBottom: '10px',
            }}
          >
            Decision: {props.data.caseDetails?.decision?.output}
          </div>
          <div
            style={{
              textAlign: 'center',
              color: '#888',
              fontSize: '14px',
              paddingBottom: '10px',
              fontWeight: '400',
            }}
          >
            by {props.data.caseDetails?.decision?.executedBy.name}
          </div>
          <div style={{ textAlign: 'left', color: '#888', fontSize: '12px', fontWeight: '400' }}>
            {props.data.uiConfig &&
            props.data.caseDetails?.decision?.outputReason &&
            props.data.caseDetails?.decision?.outputReason?.length > 0
              ? _.find(RejectionReasonsFactory(props.data.uiConfig), {
                  key: props.data.caseDetails?.decision?.outputReason[0],
                })?.value
              : ''}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ArchivedCaseDetails;
