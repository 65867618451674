import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

type paramType = {
  usmleId:string,
  id: string
}
  
const PathwayLink: FC<paramType > = ({id, usmleId}) => {
    return (
      <Link to={`/case-management/case-detail/${usmleId}/pathway`}>
        <Button style={{ minWidth: "100%" }} size="small">
          View Pathway Case
        </Button>
      </Link>
    )
}

export default {
    Component: PathwayLink
};
  