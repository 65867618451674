import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table, Button, Tag } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import { physicians } from '../../schema/casePortal-physicians';
import {CaseMgmtPhysicianListCasePortalQuery} from '../../../generated';
import { TAG_STATUS_MAPPING } from '../../constants';

const PhysicianListPropTypes = { };

export interface ComponentProp {
  physicians: CaseMgmtPhysicianListCasePortalQuery;
}

export type PhysicianListProps = InferProps<typeof PhysicianListPropTypes> & ComponentProp;

const PhysicianListTable: FC<PhysicianListProps> = ({
  physicians,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const mapStatusTags = (tags: any) => {
    return tags.map((tag: any) => {
      switch(tag) {
        //blocked
        case TAG_STATUS_MAPPING[0].key: {
          return <Tag color={'red'}>{TAG_STATUS_MAPPING[0].value}</Tag>
        }
        default: {
          return <></>
        }
      }
    })
  }

  const columns = [
    { title: 'E-mail', dataIndex: 'email', key: 'email' },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text: any, record: any) => <>{record.profile.lastName}</>,
    },
    {
      title: 'Rest of Name',
      dataIndex: 'restOfName',
      key: 'restOfName',
      render: (text: any, record: any) => <>{record.profile.restOfName}</>,
    },
    {
      title: 'Action Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (text: any, record: any) => <>{mapStatusTags(record.minicex.tags.actionTags)}</>
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      render: (text: any, record: any) => (
        <>
          <Button
            type="primary"
            onClick={() => {
              navigate(`${location.pathname}/physician-detail/${record.email}`);
            }}
          >
            View
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={physicians as any}></Table>
    </>
  );
};

export default {
  Component: PhysicianListTable
};
