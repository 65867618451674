import { Button, Table, Modal } from 'antd';
import { InferProps } from 'prop-types';
import { FC, useEffect, useState } from 'react';
import { ADMIN_ALLOWED_ACTIONS, PATHWAY6_SCORE_CATEGORIES } from '../../../../constants';
import CSS from 'csstype';
import {
  MiniCexResponseType,
  PhysicianResponseType,
  Pathway6MiniCexType,
} from '../../../../../generated';
import { getMiniCEXPDF } from './pathway6-pdf-helpers';

enum ROW_TYPE {
  AVERAGE_ROW = 'Average',
  INDIVIDUAL_ROW = 'Individual Row',
}

const HeaderStyle: CSS.Properties = {
  fontSize: '0.7rem',
  textAlign: 'center',
};

const PASSED_COLOR = 'lightgrey';
const FAILURE_COLOR = '#d11d5c';
const MAX_MINI_CEX = 6;

const Pathway6MiniCEXScoresTablePropTypes = {};

export interface ComponentProps {
  minicexList: MiniCexResponseType[];
  physicianByMiniCEX: PhysicianResponseType[];
  applicantProvidedData: Pathway6MiniCexType[];
}

export type Pathway6MiniCEXScoresTableProps = InferProps<
  typeof Pathway6MiniCEXScoresTablePropTypes
> &
  ComponentProps;

const getScoreCell = (recordCategories: any, category: string, rowType: ROW_TYPE) => {
  const score = recordCategories?.find((item: { name: string }) => item.name === category)?.score;
  if (score === undefined) {
    return (
      <div
        style={{
          textAlign: 'center',
          borderRadius: '10px',
          margin: 'auto',
          height: '1.5rem',
          lineHeight: '1.5rem',
        }}
      ></div>
    );
  }
  let color = PASSED_COLOR;
  if (
    score < 4 &&
    (category === PATHWAY6_SCORE_CATEGORIES.COMMUNICATION_PROFESSIONALISM ||
      rowType === ROW_TYPE.AVERAGE_ROW)
  ) {
    color = FAILURE_COLOR;
  }

  if (rowType === ROW_TYPE.AVERAGE_ROW) {
    return (
      <div
        style={{
          textAlign: 'center',
          backgroundColor: color,
          borderRadius: '10px',
          margin: 'auto',
          height: '1.5rem',
          lineHeight: '1.5rem',
          fontWeight: 'bold',
        }}
      >
        <p>{score}</p>
      </div>
    );
  } else {
    return (
      <div
        style={{
          textAlign: 'center',
          backgroundColor: color,
          color: color === FAILURE_COLOR ? 'white' : 'black',
          borderRadius: '10px',
          margin: 'auto',
          height: '1.5rem',
          lineHeight: '1.5rem',
        }}
      >
        <p>{score}</p>
      </div>
    );
  }
};

const Pathway6MiniCEXScoreTable: FC<Pathway6MiniCEXScoresTableProps> = ({
  minicexList,
  applicantProvidedData,
  physicianByMiniCEX,
}) => {
  const [miniCEXList, setMiniCEXList] = useState<any>();
  const [isReadOnlyMiniCEXVisible, setIsReadOnlyMiniCEXVisible] = useState(false);
  const [readOnlyMiniCEXData, setReadOnlyMiniCEXData] = useState<any>();
  const [currentPhysicianData, setCurrentPhysicianData] = useState<any>();

  useEffect(() => {
    if (minicexList) {
      //Show only rows that are completed
      var newList = minicexList?.filter((item) => item?.summary?.state === 'MINICEX_COMPLETED');
      var miniCEXCompletedCount = newList.length;

      // add empty rows (if neccessary)
      if (miniCEXCompletedCount < MAX_MINI_CEX) {
        for (let i = 0; i < MAX_MINI_CEX - miniCEXCompletedCount; i++) {
          let emptyRow = {
            encounterId: '',
            form: {
              evaluation: {
                categories: [
                  {
                    name: PATHWAY6_SCORE_CATEGORIES.MEDICAL_INTERVIEWING,
                    score: undefined,
                  },
                  {
                    name: PATHWAY6_SCORE_CATEGORIES.PHYSICAL_EXAMINATION,
                    score: undefined,
                  },
                  {
                    name: PATHWAY6_SCORE_CATEGORIES.COMMUNICATION_PROFESSIONALISM,
                    score: undefined,
                  },
                  {
                    name: PATHWAY6_SCORE_CATEGORIES.CLINICAL_REASONING,
                    score: undefined,
                  },
                ],
              },
            },
          };
          newList.push(emptyRow);
        }
      }

      if (applicantProvidedData?.length > 0) {
        if (miniCEXCompletedCount === MAX_MINI_CEX) {
          // average row
          let avgRow = {
            encounterId: ROW_TYPE.AVERAGE_ROW,
            form: {
              evaluation: {
                categories: [
                  {
                    name: PATHWAY6_SCORE_CATEGORIES.MEDICAL_INTERVIEWING,
                    score: setAvgScores(
                      applicantProvidedData[0],
                      PATHWAY6_SCORE_CATEGORIES.MEDICAL_INTERVIEWING
                    ),
                  },
                  {
                    name: PATHWAY6_SCORE_CATEGORIES.PHYSICAL_EXAMINATION,
                    score: setAvgScores(
                      applicantProvidedData[0],
                      PATHWAY6_SCORE_CATEGORIES.PHYSICAL_EXAMINATION
                    ),
                  },
                  {
                    name: PATHWAY6_SCORE_CATEGORIES.COMMUNICATION_PROFESSIONALISM,
                    score: setAvgScores(
                      applicantProvidedData[0],
                      PATHWAY6_SCORE_CATEGORIES.COMMUNICATION_PROFESSIONALISM
                    ),
                  },
                  {
                    name: PATHWAY6_SCORE_CATEGORIES.CLINICAL_REASONING,
                    score: setAvgScores(
                      applicantProvidedData[0],
                      PATHWAY6_SCORE_CATEGORIES.CLINICAL_REASONING
                    ),
                  },
                ],
              },
            },
          };
          newList?.push(avgRow);
        }
      }
      setMiniCEXList(newList);
    }
  }, [minicexList]);

  const getCurrentPhysicianByEmail = (email: any) => {
    setCurrentPhysicianData(physicianByMiniCEX?.find((item) => item?.email === email));
  };

  const setAvgScores = (applicantProvData: any, pathwayScoreCategory: any) => {
    var avgScore = applicantProvData?.assessment?.categories?.find(
      (item: any) => item.name === pathwayScoreCategory
    )?.avgScore;
    if (avgScore !== undefined) {
      return avgScore;
    } else {
      return undefined;
    }
  };

  const getReadOnlyMiniCEXData = (index: any) => {
    setReadOnlyMiniCEXData(miniCEXList[index]);
  };

  const renderButtons = (allowedActions: any, record: any, index: any) => {
    if (allowedActions?.includes(ADMIN_ALLOWED_ACTIONS.VIEW)) {
      return (
        <Button
          onClick={() => {
            getCurrentPhysicianByEmail(record.summary.physicianEmail);
            getReadOnlyMiniCEXData(index);
            setIsReadOnlyMiniCEXVisible(true);
          }}
          type="primary"
          style={{ width: '100%' }}
        >
          View
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const columns = [
    {
      title: <div style={HeaderStyle}>ID</div>,
      dataIndex: 'encounterId',
      key: 'encounterId',
      render: (text: any, record: any) => {
        if (record.encounterId === ROW_TYPE.AVERAGE_ROW) {
          return (
            <div style={{ textAlign: 'center' }}>
              <b>{record.encounterId}</b>
            </div>
          );
        }
        return <div style={{ textAlign: 'center' }}>{record.encounterId}</div>;
      },
    },
    {
      title: <div style={HeaderStyle}>Interviewing</div>,
      dataIndex: PATHWAY6_SCORE_CATEGORIES.MEDICAL_INTERVIEWING,
      key: PATHWAY6_SCORE_CATEGORIES.MEDICAL_INTERVIEWING,
      render: (text: any, record: any) =>
        getScoreCell(
          record.form.evaluation.categories,
          PATHWAY6_SCORE_CATEGORIES.MEDICAL_INTERVIEWING,
          record.encounterId === ROW_TYPE.AVERAGE_ROW
            ? ROW_TYPE.AVERAGE_ROW
            : ROW_TYPE.INDIVIDUAL_ROW
        ),
    },
    {
      title: <div style={HeaderStyle}>Physical</div>,
      dataIndex: PATHWAY6_SCORE_CATEGORIES.PHYSICAL_EXAMINATION,
      key: PATHWAY6_SCORE_CATEGORIES.PHYSICAL_EXAMINATION,
      render: (text: any, record: any) =>
        getScoreCell(
          record.form.evaluation.categories,
          PATHWAY6_SCORE_CATEGORIES.PHYSICAL_EXAMINATION,
          record.encounterId === ROW_TYPE.AVERAGE_ROW
            ? ROW_TYPE.AVERAGE_ROW
            : ROW_TYPE.INDIVIDUAL_ROW
        ),
    },
    {
      title: <div style={HeaderStyle}>Communication</div>,
      dataIndex: PATHWAY6_SCORE_CATEGORIES.COMMUNICATION_PROFESSIONALISM,
      key: PATHWAY6_SCORE_CATEGORIES.COMMUNICATION_PROFESSIONALISM,
      render: (text: any, record: any) =>
        getScoreCell(
          record.form.evaluation.categories,
          PATHWAY6_SCORE_CATEGORIES.COMMUNICATION_PROFESSIONALISM,
          record.encounterId === ROW_TYPE.AVERAGE_ROW
            ? ROW_TYPE.AVERAGE_ROW
            : ROW_TYPE.INDIVIDUAL_ROW
        ),
    },

    {
      title: <div style={HeaderStyle}>Reasoning</div>,
      dataIndex: PATHWAY6_SCORE_CATEGORIES.CLINICAL_REASONING,
      key: PATHWAY6_SCORE_CATEGORIES.CLINICAL_REASONING,
      render: (text: any, record: any) =>
        getScoreCell(
          record.form.evaluation.categories,
          PATHWAY6_SCORE_CATEGORIES.CLINICAL_REASONING,
          record.encounterId === ROW_TYPE.AVERAGE_ROW
            ? ROW_TYPE.AVERAGE_ROW
            : ROW_TYPE.INDIVIDUAL_ROW
        ),
    },

    {
      title: <div style={HeaderStyle}>Action</div>,
      dataIndex: 'allowedActions',
      key: 'allowedActions',
      render: (allowedActions: any, record: any, index: any) =>
        renderButtons(record?.summary?.allowedActions, record, index),
    },
  ];
  return (
    <>
      <Table
        tableLayout="fixed"
        pagination={false}
        columns={columns}
        dataSource={miniCEXList as any}
        size="small"
        bordered
        className="ecfmg-small-table-pathway6"
      />
      <p style={{ fontSize: '11px', marginTop: '10px' }}>
        All times are calculated using Coordinated Universal Time (UTC)
      </p>
      <Modal
        title="Review Completed Mini-CEX"
        visible={isReadOnlyMiniCEXVisible}
        width="fit-content"
        bodyStyle={{ minWidth: '30vw' }}
        onCancel={() => {
          setIsReadOnlyMiniCEXVisible(false);
        }}
        destroyOnClose
        footer={[]}
      >
        {getMiniCEXPDF(readOnlyMiniCEXData, currentPhysicianData)}
      </Modal>
    </>
  );
};

export default Pathway6MiniCEXScoreTable;
