import { FC } from 'react';
import React from 'react';
import { Button, Select, Checkbox } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import {
  CUSTOMIZE_VIEWS_LABEL,
  DEFAULT_VIEW_TEXT,
  NUMBER_OF_RECORDS_PER_PAGE,
  DEFAULT_VIEW_ID,
} from '../../constants';

const { Option } = Select;

const CaseListFilterPropsTypes = {
  numberOfRecordsPerPage: PropTypes.number.isRequired,
  onNumberofRecordsPerPageChangeCallback: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  changeView: PropTypes.func.isRequired,
  initialViewList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      viewName: PropTypes.string,
      viewFilters: PropTypes.shape({
        caseState: PropTypes.arrayOf(PropTypes.string),
        pathwayEligibilityStatus: PropTypes.arrayOf(PropTypes.string),
        englishEligibilityStatus: PropTypes.arrayOf(PropTypes.string),
        generalEligibilityStatus: PropTypes.arrayOf(PropTypes.string),
        pathway: PropTypes.arrayOf(PropTypes.string),
      }).isRequired,
    }).isRequired
  ),
  defaultSelectValue: PropTypes.string,
  assignedToMeBoolean: PropTypes.bool.isRequired,
  updateAssignedToMeBoolean: PropTypes.func.isRequired,
};

export type CaseListFilterProps = InferProps<typeof CaseListFilterPropsTypes>;

const CaseListFilter: FC<CaseListFilterProps> = ({
  initialViewList,
  defaultSelectValue,
  showModal,
  changeView,
  assignedToMeBoolean,
  updateAssignedToMeBoolean,
  numberOfRecordsPerPage,
  onNumberofRecordsPerPageChangeCallback,
}) => {
  const onChangeChangeView = async (viewId: any) => {
    if (viewId === defaultSelectValue) return;
    changeView(viewId); // call the callback to make UI update on case-list-layout
  };

  const onChangeAssignedToMe = async () => {
    await updateAssignedToMeBoolean(!assignedToMeBoolean);
  };

  const onNumberofRecordsPerPageChange = async (value: any) => {
    if (value === numberOfRecordsPerPage) return;
    onNumberofRecordsPerPageChangeCallback(value);
  };

  return (
    <>
      <div
        style={{
          minWidth: '100%',
          padding: '10px',
          backgroundColor: '#fafafa',
        }}
      >
        <table style={{ minWidth: '100%' }}>
          <tr>
            <td>
              <Button onClick={(e) => showModal()} size="small">
                {CUSTOMIZE_VIEWS_LABEL}
              </Button>
            </td>
            <td>
              <Checkbox
                checked={assignedToMeBoolean}
                defaultChecked={assignedToMeBoolean}
                onChange={() => onChangeAssignedToMe()}
              >
                View My Cases
              </Checkbox>
            </td>
            <td style={{ textAlign: 'right' }}>
              {`View: `}&nbsp;
              <Select
                defaultValue={defaultSelectValue as string | undefined}
                style={{ maxWidth: 300 }}
                onChange={onChangeChangeView}
                value={defaultSelectValue as string | undefined}
                size="small"
                dropdownMatchSelectWidth={false}
              >
                <Option value={DEFAULT_VIEW_ID}>{DEFAULT_VIEW_TEXT}</Option>
                {initialViewList && initialViewList.length > 0
                  ? initialViewList.map((view: any) => {
                      //Assume there is only one dashboard in the "views" element
                      return (
                        <Option value={view._id} key={view._id}>
                          {view.viewName}
                        </Option>
                      );
                    })
                  : null}
                {/* DEFAULT  VIEWS ARE COMMON ACROSS ALL USERS, SAVED VIEWS ARE ONLY FOR THE LOGGED IN USER, THEY ARE LISTED TOGETHER IN THIS DROPDOWN */}
              </Select>
            </td>
            <td style={{ textAlign: 'right', width: 'auto' }}>
              {`Records per page: `}
              <Select
                style={{ width: 'auto' }}
                defaultValue={numberOfRecordsPerPage ?? NUMBER_OF_RECORDS_PER_PAGE[0].value}
                value={numberOfRecordsPerPage ?? NUMBER_OF_RECORDS_PER_PAGE[0].value}
                onChange={(e) => onNumberofRecordsPerPageChange(e)}
                size="small"
              >
                {NUMBER_OF_RECORDS_PER_PAGE.map((e: any) => {
                  return (
                    <Option value={e.key} key={e.key}>
                      {e.value}
                    </Option>
                  );
                })}
              </Select>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default {
  Component: CaseListFilter,
};
