import React, { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Descriptions } from 'antd';
import { PATHWAY6_DATE_FORMAT } from '../../../../../constants';
import moment from 'moment';

const labelStyle = {
  fontWeight: 900,
  fontSize: '0.8rem',
};
const Pathway6RejectMiniCEXRequestPropTypes = {
  currentRecord: PropTypes.shape({
    encounterId: PropTypes.string.isRequired,
    usmleId: PropTypes.string.isRequired,
    applicantLastName: PropTypes.string.isRequired,
    applicantRestOfName: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
};
export type Pathway6RejectMiniCEXRequestProps = InferProps<
  typeof Pathway6RejectMiniCEXRequestPropTypes
>;
const Pathway6RejectMiniCEXRequest: FC<Pathway6RejectMiniCEXRequestProps> = ({ currentRecord }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <p>Are you sure you want to reject this Mini-CEX Request?</p>
      <div style={{ border: '2px solid', padding: '5px' }}>
        <Descriptions layout="vertical" column={2} size="small">
          <Descriptions.Item
            labelStyle={labelStyle}
            label="Applicant"
          >{`${currentRecord?.applicantLastName}, ${currentRecord?.applicantRestOfName}`}</Descriptions.Item>
          <Descriptions.Item labelStyle={labelStyle} label="USMLEID">
            {currentRecord?.usmleId}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions layout="vertical" column={2} size="small">
          <Descriptions.Item labelStyle={labelStyle} label="Encounter ID">
            {currentRecord?.encounterId}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={labelStyle} label="Date Requested">
            {currentRecord?.createdAt !== undefined
              ? moment(currentRecord?.createdAt).utc().format(PATHWAY6_DATE_FORMAT)
              : ''}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default Pathway6RejectMiniCEXRequest;
