import { FC } from 'react';
import ArchivedCaseList from './archived-case-list';
import { useQuery } from '@apollo/client';
import { ArchivedCaseListContainerArchivedCasesDocument } from '../../../generated';
import { useParams } from 'react-router-dom';
import { ComponentQueryLoader } from '../../../utils/molecules/component-query-loader';
import { Alert } from 'antd';

const ArchivedCaseListContainer: FC<any> = () => {
  const { usmleId } = useParams();
  const {
    loading: casesLoading,
    error: casesError,
    data: casesData,
  } = useQuery(ArchivedCaseListContainerArchivedCasesDocument, {
    variables: {
      filter: {
        usmleId: usmleId ?? '',
      },
    },
  });

  return (
    <ComponentQueryLoader
      loading={casesLoading}
      hasData={casesData?.archivedCases !== undefined}
      hasDataComponent={<ArchivedCaseList data={casesData?.archivedCases} />}
      error={casesError}
      errorComponent={<Alert message={casesError?.message} type="error" />}
    />
  );
};

export default ArchivedCaseListContainer;
