import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Divider, Input, message, Modal, Row, Spin, Typography } from 'antd';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActivityLogType,
  CaseMgmtPathway6DocumentReviewLayoutAddAndDeleteDocumentDocument,
  CaseMgmtPathway6DocumentReviewLayoutCasePortalDocument,
  CaseMgmtPathway6DocumentReviewLayoutPhysicianByMiniCexDocument,
  CaseMgmtPathway6DocumentReviewLayoutUnlockReviewDocument,
  CaseSummaryType,
  MiniCexChecklist,
  MiniCexResponseType,
  Pathway6MiniCexType,
  PhysicianResponseType,
  UiConfigRecordType,
  UiPortalConfigType,
} from '../../../../../generated';
import { PATHWAY6_RESPONSE_TYPE } from '../../../../constants';
import { case_ } from '../../../../schema/casePortal-case';
import { physicianByMiniCEX } from '../../../../schema/casePortal-physicianByMiniCEX';
import ActivityFeed from '../../../activity-feed';
import { AdditionalDocumentCardList } from '../../additional-document/additional-document-card-list';
import ApplicantSummaryFactory from '../../applicant-summary/applicant-summary-factory';
import { UploadDocument } from '../../upload-document/upload-document';
import Pathway6DocumentReviewApplicantDetails from './pathway6-document-review-applicant-details';
import Pathway6DocumentReviewChecklistFactory from './checklist-versions/pathway6-document-review-checklist-factory';
import Pathway6TableLayout from './pathway6-table-layout';
const { Title } = Typography;

export interface CaseComponentProp {
  casePortal: {
    case: case_;
  };
}

export interface PhysicianByMiniCEXComponentProp {
  casePortal: {
    physicianByMiniCEX: physicianByMiniCEX[];
  };
}

const Pathway6DocumentReviewLayout: FC<any> = (props) => {
  const navigate = useNavigate();
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);

  const { usmleId, documentId } = useParams<any>();
  const [selectedDocument, setSelectedDocument] =
    useState<Pathway6MiniCexType | undefined>(undefined);
  const [responseType, setResponseType] = useState<string>(PATHWAY6_RESPONSE_TYPE.ACTIVE);

  const [unlockChecklist, { loading: unlockChecklistLoading, error: unlockChecklistError }] =
    useMutation(CaseMgmtPathway6DocumentReviewLayoutUnlockReviewDocument);

  const [confirmUSMLEIDText, setConfirmUSMLEIDText] = useState<string>('');
  const [isUnlockChecklistModalVisible, setIsUnlockChecklistModalVisible] =
    useState<boolean>(false);

  const {
    loading: pathway6Loading,
    error: pathway6Error,
    data: pathway6Data,
    refetch,
  } = useQuery(CaseMgmtPathway6DocumentReviewLayoutCasePortalDocument, {
    variables: {
      usmleId: usmleId,
      filter: {
        usmleId: usmleId as string,
        responseType: responseType,
      },
    },
  });
  const [addAndDeleteAdditionalDocumentsMutation] = useMutation(
    CaseMgmtPathway6DocumentReviewLayoutAddAndDeleteDocumentDocument,
    {
      refetchQueries: [
        {
          query: CaseMgmtPathway6DocumentReviewLayoutCasePortalDocument,
          variables: {
            usmleId: usmleId,
            filter: {
              usmleId: usmleId,
              responseType: responseType,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const {
    loading: physicianLoading,
    error: physicianError,
    data: physicianData,
    refetch: physicianRefetch,
  } = useQuery(CaseMgmtPathway6DocumentReviewLayoutPhysicianByMiniCexDocument, {
    variables: {
      filter: {
        usmleId: usmleId as string,
        responseType: responseType,
      },
    },
  });

  useEffect(() => {
    if (pathway6Data) {
      const applicantProvidedData =
        pathway6Data?.casePortal?.case?.pathway6?.applicantProvidedData ?? [];
      const staffProvidedData = pathway6Data?.casePortal?.case?.pathway6?.staffProvidedData ?? [];
      const joinData = applicantProvidedData?.concat(staffProvidedData) ?? [];
      const tempSelectedDoc =
        (joinData?.find((record) => record?._id === documentId) as Pathway6MiniCexType) ??
        undefined;
      setSelectedDocument(tempSelectedDoc);
    }
    if (pathway6Data?.casePortal?.case?.caseSummary?.dataVersion) {
      setDataVersion(pathway6Data.casePortal.case.caseSummary.dataVersion);
    }

    const retrievedAdditionalDocuments: any = [];
    _.forEach(pathway6Data?.casePortal?.case?.additionalDocuments, (element) => {
      retrievedAdditionalDocuments.push(element?.document);
    });
    setAdditionalDocuments(retrievedAdditionalDocuments);
  }, [pathway6Data]);

  const disableDocumentUpload: boolean =
    selectedDocument?.review?.status?.toUpperCase() === 'APPROVED' ||
    selectedDocument?.review?.status?.toUpperCase() === 'REJECTED' ||
    pathway6Data?.casePortal?.case?.caseSummary?.pathwayEligibilityStatus?.toUpperCase() ===
      'COMPLETED';

  //Get the blob name for azure storage by saving in mongo
  async function getBlobname(filename: string, docType: string) {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));
    var uniqueDocIds: any = [];
    deepCopyDocuments.map((doc: any) => {
      uniqueDocIds.push(doc.docId);
      delete doc.__typename;
    });
    const documentToUpload = {
      docType: docType,
      title: filename,
    };
    deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
      return {
        document: element,
      };
    });
    deepCopyDocuments.push({ document: documentToUpload });
    return addAndDeleteAdditionalDocumentsMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        input: deepCopyDocuments,
      },
    })
      .then((data) => {
        setDataVersion(data?.data?.CasePortal_additionalDocuments?.caseSummary?.dataVersion);
        const addedDocuments = data?.data?.CasePortal_additionalDocuments?.additionalDocuments; //debug this

        var docs: any = [];
        _.forEach(addedDocuments, (data) => {
          docs.push(data?.document);
        });
        var thisDocGuid = '';
        docs.map((doc: any) => {
          //The most recent document won't exist in the uniqueDocIds, so that is the one for this document
          if (!uniqueDocIds.includes(doc.docId)) {
            thisDocGuid = doc.docId;
          }
        });
        setAdditionalDocuments(docs);
        refetch();
        return thisDocGuid;
      })
      .catch((error) => {
        refetch();
        console.log(error);
        return 'error';
      });
  }

  const onUpdateDataVersionFromChild = async (dataVersion: number) => {
    setDataVersion(dataVersion);
  };

  const updateResponseType = (responseType: string) => {
    setResponseType(responseType);
  };
  const onDeleteDocument = async (docId: any) => {
    var deepCopyDocuments = JSON.parse(JSON.stringify(additionalDocuments));

    deepCopyDocuments = _.remove(deepCopyDocuments, (document: any) => {
      return document.docId !== docId;
    });

    deepCopyDocuments.map((doc: any) => {
      delete doc.__typename;
    });

    deepCopyDocuments = _.map(deepCopyDocuments, (element) => {
      return {
        document: element,
      };
    });
    return addAndDeleteAdditionalDocumentsMutation({
      variables: {
        usmleId: usmleId as string,
        dataVersion: dataVersion!,
        input: deepCopyDocuments,
      },
    })
      .then((res: any) => {
        message.success('Delete successfully!');
        refetch();
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        refetch();
      });
  };

  const onUnlockChecklist = async () => {
    if (!usmleId || !dataVersion || !documentId) {
      console.error('Missing required data to unlock checklist');
      message.error('Error unlocking checklist');
      return;
    }

    if (confirmUSMLEIDText.trim() !== usmleId) {
      if (confirmUSMLEIDText.trim().length > 0) {
        message.error('Applicant USMLE ID does not match');
      } else {
        message.error('Please confirm Applicant USMLE ID');
      }
      return;
    }

    unlockChecklist({
      variables: {
        usmleId: usmleId,
        dataVersion: dataVersion,
        recordId: documentId,
      },
    })
      .then(() => {
        message.success('Checklist unlocked successfully');
        refetch();
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        refetch();
      })
      .finally(() => {
        setIsUnlockChecklistModalVisible(false);
        onCloseConfirmPathwayModal();
      });
  };

  const onCloseConfirmPathwayModal = () => {
    setConfirmUSMLEIDText('');
  };

  return (
    <>
      <Helmet>
        <title>Pathway 6 Review</title>
      </Helmet>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xl={14}>
          {!!pathway6Data?.casePortal?.case?.applicant &&
          !!pathway6Data?.casePortal?.case?.caseSummary ? (
            <Pathway6DocumentReviewApplicantDetails.Component
              applicant={pathway6Data.casePortal.case.applicant}
              caseSummary={pathway6Data.casePortal.case.caseSummary}
            />
          ) : (
            <Spin />
          )}
          <Divider plain orientation="center" style={{ marginBottom: '-5px' }}>
            <b>Mini CEX</b>
          </Divider>

          <Pathway6TableLayout.Component
            minicexList={
              pathway6Data?.casePortal?.case?.pathway6?.minicexList as MiniCexResponseType[]
            }
            physicianByMiniCEX={
              physicianData?.casePortal?.physicianByMiniCEX as PhysicianResponseType[]
            }
            applicantProvidedData={
              [selectedDocument] as Pathway6MiniCexType[]
            }
            uiConfig={pathway6Data?.casePortal?.case?.uiConfig as UiConfigRecordType}
            updateResponseType={(responseType: string) => updateResponseType(responseType)}
          />
          <Divider plain orientation="center">
            <b>Additional Documents</b>
          </Divider>
          <Row>
            <Col span={23}>
              {additionalDocuments.length === 0 ? (
                <div>There is no additional document added.</div>
              ) : (
                <AdditionalDocumentCardList
                  numberOfCardsPerRow={3}
                  documents={additionalDocuments}
                  disabled={disableDocumentUpload}
                  onDelete={(docId) => onDeleteDocument(docId)}
                />
              )}
              <UploadDocument
                getGuid={(filename: any) => getBlobname(filename, 'Additional Document')}
                showIcon={false}
                label="Add Document"
                //disabled because we want to allow document uploads at all times for supporting docs. Ex: refund supporting docs
                // disabled={true}
              />
            </Col>
          </Row>
          <Divider plain orientation="center">
            <b>Applicant Provided Information (read only)</b>
          </Divider>

          <ApplicantSummaryFactory
            usmleID={usmleId}
            versionConfig={pathway6Data?.casePortal?.case?.uiConfig}
          ></ApplicantSummaryFactory>
        </Col>

        <Col md={24} lg={24} xl={10}>
          <div>
            {!!pathway6Data?.casePortal?.case?.caseSummary &&
            !!pathway6Data?.casePortal?.case?.applicant &&
            !!pathway6Data?.casePortal?.case?.uiConfig ? (
              <Pathway6DocumentReviewChecklistFactory
                caseSummary={pathway6Data.casePortal.case.caseSummary}
                checklist={selectedDocument?.review?.checklist as MiniCexChecklist}
                checklistState={selectedDocument?.review?.checklistState as string}
                recordId={documentId as string}
                parentDataVersion={dataVersion as number}
                onUpdateDataVersion={(dataVersion: number) => {
                  onUpdateDataVersionFromChild(dataVersion);
                }}
                applicant={pathway6Data.casePortal.case.applicant}
                redirect={(url: string) => {
                  navigate(url);
                }}
                checklistStatus={selectedDocument?.review?.status as string}
                isLocked={selectedDocument?.review?.isLocked ?? true}
                versionConfig={pathway6Data?.casePortal?.case?.uiConfig as UiPortalConfigType}
                onUnlockChecklist={() => setIsUnlockChecklistModalVisible(true)}
              />
            ) : null}
            <br />
            <Title level={3}>Activity Feed</Title>
            <hr style={{ borderTop: '1px black solid' }} />

            {!!pathway6Data?.casePortal?.case?.activityLog &&
            !!pathway6Data?.casePortal?.case?.caseSummary ? (
              <ActivityFeed.Component
                activityLog={pathway6Data.casePortal.case.activityLog as ActivityLogType[]}
                caseSummary={pathway6Data.casePortal.case.caseSummary}
                parentDataVersion={dataVersion}
                onUpdateDataVersion={(dataVersion: number) => {
                  onUpdateDataVersionFromChild(dataVersion);
                }}
                refetch={() => {
                  refetch();
                }}
              />
            ) : null}
          </div>
        </Col>
      </Row>

      {/* unlock checklist confirm modal */}
      <Modal
        title={`Confirm Unlock Checklist`}
        visible={isUnlockChecklistModalVisible}
        closable={false}
        onCancel={() => {
          setIsUnlockChecklistModalVisible(false);
          onCloseConfirmPathwayModal();
        }}
        destroyOnClose
        footer={[
          <Button type="primary" onClick={onUnlockChecklist}>
            Confirm
          </Button>,
          <Button
            onClick={() => {
              setIsUnlockChecklistModalVisible(false);
              onCloseConfirmPathwayModal();
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <Row>
          <Col>{`USMLE ID: ${usmleId}`}</Col>
        </Row>
        <Row>
          <Col>{'Enter Applicant USMLE ID to confirm:'}</Col>
        </Row>
        <Row>
          <Col>
            <Input
              type="text"
              value={confirmUSMLEIDText}
              onChange={(e) => setConfirmUSMLEIDText(e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Pathway6DocumentReviewLayout;
