import { Table } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { MiniCexResponseType, PhysicianResponseType } from '../../../../../generated';
import { CEX_STATUS } from '../../../../constants';
import { Pathway6ArchivedCaseAssessmentsViewMiniCexButton } from './pathway6-archived-case-assessments-view-minicex-button';

export interface Pathway6ArchivedCaseAssessmentsMiniCEXInvalidTableDataType {
  id: string;
  nameFromPhysician: string;
  dateRequested: string;
  actionDue: string;
  status: string;
  rejectionReason: string;
  physicianEmail: string;
}
interface Pathway6ArchivedCaseAssessmentsMiniCEXInvalidTableProps {
  tableData: Pathway6ArchivedCaseAssessmentsMiniCEXInvalidTableDataType[];
  minicexList: MiniCexResponseType[];
  physicianByMiniCEX: PhysicianResponseType[];
}
export const Pathway6ArchivedCaseAssessmentsMiniCEXInvalidTable: FC<Pathway6ArchivedCaseAssessmentsMiniCEXInvalidTableProps> =
  (props) => {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Name from Physician',
        dataIndex: 'nameFromPhysician',
        key: 'nameFromPhysician',
      },
      {
        title: 'Date Requested',
        dataIndex: 'dateRequested',
        key: 'dateRequested',
        render: (text: string) => {
          return text ? dayjs(text).format('MM/DD/YYYY') : 'N/A';
        },
      },
      {
        title: 'Action Due',
        dataIndex: 'actionDue',
        key: 'actionDue',
        render: (text: string) => {
          return text ? dayjs(text).format('MM/DD/YYYY') : 'N/A';
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => {
          return (CEX_STATUS as any)[text];
        },
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        render: (
          _: any,
          record: Pathway6ArchivedCaseAssessmentsMiniCEXInvalidTableDataType,
          index: any
        ) => (
          <Pathway6ArchivedCaseAssessmentsViewMiniCexButton
            readOnlyMiniCEXData={props.minicexList[index]}
            currentPhysicianData={
              props.physicianByMiniCEX?.find(
                (item) => item?.email === record?.physicianEmail
              ) as PhysicianResponseType
            }
            rejectionReason={record.rejectionReason}
          />
        ),
      },
    ];
    return (
      <>
        <Table columns={columns} dataSource={props.tableData} pagination={false} size="small" />
      </>
    );
  };
