import { Layout, Menu } from 'antd';
import { FC } from 'react';
import ECFMGLogo from '../../logos/ECFMG_NoTag_WHITE_TM_forSmall.svg';

const { Header } = Layout;

export const MedicalSchoolOfficialsHeader: FC<any> = () => {
  return (
    <Header style={{ zIndex: 1, width: '100%', padding: '0 10px' }}>
      <img className="logo" src={ECFMGLogo} alt="" />
      <Menu theme="dark" mode="horizontal" selectable={false}>
        <Menu.Item key="1" style={{ marginLeft: 'auto' }}>
          {sessionStorage.getItem('msal.idtoken') ? 'Logout' : 'Login'}
        </Menu.Item>
      </Menu>
    </Header>
  );
};
