import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client/react';
import { Divider, Space, Spin } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import {
  CaseMgmtApplicantSummaryCasePortalDocument,
  CaseMgmtApplicantSummarySubmissionSummaryDocument,
  CaseSummaryType,
  Pathway345,
} from '../../../../../generated';
import { getSummarySectionIndication, SummarySectionsIndication } from '../../../../constants';
import { useFeatureFlags } from '../../../../feature-flag-react-lite';
import { case_ } from '../../../../schema/casePortal-case';
import { ApplicationSummaryPDFButton } from '../application-summary-pdf-button';
import QualificationReviewCSExam2 from '../qualification-review-csExam-not-recent-past';
import {
  default as QualificationReviewCSExamV5,
  default as QualificationReviewCsExamV5,
} from '../qualification-review-csExamV5';
import QualificationReviewEducationV5 from '../qualification-review-educationV5';
import QualificationReviewException from '../qualification-review-exception';
import QualificationReviewLanguageAssessment from '../qualification-review-languageAssessment';
import QualificationReviewLicenseV5 from '../qualification-review-licenseV5';
import QualificationReviewPathway6 from '../qualification-review-pathway6';

const PATHWAY_NAME = {
  PathwayX: 'PATHWAYX',
  Pathway1: 'PATHWAY1',
  Pathway2: 'PATHWAY2',
  Pathway3: 'PATHWAY3',
  Pathway4: 'PATHWAY4',
  Pathway5: 'PATHWAY5',
  Pathway6: 'PATHWAY6',
};

export interface ComponentProp {
  casePortal: {
    case: case_;
  };
}

const ApplicantSummaryV5: FC<any> = (props: any) => {
  const { loading, error, data } = useQuery(CaseMgmtApplicantSummaryCasePortalDocument, {
    variables: { usmleId: props.usmleID },
  });
  const [getApplicantSummaryPDF] = useMutation(CaseMgmtApplicantSummarySubmissionSummaryDocument);
  const { GetFeatureFlagByName } = useFeatureFlags();
  const [summarySectionIndication, setSummarySectionIndication] =
    useState<SummarySectionsIndication | undefined>(undefined);

  useEffect(() => {
    if (data) {
      let isStep2CSFailed = data?.casePortal?.case?.applicant?.isStep2CSFailed;
      let schoolPathway =
        data?.casePortal?.case?.applicant?.graduation?.schoolEligibility?.schoolPathway;
      let isYearEligible = data?.casePortal?.case?.applicant?.graduation?.isYearEligible;
      setSummarySectionIndication(
        getSummarySectionIndication(isStep2CSFailed, schoolPathway, isYearEligible)
      );
    }
  }, [data]);
  if (error) {
    console.log('ERROR FETCHING DATA', error);
    return <div>{'ErrorX :( --> (applicant-summary)'}</div>;
  }

  const GetPathwaySection = (submittedPathway: string) => {
    if (submittedPathway) {
      //exception
      if (submittedPathway === PATHWAY_NAME.PathwayX) {
        return (
          <QualificationReviewException.Component pathwayX={data?.casePortal?.case?.pathwayX} />
        );
      }
      //license - pathway1
      if (submittedPathway === PATHWAY_NAME.Pathway1) {
        return (
          <QualificationReviewLicenseV5.Component pathway1={data?.casePortal?.case?.pathway1} />
        );
      }
      //cs exam - pathway2
      if (submittedPathway === PATHWAY_NAME.Pathway2) {
        return (
          <>
            <QualificationReviewLicenseV5.Component pathway1={data?.casePortal?.case?.pathway1} />
            <QualificationReviewCsExamV5.Component pathway2={data?.casePortal?.case?.pathway2} />
          </>
        );
      }
      //education - path345
      if (
        submittedPathway === PATHWAY_NAME.Pathway3 ||
        submittedPathway === PATHWAY_NAME.Pathway4 ||
        submittedPathway === PATHWAY_NAME.Pathway5
      ) {
        return (
          <>
            <QualificationReviewLicenseV5.Component pathway1={data?.casePortal?.case?.pathway1} />
            <QualificationReviewCSExam2.Component pathway2={data?.casePortal?.case?.pathway2} />
            <QualificationReviewEducationV5.Component
              pathway345={data?.casePortal?.case?.pathway345 as Pathway345}
              caseSummary={data?.casePortal?.case?.caseSummary as CaseSummaryType}
            />
          </>
        );
      }

      //pathway 6
      if (submittedPathway === PATHWAY_NAME.Pathway6) {
        if (summarySectionIndication === SummarySectionsIndication.Pw6) {
          return <QualificationReviewPathway6.Component />;
        }

        return (
          <>
            <QualificationReviewLicenseV5.Component pathway1={data?.casePortal?.case?.pathway1} />
            <QualificationReviewCSExam2.Component pathway2={data?.casePortal?.case?.pathway2} />
            {summarySectionIndication === SummarySectionsIndication.Pw12_2b_345_6 ? (
              <>
                <Divider plain orientation="left">
                  <Space size="middle">
                    <b>Pathway 3, 4, or 5</b>
                  </Space>
                </Divider>
                <div>I do not meet the requirements for Pathway 3, 4, or 5.</div>
              </>
            ) : null}

            <QualificationReviewPathway6.Component />
          </>
        );
      }
    } else {
      if (data?.casePortal?.case?.pathwayX) {
        return (
          <QualificationReviewException.Component pathwayX={data?.casePortal?.case?.pathwayX} />
        );
      } else {
        return (
          <>
            <QualificationReviewLicenseV5.Component pathway1={data?.casePortal?.case?.pathway1} />

            <QualificationReviewCSExamV5.Component pathway2={data?.casePortal?.case?.pathway2} />

            <QualificationReviewEducationV5.Component
              pathway345={data?.casePortal?.case?.pathway345 as Pathway345}
              caseSummary={data?.casePortal?.case?.caseSummary as CaseSummaryType}
            />
          </>
        );
      }
    }
  };

  if (loading) {
    return <Spin></Spin>;
  }
  return (
    <div>
      {GetFeatureFlagByName('SubmissionSummary') === 'true' &&
      !!data?.casePortal?.case?.caseSummary?.caseState &&
      data?.casePortal?.case?.caseSummary?.caseState !== 'ApplicationCreated' ? (
        moment(data.casePortal.case.caseSummary.submittedAt) >
        moment(process.env.REACT_APP_SUBMISSION_SUMMARY_CUTOFF_DATE ?? '') ? (
          <ApplicationSummaryPDFButton
            usmleId={props.usmleID}
            getApplicationSummaryPDFMutation={getApplicantSummaryPDF}
            responseDataFieldName="CasePortal_SubmissionSummary"
          />
        ) : null
      ) : null}
      {GetPathwaySection(data?.casePortal?.case?.caseSummary?.submittedPathway!)}
      {/* languageAssessment */}
      {!!data?.casePortal?.case?.languageAssessment ? (
        <QualificationReviewLanguageAssessment.Component
          languageAssessment={data.casePortal.case.languageAssessment}
        />
      ) : null}
    </div>
  );
};

export default ApplicantSummaryV5;
