import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation, useMatch, Route, Routes } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useParams, Link } from 'react-router-dom';
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  ContainerOutlined,
  MedicineBoxOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { GetUserGroup, GetUserName } from '../../utils/helperFunctions';
import { element } from 'prop-types';
import { text } from 'stream/consumers';

const { SubMenu } = Menu;
const { Sider } = Layout;
const ms = require('ms');

const PhysicianLeftNav: FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? false : true);

  let oetDetailMatch = useMatch(`${location.pathname}/oet-detail/:oetIdParam`);
  let oetIdParam =
    typeof oetDetailMatch?.params !== undefined ? (oetDetailMatch?.params as any)?.oetIdParam : '';
  let selectedKeys = oetDetailMatch ? '/oet-submissions/oet-detail' : location.pathname;
  const userGroup = GetUserGroup();

  var handleClick = async (e: any) => {
    switch (e.key) {
      case 'case-management':
        navigate(`/case-management`);
        break;
      case '/physician-management':
        navigate(`/physician-management`);
        break;
    }
  };

  useEffect(() => {
    if (!isExpanded) {
      const icons = document.querySelectorAll('.ant-menu-item.sider-icon');
      icons.forEach((icon) => icon.setAttribute('style', 'white-space: normal'));
    }
  });

  const getLeftNavMenu = (userGroupName: string) => {
    //userGroupName = "Finance"
    console.log('userGroup', userGroup);
    if (userGroup?.includes(userGroupName)) {
      return (
        <Menu.Item
          key="finance-settings"
          icon={<DollarOutlined />}
          className="sider-icon"
          style={{ paddingLeft: '15px' }}
        >
          <Link to={'/case-management/finance-settings'}>Finance Settings</Link>
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item
          key="/physician-management"
          icon={<MedicineBoxOutlined />}
          className="sider-icon"
          style={{ paddingLeft: '15px' }}
        >
          <Link to={`/physician-management`}>Physician List</Link>
        </Menu.Item>
      );
    }
  };

  const handleToggler = () => {
    const icons = document.querySelectorAll('.ant-menu-item.sider-icon');
    if (isExpanded) {
      setIsExpanded(false);
      localStorage.setItem('sidebar-collapsed', 'true');
      icons.forEach((icon) => icon.setAttribute('style', 'white-space: normal'));
      return;
    }
    setIsExpanded(true);
    localStorage.removeItem('sidebar-collapsed');
    icons.forEach((icon) => icon.setAttribute('style', 'white-space: nowrap'));
  };

  return (
    <Sider
      width={220}
      theme="light"
      className="site-layout-background"
      collapsible
      collapsed={!isExpanded}
      onCollapse={handleToggler}
      style={{
        overflow: 'auto',
        height: 'calc(100vh - 64px)',
        position: 'relative',
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <Menu
        onClick={handleClick}
        mode="inline"
        forceSubMenuRender={true}
        selectedKeys={[selectedKeys]}
        defaultSelectedKeys={['physician-management']}
        style={{ height: '100%', borderRight: 0 }}
      >
        <Menu.Item
          key="case-management"
          icon={<ContainerOutlined />}
          className="sider-icon"
          style={{ paddingLeft: '15px' }}
        >
          <Link to={`/case-management`}>Pathway Case List</Link>
        </Menu.Item>
        <Menu.Item
          key="archived-cases"
          icon={<ContainerOutlined />}
          className="sider-icon"
          style={{ paddingLeft: '15px' }}
          id="case-list"
        >
          <Link to={`/archived-cases`}>Archived Case List</Link>
        </Menu.Item>
        {getLeftNavMenu('Finance')}
        <Menu.Item
          key="medical-school-officials"
          icon={<ContainerOutlined />}
          className="sider-icon"
          style={{ paddingLeft: '15px' }}
          id="medical-school-officials"
        >
          <Link to={`/medical-school-officials`}>Medical School Officials</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="userName"
          disabled={true}
          icon={<ContainerOutlined style={{ visibility: 'hidden' }} />}
          className="sider-icon"
          style={{ cursor: 'default', paddingLeft: '15px' }}
        >
          <div style={{ color: '#3f4373' }}>Logged in as </div>
        </Menu.Item>
        <Menu.Item
          key="userName"
          disabled={true}
          icon={<ContainerOutlined style={{ visibility: 'hidden' }} />}
          className="sider-icon"
          style={{ cursor: 'default', paddingLeft: '15px' }}
        >
          <div style={{ color: '#3f4373' }}>{GetUserName()}</div>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default PhysicianLeftNav;
