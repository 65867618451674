import { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  CaseSummaryType,
  C_ApplicantType,
  UiPageConfigType,
  UiPortalConfigType,
} from '../../../../../generated';

import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';
import Pathway2DocumentReviewChecklist from './checklist-versions/pathway2-document-review-checklist';
import Pathway2DocumentReviewChecklistV2 from './checklist-versions/pathway2-document-review-checklist-v2';
import Pathway2DocumentReviewChecklistV3 from './checklist-versions/pathway2-document-review-checklist-v3';
import Pathway2DocumentReviewChecklistV4 from './checklist-versions/pathway2-document-review-checklist-v4';

const ComponentPropTypes = {
    checklist: PropTypes.shape({
      osceExaminationAcceptable: PropTypes.string,
      osceExaminationPassed: PropTypes.string,
      osceExaminationAfterJuly1: PropTypes.string,
      osceNameOK: PropTypes.string,
      osceDocumentCompleteLegible: PropTypes.string,
      osceTranslation: PropTypes.string,
      verificationCompleted: PropTypes.string,
      verificationReturned: PropTypes.string,
      verificationPassedExamConfirmed: PropTypes.string,
      verificationAcceptableDateConfirmed: PropTypes.string,
    }),
    checklistState: PropTypes.string.isRequired,
    recordId: PropTypes.string.isRequired,
    parentDataVersion: PropTypes.number,
    onUpdateDataVersion: PropTypes.func.isRequired,
    applicant: PropTypes.shape({
      email: PropTypes.string,
    }),
    redirect: PropTypes.func.isRequired,
    checklistStatus: PropTypes.string.isRequired,
    refetch: PropTypes.func.isRequired,
  };

export interface IProps {
  versionConfig: UiPortalConfigType;
  caseSummary: CaseSummaryType;
  applicant: C_ApplicantType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes & IProps>;

const Pathway2DocumentReviewChecklistFactory: FC<ComponentProps> = ({
  versionConfig,
  checklist,
  caseSummary,
  checklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  checklistStatus,
  applicant,
  redirect,
  refetch,
}) => {
  const getPathway2Checklist = () => {
    return versionConfig?.config?.pages.map((page: UiPageConfigType) => {
      if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
        return page?.components?.map((component) => {
          if (component?.componentName === COMPONENT_NAME.PATHWAY2_CHECKLIST) {
            switch (component.version) {
              case 1:
                return (
                  <Pathway2DocumentReviewChecklist.Component
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    checklistStatus={checklistStatus}
                    refetch={() => {
                      refetch();
                    }}
                  />
                );
              case 2:
                return (
                  <Pathway2DocumentReviewChecklistV2.Component
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    checklistStatus={checklistStatus}
                    refetch={() => {
                      refetch();
                    }}
                  />
                );
              case 3:
                return (
                  <Pathway2DocumentReviewChecklistV3.Component
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    checklistStatus={checklistStatus}
                    refetch={() => {
                      refetch();
                    }}
                  />
                );
              
              case 4:
              default:
                  //return the latest version by default
                  return (
                    <Pathway2DocumentReviewChecklistV4.Component
                      caseSummary={caseSummary}
                      checklist={checklist}
                      checklistState={checklistState}
                      recordId={recordId}
                      parentDataVersion={parentDataVersion}
                      onUpdateDataVersion={(dataVersion: number) => {
                        onUpdateDataVersion(dataVersion);
                      }}
                      applicant={applicant}
                      redirect={(url: string) => {
                        redirect(url);
                      }}
                      checklistStatus={checklistStatus}
                      refetch={() => {
                        refetch();
                      }}
                    />
                  );
            }
          }
        });
      }
    });
  };

  return <div>{getPathway2Checklist()}</div>;
};

export default Pathway2DocumentReviewChecklistFactory;
