import { useMutation } from '@apollo/client/react';
import { Button, Input, Space, Table, message } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import { FC, useEffect, useState } from 'react';
import {
  ActivityLogType,
  CaseMgmtActivityFeedAddCaseNoteDocument,
  CaseSummaryType,
} from '../../generated';
import { compareDate, compareString, getDateFromTimestamp, getSortedActivityLog } from '../../utils/helperFunctions';

const { TextArea } = Input;
const { Column } = Table;

//TODO: Make this required
const ActivityLogPropsTypes = {
  onUpdateDataVersion: PropTypes.func.isRequired,
  parentDataVersion: PropTypes.number,
  refetch: PropTypes.func.isRequired,
  showAddCaseNote: PropTypes.bool,
};

export interface ComponentProps {
  activityLog: ActivityLogType[];
  caseSummary?: CaseSummaryType;
}

export type ActivityFeedProps = InferProps<typeof ActivityLogPropsTypes> & ComponentProps;

const ActivityFeed: FC<ActivityFeedProps> = ({
  activityLog,
  caseSummary,
  parentDataVersion,
  onUpdateDataVersion,
  refetch,
  showAddCaseNote,
}) => {
  const [caseNoteText, setCaseNoteText] = useState<string>('');
  const [activityFeed, setActivityFeed] = useState<ActivityLogType[] | undefined>(undefined);
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);
  const [addCaseNote] = useMutation(CaseMgmtActivityFeedAddCaseNoteDocument);

  useEffect(() => {
    if (activityLog) {
      setActivityFeed(getSortedActivityLog(activityLog));
    }
    if (parentDataVersion) {
      if (!dataVersion) {
        setDataVersion(parentDataVersion);
      } else if (dataVersion < parentDataVersion) {
        setDataVersion(parentDataVersion);
      }
    }
  }, [activityLog, parentDataVersion]);

  const onCaseNoteChange = (e: any) => {
    setCaseNoteText(e.target.value);
  };

  const addCaseNote_Click = async (event: any) => {
    if (!caseSummary?.usmleId || dataVersion == undefined) {
      return;
    }

    await addCaseNote({
      variables: {
        usmleId: caseSummary?.usmleId,
        dataVersion: dataVersion,
        text: caseNoteText,
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_addCaseNote;
        setActivityFeed(getSortedActivityLog(casePortalResponse?.activityLog as ActivityLogType[]));
        setDataVersion(casePortalResponse?.caseSummary?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.caseSummary?.dataVersion);
        refetch();
        setCaseNoteText('');
      })
      .catch((error) => {
        if (error?.graphQLErrors) {
          //only grab the first error
          const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
          message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        } else {
          message.error(error.toString());
        }
      });
  };

  return (
    <>
      {/* width='99%': trick to fix the width growing continuously */}
      <Space direction="vertical" style={{ width: '99%' }}>
        <Table
          dataSource={activityFeed}
          scroll={{ y: 240 }}
          size="small"
          className="ecfmg-small-table"
        >
          <Column
            title="Date Created"
            dataIndex="timestamp"
            key="timestamp"
            render={(text: any, record: any) => {
              return getDateFromTimestamp(text);
            }}
            sorter={(rec1: any, rec2: any) => compareDate(rec1.timestamp, rec2.timestamp, true)}
          />
          <Column
            title="Source"
            dataIndex="user"
            key="user"
            render={(text: any, record: any) => {
              return <div>{text?.name}</div>;
            }}
            sorter={(rec1: any, rec2: any) => compareString(rec1.user?.name, rec2.user?.name)}
          />
          <Column
            title="Details"
            dataIndex="message"
            key="message"
            sorter={(rec1: any, rec2: any) => compareString(rec1.message, rec2.message)}
          />
        </Table>
        {showAddCaseNote !== undefined && showAddCaseNote === false ? (
          <></>
        ) : (
          <>
            <TextArea rows={4} onChange={(e: any) => onCaseNoteChange(e)} value={caseNoteText} />
            <div style={{ textAlign: 'right' }}>
              <Button size="small" onClick={(e: any) => addCaseNote_Click(e)}>
                Add Case Note
              </Button>
            </div>
          </>
        )}
      </Space>
    </>
  );
};

export default {
  Component: ActivityFeed,
};
