import { useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import { Pathway6ArchivedCaseAssessmentsMiniCexScoresTableContainerArchivedCaseDocument } from '../../../../../generated';
import { ComponentQueryLoader } from '../../../../../utils/molecules/component-query-loader';
import { PATHWAY6_RESPONSE_TYPE } from '../../../../constants';
import {
  Pathway6ArchivedCaseAssessmentsMiniCEXScoresTable,
  Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableDataType,
} from './pathway6-archived-case-assessments-minicex-scores-table';

interface Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableContainerProps {
  caseId: string;
  usmleId: string;
}

export const Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableContainer: FC<Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableContainerProps> =
  (props) => {
    const [tableData, setTableData] = useState<
      Pathway6ArchivedCaseAssessmentsMiniCEXScoresTableDataType[]
    >([]);

    const {
      data: miniCEXData,
      loading,
      error,
    } = useQuery(Pathway6ArchivedCaseAssessmentsMiniCexScoresTableContainerArchivedCaseDocument, {
      variables: {
        caseId: props.caseId ?? '',
        filter: { caseId: props.caseId ?? '', responseType: PATHWAY6_RESPONSE_TYPE.ACTIVE },
      },
    });

    useEffect(() => {
      if (miniCEXData) {
        getTableData();
      }
    }, [miniCEXData]);

    const getTableData = () => {
      // build table data
      const tableData =
        miniCEXData?.archivedCase?.assessments?.minicex?.minicexList?.map((miniCEX) => {
          const id = miniCEX?.encounterId;
          // flatten the categories into an object
          const miniCEXScore = miniCEX?.form?.evaluation?.categories?.reduce(
            (accumulateObj: any, category) => {
              const cateName = category?.name ?? '';
              accumulateObj[cateName] = category?.score;
              return accumulateObj;
            },
            {}
          );

          return {
            id: id,
            ...miniCEXScore,
          };
        }) ?? [];

      // add average score to the table data
      const avgScore =
        miniCEXData?.archivedCase?.assessments?.minicex?.assessment?.categories?.reduce(
          (accumulateObj: any, category) => {
            const cateName = category?.name ?? '';
            accumulateObj[cateName] = category?.avgScore;
            return accumulateObj;
          },
          {}
        );
      tableData.push({
        id: 'Average',
        ...avgScore,
      });

      setTableData(tableData);
    };

    return (
      <ComponentQueryLoader
        loading={loading}
        hasData={
          miniCEXData?.archivedCase?.assessments?.minicex?.minicexList &&
          miniCEXData?.archivedCase?.assessments?.minicex?.minicexList.length > 0
        }
        hasDataComponent={
          <Pathway6ArchivedCaseAssessmentsMiniCEXScoresTable tableData={tableData} />
        }
        noDataComponent={<Pathway6ArchivedCaseAssessmentsMiniCEXScoresTable tableData={[]} />}
        error={error}
      />
    );
  };
