import { Table, Tabs } from 'antd';
import { FC, Key, useState } from 'react';
import { Pathway6ArchivedCaseChecklist } from './pathway6-archived-case-checklist';
import ArchivedCaseOetReview from '../../shared-components/oet/archived-case-oet-review';
import { ArchivedCaseOetReviewType, ArchivedCaseReviewsType, UiPortalConfigType } from '../../../../generated';
import React from 'react';

const TabPaneName = {
  Pathway6: 'Pathway 6',
  OET: 'OET',
};

interface Pathway6ArchivedCaseReviewProps {
  data: ArchivedCaseReviewsType;
  uiConfig: UiPortalConfigType
}
export const Pathway6ArchivedCaseReview: FC<Pathway6ArchivedCaseReviewProps> = (props) => {
  const [currentPathwayLicense, setCurrentPathwayLicense] = useState(
    props.data.pathway6 ? props.data.pathway6[0] : null
  );
  const [currentRowKeys, setCurrentRowKeys] = useState([currentPathwayLicense?.summary?._id]);

  const rowSelection = {
    selectedRowKeys: currentRowKeys as Key[],
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setCurrentRowKeys(selectedRowKeys);
      setCurrentPathwayLicense(selectedRows[0]);
    },
  };

  const tableData = props?.data?.pathway6?.map((entry: any) => ({
    key: entry?.summary?._id,
    title: entry?.summary?.title,
    status: entry?.summary?.status,
    name: entry?.summary?.updatedBy?.name
  }));


  const pathwayTableColumns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'name',
    },
    {
      title: 'Last Reviewed By',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'name',
    },
  ];

  const pathway6Tab = (
    <Tabs.TabPane tab={TabPaneName.Pathway6} key={TabPaneName.Pathway6}>
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        columns={pathwayTableColumns}
        dataSource={tableData}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Pathway6ArchivedCaseChecklist data={currentPathwayLicense ?? {}} uiConfig={props.uiConfig}/>
      </div>
    </Tabs.TabPane>
  );

  const OetTab = (
    <Tabs.TabPane tab={TabPaneName.OET} key={TabPaneName.OET}>
      <ArchivedCaseOetReview data={props.data.oet as ArchivedCaseOetReviewType[]} />
    </Tabs.TabPane>
  );

  return (
    <Tabs defaultActiveKey="1">
      {pathway6Tab}
      {OetTab}
    </Tabs>
  );
};
