import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { case_ } from '../schema/casePortal-case';
import {CaseMgmtRedirectPathwayCasePortalDocument} from '../../generated'; 

export interface ComponentProp {
  casePortal: {
    case: case_
  }
}

type paramType = {
  usmleId: string;
};

const RedirectPathway: FC<any> = () => {
  let { usmleId } = useParams<paramType>();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(CaseMgmtRedirectPathwayCasePortalDocument, {
    variables: {
      usmleId: usmleId,
    },
  });

  if (error) {
    console.log('WE GOT AN ERROR', error);
  }

  if (data) {
    var pathwayLink = data.casePortal?.case;
    // var useApplicantProvidedData =
    //   pathwayLink?.caseSummary?.currentPathway === pathwayLink?.caseSummary?.submittedPathway ||
    //   pathwayLink?.caseSummary?.submittedPathway === null;

    var url = '';
    switch (pathwayLink?.caseSummary?.currentPathway) {
      case 'PATHWAY1':
        url = `/case-management/case-detail/${pathwayLink.caseSummary.usmleId}/pathway-1/`;
        break;
      case 'PATHWAY2':
        url = `/case-management/case-detail/${pathwayLink.caseSummary.usmleId}/pathway-2/${
          (pathwayLink?.caseSummary?.isPathwayChanged !== true
            ? pathwayLink?.pathway2?.applicantProvidedData?.[0]?._id
            : pathwayLink?.pathway2?.staffProvidedData?.[0]?._id) ?? 'OveridePath'
        }`;
        break;
      case 'PATHWAY3':
        url = `/case-management/case-detail/${pathwayLink.caseSummary.usmleId}/pathway-3/${
          (pathwayLink?.caseSummary?.isPathwayChanged !== true
            ? pathwayLink?.pathway345?.applicantProvidedData?.[0]?._id
            : pathwayLink?.pathway345?.staffProvidedData?.[0]?._id) ?? 'OveridePath'
        }`;
        break;
      case 'PATHWAY4':
        url = `/case-management/case-detail/${pathwayLink.caseSummary.usmleId}/pathway-4/${
          (pathwayLink?.caseSummary?.isPathwayChanged !== true
            ? pathwayLink?.pathway345?.applicantProvidedData?.[0]?._id
            : pathwayLink?.pathway345?.staffProvidedData?.[0]?._id) ?? 'OveridePath'
        }`;
        break;
      case 'PATHWAY5':
        url = `/case-management/case-detail/${pathwayLink.caseSummary.usmleId}/pathway-5/${
          (pathwayLink?.caseSummary?.isPathwayChanged !== true
            ? pathwayLink?.pathway345?.applicantProvidedData?.[0]?._id
            : pathwayLink?.pathway345?.staffProvidedData?.[0]?._id) ?? 'OveridePath'
        }`;
        break;
      case 'PATHWAY6':
        url = `/case-management/case-detail/${pathwayLink.caseSummary.usmleId}/pathway-6/${
          (pathwayLink?.caseSummary?.isPathwayChanged !== true
            ? pathwayLink?.pathway6?.applicantProvidedData?.[0]?._id
            : pathwayLink?.pathway6?.staffProvidedData?.[0]?._id) ?? 'OveridePath'
        }`;
        break;
      case 'PATHWAYX':
        url = `/case-management/case-detail/${pathwayLink?.caseSummary?.usmleId}/pathway-X/${pathwayLink?.pathwayX?._id}`;
        break;
      default:
        return <>No Pathway Defined</>;
    }

    navigate(url);
  }

  return <div></div>;
};

export default {
  Component: RedirectPathway,
};
