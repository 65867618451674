import { FC } from 'react';
import { ArchivedCaseType } from '../../../../generated';
import { GetUserGroup } from '../../../../utils/helperFunctions';
import { Button } from 'antd';

interface ArchivedCaseExportProps {
  data: ArchivedCaseType;
}

const ArchivedCaseExport: FC<ArchivedCaseExportProps> = (props) => {
  const userGroup = GetUserGroup();
  const isFinanceUser = userGroup?.includes('Finance');
  let modifiedData = props.data;

  if (!isFinanceUser) {
    delete modifiedData.finance;
  }

  const outputData = JSON.stringify(modifiedData, null, 2);

  return (
    <>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(outputData);
        }}
        style={{ float: 'right' }}
      >
        Copy Data
      </Button>
      <div>
        <pre>{outputData}</pre>
      </div>
    </>
  );
};

export default ArchivedCaseExport;
