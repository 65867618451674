import { Collapse } from 'antd';
import { FC } from 'react';
import { GetUserGroup } from '../../../utils/helperFunctions';
import ArchivedCaseAdditionalDocumentsContainer from '../shared-components/additional-documents/archived-case-additional-documents-container';
import ArchivedCaseApplicantContainer from '../shared-components/applicant/archived-case-applicant-container';
import ArchivedCaseDetailsContainer from '../shared-components/case-details/archived-case-details-container';
import ArchivedActivityFeedContainer from '../shared-components/activity-feed/archived-activity-feed-container';
import ArchivedCaseFinanceContainer from '../shared-components/finance/archived-case-finance-container';
import { PathwayXArchivedCaseAssessments } from './components/pathway-x-archived-case-assessments';
import PathwayXArchivedCaseReviewContainer from './components/pathway-x-archived-case-review-container';

const { Panel } = Collapse;

const PanelName = {
  Applicant: 'Applicant',
  Reviews: 'Reviews',
  AdditionalDocuments: 'Additional Documents',
  Assessments: 'Assessments',
  Finance: 'Finance',
  ActivityFeed: 'Activity Feed',
};

interface PathwayXArchivedCaseProps {}

const PathwayXArchivedCase: FC<PathwayXArchivedCaseProps> = (props) => {
  const userGroup = GetUserGroup();
  const isFinanceUser = userGroup?.includes('Finance');

  return (
    <div>
      <ArchivedCaseDetailsContainer />
      <Collapse style={{ marginTop: '20px', border: '0px' }}>
        <Panel
          header={PanelName.Applicant}
          key={PanelName.Applicant}
          style={{ marginBottom: '10px', border: '1px solid #d5d0da' }}
        >
          <ArchivedCaseApplicantContainer />
        </Panel>
        <Panel
          header={PanelName.Reviews}
          key={PanelName.Reviews}
          style={{ marginBottom: '10px', border: '1px solid #d5d0da' }}
        >
          <PathwayXArchivedCaseReviewContainer />
        </Panel>

        <Panel
          header={PanelName.Assessments}
          key={PanelName.Assessments}
          style={{ marginBottom: '10px', border: '1px solid #d5d0da' }}
        >
          <PathwayXArchivedCaseAssessments />
        </Panel>

        {isFinanceUser && (
          <Panel
            header={PanelName.Finance}
            key={PanelName.Finance}
            style={{ marginBottom: '10px', border: '1px solid #d5d0da' }}
          >
            <ArchivedCaseFinanceContainer />
          </Panel>
        )}
        <Panel
          header={PanelName.AdditionalDocuments}
          key={PanelName.AdditionalDocuments}
          style={{ marginBottom: '10px', border: '1px solid #d5d0da' }}
        >
          <ArchivedCaseAdditionalDocumentsContainer />
        </Panel>
        <Panel
          header={PanelName.ActivityFeed}
          key={PanelName.ActivityFeed}
          style={{ marginBottom: '10px', border: '1px solid #d5d0da' }}
        >
          <ArchivedActivityFeedContainer />
        </Panel>
      </Collapse>
    </div>
  );
};

export default PathwayXArchivedCase;
