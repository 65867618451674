import { useMutation, useQuery } from '@apollo/client';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  ArchivedCaseDetailsContainerApplicantSummarySubmissionSummaryDocument,
  ArchivedCaseDetailsContainerArchivedCaseDocument,
  ArchivedCaseType,
} from '../../../../generated';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';
import ArchivedCaseDetails from './archived-case-details';

const ArchivedCaseDetailsContainer: FC<any> = () => {
  const { caseId } = useParams();
  const {
    loading: casesLoading,
    error: casesError,
    data: casesData,
  } = useQuery(ArchivedCaseDetailsContainerArchivedCaseDocument, {
    variables: {
      caseId: caseId ?? '',
    },
  });

  const [getApplicantSummaryPDF] = useMutation(
    ArchivedCaseDetailsContainerApplicantSummarySubmissionSummaryDocument
  );

  return (
    <ComponentQueryLoader
      loading={casesLoading}
      hasData={casesData?.archivedCase !== undefined}
      hasDataComponent={
        <ArchivedCaseDetails
          data={casesData?.archivedCase as ArchivedCaseType}
          getApplicantSummaryPDF={getApplicantSummaryPDF}
          responseDataFieldName="CasePortal_archivedCase_SubmissionSummary"
        />
      }
      error={casesError}
    />
  );
};

export default ArchivedCaseDetailsContainer;
