import { useQuery } from '@apollo/client';
import { FC } from 'react';
import {
  ArchivedCaseAssessmentOetType,
  ArchivedCaseAssessmentsOetContainerArchivedCaseDocument,
} from '../../../../generated';
import { ComponentQueryLoader } from '../../../../utils/molecules/component-query-loader';
import { ArchivedCaseAssessmentsOET } from './archived-case-assessments-oet';

interface ArchivedCaseAssessmentsOETContainerProps {
  caseId: string;
}
export const ArchivedCaseAssessmentsOETContainer: FC<ArchivedCaseAssessmentsOETContainerProps> = (
  props
) => {
  const {
    data: caseData,
    loading,
    error,
  } = useQuery(ArchivedCaseAssessmentsOetContainerArchivedCaseDocument, {
    variables: { caseId: props.caseId ?? '' },
  });
  return (
    <ComponentQueryLoader
      loading={loading}
      hasData={caseData?.archivedCase !== undefined}
      hasDataComponent={
        <ArchivedCaseAssessmentsOET
          data={caseData?.archivedCase?.assessments?.oet as ArchivedCaseAssessmentOetType}
        />
      }
      error={error}
    />
  );
};
