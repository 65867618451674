import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Space, Button, Row, Col, Select, Popconfirm, message, Typography } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import { SendOutlined, CheckOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client/react';
import {
  CaseMgmtPathwayXDocumentReviewChecklistReviewDocument,
  CaseSummaryType,
  C_ApplicantType,
} from '../../../../../../generated';
const { Option } = Select;
const { Title } = Typography;

const PathwayXDocumentReviewChecklistPropsTypes = {
  checklist: PropTypes.shape({
    pathwayStatus: PropTypes.string,
    exceptionStatus: PropTypes.string,
    applicantIsNotCertified: PropTypes.string,
    applicantNotValidStep2CSScore: PropTypes.string,
    applicantHasNotSubmittedAppForCert: PropTypes.string,
    applicantHasPurchasedToken2021Match: PropTypes.string,
    applicantNotBarredByUSMLE: PropTypes.string,
    ecfmgRestrictionsDoNotPreventAppExamination: PropTypes.string,
    step1PassedWithOneOrNoFails: PropTypes.string,
    step2CKPassedWithOneOrNoFails: PropTypes.string,
    exceptionApproved: PropTypes.string,
    applicantEligiblePathway1: PropTypes.string,
    pathway1DocsVerified: PropTypes.string,
    applicantEligiblePathway2: PropTypes.string,
    pathway2DocsVerified: PropTypes.string,
    applicantEligiblePathway345: PropTypes.string,
    pathway345DocsVerified: PropTypes.string,
  }),
  pathwayChecklistState: PropTypes.string.isRequired,
  exceptionChecklistState: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  parentDataVersion: PropTypes.number,
  onUpdateDataVersion: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  checklistStatus: PropTypes.string.isRequired,
};

export interface ComponentProps {
  caseSummary: CaseSummaryType;
  applicant: C_ApplicantType;
}

export type PathwayXDocumentReviewChecklistProps = InferProps<
  typeof PathwayXDocumentReviewChecklistPropsTypes
> &
  ComponentProps;

const PathwayXDocumentReviewChecklist: FC<PathwayXDocumentReviewChecklistProps> = ({
  checklist,
  caseSummary,
  pathwayChecklistState,
  exceptionChecklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  applicant,
  redirect,
  checklistStatus,
}) => {
  console.log('INITIAL VALUES', checklist);
  const [pathwayStatus, setPathwayStatus] = useState(checklist?.pathwayStatus);
  const [exceptionStatus, setExceptionStatus] = useState(checklist?.exceptionStatus);
  const [applicantIsNotCertified, setApplicantIsNotCertified] = useState(
    checklist?.applicantIsNotCertified
  );
  const [applicantNotValidStep2CSScore, setApplicantNotValidStep2CSScore] = useState(
    checklist?.applicantNotValidStep2CSScore
  );
  const [applicantHasNotSubmittedAppForCert, setApplicantHasNotSubmittedAppForCert] = useState(
    checklist?.applicantHasNotSubmittedAppForCert
  );
  const [applicantHasPurchasedToken2021Match, setApplicantHasPurchasedToken2021Match] = useState(
    checklist?.applicantHasPurchasedToken2021Match
  );
  const [applicantNotBarredByUSMLE, setApplicantNotBarredByUSMLE] = useState(
    checklist?.applicantNotBarredByUSMLE
  );
  const [
    ecfmgRestrictionsDoNotPreventAppExamination,
    setEcfmgRestrictionsDoNotPreventAppExamination,
  ] = useState(checklist?.ecfmgRestrictionsDoNotPreventAppExamination);
  const [step1PassedWithOneOrNoFails, setStep1PassedWithOneOrNoFails] = useState(
    checklist?.step1PassedWithOneOrNoFails
  );
  const [step2CKPassedWithOneOrNoFails, setStep2CKPassedWithOneOrNoFails] = useState(
    checklist?.step2CKPassedWithOneOrNoFails
  );
  const [exceptionApproved, setExceptionApproved] = useState(checklist?.exceptionApproved);
  const [applicantEligiblePathway1, setApplicantEligiblePathway1] = useState(
    checklist?.applicantEligiblePathway1
  );
  const [pathway1DocsVerified, setPathway1DocsVerified] = useState(checklist?.pathway1DocsVerified);
  const [applicantEligiblePathway2, setApplicantEligiblePathway2] = useState(
    checklist?.applicantEligiblePathway2
  );
  const [pathway2DocsVerified, setPathway2DocsVerified] = useState(checklist?.pathway2DocsVerified);
  const [applicantEligiblePathway345, setApplicantEligiblePathway345] = useState(
    checklist?.applicantEligiblePathway345
  );
  const [pathway345DocsVerified, setPathway345DocsVerified] = useState(
    checklist?.pathway345DocsVerified
  );
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);

  const [setPathwayXChecklist] = useMutation(CaseMgmtPathwayXDocumentReviewChecklistReviewDocument);

  useEffect(() => {
    if (pathwayChecklistState && typeof pathwayStatus == 'undefined') {
      setPathwayStatus(pathwayChecklistState);
      form.setFieldsValue({
        pathwayStatus: pathwayChecklistState,
      });
    }
    if (exceptionChecklistState && typeof exceptionStatus == 'undefined') {
      setExceptionStatus(exceptionChecklistState);
      form.setFieldsValue({
        exceptionStatus: exceptionChecklistState,
      });
    }
    if (parentDataVersion) {
      setDataVersion(parentDataVersion);
    }
    if (checklist) {
      if (typeof applicantIsNotCertified == 'undefined') {
        setApplicantIsNotCertified(checklist.applicantIsNotCertified);
        form.setFieldsValue({
          applicantIsNotCertified: checklist.applicantIsNotCertified,
        });
      }
      if (typeof applicantNotValidStep2CSScore == 'undefined') {
        setApplicantNotValidStep2CSScore(checklist.applicantNotValidStep2CSScore);
        form.setFieldsValue({
          applicantNotValidStep2CSScore: checklist.applicantNotValidStep2CSScore,
        });
      }
      if (typeof applicantHasNotSubmittedAppForCert == 'undefined') {
        setApplicantHasNotSubmittedAppForCert(checklist.applicantHasNotSubmittedAppForCert);
        form.setFieldsValue({
          applicantHasNotSubmittedAppForCert: checklist.applicantHasNotSubmittedAppForCert,
        });
      }
      if (typeof applicantHasPurchasedToken2021Match == 'undefined') {
        setApplicantHasPurchasedToken2021Match(checklist.applicantHasPurchasedToken2021Match);
        form.setFieldsValue({
          applicantHasPurchasedToken2021Match: checklist.applicantHasPurchasedToken2021Match,
        });
      }
      if (typeof applicantNotBarredByUSMLE == 'undefined') {
        setApplicantNotBarredByUSMLE(checklist.applicantNotBarredByUSMLE);
        form.setFieldsValue({
          applicantNotBarredByUSMLE: checklist.applicantNotBarredByUSMLE,
        });
      }
      if (typeof ecfmgRestrictionsDoNotPreventAppExamination == 'undefined') {
        setEcfmgRestrictionsDoNotPreventAppExamination(
          checklist.ecfmgRestrictionsDoNotPreventAppExamination
        );
        form.setFieldsValue({
          ecfmgRestrictionsDoNotPreventAppExamination:
            checklist.ecfmgRestrictionsDoNotPreventAppExamination,
        });
      }
      if (typeof step1PassedWithOneOrNoFails == 'undefined') {
        setStep1PassedWithOneOrNoFails(checklist.step1PassedWithOneOrNoFails);
        form.setFieldsValue({
          step1PassedWithOneOrNoFails: checklist.step1PassedWithOneOrNoFails,
        });
      }
      if (typeof step2CKPassedWithOneOrNoFails == 'undefined') {
        setStep2CKPassedWithOneOrNoFails(checklist.step2CKPassedWithOneOrNoFails);
        form.setFieldsValue({
          step2CKPassedWithOneOrNoFails: checklist.step2CKPassedWithOneOrNoFails,
        });
      }
      if (typeof exceptionApproved == 'undefined') {
        setExceptionApproved(checklist.exceptionApproved);
        form.setFieldsValue({
          exceptionApproved: checklist.exceptionApproved,
        });
      }
      if (typeof applicantEligiblePathway1 == 'undefined') {
        setApplicantEligiblePathway1(checklist.applicantEligiblePathway1);
        form.setFieldsValue({
          applicantEligiblePathway1: checklist.applicantEligiblePathway1,
        });
      }
      if (typeof pathway1DocsVerified == 'undefined') {
        setPathway1DocsVerified(checklist.pathway1DocsVerified);
        form.setFieldsValue({
          pathway1DocsVerified: checklist.pathway1DocsVerified,
        });
      }
      if (typeof applicantEligiblePathway2 == 'undefined') {
        setApplicantEligiblePathway2(checklist.applicantEligiblePathway2);
        form.setFieldsValue({
          applicantEligiblePathway2: checklist.applicantEligiblePathway2,
        });
      }
      if (typeof pathway2DocsVerified == 'undefined') {
        setPathway2DocsVerified(checklist.pathway2DocsVerified);
        form.setFieldsValue({
          pathway2DocsVerified: checklist.pathway2DocsVerified,
        });
      }
      if (typeof applicantEligiblePathway345 == 'undefined') {
        setApplicantEligiblePathway345(checklist.applicantEligiblePathway345);
        form.setFieldsValue({
          applicantEligiblePathway345: checklist.applicantEligiblePathway345,
        });
      }
      if (typeof pathway345DocsVerified == 'undefined') {
        setPathway345DocsVerified(checklist.pathway345DocsVerified);
        form.setFieldsValue({
          pathway345DocsVerified: checklist.pathway345DocsVerified,
        });
      }
    }
  });

  const disableChecklist: boolean =
    checklistStatus?.toUpperCase() === 'APPROVED' ||
    checklistStatus?.toUpperCase() === 'REJECTED' ||
    caseSummary?.pathwayEligibilityStatus?.toUpperCase() === 'COMPLETED' ||
    caseSummary?.eligibilityReviewAssigneeId !== sessionStorage.getItem('userIdToken');

  const selectOptions = () => {
    return (
      <>
        <Option value=""> </Option>
        <Option value="No">No</Option>
        <Option value="Yes">Yes</Option>
        <Option value="N/A">N/A</Option>
      </>
    );
  };
  const onClickSave = async () => {
    var submitChecklist = {
      applicantIsNotCertified: applicantIsNotCertified || '',
      applicantNotValidStep2CSScore: applicantNotValidStep2CSScore || '',
      applicantHasNotSubmittedAppForCert: applicantHasNotSubmittedAppForCert || '',
      applicantHasPurchasedToken2021Match: applicantHasPurchasedToken2021Match || '',
      applicantNotBarredByUSMLE: applicantNotBarredByUSMLE || '',
      ecfmgRestrictionsDoNotPreventAppExamination:
        ecfmgRestrictionsDoNotPreventAppExamination || '',
      step1PassedWithOneOrNoFails: step1PassedWithOneOrNoFails || '',
      step2CKPassedWithOneOrNoFails: step2CKPassedWithOneOrNoFails || '',
      exceptionApproved: exceptionApproved || '',
      applicantEligiblePathway1: applicantEligiblePathway1 || '',
      pathway1DocsVerified: pathway1DocsVerified || '',
      applicantEligiblePathway2: applicantEligiblePathway2 || '',
      pathway2DocsVerified: pathway2DocsVerified || '',
      applicantEligiblePathway345: applicantEligiblePathway345 || '',
      pathway345DocsVerified: pathway345DocsVerified || '',
    };

    await setPathwayXChecklist({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion!,
        recordId: recordId,
        isComplete: false,
        checklistResponse: submitChecklist,
        pathwayChecklistState: pathwayStatus || '',
        exceptionChecklistState: exceptionStatus || '',
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_pathwayX_setReview?.caseSummary;
        setDataVersion(casePortalResponse?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.dataVersion);
        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onFormFinish = async () => {
    console.log('FORM VALIDATED');
    var submitChecklist = {
      applicantIsNotCertified: applicantIsNotCertified || '',
      applicantNotValidStep2CSScore: applicantNotValidStep2CSScore || '',
      applicantHasNotSubmittedAppForCert: applicantHasNotSubmittedAppForCert || '',
      applicantHasPurchasedToken2021Match: applicantHasPurchasedToken2021Match || '',
      applicantNotBarredByUSMLE: applicantNotBarredByUSMLE || '',
      ecfmgRestrictionsDoNotPreventAppExamination:
        ecfmgRestrictionsDoNotPreventAppExamination || '',
      step1PassedWithOneOrNoFails: step1PassedWithOneOrNoFails || '',
      step2CKPassedWithOneOrNoFails: step2CKPassedWithOneOrNoFails || '',
      exceptionApproved: exceptionApproved || '',
      applicantEligiblePathway1: applicantEligiblePathway1 || '',
      pathway1DocsVerified: pathway1DocsVerified || '',
      applicantEligiblePathway2: applicantEligiblePathway2 || '',
      pathway2DocsVerified: pathway2DocsVerified || '',
      applicantEligiblePathway345: applicantEligiblePathway345 || '',
      pathway345DocsVerified: pathway345DocsVerified || '',
    };

    await setPathwayXChecklist({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion!,
        recordId: recordId,
        isComplete: true,
        checklistResponse: submitChecklist,
        pathwayChecklistState: pathwayStatus || '',
        exceptionChecklistState: exceptionStatus || '',
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_pathwayX_setReview?.caseSummary;
        setDataVersion(casePortalResponse?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.dataVersion);
        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onFormFinishFailed = () => {
    console.log('FORM VALIDATION FAILED');
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        size={'small'}
        form={form}
        onFinish={() => onFormFinish()}
        onFinishFailed={() => onFormFinishFailed()}
      >
        <div style={{ border: '1px black solid', padding: '15px' }}>
          <Title level={3}>Exception Review</Title>
          <hr style={{ borderTop: '1px black solid' }} />
          <Row justify="end">
            <Col>
              <Button shape="round" icon={<SendOutlined />} size={'small'}>
                <a href={`mailto:${applicant?.email}?subject=ECFMG%20Pathway%20Application`}>
                  {' Contact Applicant'}
                </a>
              </Button>
            </Col>
          </Row>
          <Title level={4}>Pathway Status</Title>
          <Row>
            <Col span={24}>
              <Form.Item
                name="pathwayStatus"
                rules={[{ required: true, message: 'Please select an answer' }]}
                initialValue={pathwayStatus !== '' ? pathwayStatus : null}
              >
                <Select
                  onChange={(e: any) => setPathwayStatus(e.toString())}
                  disabled={disableChecklist}
                >
                  <Option value="pathway-exception-request-in-process">
                    Exception request in progress
                  </Option>
                  <Option value="pathway-awaiting-applicant-resolution">
                    Awaiting applicant resolution
                  </Option>
                  <Option value="pathway-awaiting-verification-licensure-document">
                    Awaiting Verification of Licensure Document (Pathway 1)
                  </Option>
                  <Option value="pathway-awaiting-verification-osce-document">
                    Awaiting Verification of OSCE Document (Pathway 2)
                  </Option>
                  <Option value="pathway-awaiting-attestation-pathway-2">
                    Awaiting Attestation (Pathway 2)
                  </Option>
                  <Option value="pathway-awaiting-attestation-pathway-345">
                    Awaiting Attestation (Pathway 3, 4, 5)
                  </Option>
                  <Option value="pathway-document-received-in-review">
                    Pathway document received; In Review
                  </Option>
                  <Option value="pathway-verification-rejected-and-resent">
                    Pathway verification Rejected and Resent
                  </Option>
                  <Option value="pathway-verification-review-complete">
                    Pathway verification review complete
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Title level={4}>Exception Status</Title>
          <Row>
            <Col span={24}>
              <Form.Item
                name="exceptionStatus"
                rules={[{ required: true, message: 'Please select an answer' }]}
                initialValue={exceptionStatus !== '' ? exceptionStatus : null}
              >
                <Select
                  onChange={(e: any) => {
                    console.log(e);
                    setExceptionStatus(e.toString());
                  }}
                  disabled={disableChecklist}
                >
                  <Option value="exception-exception-request-in-process">
                    Exception request in process
                  </Option>
                  <Option value="awaiting-applicant-resolution">
                    Awaiting applicant resolution
                  </Option>
                  <Option value="exception-request-under-review-with-operations">
                    Exception request under review with operations leadership
                  </Option>
                  <Option value="exception-request-under-review-with-enterprise">
                    Exception request under review with enterprise leadership
                  </Option>
                  <Option value="exception-review-complete">Exception review complete</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Title level={4}>General Eligibility</Title>
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="applicantIsNotCertified"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={applicantIsNotCertified !== '' ? applicantIsNotCertified : null}
              >
                <Select
                  onChange={(e: any) => setApplicantIsNotCertified(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Applicant is not Certified</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="applicantNotValidStep2CSScore"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={
                  applicantNotValidStep2CSScore !== '' ? applicantNotValidStep2CSScore : null
                }
              >
                <Select
                  onChange={(e: any) => setApplicantNotValidStep2CSScore(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Applicant does not have Step 2 CS score that is valid for ECFMG Certification</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="applicantHasNotSubmittedAppForCert"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={
                  applicantHasNotSubmittedAppForCert !== ''
                    ? applicantHasNotSubmittedAppForCert
                    : null
                }
              >
                <Select
                  onChange={(e: any) => setApplicantHasNotSubmittedAppForCert(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Applicant has submitted an App for Cert</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="applicantHasPurchasedToken2021Match"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={
                  applicantHasPurchasedToken2021Match !== ''
                    ? applicantHasPurchasedToken2021Match
                    : null
                }
              >
                <Select
                  onChange={(e: any) => setApplicantHasPurchasedToken2021Match(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Applicant has purchased a Token for 2021 Match</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="applicantNotBarredByUSMLE"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={applicantNotBarredByUSMLE !== '' ? applicantNotBarredByUSMLE : null}
              >
                <Select
                  onChange={(e: any) => setApplicantNotBarredByUSMLE(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              Applicant is not barred by USMLE from taking an exam from August 1, 2020 to January
              31, 2021
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="ecfmgRestrictionsDoNotPreventAppExamination"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={
                  ecfmgRestrictionsDoNotPreventAppExamination !== ''
                    ? ecfmgRestrictionsDoNotPreventAppExamination
                    : null
                }
              >
                <Select
                  onChange={(e: any) =>
                    setEcfmgRestrictionsDoNotPreventAppExamination(e.toString())
                  }
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>ECFMG Restrictions do not prevent application for examination</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="step1PassedWithOneOrNoFails"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={
                  step1PassedWithOneOrNoFails !== '' ? step1PassedWithOneOrNoFails : null
                }
              >
                <Select
                  onChange={(e: any) => setStep1PassedWithOneOrNoFails(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Step 1 passed, 1 or no fails</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="step2CKPassedWithOneOrNoFails"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={
                  step2CKPassedWithOneOrNoFails !== '' ? step2CKPassedWithOneOrNoFails : null
                }
              >
                <Select
                  onChange={(e: any) => setStep2CKPassedWithOneOrNoFails(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Step 2 CK passed, 1 or no fails</Col>
          </Row>
          <br />
          <Title level={4}>Exception Eligibility</Title>
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="exceptionApproved"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={exceptionApproved !== '' ? exceptionApproved : null}
              >
                <Select
                  onChange={(e: any) => setExceptionApproved(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Exception Approved</Col>
          </Row>
          <br />
          <Title level={4}>Path Eligibility</Title>
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="applicantEligiblePathway1"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={applicantEligiblePathway1 !== '' ? applicantEligiblePathway1 : null}
              >
                <Select
                  onChange={(e: any) => setApplicantEligiblePathway1(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Applicant is eligible for Pathway 1</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="pathway1DocsVerified"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={pathway1DocsVerified !== '' ? pathway1DocsVerified : null}
              >
                <Select
                  onChange={(e: any) => setPathway1DocsVerified(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Pathway 1 documents verified</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="applicantEligiblePathway2"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={applicantEligiblePathway2 !== '' ? applicantEligiblePathway2 : null}
              >
                <Select
                  onChange={(e: any) => setApplicantEligiblePathway2(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Applicant is eligible for Pathway 2</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="pathway2DocsVerified"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={pathway2DocsVerified !== '' ? pathway2DocsVerified : null}
              >
                <Select
                  onChange={(e: any) => setPathway2DocsVerified(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Pathway 2 documents verified</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="applicantEligiblePathway345"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={
                  applicantEligiblePathway345 !== '' ? applicantEligiblePathway345 : null
                }
              >
                <Select
                  onChange={(e: any) => setApplicantEligiblePathway345(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Applicant is eligible for Pathway 3/4/5</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="pathway345DocsVerified"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: 'Please select an answer' },
                  {
                    pattern: new RegExp('^(?!\\s*$).+'),
                    message: 'Please select an answer',
                  },
                ]}
                initialValue={pathway345DocsVerified !== '' ? pathway345DocsVerified : null}
              >
                <Select
                  onChange={(e: any) => setPathway345DocsVerified(e.toString())}
                  style={{ width: '70px' }}
                  disabled={disableChecklist}
                >
                  {selectOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col>Pathway 3/4/5 documents verified</Col>
          </Row>
          <br />
          <Row justify="end">
            <Col>
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={form.submit}
                disabled={disableChecklist}
              >
                <Button
                  type="primary"
                  shape="round"
                  icon={<CheckOutlined />}
                  size={'small'}
                  disabled={disableChecklist}
                >
                  Complete Review
                </Button>
              </Popconfirm>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Col>
              <Space>
                <Button
                  shape="round"
                  onClick={() => {
                    redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  shape="round"
                  type="primary"
                  onClick={onClickSave}
                  disabled={disableChecklist}
                >
                  Save and Return
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default {
  Component: PathwayXDocumentReviewChecklist,
};
